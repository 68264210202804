import { Card, CardBody, Col,  Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import React, { PureComponent } from 'react';
import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';



const PiechartCard = ({pieData}) => {
  if (pieData && pieData.datasets) {
    const datasetCopy = [...pieData.datasets]
    if (datasetCopy && datasetCopy[0].data.length === 0) {
      datasetCopy[0].data = new Array(pieData.labels.length).fill(null);
    }
    pieData.datasets = datasetCopy;
  };

  return (
    <Col md={6} className="dashboard_middle pie_graph">
      <Card>
      <h2>SELLING CHANNELS</h2>
          <div>
          </div>
          <div className="chart-wrapper">
            <div className="pie_chart for_web">
              <Doughnut width={10} height={3} key={Math.random()} data={pieData} options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  tooltips: {
                    enabled: true,
                    mode: 'nearest',
                    callbacks: {
                      label: function(tooltipItem, data) {
                        var value = pieData.seriesData[tooltipItem.index];
                        var label = pieData.labels[tooltipItem.index];
                        return `${label}: ${value}`;
                      }
                    }
                  }
                }}/>
            </div>

            <div className="pie_chart for_mobile">
              <Doughnut width={30} height={20} key={Math.random()} data={pieData} options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  tooltips: {
                    enabled: true,
                    mode: 'nearest',
                    callbacks: {
                      label: function(tooltipItem, data) {
                        var value = pieData.seriesData[tooltipItem.index];
                        var label = pieData.labels[tooltipItem.index];
                        return `${label}: ${value}`;
                      }
                    }
                  }
                }}/>
            </div>
          </div>
      </Card>
    </Col>
)};

export default PiechartCard;
