import React, {PureComponent} from 'react';
import api from '../../../utils/api';
import helper from '../../../utils/helper';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';
import 'react-datepicker/dist/react-datepicker.css';

import {
    Card,
    CardBody,
    Col,
    Button,
    Input,
    Label,
} from 'reactstrap';
import {Link} from "react-router-dom";

class AddUpcManagementCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            number: '',
            sku: '',
            isLoading: false,
        };

        this.getUpc = this.getUpc.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({id: this.props.match.params.id}, () => this.getUpc())

        }
    }

    async getUpc() {
        this.setState({isLoading: true})
        try {

          const data = await api.getApi(`upc/${this.state.id}`);
            if (data.status == 'Success') {
                this.setState({ number: data.data.Number, sku: data.data.Sku, isLoading: false})
            } else {
                helper.errorMessage(data.messages[0]);
                this.setState({isLoading: false})
            }


        } catch (e) {
            this.setState({isLoading: false})

        }
    }

    async submit() {
        if (!this.state.number) {
            helper.errorMessage('Number field is required');
        } else {
            this.setState({isLoading: true})
            try {
                if (this.state.id) {
                   const data = await api.putApi(`upc/update/${this.state.id}`, {number: this.state.number, sku: this.state.sku});
                    if (data.status == 'Success') {
                        history.push('/pages/upcmanagement');
                        helper.successMessage('Upc Updated');


                    } else {
                        helper.errorMessage(data.messages[0]);
                    }

                } else {
                    const data = await api.postApi('upc/create', {number: this.state.number, sku: this.state.sku});
                    if (data.status == 'Success') {
                        history.push('/pages/upcmanagement');
                        helper.successMessage('Upc saved');
                    } else {
                        helper.errorMessage(data.messages[0]);
                    }
                }
                this.setState({isLoading: false})

            } catch (e) {
                this.setState({isLoading: false})

            }
        }
    };

    render() {
        const {number, sku} = this.state;

        return (
            <Col className='container-div' md={12}>
                {this.state.isLoading ? (
                    <div
                        className='load'
                        style={{
                            backgroundColor: 'transparent',
                            width: '85%',
                            height: '50%',
                        }}
                    >
                        <div className='load__icon-wrap'>
                            <svg className='load__icon'>
                                <path
                                    fill='#6c6f7f'
                                    d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
                                />
                            </svg>
                        </div>
                    </div>
                ) : (
                    <Col md={12}>
                        <Card style={{marginBottom: 15}}>
                            <CardBody>
                                <div className='card__title'>
                                    <h5 className='bold-text'>Add Upc</h5>
                                </div>

                                <div className='form__form-group-field left'>
											<span>
												<label>
													Number<span className='mantory'>*</span>
												</label>
												<input
                                                    name='name'
                                                    type='text'
                                                    placeholder=''
                                                    value={number}
                                                    onChange={(event) => {
                                                        this.setState({number: event.target.value});
                                                    }}
                                                />
											</span>
                                </div>

                                <div className='form__form-group-field left'>
											<span>
												<label>
													Sku
												</label>
												<input
                                                    name='name'
                                                    type='text'
                                                    placeholder=''
                                                    value={sku}
                                                    onChange={(event) => {
                                                        this.setState({sku: event.target.value});
                                                    }}
                                                />
											</span>
                                </div>
                                <div className='form_footer'>
                                    <Link to='/pages/upcmanagement'>
                                        <button className='btn addbtn cancelbtn'>Cancel</button>
                                    </Link>

                                    <button
                                        className='btn addbtn'
                                        onClick={this.submit}
                                    >
                                        {this.state.id ? 'Update' : 'Add'}
                                    </button>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Col>
        );
    }
}

export default AddUpcManagementCard;
