// F1 API v6
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import BulkProductTableCell from "../components/BulkProductTableCell";

const sellList = [
  { value: "Amazon", label: "Amazon" },
  { value: "ebay", label: "eBay" },
  { value: "Walmart", label: "Walmart" },
  { value: "Sears", label: "Sears" },
  { value: "Wish", label: "Wish" },
  { value: "Bonanza", label: "Bonanza" },
  { value: "Walmart Canada", label: "Walmart Canada" },
  { value: "Amazon Canada", label: "Amazon Canada" },
  { value: "Valet Shop", label: "Valet Shops" },
  { value: "Shopify", label: "Shopify" },
  // { value: 'Google', label: 'Google' },
  // { value: 'Facebook', label: 'Facebook' },
  { value: "Kroger", label: "Kroger" },
  { value: "amazon.ae", label: "amazon.ae" },
  { value: "amazon.sg", label: "amazon.sg" },
  { value: "Bed Bath & Beyond", label: "Bed Bath and Beyond" },
  { value: "Wayfair", label: "Wayfair" },
];

const planList = [
  { plan_id: "plan_GolzmuxL8nUZAe", plan_name: "plan_99_m", market: 3, sku: 9 },
  {
    plan_id: "price_1GySs4DlZZqatNiCdCaK5qc6",
    plan_name: "plan_149_m",
    market: 5,
    sku: 49,
  },
  {
    plan_id: "price_1GySsyDlZZqatNiCEiAdXPKl",
    plan_name: "plan_199_m",
    market: 100,
    sku: 99,
  },
  {
    plan_id: "price_1H6z3mDlZZqatNiCMg0WPM54",
    plan_name: "plan_1020_y",
    market: 3,
    sku: 9,
  },
  {
    plan_id: "price_1H6z4LDlZZqatNiCn1dfcGe0",
    plan_name: "plan_1524_y",
    market: 5,
    sku: 49,
  },
  {
    plan_id: "price_1H6z4tDlZZqatNiCkD2GK6UQ",
    plan_name: "plan_2040_y",
    market: 100,
    sku: 99,
  },
];

const capitalize = (text) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
};

export const flaggedKeywords = [
  "All Natural",
  "Anti-bacterial",
  "Anti-fungal",
  "anti-microbial",
  "Antibacterial",
  "antifungal",
  "approved",
  "bacteria",
  "biodegradable",
  "biological contaminants",
  "cancer",
  "certified",
  "compostable",
  "cure",
  "decomposable",
  "degradable",
  "eco-friendly",
  "ecofriendly",
  "environmentally friendly",
  "fda",
  "filter",
  "flawless",
  "fungus",
  "green",
  "guarantee",
  "heal",
  "home compostable",
  "marine degradable",
  "mildew",
  "mould",
  "mould resistant",
  "mould spores",
  "Native American, Indian or tribes",
  "non-toxi",
  "noncorrosive",
  "peal",
  "platinum",
  "proven",
  "recommended by",
  "sanitize",
  "sanitizes",
  "tested",
  "toxic",
  "treat",
  "validated",
  "virus",
  "viruses",
  "pesticides",
  "pesticide",
  "Fungicides",
  "Fungicide",
  "covid",
  "coronavirus",
  "detoxify",
  "dotoxifying",
  "detoxification",
  "weight loss",
  "treatment",
  "disease",
  "toxin",
  "toxins",
  "viral",
  "fungal",
  "parasitic",
  "remedy",
  "remedies",
  "diseases",
  "Cancroid",
  "Chlamydia",
  "Coronavirus",
  "COVID-19",
  "Cytomegalovirus",
  "CMV",
  "Human Papiloma Virus",
  "HPV",
  "Gororrhea",
  "The Clap",
  "Hepatitis A",
  "Hepatitis B",
  "Hepatitis C",
  "Herpes",
  "Herpes Simplex Virus 1",
  "Herpes Simplex Virus 2",
  "HSV1",
  "HSV2",
  "Human Immunodeficiency Virus",
  "HIV",
  "AIDS",
  "Acquired Immune Deficiency Syndrome",
  "Lymphogranuloma Venereum",
  "LGV",
  "Mononucleosis",
  "Mono",
  "Mycoplasma Genitalium",
  "Nongonococcal Urethritis",
  "NGU",
  "Pelvic Inflammatory Disease",
  "PID",
  "Public lice",
  "Crabs",
];

export default {
  colourNameToHex: (colour) => {
    var colours = {
      aliceblue: "#f0f8ff",
      antiquewhite: "#faebd7",
      aqua: "#00ffff",
      aquamarine: "#7fffd4",
      azure: "#f0ffff",
      beige: "#f5f5dc",
      bisque: "#ffe4c4",
      black: "#000000",
      blanchedalmond: "#ffebcd",
      blue: "#0000ff",
      blueviolet: "#8a2be2",
      brown: "#a52a2a",
      burlywood: "#deb887",
      cadetblue: "#5f9ea0",
      chartreuse: "#7fff00",
      chocolate: "#d2691e",
      coral: "#ff7f50",
      cornflowerblue: "#6495ed",
      cornsilk: "#fff8dc",
      crimson: "#dc143c",
      cyan: "#00ffff",
      darkblue: "#00008b",
      darkcyan: "#008b8b",
      darkgoldenrod: "#b8860b",
      darkgray: "#a9a9a9",
      darkgreen: "#006400",
      darkkhaki: "#bdb76b",
      darkmagenta: "#8b008b",
      darkolivegreen: "#556b2f",
      darkorange: "#ff8c00",
      darkorchid: "#9932cc",
      darkred: "#8b0000",
      darksalmon: "#e9967a",
      darkseagreen: "#8fbc8f",
      darkslateblue: "#483d8b",
      darkslategray: "#2f4f4f",
      darkturquoise: "#00ced1",
      darkviolet: "#9400d3",
      deeppink: "#ff1493",
      deepskyblue: "#00bfff",
      dimgray: "#696969",
      dodgerblue: "#1e90ff",
      firebrick: "#b22222",
      floralwhite: "#fffaf0",
      forestgreen: "#228b22",
      fuchsia: "#ff00ff",
      gainsboro: "#dcdcdc",
      ghostwhite: "#f8f8ff",
      gold: "#ffd700",
      goldenrod: "#daa520",
      gray: "#808080",
      green: "#008000",
      greenyellow: "#adff2f",
      honeydew: "#f0fff0",
      hotpink: "#ff69b4",
      "indianred ": "#cd5c5c",
      indigo: "#4b0082",
      ivory: "#fffff0",
      khaki: "#f0e68c",
      lavender: "#e6e6fa",
      lavenderblush: "#fff0f5",
      lawngreen: "#7cfc00",
      lemonchiffon: "#fffacd",
      lightblue: "#add8e6",
      lightcoral: "#f08080",
      lightcyan: "#e0ffff",
      lightgoldenrodyellow: "#fafad2",
      lightgrey: "#d3d3d3",
      lightgreen: "#90ee90",
      lightpink: "#ffb6c1",
      lightsalmon: "#ffa07a",
      lightseagreen: "#20b2aa",
      lightskyblue: "#87cefa",
      lightslategray: "#778899",
      lightsteelblue: "#b0c4de",
      lightyellow: "#ffffe0",
      lime: "#00ff00",
      limegreen: "#32cd32",
      linen: "#faf0e6",
      magenta: "#ff00ff",
      maroon: "#800000",
      mediumaquamarine: "#66cdaa",
      mediumblue: "#0000cd",
      mediumorchid: "#ba55d3",
      mediumpurple: "#9370d8",
      mediumseagreen: "#3cb371",
      mediumslateblue: "#7b68ee",
      mediumspringgreen: "#00fa9a",
      mediumturquoise: "#48d1cc",
      mediumvioletred: "#c71585",
      midnightblue: "#191970",
      mintcream: "#f5fffa",
      mistyrose: "#ffe4e1",
      moccasin: "#ffe4b5",
      navajowhite: "#ffdead",
      navy: "#000080",
      oldlace: "#fdf5e6",
      olive: "#808000",
      olivedrab: "#6b8e23",
      orange: "#ffa500",
      orangered: "#ff4500",
      orchid: "#da70d6",
      palegoldenrod: "#eee8aa",
      palegreen: "#98fb98",
      paleturquoise: "#afeeee",
      palevioletred: "#d87093",
      papayawhip: "#ffefd5",
      peachpuff: "#ffdab9",
      peru: "#cd853f",
      pink: "#ffc0cb",
      plum: "#dda0dd",
      powderblue: "#b0e0e6",
      purple: "#800080",
      rebeccapurple: "#663399",
      red: "#ff0000",
      rosybrown: "#bc8f8f",
      royalblue: "#4169e1",
      saddlebrown: "#8b4513",
      salmon: "#fa8072",
      sandybrown: "#f4a460",
      seagreen: "#2e8b57",
      seashell: "#fff5ee",
      sienna: "#a0522d",
      silver: "#c0c0c0",
      skyblue: "#87ceeb",
      slateblue: "#6a5acd",
      slategray: "#708090",
      snow: "#fffafa",
      springgreen: "#00ff7f",
      steelblue: "#4682b4",
      tan: "#d2b48c",
      teal: "#008080",
      thistle: "#d8bfd8",
      tomato: "#ff6347",
      turquoise: "#40e0d0",
      violet: "#ee82ee",
      wheat: "#f5deb3",
      white: "#ffffff",
      whitesmoke: "#f5f5f5",
      yellow: "#ffff00",
      yellowgreen: "#9acd32",
    };

    if (typeof colours[colour.toLowerCase()] != "undefined")
      return colours[colour.toLowerCase()];

    return "#000";
  },
  errorMessage: (message) => {
    console.log("message==", message);
    if (typeof message === "object") {
      let errorMsg = "";
      for (let data in message) {
        console.log(message[data][0]);
        errorMsg = errorMsg + message[data][0];
      }
      toast.error(errorMsg);
    } else {
      toast.error(message);
    }
  },
  warningMessage: (message) => {
    console.log("message==", message);
    if (typeof message === "object") {
      let errorMsg = "";
      for (let data in message) {
        console.log(message[data][0]);
        errorMsg = errorMsg + message[data][0];
      }
      toast.warn(errorMsg);
    } else {
      toast.warn(message);
    }
  },
  getPlanList: () => {
    return planList;
  },
  getPlanName: (planName) => {
    let selectPlan = "";
    selectPlan = planList.find((data1) => {
      return data1.plan_name === planName;
    });
    return selectPlan;
  },
  getTabIndex: () => {
    let tabIndex = 0;
    if (window.location.pathname == "/pages/profile") {
      tabIndex = 0;
    } else if (window.location.pathname == "/pages/integrations") {
      tabIndex = 1;
    } else if (window.location.pathname == "/pages/marketplaces") {
      tabIndex = 2;
    } else if (window.location.pathname == "/pages/transaction") {
      tabIndex = 3;
    } else if (window.location.pathname == "/pages/branding") {
      tabIndex = 4;
    } else if (window.location.pathname == "/pages/subscription") {
      tabIndex = 5;
    } else if (window.location.pathname == "/pages/notification") {
      tabIndex = 6;
    } else if (window.location.pathname == "/pages/tax") {
      tabIndex = 7;
    }
    return tabIndex;
  },
  getSellList: () => {
    return sellList;
  },
  getShipList: () => {
    return [
      { value: "myself", label: "I will ship orders myself" },
      { value: "valetseller", label: "I want Valet Seller to ship my orders" },
    ];
  },
  getPaidList: () => {
    return [
      { value: "paypal", label: "PayPal" },
      // { value: 'payoneer', label: 'Payoneer' },
      { value: "ach", label: "ACH Bank Transfer" },
    ];
  },
  getTimeList: () => {
    return [
      { value: "sameDay", label: "Same Day" },
      { value: "oneBusinessDays", label: "1 Business Day" },
      { value: "twoBusinessDays", label: "2 Business Days" },
      { value: "threeBusinessDays", label: "3 Business Days" },
      { value: "fourBusinessDays", label: "4 Business Days" },
      { value: "5+BusinessDays", label: "5+ Business Days" },
    ];
  },
  getColorList: () => {
    return [
      { value: "redPrioritycolor", label: "Red" },
      { value: "orangePrioritycolor", label: "Orange" },
      { value: "yellowPrioritycolor", label: "Yellow" },
      { value: "blackPrioritycolor", label: "Black" },
    ];
  },
  getBusinesstypeList: () => {
    return [
      { value: "llc", label: "LLC" },
      { value: "corporation", label: "Corporation" },
      { value: "individual", label: "Individual/Sole Proprietorship" },
      { value: "nonprofit", label: "Non-Profit" },
      { value: "partnership", label: "Partnership" },
    ];
  },
  getstateList: () => {
    return [
      { value: "alabama", label: "Alabama" },
      { value: "alaska", label: "Alaska" },
      { value: "arizona", label: "Arizona" },
      { value: "arkansas", label: "Arkansas" },
      { value: "california", label: "California" },
      { value: "colorado", label: "Colorado" },
      { value: "connecticut", label: "Connecticut" },
      { value: "delaware", label: "Delaware" },
      { value: "florida", label: "Florida" },
      { value: "georgia", label: "Georgia" },
      { value: "hawaii", label: "Hawaii" },
      { value: "idaho", label: "Idaho" },
      { value: "illinois", label: "Illinois" },
      { value: "indiana", label: "Indiana" },
      { value: "iowa", label: "Iowa" },
      { value: "kansas", label: "Kansas" },
      { value: "kentucky", label: "Kentucky" },
      { value: "louisiana", label: "Louisiana" },
      { value: "maine", label: "Maine" },
      { value: "maryland", label: "Maryland" },
      { value: "massachusetts", label: "Massachusetts" },
      { value: "michigan", label: "Michigan" },
      { value: "minnesota", label: "Minnesota" },
      { value: "mississippi", label: "Mississippi" },
      { value: "missouri", label: "Missouri" },
      { value: "montana", label: "Montana" },
      { value: "nebraska", label: "Nebraska" },
      { value: "nevada", label: "Nevada" },
      { value: "new_hampshire", label: "New Hampshire" },
      { value: "new_jersey", label: "New Jersey" },
      { value: "new_mexico", label: "New Mexico" },
      { value: "new_york", label: "New York" },
      { value: "north_carolina", label: "North Carolina" },
      { value: "north_dakota", label: "North Dakota" },
      { value: "ohio", label: "Ohio" },
      { value: "oklahoma", label: "Oklahoma" },
      { value: "oregon", label: "Oregon" },
      { value: "pennsylvania", label: "Pennsylvania" },
      { value: "rhode_island", label: "Rhode Island" },
      { value: "south_carolina", label: "South Carolina" },
      { value: "south_dakota", label: "South Dakota" },
      { value: "tennessee", label: "Tennessee" },
      { value: "texas", label: "Texas" },
      { value: "utah", label: "Utah" },
      { value: "vermont", label: "Vermont" },
      { value: "virginia", label: "Virginia" },
      { value: "washington", label: "Washington" },
      { value: "west Virginia", label: "West Virginia" },
      { value: "wisconsin", label: "Wisconsin" },
      { value: "wyoming", label: "Wyoming" },
    ];
  },

  successMessage: (message) => {
    if (typeof message === "object") {
      let errorMsg = "";
      for (let data in message) {
        console.log(message[data][0]);
        errorMsg = errorMsg + message[data][0];
      }
      toast.success(errorMsg);
    } else {
      toast.success(message);
    }
  },
  priceFormat: (amount) => {
    if (amount) {
      return (
        "$" +
        Number(amount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      );
    }

    return 0;
  },
  matchItem: (data, field, value) => {
    let index = -1;
    for (let item in data) {
      if (data[item][field] == value) {
        index = item;
      }
    }
    return index;
  },
  getItem: (data, field, value) => {
    let res;
    for (let item in data) {
      if (data[item][field] == value) {
        res = data[item];
      }
    }
    return res;
  },
  validateUrl: (url) => {
    var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    return re.test(url);
  },
  getItemIndex: (data, field, value) => {
    let index = 0;
    for (let item in data) {
      if (data[item][field] == value) {
        index = item;
      }
    }
    return index;
  },
  getItemLowerIndex: (data, field, value) => {
    let index = 0;
    for (let item in data) {
      if (
        String(data[item][field]).toLocaleLowerCase() ==
        String(value).toLocaleLowerCase()
      ) {
        index = item;
      }
    }
    return index;
  },
  getActiveItemIndex: (data, field, value, active) => {
    let index = 0;
    let count = 0;
    for (let item in data) {
      if (data[item].active == active) {
        count = count + 1;
      }

      if (data[item][field] == value) {
        index = count;
      }
    }
    return index;
  },
  compareDate: (endDate) => {
    var date1 = new Date();
    date1.setDate(date1.getDate() - 1);
    var date2 = new Date(endDate);
    if (date1 < date2) {
      return true;
    }
    return false;
  },

  diffDays: (startDate, endDate) => {
    // var date1 = new Date(startDate);
    // var date2 = new Date(endDate);
    // var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    var date1 = moment(startDate);
    var date2 = moment(endDate);
    var diffDays = date2.diff(date1, "days"); // 1
    return diffDays;
  },
  diffHours: (startDate, endDate) => {
    // var date1 = new Date(startDate);
    // var date2 = new Date(endDate);
    // var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    var date1 = moment(startDate);
    var date2 = moment(endDate);
    var diffHours = date2.diff(date1, "hours"); // 1
    return diffHours;
  },
  getFileNameCsv: (companyname, name, dateObject) => {
    if (dateObject) {
      let fileName = `${capitalize(name)}${
        companyname ? `-${capitalize(companyname)} ` : ""
      }-${moment(dateObject).format("MM-DD-YYYY")}`;
      return fileName + ".csv";
    } else {
      return name + ".csv";
    }
  },
  getDateFormat: (dateObject) => {
    let currentDate = moment(dateObject).format("MM/DD/YYYY");
    return currentDate;
  },
  getMonthFormat: (dateObject) => {
    let currentDate = moment(dateObject).format("MM-YYYY");
    return currentDate;
  },
  getMonth: (dateObject) => {
    let currentDate = moment(dateObject).format("MM");
    return currentDate;
  },
  getYear: (dateObject) => {
    let currentDate = moment(dateObject).format("YYYY");
    return currentDate;
  },
  getMonthObjName: (dateObject) => {
    let currentDate = moment(dateObject).format("MMM");
    return currentDate;
  },
  getFirstUpperCase: (name) => {
    if (name != "") {
      name = name.split("_").join(" ");
      name = name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return name;
  },
  getNumberFormat: (number, Iscurrency) => {
    if (
      number &&
      number !== Infinity &&
      number !== "Infinity" &&
      !isNaN(number)
    ) {
      // number = Math.round(number);
      //   number = new Number(number);
      //   number = number.toFixed(2);
      // number = Math.round(number * 100) / 100;
      number = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(number).substring(1);
    } else {
      number = new Number(0);
      //number = number.toFixed(2);
    }
    //

    //let format = parseFloat(number.toFixed(2)).toLocaleString('en-US');
    let format = number.toLocaleString("en-US", { minimumFractionDigits: 2 });
    if (format == 0) {
      format = "00.00";
    }
    return format;
  },
  getDateTimeFormat: (dateObject) => {
    let currentDate = moment(dateObject).format("MM/DD/YYYY HH:MM:SS");
    return currentDate;
  },
  getActivityFormat: (dateObject) => {
    let currentDate = moment(dateObject).format("DD MMM");
    return currentDate;
  },
  americaFormat: (dateObject) => {
    if (dateObject) {
      dateObject = dateObject.split("-");
      let currentDate =
        ("0" + dateObject[1]).slice(-2) +
        "-" +
        ("0" + dateObject[2]).slice(-2) +
        "-" +
        dateObject[0];
      return currentDate;
    }

    return "";
  },
  getPercentage(total, segment) {
    if (total > 0) {
      let percentage = (Number(segment) * (100 / Number(total))).toFixed(2);
      return percentage;
    } else {
      return 0;
    }
  },
  getPercentCalculation(a, b) {
    var c = (parseFloat(a) * parseFloat(b)) / 100;
    return parseFloat(c).toFixed(2);
  },

  getAverage(total, segment) {
    if (total > 0) {
      let average = Math.round(Number(total) / Number(segment));
      if (average > 0) {
        return average;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  },
  getMonthName(index) {
    var list = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return list[index];
  },
  checkYouTubeUrl(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      //error
      return false;
    }
  },
};

// product react table column with editable cells
export const bulkProductTableColumns = [
  {
    Header: "Parent SKU Number",
    accessor: "row.parent_sku_number__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.ParentSku}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="parent_sku_number__optional_"
      />
    ),
  },
  {
    Header: "Sku",
    accessor: "row.sku_number__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Sku}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="sku_number__required_"
      />
    ),
  },
  {
    Header: "Category",
    accessor: "row.category__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Category}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="category__required_"
      />
    ),
  },
  {
    Header: "Name",
    accessor: "row.name__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Name}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="name__required_"
      />
    ),
  },
  {
    Header: "Brand",
    accessor: "row.brand__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Brand}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="brand__required_"
      />
    ),
  },
  {
    Header: "Price",
    accessor: "row.price__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Price}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="price__required_"
      />
    ),
  },
  {
    Header: "Upc",
    accessor: "row.upc__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Upc}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="upc__required_"
      />
    ),
  },
  {
    Header: "Weight",
    accessor: "row.weight__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Weight}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="weight__required_"
      />
    ),
  },
  {
    Header: "Description",
    accessor: "row.description__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Description}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="description__required_"
      />
    ),
  },
  {
    Header: "Image 1",
    accessor: "row.image_1__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image1}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_1__required_"
      />
    ),
  },
  {
    Header: "Image 2",
    accessor: "row.image_2__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image2}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_2__optional_"
      />
    ),
  },
  {
    Header: "Image 3",
    accessor: "row.image_3__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image3}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_3__optional_"
      />
    ),
  },
  {
    Header: "Image 4",
    accessor: "row.image_4__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image4}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_4__optional_"
      />
    ),
  },
  {
    Header: "Image 5",
    accessor: "row.image_5__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image5}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_5__optional_"
      />
    ),
  },
  {
    Header: "Image 6",
    accessor: "row.image_6__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image6}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_6__optional_"
      />
    ),
  },
  {
    Header: "Image 7",
    accessor: "row.image_7__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.Image7}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="image_7__optional_"
      />
    ),
  },
  {
    Header: "Quantity",
    accessor: "row.inventory_quantity__required_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.QuantityOnHand}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="inventory_quantity__required_"
      />
    ),
  },
  {
    Header: "Retail Price",
    accessor: "row.retail_price__optional_",
    Cell: (tableInfo) => (
      <BulkProductTableCell
        rowValidation={tableInfo.original.validation.RetailPrice}
        value={tableInfo.value}
        rowIndex={tableInfo.index}
        updateFunction={tableInfo.original.updateFunction}
        field="retail_price__optional_"
      />
    ),
  },
];
