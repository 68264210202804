import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddUpcManagementCard from './components/AddUpcManagementCard';


import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class AddUpcManagement extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:''
    }
  }


  render() {
    return (
      <div>
      
     <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add Upc Management</h3>
          </Col>
        </Row>
        <Row>
            <AddUpcManagementCard {...this.props}/>
          
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(AddUpcManagement);
