import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
    Col,
    Row,
} from 'reactstrap';
import api from '../../../utils/api';
import 'react-tabs/style/react-tabs.css';
import firebase1 from '../../../utils/config';
import Modal from 'react-modal';
import PayPalSubscriberBtn from '../../../components/PayPalSubscriberBtn';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '-28%',
        bottom: '-37%',
        width: '50%',
        height: '390px',
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        zindex: '9999',
        overflow: 'hidden',
    },
};


class BrandMonitoring extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modalIsOpen: false,
            price: 49,

        };
        this.paypalOnError = this.paypalOnError.bind(this);
        this.paypalOnSuccess = this.paypalOnSuccess.bind(this);
        this.paypalSubscribe = this.paypalSubscribe.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (
            nextProps.userdetails != undefined &&
            nextProps.userdetails != this.props.userdetails
        ) {
            this.updateUser(nextProps.userdetails);
        }
    }

    updateUser(user) {
        let stripeCustomerId = '';

        if (user.billing && user.billing.stripe_customer_id) {
            stripeCustomerId = user.billing.stripe_customer_id;
        }
        this.setState(
            {
                isLoading: false,
                key: user.key,
                firstname: user.firstname,
                company: user.company,
                email: user.email,
                role: user.role.label,
                Uid: user.uid,
                stripeCustomerId: stripeCustomerId,
                selectDiscount: user.discount ? user.discount : null,
            },
            () => {
                // if (user.role.label == 'admin') {
                //   this.getUser();
                //   this.getPurchaseSku(1, this.state.pageSize)
                // }
            },
        );
    }

    brandPurchase() {
        let stripeCustomerId = this.state.stripeCustomerId;
        console.log('stripeCustomerId===', stripeCustomerId);
        if (stripeCustomerId == '' || stripeCustomerId == undefined) {
            helper.errorMessage('please go to profile and add credit card.');
        } else {
            this.setState({ isLoading: true });
            let req = {
                amount: 10,
                currency: 'usd',
                description: 'Brand Monitoring',
                customer: stripeCustomerId,
            };
            api.stripePost('charges', req).then((cutomerData) => {
                if (cutomerData.error) {
                    helper.errorMessage(cutomerData.error.message);
                    this.setState({ isLoading: false });
                } else {
                    this.updateBrandPurchase();
                }
            });
        }
    }

    async updateBrandPurchase() {
        // const key = this.props.userdetails && this.props.userdetails.key
        // const db = firebase1.app.firestore();
        // await db
        //     .collection('user')
        //     .doc(this.state.key || key)
        //     .update({ consulting_session: { created_at: new Date(), active: true } });
        // this.setState({ isLoading: false });
        helper.successMessage('Payment successfully made');
        // this.openModal();
        let reqData = {
            email: this.state.email,
            firstname: this.state.firstname,
            price: this.state.price,
            company: this.state.company,
            subtotal: this.state.price,
            taxes: 0,
            total: this.state.price,
        };
        api.postApi('mail/onBrandMonitoring', reqData).then((data) => {
            console.log(data);
        });
    }

    
	paypalSubscribe = (data, actions) => {
		// const { selectPlan: { product_id } } = this.state;
        return actions.subscription.create({
            plan_id: "P-34F7199317550890RMEAVDJI",
            application_context: {
                shipping_preference: 'NO_SHIPPING',
            },
        });
	};

	paypalOnSuccess = (data, details) => {
        helper.successMessage('Payment successfully made');
		this.setState({ modalIsOpen: true });
		let reqData = {
            email: this.props.userdetails.email,
            firstname: this.props.userdetails.firstname,
            price: this.state.price,
            company: this.props.userdetails.company,
            subtotal: this.state.price,
            taxes: 0,
            total: this.state.price,
        };
        api.postApi('mail/onBrandMonitoring', reqData).then((data) => {
            console.log(data);
        });
		this.props.savePaypalSubscription({ Uid: this.props.userdetails.uid, subscriptionID: data.subscriptionID, paymentType: 'BRAND_MONITORING', userDetails: this.props.userdetails })
	}


    paypalOnError(err) {
        console.log('Error', err);
    }


    openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}



    render() {
        const {
            isLoading,
            price,
        } = this.state;

        if (isLoading) {
            return (
                <div
                    className='load'
                    style={{
                        backgroundColor: 'transparent',
                        width: '85%',
                        height: '50%',
                    }}
                >
                    <div className='load__icon-wrap'>
                        <svg className='load__icon'>
                            <path
                                fill='#6c6f7f'
                                d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
                            />
                        </svg>
                    </div>
                </div>
            );
        } else {
            return (
                <Row>
                    <Col md={12}>
                        <Link to='/pages/marketing'>
                            <button className='back_products'>Back</button>
                        </Link>
                    </Col>
                    <Row className='ads-market-main'>
                        <Col className='ads-market-div'>
                            <Col md={6}>
                                <h2> Brand Monitoring </h2>
                                <p>
                                    Monitor and report unauthorized resellers for your brand on Amazon, eBay, and Walmart.
                                </p>
                            </Col>
                            <Col md={6}>
                                <img src='../img/BrandProtection.png'/>
                            </Col>
                        </Col>
                    </Row>
                    <Row className='' style={{width: '100%'}}>
                        <div className='purchase_div' style={{ marginBottom: 300 }}>
                            <h1>PRICE: ${helper.getNumberFormat(this.state.price)}</h1>
                            <PayPalSubscriberBtn
							createSubscription={this.paypalSubscribe}
							onApprove={this.paypalOnSuccess}
							catchError={this.paypalOnError}
							onError={this.paypalOnError}
							onCancel={this.paypalOnError}
                                />
                        </div>
                        <Modal
						isOpen={this.state.modalIsOpen}
						onAfterOpen={this.afterOpenModal}
						onRequestClose={this.closeModal}
						style={customStyles}
						contentLabel='Example Modal'
					>
						<span className='closepurchase' onClick={this.closeModal}>
							X
						</span>
						<img
							src='../img/favlogo.png'
							style={{ width: 80, marginBottom: 20 }}
						/>
						<h2>Thank you for your purchase!</h2>
						<p className='purchase_p'>
							If you have any questions, please email us at
							support@valetseller.com
						</p>

						<button
							className='btn addbtn return_app'
							onClick={() => {
								this.closeModal();
							}}
						>
							Return to App
						</button>
					</Modal>
                    </Row>
                </Row>
            );
        }
    }
}
const mapStateToProps = ({ authReducer }) => {
    const { user, userdetails } = authReducer;
    return { user, userdetails };
};

export default connect(mapStateToProps, actions)(BrandMonitoring);
