import React, { PureComponent } from 'react';
import api from '../../../utils/api';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import CSVReader from "../../../utils/csvReader";
import helper from '../../../utils/helper';
import InventoryWalkthrough from "../../Walkthrough/component/InventoryWalkthrough"
import firebase1 from '../../../utils/config';


const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

class InventoryCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchText: '',
      headers: [{ key: 'Sku', title: 'SKU' },
      { key: 'Name', title: 'Product Title' },
      { key: 'image', title: 'Image' },
      { key: 'warehouse_stock', title: 'Valet Seller Warehouse Stock' },
      { key: 'amazon_fba_stock', title: 'Amazon FBA Stock' },
      { key: 'edit', title: 'Edit' }
      ],
      name: 'products',
      userCompany: '',
      role: '',
      items: [],
      tempItems: [],
      totalPage: 0,
      pageSize: 25,
      skuList: [],
      key: '',
      tableLoading: true,
      normal: false,
      Uid: ''
    };

    this.getData = this.getData.bind(this);
  }


  componentDidMount() {
    //  this.props.addWalkthroughFlag()
    if (this.props.userdetails) {
      this.updateUser(this.props.userdetails);
    }
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.userdetails != undefined && nextProps.userdetails != this.props.userdetails) {
      this.updateUser(nextProps.userdetails);
    }
  }
  updateUser(user) {


    this.setState({
      user: user,
      userCompany: user.company,
      role: user.role.label,
      key: user.key,
      Uid: user.uid,
    })
  }

  handleForce = (data, file) => {
    //console.log('this.fileInput==', file);

    this.setState(
      {
        csvData: data,
      },
      () => {
        // this.fileInput.value = "";
        if (data.length > 0) {
          this.productList = [];
          this.productCount = 1;
          this.addProduct();
        }
      }
    );
    //
  };

  addProduct() {

    if (this.state.csvData[this.productCount]) {
      let data = this.state.csvData[this.productCount];
      //console.log(data);
      if (data.sku_number__required_ === 'A Stock Keeping Unit (SKU) is a distinct alphanumeric number associated with a distinct item within an organization.') {
        helper.errorMessage((this.productCount + 1) + ' row : remove all description');
      } else if (data.sku_number__required_ === '' || data.sku_number__required_ === null) {
        helper.errorMessage((this.productCount + 1) + ' row : SKU Number is required');
      } else if (data.name__required_ === '' || data.name__required_ === null) {
        helper.errorMessage((this.productCount + 1) + ' row : Name is required');
      } else if (data.price == '' || data.price == null) {
        helper.errorMessage((this.productCount + 1) + ' row : Price  is required');
      } else if (data.description__required_ == '') {
        helper.errorMessage((this.productCount + 1) + ' row : Description is required');
      } else if (data.long_description__required_ == '') {
        helper.errorMessage((this.productCount + 1) + ' row : Long description  is required');
      } else if (data.image_1__required_ == '') {
        helper.errorMessage((this.productCount + 1) + ' row : Image 1 is required');
      } else if (!helper.validateUrl(data.image_1__required_)) {
        helper.errorMessage((this.productCount + 1) + ' row : Image 1 is url invalid');
      } else {
        let Images = [];
        let isError = false;
        Images.push({ Url: data.image_1__required_ });
        for (let i = 2; i < 8; i++) {
          if (data["image_" + i + "__optional_"] && data["image_" + i + "__optional_"] != '') {
            //console.log(data["image_" + i + "__optional_"]);
            if (!helper.validateUrl(data["image_" + i + "__optional_"])) {
              helper.errorMessage((this.productCount + 1) + ' row : Image ' + i + ' is url invalid');
              isError = true;
            } else {
              Images.push({ Url: data["image_" + i + "__optional_"] });
            }
          }
        }
        if (!isError) {
          let skuName = this.state.userCompany + "-" + data.sku_number__required_;
          skuName = skuName.replace(/\s/g, '');
          let reqData = {
            Sku: skuName,
            Name: data.name__required_,
            Type: "Product",
            TypeValue: null,
            QuantityOnHand: 0,
            Identifiers: {
              UPC: "",
              EAN: "",
              ISBN: "",
              GTIN: "",
            },
            IsAutoSyncEnabled: true,
            IsDiscontinued: false,
            IsDropshipped: false,
            IsFireArm: false,
            IsVariation: false,
            IsParent: false,
            ProductStatus: 'pending_reviewal',
            GlobalListingAttributes: {
              SoldAsName: "",
              ShortDescription: data.description__required_,
              LongDescription: data.long_description__required_,
              Price: data.price,
              Condition: null,
              value: null,
            },
            Images: Images,
            Warehouses: [],
            SupplierInformation: {
              SupplierId: "",
              SKU: "",
              Price: "",
            },
          };
          this.productList.push(reqData);
          //console.log(this.productList);
          this.productCount = this.productCount + 1;
          this.addProduct();
        }

      }
    } else {
      //console.log('products done===');
      this.productCount = 0;
      this.setState({ isLoading: true });
      this.updateProductCsv();
    }

  }

  updateProductCsv() {
    //console.log('this.productCount====', this.productCount);
    if (this.productList[this.productCount]) {

      api.postEcomDashApi("Inventory", this.productList[this.productCount]).then(
        (data) => {
          //console.log("Inventory====", data);

          if (data.status == "Success") {
            let product = data.data.Product;
            product.Uid = this.state.Uid;
            product.ProductStatus = 'pending_reviewal';
            product.BuyPrice = 0;
            api.postApi("inventory/addProducts", product).then((data) => {

              this.productCount = this.productCount + 1;
              //console.log("Inventory1====", data);
              this.updateProductCsv();
            });
          } else {
            if (data.data && data.data.Messages.length > 0) {
              helper.errorMessage((this.productCount + 2) + ' row : ' + data.data.Messages[0]);
            }
            this.setState({ isLoading: false });
          }
        },
        (err) => {
          //console.log("err====", err);
          this.setState({ isLoading: false });
          helper.errorMessage(err);
        }
      );
    } else {
      this.setState({ isLoading: false });
      //console.log('products update done===', this.productList.length);
      helper.successMessage(
        this.productList.length +
        " Products added, Your Product is Under Review and Will Be Live in 1-3 Business Days"
      );
    }

  }




  handleDarkSideForce = error => {
    //console.log('error==', error);
  }

  getData(page, pageSize) {
    this.setState({ tableLoading: true, pageSize: pageSize, items: [] })
    let apiPath = '';
    let items = [];
    let user = this.state.user;
    let isMyWarehouses = false;

    apiPath = 'inventory/getProducts';
    let reqData = {
      "Pagination": {
        "ResultsPerPage": pageSize,
        "PageNumber": page
      }
    }
    if (this.state.searchText.length > 0) {
      reqData.sku = this.state.searchText;
    }
    if (this.state.role != 'admin') {
      reqData.Uid = this.state.Uid;
    }
    let headers = this.state.headers;


    api.postApi(apiPath, reqData).then((data) => {
      if (data.status == 'Success') {

        items = data.data;
        console.log('items==',items);
        for (let item in items) {
          items[item].warehouse_stock = 0;
          items[item].amazon_fba_stock = 0;
          items[item].brand_warehouse_stock = 0;
          items[item].deliver_stock = 0;
          items[item].Id =  items[item]._id;
          // for(let item1 in items[item].Warehouses){
          //   items[item].warehouse_stock=Number(items[item].warehouse_stock)+Number(items[item].Warehouses[item1].QuantityOnHand);
          // }
          for (let item1 in items[item].Warehouses) {
            if (items[item].Warehouses[item1].WarehouseId == '2019003243') {// warehouse stock
              items[item].warehouse_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
            } else if (items[item].Warehouses[item1].WarehouseId == '2019017136') {//Amazon - FBA
              items[item].amazon_fba_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
            } else if (items[item].Warehouses[item1].WarehouseId == '2019008149') {//Deliverr
              items[item].deliver_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
            }
            // else if (user.ship_orders && user.ship_orders.value == 'myself'&&items[item].Warehouses[item1].Name == user.warehouse_name) { // own warehouses
            //   ////console.log('user.warehouse_id====',user.warehouse_name,items[item].Warehouses[item1].QuantityOnHand);
            //   isMyWarehouses = true
            //   items[item].brand_warehouse_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
            // }

          }
          //items[item].deliver_stock = items[item].QuantityOnHand
          if (items[item].Images && items[item].Images.length > 0) {
            let Images = items[item].Images;
            Images = Images.sort((a, b) => (Number(a.DisplayOrder) > Number(b.DisplayOrder)) ? 1 : ((Number(b.DisplayOrder) > Number(a.DisplayOrder)) ? -1 : 0));
            items[item].image = Images[0].Url;
          } else {
            items[item].image = '';
          }
        }

        if (isMyWarehouses && user.ship_orders && user.ship_orders.value == 'myself') {
          let headerIndex = headers.findIndex(item => item.key === 'brand_warehouse_stock');
          headers[headerIndex].title = user.warehouse_name + ' Warehouse Stock';
        }
        //console.log('items==',items);
        this.setState({ items: items, totalPage: data.pagination.TotalNumberOfPages, normal: false });
      }
      this.setState({ tableLoading: false })
    }, err => {
      //console.log('err====', err);
      this.setState({ tableLoading: false });
      helper.errorMessage(err);
    })

  }




  actionSearch() {

    if (this.state.searchText.length > 0) {
      this.getData(1, this.state.pageSize)
    }
  }

  searchKey(text) {
    this.setState({
      searchText: text
    }, () => {
      //console.log('text.length==', text.length);
      if (text.length == 0) {
        this.getData(1, this.state.pageSize)
      }
    })

  }
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.actionSearch();
    }
  }

  render() {
    const { t } = this.props.setOptions;
    return (

      <Col className="container-div" md={12}>
        {this.state.isLoading == true && <div className="load" style={{ backgroundColor: 'transparent', width: '85%', height: '50%' }}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>}
        {this.state.isLoading == false && <Col md={12} >
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">
                  Products
                      <a className="product_bold-text btn addbtn add_products optimisation"
                    download
                    href="https://firebasestorage.googleapis.com/v0/b/lustrous-oasis-258814.appspot.com/o/template%2FValet_Seller_Bulk_Upload_Template.csv?alt=media"
                  >
                    Download CSV Template
                  </a>
                  <div style={{ float: "right" }} className="step1">
                    <a className="product_bold-text btn addbtn add_products optimisation"
                      target="_blank"
                      href="https://www.valetseller.com/wp-content/uploads/2020/06/OptimizationsTemplate.pdf"
                    >
                      Optimization Guide
                  </a>

                    <Link href="/pages/newproducts" className="btn addbtn add_products add-button">
                      Add Products
                    </Link>

                    <button className="btn addbtn">
                      Import Products
                          <CSVReader
                        accept='.csv'
                        ref={ref => this.fileInput = ref}
                        cssClass="csv-reader-input"
                        onFileLoaded={this.handleForce}
                        onError={this.handleDarkSideForce}
                        parserOptions={papaparseOptions}
                        inputId="ObiWan"
                        inputStyle={{ color: "red" }}
                      />
                    </button>
                  </div>
                </h5>
                <div className="search">
                  <Input type="text" name="search" placeholder="Search for sku" onKeyDown={this.handleKeyDown} value={this.state.searchText} onChange={(event) => { this.searchKey(event.target.value) }} />
                  <Button onClick={() => { this.actionSearch() }}>Search</Button>
                </div>

              </div>

              {this.state.userCompany != '' && <TableList normal={this.state.normal} tableLoading={this.state.tableLoading} totalPage={this.state.totalPage} getData={this.getData} items={this.state.items} headers={this.state.headers} name={this.state.name} />}
            </CardBody>
          </Card>
        </Col>}
        <InventoryWalkthrough />

      </Col>
    )
  }

}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(InventoryCard);
