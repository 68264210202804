import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReportsCard from './components/ReportsCard';



const ReportsCardPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Reports</h3>
      </Col>
    </Row>
    <Row>
      <ReportsCard />
    </Row>
  </Container>
);

export default ReportsCardPage;
