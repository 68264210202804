import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
    Col,
    Row,
} from 'reactstrap';
import api from '../../../utils/api';
import 'react-tabs/style/react-tabs.css';
import firebase1 from '../../../utils/config';
import PayPalBtn from '../../../components/PayPalBtn';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '-28%',
        bottom: '-37%',
        width: '50%',
        height: '390px',
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        zindex: '9999',
        overflow: 'hidden',
    },
};


class ConsultingSession extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            consultingSession: false,
            price: 50,

        };
        this.paypalOnError = this.paypalOnError.bind(this);
        this.paypalonSuccess = this.paypalonSuccess.bind(this);
        this.paypalonCreateOrder = this.paypalonCreateOrder.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (
            nextProps.userdetails != undefined &&
            nextProps.userdetails != this.props.userdetails
        ) {
            this.updateUser(nextProps.userdetails);
        }
    }

    updateUser(user) {
        let stripeCustomerId = '';
        let consultingSession = false;

        if (user.billing && user.billing.stripe_customer_id) {
            stripeCustomerId = user.billing.stripe_customer_id;
        }
        if (user.consulting_session) {
            consultingSession = user.consulting_session.active;
        }
        this.setState(
            {
                isLoading: false,
                key: user.key,
                firstname: user.firstname,
                company: user.company,
                email: user.email,
                role: user.role.label,
                Uid: user.uid,
                stripeCustomerId: stripeCustomerId,
                consultingSession: consultingSession,
                selectDiscount: user.discount ? user.discount : null,
            },
            () => {
                // if (user.role.label == 'admin') {
                //   this.getUser();
                //   this.getPurchaseSku(1, this.state.pageSize)
                // }
            },
        );
    }

    brandPurchase() {
        let stripeCustomerId = this.state.stripeCustomerId;
        console.log('stripeCustomerId===', stripeCustomerId);
        if (stripeCustomerId == '' || stripeCustomerId == undefined) {
            helper.errorMessage('please go to profile and add credit card.');
        } else {
            this.setState({ isLoading: true });
            let req = {
                amount: 50,
                currency: 'usd',
                description: 'Consulting Session (1-Hour)',
                customer: stripeCustomerId,
            };
            api.stripePost('charges', req).then((cutomerData) => {
                if (cutomerData.error) {
                    helper.errorMessage(cutomerData.error.message);
                    this.setState({ isLoading: false });
                } else {
                    this.updateBrandPurchase();
                }
            });
        }
    }

    async updateBrandPurchase() {
        const key = this.props.userdetails && this.props.userdetails.key
        const db = firebase1.app.firestore();
        await db
            .collection('user')
            .doc(this.state.key || key)
            .update({ consulting_session: { created_at: new Date(), active: true } });
        this.setState({ isLoading: false });
        helper.successMessage('Payment successfully made');
        // this.openModal();
        let reqData = {
            email: this.state.email,
            firstname: this.state.firstname,
            price: this.state.price,
            company: this.state.company,
            subtotal: this.state.price,
            taxes: 0,
            total: this.state.price,
        };
        api.postApi('mail/onConsultingSession', reqData).then((data) => {
            console.log(data);
        });
    }

    paypalonCreateOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: 50,
                    },
                },
            ],
            application_context: {
                shipping_preference: 'NO_SHIPPING',
            },
        });
    }

    paypalonSuccess(data) {
        console.log('Payapl approved', data);
        window.gtag('event', 'consulting-session', {
            'type': 'paid_subscription',
            'value': 50.00,
            'utm_source': localStorage.getItem('utm_source'),
            'utm_campaign': localStorage.getItem('utm_campaign')
        })
        this.updateBrandPurchase();
        const Uid = this.state.Uid || (this.props.userdetails && this.props.userdetails.uid)
        this.props.savePaypalOrder({ Uid, orderID: data.orderID || data.id, paymentType: 'CONSULTING_SESSSION', userDetails: this.props.userdetails})
    }

    paypalOnError(err) {
        console.log('Error', err);
    }

    render() {
        const {
            isLoading,
            price,
            consultingSession,
        } = this.state;

        if (isLoading) {
            return (
                <div
                    className='load'
                    style={{
                        backgroundColor: 'transparent',
                        width: '85%',
                        height: '50%',
                    }}
                >
                    <div className='load__icon-wrap'>
                        <svg className='load__icon'>
                            <path
                                fill='#6c6f7f'
                                d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
                            />
                        </svg>
                    </div>
                </div>
            );
        } else {
            return (
                <Row>
                    <Col md={12}>
                        <Link to='/pages/marketing'>
                            <button className='back_products'>Back</button>
                        </Link>
                    </Col>
                    <Row className='ads-market-main'>
                        <Col className='ads-market-div'>
                            <Col md={6}>
                                <h2> Consulting Session (1-Hour) </h2>
                                <p>
                                    Work on your product listings and increase sales with a 1-on-1 coaching session with a verified Valet Seller Consultant.
                                </p>
                            </Col>
                            <Col md={6}>
                                <img src='../img/consulting-session.png'/>
                            </Col>
                        </Col>
                    </Row>
                    <Row className='' style={{width: '100%'}}>
                        <div className='purchase_div' style={{ marginBottom: 300 }}>
                            <h1>PRICE: ${helper.getNumberFormat(this.state.price)} PER SESSION</h1>
                            {/* {consultingSession && ( */}
                                <PayPalBtn
                                    amount={price}
                                    onCreateOrder={this.paypalonCreateOrder}
                                    onSuccess={this.paypalonSuccess}
                                    catchError={this.paypalOnError}
                                    onError={this.paypalOnError}
                                    onCancel={this.paypalOnError}
                                />
                            {/* // )} */}
                            {/* {consultingSession && (
                                <button className='btn btn-primary'>Purchased</button>
                            )} */}
                        </div>
                    </Row>
                </Row>
            );
        }
    }
}
const mapStateToProps = ({ authReducer }) => {
    const { user, userdetails } = authReducer;
    return { user, userdetails };
};

export default connect(mapStateToProps, actions)(ConsultingSession);
