import {
  ADD_RECORD,
  EDIT_RECORD,
  DELETE_RECORD,
  LIST_RECORD,
  UPDATE_RECORD,
  UPDATE_EXAM_MODAL,
  EDIT_MEDICAL_RECORD,
  UPDATE_MEDICAL_RECORD,
  TABLE_LOADING
} from '../constants/Type';

const INITIAL_STATE = {
  list_patients: {},
  list_medicalcases: {},
  list_diagnosis: {},
  list_hypothesis: {},
  list_medicalexam: {},
  list_medicalcases: {},
  list_treatment:{},
  list_derivations:{},
  list_companies:{},
  list_symptoms:{},
  list_user:{},
  list_medical:{},
  list_generalphysicalexam:{},
  list_segmentaryphysicalexam:{},
  list_laboratoryexams:{},
  list_laboratoryexams_medical:{},
  list_imageonologyexams:{},
  list_imageonologyexams_medical:{},
  list_proceduralexams:{},
  list_proceduralexams_medical:{},
  list_examresults:{},
  edit_data:{},
  edit_medical_data:{},
  error: '',
  loading: false,
  isTableLoading:false,
  examModal:false,
  lastUpdated:''
};

export default function(state = {}, action) {

  switch (action.type) {
      case ADD_RECORD:
      let addRecord =''
        if(state[action.name]){
          addRecord = state[action.name].concat(action.payload)
        }else{
          addRecord = {...action.payload}
        }
        return { ...state, loading: false, error: '',[action.name]: addRecord};
      case EDIT_RECORD:
        return { ...state, loading: false, edit_data:action.payload,lastUpdated: Date.now()};
      case TABLE_LOADING:
        return { ...state, isTableLoading: action.payload, lastUpdated: Date.now()};
      case EDIT_MEDICAL_RECORD:
        return { ...state, loading: false, edit_medical_data:action.payload,lastUpdated: Date.now()};
      case UPDATE_EXAM_MODAL:
        return { ...state, examModal:action.payload,lastUpdated: Date.now()};
      case LIST_RECORD:
          return { ...state, loading: false, [action.name]: action.payload};
      case DELETE_RECORD:
          console.log('action.payload===',state[action.name],action.payload);
          let data = state[action.name].filter(item => action.payload !== item.key)
          return { ...state, loading: false,lastUpdated: Date.now(),[action.name]:data};
      case UPDATE_RECORD:
          let update =''
          if(state[action.name]){
            update=state[action.name].map((content, i) => content.key === action.payload.key ? {...action.payload}: content)
          }else{
            update = {...action.payload}
          }
          return { ...state, loading: false,lastUpdated: Date.now(),[action.name]:update};
      case UPDATE_MEDICAL_RECORD:
        let updateMediacl =''
        if(state[action.name]){
          updateMediacl=state[action.name].map((content, i) => content.id === action.payload.id ? {...action.payload}: content)
        }else{
          updateMediacl = {...action.payload}
        }
        let name = action.name+'_medical';
        console.log('name=======',name);
        return { ...state, loading: false,lastUpdated: Date.now(),[name]:updateMediacl};
        default:
      return state;
  }
}
