import React, {PureComponent} from 'react';
import helper from '../../../utils/helper';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import {
    Card,
    CardBody,
    Col,
    Button,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter, Modal
} from 'reactstrap';
import api from '../../../utils/api';
import MarketPlaceValidator from './MarketPlaceLinkValidator';

import CSVReader from 'react-csv-reader';

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}

class MarketplaceLinkManagement extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            name: 'marketplacelinkmanagement',
        };
        this.handleForce = this.handleForce.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.bulkUpload = this.bulkUpload.bind(this);
        this.handleDarkSideForce = this.handleDarkSideForce.bind(this);

        this.tempPage = 0
        this.stripeCustomer = [];
        this.pageArray = [];
    }

    handleForce = data => {
        const fileInput = document.getElementById('marketplace-links');
        fileInput.value = null;
        this.setState({
            csvData: data
        })
        this.toggleConfirm();
    }

    handleDarkSideForce = error => {
        console.log('error==', error);
    }

    toggleConfirm() {
        this.setState({confirmationModal: !this.state.confirmationModal})
    }

    bulkUpload = async () => {
        try {
            this.setState({ importing: true});
            const data = await api.postApi('marketplaceLinks/bulkUpload', { data: this.state.csvData });
            if (data.status == 'Success') {
                this.toggleConfirm();
                helper.successMessage('Import completed')
                this.setState({ importing: false });
            } else {
                helper.errorMessage(data.messages[0]);
                this.setState({ confirmationModal: false, importing: false });
            }
        } catch (e) {
            this.setState({ importing: false })
        }
    }


    render() {
        return (
            <Col className="container-div" md={12}>
                {this.state.isLoading == true &&
                <div className="load" style={{backgroundColor: 'transparent', width: '85%', height: '50%'}}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                        </svg>
                    </div>
                </div>}
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">Marketplace Link Management
                                    {
                                        this.state.confirmationModal ? (
                                            <button
                                                className="btn addbtn" 
                                                onClick={() => {
                                                    this.bulkUpload();
                                                }}
                                            >
                                                Uploaded Marketplace Listings
                                            </button>
                                        ) : (
                                            <Button className="btn addbtn product_orange">
                                            Import Links
                                            <CSVReader
                                                accept='.csv'
                                                cssClass="csv-reader-input"
                                                onFileLoaded={this.handleForce}
                                                onError={this.handleDarkSideForce}
                                                parserOptions={papaparseOptions}
                                                inputId="marketplace-links"
                                                inputStyle={{color: "red"}}
                                            />
                                        </Button>
                                        )
                                    }
                                </h5>
                            </div>
                            {
                                this.state.confirmationModal && (
                                    <MarketPlaceValidator
                                        marketplacedata={this.state.csvData}
                                        setCSVData={(csvData) => {
                                            this.setState({ csvData })
                                        }}
                                    />
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Col>
        )
    }

}

const mapStateToProps = ({curdReducer, authReducer}) => {
};

export default connect(mapStateToProps, actions)(MarketplaceLinkManagement);
