import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Button,
	Input,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import api from '../../../utils/api';
import TableList from '../../Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import firebase1 from '../../../utils/config';
import PayPalBtn from '../../../components/PayPalBtn';

class Professionalstorefront extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			professionalBrand: false,
		};
		this.paypalOnError = this.paypalOnError.bind(this);
		this.paypalonSuccess = this.paypalonSuccess.bind(this);
		this.paypalonCreateOrder = this.paypalonCreateOrder.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	updateUser(user) {
		//console.log('user==2323232332323=', user);
		let stripeCustomerId = '';
		let professionalBrand = false;

		if (user.billing && user.billing.stripe_customer_id) {
			stripeCustomerId = user.billing.stripe_customer_id;
		}
		if (user.professional_brand) {
			professionalBrand = user.professional_brand.active;
		}
		this.setState(
			{
				isLoading: false,
				key: user.key,
				firstname: user.firstname,
				company: user.company,
				email: user.email,
				role: user.role.label,
				Uid: user.uid,
				stripeCustomerId: stripeCustomerId,
				professionalBrand: professionalBrand,
				selectDiscount: user.discount ? user.discount : null,
			},
			() => {
				// if (user.role.label == 'admin') {
				//   this.getUser();
				//   this.getPurchaseSku(1, this.state.pageSize)
				// }
			},
		);
	}

	brandPurchase() {
		let stripeCustomerId = this.state.stripeCustomerId;
		console.log('stripeCustomerId===', stripeCustomerId);
		if (stripeCustomerId == '' || stripeCustomerId == undefined) {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			let req = {
				amount: 79900,
				currency: 'usd',
				description: 'Professional Storefront Design',
				customer: stripeCustomerId,
			};
			api.stripePost('charges', req).then((cutomerData) => {
				if (cutomerData.error) {
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					this.updateBrandPurchase();
				}
			});
		}
	}

	async updateBrandPurchase() {
		const key = this.props.userdetails && this.props.userdetails.key;
		const db = firebase1.app.firestore();
		await db
			.collection('user')
			.doc(this.state.key || key)
			.update({ professional_brand: { created_at: new Date(), active: true } });
		this.setState({ isLoading: false });
		// this.openModal();
		let reqData = {
			email: this.state.email,
			firstname: this.state.firstname,
			price: 799,
			company: this.state.company,
		};
		api.postApi('mail/onProfessionalDetails', reqData).then((data) => {
			console.log(data);
		});
	}

	paypalonCreateOrder(data, actions) {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						currency_code: 'USD',
						value: 799,
					},
				},
			],
			application_context: {
				shipping_preference: 'NO_SHIPPING',
			},
		});
	}

	paypalonSuccess(data) {
		console.log('Payapl approved', data);
		window.gtag('event', 'professional-storefront-design', {
			'type': 'paid_subscription',
			'value': 799.00,
			'utm_source': localStorage.getItem('utm_source'),
			'utm_campaign': localStorage.getItem('utm_campaign')
		})
		const Uid = this.state.Uid || (this.props.userdetails && this.props.userdetails.uid);
		this.updateBrandPurchase();
		this.props.savePaypalOrder({ Uid, orderID: data.id, paymentType: 'PROFESSIONAL_STOREFRONT_DESIGN', userDetails: this.props.userdetails });
	}

	paypalOnError(err) {
		console.log('Error', err);
	}

	render() {
		const { t } = this.props.setOptions;
		const {
			selectDiscount,
			isLoading,
			role,
			totalPrice,
			professionalBrand,
		} = this.state;

		if (isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<div className='storefront_design'>
					<Col md={12}>
						<Link to='/pages/marketing'>
							<button className='back_products'>Back</button>
						</Link>
					</Col>
					<div className='storefront_design_div'>
						<h1>Professional Storefront Design</h1>
						<img src='../img/banner.png' />

						<div className='storefront_design_div_inner'>
							<h4>
								Highlight your catalog, collection, and brand with Valet
								Seller's Storefront Design Service
							</h4>
							<span className='small_p'>
								(TO BE DISPLAYED ON AMAZON AND VALET SHOP)
							</span>
							<span className='title_top'>WHATS INCLUDED:</span>
							<span className='title_top_border' />

							<span className='title_top_heading'>
								Image Optimization & Editing:
							</span>

							<span className='li'>
								- Touch up your white background images and turn them into
								storefront style images
							</span>
							<span className='li'>
								- Creating enticing and eye-catching hero images for each
								Storefront Page
							</span>
							<span className='li'>
								- If you don't have lifestyle images, we work to create
								lifestyle composite images where your product is photoshopped
								into a professional stock photo
							</span>
							<span className='li'>
								- Use of advanced graphic techniques and top-notch tools to
								create rich multimedia content tailored just for your products
							</span>

							<span className='title_top_heading'>
								Expert Content Creation:
							</span>

							<span className='li'>
								- A dedicated team of content writing professional to leverage
								and highlight the benefits and unique features of your products
							</span>
							<span className='li'>
								- Search-optimized text placement and creation of attractive
								headlines that instantly clicks with the customers
							</span>
							<span className='li'>
								- Comprehensive creative briefing to comprehend your products
								efficiently
							</span>
							<span className='li'>
								- Accurate display of your brand's unique attributes, company
								culture, competitive consideration and product catalog
							</span>

							<span className='title_top_heading'>Store Design & Upload:</span>

							<span className='li'>
								- Proper display and breakdown of your catalog according to the
								product categories and subcategories
							</span>
							<span className='li'>
								- Handpicked product assortment to highlight the popular, new
								arrival, best-selling products and also recommended products
								based on visitors search history
							</span>
							<span className='li'>
								- Executing, managing and follow up on all the aspects related
								to brand store submission process
							</span>
						</div>
						<div className='purchase_div' style={{ marginBottom: 300 }}>
							<h1>PRICE: ${helper.getNumberFormat(799)} PER BRAND</h1>
							{!professionalBrand && (
								<PayPalBtn
									amount={totalPrice}
									onCreateOrder={this.paypalonCreateOrder}
									onSuccess={this.paypalonSuccess}
									catchError={this.paypalOnError}
									onError={this.paypalOnError}
									onCancel={this.paypalOnError}
								/>
							)}
							{professionalBrand && (
								<button className='btn btn-primary'>Purchased</button>
							)}
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(Professionalstorefront);
