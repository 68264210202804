import React, { PureComponent, Component } from "react";
import api from "../../../utils/api";
import helper from "../../../utils/helper";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link } from "react-router-dom";
import firebase1 from "../../../utils/config";
import Country from "../../../utils/country.json";
import NumberFormat from "react-number-format";
import Modal from "react-modal";
import history from "../../../utils/history";
import TableList from "../../Table";
import {
  Card,
  CardBody,
  Col,
  Label,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
import Client from "shopify-buy";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import Cropper from "react-easy-crop";
import { getOrientation } from "get-orientation/browser";
import getCroppedImg from "../../Addproducts/components/cropImage";
import { getRotatedImage } from "../../Addproducts/components/rotateImage";
import DatePicker from "react-date-picker";
import PayPalSubscriberBtn from "../../../components/PayPalSubscriberBtn";
import Slider from "react-rangeslider";
import moment from "moment";

// To include the default styles
import "react-rangeslider/lib/index.css";
// import BrandingWalkthrogh from '../../Walkthrough/component/BrandingWalkthrogh';

// import Pricing from "./Pricing";
import {
  BRANDING,
  MARKETING_PLACE_PLAN,
  FINISHED,
  MARKETING_PLACE,
  ADD_BUTTON,
  CANCEL_BUTTON,
} from "../../Walkthrough/popupText";
import Tour from "react-product-intro";

import {
  CardElement,
  injectStripe,
  StripeProvider,
  PostalCodeElement,
  Elements,
} from "react-stripe-elements";
const shipList = helper.getShipList();
const paidList = helper.getPaidList();
const timeList = helper.getTimeList();
const businesstypeList = helper.getBusinesstypeList();
const stateList = helper.getstateList();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "550px",
  },
};

const imageCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "-28%",
    bottom: "-37%",
    width: "380px",
    height: "450px",
    transform: "translate(-50%, -50%)",
    zindex: "9999",
    overflow: "hidden",
  },
};

const alertcustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "160px",
    width: "300px",
  },
};

const deleteCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "160px",
    width: "400px",
  },
};

const videocustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "450px",
    width: "654px",
  },
};

const shopifyproductcustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "60vh",
    width: "80%",
  },
};

const shopifyStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "360px",
    width: "40%",
  },
};

const validEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const tradetypeList = [
  { value: "wordmark", label: "Word Mark" },
  { value: "designmark", label: "Design Mark" },
];

const regionList = [
  { value: "australia", label: "Australia - Intellectual Property Australia" },
  {
    value: "brazil",
    label: "Brazil - Instituto Nacional da Propriedade Industrial",
  },
  { value: "canada", label: "Canada - Canadian Intellectual Property Office" },
  {
    value: "europe",
    label: "Europe - European Union Intellectual Property Office",
  },
  {
    value: "france",
    label: "France - Institut national de la propriété industrielle",
  },
  { value: "germany", label: "Germany - Deutsches Patent- und Markenamt" },
  { value: "india", label: "India - Trademark Registry" },
  { value: "italy", label: "Italy - Ufficio Italiano Brevetti e Marchi" },
  { value: "japan", label: "Japan - Japanese Patent/Trademark Office" },
  { value: "mexico", label: "Mexico - Marcas IMPI" },
  {
    value: "netherlands",
    label: "Netherlands - Benelux Office for Intellectual Property",
  },
  {
    value: "saudiarabia",
    label: "Saudi Arabia - Saudi Authority for Intellectual Property",
  },
  {
    value: "singapore",
    label: "Singapore - Intellectual Property Office of Singapore",
  },
  { value: "spain", label: "Spain - Oficina Española de Patentes y Marcas" },
  { value: "turkey", label: "Turkey - Turkish Patent and Trademark Office" },
  {
    value: "unitedarabemirates",
    label: "United Arab Emirates - Ministry of Economy",
  },
  {
    value: "unitedkingdom",
    label: "United Kingdom - Intellectual Property Office UK",
  },
  {
    value: "unitedstates",
    label: "United States - United States Patent and Trademark Office",
  },
];

const countryList = [];
let countries = Country.countries;
for (let data in countries) {
  countryList.push({
    label: countries[data].name,
    value: countries[data].name,
  });
}

const ORIENTATION_TO_ANGLE = {
  "3": 180,
  "6": 90,
  "8": -90,
};

const paypalPlanList = [
  {
    id: "P-8SU46672P5752974EL6V7DYI",
    sku: 9,
    marketplace: 3,
    name: "$99 / Month",
    price: "$99.00",
    isYear: false,
  }, //plan1 month
  {
    id: "P-8CG47749TA642021FL6V7EGA",
    sku: 49,
    marketplace: 5,
    name: "$149 / Month",
    price: "$149.00",
    isYear: false,
  }, //plan2 month
  {
    id: "P-28W706339P938212YL6V7EUI",
    sku: 99,
    marketplace: 10,
    name: "$199 / Month",
    price: "$199.00",
    isYear: false,
  },
  {
    id: "P-8LN221246F8497041MBDU6XY",
    sku: 9,
    marketplace: 3,
    name: "$85 / Month*",
    price: "$510.00",
    isYear: true,
  }, //plan1 half year
  {
    id: "P-55N781364R8691314MBDU7GQ",
    sku: 49,
    marketplace: 5,
    name: "$127 / Month*",
    price: "$762.00",
    isYear: true,
  }, //plan2 half year
  {
    id: "P-77X47275R2592790FMBDU7UQ",
    sku: 99,
    marketplace: 10,
    name: "$170 / Month*",
    price: "$1,020.00",
    isYear: true,
  }, //plan3 half year
  {
    id: "P-4N066220RU154484NMEFJ4KA",
    sku: 5,
    marketplace: 1,
    name: "$39.00 / Month",
    price: "$39.00",
    isYear: false,
    commission: "10%",
  }, //plan1 month
  {
    id: "P-3UD50413W8710083RMD6ZLPI",
    sku: 10,
    marketplace: 3,
    name: "$99.00 / Month",
    price: "$99.00",
    isYear: false,
    commission: "5%",
  }, //plan2 month
  {
    id: "P-73V28932PS628292FMD6ZMCI",
    sku: 100,
    marketplace: 10,
    name: "$199 / Month",
    price: "$199.00",
    isYear: false,
    commission: "5%",
  },
  {
    id: "P-77M806847P0378016MD6ZM2I",
    sku: 5,
    marketplace: 1,
    name: "$34.00 / month",
    price: "$290.00",
    isYear: true,
    commission: "10%",
  }, //plan1 half year
  {
    id: "P-8W652289VG3957527MD6ZNOA",
    sku: 10,
    marketplace: 3,
    name: "$79 / Month*",
    price: "$990.00",
    isYear: true,
    commission: "5%",
  }, //plan2 half year
  {
    id: "P-4MG839423G922882KMD6ZN7Q",
    sku: 100,
    marketplace: 10,
    name: "$159 / Month*",
    price: "$1,990.00",
    isYear: true,
    commission: "5%",
  }, //plan3 half year
];

class ProfileCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      shopifySearchText: "",
      pageSize: 10,
      page: 1,
      email: "",
      selectPlan: paypalPlanList[0],
      firstname: "",
      password: "",
      company: "",
      file: "",
      profile_photo: "",
      login_type: "",
      error: "",
      selectCountry: null,
      selectRegion: null,
      roleName: "",
      changeCardNumber: false,
      tokencard: "",
      shopifyUsername: "",
      shopifyPassword: "",
      shopifyStore: "",
      isShopify: false,
      modalIsOpen: false,
      videomodalIsOpen: false,
      walmartmodalIsOpen: false,
      shopifyproductIsOpen: false,
      subscribeIsOpen: false,
      modalCardOpen: false,
      alertmodalIsOpen: false,
      suscribermodalIsOpen: false,
      isAgree: false,
      emailmodalIsOpen: false,
      tempemail: "",
      selectSellType: null,
      selectShip: null,
      selectPaid: null,
      selectTime: null,
      warehouseName: "",
      cartList: [],
      tempWarehouseName: "",
      customMsg: "",
      businessDescription: "",
      videoUrl: "",
      tagLine: "",
      fbProfile: "",
      instaProfile: "",
      selectTrade: null,
      logo_photo: "",
      cover_photo: "",
      mark_photo: "",
      packaging_photo: "",
      trademarkNo: "",
      selectRegion: null,
      imageModalIsOpen: false,
      imageIndex: "",
      brandName: "",
      markName: "",
      items: [],
      tempItems: [],
      subscribtionItems: "",
      lastBillingDate: "",
      nextBillingDate: "",
      cardList: [],
      subscriberId: "",
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      isSell: false,
      isSales: false,
      isProduct: false,
      isExpensive: false,
      shopifyModalIsOpen: false,
      isElse: false,
      feedBack: "",
      phoneNumber: "",
      uid: "",
      orderNotificationList: [],
      orderEmail: "",
      selectbusinessType: null,
      selectState: null,
      dateBirth: null,
      einNumber: "",
      maxDate: "",
      fileW9Upload: "",
      IsSelectAllShopify: false,
      planId: "",
      tabIndex: helper.getTabIndex(),
      totalPage: 1,
      skuCount: 9,
      websiteLink: "",
      selectMonth: "month",
      marketName: "",
      activePlan: "",
      activestartTime: "",
      billing: {
        firstname: "",
        lastname: "",
        zip: "",
        country: "",
        token: "",
        stripe_customer_id: "",
      },
      transactionHeaders: [
        { key: "Time", title: "Date" },
        { key: "Name", title: "Name" },
        { key: "Email", title: "Email" },
        { key: "Status", title: "Status" },
        { key: "GrossAmount", title: "Amount" },
        { key: "Currency", title: "Currency" },
        { key: "receipt_url", title: "Invoice" },
      ],
      headers: [
        // { key: 'sku', title: 'Sku' },
        { key: "title", title: "Product Title" },
        { key: "shopify_image", title: "Image" },
        { key: "vendor", title: "Vendor" },
        { key: "product_type", title: "Product Type" },
        { key: "add_product", title: "Action" },
      ],
      ordernotificationHeader: [
        { key: "count", title: "No" },
        { key: "email", title: "Email" },
        // { key: 'notification_status', title: 'Status' },
        { key: "delete", title: "Delete" },
      ],

      transactionItems: [],
      Images: [
        {
          File: "",
          Url: "",
          Name: "logo",
        },
        {
          File: "",
          Url: "",
          Name: "coverimage",
        },
        {
          File: "",
          Url: "",
          Name: "markimage",
        },
        {
          File: "",
          Url: "",
          Name: "packagingimage",
        },
      ],
      volume: 0,
      isTourActive: false,
      tourStep: 1,
      upcHeader: [
        { key: "number", title: "UPC" },
        { key: "sku", title: "SKU" },
        { key: "view-upc", title: "View" },
      ],
      syncInventoryLevelLoading: false,
      syncInventoryLevelSyncOptin: true,
      syncOrdersToMyShopify: true,
      paypalEmail: null,
      bankAccount: null,
      bankRouting: null,
      bankName: null,
      returnAddress: null,
    };
    this.handleToken = this.handleToken.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getCardDetails = this.getCardDetails.bind(this);
    this.cancelSubscribe = this.cancelSubscribe.bind(this);
    this.closeSubscriberModal = this.closeSubscriberModal.bind(this);
    this.openvideoModal = this.openvideoModal.bind(this);
    this.closevideoModal = this.closevideoModal.bind(this);
    this.closewalmartModal = this.closewalmartModal.bind(this);

    this.closeShopifyModel = this.closeShopifyModel.bind(this);
    this.openshopifyproductModal = this.openshopifyproductModal.bind(this);
    this.closeshopifyproductModal = this.closeshopifyproductModal.bind(this);
    this.opensubscribeModal = this.opensubscribeModal.bind(this);
    this.closesubscribeModal = this.closesubscribeModal.bind(this);

    this.addShopifyProduct = this.addShopifyProduct.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.paypalOnError = this.paypalOnError.bind(this);
    this.paypalOnApprove = this.paypalOnApprove.bind(this);
    this.paypalSubscribe = this.paypalSubscribe.bind(this);
    this.shopifyGetProducts = this.shopifyGetProducts.bind(this);
    this.syncInventoryLevels = this.syncInventoryLevels.bind(this);
    this.handleInventoryLevelSyncOptin = this.handleInventoryLevelSyncOptin.bind(
      this
    );
    this.handleFetchTransactions = this.handleFetchTransactions.bind(this);
  }

  componentDidMount() {
    console.log(
      "user===userdetails======================",
      this.props.userdetails
    );
    if (this.props.userdetails) {
      if (!this.props.userdetails.walkthroughDone) {
        this.setState({
          isTourActive: true,
        });
      }
    }
    console.log(
      "this.props.userdetails.walkthroughDone====",
      this.props.userdetails
    );
    const { user, userdetails } = this.state;
    let cartList = helper
      .getSellList()
      .filter((item) => "Valet Shop" != item.value && "Shopify" != item.value);
    for (let item in cartList) {
      cartList[item].active = false;
    }
    this.setState({ cartList: [...cartList] }, () => {
      if (this.props.userdetails) {
        this.updateUser(this.props.userdetails);
      }
    });

    this.tempPage = 0;
    this.shopifyAddProductId = [];
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    this.setState({ maxDate: maxDate });
    const db = firebase1.app.firestore();
    db.collection("user")
      .where("uid", "==", this.state.uid)
      .get()
      .then((snapShot) => {
        if (!snapShot.empty) {
          snapShot.forEach((doc) => {
            const user = doc.data();
            // cancel prevuios existing subscription when a new one is created
            if (user.paypal_billing && user.paypal_billing.subscription_id) {
              console.log("myuserbi", user.paypal_billing);
            }
          });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userdetails != undefined &&
      nextProps.userdetails != this.props.userdetails
    ) {
      this.updateUser(nextProps.userdetails);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userdetails } = this.props;
    if (prevProps.userdetails !== userdetails && !userdetails.admin) {
      this.props.getTransactionHistory({ Uid: userdetails.uid });
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  openvideoModal() {
    this.setState({ videomodalIsOpen: true });
  }

  closevideoModal() {
    this.setState({ videomodalIsOpen: false });
  }

  closewalmartModal() {
    this.setState({ walmartmodalIsOpen: false });
  }

  openshopifyproductModal() {
    this.setState({ shopifyproductIsOpen: true });
  }

  closeshopifyproductModal() {
    this.setState({ shopifyproductIsOpen: false });
  }

  paypalOnApprove(data, detail) {
    this.setState({ selectPlan: paypalPlanList.find((plan) => plan.id === data.plan_id) })
    // check firestore for a previous existing paypal subscription
    const db = firebase1.app.firestore();
    db.collection("user")
      .where("uid", "==", this.state.uid)
      .get()
      .then((snapShot) => {
        if (!snapShot.empty) {
          snapShot.forEach((doc) => {
            const user = doc.data();
            // cancel prevuios existing subscription when a new one is created
            if (user.paypal_billing && user.paypal_billing.subscription_id) {
              api.postApi("payment/cancelSubscription", {
                subscriptionID: user.paypal_billing.subscription_id,
              });
            }
          });
        }
        if (data.subscriptionID) {
          this.updateSubscriber(data.subscriptionID);
          if (this.props.userdetails && !this.props.userdetails.admin) {
            this.props.savePaypalSubscription({
              subscriptionID: data.subscriptionID,
              Uid: this.props.userdetails.uid,
              paymentType: "SUBSCRIPTION_UPGRADE",
              userDetails: this.props.userdetails,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // this.closesubscribeModal();
  }

  handleChangeComplete = (value) => {
    // console.log('Change event completed',value)
  };

  handleOnChangeSku = (value) => {
    this.setState({ skuCount: value }, () => {
      this.calculatePlan();
    });
  };

  selectMarketPlace(index) {
    let cartList = this.state.cartList;
    cartList[index].active = !cartList[index].active;
    this.setState({ cartList: [...cartList] }, () => {
      this.calculatePlan();
    });
  }

  changeType(event) {
    console.log("value==", event.target.value);
    this.setState({ selectMonth: event.target.value }, () => {
      this.calculatePlan();
    });
  }

  calculatePlan() {
    let { skuCount, cartList, selectMonth, selectPlan } = this.state;
    let activeMarket = cartList.filter((item) => item.active);
    if (skuCount < 10 && activeMarket.length < 4) {
      //plan 1
      if (selectMonth == "month") {
        selectPlan = paypalPlanList[0];
      } else if (selectMonth == "halfyear") {
        selectPlan = paypalPlanList[3];
      } else {
        selectPlan = paypalPlanList[6];
      }
    } else if (skuCount < 50 && activeMarket.length < 6) {
      //plan 2
      if (selectMonth == "month") {
        selectPlan = paypalPlanList[1];
      } else if (selectMonth == "halfyear") {
        selectPlan = paypalPlanList[4];
      } else {
        selectPlan = paypalPlanList[7];
      }
    } else {
      //plan 3
      if (selectMonth == "month") {
        selectPlan = paypalPlanList[2];
      } else if (selectMonth == "halfyear") {
        selectPlan = paypalPlanList[5];
      } else {
        selectPlan = paypalPlanList[8];
      }
    }

    this.setState({ selectPlan: selectPlan });
    //console.log(paypalPlanList);
  }

  selectMyPlan(selectPlan) {
    this.setState({ selectPlan: selectPlan });
    //console.log(paypalPlanList);
  }

  paypalOnError(err) {
    console.log("Error", err);
  }

  updateSubscriber(subscriptionID) {
    let activeMarket = this.state.cartList.filter((item) => item.active);
    let data = {
      sell_type: activeMarket,
      paypal_billing: {
        subscription_id: subscriptionID,
        plan_id: this.state.selectPlan.id,
        sku_count: this.state.skuCount,
        created_at: new Date(),
      },
    };

    // const db = firebase1.app.firestore();
    // db.collection('user').where('uid', '==', this.state.uid).update(data)
    console.log("-------->", data);
    helper.successMessage("You have successfully subscribed");
    console.log("======>1");
    window.gtag("event", "paid_conversion", {
      type: "paid_subscription",
      value: parseFloat(
        this.state.selectPlan.price.replace(",", "").replace("$", "")
      ),
      utm_source: localStorage.getItem("utm_source"),
      utm_campaign: localStorage.getItem("utm_campaign"),
    });
    console.log("======>2");
    this.setState({ billing: data.paypal_billing, isLoading: true }, () => {
      this.activeSubscriber(data.paypal_billing, data.sell_type);
      console.log("======>3");
      this.changeTab(5);
      console.log("======>4");
    });
    console.log("======>5");
    let activeAmazonMarket = this.state.cartList.find(
      (item) => item.active && item.value == "Amazon"
    );
    console.log(
      "activeAmazonMarket====",
      activeAmazonMarket,
      this.state.cartList
    );
    if (activeAmazonMarket) {
      this.sendAmazonSubsciber();
      console.log("======>6");
    } else {
      this.sendSubsciberEmail();
      console.log("======>7");
    }
    let apiPath = "";
    apiPath = "mail/onSubscriber";
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
      planname: this.state.selectPlan.name,
      subscriptionID,
    };
    console.log("reqData===", reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log(data);
      if (data.status == "Success") {
      }
      this.setState({ isLoading: false });
    });
    console.log("======>8");
    this.props.updateRecord("user", data, this.state.key, false);
    console.log("======>9");
  }
  sendSubsciberEmail() {
    let apiPath = "";
    apiPath = "mail/onSubscriberUser";
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
      planname: this.state.selectPlan.name,
      price: this.state.selectPlan.price,
    };
    console.log("reqData===", reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log(data);
      if (data.status == "Success") {
      }
    });
  }
  sendAmazonSubsciber() {
    let apiPath = "";
    apiPath = "mail/onAmazonSubscriber";
    let reqData = {
      firstname: this.state.firstname,
      email: this.state.email,
    };
    console.log("reqData===", reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log(data);
      if (data.status == "Success") {
      }
    });
  }

  paypalSubscribe(data, actions) {
    console.log("paypalSubscribe", data);
    console.log("paypalSubscribe========", this.state.selectPlan);
    return actions.subscription.create({
      plan_id: "P-64906081RY5478115MCDIUCA",
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  }

  opensubscribeModal(planId) {
    let selectPlan = paypalPlanList.find((item) => planId == item.id);
    if (selectPlan) {
      this.setState({ selectPlan: selectPlan, subscribeIsOpen: true });
    }
  }

  closesubscribeModal() {
    this.setState({ subscribeIsOpen: false });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openalertModal() {
    this.setState({ alertmodalIsOpen: true });
  }

  // handleCartChange = (selectSellType) => {
  //     this.setState({selectSellType });
  // };
  handleShipChange = (selectShip) => {
    this.setState({ selectShip });
  };
  handlePaidChange = (selectPaid) => {
    this.setState({ selectPaid });
  };
  handleTimeChange = (selectTime) => {
    this.setState({ selectTime });
  };
  handletradeChange = (selectTrade) => {
    this.setState({ selectTrade });
  };
  handlecountryChange = (selectRegion) => {
    this.setState({ selectRegion });
  };

  handleCartChange = (selectSellType) => {
    // if(selectSellType.map(o => o.value) == 'Walmart')
    // {
    //   this.setState({selectSellType, walmartmodalIsOpen:true });
    // }
    console.log("selectSellType===", selectSellType);
    let type = selectSellType.filter((item) => "Valet Shop" != item.value);
    let selectPlan = this.state.selectPlan;

    if (
      selectSellType[selectSellType.length - 1] &&
      selectSellType[selectSellType.length - 1].value == "Walmart"
    ) {
      helper.warningMessage(
        "Walmart's default handling time is 1 business day only"
      );
    }
    if (selectPlan.marketplace < type.length) {
      helper.errorMessage(
        "please select " + selectPlan.marketplace + " marketplace"
      );
    } else {
      this.setState({ selectSellType });
    }
  };

  async deleteAction(key) {
    console.log("key===", key);
    let orderNotificationList = this.state.orderNotificationList;

    if (orderNotificationList[key - 1].email == this.state.email) {
      helper.errorMessage("this is primary email address");
    } else {
      orderNotificationList.splice(key - 1, 1);
      const db = firebase1.app.firestore();
      await db
        .collection("user")
        .doc(this.state.key)
        .update({ order_notification_list: orderNotificationList });
      this.setState({ orderNotificationList: orderNotificationList }, () => {
        this.resetOrderNotifications();
      });
    }
  }

  updateUser(user) {
    let roleName = "";
    let Images = this.state.Images;
    if (user.role) {
      roleName = user.role.label;
    }
    let shopifyUsername = "";
    let shopifyPassword = "";
    let shopifyStore = "";
    let isShopify = false;
    let customMsg = "";
    let businessDescription = "";
    let tagLine = "";
    let videoUrl = "";
    let fbProfile = "";
    let instaProfile = "";
    let brandName = "";
    let selectTrade = null;
    let markName = "";
    let trademarkNo = "";
    let selectRegion = null;

    if (user.shopify) {
      shopifyUsername = user.shopify.username;
      shopifyPassword = user.shopify.password;
      shopifyStore = user.shopify.domain;
      isShopify = true;
    }
    if (user.branding) {
      Images[0].Url = user.branding.logo_photo ? user.branding.logo_photo : "";
      Images[1].Url = user.branding.cover_photo
        ? user.branding.cover_photo
        : "";
      Images[2].Url = user.branding.mark_photo ? user.branding.mark_photo : "";
      Images[3].Url = user.branding.packaging_photo
        ? user.branding.packaging_photo
        : "";

      customMsg = user.branding.custom_msg ? user.branding.custom_msg : "";
      businessDescription = user.branding.business_description
        ? user.branding.business_description
        : "";
      tagLine = user.branding.tag_line ? user.branding.tag_line : "";
      videoUrl = user.branding.video_url ? user.branding.video_url : "";
      fbProfile = user.branding.fb_profile ? user.branding.fb_profile : "";
      instaProfile = user.branding.insta_profile
        ? user.branding.insta_profile
        : "";
      brandName = user.branding.brand_name ? user.branding.brand_name : "";
      selectTrade = user.branding.select_trade
        ? user.branding.select_trade
        : null;
      markName = user.branding.mark_name ? user.branding.mark_name : "";
      trademarkNo = user.branding.trademark_no
        ? user.branding.trademark_no
        : "";
      selectRegion = user.branding.select_region
        ? user.branding.select_region
        : null;
    }

    let orderNotificationList = [];
    if (user.order_notification_list != undefined) {
      orderNotificationList = user.order_notification_list;
    } else {
      orderNotificationList.push({ email: user.email, status: true });
    }
    let count = 1;
    for (let item in orderNotificationList) {
      orderNotificationList[item].count = count;
      orderNotificationList[item].key = count;
      count = count + 1;
    }

    let selectbusinessType = null;
    let selectState = null;
    let einNumber = "";
    let dateBirth = null;

    if (user.tax_information) {
      selectbusinessType = user.tax_information.business_type;
      selectState = user.tax_information.state;
      einNumber = user.tax_information.ein;
      if (user.tax_information.dob && user.tax_information.dob.seconds) {
        dateBirth = new Date(user.tax_information.dob.seconds * 1000);
      }
    }

    const { paypalEmail, bankAccount, bankRouting, bankName } = Boolean(
      user.paymentDetails
    )
      ? user.paymentDetails
      : {};

    this.setState({
      isLoading: false,
      selectCount: 0,
      isShopify: isShopify,
      orderNotificationList: orderNotificationList,
      selectbusinessType: selectbusinessType,
      selectState: selectState,
      einNumber: einNumber,
      dateBirth: dateBirth,
      key: user.key,
      uid: user.uid,
      syncInventoryLevelSyncOptin: user.syncInventoryLevelSyncOptin,
      syncOrdersToMyShopify: user.syncOrdersToMyShopify,
      email: user.email,
      tempemail: user.email,
      password: user.password,
      role: user.role,
      roleName: roleName,
      phoneNumber: user.phone_number ? user.phone_number : "",
      profile_photo: user.profile_photo ? user.profile_photo : "",
      firstname: user.firstname,
      returnAddress: user.returnAddress,
      login_type: user.login_type == undefined ? "register" : user.login_type,
      company: user.company,
      shopifyUsername: shopifyUsername,
      shopifyPassword: shopifyPassword,
      shopifyStore: shopifyStore,
      selectSellType: user.sell_type ? user.sell_type : null,
      selectShip: user.ship_orders ? user.ship_orders : null,
      selectPaid: user.paid_type ? user.paid_type : null,
      selectTime: user.handle_time ? user.handle_time : null,
      websiteLink: user.website_link ? user.website_link : "",
      warehouseName: user.warehouse_name ? user.warehouse_name : "",
      tempWarehouseName: user.warehouse_name ? user.warehouse_name : "",
      Images: Images,
      customMsg: customMsg,
      businessDescription: businessDescription,
      tagLine: tagLine,
      videoUrl: videoUrl,
      fbProfile: fbProfile,
      instaProfile: instaProfile,
      brandName: brandName,
      selectTrade: selectTrade,
      markName: markName,
      trademarkNo: trademarkNo,
      selectRegion: selectRegion,
      upcList: user.upcPurchases ? user.upcPurchases.upcs || [] : [],
      paypalEmail,
      bankAccount,
      bankRouting,
      bankName,
    });

    let sellList = user.sell_type ? user.sell_type : [];
    let cartList = this.state.cartList;
    for (let item in cartList) {
      let activeSell = sellList.find(
        (item1) => cartList[item].value == item1.value && item1.active
      );
      if (activeSell) {
        cartList[item].active = true;
        cartList[item].paid = true;
      } else {
        cartList[item].paid = false;
      }
    }
    cartList = [...cartList.filter((marketPlace) => marketPlace.paid)].concat(
      cartList.filter((marketPlace) => !marketPlace.paid)
    );
    this.setState({ cartList: [...cartList] });
    console.log("user.paypal_billing==", user.paypal_billing, sellList);
    if (user.paypal_billing) {
      this.setState({ isLoading: true });
      this.activeSubscriber(user.paypal_billing, sellList);
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  activeSubscriber(billing, sellList) {
    let cartList = this.state.cartList;
    let marketName = "";
    for (let item in cartList) {
      let activeSell = sellList.find(
        (item1) => cartList[item].value == item1.value && item1.active
      );
      if (activeSell) {
        if (marketName == "") {
          marketName = cartList[item].label;
        } else {
          marketName = marketName + " , " + cartList[item].label;
        }
      }
    }
    let selectPlan = paypalPlanList[0];
    let activePlan = paypalPlanList.find(
      (item1) => billing.plan_id == item1.id
    );
    console.log("activePlan--->>>", activePlan, billing.plan_id);
    if (activePlan) {
      selectPlan = activePlan;
      activePlan.status = "";
    } else {
      activePlan = "";
    }
    console.log("activePlan===", activePlan);
    this.setState(
      {
        billing: billing,
        skuCount: billing.sku_count,
        selectPlan: selectPlan,
        activePlan: activePlan,
        marketName: marketName,
      },
      () => {
        this.newTransactionItems = [];
        this.getToken();
      }
    );
  }

  getToken() {
    let url = "oauth2/token";
    api.paypalTokenPost(url, { grant_type: "client_credentials" }).then(
      (tokenData) => {
        console.log("tokenData==", tokenData.access_token);
        this.getSubscribeDetails(tokenData.access_token);
      },
      (err) => {
        console.log("err====", err);
        this.setState({ isLoading: false });
      }
    );
  }

  getSubscribeDetails(token) {
    this.paypalToken = token;
    let url = "billing/subscriptions/" + this.state.billing.subscription_id;
    api.paypalGet(url, token).then(
      (subscribeData) => {
        console.log("subscribeData==", subscribeData);
        let activePlan = this.state.activePlan;
        let activestartTime = "";
        if (subscribeData) {
          if (subscribeData.status == "CANCELLED") {
            activePlan = "";
          }
          if (activePlan != "") {
            activePlan.status = subscribeData.status;
            activePlan.skuCount = this.state.billing.sku_count;
          }

          activestartTime = helper.getDateFormat(subscribeData.start_time);
        }
        //activePlan = paypalPlanList[0]
        this.setState({
          activestartTime: activestartTime,
          activePlan: activePlan,
        });
        this.getTransactionHistory(token);
      },
      (err) => {
        console.log("err====", err);
        this.setState({ isLoading: false });
      }
    );
  }

  getTransactionHistory(token) {
    let url =
      "billing/subscriptions/" +
      this.state.billing.subscription_id +
      "/transactions?start_time=2020-10-01T07:50:20.940Z&end_time=2020-11-30T07:50:20.940Z";
    api.paypalGet(url, token).then(
      (transactionData) => {
        let transactionItems = [];
        console.log("transactionData==", transactionData);
        if (transactionData) {
          transactionData = transactionData.transactions;
          for (let item in transactionData) {
            transactionItems.push({
              created: transactionData[item].time,
              email: transactionData[item].payer_email,
              status: transactionData[item].status,
              name: transactionData[item].payer_name
                ? transactionData[item].payer_name.given_name
                : "",
              amount: transactionData[item].amount_with_breakdown
                ? transactionData[item].amount_with_breakdown.gross_amount.value
                : "",
              currency: transactionData[item].amount_with_breakdown
                ? transactionData[item].amount_with_breakdown.gross_amount
                  .currency_code
                : "",
            });
          }
          console.log("transactionItems====", transactionItems);
          this.setState({
            transactionItems: [...transactionItems],
            isLoading: false,
          });
        }
      },
      (err) => {
        console.log("err====", err);
        this.setState({ isLoading: false });
      }
    );
  }

  deleteSubscriberAction() {
    //console.log('cancelSubscribe===', this.state.subscriberId);
    let reason = "";
    if (this.state.isSell) {
      reason = "I Found Another Company To Sell My Products";
    }
    if (this.state.isSales) {
      reason = reason == "" ? "" : reason + " , ";
      reason = reason + "I Was Unhappy With My Sales Volume";
    }
    if (this.state.isProduct) {
      reason = reason == "" ? "" : reason + " , ";

      reason = reason + "I Was Unhappy With My Product Listings";
    }
    if (this.state.isExpensive) {
      reason = reason == "" ? "" : reason + " , ";

      reason = reason + "Valet Seller Is Too Expensive";
    }
    if (this.state.isElse) {
      reason = reason == "" ? "" : reason + " , ";

      reason = reason + "It's Something Else";
    }
    if (reason == "") {
      helper.errorMessage("Please select one reason");
    } else if (this.state.feedBack == "") {
      helper.errorMessage("Please enter feedback");
    } else {
      this.setState({ isLoading: true });
      api
        .paypalPost(
          "billing/subscriptions/" +
          this.state.billing.subscription_id +
          "/cancel",
          { reason: reason },
          this.paypalToken
        )
        .then(
          (transactionData) => {
            console.log("cancel====", transactionData);
            // if (transactionData && transactionData.message) {
            // 	helper.errorMessage(transactionData.message);
            // 	this.setState({ isLoading: false });
            // } else {
            // this.setState({ isLoading: false, activePlan: '' });
            // this.accountBlocked(reason);
            // }
            this.setState({ isLoading: false, activePlan: "" });
            this.accountBlocked(reason);
          },
          (err) => {
            console.log("err====", err);
            this.setState({ isLoading: false });
          }
        );
    }
  }

  async accountBlocked(reason) {
    this.setState({ isLoading: true });

    let apiPath = "";
    apiPath = "mail/onCancelAccount";
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
      uid: this.state.uid,
      reason: reason,
      feedback: this.state.feedBack,
    };
    //console.log('reqData===', reqData);
    api.postApi(apiPath, reqData).then((data) => {
      //console.log(data);
    });

    const db = firebase1.app.firestore();
    await db
      .collection("user")
      .doc(this.state.key)
      .update({
        customer_feedback: this.state.feedBack,
        cancel_reason: reason,
        cancel_date: moment().format("MM/DD/YYYY"),
      });
    localStorage.removeItem("role");
    localStorage.removeItem("addProductSeen");
    localStorage.removeItem("/");
    localStorage.removeItem("inventorySeen");
    localStorage.removeItem("salesSeen");
    localStorage.removeItem("dashboardSeen");
    localStorage.removeItem("topRightMenuSeen");
    await firebase1.app.auth().signOut();
    this.setState({ isLoading: false });
    history.push("/login");
  }

  getCardDetails(customerId) {
    this.setState({ isLoading: true });
    api.stripeGet("customers/" + customerId).then((tokenData) => {
      this.setState({ isLoading: false });
      //console.log('tokenData===', tokenData);
      if (tokenData.sources && tokenData.sources.data) {
        let cardList = JSON.parse(JSON.stringify(tokenData.sources.data));
        // //console.log('cardList===', cardList);
        for (let data in cardList) {
          var companyData = countryList.find((data1) => {
            return data1.label === cardList[data].address_country;
          });
          cardList[data].address_country = companyData ? companyData : null;
          cardList[data].cardnumber = "XXXXXXXXXXXX" + cardList[data].last4;
          cardList[data].ccv = "***";
          cardList[data].firstname = cardList[data].name
            ? cardList[data].name
            : this.state.firstname;
          cardList[data].default =
            cardList[data].id == tokenData.default_source ? true : false;
        }
        // //console.log(cardList);
        this.setState({
          cardList: [...cardList],
          defaultCardId: tokenData.default_source,
        });
      }
      // if (tokenData.subscriptions && tokenData.subscriptions.data) {
      //   let subscriberList = JSON.parse(JSON.stringify(tokenData.subscriptions.data));
      //   //console.log('subscriberList==', subscriberList);
      //   this.setState({ subscriberId: subscriberList[subscriberList.length - 1] ? subscriberList[subscriberList.length - 1].id : '' });
      // }
    });
  }

  deConnectShopify() {
    helper.successMessage("sucessfully disconnected");
    const db = firebase1.app.firestore();
    this.setState({
      shopifyUsername: "",
      shopifyPassword: "",
      shopifyStore: "",
      isShopify: false,
      shopifyModalIsOpen: false,
    });

    db.collection("user")
      .doc(this.state.key)
      .update({ shopify: null });
  }

  connectShopify() {
    const { shopifyPassword, shopifyUsername, shopifyStore } = this.state;
    if (shopifyUsername == "" || shopifyUsername == undefined) {
      helper.errorMessage("shopify api field is required");
    } else if (shopifyPassword == "" || shopifyPassword == undefined) {
      helper.errorMessage("shopify Password field is required");
    } else if (shopifyStore == "" || shopifyStore == undefined) {
      helper.errorMessage("shopify store name field is required");
    } else {
      let data = {
        username: shopifyUsername,
        password: shopifyPassword,
        domain: shopifyStore,
      };

      this.setState({ isLoading: true });
      api.postApi("shopify/onCheckApi", data).then((shopData) => {
        //console.log('shopData===', shopData);
        if (shopData.status == "Success") {
          const db = firebase1.app.firestore();
          helper.successMessage("sucessfully connected");
          db.collection("user")
            .doc(this.state.key)
            .update({ shopify: data });
          this.setState({ isLoading: false });
          //this.setState({ isShopify: true, isLoading: false, shopifyproductIsOpen: true });
        } else {
          helper.errorMessage(shopData.messages);
          this.setState({ isLoading: false });
        }
      });
    }
  }

  logoOnChange = async (e: ChangeEvent<HTMLInputElement>, index) => {
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0];
      //console.log(file);
      let that = this;
      try {
        this.setState({ file: file });
        const orientation = await getOrientation(file);
        var reader = new FileReader();
        reader.onload = function (e) {
          // that.setState({ 'logo_photo': e.target.result });
          if (that.fileInput1) {
            that.fileInput1.value = "";
          }
          if (that.fileInput2) {
            that.fileInput2.value = "";
          }
          if (that.fileInput3) {
            that.fileInput3.value = "";
          }
          if (that.fileInput4) {
            that.fileInput4.value = "";
          }
          that.rotatedImage(e.target.result, orientation, index);
        };
        reader.readAsDataURL(file);
      } catch (err) {
        //console.log(err);
      } finally {
        //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
      }
    }
  };

  async rotatedImage(imageDataUrl, orientation, index) {
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    // if (rotation) {
    //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
    // }
    this.setState({
      imageModalIsOpen: true,
      imageIndex: index,
      imageSrc: imageDataUrl,
      rotation: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
    });
  }

  async croppedImage() {
    ////console.log(this.state.croppedAreaPixels);
    ////console.log(this.state.rotation);
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    );
    let Images = this.state.Images;

    Images[this.state.imageIndex].file = croppedImage.file;
    Images[this.state.imageIndex].Url = croppedImage.url;

    console.log("Images===", Images);

    this.setState({ Images: Images, imageModalIsOpen: false });
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels });
    //////console.log(croppedArea, croppedAreaPixels)
  };

  closeImageModal = () => {
    if (this.fileInput1) {
      this.fileInput1.value = "";
    }
    if (this.fileInput2) {
      this.fileInput2.value = "";
    }
    if (this.fileInput3) {
      this.fileInput3.value = "";
    }
    if (this.fileInput4) {
      this.fileInput4.value = "";
    }
    this.setState({ imageModalIsOpen: false });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onRotate = () => {
    let rotation = this.state.rotation;
    if (rotation == 0) {
      rotation = 90;
    } else if (rotation == 90) {
      rotation = 180;
    } else if (rotation == 180) {
      rotation = 270;
    } else if (rotation == 270) {
      rotation = 360;
    } else if (rotation == 360) {
      rotation = 0;
    }
    this.setState({ rotation: rotation });
  };

  storeBranding() {
    const {
      customMsg,
      businessDescription,
      videoUrl,
      tagLine,
      fbProfile,
      instaProfile,
      brandName,
      selectTrade,
      markName,
      logo_photo,
      cover_photo,
      mark_photo,
      trademarkNo,
      selectRegion,
      Images,
    } = this.state;
    // if (Images[0].Url == '') {
    //   helper.errorMessage('Logo Image is required');
    // } else if (Images[1].Url == '') {
    //   helper.errorMessage('Cover Image is required');
    // }
    if (customMsg == "" || customMsg == undefined) {
      helper.errorMessage("Custom Message field is required");
    } else if (20 < customMsg.length) {
      helper.errorMessage(
        "Custom message field is maximum 20 characters allowed"
      );
    } else if (businessDescription == "" || businessDescription == undefined) {
      helper.errorMessage("Business Description field is required");
    } else if (1000 < businessDescription.length) {
      helper.errorMessage(
        "Business Description field is maximum 1000 characters allowed"
      );
    } else if (tagLine == "" || tagLine == undefined) {
      helper.errorMessage("Tagline field is required");
    } else if (videoUrl == "" || videoUrl == undefined) {
      helper.errorMessage("Video Url field is required");
    } else if (fbProfile == "" || fbProfile == undefined) {
      helper.errorMessage("Facebook Profile field is required");
    } else if (instaProfile == "" || instaProfile == undefined) {
      helper.errorMessage("Instagram Profile field is required");
    } else if (brandName == "" || brandName == undefined) {
      helper.errorMessage("Brand Name field is required");
    } else if (selectTrade == null) {
      helper.errorMessage("please select Trademark Type");
    } else if (
      selectTrade.value == "wordmark" &&
      (markName == "" || markName == undefined)
    ) {
      helper.errorMessage("Mark Name field is required");
    } else if (trademarkNo == "" || trademarkNo == undefined) {
      //  else if (selectTrade.value == 'designmark' && (Images[2].Url == '')) {
      //   helper.errorMessage('Mark Image is required');
      // }
      helper.errorMessage("Trademark Registration Number field is required");
    } else if (selectRegion == null) {
      helper.errorMessage("please select Region");
    } else {
      let that = this;

      let data = {
        logo_photo: Images[0].Url,
        cover_photo: Images[1].Url,
        custom_msg: customMsg,
        business_description: businessDescription,
        tag_line: tagLine,
        video_url: videoUrl,
        fb_profile: fbProfile,
        insta_profile: instaProfile,
        brand_name: brandName,
        select_trade: selectTrade,
        mark_name: markName,
        mark_photo: Images[2].Url,
        packaging_photo: Images[3].Url,
        trademark_no: trademarkNo,
        select_region: selectRegion,
      };
      //console.log(data);
      this.imageCount = 0;
      this.uploadImage(data);
      this.setState({ isLoading: true });
    }
  }

  onChangeDob = (date) => {
    console.log("date===", date);
    this.setState({ dateBirth: date });
  };

  fileW9OnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0];
      //console.log(file);
      let that = this;
      try {
        console.log("file==", file);
        if (file.type == "application/pdf") {
          this.setState({ fileW9Upload: file });
        } else {
          helper.errorMessage("please upload pdf file");
        }
      } catch (err) {
        //console.log(err);
      } finally {
        //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
      }
    }
  };

  async uploadW9Pdf(file) {
    this.setState({ isLoading: true });
    const w9Pdf = await this.uploadFirebaseImage(file, "w-9");
    const db = firebase1.app.firestore();
    await db
      .collection("user")
      .doc(this.state.key)
      .update({ w9Pdf: w9Pdf });
    helper.successMessage("Successfully uploaded");
    this.sendW9Pdf(w9Pdf);
  }

  sendW9Pdf(w9Pdf) {
    let apiPath = "";
    apiPath = "mail/onW9Details";

    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
      w9Pdf: w9Pdf,
      business_type: this.state.selectbusinessType,
      state: this.state.selectState,
      ein: this.state.einNumber,
      dob: this.state.dateBirth,
    };

    console.log("reqData===", reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log(data);
      if (data.status == "Success") {
      }
      this.setState({ isLoading: false });
    });
  }

  async taxInformation() {
    const {
      selectbusinessType,
      selectState,
      einNumber,
      dateBirth,
      fileW9Upload,
    } = this.state;

    if (selectbusinessType == null) {
      helper.errorMessage("Business Type field is required");
    } else if (selectState == null) {
      helper.errorMessage("State field is required");
    } else if (einNumber == "") {
      helper.errorMessage("EIN Number field is required");
    } else if (9 < einNumber.length) {
      console.log("einNumber", einNumber.length);
      helper.errorMessage("Please Enter Valid EIN Number");
    } else if (dateBirth == null || dateBirth == "") {
      helper.errorMessage(
        "Please Select Date of Birth of Owner/Representative"
      );
    } else if (fileW9Upload == "") {
      helper.errorMessage("please download and complete w-9 file");
    } else {
      this.setState({ isLoading: true });
      const db = firebase1.app.firestore();
      let data = {
        business_type: selectbusinessType,
        state: selectState,
        ein: einNumber,
        dob: dateBirth,
      };
      //helper.successMessage('Successfully updated');
      await db
        .collection("user")
        .doc(this.state.key)
        .update({ tax_information: data });
      //this.sendTaxInformation(data);
      this.uploadW9Pdf(fileW9Upload);
    }
  }

  sendTaxInformation(data) {
    let apiPath = "";
    apiPath = "mail/onTaxDetails";
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
      ...data,
    };
    console.log("reqData===", reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log(data);
      if (data.status == "Success") {
      }
      //this.setState({ isLoading: false });
    });
  }

  async uploadImage(data) {
    if (this.imageCount < this.state.Images.length) {
      if (
        this.state.Images[this.imageCount].file != "" &&
        this.state.Images[this.imageCount].file != undefined
      ) {
        const photoURL = await this.uploadFirebaseImage(
          this.state.Images[this.imageCount].file,
          "products"
        );
        //console.log('photoURL====', photoURL);
        this.state.Images[this.imageCount].Url = photoURL;
        this.state.Images[this.imageCount].file = "";
      }
      this.imageCount = this.imageCount + 1;
      this.uploadImage(data);
    } else {
      this.updateBranding(data);
    }
  }

  async updateBranding(data) {
    data.logo_photo = this.state.Images[0].Url;
    data.cover_photo = this.state.Images[1].Url;
    data.mark_photo = this.state.Images[2].Url;
    data.packaging_photo = this.state.Images[3].Url;

    let item = {
      branding: data,
    };
    //console.log(item);
    await this.props.updateRecord("user", item, this.state.key, false);
    helper.successMessage("sucessfully updated");
    this.setState({ isLoading: false });
  }

  async uploadFirebaseImage(file, collection) {
    try {
      let timeStamp = new Date().getTime();
      const spaceRef = firebase1.app
        .storage()
        .ref()
        .child(collection)
        .child(String(timeStamp));
      return spaceRef
        .put(file)
        .then((snapshot: firebase.storage.UploadTaskSnapshot) => {
          return snapshot.ref.getDownloadURL().then((downloadURL) => {
            return downloadURL;
          });
        });
    } catch (err) {
      ////console.log(err);
      return null;
    }
  }

  async addOrderEmail() {
    let email = this.state.orderEmail;
    let orderNotificationList = this.state.orderNotificationList;

    if (email == "" || email == undefined) {
      helper.errorMessage("Email field is required");
    } else if (email && !validEmail.test(email.replace(/ /g, ""))) {
      helper.errorMessage("Email invalid");
    } else {
      var emailData = this.state.orderNotificationList.find((data1) => {
        return data1.email === email;
      });
      console.log("emailData===", emailData);
      if (emailData) {
        helper.errorMessage("Already added");
      } else {
        orderNotificationList.push({ email: email, status: true });
        this.setState(
          {
            orderNotificationList: [...orderNotificationList],
            emailmodalIsOpen: false,
          },
          () => {
            this.resetOrderNotifications();
          }
        );
        const db = firebase1.app.firestore();
        await db
          .collection("user")
          .doc(this.state.key)
          .update({ order_notification_list: orderNotificationList });
      }
    }
  }

  resetOrderNotifications() {
    let orderNotificationList = this.state.orderNotificationList;
    let count = 1;
    for (let data in orderNotificationList) {
      orderNotificationList[data].count = count;
      orderNotificationList[data].key = count;
      count = count + 1;
    }
    this.setState({ orderNotificationList: [...orderNotificationList] });
  }

  updateProfile() {
    let date = new Date();
    let createdDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    //Must be at least six characters long

    const {
      selectSellType,
      selectShip,
      selectPaid,
      websiteLink,
      selectTime,
      role,
      firstname,
      profile_photo,
      file,
      company,
      email,
      password,
      login_type,
      warehouseName,
      phoneNumber,
      paypalEmail,
      bankAccount,
      bankRouting,
      bankName,
      returnAddress,
    } = this.state;

    if (firstname == "" || firstname == undefined) {
      helper.errorMessage("Firstname field is required");
    } else if (email == "" || email == undefined) {
      helper.errorMessage("Email field is required");
    } else if (email && !validEmail.test(email.replace(/ /g, ""))) {
      helper.errorMessage("Email invalid");
    } else if (phoneNumber == "") {
      helper.errorMessage("Phone field is required");
    } else if (company == "" || company == undefined) {
      helper.errorMessage("Company field is required");
    } else if (login_type == "" && (password == "" || password == undefined)) {
      helper.errorMessage("Password field is required");
    } else if (login_type == "" && password.length < 6) {
      helper.errorMessage("Password must be at least six characters long");
      // } else if (selectSellType == null) {
      //   helper.errorMessage("Please select sell type");
    } else if (selectShip == null) {
      helper.errorMessage("Please select ship orders");
    } else if (selectPaid == null) {
      // else if (warehouseName == '' && selectShip.value == 'myself') {
      //   helper.errorMessage('please enter warehouse name');
      // }
      helper.errorMessage("please select paid type");
    } else if (selectTime == null) {
      helper.errorMessage("please select Handle time");
    } else if (selectPaid && selectPaid.value === "ach" && !bankAccount) {
      helper.errorMessage("please enter a bank account");
    } else if (selectPaid && selectPaid.value === "ach" && !bankRouting) {
      helper.errorMessage("please enter a bank routing");
    } else if (selectPaid && selectPaid.value === "ach" && !bankName) {
      helper.errorMessage("please enter a bank name");
      // } else if (selectPaid && selectPaid.value === "paypal" && !paypalEmail) {
      //   helper.errorMessage("please enter a paypal email");
    } else {
      let that = this;
      const db = firebase1.app.firestore();

      let date = new Date();
      let createdDate =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

      let paymentDetails;
      if (selectPaid && selectPaid.value === "ach") {
        paymentDetails = {
          bankAccount,
          bankRouting,
          bankName,
        };
      }
      if (selectPaid && selectPaid.value === "paypal") {
        paymentDetails = { paypalEmail: paypalEmail || '' };
      }

      let data = {
        firstname: firstname,
        email: email,
        role: role,
        profile_photo: profile_photo,
        company: company,
        password: password,
        phone_number: phoneNumber,
        file: file,
        updated_at: createdDate,
        sell_type: selectSellType,
        ship_orders: selectShip,
        paid_type: selectPaid,
        handle_time: selectTime,
        website_link: websiteLink,
        warehouse_name: "",
        paymentDetails,
        returnAddress: returnAddress || '',
      };
      //console.log(data);
      that.setState({ isLoading: true });

      db.collection("user")
        .where("email", "==", email)
        .get()
        .then(function (querySnapshot) {
          //console.log('querySnapshot===', querySnapshot.size);
          if (querySnapshot.size == 0) {
            // that.checkWarehouse(db, data);
            that.updateAction(db, data);
          } else {
            if (that.state.tempemail == that.state.email) {
              // that.checkWarehouse(db, data);
              that.updateAction(db, data);
            } else {
              that.setState({ isLoading: false });
              helper.errorMessage("This email already taken");
            }
          }
        });
    }
  }

  async checkWarehouse(db, data) {
    //console.log('this.state.warehouseName===', this.state.warehouseName, this.state.tempWarehouseName);
    if (
      data.ship_orders &&
      data.ship_orders.value == "myself" &&
      this.state.warehouseName != this.state.tempWarehouseName
    ) {
      let reqData = {
        Name: this.state.warehouseName,
        ContactInformation: {
          FullName: this.state.firstname,
          EmailAddress: this.state.email,
        },
      };
      api.postEcomDashApi("Warehouses", reqData).then((warehouseData) => {
        //console.log('warehouseData===', warehouseData);
        if (warehouseData.status == "Success") {
          // helper.successMessage('');
          data.warehouse_id = warehouseData.data.Id;
          this.updateAction(db, data);
        } else {
          if (warehouseData && warehouseData.messages.length > 0) {
            helper.errorMessage(
              warehouseData.messages[warehouseData.messages.length - 1]
            );
          }
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.updateAction(db, data);
    }
  }

  async updateAction(db, data) {
    try {
      if (this.state.tempemail != this.state.email) {
        await firebase1.app.auth().currentUser.updateEmail(this.state.email);
        //console.log(this.state.key, data);
        await db
          .collection("user")
          .doc(this.state.key)
          .update(data);
        localStorage.removeItem("role");
        localStorage.removeItem("addProductSeen");
        localStorage.removeItem("/");
        localStorage.removeItem("inventorySeen");
        localStorage.removeItem("salesSeen");
        localStorage.removeItem("dashboardSeen");
        localStorage.removeItem("topRightMenuSeen");
        await firebase1.app.auth().signOut();

        this.setState({ isLoading: false });
        helper.successMessage("sucessfully email changed. please login again");
        history.push("/login");
      } else {
        if (this.state.key && this.state.key != "") {
          await this.props.updateRecord("user", data, this.state.key, false);
          helper.successMessage("sucessfully updated");
        }

        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      if (error && error.message) {
        helper.errorMessage(error.message);
        //console.log('error==', error);
      }
    }
    //this.props.updateRecord('user',data,this.state.key,false);
  }

  handleToken(billing, type) {
    const {
      changeCardNumber,
      roleName,
      role,
      firstname,
      profile_photo,
      file,
      company,
      email,
      password,
      login_type,
    } = this.state;

    let date = new Date();
    let createdDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

    let data = {
      firstname: firstname,
      email: email,
      role: role,
      profile_photo: profile_photo,
      company: company,
      password: password,
      file: file,
      updated_at: createdDate,
      billing: billing,
    };

    if (type == "delete") {
      this.setState({ billing: billing });
    }
    // const db = firebase1.app.firestore();
    // db.collection('user').doc(this.state.key).update(data);
    this.props.updateRecord("user", data, this.state.key, false);
  }

  photoOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length > 0) {
      const file: File = e.target.files[0];
      //console.log(file);
      let that = this;
      try {
        this.setState({ file: file });
        var reader = new FileReader();
        reader.onload = function (e) {
          that.setState({ profile_photo: e.target.result });
        };
        reader.readAsDataURL(file);
      } catch (err) {
        //console.log(err);
      } finally {
        //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
      }
    }
  };

  cancelSubscribe() {
    this.setState({ suscribermodalIsOpen: true });
  }

  openEmail() {
    this.setState({ emailmodalIsOpen: true });
  }

  closeSubscriberModal() {
    this.setState({ suscribermodalIsOpen: false });
  }

  closeEmailModal() {
    this.setState({ emailmodalIsOpen: false });
  }

  shopifySearchKey(text) {
    this.setState({ shopifySearchText: text }, () => {
      if (text.length != 0) {
        this.shopifyGetProducts(1);
      } else if (text.length == 0) {
        this.setState({ shopifySearchText: "" });
        this.shopifyGetProducts(1);
      }
    });
  }

  shopifyGetProducts(page, pageSize) {
    this.setState({ tableLoading: true });
    if (
      (this.state.shopifySearchText && this.state.allShopifyProducts) ||
      (Boolean(pageSize) && pageSize !== 1)
    ) {
      let results = [];
      let products = this.state.allShopifyProducts;
      for (let i in products) {
        if (products[i].title) {
          if (
            products[i].title
              .toLowerCase()
              .includes(this.state.shopifySearchText.toLowerCase())
          ) {
            results.push(products[i]);
          }
        }
      }
      let end =
        (page - 1) * (pageSize || this.state.pageSize) + pageSize ||
        this.state.pageSize;
      let i = (page - 1) * (pageSize || this.state.pageSize);
      let visibleProducts = [];
      for (i; i < end; i++) {
        if (results[i]) {
          visibleProducts.push(results[i]);
        }
      }
      let totalPages = Math.ceil(
        results.length / (pageSize || this.state.pageSize)
      );
      this.pageInfoNext = page + 1;
      this.pageInfoPrev = "";
      this.setState(
        {
          tableLoading: false,
          shopifyProducts: visibleProducts,
          totalPage: totalPages,
        },
        () => {
          this.updateCartShopify();
        }
      );
    } else {
      if (this.state.allShopifyProducts) {
        let totalPages = Math.ceil(
          this.state.allShopifyProducts.length / this.state.pageSize
        );
        let i = (page - 1) * this.state.pageSize;
        let visibleProducts = [];
        let end = (page - 1) * this.state.pageSize + this.state.pageSize;
        if (this.state.allShopifyProducts.length < end) {
          end = this.state.allShopifyProducts.length;
        }
        for (i; i < end; i++) {
          visibleProducts.push(this.state.allShopifyProducts[i]);
        }
        if (page == 1) {
          this.pageInfoNext = 2;
        } else {
          this.pageInfoNext = page + 1;
          this.pageInfoPrev = page - 1;
        }
        this.setState(
          {
            tableLoading: false,
            shopifyProducts: visibleProducts,
            totalPage: totalPages,
          },
          () => {
            this.updateCartShopify();
          }
        );
      } else {
        const { shopifyUsername, shopifyPassword, shopifyStore } = this.state;
        let data = {
          username: shopifyUsername,
          password: shopifyPassword,
          domain: shopifyStore,
        };
        api.postApi("shopify/getProducts", data).then((shopData) => {
          console.log("shopData===", shopData);
          let products = [];
          let totalPages = 1;
          if (shopData.status == "Success") {
            if (shopData.data) {
              products = shopData.data;
              totalPages = Math.ceil(products.length / this.state.pageSize);
              let visibleProducts = [];
              let i = 0;
              let visible = 10;
              if (products.length < 10) {
                visible = products.length;
              }
              for (i; i < visible; i++) {
                visibleProducts.push(products[i]);
              }
              this.pageInfoNext = 2;
              this.tempPage = 0;
              this.setState(
                {
                  allShopifyProducts: products,
                  totalPage: totalPages,
                  shopifyProducts: visibleProducts,
                  tableLoading: false,
                },
                () => {
                  this.updateCartShopify();
                }
              );
            }
          } else {
            helper.errorMessage(shopData.messages);
            this.setState({ tableLoading: false });
          }
        });
      }
    }
  }

  updateCartShopify() {
    let shopifyProducts = this.state.shopifyProducts;
    for (let item in shopifyProducts) {
      if (this.state.IsSelectAllShopify) {
        shopifyProducts[item].marked = true;
      } else {
        shopifyProducts[item].marked =
          this.shopifyAddProductId.indexOf(shopifyProducts[item].id) == -1
            ? false
            : true;
      }
    }
    this.setState({ shopifyProducts: [...shopifyProducts] });
  }

  addShopifyProduct(id) {
    let items = this.state.shopifyProducts;
    let index = helper.getItemIndex(items, "id", id);
    items[index].marked = !items[index].marked;
    if (items[index].marked) {
      this.shopifyAddProductId.push(id);
    } else {
      this.shopifyAddProductId.splice(this.shopifyAddProductId.indexOf(id), 1);
    }
    this.setState({ shopifyProducts: [...items] });
  }

  uploadShopifyProducts() {
    if (
      this.shopifyAddProductId.length == 0 &&
      !this.state.IsSelectAllShopify
    ) {
      helper.errorMessage("please add one products");
    } else {
      this.setState({ isLoading: true, shopifyproductIsOpen: false });
      api
        .postApi("inventory/shopifyAddProducts", {
          products: this.shopifyAddProductId,
          Uid: this.state.uid,
          select_all: this.state.IsSelectAllShopify,
        })
        .then((shopData) => {
          if (shopData.status == "Success") {
            helper.successMessage("Products have been added as draft.");
            let shopifyProducts = this.state.shopifyProducts;
            for (let item in shopifyProducts) {
              shopifyProducts[item].marked = false;
            }
            this.setState({
              isLoading: false,
              shopifyproductIsOpen: false,
              shopifyProducts: [...shopifyProducts],
            });
          } else {
            helper.errorMessage(shopData.messages);
            this.setState({ isLoading: false });
          }
        });
    }
  }

  actionShopify() {
    this.shopifyAddProductId = [];
    this.setState({ shopifyproductIsOpen: true, IsSelectAllShopify: false });
  }
  selectShopifyAllProducts() {
    let shopifyProducts = this.state.shopifyProducts;

    for (let item in shopifyProducts) {
      shopifyProducts[item].marked = !this.state.IsSelectAllShopify;
    }
    this.setState({
      shopifyProducts: [...shopifyProducts],
      IsSelectAllShopify: !this.state.IsSelectAllShopify,
    });
  }

  closeShopifyModel() {
    this.setState({ shopifyModalIsOpen: false });
  }

  handleBusinessChange = (selectbusinessType) => {
    this.setState({ selectbusinessType });
  };

  handleStateChange = (selectState) => {
    this.setState({ selectState });
  };

  upgradePlan(planid) {
    console.log("planid==", planid);
  }

  changeTab(index) {
    console.log("index===", index);
    let url = "";
    if (index == 0) {
      url = "/pages/profile";
    } else if (index == 1) {
      url = "/pages/integrations";
    } else if (index == 2) {
      url = "/pages/marketplaces";
    } else if (index == 3) {
      url = "/pages/transaction";
      this.handleFetchTransactions(this.props.userdetails);
    } else if (index == 4) {
      url = "/pages/branding";
    } else if (index == 5) {
      url = "/pages/subscription";
    } else if (index == 6) {
      url = "/pages/notification";
    } else if (index == 7) {
      url = "/pages/tax";
    }
    this.setState({ tabIndex: index });
    window.history.pushState("page2", "Valet Seller", url);
  }

  onCancelWalkthrough = () => {
    this.setState({ isTourActive: false });
    this.props.addWalkthroughFlag();
  };
  onNext = (step) => {
    if (step == 2) {
      this.changeTab(2);
      this.setState({ tourStep: step });
    } else if (step == 3) {
      this.setState({
        isTourActive: false,
      });
      history.push("sales");
    } else {
      this.setState({
        isTourActive: false,
      });
    }
  };

  syncInventoryLevels = () => {
    this.setState({ syncInventoryLevelLoading: true });
    api
      .postApi("shopify/InventorySync", {
        Uid: this.state.uid,
      })
      .then((responseData) => {
        if (responseData.status == "Success") {
          helper.successMessage(responseData.message);
        } else {
          helper.errorMessage(responseData.message);
        }
        this.setState({
          syncInventoryLevelLoading: false,
        });
      });
  };

  handleInventoryLevelSyncOptin = async (e) => {
    e.preventDefault();
    this.setState({ syncInventoryLevelSyncOptin: e.target.checked });
    const db = firebase1.app.firestore();
    await db
      .collection("user")
      .doc(this.state.key)
      .update({ syncInventoryLevelSyncOptin: e.target.checked });
  };

  handleSyncOrdersToMyShopify = async (e) => {
    e.preventDefault();
    this.setState({ syncOrdersToMyShopify: e.target.checked });
    const db = firebase1.app.firestore();
    await db
      .collection("user")
      .doc(this.state.key)
      .update({ syncOrdersToMyShopify: e.target.checked });
  };

  handleFetchTransactions(userObj) {
    if (!userObj || !userObj.uid || userObj.admin) return false;
    this.props.getTransactionHistory({ Uid: userObj.uid });
  }

  render() {
    const {
      tabIndex,
      cartList,
      maxDate,
      selectPlan,
      selectMonth,
      isAgree,
      feedBack,
      websiteLink,
      isElse,
      isSell,
      isSales,
      isProduct,
      isExpensive,
      firstStep,
      secondStep,
      thirdStep,
      activePlan,
      activestartTime,
      lastBillingDate,
      nextBillingDate,
      subscribtionItems,
      roleName,
      shopifyUsername,
      shopifypassword,
      isShopify,
      marketName,
      billing,
      selectSellType,
      selectShip,
      selectTrade,
      selectCountry,
      selectRegion,
      selectPaid,
      selectTime,
      warehouseName,
      selectbusinessType,
      selectState,
      skuCount,
      syncInventoryLevelLoading,
      syncInventoryLevelSyncOptin,
      syncOrdersToMyShopify,
      paypalEmail,
      bankAccount,
      bankRouting,
      bankName,
      returnAddress,
    } = this.state;
    const { transactionItems } = this.props;

    const isAnnualPlan = (this.props.userdetails && this.props.userdetails.paypal_billing && this.props.userdetails.paypal_billing.plan_id) === 'Annual Subscription';
    console.log('hahah', isAnnualPlan)

    return (
      <Col md={12}>
        {this.state.isLoading == true && (
          <div
            className="load"
            style={{
              backgroundColor: "transparent",
              width: "85%",
              height: "50%",
            }}
          >
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path
                  fill="#6c6f7f"
                  d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                />
              </svg>
            </div>
          </div>
        )}
        <div className="overlayIntro resizePop resizePop_brand productpop2 ">
          <Tour
            active={this.state.isTourActive}
            step={this.state.tourStep}
            onNext={(step) => this.onNext(step)}
            onBack={(step) => this.setState({ tourStep: step })}
            onCancel={() => this.onCancelWalkthrough()}
            hideSteps={true}
            steps={[
              {
                step: 1,
                selector: ".step_branding",
                title: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  />
                ),
                body: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                      marginBottom: "40px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    {BRANDING}
                  </div>
                ),
              },
              {
                step: 2,
                selector: ".marketplace_plan",
                title: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  />
                ),
                body: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    {MARKETING_PLACE_PLAN}
                  </div>
                ),
              },
              {
                step: 3,
                selector: ".final",
                title: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  />
                ),
                body: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    {FINISHED}
                  </div>
                ),
              },
            ]}
          />
        </div>

        {this.state.isLoading == false && (
          <Tabs
            defaultIndex={tabIndex}
            selectedIndex={tabIndex}
            onSelect={(index) => this.changeTab(index)}
            id="transaction__history"
          >
            <TabList>
              <Tab>Profile</Tab>
              <Tab>Integrations</Tab>
              {/* <Tab>Payment Plan</Tab> */}
              <Tab>Transaction History</Tab>
              <Tab>Branding</Tab>
              <Tab>Subscription & Purchases</Tab>
              <Tab>Order Notification</Tab>
              <Tab>Tax Information</Tab>
              <Tab>Upc Information</Tab>
            </TabList>

            <TabPanel>
              {this.state.isLoading == false && (
                <Card>
                  <CardBody>
                    {/* <div id="payment-request-button" className="cancelsub-div">
                  {this.state.subscriberId != '' && <button className="btn addbtn cancelsub" onClick={() => { this.cancelSubscribe() }}> Cancel Subscription</button>}
                </div> */}
                    <div className="form__form-group-field left">
                      <span>
                        <label>
                          Name<span className="mantory">*</span>
                        </label>
                        <input
                          name="name"
                          type="email"
                          placeholder=""
                          value={this.state.firstname}
                          onChange={(event) => {
                            this.setState({ firstname: event.target.value });
                          }}
                        />
                      </span>
                    </div>

                    <div className="form__form-group-field left">
                      <span>
                        <label>
                          Email<span className="mantory">*</span>
                        </label>
                        {/*<ReactTooltip place="bottom" />*/}
                        <input
                          // data-tip="hello world"
                          name="name"
                          type="email"
                          placeholder=""
                          value={this.state.email}
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                          }}
                        />
                      </span>
                    </div>

                    {this.state.login_type != "facebook" && (
                      <div className="form__form-group-field right">
                        <span>
                          <label>
                            Password<span className="mantory">*</span>
                          </label>
                          <input
                            name="name"
                            type="password"
                            placeholder=""
                            value={this.state.password}
                            onChange={(event) => {
                              this.setState({ password: event.target.value });
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <div className="form__form-group-field right">
                      <span>
                        <label>
                          Phone Number<span className="mantory">*</span>
                        </label>
                        <input
                          name="name"
                          type="number"
                          placeholder=""
                          value={this.state.phoneNumber}
                          onChange={(event) => {
                            this.setState({ phoneNumber: event.target.value });
                          }}
                        />
                      </span>
                    </div>
                    <div className="form__form-group-field right">
                      <span>
                        <label>
                          Company Name<span className="mantory">*</span>
                        </label>
                        <input
                          name="name"
                          type="text"
                          placeholder=""
                          value={this.state.company}
                          onChange={(event) => {
                            this.setState({ company: event.target.value });
                          }}
                        />
                      </span>
                    </div>
                    <div
                      className="form__form-group-field profile_image"
                      style={{ marginBottom: 10 }}
                    >
                      <div className="uploaddiv">
                        {this.state.profile_photo != "" && (
                          <img className="" src={this.state.profile_photo} />
                        )}
                        <p>Profile Image</p>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            this.photoOnChange(e)
                          }
                        />
                      </div>
                    </div>

                    <div className="profile_select">
                      {/* {this.state.selectSellType.map(o => o.value) == 'Walmart' && 

                  <div className="">
                    <button>Close</button>
                  </div>

                  } */}

                      <div className="form-group col-md-12">
                        <Label>
                          How Will You Ship Orders?
                          <span className="mantory">*</span>
                        </Label>
                        <Select
                          value={selectShip}
                          onChange={this.handleShipChange}
                          options={shipList}
                        />
                      </div>
                      {/* {selectShip && selectShip.value == 'myself' && <div className="form__form-group-field warehouse_input">
                    <span>
                      <Label>Warehouse Name</Label>
                      <input name="shortdescription" type="text" placeholder="" value={warehouseName}
                        onChange={(event) => { this.setState({ warehouseName: event.target.value }) }} />
                    </span>
                  </div>} */}
                      <div className="form-group col-md-12">
                        <Label>
                          How Will You Get Paid?
                          <span className="mantory">*</span>
                        </Label>
                        <Select
                          value={selectPaid}
                          onChange={this.handlePaidChange}
                          options={paidList}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <Label>
                          Handling time<span className="mantory">*</span>
                        </Label>
                        <Select
                          value={selectTime}
                          onChange={this.handleTimeChange}
                          options={timeList}
                        />
                      </div>
                      <div className="form-group col-md-12 warehousename">
                        <Label>Company Website Link</Label>
                        <input
                          name="shortdescription"
                          type="text"
                          placeholder=""
                          value={websiteLink}
                          onChange={(event) => {
                            this.setState({ websiteLink: event.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group col-md-12 warehousename">
                        <Label>Return Address</Label>
                        <input
                          name="shortdescription"
                          type="text"
                          placeholder="Return Address"
                          value={returnAddress}
                          onChange={(event) => {
                            this.setState({
                              returnAddress: event.target.value,
                            });
                          }}
                        />
                      </div>
                      {selectPaid && selectPaid.value === "paypal" && (
                        <div className="form-group col-md-12 warehousename">
                          <Label>Paypal Email</Label>
                          <input
                            name="paypalEmail"
                            type="text"
                            placeholder="Paypal Email"
                            value={paypalEmail}
                            onChange={this.handleChange}
                          />
                        </div>
                      )}
                      {selectPaid && selectPaid.value === "ach" && (
                        <div>
                          <div className="form-group col-md-12 warehousename">
                            <Label>Bank Account</Label>
                            <input
                              name="bankAccount"
                              type="text"
                              placeholder="Bank Account"
                              value={bankAccount}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12 warehousename">
                            <Label>Bank Routing</Label>
                            <input
                              name="bankRouting"
                              type="text"
                              placeholder="Bank Routing"
                              value={bankRouting}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group col-md-12 warehousename">
                            <Label>Bank Name</Label>
                            <input
                              name="bankName"
                              type="text"
                              placeholder="Bank Name"
                              value={bankName}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="form_footer">
                      {/* <Link to='/pages/user'>
												<button className='btn addbtn cancelbtn'>Cancel</button>
											</Link> */}
                      <button
                        className="btn addbtn update-btn"
                        onClick={() => {
                          this.updateProfile();
                        }}
                      >
                        Update
                      </button>
                    </div>
                    {/* <div className="form__form-group-field right">
                    <span>
                      <label>Profile Image</label>
                      <span className="profileimage">
                        <img className="" src="../img/pro_img.jpg" ></img>
                      </span>
                      <input name="name" type="file" placeholder=""/>
                    </span>
                </div> */}

                    <div id="payment-request-button" />
                  </CardBody>
                </Card>
              )}
            </TabPanel>
            <TabPanel>
              <div className="card__title" style={{ marginTop: 40 }}>
                <h5 className="bold-text">
                  Integrations
                  {isShopify && <p className="connected">( Connected )</p>}
                  {!isShopify && (
                    <p className="not_connected">( Not Connected )</p>
                  )}
                  <button
                    className="btn video_btn"
                    onClick={this.openvideoModal}
                  >
                    Shopify Integration Tutorial
                  </button>
                </h5>
              </div>

              <div className="form__form-group-field left">
                <span>
                  <label>
                    API Key<span className="mantory">*</span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    placeholder=""
                    value={this.state.shopifyUsername}
                    onChange={(event) => {
                      this.setState({ shopifyUsername: event.target.value });
                    }}
                  />
                </span>
              </div>

              <div className="form__form-group-field right">
                <span>
                  <label>
                    Password (Secret Key)<span className="mantory">*</span>
                  </label>
                  <input
                    name="shopifyname"
                    type="password"
                    placeholder=""
                    value={this.state.shopifyPassword}
                    onChange={(event) => {
                      this.setState({ shopifyPassword: event.target.value });
                    }}
                    autoComplete="new-password"
                  />
                </span>
              </div>

              <div className="form__form-group-field right">
                <span className="prefixtext">
                  <label>
                    Store Name<span className="mantory">*</span>
                  </label>
                  <span className="prefix">https://</span>
                  <input
                    name="name"
                    type="text"
                    className="prefixinput"
                    placeholder=""
                    value={this.state.shopifyStore}
                    onChange={(event) => {
                      this.setState({ shopifyStore: event.target.value });
                    }}
                  />
                  <span className="suffix">.myshopify.com</span>
                  {/* <NumberFormat type={'text'} isNumericString={true} value={this.state.shopifyStore} prefix={'https://'} suffix={'.myshopify.com'}
                      onValueChange={(values) => {//console.log('===========',values)}}/> */}
                </span>
              </div>

              <div className="form_footer">
                <span className="question_name">
                  <div class="product_options">
                    <input
                      name="options"
                      type="checkbox"
                      placeholder=""
                      checked={
                        syncInventoryLevelSyncOptin === undefined
                          ? true
                          : syncInventoryLevelSyncOptin
                      }
                      onChange={this.handleInventoryLevelSyncOptin}
                    />
                    <span>Sync inventory levels from my shopify</span>
                  </div>
                  <div
                    class="product_options sync_orders"
                    style={{ marginLeft: "1%" }}
                  >
                    <input
                      name="options"
                      type="checkbox"
                      placeholder=""
                      checked={
                        syncOrdersToMyShopify === undefined
                          ? true
                          : syncOrdersToMyShopify
                      }
                      onChange={this.handleSyncOrdersToMyShopify}
                    />
                    <span>Sync orders to my shopify</span>
                  </div>
                  <button className="btn ques_btn" onClick={this.openModal}>
                    <img
                      className="tooltips question_mark"
                      src="../../img/info.png"
                      data-tip="Universal Product Code"
                    />
                  </button>
                  {!isShopify && (
                    <button
                      className="btn addbtn update-btn"
                      onClick={() => {
                        this.connectShopify();
                      }}
                    >
                      Connect Shopify
                    </button>
                  )}
                  {isShopify && (
                    <button
                      className="btn addbtn disconnect-btn"
                      onClick={() => {
                        this.setState({ shopifyModalIsOpen: true });
                      }}
                    >
                      Disconnect Shopify
                    </button>
                  )}
                  {isShopify && (
                    <button
                      className="btn addbtn update-btn"
                      onClick={() => {
                        this.actionShopify();
                      }}
                    >
                      Sync Products
                    </button>
                  )}
                  {isShopify && (
                    <button
                      className="btn addbtn update-btn"
                      onClick={() => {
                        this.syncInventoryLevels();
                      }}
                      disabled={syncInventoryLevelLoading}
                    >
                      {syncInventoryLevelLoading
                        ? "please wait"
                        : "Sync Inventory Levels"}
                    </button>
                  )}

                  {/* {(isShopify &&this.state.uid=='hbT1SmQibMahUGkeH0wr5Eqx9Up1')&& <button className="btn addbtn update-btn" onClick={() => { this.setState({ shopifyproductIsOpen: true }) }}>Sync Products</button>} */}
                </span>
              </div>
            </TabPanel>

            {/* <TabPanel>
              <div className="marketplaces_sku">
                <Pricing
                  activePlan={this.state.activePlan}
                  changeTab={this.changeTab}
                  paypalOnError={this.paypalOnError}
                  paypalOnApprove={this.paypalOnApprove}
                  paypalSubscribe={this.paypalSubscribe}
                />
              </div>
            </TabPanel> */}

            <TabPanel>
              <div className="card__title" style={{ marginTop: 40 }}>
                <h5 className="bold-text">Transaction History</h5>
              </div>
              <TableList
                normal={true}
                items={transactionItems}
                headers={this.state.transactionHeaders}
                name={this.state.name}
                className="myprodList"
                company={this.state.company}
                filename="transactionhistory"
              />
            </TabPanel>
            <TabPanel>
              <ReactTooltip />
              {this.state.isLoading == false && (
                <div className="card__title branding_whole">
                  <Col lg={6} md={12} sm={12} className="branding_whole_left">
                    <h4>Store Branding</h4>
                    <Col md={12} className="branding_div">
                      <Col md={6} className="branding_left branding_left_image">
                        <h4>
                          Logo Image{" "}
                          <img
                            className="tooltips"
                            src="../../img/info.png"
                            data-tip="This is the logo of your storefront."
                          />
                        </h4>
                        {/* <p></p> */}
                        {/* <p>.</p> */}
                        {this.state.Images[0].Url != "" && (
                          <img
                            className="logo_photo"
                            src={this.state.Images[0].Url}
                          />
                        )}
                        <input
                          type="file"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            this.logoOnChange(e, 0)
                          }
                          ref={(ref) => (this.fileInput1 = ref)}
                          accept="image/*"
                        />
                        <img
                          className="tooltips"
                          src="../../img/info.png"
                          data-tip="Choose a Square JPG image at least 130 X 130px."
                        />
                      </Col>
                      <Col
                        md={6}
                        className="branding_right branding_left_image"
                      >
                        <h4>
                          Cover Image{" "}
                          <img
                            className="tooltips"
                            src="../../img/info.png"
                            data-tip="This is the main image on your storefront."
                          />
                        </h4>
                        {/* <p>This is the main image on your storefront.</p>
                      <p>Choose a rectangular JPG image at least 1200 X 250px.</p> */}
                        {this.state.Images[1].Url != "" && (
                          <img
                            className="logo_photo"
                            src={this.state.Images[1].Url}
                          />
                        )}
                        <input
                          type="file"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            this.logoOnChange(e, 1)
                          }
                          ref={(ref) => (this.fileInput2 = ref)}
                          accept="image/*"
                        />
                        <img
                          className="tooltips"
                          src="../../img/info.png"
                          data-tip="Choose a rectangular JPG image at least 1200 X 250px."
                        />
                      </Col>
                      <Col
                        md={12}
                        className="branding_right branding_left_image"
                      >
                        <h4>
                          Packaging Image{" "}
                          <img
                            className="tooltips"
                            src="../../img/info.png"
                            data-tip="This is the main image on your storefront."
                          />
                        </h4>
                        {/* <p>This is the main image on your storefront.</p>
                      <p>Choose a rectangular JPG image at least 1200 X 250px.</p> */}
                        {this.state.Images[3].Url != "" && (
                          <img
                            className="logo_photo"
                            src={this.state.Images[3].Url}
                          />
                        )}
                        <input
                          type="file"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            this.logoOnChange(e, 3)
                          }
                          ref={(ref) => (this.fileInput4 = ref)}
                          accept="image/*"
                        />
                        <img
                          className="tooltips"
                          src="../../img/info.png"
                          data-tip="Choose a rectangular JPG image at least 1200 X 250px."
                        />
                      </Col>
                    </Col>

                    <Col md={12} className="branding_div">
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            Your Custom Message{" "}
                            <span className="mantory">*</span>{" "}
                            <img
                              className="tooltips"
                              src="../../img/info.png"
                              data-tip="This is the Message that is displayed on your storefront."
                            />
                          </h4>
                          {/* <p>This is the Message that is displayed on your storefront.</p> */}
                          <p>(maximum 20 characters)</p>
                          <input
                            type="text"
                            placeholder=""
                            style={{ width: "100%" }}
                            value={this.state.customMsg}
                            onChange={(event) => {
                              this.setState({ customMsg: event.target.value });
                            }}
                            maxlength="20"
                          />
                        </span>
                      </div>
                    </Col>

                    <Col md={12} className="branding_div">
                      <h4>Business Description</h4>
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            Your Story <span className="mantory">*</span>
                            <span>(maximum 1000 characters)</span>
                          </h4>
                          <textarea
                            type="text"
                            style={{ width: "100%", padding: 10 }}
                            rows="5"
                            value={this.state.businessDescription}
                            onChange={(event) => {
                              this.setState({
                                businessDescription: event.target.value,
                              });
                            }}
                            maxlength="1000"
                          />
                        </span>
                      </div>
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            Your Tagline<span className="mantory">*</span>
                          </h4>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.tagLine}
                            onChange={(event) => {
                              this.setState({ tagLine: event.target.value });
                            }}
                          />
                        </span>
                      </div>
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            YouTube Video URL <span className="mantory">*</span>
                            <span>(help us get to know you)</span>
                          </h4>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.videoUrl}
                            onChange={(event) => {
                              this.setState({ videoUrl: event.target.value });
                            }}
                          />
                        </span>
                      </div>
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            Facebook Profile<span className="mantory">*</span>
                          </h4>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.fbProfile}
                            onChange={(event) => {
                              this.setState({ fbProfile: event.target.value });
                            }}
                          />
                        </span>
                      </div>
                      <div className="form__form-group-field left branding_left">
                        <span>
                          <h4>
                            Instagram Profile<span className="mantory">*</span>
                          </h4>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.instaProfile}
                            onChange={(event) => {
                              this.setState({
                                instaProfile: event.target.value,
                              });
                            }}
                          />
                        </span>
                      </div>
                      {/* <div className="form__form-group-field left branding_left">
                      <span className="save_btn">
                        <button className="btn addbtn">Save</button>
                      </span>
                    </div> */}
                    </Col>
                  </Col>

                  <Col lg={6} md={12} sm={12} className="branding_whole_right">
                    <h4 className="step_brandings">Register Your Brand</h4>
                    <p className="branding_whole_right_head">
                      Protect your brand and intellectual property and create an
                      accurate and trusted experience for customers
                    </p>

                    <Col md={12} className="branding_div">
                      <div className="form__form-group-field left branding_left brand_registered">
                        <span>
                          <h4>
                            Brand Name to Be Registered
                            <span className="mantory">*</span>
                          </h4>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            value={this.state.brandName}
                            onChange={(event) => {
                              this.setState({ brandName: event.target.value });
                            }}
                          />
                        </span>
                      </div>

                      <div className="trademark_type">
                        <span className="trademark_type_span">
                          Acceptable trademark types
                        </span>
                        <ul>
                          <li>
                            <p>
                              Word marks and design trademarks that contain
                              words, letters or numbers
                            </p>
                          </li>
                          <li>
                            <p>
                              Brand name on your application must match the name
                              on your Trademark record.
                            </p>
                          </li>
                        </ul>
                        <span className="trademark_type_p">
                          To request a design mark, please select design mark in
                          the drop down and attach an image that matches the
                          image on your Trademark record.
                        </span>
                      </div>

                      <div className="trademark">
                        <div className="form__form-group-field left branding_left">
                          <span>
                            <h4>
                              Trademark type<span className="mantory">*</span>
                            </h4>
                            <Select
                              value={selectTrade}
                              onChange={this.handletradeChange}
                              options={tradetypeList}
                              placeholder="Select trademark type"
                            />
                          </span>
                        </div>
                        {selectTrade && selectTrade.value == "wordmark" && (
                          <div className="form__form-group-field left branding_left">
                            <span>
                              <h4>
                                Mark name<span className="mantory">*</span>
                              </h4>
                              <input
                                type="text"
                                style={{ width: "100%" }}
                                value={this.state.markName}
                                onChange={(event) => {
                                  this.setState({
                                    markName: event.target.value,
                                  });
                                }}
                              />
                            </span>
                          </div>
                        )}

                        {selectTrade && selectTrade.value == "designmark" && (
                          <Col md={12} className="branding_right">
                            <h4>Mark Image </h4>
                            {/* <p>This is the main image on your storefront.</p>
                        <p>Choose a rectangular JPG image at least 1200 X 250px.</p> */}
                            {this.state.Images[2].Url != "" && (
                              <img
                                className="logo_photo"
                                src={this.state.Images[2].Url}
                              />
                            )}
                            <input
                              type="file"
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                this.logoOnChange(e, 2)
                              }
                              ref={(ref) => (this.fileInput3 = ref)}
                              accept="image/*"
                            />
                          </Col>
                        )}

                        <div className="form_branding_left left branding_left">
                          <span>
                            <h4>
                              Trademark registration number
                              <span className="mantory">*</span>
                            </h4>
                            <input
                              type="text"
                              style={{ width: "100%" }}
                              value={this.state.trademarkNo}
                              onChange={(event) => {
                                this.setState({
                                  trademarkNo: event.target.value,
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="form_branding_right left branding_left">
                          <span>
                            <h4>
                              Trademark office<span className="mantory">*</span>
                            </h4>
                            <Select
                              value={selectRegion}
                              onChange={this.handlecountryChange}
                              options={regionList}
                              placeholder="Select country/region"
                            />
                          </span>
                        </div>
                        <div className="form__form-group-field left branding_left">
                          <span className="save_btn">
                            <button
                              className="btn addbtn"
                              onClick={() => {
                                this.storeBranding();
                              }}
                            >
                              Save
                            </button>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Col>
                </div>
              )}

              {/* <div className='alignTop' />
				<BrandingWalkthrogh /> */}
            </TabPanel>

            <TabPanel>
              {subscribtionItems != "" && firstStep && (
                <div className="card__title sub-div" style={{ marginTop: 40 }}>
                  <span className="subscription_div-text">
                    Your Subscription
                  </span>
                  {subscribtionItems &&
                    subscribtionItems.items &&
                    subscribtionItems.items.data && (
                      <div className="subscription_div">
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[0].month && (
                            <span>
                              <b>Plan:</b> 1-9 SKUS ($99/month.)
                            </span>
                          )}
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[1].month && (
                            <span>
                              <b>Plan:</b> 10-49 SKUS ($149/month.)
                            </span>
                          )}
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[2].month && (
                            <span>
                              <b>Plan:</b> 50-99 SKUS ($199/month.)
                            </span>
                          )}
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[0].year && (
                            <span>
                              <b>Plan:</b> 1-9 SKUS ($1020/year.)
                            </span>
                          )}
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[1].year && (
                            <span>
                              <b>Plan:</b> 10-49 SKUS ($1524/year.)
                            </span>
                          )}
                        {subscribtionItems.items.data[
                          subscribtionItems.items.data.length - 1
                        ].plan.id == paypalPlanList[2].year && (
                            <span>
                              <b>Plan:</b> 50-99 SKUS ($2040/year.)
                            </span>
                          )}
                        <span>
                          <b>Status:</b> {subscribtionItems.status}
                        </span>
                      </div>
                    )}
                  <div className="subscription_div">
                    {lastBillingDate != "" && (
                      <span>
                        <b>Most Recent Billing Date:{lastBillingDate}</b>
                      </span>
                    )}
                    {nextBillingDate != "" && (
                      <span>
                        <b>Next Billing Date:{nextBillingDate}</b>
                      </span>
                    )}
                  </div>
                  <div
                    className="subscription_cancel"
                    onClick={() => {
                      this.setState({
                        firstStep: false,
                        secondStep: true,
                        thirdStep: false,
                      });
                    }}
                  >
                    <a href="javascript:void(0);">Cancel My Subscription</a>
                  </div>
                </div>
              )}

              {activePlan == "" && !isAnnualPlan && (
                <div className="card__title sub-div" style={{ marginTop: 40 }}>
                  <span className="subscription_div-text">
                    Your Subscription
                  </span>
                  <div className="subscription_div subscription_div_cancelled">
                    <span style={{ textAlign: "center" }}>
                      <b>Status:</b> You have no active subscriptions
                    </span>
                  </div>
                </div>
              )}


              {(activePlan != "" || isAnnualPlan) && firstStep && !secondStep && !thirdStep && (
                <div className="card__title sub-div" style={{ marginTop: 40 }}>
                  <span className="subscription_div-text subscription_div-text-span">
                    Your Subscription
                  </span>
                  {
                    activePlan.plan_id === 'Annual Subscription' ? 'Annual Subscription' : (
                      <div className="subscription_div subscription_div_cancelled">
                        <span>
                          {" "}
                          <b>Status:</b> {!isAnnualPlan ? activePlan.status : 'Active'}{" "}
                        </span>
                        <span>
                          {" "}
                          <b>Member Since:</b> {activestartTime}{" "}
                        </span>
                        <span>
                          {" "}
                          <b>Current Plan:</b> {!isAnnualPlan ? activePlan.name : 'Annual Subscription'}{" "}
                        </span>
                        {
                          !isAnnualPlan && (
                            <div>
                              <span>
                                {" "}
                                <b>Marketplaces:</b> {activePlan.marketplace}{" "}
                              </span>
                              <span>
                                {" "}
                                <b>SKU's:</b> {activePlan.skuCount}{" "}
                              </span>
                            </div>
                          )
                        }
                        {activePlan.commission && (
                          <span>
                            {" "}
                            <b>Commission:</b> {activePlan.commission}{" "}
                          </span>
                        )}
                        <span>
                          <button
                            className="upgrade_plan"
                            onClick={() => {
                              this.changeTab(2);
                            }}
                          >
                            Upgrade Plan
                          </button>
                          <button
                            onClick={() => {
                              this.setState({
                                firstStep: false,
                                secondStep: true,
                                thirdStep: false,
                              });
                            }}
                            className="cancel_plan"
                          >
                            Cancel
                          </button>
                        </span>
                      </div>
                    )
                  }
                </div>
              )}

              {secondStep && (
                <div className="card__title sub-div" style={{ marginTop: 40 }}>
                  <span className="subscription_div-text">Are You Sure?</span>
                  <div className="subscription_div sub_span_div">
                    <span>
                      Are you sure you want to cancel your Valet seller
                      Subscription? This action cannot be done and your product
                      listings will be deleted. If you wish to subscribe again
                      in the future. you will need to restart your applicaation
                      process.
                    </span>
                  </div>
                  <div
                    className="subscription_div"
                    onClick={() => {
                      this.setState({
                        secondStep: false,
                        thirdStep: false,
                        firstStep: true,
                      });
                    }}
                  >
                    <button className="btn">NEVERMIND</button>
                  </div>
                  <div
                    className="subscription_cancel"
                    onClick={() => {
                      this.setState({
                        secondStep: false,
                        thirdStep: true,
                        firstStep: false,
                      });
                    }}
                  >
                    <a
                      href="javascript:void(0);"
                      className="subscription_cancel_a"
                    >
                      No thanks! I want to cancel.{" "}
                    </a>
                  </div>
                </div>
              )}

              {thirdStep && (
                <div className="card__title sub-div" style={{ marginTop: 40 }}>
                  <span
                    className="subscription_div-text"
                    style={{ marginBottom: 20 }}
                  >
                    Why Are You Cancelling?
                  </span>
                  <div className="subscription_div sub_span_div">
                    <span>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={isExpensive}
                            onChange={() => {
                              this.setState({ isExpensive: !isExpensive });
                            }}
                          />{" "}
                          Valet Seller is too expensive
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={isProduct}
                            onChange={() => {
                              this.setState({ isProduct: !isProduct });
                            }}
                          />{" "}
                          I was unhappy with my product listings
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={isSales}
                            onChange={() => {
                              this.setState({ isSales: !isSales });
                            }}
                          />{" "}
                          I was unhappy with my sales volume
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={isSell}
                            onChange={() => {
                              this.setState({ isSell: !isSell });
                            }}
                          />{" "}
                          I found another company to sell my products
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={isElse}
                            onChange={() => {
                              this.setState({ isElse: !isElse });
                            }}
                          />{" "}
                          It's something else
                        </Label>
                      </FormGroup>

                      <FormGroup>
                        <textarea
                          value={feedBack}
                          onChange={(event) => {
                            this.setState({ feedBack: event.target.value });
                          }}
                          rows="5"
                        />
                      </FormGroup>
                    </span>
                  </div>
                  <div
                    className="subscription_div"
                    style={{ marginTop: 10, marginBottom: 20 }}
                    onClick={() => {
                      this.deleteSubscriberAction();
                    }}
                  >
                    <button className="btn">CANCEL MY SUBSCRIPTION</button>
                  </div>
                </div>
              )}

              {/* <div className="planing_div">
            <Tabs>
              <TabList>
                <Tab>Monthly</Tab>
                <Tab>Yearly</Tab>
              </TabList>

              <TabPanel>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[0].month ? 'planing activeplan': 'planing' }>
                      <h4>PERSONAL</h4>      
                      <h3>$99</h3>
                      <p>Sell up to 9 SKU's</p>
                      <p>3 Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[0].month&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[0].month&&<Button onClick={() => { this.upgradePlan(paypalPlanList[0].month) }}>Upgrade</Button>}
                  </div>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[1].month ? 'planing activeplan': 'planing' }>
                      <h4>BUSINESS</h4>      
                      <h3>$149</h3>
                      <p>Sell up to 49 SKU's</p>
                      <p>5 Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[1].month&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[1].month&&<Button onClick={() => { this.upgradePlan(paypalPlanList[1].month) }}>Upgrade</Button>}
                  </div>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[2].month ? 'planing activeplan': 'planing' }>
                      <h4>PROFESSIONAL</h4>   
                      <h3>$199</h3>
                      <p>Sell up to 99 SKU's</p>
                      <p>10+ Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[2].month&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[2].month&&<Button onClick={() => { this.upgradePlan(paypalPlanList[2].month) }}>Upgrade</Button>}
                  </div>
                  </TabPanel>

                  <TabPanel>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[0].year ? 'planing activeplan': 'planing' }>
                      <h4>PERSONAL</h4>      
                      <h3>$1020</h3>
                      <p>Sell up to 9 SKU's</p>
                      <p>3 Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[0].year&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[0].year&&<Button onClick={() => { this.upgradePlan(paypalPlanList[0].year) }}>Upgrade</Button>}
                  </div>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[1].year ? 'planing activeplan': 'planing' }>
                      <h4>BUSINESS</h4>      
                      <h3>$1524</h3>
                      <p>Sell up to 49 SKU's</p>
                      <p>5 Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[1].year&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[1].year&&<Button onClick={() => { this.upgradePlan(paypalPlanList[1].year) }}>Upgrade</Button>}
                  </div>
                  <div className={ subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[2].year ? 'planing activeplan': 'planing' }>
                      <h4>PROFESSIONAL</h4>   
                      <h3>$2040</h3>
                      <p>Sell up to 99 SKU's</p>
                      <p>10+ Marketplaces</p>
                      <p>Generate Reports</p>
                      <p>Mail/Chat Support</p>
                      <p>10% Commission</p>
                      {subscribtionItems.plan&&subscribtionItems.plan.id==paypalPlanList[2].year&&<Button disabled>Current Subscription</Button>}
                      {subscribtionItems.plan&&subscribtionItems.plan.id!=paypalPlanList[2].year&&<Button onClick={() => { this.upgradePlan(paypalPlanList[2].year) }}>Upgrade</Button>}
                  </div>
                  </TabPanel>
              </Tabs>
            </div> */}
            </TabPanel>

            <TabPanel>
              <button
                className="btn addbtn"
                style={{ marginTop: 10 }}
                onClick={() => {
                  this.setState({ emailmodalIsOpen: true, orderEmail: "" });
                }}
              >
                Add
              </button>
              <TableList
                normal={true}
                items={this.state.orderNotificationList}
                deleteAction={this.deleteAction}
                headers={this.state.ordernotificationHeader}
                name={this.state.name}
                className="myprodList"
                company={this.state.company}
                filename="ordernotification"
              />
            </TabPanel>

            <TabPanel className="tax_tab">
              <div className="card__title" style={{ marginTop: 10 }}>
                <h5 className="bold-text">W-9 INFORMATION</h5>
              </div>

              <div className="w-9 right btn addbtn">
                <Link to="/file/W-9.pdf" target="_blank" download>
                  Download W-9 File
                </Link>
              </div>
              <div className="w-9-upload">
                <p>Upload Completed W-9 File</p>
                <input
                  name="name"
                  type="file"
                  placeholder=""
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    this.fileW9OnChange(e)
                  }
                />
              </div>
              <div className="card__title" style={{ marginTop: 40 }}>
                <h5 className="bold-text">TAX INFORMATION</h5>
              </div>
              <div className="form__form-group-field left">
                <span>
                  <label>
                    Business Type<b className="mantory">*</b>
                  </label>
                  <Select
                    value={selectbusinessType}
                    onChange={this.handleBusinessChange}
                    options={businesstypeList}
                  />
                </span>
              </div>

              <div className="form__form-group-field right">
                <span>
                  <label>
                    State<b className="mantory">*</b>
                  </label>
                  <Select
                    value={selectState}
                    onChange={this.handleStateChange}
                    options={stateList}
                  />
                </span>
              </div>

              <div className="form__form-group-field right">
                <span>
                  <label>
                    9-digit Federal Tax ID (EIN number)
                    <b className="mantory">*</b>{" "}
                    <a
                      href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
                      target="_blank"
                    >
                      <span>Get a free EIN here</span>
                    </a>
                  </label>
                  <input
                    type="number"
                    placeholder=""
                    value={this.state.einNumber}
                    onChange={(event) => {
                      this.setState({ einNumber: event.target.value });
                    }}
                    maxLength="9"
                  />
                </span>
              </div>

              <div className="form__form-group-field right">
                <span>
                  <label>
                    Date of Birth of Owner/Representative (format:MM/DD/YYYY){" "}
                    <b className="mantory">*</b>
                  </label>
                  <DatePicker
                    onChange={this.onChangeDob}
                    value={this.state.dateBirth}
                    maxDate={maxDate}
                  />
                </span>
              </div>

              <div className="form_footer" style={{ padding: 0 }}>
                <button
                  className="btn addbtn update-btn"
                  onClick={() => {
                    this.taxInformation();
                  }}
                >
                  Submit
                </button>
              </div>
            </TabPanel>

            <TabPanel>
              <TableList
                normal={true}
                items={this.state.upcList}
                headers={this.state.upcHeader}
                name={this.state.name}
                company={this.state.company}
                filename="upc-information"
              />
            </TabPanel>
          </Tabs>
        )}

        <Modal
          isOpen={this.state.emailmodalIsOpen}
          onRequestClose={this.closeEmailModal}
          style={deleteCustomStyles}
        >
          <h2
            ref={(subtitle) => (this.subtitle = subtitle)}
            className="emailh2"
          >
            Email{" "}
            <a
              className="closemodal"
              onClick={() => {
                this.setState({ emailmodalIsOpen: false });
              }}
            >
              X
            </a>
          </h2>

          <div className="agreement">
            <input
              type="email"
              style={{
                width: "100%",
                padding: 8,
                marginBottom: 10,
                marginTop: 8,
              }}
              value={this.state.orderEmail}
              onChange={(event) => {
                this.setState({ orderEmail: event.target.value });
              }}
            />
          </div>
          <button
            className="btn addbtn update-btn"
            style={{ marginBottom: 0 }}
            onClick={() => {
              this.addOrderEmail();
            }}
          >
            Save
          </button>
          <button
            className="btn addbtn disconnect-btn"
            style={{ marginBottom: 0 }}
            onClick={() => {
              this.setState({ emailmodalIsOpen: false });
            }}
          >
            Cancel
          </button>
        </Modal>

        <Modal
          isOpen={this.state.suscribermodalIsOpen}
          onRequestClose={this.closeSubscriberModal}
          style={deleteCustomStyles}
          contentLabel="Delete Modal"
        >
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            Delete{" "}
            <a className="closemodal" onClick={this.closeSubscriberModal}>
              X
            </a>
          </h2>

          <div className="agreement">
            <span>
              <b>if you cancel a subscription, your account will blocked.</b>
            </span>
          </div>
          <button
            className="btn addbtn update-btn"
            style={{ marginBottom: 0 }}
            onClick={() => {
              this.deleteSubscriberAction();
            }}
          >
            Yes
          </button>
          <button
            className="btn addbtn disconnect-btn"
            style={{ marginBottom: 0 }}
            onClick={this.closeSubscriberModal}
          >
            No
          </button>
        </Modal>

        <Modal
          isOpen={this.state.shopifyModalIsOpen}
          onRequestClose={this.closeShopifyModel}
          style={deleteCustomStyles}
          contentLabel="Delete Modal"
        >
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            Delete{" "}
            <a className="closemodal" onClick={this.closeShopifyModel}>
              X
            </a>
          </h2>

          <div className="agreement">
            <span>
              <b>Are you sure you want to disconnect shopify?</b>
            </span>
          </div>
          <button
            className="btn addbtn update-btn"
            style={{ marginBottom: 0 }}
            onClick={() => {
              this.deConnectShopify();
            }}
          >
            Yes
          </button>
          <button
            className="btn addbtn disconnect-btn"
            style={{ marginBottom: 0 }}
            onClick={this.closeShopifyModel}
          >
            No
          </button>
        </Modal>

        <Modal
          isOpen={this.state.imageModalIsOpen}
          onRequestClose={this.closeImageModal}
          style={imageCustomStyles}
          contentLabel="Example Modal"
        >
          <div className="editimage">
            <button
              className="btn addbtn optimisation cropclose"
              onClick={() => {
                this.closeImageModal();
              }}
            >
              Close
            </button>
            <span>Edit Image</span>
            <button
              className="btn addbtn optimisation cropsave"
              onClick={() => {
                this.croppedImage();
              }}
            >
              SAVE
            </button>
          </div>

          <Cropper
            image={this.state.imageSrc}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            rotation={this.state.rotation}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
          <button
            className="btn addbtn add_products optimisation cropbtn"
            onClick={() => {
              this.onRotate();
            }}
          >
            ROTATE
          </button>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            Shopify App{" "}
            <a className="closemodal" onClick={this.closeModal}>
              X
            </a>
          </h2>

          <div className="agreement">
            <span>
              <b>1) Go to your Shopify Admin</b>
            </span>
            <span>
              <b>
                2) After Login Go to "Apps" and scroll down and click "Manage
                Private Apps"
              </b>
            </span>
            <span>
              <b>
                3) Click "Enable Private App Development" and agree to the terms
              </b>
            </span>
            <span>
              <b>4) Click "Create private app"</b>
            </span>
            <span>
              <b>
                5) Name the app Valet Seller and use{" "}
                <a href="mailto:support@valetseller.com">
                  support@valetseller.com
                </a>{" "}
                for the support email
              </b>
            </span>
            <span>
              <b>6) Click "Save". </b>
            </span>
            <span>
              <b>
                7) Scroll down and click "Show Inactive Admin API Permissions"
              </b>
            </span>
            <span>
              <b>
                8) Give "Read and Write" access for Products, Inventory, Orders,
                and Product Listings
              </b>
            </span>
            <span>
              <b>9) Click "Save" again</b>
            </span>
            <span>
              <b>10) You will be shown your API Key and Password</b>
            </span>
            <span>
              <b>11) Click "Show" next to Password</b>
            </span>
            <span>
              <b>
                12) Copy those keys and paste them in your{" "}
                <Link to="/pages/integrations">"Integrations Page"</Link> and
                click on connect.
              </b>
            </span>
            <br />
            You can also watch the video tutorial{" "}
            <Link
              style={{ color: "blue" }}
              onClick={this.openvideoModal}
              to="#"
            >
              here.
            </Link>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.videomodalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closevideoModal}
          style={videocustomStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            {" "}
            Shopify Integration Tutorial{" "}
            <a className="closemodal" onClick={this.closevideoModal}>
              X
            </a>
          </h2>

          <div className="agreement">
            <iframe
              width="600"
              height="340"
              src="https://www.youtube.com/embed/Bzcny-pluDM"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.walmartmodalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closewalmartModal}
          style={deleteCustomStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            {" "}
            Business day
            <a className="closemodal" onClick={this.closewalmartModal}>
              X
            </a>
          </h2>

          <div className="agreement">
            <p style={{ marginTop: 10, fontSize: 16 }}>
              Walmart's default handling time is 1 business day only
            </p>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.shopifyproductIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeshopifyproductModal}
          style={shopifyproductcustomStyles}
          contentLabel="Example Modal"
        >
          <div>
            <div style={{ display: "flex" }}>
              <div>
                <button
                  class="btn addbtn add_products optimisation"
                  onClick={() => {
                    this.uploadShopifyProducts();
                  }}
                >
                  Upload
                </button>
              </div>
              {!this.state.IsSelectAllShopify && (
                <div style={{ margin: "0 10px" }}>
                  <button
                    style={{ margin: "0 10px" }}
                    className="btn addbtn add_products optimisation"
                    onClick={() => {
                      this.selectShopifyAllProducts();
                    }}
                  >
                    Select All Products
                  </button>
                </div>
              )}
              {this.state.IsSelectAllShopify && (
                <div>
                  <button
                    style={{ margin: "0 10px" }}
                    class="btn addbtn add_products optimisation"
                    onClick={() => {
                      this.selectShopifyAllProducts();
                    }}
                  >
                    Deselect All Products
                  </button>
                </div>
              )}
              <div style={{ flex: 3, margin: "0 30px" }}>
                <Input
                  type="text"
                  name="search"
                  placeholder="Search by product title"
                  value={this.state.shopifySearchText}
                  onChange={(e) => {
                    this.shopifySearchKey(e.target.value);
                  }}
                />
              </div>
              <div>
                <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                  {" "}
                  <a onClick={this.closeshopifyproductModal}>X</a>
                </h2>
              </div>
            </div>
            <div>
              <TableList
                normal={false}
                headers={this.state.headers}
                className="myprodList"
                addShopifyProduct={this.addShopifyProduct}
                items={this.state.shopifyProducts}
                getData={this.shopifyGetProducts}
                pageSize={this.state.totalPage}
                totalPage={this.state.totalPage}
                tableLoading={this.state.tableLoading}
              />
            </div>
          </div>
        </Modal>
      </Col>
    );
  }
}

const mapStateToProps = ({ authReducer, paypalReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails, transactionItems: paypalReducer.data };
};

export default connect(mapStateToProps, actions)(ProfileCard);

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const CardcustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "420px",
  },
};

class _CardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billing: {},
      cardList: this.props.cardList,
      isLoading: false,
      error: "",
      selectCountry: null,
      modalIsOpen: false,
      videomodalIsOpen: false,
      walmartmodalIsOpen: false,
      alertmodalIsOpen: false,
      shopifyproductIsOpen: false,
      subscribeIsOpen: false,
      modalCardOpen: false,
      checked: true,
      isButtonDisabled: false,
    };
    //console.log('this.props.billing===', this.props.cardList);
    this.openCardModal = this.openCardModal.bind(this);
    this.closeCardModal = this.closeCardModal.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closealertModal = this.closealertModal.bind(this);
  }

  componentWillReceiveProps({ cardList }) {
    if (cardList != this.props.cardList) {
      this.setState({ cardList: cardList });
    }
  }

  addCard() {
    let billing = {
      firstname: "",
      lastname: "",
      token: "",
      zip: "",
      cardnumber: "",
      expiremonth: "",
      expireyear: "",
      stripe_customer_id: "",
      address_country: null,
      ccv: "",
      id: "",
    };
    this.setState({ billing: billing, modalCardOpen: true });
  }

  editCard(billing) {
    //console.log(billing, this.state.countryList);
    this.setState({ billing: billing, modalCardOpen: true });
  }

  removeCard(index) {
    this.selectCardIndex = index;
    this.setState({ alertmodalIsOpen: true });
  }
  deleteCardAction() {
    //console.log(this.selectCardIndex);
    let cardList = this.state.cardList;

    this.setState({ isLoading: true });
    api
      .stripeDelete(
        "customers/" +
        this.props.billing.stripe_customer_id +
        "/sources/" +
        cardList[this.selectCardIndex].id
      )
      .then((cutomerData) => {
        if (cutomerData.error) {
          helper.errorMessage(cutomerData.error.message);
        } else {
          cardList.splice(this.selectCardIndex, 1);
          helper.successMessage("sucessfully deleted");
        }
        this.setState({
          alertmodalIsOpen: false,
          isLoading: false,
          cardList: cardList,
        });
      });
  }

  openCardModal() {
    this.setState({ modalCardOpen: true });
  }

  closeCardModal() {
    this.setState({ modalCardOpen: false });
  }
  closealertModal() {
    this.setState({ alertmodalIsOpen: false });
  }

  handleSwitchChange(index) {
    let cardList = this.state.cardList;
    if (!cardList[index].default) {
      for (let data in cardList) {
        cardList[data].default = false;
      }
      cardList[index].default = true;
      this.setState({ isLoading: true, cardList: cardList });
      let req = {
        default_source: cardList[index].id,
      };
      api
        .stripePost("customers/" + this.props.billing.stripe_customer_id, req)
        .then((cutomerData) => {
          if (cutomerData.error) {
            helper.errorMessage(cutomerData.error.message);
            this.setState({ isLoading: false });
          } else {
            helper.successMessage("sucessfully changed");
            this.setState({ isLoading: false });
          }
        });
    } else {
      helper.errorMessage("please select other primary card or add new card");
    }
  }

  handleValueChange = (selectCountry) => {
    let billing = this.state.billing;
    billing.address_country = selectCountry;
    this.setState({ billing: billing, time: new Date().getTime() });
  };

  changeValue = (field, value) => {
    let billing = this.state.billing;
    if (value.length == 4 && field == "cardnumber") {
      billing[field] = "XXXXXXXXXXXX" + value;
    } else {
      billing[field] = value;
    }

    this.setState({ billing: billing, time: new Date().getTime() });
  };

  handleSubmit = async (evt) => {
    evt.preventDefault();

    if (this.props.stripe) {
      const {
        selectCountry,
        billing,
        expireyear,
        expiremonth,
        isButtonDisabled,
      } = this.state;

      if (billing.firstname == "") {
        helper.errorMessage("name field is required");
      } else if (billing.address_zip == "") {
        helper.errorMessage("postal Code is required");
      } else if (billing.address_country == null) {
        helper.errorMessage("please select country");
      } else if (billing.id == "") {
        this.setState({ isButtonDisabled: true });
        this.props.stripe.createToken().then((tokenData) => {
          //console.log('tokenData==', tokenData, this.props.billing.stripe_customer_id);
          if (tokenData.error) {
            helper.errorMessage(tokenData.error.message);
            this.setState({ isLoading: false, isButtonDisabled: false });
          } else {
            const currentUser = firebase1.app.auth().currentUser;
            this.setState({ isLoading: true, isButtonDisabled: false });
            if (currentUser && this.props.billing.stripe_customer_id == "") {
              //new stripe customer
              let req = {
                email: currentUser.email,
                source: tokenData.token.id,
              };
              api.stripePost("customers", req).then((cutomerData) => {
                if (cutomerData.error) {
                  helper.errorMessage(cutomerData.error.message);
                  this.setState({ isLoading: false, isButtonDisabled: false });
                } else {
                  let date = new Date();
                  let createdDate =
                    date.getDate() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    date.getFullYear();
                  let data = {
                    firstname: billing.firstname,
                    token: tokenData.token.id,
                    created_at: createdDate,
                    country: billing.address_country,
                    stripe_customer_id: cutomerData.id,
                    zip: billing.address_zip,
                  };
                  this.props.handleResult(data, "insert");
                  billing.id = tokenData.token.card.id;
                  let req = {
                    address_zip: billing.address_zip,
                    address_country: billing.address_country.value,
                    name: billing.firstname,
                  };
                  this.updateStripeCard(cutomerData.id, billing, req);
                }
              });
            } else {
              let req = {
                source: tokenData.token.id,
              };

              api
                .stripePost(
                  "customers/" +
                  this.props.billing.stripe_customer_id +
                  "/sources",
                  req
                )
                .then((stripeData) => {
                  if (stripeData.error) {
                    helper.errorMessage(stripeData.error.message);
                    this.setState({
                      isLoading: false,
                      isButtonDisabled: false,
                    });
                  } else {
                    billing.id = tokenData.token.card.id;
                    req = {
                      address_zip: billing.address_zip,
                      address_country: billing.address_country.value,
                      name: billing.firstname,
                    };
                    this.updateStripeCard(
                      this.props.billing.stripe_customer_id,
                      billing,
                      req
                    );
                  }
                });
            }
          }
        });
      } else {
        let req = {
          exp_month: billing.exp_month,
          exp_year: billing.exp_year,
          address_zip: billing.address_zip,
          address_country: billing.address_country.value,
          name: billing.firstname,
        };
        this.updateStripeCard(
          this.props.billing.stripe_customer_id,
          billing,
          req
        );
      }
    }
  };

  updateStripeCard(stripe_customer_id, billing, req) {
    this.setState({ isLoading: true });

    api
      .stripeUpdate(
        "customers/" + stripe_customer_id + "/sources/" + billing.id,
        req
      )
      .then((cutomerData) => {
        if (cutomerData.error) {
          helper.errorMessage(cutomerData.error.message);
          this.setState({ isLoading: false });
        } else {
          this.closeCardModal();
          this.props.getCardDetails(this.props.billing.stripe_customer_id);
          helper.successMessage("Sucessfully update");
          this.setState({ isLoading: false });
        }
      });
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  render() {
    const { isLoading, billing, cardList, isButtonDisabled } = this.state;
    if (isLoading) {
      return (
        <div
          className="load"
          style={{
            backgroundColor: "transparent",
            width: "85%",
            height: "50%",
          }}
        >
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path
                fill="#6c6f7f"
                d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
              />
            </svg>
          </div>
        </div>
      );
    } else {
      return (
        <div className="profilecards">
          {/* <span className="spliter"></span> */}
          <div className="card__title" style={{ marginTop: 40 }}>
            <h5 className="bold-text">Billing Information</h5>
          </div>
          <div className="addcards">
            <button
              className="btn addbtn add_products"
              onClick={() => {
                this.addCard();
              }}
            >
              Add Credit Card
            </button>
          </div>
          {cardList && cardList.length == 0 && (
            <div className="cards_added">
              <p>No Credit Cards Added</p>
            </div>
          )}
          {cardList &&
            cardList.map((item, i) => {
              return (
                <div className="billing_card" key={i}>
                  <span className="primary_card">
                    <Switch
                      onChange={() => {
                        this.handleSwitchChange(i);
                      }}
                      checked={item.default}
                    />
                    <button
                      className="edit_card"
                      onClick={() => {
                        this.editCard(item);
                      }}
                    >
                      <img src="../../img/edit_btn.png" />
                    </button>
                    {!item.default && (
                      <button
                        className="edit_card"
                        onClick={() => {
                          this.removeCard(i);
                        }}
                      >
                        <img src="../../img/trash.png" />
                      </button>
                    )}
                  </span>
                  <span className="billing_card_block">
                    <span className="billing_card_left">Name</span>
                    <span className="billing_card_right">
                      : {item.firstname}
                    </span>
                  </span>
                  <span className="billing_card_block">
                    <span className="billing_card_left">
                      Credit Card Number
                    </span>
                    <span className="billing_card_right">
                      : {item.cardnumber}
                    </span>
                  </span>
                  <span className="billing_card_block">
                    <span className="billing_card_left">Expiration Date</span>
                    <span className="billing_card_right">
                      : {item.exp_month} / {item.exp_year}
                    </span>
                  </span>

                  <span className="billing_card_block">
                    <span className="billing_card_left">Country</span>
                    <span className="billing_card_right">
                      :{" "}
                      {item.address_country && item.address_country.label
                        ? item.address_country.label
                        : ""}
                    </span>
                  </span>
                  <span className="billing_card_block">
                    <span className="billing_card_left">Zip/Postal</span>
                    <span className="billing_card_right">
                      : {item.address_zip}
                    </span>
                  </span>
                </div>
              );
            })}

          <Modal
            isOpen={this.state.modalCardOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeCardModal}
            style={CardcustomStyles}
            contentLabel="Example Modal"
          >
            {billing.id == "" && (
              <h4 style={{ paddingLeft: 18 }}>
                Add Card{" "}
                <a className="closemodal" onClick={this.closeCardModal}>
                  X
                </a>
              </h4>
            )}
            {billing.id != "" && (
              <h4 style={{ paddingLeft: 18 }}>
                Edit Card{" "}
                <a className="closemodal" onClick={this.closeCardModal}>
                  X
                </a>
              </h4>
            )}
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="form__form-group-field left">
                <span>
                  <label>Name</label>
                  <input
                    name="firstname1"
                    type="text"
                    placeholder="Cardholder name"
                    value={billing.firstname}
                    onChange={(event) => {
                      this.changeValue("firstname", event.target.value);
                    }}
                  />
                </span>
              </div>

              {billing.id != "" && (
                <div className="form__form-group-field left">
                  <span>
                    <label>Credit Card Number</label>
                    <NumberFormat
                      isNumericString={true}
                      disabled={true}
                      format="#### #### #### ####"
                      value={billing.cardnumber}
                      onValueChange={(values) => {
                        this.changeValue("cardnumber", values.value);
                      }}
                    />
                  </span>
                </div>
              )}
              {billing.id == "" && (
                <div className="form__form-group-field left">
                  <span>
                    <label>Credit Card Number</label>
                    <CardElement
                      onChange={this.handleChange}
                      {...createOptions()}
                    />
                  </span>
                </div>
              )}
              {billing.stripe_customer_id != "" && (
                <div className="form__form-group-field left">
                  <span>
                    <span className="expiration_date_profile expo_date">
                      Expiration Date
                    </span>
                    <input
                      name="name"
                      type="number"
                      placeholder="Month"
                      style={{ width: "48%", marginRight: "2%" }}
                      value={billing.exp_month}
                      onChange={(event) => {
                        this.changeValue("expiremonth", event.target.value);
                      }}
                    />
                    <input
                      name="name"
                      type="number"
                      placeholder="Year"
                      style={{ width: "48%", marginLeft: "2%" }}
                      value={billing.exp_year}
                      onChange={(event) => {
                        this.changeValue("expireyear", event.target.value);
                      }}
                    />
                  </span>
                </div>
              )}

              {billing.id != "" && (
                <div className="form__form-group-field left">
                  <span>
                    <label>Security Code</label>
                    <input
                      name="name"
                      type="password"
                      placeholder=""
                      value={billing.ccv}
                      onChange={(event) => {
                        this.changeValue("ccv", event.target.value);
                      }}
                    />
                  </span>
                </div>
              )}

              <div className="form__form-group-field left">
                <span>
                  <label>Country</label>
                  <Select
                    value={billing.address_country}
                    onChange={this.handleValueChange}
                    options={countryList}
                  />
                </span>
              </div>

              <div className="form__form-group-field left">
                <span>
                  <label>Zip/Postal</label>
                  <input
                    name="name"
                    type="text"
                    placeholder=""
                    value={billing.address_zip}
                    onChange={(event) => {
                      this.changeValue("address_zip", event.target.value);
                    }}
                  />
                </span>
              </div>
              {billing.id == "" && (
                <div className="full_width">
                  <button
                    disabled={isButtonDisabled}
                    className="btn signupbtns_active account__btn--small buttons_pay addcard_green"
                  >
                    Add Card
                  </button>
                </div>
              )}
              {billing.id != "" && (
                <div className="full_width">
                  <button
                    disabled={isButtonDisabled}
                    className="btn signupbtns_active account__btn--small buttons_pay addcard_green"
                  >
                    Update Card
                  </button>
                </div>
              )}

              {/* {billing.stripe_customer_id!=''&&<div className="full_width"><button className="btn signupbtns_active account__btn--small buttons_pay deletecard_red" >Cancel subscription</button></div>} */}
            </form>
          </Modal>
          <Modal
            isOpen={this.state.alertmodalIsOpen}
            onRequestClose={this.closealertModal}
            style={alertcustomStyles}
            contentLabel="Delete Modal"
          >
            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
              Delete{" "}
              <a className="closemodal" onClick={this.closealertModal}>
                X
              </a>
            </h2>

            <div className="agreement">
              <span>
                <b>Are you sure you want to delete?</b>
              </span>
            </div>
            <button
              className="btn addbtn update-btn"
              style={{ marginBottom: 0 }}
              onClick={() => {
                this.deleteCardAction();
              }}
            >
              Yes
            </button>
            <button
              className="btn addbtn disconnect-btn"
              style={{ marginBottom: 0 }}
              onClick={this.closealertModal}
            >
              No
            </button>
          </Modal>
        </div>
      );
    }
  }
}

const CardForm = injectStripe(_CardForm);
