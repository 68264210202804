
// Walkthrough for the sales
import React, { Component } from 'react';
import Tour from "react-product-intro";
import history from '../../../utils/history';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
    SALES,FINISHED
  } from '../../Walkthrough/popupText';
class SalesWalkthrough extends Component {
    constructor() {
        super();
        this.state = {

            isTourActive: false,
            finishedWalkthrough:false,
            tourStep: 1,
            StepTour:1
        };
    }

    componentDidMount() {
        if (this.props.userdetails && !this.props.userdetails.walkthroughDone) {
            let dashboardSeen = typeof localStorage.dashboardSeen != "undefined" ? localStorage.dashboardSeen : false;
            let productSeen = typeof localStorage.productSeen != "undefined" ? localStorage.productSeen : false;
            let addProductSeen = typeof localStorage.addProductSeen != "undefined" ? localStorage.addProductSeen : false;
            let inventorySeen = typeof localStorage.inventorySeen != "undefined" ? localStorage.inventorySeen : false;
            let topRightMenuSeen = typeof localStorage.topRightMenuSeen != "undefined" ? localStorage.topRightMenuSeen : false;
            let salesSeen = typeof localStorage.salesSeen != "undefined" ? localStorage.salesSeen : false;

            //check the currentRoute
            //currentTab	
            switch ("sales") {
                case "dashboard":
                    if (!dashboardSeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.dashboardSeen = true;
                    }
                    break;
                case "product":
                    if (!productSeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.productSeen = true;
                    }
                    break;
                case "addProduct":
                    if (!addProductSeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.addProductSeen = true;
                    }
                    break;
                case "inventory":
                    if (!inventorySeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.inventorySeen = true;
                    }
                    break;
                case "topRightMenu":
                    if (!topRightMenuSeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.topRightMenuSeen = true;
                    }
                    break;
                case "sales":
                    if (!salesSeen) {
                        //show walkthrough as per current page
                        this.setState({
                            isTourActive: true
                        });
                        localStorage.salesSeen = true;
                    }
                    break;

            }
        }
    }
    onNextWalkthrough = (step) => {
        if (step == 2) {
            this.setState({
                isTourActive: false,finishedWalkthrough: true
            });
        } else {
            this.setState({
                isTourActive: false
            });
            
        }
        // if (step == 2) {
        //     this.setState({
        //       isTourActive: false
        //     });
        //     history.push('branding')
        //   } else {
        //     this.setState({
        //       isTourActive: false
        //     });
        //   }
    }

    onFinishedWalkthrough= () => {
            this.setState({
                finishedWalkthrough: false,isTourActive:false
            });
            this.props.addWalkthroughFlag()  
    }

    render() {
        return (
            <>
            
             <div className="overlayIntro">
             <Tour
                 active={this.state.finishedWalkthrough}
                 step={this.state.StepTour}
                 onNext={(step) => this.onFinishedWalkthrough(step)}
                 onBack={(step) => this.setState({ tourStep: step })}
                 onCancel={() => this.onFinishedWalkthrough()}
                 hideSteps={true}
                 width="450px"
                 steps={[
                    //  {
                    //      step: 1,
                    //      selector: ".step1",
                    //      title: <div style={{ color: "blue" }}>Sales</div>,
                    //      body: <div style={{ color: "green" }}>On the sales page you will have a live time view at sales across each channel and each product. You will also see incoming orders with customer information. You can also connect your Shopify account in your profile settings to automatically sync orders.</div>
                    //  }
                      {
                         step: 1,
                         selector: ".alignTop",
                         title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                         body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>{FINISHED} </div>
                     }
                 ]}
             />
         </div>
         <div className="overlayIntro resizeSalesPop">
                <Tour
                    active={this.state.isTourActive}
                    step={this.state.tourStep}
                    onNext={(step) => this.onNextWalkthrough(step)}
                    onBack={(step) => this.setState({ tourStep: step })}
                    onCancel={() => this.onFinishedWalkthrough()}
                    hideSteps={true}
                    width="450px"
                    steps={[
                        {
                            step: 1,
                            selector: ".step1",
                            title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                            body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>{SALES}</div>
                        }
                        , {
                            step: 2,
                            selector: ".alignTopaa",
                            title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                            body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>Great job! You've completed the basic tour. For more help, chat us anytime or open a case on your help page. Let's start selling! </div>
                        }
                    ]}
                />
            </div>
         </>
        )
    }


}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
    const { user, userdetails } = authReducer;
    return { user, userdetails };
};

export default connect(mapStateToProps, actions)(SalesWalkthrough);

