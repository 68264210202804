import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from "react-router-dom";
import {
  Badge,
  Button, Collapse,
  Input
} from "reactstrap";
import DownIcon from "mdi-react/ChevronDownIcon";
class SidebarContent extends Component {

  constructor() {
    super();
    this.state = {
      company_logo:'',
      searchOrder:'',
      collapse: '',
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.userdetails!=undefined&&nextProps.userdetails!=this.props.userdetails){
       this.updateUser(nextProps.userdetails);
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current &&!this.wrapperRef.current.contains(event.target)) {
      this.setState({collapse: ''})
    }
  }

  updateUser(user){
  //  console.log('sidebar====',user);
    let company_logo = '';
    if(user.company_logo){
      company_logo = user.company_logo;
    }
    this.setState({company_logo:company_logo})
  }

  hideSidebar = () => {
    this.props.onClick();
    this.setState({searchOrder:''})
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.onClick();
      this.props.history.push("/pages/searchorders/"+this.state.searchOrder);
      this.setState({searchOrder:''})
    }
  }

  toggleCollapse = (collapse) => {
    this.setState({ collapse });
  };

  render() {
  //  console.log('okokokko',this.props);
    let role = localStorage.getItem('role');
    const {t} = this.props.setOptions
    const {searchOrder} = this.state;
    let url = "/pages/searchorders/"+searchOrder;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">

           <SidebarLink className="dashb" title="Dashboard" route="/pages/dashboard" onClick={this.hideSidebar} icon="home"/>
            {/* <SidebarLink title="Inventory" route="/pages/inventory" onClick={this.hideSidebar} icon="sort-amount-asc"/> */}
            <SidebarLink title="Orders" route="/pages/sales" onClick={this.hideSidebar} icon="cart"/>  
            <SidebarLink title='Products' route="/pages/products" onClick={this.hideSidebar} icon="list"/>
           
            
            <SidebarLink title='Plus+' route="/pages/marketing" onClick={this.hideSidebar} icon="list"/>
            <SidebarLink title="Reports" route="/pages/reports" onClick={this.hideSidebar} icon="chart-bars"/>

            <SidebarLink title="Payment" route="/pages/payments" onClick={this.hideSidebar} icon="chart-bars"/>

            {role=='admin'&&role!='undefined'&&<SidebarLink title="Customers" route="/pages/user" onClick={this.hideSidebar} icon="user"/>}
            {role=='admin'&&role!='undefined'&&<SidebarLink title="Help Request" route="/pages/helprequest" onClick={this.hideSidebar} icon="question-circle"/>}
            {role == 'admin' && role != 'undefined' &&
            <div ref={this.wrapperRef} style={{ position: "relative"}}>

              <li className="sidebar__link" onClick={() => this.toggleCollapse('others')} >
                <p className="sidebar__link-title">
                  Others
                </p>
              </li>
              <Collapse  id="topBar__Id" isOpen={this.state.collapse === 'others'} className="topbar__menu-wrap" style={{ bottom:-30}}>
                <div  className="topbar__menu" onClick={() => this.toggleCollapse()}>
                  <Link to="/pages/upcmanagement"><span>UPC management</span></Link>
                </div>
                <div className="topbar__menu" onClick={() => this.toggleCollapse()}>
                  <Link to="/pages/marketplacelinkmanagement"><span>Marketplace Link Management</span></Link>
                </div>
                <div className="topbar__menu" onClick={() => this.toggleCollapse()}>
                  <Link to="/pages/flaggedKeywords"><span>Flagged Keywords</span></Link>
                </div>
              </Collapse>
            </div>}



            <div className="search heading_search" style={{ marginLeft: 15 }}>
              <Input type="text" name="search" placeholder="Search for SKU" value={searchOrder}  onKeyDown={this.handleKeyDown} onChange={(event) => {this.setState({ searchOrder: event.target.value })}}/>
              <Link to={url}>
                <button onClick={this.hideSidebar}><img src="/img/search1.png" style={{width: 15}}/></button></Link>
          </div>
        </ul>

        {/* {this.state.company_logo!=''&&<div><div className="company_logo" ><img src={this.state.company_logo} alt="" className="company_logo_img"/></div></div>} */}
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  const {user,userdetails} = authReducer;
  return {user,userdetails};
};

export default connect(mapStateToProps, actions)(SidebarContent);

