import { SchemaModel, StringType, NumberType, } from 'schema-typed';

export const validateDraftProduct = SchemaModel({
    Sku: StringType().isRequired('Sku is required.')
});

export const marketPlaceModal = SchemaModel({
    sku: StringType().isRequired('Sku is required.'),
    marketplace: StringType().isRequired('Marketplace is required.').addRule((value, data) => {
        const marketPlacesNameArr = [
            'eBay', 'ebay', 'Ebay', 'Walmart', 'Wish', 'Bonanza', "Amazon Canada",
            "Walmart Canada", 'Sears', 'Valet Shops', 'Amazon', 'Kroger',
            'amazon.ae', 'amazon.sg', 'Shopify', 'Amazon.ae', 'Amazon.sg',
            'kroger', 'sears', 'walmart canada', 'Walmart canada', 'Amazon canada',
            'amazon canada', 'Valet shops', 'valet shops', 'Wayfair', 'Bed Bath and Beyond',
        ];
        return marketPlacesNameArr.includes(value);
    }, 'Unknown market place.'),
    link: StringType().isURL('Please enter the correct URL address').isRequired('Link is required.'),
});

// validation schema from product model
export const productModel = SchemaModel({
    Sku: StringType().isRequired('Sku is required.')
        .addRule((value, data) => {
            if (data.admin || ['cmgJ1BDRnIZsSDFtkd71bfB2NRP2', 'v9divc3qE1Wm67auRj7RlwiVhow1', 'iawwHqplzzL7WKpJnZ8aLnYZ94T2'].includes(data.Uid)) {
                return true;
            }
            const productStatus = data.productStatus ? data.productStatus.label : '';
            if (value.length > 30 && productStatus.toLowerCase() !== 'published') {
                return false;
            }
            return true;
        }, 'Sku cannot be more than 30 characters.'),
    ParentSku: StringType(),
    Name: StringType().isRequired('Name is required.').maxLength(140, 'Name cannot be greater than 140 characters'),
    Weight: NumberType().isRequired('Weight is required.').min(0, 'Enter a valid weight.'),
    Length: NumberType().min(0, 'Enter a valid length.'),
    Height: NumberType().min(0, 'Enter a valid height.'),
    Width: NumberType().min(0, 'Enter a valid Width.'),
    Price: NumberType().isRequired('Price is required.').min(0, 'Enter a valid Price.'),
    Description: StringType().isRequired('Description is required.'),
    selectedCategory: StringType().isRequired('Catergory is required.'),
    Brand: StringType().isRequired('Brand is required.'),
    Seo: StringType(),
    BuyPrice: NumberType().addRule((value, data) => {
        if (data.IsSimpleProduct && value < 0) {
            return false;
        }
        return true;
    }, 'Enter a valid retail price.'),
    MaxDiscount: NumberType().addRule((value, data) => {
        if (data.IsSimpleProduct && value < 0) {
            return false;
        }
        return true;
    }, 'Invalid max discount price.'),
    QuantityOnHand: NumberType().isRequired('Quantity is required.').addRule((value, data) => {
        if (data.IsSimpleProduct && value < 0) {
            return false;
        }
        return true;
    }, 'Enter a valid quantity.'),
});

// validation schema for product variation
export const childProductModel = SchemaModel({
    Price: NumberType().isRequired('Price is required.').min(0, 'Enter a valid Price.'),
    RetailPrice: NumberType().min(0, 'Enter a valid Price.'),
    Sku: StringType().isRequired('Sku is required.')
        .addRule((value, data) => {
            if (data.admin) {
                return true;
            }
            const productStatus = data.productStatus ? data.productStatus.label : '';
            if (value.length > 30 && productStatus.toLowerCase() !== 'published') {
                return false;
            }
            return true;
        }, 'Sku cannot be more than 30 characters.'),
})

// helper function to check bulk products for existing descriptions
const checkForDescription = (value, CsvDescriptionText) => {
    if (value === CsvDescriptionText) {
        return false;
    }
    return true;
}

// default descriptions in the product CSV template
const DEFAULT_SKU_DESCRIPTION = 'A Stock Keeping Unit (SKU) is a distinct alphanumeric number associated with a distinct item within an organization.';
const DEFAULT_NAME_DESCRIPTION = 'The name of the product';
const DEFAULT_WEIGHT_DESCRIPTION = 'In lbs.';
const DEFAULT_PRICE_DESCRIPTION = 'Selling Price';
const DEFAULT_UPC_DESCRIPTION = '12 digit code';
const DEFAULT_DESCRIPTION = 'Detailed description of the product';
const DEFAULT_IMAGE_DESCRIPTION = 'Upload image URL';
const DEFAULT_CATEGORY_DESCRIPTION = 'Category';
const DEFAULT_RETAIL_PRICE_DESCRIPTION = 'MSRP';
const DEFAULT_QUANTITY_DESCRIPTION = 'Quantity of product';
const PARENT_SKU_DESCRIPTION = "If you use variations, this is the parent SKU that joins your child SKU's together"
const DEFAULT_BRAND_DESCRIPTION = '';

// validation schema for bulk product (that is, products from CSV file)
export const bulkProductModel = SchemaModel({
    ParentSku: StringType()
        .addRule((value) => checkForDescription(value, PARENT_SKU_DESCRIPTION), 'Remove all description'),
    Brand: StringType()
        .isRequired('Brand is required.')
        .addRule((value) => checkForDescription(value, DEFAULT_BRAND_DESCRIPTION), 'Remove all description'),
    Sku: StringType()
        .isRequired('Sku is required.')
        .maxLength(30, 'Sku cannot be greater than 30 characters')
        .addRule((value) => checkForDescription(value, DEFAULT_SKU_DESCRIPTION), 'Remove all description'),
    Name: StringType().isRequired('Name is required.')
        .maxLength(140, 'Name cannot be greater than 140 characters')
        .addRule((value) => checkForDescription(value, DEFAULT_NAME_DESCRIPTION), 'Remove all description'),
    Weight: NumberType('Weight should be a positive interger value.')
        .isRequired('Weight is required.')
        .min(0, 'Weight a valid weight, Should be a positive interger.')
        .addRule((value) => checkForDescription(value, DEFAULT_WEIGHT_DESCRIPTION), 'Remove all description'),
    Upc: NumberType()
        .isRequired('UPC is required.')
        .addRule((value) => checkForDescription(value, DEFAULT_PRICE_DESCRIPTION), 'Remove all description'),
    Price: NumberType('Price should be a positive interger value.')
        .isRequired('Price is required.')
        .min(0, 'Enter a valid Price, Should be a positive interger.')
        .addRule((value) => checkForDescription(value, DEFAULT_UPC_DESCRIPTION), 'Remove all description'),
    Description: StringType()
        .isRequired('Description is required.')
        .addRule((value) => checkForDescription(value, DEFAULT_DESCRIPTION), 'Remove all description'),
    Image1: StringType()
        .isRequired('Image URL is required.')
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image2: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image3: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image4: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image5: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image6: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Image7: StringType()
        .isURL('Please enter the correct URL address')
        .addRule((value) => checkForDescription(value, DEFAULT_IMAGE_DESCRIPTION), 'Remove all description'),
    Category: StringType()
        .isRequired('Catergory is required.')
        .addRule((value) => checkForDescription(value, DEFAULT_CATEGORY_DESCRIPTION), 'Remove all description'),
    RetailPrice: NumberType('Retail price should be a positive interger value.')
        .min(0, 'Enter a retail Price.')
        .addRule((value) => checkForDescription(value, DEFAULT_RETAIL_PRICE_DESCRIPTION), 'Remove all description'),
    QuantityOnHand: NumberType('Quantity should be a positive interger value.')
        .isRequired('Quantity is required.')
        .addRule((value) => checkForDescription(value, DEFAULT_QUANTITY_DESCRIPTION), 'Remove all description'),
});


export const trackingModel = SchemaModel({
    trackingNumber: StringType().isRequired('Tracking Number is required.'),
    trackingUrl: StringType().isURL('Please enter the correct tracking URL'),
    carrier: StringType().isRequired('Carrier is required.'),
})
