import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import history from '../../../utils/history';
import firebase1 from '../../../utils/config';
import { Link } from 'react-router-dom';
import Tour from "react-product-intro";
import { Tooltip } from 'reactstrap';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      email:'',
      firstname:'',
      profile_photo:'',
      isTourActive: false,
      tourStep: 1
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    // FB.CustomerChat.show(true);
    const currentUser = firebase1.app.auth().currentUser;
    if(currentUser==undefined){
      history.push("/");
    }
    else if(currentUser){
      this.setState({email:currentUser.email})
    }
    this.props.getUser();
    document.addEventListener('mousedown', this.handleClickOutside);

  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.userdetails!=undefined&&nextProps.userdetails!=this.props.userdetails){
       this.updateUser(nextProps.userdetails);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({collapse: false})
    }
  }


  updateUser(user){
    let profilePhoto = '';
    if(user.profile_photo){
      profilePhoto = user.profile_photo;
    }
    this.setState({email:user.email,profile_photo:profilePhoto,firstname:user.firstname})
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse})
    //   ,()=>{
    //   if(!this.state.isTourActive && this.props.userdetails && !this.props.userdetails.walkthroughDone){
    //     let topRightMenuSeen = typeof localStorage.topRightMenuSeen != "undefined" ? localStorage.topRightMenuSeen : false;
    //     switch("topRightMenuSeen") {
    //       case "topRightMenuSeen" : 
    //         if(!topRightMenuSeen) {
    //           //show walkthrough as per current page
    //           this.setState({
    //             isTourActive: true
    //           });        
    //           localStorage.topRightMenuSeen = true;	
    //         }      
    //         break;
    //       }
    //   }
    // });
  };

  logout(){
    localStorage.removeItem('role');
    localStorage.removeItem('addProductSeen');
    localStorage.removeItem('productSeen');
    localStorage.removeItem('inventorySeen');
    localStorage.removeItem('salesSeen');
    localStorage.removeItem('dashboardSeen');
    localStorage.removeItem('topRightMenuSeen');
    this.props.logout()

  }
  onCloseTour=()=>{
    this.setState({ isTourActive: false,collapse: false })
  }

  restartWalkthrough=()=>{
    localStorage.removeItem('addProductSeen');
    localStorage.removeItem('productSeen');
    localStorage.removeItem('inventorySeen');
    localStorage.removeItem('salesSeen');
    localStorage.removeItem('dashboardSeen');
    localStorage.removeItem('topRightMenuSeen');
    this.props.changeWalkthroughFlag()
    // setTimeout(function(){
      history.push('products')
    // },1000)
    
  }

  render() {
    const {t} = this.props.setOptions
    return (
      <>
      <div className="overlayIntro overlayHelp" >
          <Tour
          active={this.state.isTourActive}
          step={this.state.tourStep}
          onNext={(step) => this.setState({ tourStep: step })}
          onBack={(step) => this.setState({ tourStep: step })}
          onCancel={() => this.onCloseTour()}
          hideSteps={true}
          steps={[
            {
              step: 1,
              selector: ".help11",
              title: <div style={{ color: "blue" }}>Contact us</div>,
              body: <div style={{ color: "green" }}>You’re all set to start selling! Remember, if you need any help, you can contact us here </div>
            }
          ]}
        />
        </div>
      <div ref={this.wrapperRef} className="topbar__profile top_profilename">
        <button className="topbar__avatar" onClick={this.toggle}>
          {this.state.firstname!=''&&this.state.profile_photo!=''&&<img className="topbar__avatar-img" src={this.state.profile_photo} alt="avatar" />}
          {this.state.firstname!=''&&this.state.profile_photo==''&&<img className="topbar__avatar-img" src="../../img/pro_img.jpg" alt="avatar" />}
          {/* <p className="topbar__avatar-name">{this.state.email}</p> */}
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse id="topBar__Id" isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu" onClick={() => { this.setState({collapse:false})}}>
            <Link to="/pages/profile"><span>Profile</span></Link>
          </div>
          <div className="topbar__menu help11" onClick={() => { this.setState({collapse:false})}}>
          <Link to="/pages/help"><span>Help</span></Link>
            {/* <a href="/pages/help" target="_blank" ><span>Help</span></a> */}
          </div>
          <div className="topbar__menu" onClick={() => { this.setState({collapse:false})}}>
            <a href="javascript:void(0)" onClick={() => { this.logout() }}><span>Logout</span></a>
          </div>
        </Collapse>
      </div>
          {/* <div className="questionMark">
          <div className="tooltipMark">
            <img src="../img/question.svg" onClick={this.restartWalkthrough} height="40" alt=""/>
            <span className="tooltipText">Valet Seller Tour</span>
          </div>
          
        </div> */}
  
      </>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  const {user,userdetails} = authReducer;
  return {user,userdetails};
};

export default connect(mapStateToProps, actions)(TopbarProfile);
