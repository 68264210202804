import React, { PureComponent } from 'react';
import api from '../../../utils/api';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import Select from 'react-select';

import { Card, CardBody, Col, Button, Input, FormGroup, Label } from 'reactstrap';


class TopsellerCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:'',
      headers:[
        {key:'sku',title:'SKU'},
        {key:'product_title',title:'Product Title'},
        {key:'price',title:'Price'},
        {key:'TotalAmount',title:'Sales'},
        {key:'volume',title:'Quantity Ordered'}
      ],
      name:'TopProduct',
      items:[],
      tempItems:[],
      totalPage:0,
      tableLoading:true,

      channelFilter:[
        {value:-1,label:'All'},
        {value:'Amazon',label:'Amazon'},
        {value:'ebay',label:'eBay'},
        {value:'Walmart',label:'Walmart'}
      ]
    };
  }


  render() {

    return (
      
      <Col id="top_products__table" md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">TOP Products</h5>
              <Col sm="5" className="channel_div">
              {/* <span>
                <Label style={{fontSize:12}}>Channel</Label>
                  <Select
                    value={this.props.channel}
                    onChange={this.props.handleChannelChange}
                    options={this.state.channelFilter}
                  />
              </span> */}
              </Col>
            </div>
            <div>
              <TableList headers={this.state.headers} showPageSizeOptions={false} company={this.props.company}
                      					filename={this.props.filename} name={this.state.name} normal={true}  items={this.props.topProducts} />
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

}

const mapStateToProps = ({ curdReducer }) => {
  const {list_companies} = curdReducer;
  return {list_companies};
};

export default connect(mapStateToProps, actions)(TopsellerCard);

