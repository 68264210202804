import React, { PureComponent } from 'react';
import api from '../../../utils/api';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import { Link } from 'react-router-dom';
import {
	Card,
	CardBody,
	Col,
	Button,
	Input,
	FormGroup,
	Label,
} from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SliderCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			searchText: '',
			headers: [
				{ key: 'Sku', title: 'SKU' },
				{ key: 'price', title: 'Price' },
				{ key: 'deliver_stock', title: 'Stock' },
			],
			name: 'Inventory',
			items: [],
			tempItems: [],
			totalPage: 0,
			tableLoading: true,
		};
	}

	render() {
		return (
			<Col className='inventory_status' md={6}>
				<Card className='plus_border'>
					<CardBody style={{ padding: 0 }}>
						<Carousel
							showThumbs={false}
							onClickItem={this.click_item}
							showStatus={false}
							autoPlay={true}
							interval={10000}
							infiniteLoop={true}
						>
							<div>
								<h1>Set Advertising Budget</h1>
								<p>
									Setting a marketing budget increases your product visibility
									and competitiveness on Valet Seller's third party marketplace
									channels.
								</p>
								<Link to='/pages/advertising'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Set Discounts</h1>
								<p>
									Merchandise your products by setting a maximum discount level.
									This can be used as coupon codes, credits, and discounts.
								</p>
								<Link to='/pages/discount'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Listing Optimization</h1>
								<p>
									Valet Seller's team of expert copywriters will optimize your
									product listing to stand out among competitors and rank higher
									in marketplace search engines.
								</p>
								<Link to='/pages/listingoptimization'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Product Photography</h1>
								<p>
									Get high quality product or model images that sell better -
									quickly and affordably.
								</p>
								<Link to='/pages/productphotography'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Enhanced Brand Content</h1>
								<p>
									With Enhanced Brand Content, We are able to add enhanced
									images and text that will enhance your brand's story.
								</p>
								<Link to='/pages/enhancedbrand'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Get More Reviews</h1>
								<p>
									Reviews are an essential part of increasing your product
									ranking and gaining traction. Incentivize buyers to leave
									reviews for your products.
								</p>
								<Link to='/pages/morereviews'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
							<div>
								<h1>Professional Storefront Design</h1>
								<p>
									Highlight your catalog, collection, and brand with Valet
									Seller's Storefront Design Service
								</p>
								<Link to='/pages/professionalstorefront'>
									<Button>LEARN MORE</Button>
								</Link>
								<Label className='plus_plus'>PLUS</Label>
							</div>
						</Carousel>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

const mapStateToProps = ({ curdReducer }) => {
	const { list_companies } = curdReducer;
	return { list_companies };
};

export default connect(mapStateToProps, actions)(SliderCard);
