import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';


class LogIn extends PureComponent {


  constructor(props) {
    super(props)
    this.state = {
    };
  }
  render() {
        const {t} = this.props.setOptions
    return (
      <div className="account">
    {/* <div className="account__left">
      <img className="" src="img/bg.png" ></img>
    </div> */}
    <div className="account__wrapper" >
      <div className="account__card">
        <div className="account__card_inner">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo"> <img src='../img/logo1.png' alt=""/>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Welcome Back</h4>
        </div>
        <LogInForm {...this.props}/>
        </div>
      </div>
    </div>
  </div>
    )

  }

}
export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
