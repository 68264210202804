
// Walkthrough for the dashboard
import React, { Component } from 'react';
import Tour from "react-product-intro";
import history from '../../../utils/history';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
    DASHBOARD
  } from '../../Walkthrough/popupText';

class DashboardWalkthrough extends Component {
    constructor() {
        super();
        this.state = {

            isTourActive: false,
            tourStep: 1
        };
    }

    componentDidMount() {
        if (this.props.userdetails && !this.props.userdetails.walkthroughDone) {
            this.setState({isTourActive:true})
        }
    }



    onNextWalkthrough = (step) => {
        if (step == 2) {
            this.setState({
              isTourActive: false
            });
            history.push('branding')
          } else {
            this.setState({
              isTourActive: false
            });
          }   
    }
    onCancelWalkthrough=()=>{
        this.setState({ isTourActive: false})
        this.props.addWalkthroughFlag()
    }

    render() {
        return (
            <div className="overlayIntro">
                {this.state.isTourActive && (
                    <Tour
                        active={this.state.isTourActive}
                        step={this.state.tourStep}
                        onNext={(step) => this.onNextWalkthrough(step)}
                        onBack={(step) => this.setState({ tourStep: step })}
                        onCancel={() => this.onCancelWalkthrough()}
                        hideSteps={true}
                        steps={[
                            {
                                step:1,
                                //tourStepsCounterStyle: { color: "blue" },
                                selector: ".dashb",
                                title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                                body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>{DASHBOARD}</div>
                            },{
                                step: 2,
                                selector: ".step1",
                                title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                                body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>On your dashboard, you will find summarized information on how your listings are performing.</div>
                            }
                        ]}
                    />
                )}
            </div>
        )
    }


}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
    const { user, userdetails } = authReducer;
    return { user, userdetails };
};

export default connect(mapStateToProps, actions)(DashboardWalkthrough);