import React, { PureComponent, Fragment } from 'react';
import api from '../../../utils/api';
import helper, { flaggedKeywords } from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';
import { findAll } from "highlight-words-core";
import {
	Card,
	CardBody,
	Col,
	Input,
	Button,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
} from 'reactstrap';
import Select, { Creatable } from 'react-select';
import ReactTooltip from 'react-tooltip';
import Tour from 'react-product-intro';
import TopbarProfile from '../../Layout/topbar/TopbarProfile';
import {
	GLOBAL_LISTING_ATTRIBUTES,
	HELP,
	WAREHOUSE,
	MARKETING_PLACE,
	ADD_BUTTON,
	CANCEL_BUTTON,
} from '../../Walkthrough/popupText';
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser';
import getCroppedImg from './cropImage';
import { getRotatedImage, getImageSize } from './rotateImage';

import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import {
	EditorState,
	convertToRaw,
	convertFromHTML,
	ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Switch from 'react-switch';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import htmlToDraft from 'html-to-draftjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import ProductImage from '../../Table/ProductImage';
import S3 from 'react-aws-s3';
// import UpgradePopup from '../../../components/UpgradePopup';
import PayPalBtn from "../../../components/PayPalBtn";
import ReactTable from "react-table";
import { productModel, childProductModel, validateDraftProduct } from '../../../utils/validationSchemas';
import { xorBy } from 'lodash';

const amazonConfig = {
	bucketName: 'valetseller-products',
	dirName: 'product' /* optional */,
	region: 'us-east-1',
	accessKeyId: 'AKIAVHMPV5FGADY56KOP',
	secretAccessKey: '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',
	s3Url: 'https://valetseller-products.s3.amazonaws.com' /* optional */,
};

const ReactS3Client = new S3(amazonConfig);

const categoryList = [
	{ value: 'Accessories', label: 'Accessories' },
	{ value: 'Apparel', label: 'Apparel' },
	{ value: 'Baby', label: 'Baby' },
	{ value: 'Beauty', label: 'Beauty' },
	{ value: 'Electronics', label: 'Electronics' },
	{ value: 'Entertainment', label: 'Entertainment' },
	{ value: 'Grocery', label: 'Grocery' },
	{ value: 'Hobby', label: 'Hobby' },
	{ value: 'Home', label: 'Home' },
	{ value: 'Jewelry', label: 'Jewelry' },
	{ value: 'Kitchen', label: 'Kitchen' },
	{ value: 'Casual', label: 'Casual' },
	{ value: 'Shoes', label: 'Shoes' },
	{ value: 'Pets', label: 'Pets' },
	{ value: 'Sporting Goods', label: 'Sporting Goods' },
	{ value: 'Toys', label: 'Toys' },
	{ value: 'Wellness', label: 'Wellness' },
];

const notnewList = [
	{ value: 'Used', label: 'Used' },
	{ value: 'Refurbished', label: 'Refurbished' },
	{ value: 'Acceptable', label: 'Acceptable' },
	{ value: 'GOOD', label: 'GOOD' },
	{ value: 'Like New', label: 'Like New' },
	{ value: 'Very Good', label: 'Very Good' },
	{ value: 'New with box/tags', label: 'New with box/tags' },
	{ value: 'New without box/tags', label: 'New without box/tags' },
	{ value: 'Pre-owned', label: 'Pre-owned' },
	{ value: 'Vintage', label: 'Vintage' },
	{ value: 'Second', label: 'Second' },
];

const IsCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '380px',
		height: '450px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

const editImageStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '680px',
		height: '480px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

const uploadVideoStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '480px',
		height: '200px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

const purchaseUpcStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '400px',
		height: '375px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

const selectUpcStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '400px',
		height: '480px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};


const ORIENTATION_TO_ANGLE = {
	'3': 180,
	'6': 90,
	'8': -90,
};

const styles = reactCSS({
	default: {
		color: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			// background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
		},
		swatch: {
			padding: '5px',
			background: '#fff',
			borderRadius: '1px',
			boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
			display: 'inline-block',
			cursor: 'pointer',
		},
		popover: {
			position: 'absolute',
			zIndex: '2',
		},
		cover: {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		},
	},
});
const options = {
	buttons: {
		backgroundColor: '#1C7293',
		iconColor: '#e8eddf',
		iconPadding: '7px',
	},
	caption: {
		captionColor: '#e8eddf',
		captionFontSize: '30px',
	},
	settings: {
		overlayColor: 'rgba(6, 90, 130, 1)',
	},
	thumbnails: {
		thumbnailsAlignment: 'start',
	},
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: 2 * 2,
	margin: `0 ${2}px 0 0`,

	// change background colour if dragging
	background: isDragging ? '#fff' : '#fff',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#f3f3f3' : 'transparent',
	display: 'flex',
	padding: 2,
	overflow: 'auto',
});

class ProductVariation extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			productValidation: {},
			isLoading: true,
			searchText: '',
			isUpdate: false,
			Id: '',
			key: '',
			userCompany: '',
			CompanyId: '',
			productStatus: null,
			brandStatus: null,
			selectedCategory: null,
			notNew: { value: 'Used', label: 'Used' },
			youTubeId: '',
			youtubeUrl: '',
			MaxDiscount: 0,
			isProductNew: false,
			isBlockIdentifiers: false,
			isDiscountPrice: false,
			brandStatusType: [
				{ value: 'our_brandname', label: 'Use A Valet Seller Brand' },
				{ value: 'your_brandname', label: 'Use Your Brand Name' },
			],
			OurBrandStatusType: [
				{ value: 'discount_trends', label: 'Discount Trends' },
				{ value: 'primo_groceries', label: 'Primo Groceries' },
				{ value: 'maya_grace', label: "Maya's Grace" },
				{ value: 'ice_city', label: 'Ice City' },
			],
			productType: [
				{ value: 'Product', label: 'Product' },
				{ value: 'Kit', label: 'Kit' },
				{ value: 'Built', label: 'Built' },
			],
			statusType: [
				{ value: 'draft', label: 'Draft' },
				{ value: 'pending_reviewal', label: 'Pending Reviewal' },
				{ value: 'under_review', label: 'Under Review' },
				{ value: 'published', label: 'Published' },
			],
			globalType: [
				{ value: 'New', label: 'New' },
				{ value: 'Used', label: 'Used' },
			],
			mimeType: [
				{ value: 'image/gif', label: 'image/gif' },
				{ value: 'image/png', label: 'image/png' },
				{ value: 'image/jpeg', label: 'image/jpeg' },
			],
			selectSellType: [],
			Sku: '',
			Name: '',
			BrandName: '',
			ProductAttributes: [],
			Type: null,
			TypeValue: null,
			QuantityOnHand: 0,
			MyBrandStatus: null,
			Identifiers: {
				UPC: '',
				EAN: '',
				ISBN: '',
				GTIN: '',
			},
			ProductUid: '',
			IsAutoSyncEnabled: true,
			isNotNewProduct: false,
			IsDiscontinued: false,
			IsDropshipped: false,
			IsFireArm: false,
			IsVariation: false,
			IsParent: false,
			IsColor: false,
			IsSize: false,
			IsQuantity: false,
			IsCustom: false,
			IsCurrentVariations: true,
			sizeItems: [],
			BuyPrice: 0,
			Weight: 0,
			Length: 0,
			Width: 0,
			Height: 0,
			OurBrandStatus: null,
			editorState: EditorState.createEmpty(),
			GlobalListingAttributes: {
				SoldAsName: '',
				ShortDescription: '',
				LongDescription: '',
				Price: '',
				Condition: null,
				value: null,
				GlobalValue: null,
			},
			_id: '',
			Images: [
				{
					MIMEType: null,
					Url: '',
					DisplayOrder: '',
					value: '',
					title: '',
					icon: '',
					id: 1,
				},
			],
			skuList: [],
			Warehouses: [],

			SupplierInformation: {
				SupplierId: '',
				SKU: '',
				Price: '',
			},
			tempItems: [],
			isTourActive: false,
			tourStep: 1,
			Uid: '',
			modalIsOpen: false,
			crop: { x: 0, y: 0 },
			zoom: 1,
			aspect: 3 / 3,
			rotation: 0,
			croppedAreaPixels: null,
			imageIndex: -1,
			productVariations: [],
			colorItems: [],
			displayColorPicker: false,
			activeDisabled: true,
			Category: '',
			Seo: '',
			confirmmodal: false,
			IsSimpleProduct: true,
			ChildProducts: [],
			ProductSize: [],
			ProductVariationOptions: [],
			ProductColor: [],
			cartList: [],
			CustomList: [],
			Feature1: '',
			Feature2: '',
			Feature3: '',
			Feature4: '',
			Feature5: '',
			paypalBilling: '',
			admin: true,
			email: '',
			// isShowPopup: false,
			_id: '',
			purchaseUpcModal: false,
			upcQuantity: 1,
			upcPrice: 1,
			upcPurchases: [],
			inUpcs: [],
			selectUpcModal: false,
			selectedUPC: undefined,
			ProductVariations: [],
			userBrandHistory: [],
			childProductValidation: [],
			InitialProductStatus: null,
			LinksUploaded: null,
			flaggedKeywords: [],
		};
		this.toggle = this.toggle.bind(this);
		this.openModal = this.openModal.bind(this);
		this.editImageModal = this.editImageModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleActiveChange = this.handleActiveChange.bind(this);
		this.closeImageModal = this.closeImageModal.bind(this);
		this.redirect = this.redirect.bind(this);
		this.draftProduct = this.draftProduct.bind(this);
		this.bulkPublish = this.bulkPublish.bind(this);
	}

	componentDidMount() {
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
			if (!this.props.userdetails.walkthroughDone) {
				let dashboardSeen =
					typeof localStorage.dashboardSeen != 'undefined'
						? localStorage.dashboardSeen
						: false;
				let productSeen =
					typeof localStorage.productSeen != 'undefined'
						? localStorage.productSeen
						: false;
				let addProductSeen =
					typeof localStorage.addProductSeen != 'undefined'
						? localStorage.addProductSeen
						: false;
				let inventorySeen =
					typeof localStorage.inventorySeen != 'undefined'
						? localStorage.inventorySeen
						: false;
				let topRightMenuSeen =
					typeof localStorage.topRightMenuSeen != 'undefined'
						? localStorage.topRightMenuSeen
						: false;

				//check the currentRoute
				//currentTab
				switch ('addProduct') {
					case 'dashboard':
						if (!dashboardSeen) {
							//show walkthrough as per current page
							this.setState({
								isTourActive: true,
							});
							localStorage.dashboardSeen = true;
						}

						break;
					case 'product':
						if (!productSeen) {
							//show walkthrough as per current page
							this.setState({
								isTourActive: true,
							});
							localStorage.productSeen = true;
						}
						break;
					case 'addProduct':
						if (!addProductSeen) {
							//show walkthrough as per current page
							this.setState({
								isTourActive: true,
							});
							localStorage.addProductSeen = true;
						}
						break;
					case 'inventory':
						if (!inventorySeen) {
							//show walkthrough as per current page
							this.setState({
								isTourActive: true,
							});
							localStorage.inventorySeen = true;
						}
						break;
					case 'topRightMenu':
						if (!topRightMenuSeen) {
							//show walkthrough as per current page
							this.setState({
								isTourActive: true,
							});
							localStorage.topRightMenuSeen = true;
						}
						break;
					default:
						break;
				}
			}
		}
	}
	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	// handleCategoryChange = (Category) => {
	//   this.setState({ Category })
	// }
	toggle() {
		this.setState({
			confirmmodal: !this.state.confirmmodal,
		});
	}
	updateUser(user) {
		let subscription_id = '';
		let skuList = [];
		if (user.paypal_billing && user.paypal_billing.subscription_id) {
			subscription_id = user.paypal_billing.subscription_id;
		} else {
			subscription_id = 'none';
		}
		if (user.sku) {
			skuList = user.sku;
		}
		let cartList = user.sell_type ? user.sell_type : [];
		let valetCheckIndex = cartList.findIndex((data1) => {
			return data1.value === 'Valet Shop';
		});
		if (valetCheckIndex == -1) {
			cartList.push({ value: 'Valet Shop', label: 'Valet Shops' });
		} else {
			cartList[valetCheckIndex] = { value: 'Valet Shop', label: 'Valet Shops' };
		}
		let selectSellType = this.state.selectSellType;
		console.log('skuList===', selectSellType);
		if (selectSellType.length == 0) {
			selectSellType.push({ value: 'Valet Shop', label: 'Valet Shops' });
		}

		console.log('user.email==', user.email);
		this.setState(
			{
				userCompany: user.company,
				role: user.role.label,
				email: user.email,
				Uid: user.uid,
				skuList: skuList,
				selectSellType: selectSellType,
				subscription_id: subscription_id,
				cartList: cartList,
				key: user.key,
				admin: user.admin,
				paypalBilling: user.paypal_billing ? user.paypal_billing : '',
				upcPurchases: user.upcPurchases ? user.upcPurchases.upcs.filter(upc => !upc.sku) : [],
				inUpcs: user.upcPurchases ? user.upcPurchases.upcs.filter(upc => upc.sku) : [],
			},
			() => {
				if (this.props.match.params.id) {
					this.setState({ Id: Number(this.props.match.params.id) });
					this.getProduct();
				} else {
					this.getBrandList();
					let Images = [];
					this.setState({ Images: Images, Sku: this.getRandomSku(true) });
				}
			},
		);
	}

	getRandomSku(parent) {
		if (parent) {
			let sku = this.state.userCompany.replace(/\s/g, '');
			sku = sku + '-' + Date.now();
			return sku;
		} else {
			let sku = this.state.Sku;
			sku = sku.split('-');
			sku = sku[0] + '-' + sku[1] + '-' + Date.now();
			return sku;
		}
	}

	getChildSku(title) {
		let sku = this.state.Sku;
		title = title.toLowerCase();
		title = title.replace(/ /g, '-');
		title = title.substring(0, title.length - 1);
		sku = sku + '-' + title;
		return sku;
	}

	updateSku(newSku) {
		let skuList = this.state.skuList;
		skuList.push(newSku);
		const db = firebase1.app.firestore();
		db.collection('user').doc(this.state.key).update({ sku: skuList });
	}

	getBrandList() {
		this.setState({ isLoading: true });
		api
			.getApi(`Inventory/getBrandList?Uid=${this.state.Uid}`)
			.then((resdata) => {
				const data = resdata.data;
				this.setState({ isLoading: false });
				if (data) {
					const brandList = data.map((brand) => ({
						label: brand.BrandName,
						value: brand.BrandName,
					}));

					this.setState({
						brandStatusType: brandList.concat(this.state.brandStatusType),
						userBrandHistory: brandList,
					});
				}
			});
	}

	getProduct() {
		this.setState({ isLoading: true });
		api
			.getApi('Inventory/?sku=' + this.props.match.params.id)
			.then((resdata) => {
				let data = resdata.data;
				console.log('resdata==', resdata);
				if (data) {
					api.getApi('product/flaggedKeyword')
						.then((keywords) => {
							this.setState({
								isLoading: false,
								flaggedKeywords: keywords.map((keyword) => keyword.keyword),
							});
							this.setState({
								InitialProductStatus: data.ProductStatus,
								LinksUploaded: data.LinksUploaded,
							})
							if (Boolean(data.BrandName)) {
								this.setState({ MyBrandStatus: { label: data.BrandName, value: data.BrandName } });
							}
							this.assignSingleProduct(resdata, data);
						});
				} else {
					helper.errorMessage('Invalid product id');
					this.props.history.push('/pages/products');
				}
			});
	}

	// getMarketplaces() {
	// 	this.setState({ isLoading: true });
	// 	this.props.history.push('/pages/marketplaces');
	// }

	assignVariationProduct(data) {
		let skuSplit = data.Sku.split('-');
		let sku = '';
		for (let data in skuSplit) {
			if (data == 0) {
				sku = skuSplit[data];
			} else if (data < skuSplit.length - 1) {
				sku = sku + '-' + skuSplit[data];
			}
		}
		let apiPath = '';

		apiPath = 'inventory/getProducts';
		let reqData = {
			sku: sku,
			Pagination: {
				ResultsPerPage: 50,
				PageNumber: 1,
			},
		};

		api.postApi(apiPath, reqData).then(
			(resData) => {
				let variationProducts = [];
				if (resData.status == 'Success') {
					let productVariationImages = [];
					for (let item in resData.data) {
						if (resData.data[item].IsVariation) {
							variationProducts.push(resData.data[item]);
							productVariationImages = productVariationImages.concat(
								resData.data[item].Images,
							);
						}
					}
					console.log(
						'===variationProducts==',
						variationProducts,
						productVariationImages,
					);
					this.setState({ isLoading: false });
					if (0 < variationProducts.length) {
						this.assignSingleProduct(resData.data, variationProducts[0]);
						let ChildProducts = [];
						let productsData = variationProducts;
						for (let data in productsData) {
							ChildProducts.push({
								Image:
									this.state.Images.length == 0 ? '' : this.state.Images[0].Url,
								Variation: productsData[data].Name,
								Sku: productsData[data].Sku,
								UPC: productsData[data].Identifiers.UPC
									? productsData[data].Identifiers.UPC
									: '',
								EAN: productsData[data].Identifiers.EAN
									? productsData[data].Identifiers.EAN
									: '',
								ISBN: productsData[data].Identifiers.ISBN
									? productsData[data].Identifiers.ISBN
									: '',
								GTIN: productsData[data].Identifiers.GTIN
									? productsData[data].Identifiers.GTIN
									: '',
								Active: productsData[data].IsActive,
							});
						}
						console.log('ChildProducts===', ChildProducts);
						this.setState({ ChildProducts: [ ...ChildProducts ] });
					}
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ isLoading: false });
				helper.errorMessage(err);
			},
		);
		console.log('sku===', sku);
	}

	assignSingleProduct(resdata, data) {
		let TypeValue = null;
		let GlobalValue = null;
		let Identifiers = data.Identifiers;
		let GlobalListingAttributes = data.GlobalListingAttributes;
		let SupplierInformation = data.SupplierInformation;
		let Warehouses = data.Warehouses;

		for (let item in this.state.productType) {
			if (this.state.productType[item].value == data.Type) {
				TypeValue = this.state.productType[item];
			}
		}
		for (let item in this.state.globalType) {
			if (
				this.state.globalType[item].value == GlobalListingAttributes.Condition
			) {
				GlobalValue = this.state.globalType[item];
			}
		}
		let Images = data.Images;
		for (let item1 in Images) {
			for (let item in this.state.mimeType) {
				Images[item1].id = Number(item) + 1;
				if (this.state.mimeType[item].value == Images[item1].MIMEType) {
					Images[item1].value = this.state.mimeType[item];
				}
			}
		}
		if (Images == undefined) {
			Images = [];
		}
		if (Warehouses == undefined) {
			Warehouses = [];
		}

		let longDescription = convertFromHTML(
			GlobalListingAttributes.LongDescription
				? GlobalListingAttributes.LongDescription
				: '',
		);

		if (longDescription.contentBlocks) {
			let blockArray = ContentState.createFromBlockArray(longDescription);
			longDescription = EditorState.createWithContent(blockArray);
		}
		let productStatus = null;
		let brandStatus = null;
		if (data.ProductStatus && data.ProductStatus != '') {
			productStatus = this.state.statusType.find((data1) => {
				return data1.value === data.ProductStatus;
			});
			productStatus = productStatus ? productStatus : null;
		}
		let brandStatusType = this.state.brandStatusType;
		let brandList = resdata.brandList;

		for (let item in brandList) {
			brandStatusType.push({
				label: brandList[item].BrandName,
				value: brandList[item].BrandName,
			});
		}
		brandStatusType = brandStatusType.reverse();
		if (data.BrandStatus && data.BrandStatus != '') {
			brandStatus = brandStatusType.find((data1) => {
				return (
					String(data1.value).toLocaleLowerCase() ===
						String(data.BrandName).toLocaleLowerCase() &&
					data1.value != 'your_brandname'
				);
			});
			if (brandStatus == null) {
				brandStatus = this.state.brandStatusType.find((data1) => {
					return data1.value === data.BrandStatus;
				});
			}
		}

		Images = Images.sort(
			(a, b) =>
				Number(a.DisplayOrder) > Number(b.DisplayOrder)
					? 1
					: Number(b.DisplayOrder) > Number(a.DisplayOrder) ? -1 : 0,
		);

		this.setState(
			{
				_id: data._id,
				productStatus: productStatus ? productStatus : null,
				// brandStatus: brandStatus ? brandStatus : null,
				brandStatusType: [ ...brandStatusType ],
				Sku: data.Sku,
				Id: data.Id ? data.Id : '',
				_id: data._id,
				Name: data.Name,
				ProductUid: data.Uid ? data.Uid : '',
				BrandName: data.BrandName,
				CompanyId: data.CompanyId,
				Type: data.Type,
				TypeValue: TypeValue,
				QuantityOnHand: data.QuantityOnHand,
				OurBrandStatus: data.OurBrandStatus ? data.OurBrandStatus : null,
				BuyPrice: data.BuyPrice ? data.BuyPrice : 0,
				isBlockIdentifiers:
					data.isBlockIdentifiers != undefined
						? data.isBlockIdentifiers
						: false,
				isDiscountPrice:
					data.isDiscountPrice != undefined ? data.isDiscountPrice : false,
				Identifiers: {
					UPC: Identifiers ? (Identifiers.UPC ? Identifiers.UPC : '') : '',
					EAN: Identifiers ? (Identifiers.EAN ? Identifiers.EAN : '') : '',
					ISBN: Identifiers ? (Identifiers.ISBN ? Identifiers.ISBN : '') : '',
					GTIN: Identifiers ? (Identifiers.GTIN ? Identifiers.GTIN : '') : '',
				},
				IsAutoSyncEnabled: data.IsAutoSyncEnabled
					? data.IsAutoSyncEnabled
					: false,
				IsDiscontinued: data.IsDiscontinued ? data.IsDiscontinued : false,
				IsDropshipped: data.IsDropshipped ? data.IsDropshipped : false,
				IsFireArm: data.IsFireArm ? data.IsFireArm : false,
				IsVariation: data.IsVariation ? data.IsVariation : false,
				IsSimpleProduct: 0 < data.ProductVariations.length ? false : true,
				IsParent: data.IsParent ? data.IsParent : false,
				editorState: longDescription,
				GlobalListingAttributes: {
					SoldAsName: GlobalListingAttributes.SoldAsName
						? GlobalListingAttributes.SoldAsName
						: '',
					ShortDescription: GlobalListingAttributes.ShortDescription
						? GlobalListingAttributes.ShortDescription
						: '',
					LongDescription: GlobalListingAttributes.LongDescription
						? GlobalListingAttributes.LongDescription
						: '',
					Price: GlobalListingAttributes.Price
						? GlobalListingAttributes.Price
						: '',
					Condition: GlobalListingAttributes.Condition
						? GlobalListingAttributes.Condition
						: '',
					GlobalValue: GlobalValue,
				},
				Images: Images,
				Warehouses: Warehouses,
				ChildProducts: data.ProductVariations,
				ProductVariations: data.ProductVariations,
				selectSellType: data.SellType ? data.SellType : [],
				IsColor: data.ProductColor.length == 0 ? false : true,
				IsSize: data.ProductSize.length == 0 ? false : true,
				IsCustom: data.CustomList.length == 0 ? false : true,
				ProductColor: data.ProductColor ? data.ProductColor : [],
				ProductSize: data.ProductSize ? data.ProductSize : [],
				CustomList: data.CustomList ? data.CustomList : [],
				Weight: data.Weight ? Number(data.Weight) : '',
				Height: data.Height ? Number(data.Height) : '',
				Width: data.Width ? Number(data.Width) : '',
				Length: data.Length ? Number(data.Length) : '',
				Seo: data.Seo ? data.Seo : '',
				Feature1: data.Feature1 ? data.Feature1 : '',
				Feature2: data.Feature2 ? data.Feature2 : '',
				Feature3: data.Feature3 ? data.Feature3 : '',
				Feature4: data.Feature4 ? data.Feature4 : '',
				Feature5: data.Feature5 ? data.Feature5 : '',
				selectedCategory: data.Category
					? { label: data.Category, value: data.Category }
					: null,
				SupplierInformation: {
					SupplierId: SupplierInformation ? SupplierInformation.SupplierId : '',
					SKU: SupplierInformation ? SupplierInformation.SKU : '',
					Price: SupplierInformation ? SupplierInformation.Price : '',
				},
				youtubeUrl: data.youtubeUrl ? data.youtubeUrl : '',
				youTubeId: data.youTubeId ? data.youTubeId : '',
			},
			() => {
				this.firstChildProducts();
			},
		);
	}

	handleProductChange = (Type) => {
		this.setState({ Type: Type.value, TypeValue: Type });
	};

	handleGlobalChange = (Condition) => {
		let GlobalListingAttributes = this.state.GlobalListingAttributes;
		GlobalListingAttributes.Condition = Condition.value;
		GlobalListingAttributes.GlobalValue = Condition;
		this.setState({
			GlobalListingAttributes: GlobalListingAttributes,
			isUpdate: !this.state.isUpdate,
		});
	};

	discountIdentifiers() {
		let isDiscountPrice = !this.state.isDiscountPrice;
		console.log('isDiscountPrice===', isDiscountPrice);
		this.setState({ isDiscountPrice: isDiscountPrice });
	}

	updateIdentifiers(value, type) {
		let Identifiers = this.state.Identifiers;
		Identifiers[type] = value;
		this.setState({ Identifiers: Identifiers, isUpdate: !this.state.isUpdate });
	}

	updateGlobalAttributes(value, type) {
		let GlobalListingAttributes = this.state.GlobalListingAttributes;
		GlobalListingAttributes[type] = value;
		this.setState({
			GlobalListingAttributes: GlobalListingAttributes,
			isUpdate: !this.state.isUpdate,
		});
	}

	onEditorStateChange: Function = (editorState) => {
		let description = '';
		if (editorState) {
			description = editorState.getCurrentContent().getPlainText();
		}

		const unwantedWordsFound = [];
		description = description.replace(/(\r\n|\n|\r)/gm, " ");
		description = description.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ")
        for (const word of this.state.flaggedKeywords) {
            if((` ${description || ''} `.toLocaleLowerCase()).indexOf(` ${word.toLocaleLowerCase()} `) !== -1){
                unwantedWordsFound.push(word);
            }
        }
		const validation = {
			Description: {
			  hasError: Boolean(unwantedWordsFound.length),
			  errorMessage: `Description should not contain flagged keywords like ${unwantedWordsFound.join(', ')}`
			},
		};

		this.setState({
			productValidation: validation,
			editorState: editorState,
			isUpdate: !this.state.isUpdate,
		});
	};

	addImage() {
		let Images = this.state.Images;
		if (Images.length < 9) {
			if (Images.length > 0 && Images[Images.length - 1].Url == '') {
				helper.errorMessage('please upload image');
			} else {
				this.fileInput.click();
			}
		} else {
			helper.errorMessage('Minimum 9 images ');
		}
	}

	addWareHouses() {
		let Warehouses = this.state.Warehouses;
		if (
			Warehouses.length > 0 &&
			Warehouses[Warehouses.length - 1].WarehouseId == ''
		) {
			helper.errorMessage('please enter WarehouseId');
		} else {
			Warehouses.push({
				WarehouseId: '',
				Aisle: '',
				Bin: '',
				QuantityOnHand: '',
				SKU: '',
				IsActive: true,
			});
			this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate });
		}
	}

	handleStatusChange = (productStatus) => {
		this.setState({ productStatus: productStatus });
	};

	handleBrandStatusChange = (brandStatus) => {
		let BrandName = this.state.BrandName;
		if (
			brandStatus.value == 'your_brandname' ||
			brandStatus.value == 'our_brandname'
		) {
			BrandName = '';
		} else {
			BrandName = brandStatus.value;
		}
		this.setState({ brandStatus: brandStatus, BrandName: '' });
	};

	handleOurBrandStatusChange = (OurBrandStatus) => {
		this.setState({ OurBrandStatus: OurBrandStatus, BrandName: OurBrandStatus.label });
	};

	handleCategoryChange = (selectedCategory) => {
		this.setState({ selectedCategory: selectedCategory });
	};

	handlenotNewChange = (notNew) => {
		this.setState({ notNew: notNew });
	};

	handleMimeChange = (mimeType, index) => {
		let Images = this.state.Images;
		Images[index].MIMEType = mimeType.value;
		Images[index].value = mimeType;
		this.setState({ Images: Images, isUpdate: !this.state.isUpdate });
	};

	updateImageState(value, field, index) {
		let Images = this.state.Images;
		Images[index][field] = value;
		this.setState({ Images: Images, isUpdate: !this.state.isUpdate });
	}

	removeImage(index) {
		let Images = this.state.Images;
		Images.splice(index, 1);
		this.setState({ Images: Images, isUpdate: !this.state.isUpdate });
	}

	updateWareHouseState(value, field, index) {
		let Warehouses = this.state.Warehouses;

		if (field == 'IsActive') {
			Warehouses[index][field] = !Warehouses[index][field];
			//console.log('value==', Warehouses[index][field]);
		} else {
			Warehouses[index][field] = value;
		}

		this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate });
	}

	removeWareHouses(index) {
		let Warehouses = this.state.Warehouses;
		Warehouses.splice(index, 1);
		this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate });
	}

	updateSupplier(value, field) {
		let SupplierInformation = this.state.SupplierInformation;
		SupplierInformation[field] = value;
		this.setState({
			SupplierInformation: SupplierInformation,
			isUpdate: !this.state.isUpdate,
		});
	}

	updateIdentifiers(value, field) {
		let Identifiers = this.state.Identifiers;
		Identifiers[field] = value;
		this.setState({ Identifiers: Identifiers, isUpdate: !this.state.isUpdate });
	}

	removeImageFile(index) {
		let Images = this.state.Images;
		Images[index].Url = '';
		Images[index].file = '';
		Images.splice(index, 1);
		this.setState({ Images: Images, time: new Date().getTime() });
	}

	photoOnChange = async (e, field, index) => {
		if (e.target.files.length > 0) {
			const file: File = e.target.files[0];
			//console.log(file);
			let that = this;
			try {
				// let Images = that.state.Images;
				// Images[index].file = file
				console.log('file===', Number(file.size));
				if (file.size > 10000000) {
					helper.errorMessage('Please upload file less than 10MB.');
				} else {
					const orientation = await getOrientation(file);
					var reader = new FileReader();
					reader.onload = function(e) {
						// Images[index].Url = e.target.result;
						if (that.fileInput) {
							that.fileInput.value = '';
						}
						that.checkImage(e.target.result, orientation, index);
					};
					reader.readAsDataURL(file);
				}
			} catch (err) {
				//console.log(err);
			} finally {
				//this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
			}
		}
	};

	async checkImage(imageDataUrl, orientation, index) {
		const imageSize = await getImageSize(imageDataUrl);
		if (imageSize && (imageSize.width < 500 || imageSize.height < 500)) {
			helper.errorMessage(
				'That image is too small. It needs to be at least 500 x 500 pixels',
			);
		} else {
			this.rotatedImage(imageDataUrl, orientation, index);
		}
	}

	async rotatedImage(imageDataUrl, orientation, index) {
		const rotation = ORIENTATION_TO_ANGLE[orientation];
		// if (rotation) {
		//   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
		// }
		this.setState({
			modalIsOpen: true,
			imageIndex: index,
			imageSrc: imageDataUrl,
			rotation: 0,
			crop: { x: 0, y: 0 },
			zoom: 1,
		});
	}

	async croppedImage() {
		//console.log(this.state.croppedAreaPixels);
		//console.log(this.state.rotation);
		const croppedImage = await getCroppedImg(
			this.state.imageSrc,
			this.state.croppedAreaPixels,
			this.state.rotation,
		);

		let Images = this.state.Images;
		Images.push({
			MIMEType: null,
			Url: croppedImage.url,
			DisplayOrder: '',
			value: '',
			title: '',
			icon: croppedImage.url,
			file: croppedImage.file,
		});

		this.setState({ Images: Images, modalIsOpen: false });
	}

	onCropChange = (crop) => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({ croppedAreaPixels: croppedAreaPixels });
		////console.log(croppedArea, croppedAreaPixels)
	};

	onZoomChange = (zoom) => {
		this.setState({ zoom });
	};

	onRotate = () => {
		let rotation = this.state.rotation;
		if (rotation == 0) {
			rotation = 90;
		} else if (rotation == 90) {
			rotation = 180;
		} else if (rotation == 180) {
			rotation = 270;
		} else if (rotation == 270) {
			rotation = 360;
		} else if (rotation == 360) {
			rotation = 0;
		}
		this.setState({ rotation: rotation });
	};

	draftProduct(isUpc) {
		const {
			MaxDiscount,
			BuyPrice,
			Sku,
			Name,
			QuantityOnHand,
			Identifiers,
			IsAutoSyncEnabled,
			IsDiscontinued,
			IsDropshipped,
			Weight,
			Length,
			Width,
			Height,
			IsFireArm,
			brandStatusType,
			IsVariation,
			IsParent,
			ChildProducts,
			GlobalListingAttributes,
			Images,
			Warehouses,
			SupplierInformation,
			IsSimpleProduct,
			editorState,
			brandStatus,
			BrandName,
			selectedCategory,
			OurBrandStatus,
		} = this.state;

		const Brand = (brandStatus && (brandStatus.value === 'our_brandname')) ? (OurBrandStatus && OurBrandStatus.value) : BrandName;

		let Description = '';
		if (editorState) {
			let block = convertToRaw(editorState.getCurrentContent());
			Description = editorState.getCurrentContent().getPlainText();
			GlobalListingAttributes.LongDescription = draftToHtml(block);
		}

		if (ChildProducts.length === 0 && !IsSimpleProduct) {
			helper.errorMessage('Please add one variant');
			return;
		};

		let validateChildProducts = [];
		let variationsAreValid = true;
		if (!IsSimpleProduct) {
			// validation all draft product variations
			validateChildProducts = ChildProducts.map((childProduct) => {
				if (childProduct.Active) {
					const checkChild = childProductModel.check({
						Sku: childProduct.Sku,
						Price: Number(childProduct.Price),
						admin: this.state.admin,
						// RetailPrice: Number(childProduct.BuyPrice),
						// QuantityAvailable: Number(childProduct.QuantityAvailable)
					});
					const isValid = !Object.keys(checkChild).map((key) => checkChild[key].hasError).includes(true);
					if (!isValid) {
						variationsAreValid = false;
					}
					return {...checkChild, isValid};
				}
				return {
					Price: {},
					RetailPrice: {},
					Sku: {},
				};
			});
		}

		// validation for all draft product fields
		const check = validateDraftProduct.check({
			Sku,
		});

		const unwantedWordsFound = [];
		Description = Description.replace(/(\r\n|\n|\r)/gm, " ");
		Description = Description.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ")
        for (const word of this.state.flaggedKeywords) {
            if((` ${Description || ''} `.toLocaleLowerCase()).indexOf(` ${word.toLocaleLowerCase()} `) !== -1){
                unwantedWordsFound.push(word);
            }
        }

		const unwantedTitleWordsFound = [];
        for (const word of this.state.flaggedKeywords) {
            if((` ${Name} ` || '').toLocaleLowerCase().indexOf(` ${word.toLocaleLowerCase()} `) !== -1){
                unwantedTitleWordsFound.push(word);
            }
        }

		// check if all fields are valid
		const isValid = !Object.keys(check).map((key) => check[key].hasError).includes(true) && variationsAreValid;
		const validation = {
		  ...check,
		  Description: {
			hasError: Boolean(unwantedWordsFound.length),
			errorMessage: `Description should not contain flagged keywords like ${unwantedWordsFound.join(', ')}`
		  },
		  Name: {
			hasError: Boolean(unwantedTitleWordsFound.length),
			errorMessage: `Name should not contain flagged keywords like ${unwantedTitleWordsFound.join(', ')}`
		  },
		  isValid: isValid || Boolean(unwantedWordsFound.length) || Boolean(unwantedTitleWordsFound.length),
		}

		if (unwantedTitleWordsFound.length !== 0 || unwantedWordsFound.length !== 0) {
			helper.errorMessage('Waring, Listing contains flagged keywords');
		}

		// set validation state of a draft product
		this.setState({
			productValidation: validation,
			childProductValidation: validateChildProducts,
		})

		if (this.state.admin || validation.isValid
			// && unwantedTitleWordsFound.length === 0 && unwantedWordsFound.length === 0
			) {
			this.imageCount = 0;
			this.setState({ isLoading: true });
			this.uploadImage(true, isUpc);
		}
	}

	addProduct = () => {
		const {
			MaxDiscount,
			BuyPrice,
			Sku,
			Name,
			QuantityOnHand,
			Weight,
			Length,
			Width,
			Height,
			brandStatusType,
			ChildProducts,
			GlobalListingAttributes,
			Images,
			IsSimpleProduct,
			editorState,
			brandStatus,
			BrandName,
			selectedCategory,
			OurBrandStatus,
			Identifiers,
		} = this.state;

		let Description = '';
		if (editorState) {
			let block = convertToRaw(editorState.getCurrentContent());
			Description = editorState.getCurrentContent().getPlainText();
			GlobalListingAttributes.LongDescription = draftToHtml(block);
		}

		// get brand name depending on brand status
		const Brand = (brandStatus && (brandStatus.value === 'our_brandname')) ? (OurBrandStatus && OurBrandStatus.value) : BrandName;

		let validateChildProducts = [];
		let variationsAreValid = true;

		if (ChildProducts.length === 0 && !IsSimpleProduct) {
			helper.errorMessage('Please add one variant');
			return;
		};

		// validate all product variations
		if (!IsSimpleProduct) {
			validateChildProducts = ChildProducts.map((childProduct) => {
				if (childProduct.Active) {
					const checkChild = childProductModel.check({
						Sku: childProduct.Sku,
						Price: Number(childProduct.Price),
						RetailPrice: Number(childProduct.BuyPrice),
						admin: this.state.admin,
					});

					// check if all fields are valid
					const isValid = !Object.keys(checkChild).map((key) => {
						helper.errorMessage(checkChild[key].errorMessage);
						return checkChild[key].hasError;
					}).includes(true);
					if (!isValid) {
						variationsAreValid = false;
					}
					return {...checkChild, isValid};
				}
				return {
					Price: {},
					RetailPrice: {},
					Sku: {},
				};
			});
		}

		// validate all product fields
		const check = productModel.check({
			Sku,
			Name,
			productStatus: this.state.productStatus,
			IsSimpleProduct,
			Weight: Number(Weight),
			Length: Number(Length),
			Height: Number(Height),
			Width: Number(Width),
			Price: Number(IsSimpleProduct ? GlobalListingAttributes.Price : 0),
			BuyPrice: Number(BuyPrice),
			MaxDiscount: Number(MaxDiscount),
			QuantityOnHand,
			Description,
			selectedCategory: selectedCategory && selectedCategory.value,
			Brand,
			Uid: this.state.Uid,
			admin: this.state.admin,
		})
		const unwantedWordsFound = [];
		Description = Description.replace(/(\r\n|\n|\r)/gm, " ");
		Description = Description.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g," ")
        for (const word of this.state.flaggedKeywords) {
            if((` ${Description || ''} `.toLocaleLowerCase()).indexOf(` ${word.toLocaleLowerCase()} `) !== -1){
                unwantedWordsFound.push(word);
            }
        }

		const unwantedTitleWordsFound = [];
        for (const word of this.state.flaggedKeywords) {
            if((` ${Name} ` || '').toLocaleLowerCase().indexOf(` ${word.toLocaleLowerCase()} `) !== -1){
                unwantedTitleWordsFound.push(word);
            }
        }

		// check if all fields are valid
		const isValid = !Object.keys(check).map((key) => {
			helper.errorMessage(check[key].errorMessage);
			return check[key].hasError;
		}).includes(true) && variationsAreValid;
		const validation = {
		  ...check,
		  Description: {
			hasError: Boolean(unwantedWordsFound.length),
			errorMessage: `Description should not contain flagged keywords like ${unwantedWordsFound.join(', ')}`
		  },
		  Name: {
			hasError: Boolean(unwantedTitleWordsFound.length),
			errorMessage: `Name should not contain flagged keywords like ${unwantedTitleWordsFound.join(', ')}`
		  },
		  isValid: isValid || Boolean(unwantedWordsFound.length) || Boolean(unwantedTitleWordsFound.length),
		}


		if (unwantedTitleWordsFound.length !== 0 || unwantedWordsFound.length !== 0) {
			helper.errorMessage('Warning, listing contains flagged keywords');
		}

		// set validation state
		this.setState({
			productValidation: validation,
			childProductValidation: validateChildProducts,
		});

		if (!isValid && !this.state.admin
			// || unwantedWordsFound.length
			// || unwantedTitleWordsFound.length
			) {
			// if (
			// 	// descriptionHasError || 
			// 	unwantedTitleWordsFound.length) {
			// 	helper.errorMessage('Listing contains flagged keywords. Please remove to continue');
			// }
			return;
		}
		else if (Images.length == 0 && !this.state.admin) {
			helper.errorMessage('please upload one image');
			return;
		}
		// else if ((Images.length > 0 && Images[Images.length - 1].Url == '') && !this.state.admin) {
		// 	helper.errorMessage('please upload one image');
		// 	return;
		// }
		else if ((!Object.keys(Identifiers).some(key => Identifiers[key]) && this.state.IsSimpleProduct) && !this.state.admin) {
			helper.errorMessage('Must have at least one of the identifiers (UPC, ISBN, EAN, etc)');
			return;
		}
		this.imageCount = 0;
		this.uploadImage(false);
		this.setState({ isLoading: true });
	};

	async uploadImage(Isdraft, isUpc) {
		if (this.imageCount < this.state.Images.length) {
			if (
				this.state.Images[this.imageCount].file != '' &&
				this.state.Images[this.imageCount].file != undefined
			) {
				const photoURL = await this.uploadAmazonImage(
					this.state.Images[this.imageCount].file,
					'products',
				);
				console.log('photoURL====', photoURL);
				if (photoURL) {
					this.state.Images[this.imageCount].Url = photoURL;
				} else {
					this.state.Images[this.imageCount].Url = '';
				}
				this.state.Images[this.imageCount].file = '';
			}
			this.imageCount = this.imageCount + 1;
			this.uploadImage(Isdraft, isUpc);
		} else {
			let checkSku = {
				sku: this.state.Sku,
				upc:
					this.state.Identifiers && this.state.Identifiers.UPC
						? this.state.Identifiers.UPC
						: null,
			};

			const {
				MaxDiscount,
				BuyPrice,
				Sku,
				Name,
				QuantityOnHand,
				Weight,
				Length,
				Width,
				Height,
				brandStatusType,
				ChildProducts,
				GlobalListingAttributes,
				Images,
				IsSimpleProduct,
				editorState,
				brandStatus,
				BrandName,
				selectedCategory,
				OurBrandStatus,
				Identifiers,
				selectSellType,
				Feature1,
				Feature2,
				Feature3,
				Feature4,
				Feature5,
				Seo,
				isDiscountPrice,
				InitialProductStatus,
				LinksUploaded,
				youTubeId,
				youtubeUrl,
			} = this.state;
			console.log(LinksUploaded, 'LinksUploaded')

			const httpMethod = Boolean(this.state._id) ? api.putApi : api.postApi;
			let ProductStatus = Isdraft ? "draft" : "published";
			if (InitialProductStatus === 'pending_reviewal' || (!Boolean(this.state._id) && ProductStatus !== "draft") || (ProductStatus === 'published' && InitialProductStatus === 'draft' && LinksUploaded === undefined)){
				ProductStatus = 'pending_reviewal';
			};

			let ParentSku = null;
			if (ChildProducts && ChildProducts.length > 0) {
				ParentSku = Sku;
			}

			httpMethod(`product/${this.state._id}`, {
				IsSimpleProduct,
				ParentSku,
				MaxDiscount,
				BuyPrice,
				Sku,
				Name,
				QuantityOnHand,
				Weight,
				Length,
				Width,
				Height,
				brandStatusType,
				ProductVariations: ChildProducts,
				GlobalListingAttributes,
				Images,
				IsSimpleProduct,
				editorState,
				BrandStatus: brandStatus || BrandName,
				BrandName,
				OurBrandStatus,
				Identifiers,
				Category: selectedCategory ? selectedCategory.value : null,
				SellType: selectSellType,
				ProductStatus,
				Uid: this.state.Uid,
				isDiscountPrice,
				Feature1,
				Feature2,
				Feature3,
				Feature4,
				Feature5,
				Seo,
				youTubeId,
				youtubeUrl,
				admin: this.state.admin,
			})
				.then((response) => {
					if (response && response.status === "Failure") {
						Object.keys(response.errorMessage).map((key) => {
							if (response.errorMessage[key].hasError) {
								helper.errorMessage(response.errorMessage[key].errorMessage);
							}
						});
					}
					else {
						helper.successMessage('Saved Successfully');
						this.props.history.push("/pages/products")
					}
					this.setState({ isLoading: false });
				})
				.catch(() => {
					this.setState({ isLoading: false });
				});
		}
	}

	viewData = () => {
		window.open('https://www.valetseller.com/helpcenter/editing-your-photos/');
	};

	addDraftProduct(isUpc) {
		const {
			MaxDiscount,
			ProductUid,
			isDiscountPrice,
			isBlockIdentifiers,
			Weight,
			Height,
			Width,
			Length,
			editorState,
			ProductColor,
			ProductSize,
			CustomList,
			ChildProducts,
			selectedCategory,
			Seo,
			Id,
			Sku,
			Name,
			Type,
			QuantityOnHand,
			Identifiers,
			IsAutoSyncEnabled,
			IsDiscontinued,
			IsDropshipped,
			IsFireArm,
			IsVariation,
			IsParent,
			selectSellType,
			BrandName,
			GlobalListingAttributes,
			Images,
			Warehouses,
			SupplierInformation,
			BuyPrice,
			CompanyId,
			brandStatus,
			productStatus,
			Feature1,
			Feature2,
			Feature3,
			Feature4,
			Feature5,
			OurBrandStatus,
			_id,
		} = this.state;

		let description = '';
		if (editorState) {
			let block = convertToRaw(editorState.getCurrentContent());
			description = editorState.getCurrentContent().getPlainText();
			GlobalListingAttributes.LongDescription = draftToHtml(block);
		}

		let reqData = {
			Sku: Sku,
			Name: Name,
			CompanyId: CompanyId,
			Type: Type,
			QuantityOnHand: QuantityOnHand,
			Category: selectedCategory ? selectedCategory.value : null,
			BuyPrice: BuyPrice,
			Identifiers: Identifiers,
			IsAutoSyncEnabled: IsAutoSyncEnabled,
			IsDiscontinued: IsDiscontinued,
			IsDropshipped: IsDropshipped,
			IsFireArm: IsFireArm,
			IsVariation: IsVariation,
			IsParent: IsParent,
			GlobalListingAttributes: GlobalListingAttributes,
			Images: Images,
			Warehouses: Warehouses,
			Seo: Seo,
			SupplierInformation: SupplierInformation,
			ProductVariations: ChildProducts,
			ProductColor: ProductColor,
			ProductSize: ProductSize,
			CustomList: CustomList,
			Weight: Weight,
			Height: Height,
			Width: Width,
			Length: Length,
			Feature1: Feature1,
			Feature2: Feature2,
			Feature3: Feature3,
			Feature4: Feature4,
			Feature5: Feature5,
			OurBrandStatus: OurBrandStatus,
			_id: _id,
		};

		reqData.SellType = selectSellType ? selectSellType : [];
		reqData.Uid = ProductUid != '' ? ProductUid : this.state.Uid;
		reqData.BuyPrice = BuyPrice;

		reqData.ProductStatus = 'draft';
		reqData.BrandStatus = brandStatus ? brandStatus.value : '';
		reqData.BrandName = BrandName;

		reqData.isBlockIdentifiers = isBlockIdentifiers;
		reqData.isDiscountPrice = isDiscountPrice;
		console.log('Id===', Id);
		if (Id != '') {
			reqData.Id = Id;
			this.addDraftApi(reqData, isUpc);
		} else {
			this.addDraftApi(reqData, isUpc);
		}
	}

	addDraftApi(reqData, isUpc) {
		api.postApi('inventory/draftProducts', reqData).then((data) => {
			this.setState({ isLoading: false });
			if(isUpc) {
				this.togglePruchaseUpcModal();
			} else {
				this.props.history.push('/pages/products');
				helper.successMessage('Sucessfully saved');
			}

		});
	}

	redirect() {
		this.props.history.push('/pages/products');
	}
	updateSingleProduct() {
		const {
			MaxDiscount,
			ProductUid,
			selectSellType,
			isBlockIdentifiers,
			isDiscountPrice,
			Weight,
			Height,
			Width,
			Length,
			userCompany,
			ProductColor,
			ProductSize,
			CustomList,
			ChildProducts,
			selectedCategory,
			Seo,
			Id,
			Sku,
			Name,
			Type,
			QuantityOnHand,
			Identifiers,
			IsAutoSyncEnabled,
			IsDiscontinued,
			IsDropshipped,
			IsFireArm,
			IsVariation,
			IsParent,
			BrandName,
			GlobalListingAttributes,
			Images,
			Warehouses,
			SupplierInformation,
			BuyPrice,
			CompanyId,
			brandStatus,
			productStatus,
			Feature1,
			Feature2,
			Feature3,
			Feature4,
			Feature5,
			OurBrandStatus,
			BrandStatus,
			WeightUnit,
		} = this.state;

		let reqData = {
			Sku: Sku,
			Name: Name,
			CompanyId: CompanyId,
			Type: Type,
			QuantityOnHand: QuantityOnHand,
			BuyPrice: BuyPrice,
			Identifiers: Identifiers,
			IsAutoSyncEnabled: IsAutoSyncEnabled,
			IsDiscontinued: IsDiscontinued,
			IsDropshipped: IsDropshipped,
			IsFireArm: IsFireArm,
			IsVariation: IsVariation,
			IsParent: IsParent,
			GlobalListingAttributes: GlobalListingAttributes,
			Images: Images,
			Warehouses: Warehouses,
			SupplierInformation: SupplierInformation,
			Weight: Weight,
			Height: Height,
			Width: Width,
			Length: Length,
			Seo: Seo,
			Category: selectedCategory.value,
			Feature1: Feature1,
			Feature2: Feature2,
			Feature3: Feature3,
			Feature4: Feature4,
			Feature5: Feature5,
			OurBrandStatus: OurBrandStatus,
			BrandName: BrandName,
			WeightUnit: WeightUnit,
		};

		// ecomdash marketplaces
		const ecomedashSellTypes = [
			'Google',
			'Walmart',
			'Amazon',
			'eBay',
			'Sears',
			'Amazon Canada',
			'Bonanza',
		];

		console.log('we are here', Id)

		if (Id == '') {
			reqData.QuantityOnHand = Number(QuantityOnHand);
			// If content a ecomdash marketplaces, save product in ecomedash
				console.log('========ecomdash-product========');
				api.postEcomDashApi('Inventory', reqData).then(
					(data) => {
						if (data.status == 'Success') {
							let product = data.data.Product;
							product.Uid = this.state.Uid;
							product.BuyPrice = BuyPrice;
							product.Identifiers = reqData.Identifiers;
							if (productStatus == null) {
								product.ProductStatus = 'pending_reviewal';
							}
							else if (productStatus !== 'pending_reviewal' && productStatus !== 'under_review') {
								product.ProductStatus = 'published';
							}
							else {
								product.ProductStatus = productStatus ? productStatus.value : '';
							}
							product.BrandStatus = brandStatus ? brandStatus.value : '';
							product.BrandName = BrandName;

							product.isBlockIdentifiers = isBlockIdentifiers;
							product.isDiscountPrice = isDiscountPrice;

							product.Feature1 = reqData.Feature1;
							product.Feature2 = reqData.Feature2;
							product.Feature3 = reqData.Feature3;
							product.Feature4 = reqData.Feature4;
							product.Feature5 = reqData.Feature5;

							product.Seo = reqData.Seo;
							product.Category = reqData.Category;
							product.Weight = reqData.Weight;
							product.Height = reqData.Height;
							product.Width = reqData.Width;
							product.Length = reqData.Length;
							product.SellType = selectSellType ? selectSellType : [];

							console.log('product======', product);

							api.postApi('inventory/addProducts', product).then((data) => {
								this.setState({ isLoading: false });
								this.props.history.push('/pages/products');
								helper.successMessage(
									'Your Product is Under Review and Will Be Live in 1-3 Business Days',
								);
								this.sendAddproductEmail(reqData.Sku);
							});
						} else if (data.data && data.data.WasSuccessful == false) {
							this.setState({ isLoading: false });
							if (data.data.Messages.length > 0) {
								helper.errorMessage(data.data.Messages[0]);
							}
						} else {
							this.setState({ isLoading: false });
							if (data.messages.length > 0) {
								helper.errorMessage(data.messages[0]);
							}
						}
					},
					(err) => {
						//console.log('err====', err);
						this.setState({ isLoading: false });
						helper.errorMessage(err);
					},
				);
		}
		else {
			reqData.Id = Id;
			reqData.QuantityOnHand = Number(QuantityOnHand);
			if (reqData.Warehouses && 0 < reqData.Warehouses.length) {
				reqData.Warehouses[0].QuantityOnHand = reqData.QuantityOnHand;
			}
			let newImages = [];
			for (let item in reqData.Images) {
				newImages.push({
					ProductId: reqData.Images[item].ProductId,
					Url: reqData.Images[item].Url,
					DisplayOrder: reqData.Images[item].DisplayOrder,
				});
			}
			reqData.Images = newImages;
				api.putEcomDasApi('Inventory', reqData).then(
					(data) => {
						console.log('putEcomDasApi Inventory ===========ww=====', data);

						if (data.status == 'Success') {
							let product = data.data.Product;
							product.Uid = ProductUid != '' ? ProductUid : this.state.Uid;
							product.BuyPrice = BuyPrice;

							product.ProductStatus = 'published';
							product.BrandStatus = brandStatus ? brandStatus.value : '';
							product.BrandName = BrandName;
							product.Identifiers = reqData.Identifiers;

							product.Feature1 = reqData.Feature1;
							product.Feature2 = reqData.Feature2;
							product.Feature3 = reqData.Feature3;
							product.Feature4 = reqData.Feature4;
							product.Feature5 = reqData.Feature5;
							product.isBlockIdentifiers = isBlockIdentifiers;
							product.isDiscountPrice = isDiscountPrice;

							product.Seo = reqData.Seo;
							product.Category = reqData.Category;
							product.Weight = reqData.Weight;
							product.Height = reqData.Height;
							product.Width = reqData.Width;
							product.Length = reqData.Length;
							product.SellType = selectSellType ? selectSellType : [];

							console.log('product==', product);
							api.postApi('inventory/updateProducts', product).then((data) => {
								this.setState({isLoading: false});
								this.props.history.push('/pages/products');
								helper.successMessage('Your product was successfully updated');
							});
						} else if (data.data && data.data.WasSuccessful == false) {
							this.setState({isLoading: false});
							if (data.data.Messages.length > 0) {
								helper.errorMessage(data.data.Messages[0]);
							}
						} else {
							this.setState({isLoading: false});
							if (data.messages.length > 0) {
								helper.errorMessage(data.messages[0]);
							}
						}
					},
					(err) => {
						//console.log('err====', err);
						this.setState({isLoading: false});
						helper.errorMessage(err);
					},
				);
		}
	}

	updateVariantProduct() {
		const {
			ProductUid,
			selectSellType,
			Weight,
			Height,
			Width,
			Length,
			userCompany,
			ProductColor,
			ProductSize,
			CustomList,
			ChildProducts,
			selectedCategory,
			Seo,
			Id,
			Sku,
			Name,
			Type,
			QuantityOnHand,
			Identifiers,
			IsAutoSyncEnabled,
			IsDiscontinued,
			IsDropshipped,
			IsFireArm,
			IsVariation,
			IsParent,
			BrandName,
			GlobalListingAttributes,
			Images,
			Warehouses,
			SupplierInformation,
			BuyPrice,
			CompanyId,
			brandStatus,
			productStatus,
			Feature1,
			Feature2,
			Feature3,
			Feature4,
			Feature5,
			OurBrandStatus,
			_id,
		} = this.state;

		let reqData = {
			Sku: Sku,
			Name: Name,
			CompanyId: CompanyId,
			Type: Type,
			QuantityOnHand: QuantityOnHand,
			Category: selectedCategory.value,
			BuyPrice: BuyPrice,
			Identifiers: Identifiers,
			IsAutoSyncEnabled: IsAutoSyncEnabled,
			IsDiscontinued: IsDiscontinued,
			IsDropshipped: IsDropshipped,
			IsFireArm: IsFireArm,
			IsVariation: true,
			IsParent: false,
			GlobalListingAttributes: GlobalListingAttributes,
			Images: Images,
			Warehouses: Warehouses,
			Seo: Seo,
			SupplierInformation: SupplierInformation,
			ProductVariations: ChildProducts,
			ProductColor: ProductColor,
			ProductSize: ProductSize,
			CustomList: CustomList,
			Weight: Weight,
			Height: Height,
			Width: Width,
			Length: Length,
			Feature1: Feature1,
			Feature2: Feature2,
			Feature3: Feature3,
			Feature4: Feature4,
			Feature5: Feature5,
			OurBrandStatus: OurBrandStatus,
			_id: _id,
		};

		reqData.Uid = ProductUid != '' ? ProductUid : this.state.Uid;
		reqData.BuyPrice = BuyPrice;

		if (productStatus == null) {
			reqData.ProductStatus = 'pending_reviewal';
		} else {
			reqData.ProductStatus = productStatus ? productStatus.value : '';
		}
		reqData.BrandStatus = brandStatus ? brandStatus.value : '';
		reqData.BrandName = BrandName;
		reqData.SellType = selectSellType ? selectSellType : [];
		console.log('Id===', Id, this.props.match.params.id, reqData);
		if (this.props.match.params.id === undefined && this.state.IsSimpleProduct === false) {
			api.postApi('inventory/sendCsvProducts', reqData).then((data) => {
				this.setState({ isLoading: false });
				this.props.history.push('/pages/products');
				helper.successMessage(
					'Your Product is Under Review and Will Be Live in 1-3 Business Days',
				);
			});
		} else {
			reqData.Id = Id;
			console.log('varaiton product===', reqData, 'Id==', Id);
			if(this.state.IsSimpleProduct){
				return ""
			}
			api.postApi('inventory/sendCsvProducts', reqData).then((data) => {
				this.setState({ isLoading: false });
				this.props.history.push('/pages/products');
				helper.successMessage('Your product was successfully updated');
			});
		}
	}

	async sendAddproductEmail(sku) {
		let apiPath = '';
		apiPath = 'mail/onProductDetails';
		let reqData = {
			firstname: this.props.userdetails ? this.props.userdetails.firstname : '',
			email: this.props.userdetails ? this.props.userdetails.email : '',
			sku: sku,
			company: this.state.userCompany,
		};
		console.log('reqData===', reqData);
		api.postApi(apiPath, reqData).then((data) => {
			console.log(data);
			if (data.status == 'Success') {
			}
			this.setState({ isLoading: false });
		});
	}

	async uploadAmazonImage(file, collection) {
		try {
			let timeStamp = new Date().getTime();
			return ReactS3Client.uploadFile(file, timeStamp)
				.then((data) => {
					if (data.status == '204') {
						return data.location;
					} else {
						return '';
					}
				})
				.catch((err) => console.error('eroor========', err));
		} catch (err) {
			//console.log(err);
			return null;
		}
	}

	onCancelWalkthrough = () => {
		this.setState({ isTourActive: false });
		this.props.addWalkthroughFlag();
	};
	onNext = (step) => {
		if (step == 2) {
			this.setState({ tourStep: step });
			document.getElementById('topBar__Id').classList.add('show');
		} else if (step == 7) {
			history.push('products', { walkthrough: true });
			document.getElementById('topBar__Id').classList.remove('show');
		} else {
			this.setState({ tourStep: step });
			document.getElementById('topBar__Id').classList.remove('show');
		}
	};

	closeImageModal() {
		this.setState({ editmodalIsOpen: false });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		if (this.fileInput) {
			this.fileInput.value = '';
		}
		this.setState({ modalIsOpen: false });
	}

	handleActiveChange(activeDisabled, key) {
		let childProducts = this.state.ChildProducts;
		childProducts[key].Active = activeDisabled;
		this.setState({ ChildProducts: [ ...childProducts ] }, () => {
			this.addChildProducts();
		});
	}

	addColor() {
		let colorItems = this.state.colorItems;
		if (colorItems.length == 0) {
			colorItems.push({
				value: '',
				name: '',
				color: '',
				displayColorPicker: false,
			});
			this.setState({ colorItems: [ ...colorItems ] });
		} else if (
			colorItems.length == 0 ||
			colorItems[colorItems.length - 1].value == ''
		) {
			helper.errorMessage('please select name and colors');
		} else {
			colorItems.push({
				value: '',
				name: '',
				color: '',
				displayColorPicker: false,
			});
			this.setState({ colorItems: [ ...colorItems ] });
		}
	}
	removeColor(index) {
		let colorItems = this.state.colorItems;
		colorItems.splice(index, 1);
		this.setState({ colorItems: [ ...colorItems ] });
	}
	updateColor(value, index) {
		let colorItems = this.state.colorItems;
		colorItems[index].value = value;
		this.setState({ colorItems: [ ...colorItems ] });
	}

	removeAllColor() {
		this.setState({ colorItems: [], IsColor: false });
	}

	handleChangeSize = (IsSize) => {
		this.setState({
			IsSize: !this.state.IsSize,
			sizeItems: [ { value: '', name: '' } ],
		});
	};

	addSize() {
		let sizeItems = this.state.sizeItems;
		if (sizeItems.length == 0) {
			sizeItems.push({ value: '', name: '' });
			this.setState({ sizeItems: [ ...sizeItems ] });
		} else if (sizeItems[sizeItems.length - 1].value == '') {
			helper.errorMessage('please enter size');
		} else {
			sizeItems.push({ value: '', name: '' });
			this.setState({ sizeItems: [ ...sizeItems ] });
		}
	}
	updateSize(value, index) {
		let sizeItems = this.state.sizeItems;
		sizeItems[index].value = value;
		this.setState({ sizeItems: [ ...sizeItems ] });
	}
	removeSize(index) {
		let sizeItems = this.state.sizeItems;
		sizeItems.splice(index, 1);
		this.setState({ sizeItems: [ ...sizeItems ] });
	}
	removeAllSize() {
		this.setState({ sizeItems: [], IsSize: false });
	}

	bulkPublish() {
		api.postApi('bulkPublish', {
		  productIds: [''],
		})
		  .then((response) => {
			  console.log('published')
		  });
	  }

	handleChangeCustom = (IsCustom) => {
		this.setState({ IsCustom: !this.state.IsCustom });
	};
	addYouTubeVideo() {
		let url = this.state.youtubeUrl;
		if (url == '') {
			helper.errorMessage('please enter youtube url');
		} else if (!helper.checkYouTubeUrl(url)) {
			helper.errorMessage('youtube url invalid');
		} else {
			console.log('helper.checkYouTubeUrl(url)==', helper.checkYouTubeUrl(url));
			this.setState({
				confirmmodal: false,
				youTubeId: helper.checkYouTubeUrl(url),
			});
		}
	}

	addProductAttributes() {
		let ProductAttributes = this.state.ProductAttributes;
		if (ProductAttributes.length == 0) {
			ProductAttributes.push({ detail: '', description: '' });
			this.setState({ ProductAttributes: [ ...ProductAttributes ] });
		} else if (ProductAttributes[ProductAttributes.length - 1].detail == '') {
			helper.errorMessage('Detail field is required');
		} else if (
			ProductAttributes[ProductAttributes.length - 1].Description == ''
		) {
			helper.errorMessage('Description field is required');
		} else {
			console.log('ProductAttributes===', ProductAttributes);
			ProductAttributes.push({ detail: '', description: '' });
			this.setState({ ProductAttributes: [ ...ProductAttributes ] });
		}
	}

	updateProductAttributes(value, field, index) {
		let ProductAttributes = this.state.ProductAttributes;
		ProductAttributes[index][field] = value;
		this.setState({ ProductAttributes: [ ...ProductAttributes ] });
	}

	removeProductAttributes(key) {
		let ProductAttributes = this.state.ProductAttributes;
		ProductAttributes.splice(key, 1);
		this.setState({ ProductAttributes: [ ...ProductAttributes ] });
	}

	addProductSize() {
		let ProductSize = this.state.ProductSize;
		if (ProductSize.length == 0) {
			ProductSize.push({ name: '' });
			this.setState({ ProductSize: [ ...ProductSize ] });
		} else if (ProductSize[ProductSize.length - 1].name == '') {
			helper.errorMessage('Size name field is required');
		} else {
			ProductSize.push({ name: '' });
			this.setState({ ProductSize: [ ...ProductSize ] }, () => {
				this.addChildProducts();
			});
		}
	}

	addCustom() {
		let CustomList = this.state.CustomList;
		if (CustomList.length == 0) {
			CustomList.push({ name: '' });
			this.setState({ CustomList: [ ...CustomList ] });
		} else if (CustomList[CustomList.length - 1].name == '') {
			helper.errorMessage('custom name field is required');
		} else {
			CustomList.push({ name: '' });
			this.setState({ CustomList: [ ...CustomList ] }, () => {
				this.addChildProducts();
			});
		}
	}

	removeCustom(key) {
		let CustomList = this.state.CustomList;
		CustomList.splice(key, 1);
		this.setState({ CustomList: [ ...CustomList ] }, () => {
			this.addChildProducts();
		});
	}

	removeAllCustom() {
		this.setState({ CustomList: [], IsCustom: false }, () => {
			this.addChildProducts();
		});
	}

	updateCustom(value, field, index) {
		let CustomList = this.state.CustomList;
		CustomList[index][field] = value;
		this.setState({ CustomList: [ ...CustomList ] }, () => {
			this.addChildProducts();
		});
	}

	updateProductSize(value, field, index) {
		let ProductSize = this.state.ProductSize;
		ProductSize[index][field] = value;
		this.setState({ ProductSize: [ ...ProductSize ] }, () => {
			this.addChildProducts();
		});
	}

	removeProductSize(key) {
		let ProductSize = this.state.ProductSize;
		ProductSize.splice(key, 1);
		this.setState({ ProductSize: [ ...ProductSize ] }, () => {
			this.addChildProducts();
		});
	}

	removeAllProductSize() {
		this.setState({ ProductSize: [], IsSize: false }, () => {
			this.addChildProducts();
		});
	}

	addProductColor() {
		let ProductColor = this.state.ProductColor;
		if (ProductColor.length == 0) {
			ProductColor.push({ name: '', color: '#000' });
			this.setState({ ProductColor: [ ...ProductColor ] });
		} else if (ProductColor[ProductColor.length - 1].name == '') {
			helper.errorMessage('Color name field is required');
		} else {
			ProductColor.push({ name: '', color: '#000' });
			this.setState({ ProductColor: [ ...ProductColor ] }, () => {
				this.addChildProducts();
			});
		}
	}

	updateProductColor(value, field, index) {
		let ProductColor = this.state.ProductColor;
		ProductColor[index][field] = value;
		ProductColor[index].color = helper.colourNameToHex(value);
		this.setState({ ProductColor: [ ...ProductColor ] }, () => {
			this.addChildProducts();
		});
	}

	removeAllProductColor() {
		this.setState({ ProductColor: [], IsColor: false }, () => {
			this.addChildProducts();
		});
	}

	removeProductColor(key) {
		let ProductColor = this.state.ProductColor;
		ProductColor.splice(key, 1);
		this.setState({ ProductColor: [ ...ProductColor ] }, () => {
			this.addChildProducts();
		});
	}

	handleClick = (index) => {
		let ProductColor = this.state.ProductColor;
		ProductColor[index].displayColorPicker = true;
		this.setState({ ProductColor: [ ...ProductColor ] });
	};

	handleClose = (index) => {
		let ProductColor = this.state.ProductColor;
		ProductColor[index].displayColorPicker = false;
		this.setState({ ProductColor: [ ...ProductColor ] });
	};

	handleChange = (color, index) => {
		let ProductColor = this.state.ProductColor;
		ProductColor[index].color = color.hex;
		this.setState({ ProductColor: [ ...ProductColor ] });
	};

	handleChangeColor = (IsColor) => {
		this.setState({
			IsColor: !this.state.IsColor,
			colorItems: [ { value: '', name: '', color: '' } ],
		});
	};

	allPossibleCases(array, prefix) {
		prefix = prefix || '';
		if (!array.length) {
			return prefix;
		}
		var that = this;
		var result = array[0].reduce(function(result, value) {
			return result.concat(
				that.allPossibleCases(array.slice(1), value + prefix),
			);
		}, []);
		return result;
	}

	addChildProducts() {
		let ChildProducts = this.state.ChildProducts;
		const originalVariants = this.state.ProductVariations;

		let ProductColor = this.state.ProductColor;
		let ProductSize = this.state.ProductSize;
		let CustomList = this.state.CustomList;
		let GlobalListingAttributes = this.state.GlobalListingAttributes;

		let productColorName = [];
		let productSizeName = [];
		let productCustomName = [];

		var allArrays = [];

		let priceList = [];

		for (let item in ChildProducts) {
			if (ChildProducts[item].Active && 0 < ChildProducts[item].Price) {
				priceList.push(ChildProducts[item].Price);
			}
			if (ChildProducts[item].Price != '' && ChildProducts[item].Price < 1) {
				ChildProducts[item].Price = 0;
			}
		}
		if (0 < priceList.length) {
			priceList.sort(function(a, b) {
				return b - a;
			});
			GlobalListingAttributes.Price = priceList[0];
		}
		for (let data in ProductSize) {
			if (ProductSize[data].name != '') {
				productSizeName.push(ProductSize[data].name + ' ');
			}
		}

		if (0 < productSizeName.length) {
			allArrays.push(productSizeName);
		}
		for (let data in ProductColor) {
			if (ProductColor[data].color == '#000') {
				ProductColor[data].color = helper.colourNameToHex(
					ProductColor[data].name,
				);
			}
			if (ProductColor[data].name != '') {
				productColorName.push(ProductColor[data].name + ' ');
			}
		}
		if (0 < productColorName.length) {
			allArrays.push(productColorName);
		}
		for (let data in CustomList) {
			if (CustomList[data].name != '') {
				productCustomName.push(CustomList[data].name + ' ');
			}
		}
		if (0 < productCustomName.length) {
			allArrays.push(productCustomName);
		}
		let variationsList = this.allPossibleCases(allArrays);

		if (ChildProducts.length != variationsList.length) {
			for (let data1 in ChildProducts) {
				let isNew = false;
				for (let data in variationsList) {
					if (ChildProducts[data1].Variation == variationsList[data]) {
						isNew = true;
					}
				}
				if (isNew) {
					ChildProducts.splice(data1, 1);

				}
			}
		}


		for (let data in variationsList) {
			if (ChildProducts[data] == undefined) {
				ChildProducts.push({
					Image: this.state.Images.length == 0 ? '' : this.state.Images[0].Url,
					Variation: variationsList[data],
					Sku: this.getChildSku(variationsList[data]),
					Price: 0,
					EAN: '',
					GTIN: '',
					ISBN: '',
					UPC: '',
					Active: true,
				});
			} else {
				ChildProducts[data].Variation = variationsList[data];


				if (ChildProducts[data].Image == '') {
					ChildProducts[data].Image =
						this.state.Images.length == 0 ? '' : this.state.Images[0].Url;
				}
			}
		}
		console.log('ChildProducts==', ChildProducts);
		this.setState({
			ChildProducts: [ ...ChildProducts ],
			GlobalListingAttributes: GlobalListingAttributes,
		});
	}

	firstChildProducts() {
		let ChildProducts = this.state.ChildProducts;

		let ProductColor = this.state.ProductColor;
		let ProductSize = this.state.ProductSize;
		let CustomList = this.state.CustomList;
		let GlobalListingAttributes = this.state.GlobalListingAttributes;

		let productColorName = [];
		let productSizeName = [];
		let productCustomName = [];

		var allArrays = [];

		let priceList = [];

		for (let item in ChildProducts) {
			if (ChildProducts[item].Active && 0 < ChildProducts[item].Price) {
				priceList.push(ChildProducts[item].Price);
			}
			if (ChildProducts[item].Price != '' && ChildProducts[item].Price < 1) {
				ChildProducts[item].Price = 0;
			}
		}
		if (0 < priceList.length) {
			priceList.sort(function(a, b) {
				return b - a;
			});
			GlobalListingAttributes.Price = priceList[0];
		}
		for (let data in ProductSize) {
			if (ProductSize[data].name != '') {
				productSizeName.push(ProductSize[data].name + ' ');
			}
		}

		if (0 < productSizeName.length) {
			allArrays.push(productSizeName);
		}
		for (let data in ProductColor) {
			if (ProductColor[data].color == '#000') {
				ProductColor[data].color = helper.colourNameToHex(
					ProductColor[data].name,
				);
			}
			if (ProductColor[data].name != '') {
				productColorName.push(ProductColor[data].name + ' ');
			}
		}
		if (0 < productColorName.length) {
			allArrays.push(productColorName);
		}
		for (let data in CustomList) {
			if (CustomList[data].name != '') {
				productCustomName.push(CustomList[data].name + ' ');
			}
		}
		if (0 < productCustomName.length) {
			allArrays.push(productCustomName);
		}
		let variationsList = this.allPossibleCases(allArrays);

		if (ChildProducts.length != variationsList.length) {
			for (let data1 in ChildProducts) {
				let isNew = false;
				for (let data in variationsList) {
					if (ChildProducts[data1].Variation == variationsList[data]) {
						isNew = true;
					}
				}
				if (isNew) {
					ChildProducts.splice(data1, 1);

				}
			}
		}


		for (let data in variationsList) {
			if (ChildProducts[data] == undefined) {
				ChildProducts.push({
					Image: this.state.Images.length == 0 ? '' : this.state.Images[0].Url,
					Variation: variationsList[data],
					Sku: this.getChildSku(variationsList[data]),
					Price: 0,
					EAN: '',
					GTIN: '',
					ISBN: '',
					UPC: '',
					Active: false,
				});
			} else {
				if (ChildProducts[data].Image == '') {
					ChildProducts[data].Image =
						this.state.Images.length == 0 ? '' : this.state.Images[0].Url;
				}
			}
		}
		this.setState({
			ChildProducts: [ ...ChildProducts ],
			GlobalListingAttributes: GlobalListingAttributes,
		});
	}

	updateVariationsSku(value, field, index) {
		let ChildProducts = this.state.ChildProducts;
		ChildProducts[index][field] = value;
		console.log('ChildProducts=2222222=', ChildProducts);

		this.setState({ ChildProducts: [ ...ChildProducts ] });
	}

	updateVariationsAttributes(value, field, index) {
		let ChildProducts = this.state.ChildProducts;
		let GlobalListingAttributes = this.state.GlobalListingAttributes;
		ChildProducts[index][field] = value;
		console.log("-0987654", ChildProducts)
		this.setState(
			{
				ChildProducts: [ ...ChildProducts ],
				GlobalListingAttributes: GlobalListingAttributes,
			},
			() => {
				this.addChildProducts();
			},
		);
	}

	selectedImage(key) {
		let Images = this.state.Images;
		for (let data in Images) {
			Images[data].selectedImage = false;
		}
		Images[key].selectedImage = true;
		this.setState({ Images: [ ...Images ] });
	}

	applyPhoto() {
		console.log('applyPhoto===');
		let ChildProducts = this.state.ChildProducts;
		let imageIndex;
		let Images = this.state.Images.find((data1) => {
			return data1.selectedImage === true;
		});

		this.state.Images.map((image, index) => {
			if (Images && image) {
				if (Images.Url === image.Url) {
					imageIndex = index;
				}
			}
		});
		if (Images == undefined) {
			helper.errorMessage('please select one image');
		} else {
			console.log('Images===', Images);
			if (this.state.IsCurrentVariations) {
				ChildProducts[this.selectKey].Image = Images.Url;
				ChildProducts[this.selectKey].imageIndex = imageIndex;
			} else {
				for (let data in ChildProducts) {
					ChildProducts[data].Image = Images.Url;
					ChildProducts[data].imageIndex = imageIndex;
				}
			}
			console.log('kjnbhvgfcdsxdcfgh', ChildProducts);
			this.setState({
				editmodalIsOpen: false,
				ChildProducts: [ ...ChildProducts ],
			});
		}
	}

	editImageModal(key) {
		this.selectKey = key;
		if (this.state.Images.length == 0) {
			helper.errorMessage('please upload one image');
		} else {
			this.setState({ editmodalIsOpen: true });
		}
	}

	// a little function to help us with reordering the result
	reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		const [ removed ] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	handleRLDDChange = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = this.reorder(
			this.state.Images,
			result.source.index,
			result.destination.index,
		);
		this.setState({ Images: items });
	};

	openHelp = () => {
		window.open('https://www.valetseller.com/help-center/');
	};

	editPhoto = () => {
		window.open('https://valetseller.com/2020/08/17/editing-your-photos/');
	};

	handleCartChange = (selectSellType) => {
		this.setState({ selectSellType });
	};

	togglePruchaseUpcModal = () => {
		this.setState({ purchaseUpcModal: !this.state.purchaseUpcModal });
	}

	savePurchase = async (paypal) => {
		try {
			this.setState({ isLoading: true });

			const data = await api
				.postApi(
					`upc/purchaseUpc`,
					{
						paypal,
						sku: this.state.Sku,
						key: this.state.key,
						quantity: this.state.upcQuantity,
					},
				);

			if (data.status == 'Success') {
				helper.successMessage(`You have successfully purchased ${this.state.upcQuantity} UPCs. You will receive an email shortly with the list of UPCs`);
				this.togglePruchaseUpcModal();
				this.getProduct();
				this.setState({ upcQuantity: 1, isLoading: false });
			} else {
				helper.errorMessage(data.messages[0]);
				this.setState({ isLoading: false });
			}
		} catch (e) {
			this.setState({ isLoading: false });
			return helper.errorMessage('Error saving the purchase');
		}

	}

	paypalonCreateOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						currency_code: 'USD',
						value: this.state.upcPrice,
					},
				},
			],
			application_context: {
				shipping_preference: 'NO_SHIPPING',
			},
		});
	}

	paypalonSuccess = (data) => {
		console.log('Payapl approved', data);
		window.gtag('event', 'upc-purchasing', {
			'type': 'paid_upc',
			'value': this.state.upcPrice,
			'utm_source': localStorage.getItem('utm_source'),
			'utm_campaign': localStorage.getItem('utm_campaign')
		})
		this.savePurchase({ id: data.id, date: data.create_time });
	}

	paypalOnError(err) {
		console.log('Error', err);
	}


	toggleSetUpcModal = () => {
		this.setState({ selectUpcModal: !this.state.selectUpcModal });
	}

	setUpc = async () => {
		try {
			this.setState({ isLoading: true });

			const data = await api
				.postApi(
					`upc/setProductUpc`,
					{
						sku: this.state.Sku,
						upc: this.state.selectedUPC,
						key: this.state.key,
					},
				);

			if (data.status == 'Success') {
				helper.successMessage(`UPC Saved`);
				this.toggleSetUpcModal();
				this.getProduct();
				this.setState({ selectedUPC: undefined, isLoading: false });
			} else {
				helper.errorMessage(data.messages[0]);
				this.setState({ isLoading: false });
			}
		} catch (e) {
			this.setState({ isLoading: false });
			return helper.errorMessage('Error saving');
		}
	}

	toggleUpcSelectedRow = (selectedUPC) => {
		this.setState({ selectedUPC });
	}

	render() {
		let {
			brandStatusType,
			brandStatus,
			Id,
			isBlockIdentifiers,
			TypeValue,
			mimeType,
			MaxDiscount,
			productType,
			globalType,
			Sku,
			Name,
			Type,
			QuantityOnHand,
			Identifiers,
			IsAutoSyncEnabled,
			IsDiscontinued,
			IsDropshipped,
			IsFireArm,
			IsVariation,
			IsParent,
			GlobalListingAttributes,
			Images,
			Warehouses,
			SupplierInformation,
			longDescription,
			editorState,
			BuyPrice,
			statusType,
			productStatus,
			BrandName,
			IsColor,
			IsSize,
			IsQuantity,
      isDisabled,
			IsCustom,
			colorItems,
			sizeItems,
			Category,
			selectedCategory,
			activeDisabled,
			notNew,
			youTubeId,
			youtubeUrl,
			isNotNewProduct,
			ProductAttributes,
			IsSimpleProduct,
			Weight,
			Height,
			isDiscountPrice,
			Width,
			Length,
			ChildProducts,
			ProductSize,
			ProductColor,
			Seo,
			CustomList,
			IsCurrentVariations,
			Feature1,
			Feature2,
			Feature3,
			Feature4,
			Feature5,
			selectSellType,
			cartList,
			subscription_id,
			paypalBilling,
			admin,
			isShowPopup,
			OurBrandStatus,
			OurBrandStatusType,
			userBrandHistory,
      		hrefValue,
			  MyBrandStatus,
			ProductVariations,
		} = this.state;

		let images = [];
		for (let data in Images) {
			images.push({
				...Images[data],
				src: Images[data].Url,
				key: data,
				id: Number(data) + 1,
			});
		}
		const productUpc = this.state.inUpcs.some(upc => upc.number === Identifiers.UPC);
    if(productStatus != null){
      if(productStatus.value == 'published'){
        this.setState({isDisabled:true});
        this.setState({hrefValue:"/pages/help?msg=I want to edit my SKU : "+Sku});
      }
    }else{
        this.setState({isDisabled:false});
    }
		return (
			<div>
				<div className='overlayIntro resizePop productpop2 '>
					<Tour
						active={this.state.isTourActive}
						step={this.state.tourStep}
						onNext={(step) => this.onNext(step)}
						onBack={(step) => this.setState({ tourStep: step })}
						onCancel={() => this.onCancelWalkthrough()}
						hideSteps={true}
						steps={[
							{
								step: 1,
								selector: '.step1',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '80px',
											marginBottom: '40px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{GLOBAL_LISTING_ATTRIBUTES}
									</div>
								),
							},
							{
								step: 2,
								selector: '.help11',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '40px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{HELP}
									</div>
								),
							},
							{
								step: 3,
								selector: '.step2',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{WAREHOUSE}
									</div>
								),
							},
							{
								step: 4,
								selector: '.market_places',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '20px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{MARKETING_PLACE}
									</div>
								),
							},
							{
								step: 5,
								selector: '.step3',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '40px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{ADD_BUTTON}
									</div>
								),
							},
							{
								step: 6,
								selector: '.cancelbtn',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '20px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{CANCEL_BUTTON}
									</div>
								),
							},
							{
								step: 7,
								selector: '.cancelbtn1',
								title: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '10px',
											fontSize: '16px',
											color: 'black',
										}}
									/>
								),
								body: (
									<div
										style={{
											textAlign: 'center',
											marginTop: '40px',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{CANCEL_BUTTON}
									</div>
								),
							},
						]}
					/>
				</div>
				<Col className='container-div product_variant_div' md={12}>
					{this.state.isLoading == true && (
						<div
							className='load'
							style={{
								backgroundColor: 'transparent',
								width: '85%',
								height: '50%',
							}}
						>
							<div className='load__icon-wrap'>
								<svg className='load__icon'>
									<path
										fill='#6c6f7f'
										d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
									/>
								</svg>
							</div>
						</div>
					)}

					{this.state.isLoading == false && (
						<Col md={12}>
							<Link to={`/pages/products?q=${this.props.history.location && this.props.history.location.state && this.props.history.location.state.searchQuery}`}>
								<button className='back_products'>Back</button>
							</Link>
							<Card style={{ marginTop: 20 }}>
								<CardBody>
									<div className='card__title'>
										<h5 className='bold-text '>Add Products</h5>
									</div>
									<div className='proinfo_indentify variant_div_inner'>
										<div className='identifiers'>
											<h3 className='stepproductinfo step1'>
												Product Info
												<span>
													<label>Help</label>
													<a
														href=' https://valetseller.com/2020/10/27/manually-adding-products/'
														target='_blank'
													>
														<img
															className='tooltips'
															src='../../img/info.png'
														/>
													</a>
												</span>
											</h3>
											<div className='images_div'>
												<div className={(this.state.productValidation.Name && this.state.productValidation.Name.hasError) && "form__form-group-error-container"}>
													<div className='form__form-group-field image-width'>
														<span>
															<label className='step2'>
																Name<span className='mantory'>*</span>{' '}
																<span className='labelgray'>
																	(<span>{140 - Name.length}</span> chars)
																</span>
															</label>
															<input
																name='name'
																type='text'
																placeholder=''
																value={Name}
																maxLength='140'
																className="form__product-input"
																onChange={(event) => {
																	this.setState({ Name: event.target.value });
																}}
															/>
														</span>
													</div>
													{
														(this.state.productValidation.Name && this.state.productValidation.Name.hasError) && (
															<p className="form__error_text">
																{this.state.productValidation.Name.errorMessage}
															</p>
														)
													}
												</div>
												<div className={(this.state.productValidation.Sku && this.state.productValidation.Sku.hasError) && "form__form-group-error-container"}>
													<div
														className={`form__form-group-field image-width ${
															(
																this.state.productValidation.Sku
																&& this.state.productValidation.Sku.hasError
															)
															&& 'form__form-group-field-error'}`}
													>
														<ReactTooltip />
														<span>
															<span>
																<label>
																	SKU<span className='mantory'>*</span>
																</label>(<span>{30 - Sku.length}</span> chars){' '}
																<img
																	className='tooltips'
																	src='../../img/info.png'
																	data-tip={this.state.isDisabled?"Editing a SKU is not possible. Please click the link and send us an email":"Customizable Stock Keeping Unit"}
																/>
															{this.state.isDisabled ? (<a target="_blank" href={this.state.hrefValue}> Edit Sku</a>) : ""}
															</span>
															<input
																name='name'
																type='text'
																placeholder=''
																value={Sku}
																maxLength='30'
																disabled={this.state.isDisabled}
																style={this.state.isDisabled ? { cursor: 'not-allowed' } : {}}
																className="form__product-input"
																onChange={(event) => {
																	this.setState({ Sku: event.target.value });
																}}
															/>
														</span>
													</div>
													{
														(this.state.productValidation.Sku && this.state.productValidation.Sku.hasError) && (
															<p className="form__error_text">
																{this.state.productValidation.Sku.errorMessage}
															</p>
														)
													}
												</div>
												<div className={(this.state.productValidation.selectedCategory && this.state.productValidation.selectedCategory.hasError) && "form__form-group-error-container" }>
													<div className='form__form-group-field image-width image-width-market'>
														<span>
															<label>
																Category<span className='mantory'>*</span>
															</label>
															<Select
																value={selectedCategory}
																onChange={this.handleCategoryChange}
																options={categoryList}
															/>
														</span>
													</div>
													{
															(this.state.productValidation.selectedCategory && this.state.productValidation.selectedCategory.hasError) && (
																<p className="form__error_text">
																	{this.state.productValidation.selectedCategory.errorMessage}
																</p>
															)
													}
												</div>

												<div className='form__form-group-field image-width'>
													<span>
														<label className='market_places'>
															Marketplace (Multi Select)
														</label>
														{/* <a
															onClick={() => {
																this.getMarketplaces();
															}}
														> */}
															{/* <img
																className='tooltips'
																src='../../img/info.png'
																data-tip={
																	subscription_id == 'none' ? (
																		'Sell on more marketplaces. Upgrade your plan.'
																	) : (
																		'You can select one or more marketplaces.'
																	)
																}
															/> */}
														{/* </a> */}
														<Select
															value={selectSellType}
															maxMenuHeight={150}
															onChange={this.handleCartChange}
															options={cartList}
															isMulti
															// noOptionsMessage={() => <a
															// 	onClick={() => {
															// 		this.getMarketplaces();
															// 	}}
															// 	style={{ cursor: 'pointer', color: '#3c4559' }}
															// >
															// 	{subscription_id == 'none' ? (
															// 		'Upgrade To Sell On More Channels.'
															// 	) : (
															// 		'Upgrade to more marketplaces now!'
															// 	)
															// 	}
															// </a>}

														/>
													</span>
												</div>

												<div className={(this.state.productValidation.Description && this.state.productValidation.Description.hasError) && "form__form-group-error-container"}>
													<div className='form__form-group-field image-width-1'>
														<span>
															<label>
																Description<span className='mantory'>*</span>
															</label>
															<Editor
																editorState={editorState}
																toolbarClassName='toolbarClassName'
																wrapperClassName='wrapperClassName'
																editorClassName='editorClassName'
																onEditorStateChange={this.onEditorStateChange}
																customStyleMap={{
																	redBackground: {
																	  backgroundColor: 'red'
																	},
																	greenBackground: {
																	  backgroundColor: 'green'
																	}
																  }}
															/>
														</span>
													</div>
													{
														(this.state.productValidation.Description && this.state.productValidation.Description.hasError) && (
															<p className="form__error_text">
																{this.state.productValidation.Description.errorMessage}
															</p>
														)
													}
												</div>

												{/* <span className="checkbox" style={{ width: '45%', margin: 0, paddingLeft: 15, paddingBottom: 15 }}>
                        <input type="checkbox" checked={isNotNewProduct} onChange={(event) => { this.setState({ isNotNewProduct: !isNotNewProduct }) }} /> This product is not new
                      </span> */}

												{isNotNewProduct && (
													<div className='form__form-group-field name_inner'>
														<span>
															<Select
																value={notNew}
																onChange={this.handlenotNewChange}
																options={notnewList}
															/>
														</span>
													</div>
												)}
											</div>
										</div>

										<Col className='container-div sugges' md={12}>
											<h3>
												Product Features
												<span>
													<label>Help</label>
													<a
														href='https://valetseller.com/2020/10/27/manually-adding-products/'
														target='_blank'
													>
														<img
															className='tooltips'
															src='../../img/info.png'
														/>
													</a>
												</span>
											</h3>
											<ul>
												<li>
													<Input
														type='text'
														maxLength='500'
														value={Feature1}
														onChange={(event) => {
															this.setState({ Feature1: event.target.value });
														}}
														style={{ width: '92%' }}
													/>
												</li>
												<li>
													<Input
														type='text'
														value={Feature2}
														onChange={(event) => {
															this.setState({ Feature2: event.target.value });
														}}
														style={{ width: '92%' }}
													/>
												</li>
												<li>
													<Input
														type='text'
														value={Feature3}
														onChange={(event) => {
															this.setState({ Feature3: event.target.value });
														}}
														style={{ width: '92%' }}
													/>
												</li>
												<li>
													<Input
														type='text'
														value={Feature4}
														onChange={(event) => {
															this.setState({ Feature4: event.target.value });
														}}
														style={{ width: '92%' }}
													/>
												</li>
												<li>
													<Input
														type='text'
														value={Feature5}
														onChange={(event) => {
															this.setState({ Feature5: event.target.value });
														}}
														style={{ width: '92%' }}
													/>
												</li>
											</ul>
										</Col>
									</div>

									<div className='identifiers variant_div_inner'>
										<h3 style={{ color: '#f14e6c' }}>
											Photos & Video*
											<span>
												<label>Help</label>

												<img
													className='tooltips'
													src='../../img/info.png'
													onClick={() => {
														this.editPhoto();
													}}
												/>
											</span>
										</h3>
										<div style={{ marginLeft: 15}}>
											<p style={{ color: '#999'}}>Images must have an aspect ratio of 1 to 1 </p>
										</div>
										<div className='import_div_div images-upload'>
											{images.length > 0 && (
												<DragDropContext
													onDragEnd={this.handleRLDDChange}
													className='drag-context'
												>
													<Droppable
														droppableId='droppable'
														direction='horizontal'
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																style={getListStyle(snapshot.isDraggingOver)}
																{...provided.droppableProps}
															>
																{images.map((item, index) => (
																	<Draggable
																		key={item.id.toString()}
																		draggableId={item.id.toString()}
																		index={index}
																	>
																		{(provided, snapshot) => (
																			<div
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				style={getItemStyle(
																					snapshot.isDragging,
																					provided.draggableProps.style,
																				)}
																			>
																				{item.Url != '' && (
																					<div className='product-img'>
																						<SimpleReactLightbox>
																							<ProductImage
																								light
																								url={item.Url}
																							/>
																							<SRLWrapper
																								options={options}
																								images={images}
																							/>
																						</SimpleReactLightbox>
																						<img
																							src='../../img/close.png'
																							className='upload_img_close'
																							alt=''
																							onClick={() => {
																								this.removeImageFile(index);
																							}}
																						/>
																					</div>
																				)}
																			</div>
																		)}
																	</Draggable>
																))}
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</DragDropContext>
											)}

											{/* <span className="image_upload_split">
                                    <span>+</span>
                                    <img src="../../img/close.png" className="upload_img_close" alt="" onClick={() => { this.removeImage(1) }} />
                                    <input name="url" type="file" placeholder="" ref={ref => this.fileInput = ref} accept="image/*"
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => this.photoOnChange(e, 'Url', 1)} />
                                  </span> */}

											<div
												className='import_div'
												onClick={() => {
													this.addImage();
												}}
											>
												<img className='tooltips' src='../../img/image.PNG' />
												<input
													name='url'
													type='file'
													placeholder=''
													hidden
													ref={(ref) => (this.fileInput = ref)}
													accept='image/*'
													onChange={(e: ChangeEvent<HTMLInputElement>) =>
														this.photoOnChange(e, 'Url', 1)}
												/>
												<span>Add Photo</span>
											</div>
										</div>


										<div className='import_div_div'>
											{youTubeId == '' && (
												<div
													className='import_div'
													onClick={() => {
														this.toggle();
													}}
												>
													<img className='tooltips' src='../../img/video.PNG' />
													<span>Add Video</span>
												</div>
											)}

											{youTubeId != '' && (
												<div
													className='import_div video_import'
													onClick={() => {
														this.toggle();
													}}
												>
													<img
														className='tooltips video_import1'
														src={
															'https://img.youtube.com/vi/' +
															youTubeId +
															'/hqdefault.jpg'
														}
													/>
													<img
														src='../../img/close.png'
														className='upload_img_close'
														alt=''
													/>
												</div>
											)}
										</div>
									</div>

									<Col className='container-div variant_div_inner' md={6}>
										<h3>
											Keywords
											<span>
												<label>Help</label>
												<a
													href='https://valetseller.com/2020/10/27/manually-adding-products/'
													target='_blank'
												>
													<img className='tooltips' src='../../img/info.png' />
												</a>
											</span>
										</h3>
										<div className='form__form-group-field name_inner'>
											<label>
												Search Terms (<span>{150 - Seo.length}</span> chars.)
											</label>
											<span>
												<textarea
													name='name'
													type='text'
													rows='8'
													className='textare'
													value={Seo}
													maxLength='150'
													onChange={(event) => {
														this.setState({ Seo: event.target.value });
													}}
												/>
											</span>
										</div>
									</Col>

									<Col className='container-div variant_div_inner' md={6}>
										<h3>
											Product Details
											{/* <span>
                      <label>Help</label>
                      <img className="tooltips" src="../../img/info.png"
                        data-tip="Help" ></img></span> */}
										</h3>

										<div className={(this.state.productValidation.Brand && this.state.productValidation.Brand.hasError) && "form__form-group-error-container"}>
											<div className='form__form-group-field image-width'>
												<span>
													<label>
														Brand Type <span className='mantory'>*</span>
														<a
															href='https://valetseller.com/2020/10/27/manually-adding-products/'
															target='_blank'
														>
															<img
																className='tooltips'
																src='../../img/info.png'
															/>
														</a>
													</label>
													<Select
														value={brandStatus || MyBrandStatus}
														onChange={this.handleBrandStatusChange}
														placeholder="select brand type"
														options={[
															{ value: 'our_brandname', label: 'Use A Valet Seller Brand' },
															{ value: 'your_brandname', label: 'Use Your Brand Name' },
														]}
													/>
												</span>
											</div>
											{brandStatus &&
											brandStatus.value == 'our_brandname' && (
												<div className='form__form-group-field image-width-2'>
													<span>
														<label>
															Select Brand<span className='mantory'>*</span>
														</label>
														<Select
															onChange={this.handleOurBrandStatusChange}
															options={OurBrandStatusType}
														/>
													</span>
												</div>
											)}

											{brandStatus &&
											brandStatus.value == 'your_brandname' && (
												<div className='form__form-group-field image-width'>
													<span className="creatable">
														<label>
															Brand Name<span className='mantory'>*</span>
														</label>
														<Creatable
															isClearable={true}
															onChange={(userBrandnameValue) => this.setState({ BrandName: userBrandnameValue && userBrandnameValue.value })}
															options={userBrandHistory}
															defaultValue={{ label: BrandName, value: BrandName }}
															placeholder="Create or select brand name"
															style={{ menu: provided => ({ ...provided, zIndex: 'unset' }) }}
														/>
														{/* <input
															name='shortdescription'
															type='text'
															placeholder=''
															value={BrandName}
															className="form__product-input"
															onChange={(event) => {
																this.setState({ BrandName: event.target.value });
															}}
														/> */}
													</span>
												</div>
											)}
											{
												(this.state.productValidation.Brand && this.state.productValidation.Brand.hasError) && (
													<p className="form__error_text">
														{this.state.productValidation.Brand.errorMessage}
													</p>
												)
											}
										</div>

										<div className='form__form-group-field name_inner'>
											<Col className={(this.state.productValidation.Weight && this.state.productValidation.Weight.hasError) && "form__form-group-error-container"}>
												<label>
													<span className='redcolor'>
														Weight (lbs.)<span className='mantory'>*</span>
													</span>
												</label>
												<div>
													<input
														type='number'
														placeholder='0.00'
														min='1'
														value={Weight}
														className="form__product-input"
														onChange={(event) => {
															this.setState({ Weight: event.target.value ? Number(event.target.value) : null });
														}}
													/>
													{
														(this.state.productValidation.Weight && this.state.productValidation.Weight.hasError) && (
															<p className="form__error_text">
																{this.state.productValidation.Weight.errorMessage}
															</p>
														)
													}
												</div>
											</Col>
											<Col className={(this.state.productValidation.Length && this.state.productValidation.Length.hasError) && "form__form-group-error-container"}>
												<label>Length (in.)</label>
												<input
													type='number'
													placeholder='0.00'
													min='1'
													value={Length}
													className="form__product-input"
													onChange={(event) => {
														this.setState({ Length: event.target.value ? Number(event.target.value) : null});
													}}
												/>
												{
													(this.state.productValidation.Length && this.state.productValidation.Length.hasError) && (
														<p className="form__error_text">
															{this.state.productValidation.Length.errorMessage}
														</p>
													)
												}
											</Col>
											<Col className={(this.state.productValidation.Width && this.state.productValidation.Width.hasError) && "form__form-group-error-container"}>
												<label>Width (in.)</label>
												<input
													type='number'
													placeholder='0.00'
													min='1'
													value={Width}
													className="form__product-input"
													onChange={(event) => {
														this.setState({ Width: event.target.value ? Number(event.target.value) : null });
													}}
												/>
												{
													(this.state.productValidation.Width && this.state.productValidation.Width.hasError) && (
														<p className="form__error_text">
															{this.state.productValidation.Width.errorMessage}
														</p>
													)
												}
											</Col>
											<Col className={(this.state.productValidation.Height && this.state.productValidation.Height.hasError) && "form__form-group-error-container"}>
												<label>Height (in.)</label>
												<input
													type='number'
													placeholder='0.00'
													min='1'
													value={Height}
													className="form__product-input"
													onChange={(event) => {
														this.setState({ Height: event.target.value ? Number(event.target.value) : null });
													}}
												/>
																							{
												(this.state.productValidation.Height && this.state.productValidation.Height.hasError) && (
													<p className="form__error_text">
														{this.state.productValidation.Height.errorMessage}
													</p>
													)
												}
											</Col>
										</div>

										{ProductAttributes.map((item, key) => {
											return (
												<div className='form__form-group-field name_inner_detail'>
													<Col>
														<label>Detail</label>
														<input
															type='text'
															placeholder='e.g. Color'
															value={item.detail}
															onChange={(event) => {
																this.updateProductAttributes(
																	event.target.value,
																	'detail',
																	key,
																);
															}}
														/>
													</Col>
													<Col>
														<label>Description</label>
														<input
															type='text'
															placeholder='e.g. Blue'
															value={item.description}
															onChange={(event) => {
																this.updateProductAttributes(
																	event.target.value,
																	'description',
																	key,
																);
															}}
														/>
													</Col>
													<a
														href='javascript:void(0)'
														onClick={() => {
															this.removeProductAttributes(key);
														}}
														className='name_close'
													>
														X
													</a>
												</div>
											);
										})}
										<button
											className='addbtn add_product_btn'
											onClick={() => {
												this.addProductAttributes();
											}}
										>
											<span>+</span>Add Product Attributes
										</button>
									</Col>

									<div className='identifiers_tabs'>
										<Tabs selectedIndex={IsSimpleProduct ? 0 : 1}>
											<TabList>
												<Tab>
													<input
														name='producttype'
														type='radio'
														value='simpleproduct'
														checked={IsSimpleProduct}
														onChange={(event) => {
															this.setState({
																IsSimpleProduct: !IsSimpleProduct,
															});
														}}
														placeholder=''
													/>{' '}
												</Tab>
												<span>This is a simple product</span>
												<Tab>
													<input
														name='producttype'
														type='radio'
														value='productvariations'
														checked={!IsSimpleProduct}
														onChange={(event) => {
															this.setState({
																IsSimpleProduct: !IsSimpleProduct,
															});
														}}
														placeholder=''
													/>
												</Tab>
												<span>This product has variations</span>
											</TabList>
											<TabPanel>
												<Col
													className='container-div variant_div_inner_final'
													md={12}
												>
													<h3>
														Prices
													</h3>
													<Col
														md={6}
														className='container-div variant_div_inner'
														style={{ border: 'none' }}
													>
														<div className={(this.state.productValidation.Price && this.state.productValidation.Price.hasError) && "form__form-group-error-container"}>
															<div
																className='form__form-group-field name_inner'
																style={{ width: '57%' }}
															>
																<label>
																	<span className='redcolor'>Price<span className='mantory'>*</span></span>
																</label>
																<span>
																	<input
																		name='name'
																		type='number'
																		min='0'
																		className="form__product-input"
																		placeholder='0.00'
																		value={GlobalListingAttributes.Price}
																		onChange={(event) => {
																			this.updateGlobalAttributes(
																				event.target.value,
																				'Price',
																			);
																		}}
																	/>
																</span>
															</div>
															{
																(this.state.productValidation.Price && this.state.productValidation.Price.hasError) && (
																	<p className="form__error_text">
																		{this.state.productValidation.Price.errorMessage}
																	</p>
																)
															}
														</div>

														<div className={(this.state.productValidation.BuyPrice && this.state.productValidation.BuyPrice.hasError) && "form__form-group-error-container"}>
															<div
																className='form__form-group-field name_inner'
																style={{ width: '57%' }}
															>
																<label>Retail Price (MSRP)</label>
																<span>
																	<input
																		name='name'
																		type='number'
																		min='0'
																		placeholder='0.00'
																		value={BuyPrice}
																		className="form__product-input"
																		onChange={(event) => {
																			this.setState({
																				BuyPrice: event.target.value ? Number(event.target.value) : null,
																			});
																		}}
																	/>
																</span>
															</div>
															{
																(this.state.productValidation.BuyPrice && this.state.productValidation.BuyPrice.hasError) && (
																	<p className="form__error_text">
																		{this.state.productValidation.BuyPrice.errorMessage}
																	</p>
																)
															}
														</div>
														<div
															className='form__form-group-field name_inner'
															style={{ width: '100%' }}
														>
															<label>Max Product Discount</label>
															<div className={(this.state.productValidation.MaxDiscount && this.state.productValidation.MaxDiscount.hasError) && "form__form-group-error-container"}>
																<span style={{ width: '55%', float: 'left' }}>
																	<input
																		name='name'
																		type='number'
																		min='0'
																		placeholder='0.00'
																		value={MaxDiscount}
																		className="form__product-input"
																		onChange={(event) => {
																			this.setState({
																				MaxDiscount: event.target.value ? Number(event.target.value) : null,
																			});
																		}}
																	/>
																</span>
																{
																	(this.state.productValidation.MaxDiscount && this.state.productValidation.MaxDiscount.hasError) && (
																		<p
																			className="form__error_text"
																			style={{
																				position: 'absolute',
																				marginTop: '7%',
																				marginLeft: 0,
																			}}
																		>
																			{this.state.productValidation.MaxDiscount.errorMessage}
																		</p>
																	)
																}
															</div>
															<span
																className='checkbox'
																style={{
																	width: '45%',
																	margin: 0,
																	paddingLeft: 15,
																}}
															>
																<input
																	type='checkbox'
																	checked={isDiscountPrice}
																	onChange={(event) => {
																		this.discountIdentifiers();
																	}}
																/>{' '}
																Always sell at maximum discount percentage
															</span>
														</div>
													</Col>

													<Col
														md={6}
														className='container-div variant_div_inner'
														style={{ border: 'none' }}
													>
													<div className={(this.state.productValidation.QuantityOnHand && this.state.productValidation.QuantityOnHand.hasError) && "form__form-group-error-container"}>
														<div className='form__form-group-field name_inner'>
																<label>
																	<span className='redcolor'>Quantity<span className='mantory'>*</span></span>
																</label>
																<span>
																	<input
																		name='name'
																		type='number'
																		min='0'
																		placeholder='0'
																		value={QuantityOnHand}
																		className="form__product-input"
																		onChange={(event) => {
																			this.setState({
																				QuantityOnHand: event.target.value,
																			});
																		}}
																	/>
																</span>
															</div>
															{
																(this.state.productValidation.QuantityOnHand && this.state.productValidation.QuantityOnHand.hasError) && (
																	<p className="form__error_text">
																		{this.state.productValidation.QuantityOnHand.errorMessage}
																	</p>
																)
															}
													</div>
														<div
															className='form__form-group-field middle-width'
															style={{width: '100%', paddingBottom: 5}}
														>
															<span>
																<div style={{
																	display: 'flex',
																	alignItems: 'center',
																	justifyContent: 'space-between',
																}}>
																	<div>
																	<label>UPC</label>
																	<img
																		className='tooltips'
																		src='../../img/info.png'
																		data-tip='Universal Product Code'
																	/>

																	{/*	{!productUpc && <small className="ml-2"
																							   style={{fontSize: '100%'}}>
																			{this.state.upcPurchases.length > 0 ? 'Select a UPC from your list' : 'Don\'t have UPCs? Purchase them now.'}
																		</small>}*/}

                              </div> 

																{/*	{!productUpc && <button
																		className='publish mr-0 ml-0'
																		onClick={this.state.upcPurchases.length > 0 ? this.toggleSetUpcModal : this.togglePruchaseUpcModal}
																	>
																		{this.state.upcPurchases.length > 0 ? 'Select a Upc' : 'Purchase'}
																	</button>}*/}

																</div>
																<input
																	name='name'
																	type='text'
																	placeholder=''
																	value={Identifiers.UPC}
																	onChange={(event) => {
																		this.updateIdentifiers(
																			event.target.value,
																			'UPC',
																		);
																	}}
																/>
															</span>
														</div>
														<div
															className='form__form-group-field middle-width'
															style={{ width: '100%', paddingBottom: 5 }}
														>
															<span>
																<span>
																	<label>EAN</label>
																	<img
																		className='tooltips'
																		src='../../img/info.png'
																		data-tip='International Article Number'
																	/>
																</span>
																<input
																	name='name'
																	type='text'
																	placeholder=''
																	value={Identifiers.EAN}
																	disabled={isBlockIdentifiers}
																	onChange={(event) => {
																		this.updateIdentifiers(
																			event.target.value,
																			'EAN',
																		);
																	}}
																/>
															</span>
														</div>
														<div
															className='form__form-group-field middle-width'
															style={{ width: '100%', paddingBottom: 5 }}
														>
															<span>
																<span>
																	<label>ISBN</label>
																	<img
																		className='tooltips'
																		src='../../img/info.png'
																		data-tip='International Standard Book Number'
																	/>
																</span>
																<input
																	name='name'
																	type='text'
																	placeholder=''
																	value={Identifiers.ISBN}
																	disabled={isBlockIdentifiers}
																	onChange={(event) => {
																		this.updateIdentifiers(
																			event.target.value,
																			'ISBN',
																		);
																	}}
																/>
															</span>
														</div>
														<div
															className='form__form-group-field middle-width'
															style={{ width: '100%', paddingBottom: 5 }}
														>
															<span>
																<span>
																	<label>GTIN</label>
																	<img
																		className='tooltips'
																		src='../../img/info.png'
																		data-tip='Global Trade Item Number'
																	/>
																</span>
																<input
																	name='name'
																	type='text'
																	placeholder=''
																	value={Identifiers.GTIN}
																	disabled={isBlockIdentifiers}
																	onChange={(event) => {
																		this.updateIdentifiers(
																			event.target.value,
																			'GTIN',
																		);
																	}}
																/>
															</span>
														</div>
													</Col>
												</Col>
											</TabPanel>
											<TabPanel>
												<div className='identifiers'>
													<span className='form_header'>
														This product has variations
													</span>

													<div className='product_options_div'>
														<div className='color_box '>
															<span className='product-title'>
																<label>Add product options:</label>
															</span>
															<div className='product_options'>
																<input
																	name='options'
																	type='checkbox'
																	placeholder=''
																	checked={IsColor}
																	onChange={(event) => {
																		this.setState({ IsColor: !IsColor });
																	}}
																/>
																<span>Color</span>
															</div>
															<div className='product_options'>
																<input
																	name='options'
																	type='checkbox'
																	placeholder=''
																	checked={IsSize}
																	onChange={(event) => {
																		this.setState({ IsSize: !IsSize });
																	}}
																/>
																<span>Size</span>
															</div>
															{/* <div className="product_options">
                              <input name="options" type="radio" placeholder="" /><span>Quantity</span>
                            </div> */}
															<div className='product_options'>
																<input
																	name='options'
																	type='checkbox'
																	placeholder=''
																	checked={IsCustom}
																	onChange={(event) => {
																		this.setState({ IsCustom: !IsCustom });
																	}}
																/>
																<span>Other</span>
															</div>
															{/* <div className="product_add_button">
                              <button className="btn addbtn" onClick={() => { this.addProductVariants() }}>Add</button>
                            </div> */}
														</div>

														{IsColor && (
															<div className='options_block'>
																<div className='color_box '>
																	<div className='color_first'>Color</div>
																	<div className='color_second'>
																		<a
																			href='javascript:void(0)'
																			onClick={() => {
																				this.removeAllProductColor();
																			}}
																		>
																			remove all color options
																		</a>
																	</div>
																</div>
																<div className='add_color'>
																	{ProductColor.map((item, key) => {
																		return (
																			<span className='colorinput' key={key}>
																				<div
																					className='swatch_box'
																					style={{
																						backgroundColor: item.color,
																					}}
																				>
																					<div
																						style={styles.swatch}
																						onClick={() => {
																							this.handleClick(key);
																						}}
																					>
																						<div
																							style={{
																								backgroundColor: item.color,
																							}}
																						/>
																					</div>
																				</div>
																				{item.displayColorPicker ? (
																					<div style={styles.popover}>
																						<div
																							style={styles.cover}
																							onClick={() => {
																								this.handleClose(key);
																							}}
																						/>
																						<SketchPicker
																							color={item.color}
																							onChangeComplete={(hex) => {
																								this.handleChange(hex, key);
																							}}
																						/>
																					</div>
																				) : null}
																				<input
																					type='text'
																					placeholder='Name'
																					value={item.name}
																					onChange={(event) => {
																						this.updateProductColor(
																							event.target.value,
																							'name',
																							key,
																						);
																					}}
																				/>{' '}
																				<a
																					href='javascript:void(0)'
																					onClick={() => {
																						this.removeProductColor(key);
																					}}
																				>
																					<img src='../../img/close_btn.png' />
																				</a>
																			</span>
																		);
																	})}
																	<a
																		href='javascript:void(0)'
																		onClick={() => {
																			this.addProductColor();
																		}}
																	>
																		+
																	</a>
																</div>
															</div>
														)}

														{IsSize && (
															<div className='options_block'>
																<div className='color_box '>
																	<div className='color_first'>Size</div>
																	<div className='color_second'>
																		<a
																			href='javascript:void(0)'
																			onClick={() => {
																				this.removeAllProductSize();
																			}}
																		>
																			remove all size options
																		</a>
																	</div>
																</div>
																<div className='add_color'>
																	{ProductSize.map((item, key) => {
																		return (
																			<span key={key}>
																				<input
																					type='text'
																					placeholder='Name'
																					value={item.name}
																					onChange={(event) => {
																						this.updateProductSize(
																							event.target.value,
																							'name',
																							key,
																						);
																					}}
																				/>{' '}
																				<a
																					href='javascript:void(0)'
																					onClick={() => {
																						this.removeProductSize(key);
																					}}
																				>
																					<img src='../../img/close_btn.png' />
																				</a>{' '}
																			</span>
																		);
																	})}

																	<a
																		href='javascript:void(0)'
																		onClick={() => {
																			this.addProductSize();
																		}}
																	>
																		+
																	</a>
																</div>
															</div>
														)}

														{IsCustom && (
															<div className='options_block'>
																<div className='color_box '>
																	<div className='color_first'>Other</div>
																	<div className='color_second'>
																		<a
																			href='javascript:void(0)'
																			onClick={() => {
																				this.removeAllCustom();
																			}}
																		>
																			remove all custom options
																		</a>
																	</div>
																</div>
																<div className='add_color'>
																	{CustomList.map((item, key) => {
																		return (
																			<span key={key}>
																				<input
																					type='text'
																					placeholder='Name'
																					value={item.name}
																					onChange={(event) => {
																						this.updateCustom(
																							event.target.value,
																							'name',
																							key,
																						);
																					}}
																				/>{' '}
																				<a
																					href='javascript:void(0)'
																					onClick={() => {
																						this.removeCustom(key);
																					}}
																				>
																					<img src='../../img/close_btn.png' />
																				</a>{' '}
																			</span>
																		);
																	})}
																	<a
																		href='javascript:void(0)'
																		onClick={() => {
																			this.addCustom();
																		}}
																	>
																		+
																	</a>
																</div>
															</div>
														)}
													</div>

													{/* <div className="product_options_div product_options_div2">
                          <div className="form__form-group-field image-width">
                            <span>
                              <label>Your Price (Optional)</label>
                              <input name="buyprice" type="number" placeholder="" value={BuyPrice}
                                onChange={(event) => { this.setState({ BuyPrice: event.target.value }) }} />
                            </span>
                          </div>

                          <div className="form__form-group-field image-width">
                            <span>
                              <label>Selling Price<span className="mantory">*</span></label>
                              <input name="shortdescription" type="number" placeholder="" value={GlobalListingAttributes.Price}
                                onChange={(event) => { this.updateGlobalAttributes(event.target.value, 'Price') }} />
                            </span>
                          </div>
                        </div> */}
													{ChildProducts.map((item, key) => {
														const variationValidationArray = this.state.childProductValidation;
														const validation = variationValidationArray && variationValidationArray[key];
														return (
															<div className='addedvarient' key={key}>
																<div className='image'>
																	<label>Photo</label>
																	<span>
																		{item.image == '' && (
																			<img src='../../img/image.PNG' />
																		)}
																		{item.image != '' && (
																			<img src={item.Image} />
																		)}
																		<img
																			src='../../img/edit_btn.png'
																			className='edit_photo'
																			onClick={() => {
																				this.editImageModal(key);
																			}}
																		/>
																	</span>
																</div>

																<div className='variation'>
																	<label>Variation</label>
																	<span>{item.Variation}</span>
																</div>

																<div
																	className="form__form-group"
																	style={{ width: '8%' }}
																>
																	<span>
																		<span>
																			<label>Quantity</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Quantity Available'
																			/>
																		</span>
																		<input
																			name='name'
																			type='number'
																			min='0'
																			placeholder=''
																			value={item.QuantityAvailable}
																			className="form__product-variation-input"
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'QuantityAvailable',
																					key,
																				);
																			}}
																		/>
																	</span>
																	{
																		validation && validation.RetailPrice.hasError && (
																			<p className="form__product-variation-text">
																				{validation.RetailPrice.errorMessage}
																			</p>
																		)
																	}
																</div>

																<div className={`form__form-group${validation && validation.Sku.hasError ? ' form__form-variation-error' : ''}`}>
																	<ReactTooltip />
																	<span>
																		<span>
																			<label>SKU</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Customizable Stock Keeping Unit'
																			/>
																		</span>
																		<input
																			name='name'
																			type='text'
																			placeholder=''
																			maxlength="30"
																			className="form__product-variation-input"
																			onChange={(event) => {
																				this.updateVariationsSku(
																					event.target.value,
																					'Sku',
																					key,
																				);
																			}}
																			value={item.Sku}
																		/>
																	</span>
																	{
																		validation && validation.Sku.hasError && (
																			<p className="form__product-variation-text">
																				{validation.Sku.errorMessage}
																			</p>
																		)
																	}
																</div>
																<div
																	className={`form__form-group${validation && validation.RetailPrice.hasError ? ' form__form-variation-error' : ''}`}
																	style={{ width: '8%' }}
																>
																	<span>
																		<span>
																			<label>Retail Price</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Retail Price (MSRP)'
																			/>
																		</span>
																		<input
																			name='name'
																			type='number'
																			min='0'
																			placeholder=''
																			value={item.BuyPrice}
																			className="form__product-variation-input"
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'BuyPrice',
																					key,
																				);
																			}}
																		/>
																	</span>
																	{
																		validation && validation.RetailPrice.hasError && (
																			<p className="form__product-variation-text">
																				{validation.RetailPrice.errorMessage}
																			</p>
																		)
																	}
																</div>
																<div className={`form__form-group${validation && validation.Price.hasError ? ' form__form-variation-error' : ''}`}>
																	<ReactTooltip />
																	<span>
																		<span>
																			<label>Price</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Price'
																			/>
																		</span>
																		<input
																			name='name'
																			type='number'
																			min='0'
																			placeholder=''
																			defaultValue={item.Price}
																			className="form__product-variation-input"
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value ? Number(event.target.value) : null,
																					'Price',
																					key,
																				);
																			}}
																		/>
																	</span>
																	{
																		validation && validation.Price.hasError && (
																			<p className="form__product-variation-text">
																				{validation.Price.errorMessage}
																			</p>
																		)
																	}
																</div>

																<div className='form__form-group'>
																	<span>
																		<span>
																			<label>UPC</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Universal Product Code'
																			/>
																		</span>
																		<input
																			name='name'
																			type='text'
																			placeholder=''
																			value={item.UPC}
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'UPC',
																					key,
																				);
																			}}
																		/>
																	</span>
																</div>

																<div className='form__form-group'>
																	<span>
																		<span>
																			<label>EAN</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='International Article Number'
																			/>
																		</span>
																		<input
																			name='name'
																			type='text'
																			placeholder=''
																			value={item.EAN}
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'EAN',
																					key,
																				);
																			}}
																		/>
																	</span>
																</div>

																<div className='form__form-group'>
																	<span>
																		<span>
																			<label>ISBN</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='International Standard Book Number'
																			/>
																		</span>
																		<input
																			name='name'
																			type='text'
																			placeholder=''
																			value={item.ISBN}
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'ISBN',
																					key,
																				);
																			}}
																		/>
																	</span>
																</div>

																<div className='form__form-group'>
																	<span>
																		<span>
																			<label>GTIN</label>
																			<img
																				className='tooltips'
																				src='../../img/info.png'
																				data-tip='Global Trade Item Number'
																			/>
																		</span>
																		<input
																			name='name'
																			type='text'
																			placeholder=''
																			value={item.GTIN}
																			onChange={(event) => {
																				this.updateVariationsAttributes(
																					event.target.value,
																					'GTIN',
																					key,
																				);
																			}}
																		/>
																	</span>
																</div>

																<div style={{ marginTop: 35 }}>
																	<Switch
																		onChange={(event) => {
																			this.handleActiveChange(event, key);
																		}}
																		checked={item.Active}
																	/>
																</div>
																{/* <a href="#" className="closes">X</a> */}
															</div>
														);
													})}
												</div>
											</TabPanel>
											<Col style={{ float: 'left' }}>
												<button
													className='publish step3'
													onClick={() => {
														this.addProduct();
													}}
												>
													{!productStatus  || (productStatus && productStatus.value === 'draft') ? 'Publish' : 'Update' }
												</button>
												{
													(!productStatus  || (productStatus && productStatus.value === 'draft')) && (
														<button
															className='publish'
															onClick={() => {
																this.draftProduct();
														}}
														>
															Save as Draft
														</button>
													)
												}
											</Col>
										</Tabs>
									</div>
								</CardBody>
							</Card>
						</Col>
					)}
					{/* {isShowPopup && <UpgradePopup showMessage={true} redirect={this.redirect} />} */}
					<Modal
						isOpen={this.state.modalIsOpen}
						onAfterOpen={this.afterOpenModal}
						onRequestClose={this.closeModal}
						style={IsCustomStyles}
						contentLabel='Example Modal'
					>
						<div className='editimage'>
							<button
								className='btn addbtn optimisation cropclose'
								onClick={() => {
									this.closeModal();
								}}
							>
								Close
							</button>
							<span>Edit Image</span>
							<button
								className='btn addbtn optimisation cropsave'
								onClick={() => {
									this.croppedImage();
								}}
							>
								SAVE
							</button>
						</div>

						<Cropper
							image={this.state.imageSrc}
							crop={this.state.crop}
							zoom={this.state.zoom}
							aspect={this.state.aspect}
							rotation={this.state.rotation}
							onCropChange={this.onCropChange}
							onCropComplete={this.onCropComplete}
							onZoomChange={this.onZoomChange}
						/>
						<button
							className='btn addbtn add_products optimisation cropbtn'
							onClick={() => {
								this.onRotate();
							}}
						>
							ROTATE
						</button>
					</Modal>

					<Modal
						isOpen={this.state.editmodalIsOpen}
						onAfterOpen={this.afterOpenModal}
						onRequestClose={this.closeImageModal}
						style={editImageStyles}
						contentLabel='Example Modal'
					>
						<div className='top_model_head'>
							<button
								className='btn addbtn optimisation cropclose closing'
								onClick={() => {
									this.closeImageModal();
								}}
							>
								X
							</button>
							<span>Select a photo for this variation</span>
							{/* <button className="btn addbtn optimisation cropsave" onClick={() => { this.croppedImage() }}>SAVE</button> */}
						</div>
						{/* <span className="title_varients">L, green</span> */}
						<div className='selectImages'>
							{Images.map((item, key) => {
								return (
									<a
										href='javascript:void(0)'
										key={key}
										onClick={() => {
											this.selectedImage(key);
										}}
										className={item.selectedImage ? 'selected_image' : ''}
									>
										<img src={item.Url} />
									</a>
								);
							})}
						</div>
						<div className='radio_variations'>
							<p>
								<input
									type='radio'
									name='currentvariations'
									checked={IsCurrentVariations}
									onChange={(event) => {
										this.setState({
											IsCurrentVariations: !IsCurrentVariations,
										});
									}}
								/>{' '}
								<span>Only apply this photo to the current Variation</span>
							</p>
							<p>
								<input
									type='radio'
									name='allvariations'
									checked={!IsCurrentVariations}
									onChange={(event) => {
										this.setState({
											IsCurrentVariations: !IsCurrentVariations,
										});
									}}
								/>{' '}
								<span>Apply this photo to All Variations</span>
							</p>
						</div>
						<div className='variations_save'>
							<button
								className='btn optimisation'
								onClick={() => {
									this.applyPhoto();
								}}
							>
								SAVE
							</button>
						</div>
					</Modal>

					<Modal
						size='sm'
						isOpen={this.state.confirmmodal}
						style={{ top: 180 }}
						style={uploadVideoStyles}
						toggle={this.toggle}
					>
						<ModalHeader>Enter your Video YouTube Link below. </ModalHeader>
						<ModalBody>
							<input
								name='name'
								type='text'
								placeholder=''
								className='youtube_url'
								value={youtubeUrl}
								onChange={(event) => {
									this.setState({ youtubeUrl: event.target.value });
								}}
							/>
						</ModalBody>
						<ModalFooter>
							<Button
								color='primary'
								onClick={() => {
									this.addYouTubeVideo();
								}}
							>
								Submit
							</Button>
							<Button color='danger' onClick={this.toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>


					<Modal
						isOpen={this.state.purchaseUpcModal}
						toggle={this.togglePruchaseUpcModal}
						size='sm'
						style={purchaseUpcStyles}
					>
						<ModalHeader toggle={this.togglePruchaseUpcModal}>
							How many UPCs would you like to purchase?
						</ModalHeader>
						{this.state.isLoading ? (
							<ModalBody>
								<div
									className='load'
									style={{
										backgroundColor: 'transparent',
										width: '85%',
										height: '50%',
									}}
								>
									<div className='load__icon-wrap'>
										<svg className='load__icon'>
											<path
												fill='#6c6f7f'
												d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
											/>
										</svg>
									</div>
								</div>
							</ModalBody>
						) : <>
							<ModalBody>
								<input
									placeholder="Quantity"
									name='upcquantity'
									className='youtube_url'
									min="1"
									type='number'
									value={this.state.upcQuantity}
									onChange={(event) => {
										this.setState({upcQuantity: event.target.value});
									}}
								/>
							</ModalBody>
							<ModalFooter style={{flexDirection: 'column'}}>
								<h4 className="mb-2">PRICE: ${helper.getNumberFormat(this.state.upcPrice)} PER UPC</h4>
								<PayPalBtn
									amount={this.state.upcPrice * this.state.upcQuantity}
									onCreateOrder={this.paypalonCreateOrder}
									onSuccess={this.paypalonSuccess}
									catchError={this.paypalOnError}
									onError={this.paypalOnError}
									onCancel={this.paypalOnError}
								/>
								<h4 className="mb-2">Total:
									${helper.getNumberFormat(this.state.upcPrice * this.state.upcQuantity || 0)}</h4>

								<Button disabled={this.state.isLoading} color='secondary'
										onClick={this.togglePruchaseUpcModal}>
									Cancel
								</Button>
							</ModalFooter>
						</>}

					</Modal>

					<Modal
						isOpen={this.state.selectUpcModal}
						toggle={this.toggleSetUpcModal}
						size='sm'
						style={selectUpcStyles}
					>
						<ModalHeader toggle={this.toggleSetUpcModal}>
							Select a UPC from your list
						</ModalHeader>
						{this.state.isLoading ? (
							<ModalBody>
								<div
									className='load'
									style={{
										backgroundColor: 'transparent',
										width: '85%',
										height: '50%',
									}}
								>
									<div className='load__icon-wrap'>
										<svg className='load__icon'>
											<path
												fill='#6c6f7f'
												d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
											/>
										</svg>
									</div>
								</div>
							</ModalBody>
						) : <>
							<ModalBody>
								<div style={{ maxHeight: 300, overflowY: 'auto' }}>

									<ReactTable
										className='-striped -highlight'
										data={this.state.upcPurchases}
										columns={[
											{
												Header: 'UPC',
												accessor: 'number',
											},
											{
												accessor: "",
												Cell: ({original}) => {
													return (
														<input
															type="checkbox"
															className="checkbox"
															checked={this.state.selectedUPC === original.number}
															onChange={() => this.toggleUpcSelectedRow(original.number)}
														/>
													);
												},
											}
										]}
										showPagination={false}
										showPaginationTop={false}
										showPaginationBottom={false}
										resizable={false}
									/>
								</div>
							</ModalBody>
							<ModalFooter style={{flexDirection: 'row'}}>

								<Button disabled={this.state.isLoading} color='secondary'
										onClick={this.toggleSetUpcModal}>
									Cancel
								</Button>
								<Button disabled={this.state.isLoading} color='primary'
										onClick={this.setUpc}>
									Save
								</Button>
							</ModalFooter>
						</>}

					</Modal>
				</Col>


			</div>
		);
	}
}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(ProductVariation);
