import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';

// import i18n from './i18n';
// import { I18nextProvider } from 'react-i18next';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
