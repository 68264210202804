import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SalesCard from './components/SalesCard';

import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Sales extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:''
    }
  }

  componentDidMount() {
   
  }


 

  render() {
    const {t} = this.props.setOptions
    return (
      <div>
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            {/* <h3 className="page-title">Total Sales</h3> */}
          </Col>
        </Row>
        <Row>
            <SalesCard {...this.props}/>
          
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Sales);
