import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import firebase1 from '../../../utils/config';
import { Card, CardBody, Col, Badge, Button, Input, FormGroup, Label } from 'reactstrap';
import api from '../../../utils/api';

import CSVReader from 'react-csv-reader';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

class HelpRequestCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:'',
      headers:[
                {key:'name',title:'Name'},
                {key:'email',title:'Email'},
                {key:'question',title:'Question'},
                {key:'delete',title:'Delete'}
             ],
      name:'help',
      items:[],
      tempItems:[],
      searchText:''
    };
    this.deleteAction = this.deleteAction.bind(this);
  }

  componentDidMount() {
    
    this.getData();
  }

  getData(){
   
      this.setState({isLoading:true});
      const db = firebase1.app.firestore();
      let items = [];
      let that = this;
      db.collection('help').get().then(function (querySnapshot) {
        if(querySnapshot.size>0){
          querySnapshot.forEach(function(doc) {
            items.push({key:doc.id,...doc.data()})
          })
        }
        that.setState({items:items,tempItems:items,isLoading:false})
      })
  }
  
 

  actionSearch() {

    if (this.state.searchText.length > 0) {
      let filterItems =[];
      let items = this.state.tempItems;
      items.map((item, index) => {
          if(this.headerFilter(this.state.searchText,item)){
              filterItems.push(item);
          }
      })
      this.setState({items:filterItems})
    }
  }

  headerFilter(filterText,item){

    let isFilter =false;
    for(let data in  this.state.headers){
        if(item[this.state.headers[data].key]&&item[this.state.headers[data].key].label&&String(item[this.state.headers[data].key].label).toLowerCase().indexOf(filterText.toLowerCase()) !== -1){
            isFilter =true;
        }
        else if(String(item[this.state.headers[data].key]).toLowerCase().indexOf(filterText.toLowerCase()) !== -1){
            isFilter =true;
        }
    }
    return isFilter;
  }

  searchKey(text) {
    this.setState({
      searchText: text
    }, () => {
      if (text.length == 0) {
        this.setState({items:this.state.tempItems})
      }
    })

  }

  compare( a, b ) {
    if (Number(a.date) < Number(b.date)){
      return 1;
    }
    if ( Number(a.date) > Number(b.date)){
      return -1;
    }
    return 0;
  }

  handleForce = data => {
    this.setState({ 
      csvData:data
    }, () => {
        if(data.length>0){
          console.log('data==',data);
        }        
    })
  }

  deleteAction(id){
    console.log('id===',id);
    let items = this.state.items;
    let tempItems = this.state.tempItems;
    const db = firebase1.app.firestore();
    db.collection('help').doc(id).delete();
    let index = helper.getItemIndex(items,'key',id);
    items.splice(index,1);
    index = helper.getItemIndex(tempItems,'key',id);
    tempItems.splice(index,1);
    this.setState({items:[...items],tempItems:[...tempItems]});

  }

  

  

  handleDarkSideForce = error => {
    console.log('error==',error);
  }


  render() {
    const {t} = this.props.setOptions;
    
    return (
      
      <Col className="container-div" md={12} style={{marginTop:30}}>
      {this.state.isLoading==true&&<div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>}


      {this.state.isLoading==false&&<Col md={12} >
            <div className="search" style={{ marginLeft: 15 }}>
                    <Input type="text" name="search" placeholder="Search for name" value={this.state.searchText} onChange={(event) => { this.searchKey(event.target.value) }} />
                    <Button onClick={() => { this.actionSearch() }} style={{ padding: 0 }}>Search</Button>
                  </div>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Help Request
            {/* <button className="btn addbtn">Import Sku
              <CSVReader
                cssClass="csv-reader-input"
                onFileLoaded={this.handleForce}
                onError={this.handleDarkSideForce}
                parserOptions={papaparseOptions}
                inputId="ObiWan"
                inputStyle={{color: 'red'}}/>
            </button> */}
            </h5>
          </div>
         
          <TableList normal={true} items={this.state.items} deleteAction={this.deleteAction} headers={this.state.headers} name={this.state.name}/>
        </CardBody>
      </Card>
      </Col>}
      </Col>
    )
  }

}

const mapStateToProps = ({ curdReducer }) => {
  const {list_companies} = curdReducer;
  return {list_companies};
};

export default connect(mapStateToProps, actions)(HelpRequestCard);
