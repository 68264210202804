import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Input } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarRolesLink = ({
  title, icon, newLink, route, onClick,
}) => (
  <ul>
    <li className="sidebar__link">
      <Input type="checkbox"/>
      <p className="sidebar__link-title">
        Organisation Units
      </p>
    </li>

    <li className="sidebar__link">
      <Input type="checkbox"/>
      <p className="sidebar__link-title">
        Organisation Units
      </p>
    </li>
  </ul>

);

SidebarRolesLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarRolesLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarRolesLink;
