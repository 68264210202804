import React, { PureComponent } from 'react';
import { Card, CardBody, Col,Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

class SystemRolesCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      small: false,
      item:''
    };
    this.toggleSmall = this.toggleSmall.bind(this);
  }

  toggleSmall(data) {
    this.setState({
      small: !this.state.small,
      item:data
    });
  }
  componentDidMount() {


  }

  render() {
    const {t} = this.props.setOptions
    return (
      <Col md={4} className="systemroles_top">
        <Card>
          <CardBody>
            <div className="card__title">
              <span className="card__title_top">{t('SYSTEM_ROLES')} </span>
              <button className="btn addbtn" onClick={() => { this.props.openRole() }}>{t('CREATE_NEW_ROLE')}</button>
            </div>
            {this.props.roleList.map((data, key) => {
               // Return the element. Also pass key
               return (<div className="card_body" key={key} >
                   <div className={"roles_inner "+ (this.props.selectRoleId==data.id ? 'roles_inner_active' : '')} >
                     <img className="" src="../img/quickdetail1.png" onClick={() => { this.props.selectRole(data) }}></img>
                     <span onClick={() => { this.props.selectRole(data) }}>{data.name}</span>
                     <img className="role_trash" src="../img/trash.png" onClick={() => { this.toggleSmall(data) }} ></img>
                   </div>
                 </div>)
            })}
          </CardBody>
        </Card>

        <Modal isOpen={this.state.small} toggle={this.toggleSmall}
          className={'modal-sm ' + this.props.className}>
            <ModalHeader toggle={this.toggleSmall}>{t('DELETE')}</ModalHeader>
            <ModalBody>
            {t('ARE_WANT_DELETE')}?
            </ModalBody>
            <ModalFooter>
            <Button color="primary" onClick={() => { this.props.deleteRole(this.state.item);this.toggleSmall() }}>{t('OK')}</Button>{' '}
            <Button color="secondary" onClick={this.toggleSmall}>{t('CANCEL')}</Button>
            </ModalFooter>
            </Modal>

      </Col>
    )
  }



}

export default SystemRolesCard;
