import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import api from '../../../utils/api';
import DatePicker from 'react-date-picker';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import {
	Card,
	CardBody,
	Col,
	Button,
	Input,
	FormGroup,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import moment from 'moment';

import Select from 'react-select';

class SearchordersCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			headers: [
				{ key: 'OrderNumber', title: 'Order Id' },
				{ key: 'PaymentReceivedDate', title: 'Date' },
				{ key: 'StorefrontName', title: 'Channel' },
				{ key: 'customer_name', title: 'Customer Name' },
				{ key: 'customer_address', title: 'Shipping Address' },
				{ key: 'email', title: 'Email/Phone No' },
				{ key: 'sku', title: 'SKU' },
				{ key: 'product_title', title: 'Product Title' },
				{ key: 'Status', title: 'Sale Status' },
				{ key: 'DeliveryMethod', title: 'Shipment Status' },
				{ key: 'TotalAmount', title: 'Price' },
				{ key: 'volume', title: 'Quantity Ordered' },
				{ key: 'track_update', title: 'Tracking' },
			],
			status: { value: -1, label: 'All' },
			name: 'search',
			loadingText: 0,
			items: [],
			orderItems: [],
			tempItems: [],
			totalPage: 0,
			tableLoading: false,
			fromDate: '',
			toDate: '',
			salesData: [],
			totalAmount: 0,
			topProducts: [],
			typeChannel: 'All',
			userCompany: '',
			skuList: [],
			role: '',
			Uid: '',
			searchText: '',
			pageSize: 25,
			normal: false,
			small: false,
		};
		this.getData = this.getData.bind(this);
		this.trackUpdate = this.trackUpdate.bind(this);
		this.addReturns = this.addReturns.bind(this);
		this.toggleUpdate = this.toggleUpdate.bind(this);
	}

	componentDidMount() {
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
		}
		if (this.props.match.params.text && this.props.match.params.text != '') {
			this.setState({ searchText: this.props.match.params.text }, () => {
				this.getData(1, this.state.pageSize);
			});
		}
		this.getCarriers();
	}
	componentWillReceiveProps(nextProps) {
		//console.log.log('receive props---',nextProps.match.params.text,this.props.match.params.text);
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
		if (
			nextProps.match.params.text &&
			nextProps.match.params.text != this.props.match.params.text
		) {
			//console.log.log('nextProps.params.text===',nextProps.match.params.text);
			this.setState({ searchText: nextProps.match.params.text }, () => {
				this.getData(1, this.state.pageSize);
			});
		}
	}

	updateUser(user) {
		let role = '';
		if (user.role) {
			role = user.role.label;
		}
		let orderHeaders = this.state.headers;
		if (user.ship_orders && user.ship_orders.value == 'valetseller') {
			orderHeaders.push({ key: 'view', title: 'Ship Details' });
		}

		if (user.role && user.role.label == 'admin') {
			orderHeaders.push({ key: 'order_details', title: 'Order Details' });
			orderHeaders.push({ key: 'returns', title: 'Returns' });
		}
		this.setState({
			userCompany: user.company,
			key: user.key,
			role: role,
			Uid: user.uid,
			firstname: user.firstname,
			email: user.email,
		});
	}

	getCarriers() {
		api.getEcomDashApi('carriers?resultsPerPage=100').then((data) => {
			if (data.status == 'Success') {
				let carrierList = [];
				for (let item of data.data) {
					if (
						item.Id == 7607 ||
						item.Id == 7605 ||
						item.Id == 7606 ||
						item.Id == 24064
					) {
						carrierList.push({ label: item.Name, value: item.Id });
					}
				}
				this.setState({ carrierList: carrierList });
			}
		});
	}

	getData(page, pageSize) {
		if (this.state.searchText.length > 0) {
			this.setState({ tableLoading: true, pageSize: pageSize });
			this.productCount = 0;
			let apiPath = '';

			apiPath = 'orders/getSearchOrders';
			let reqData = {
				Pagination: {
					ResultsPerPage: pageSize,
					PageNumber: page,
				},
			};
			if (this.state.role != 'admin') {
				reqData.Uid = this.state.Uid;
			}
			if (this.state.searchText.length > 0) {
				reqData.search_text = this.state.searchText;
			}
			//console.log.log(reqData);
			api.postApi(apiPath, reqData).then(
				(data) => {
					console.log(data);
					if (data.status == 'Success') {
						let items = data.data;
						for (let item in items) {
							let customerAddress = '';

							if (items[item].CustomerInfo) {
								items[item].customer_name = items[item].CustomerInfo.FullName;
								items[item].email = items[item].CustomerInfo.EmailAddress;
							}
							if (
								items[item].ShippingInfo &&
								items[item].ShippingInfo.Address
							) {
								customerAddress = items[item].ShippingInfo.Address.Street1
									? items[item].ShippingInfo.Address.Street1
									: '';
								customerAddress =
									items[item].ShippingInfo.Address.Street1 == undefined
										? ''
										: customerAddress + ', ';

								customerAddress =
									customerAddress +
									(items[item].ShippingInfo.Address.Street2
										? items[item].ShippingInfo.Address.Street2
										: '');
								customerAddress =
									items[item].ShippingInfo.Address.Street2 == undefined
										? customerAddress
										: customerAddress + ', ';

								customerAddress =
									customerAddress +
									(items[item].ShippingInfo.Address.City
										? items[item].ShippingInfo.Address.City
										: '');
								customerAddress =
									items[item].ShippingInfo.Address.City == undefined
										? customerAddress
										: customerAddress + ', ';

								customerAddress =
									customerAddress +
									(items[item].ShippingInfo.Address.State
										? items[item].ShippingInfo.Address.State
										: '');
								customerAddress =
									items[item].ShippingInfo.Address.State == undefined
										? customerAddress
										: customerAddress + ', ';

								customerAddress =
									customerAddress +
									(items[item].ShippingInfo.Address.ZipCode
										? items[item].ShippingInfo.Address.ZipCode
										: '');
								customerAddress =
									items[item].ShippingInfo.Address.ZipCode == undefined
										? customerAddress
										: customerAddress + ', ';

								customerAddress =
									customerAddress +
									(items[item].ShippingInfo.Address.Country
										? items[item].ShippingInfo.Address.Country
										: '');
							}
							if (items[item].IsFBA) {
								customerAddress = 'FBA Order';
							}
							items[item].shipment_status = items[item].NeedsFulfillment
								? 'InProcess'
								: 'Shipped';
							items[item].customer_address = customerAddress;
							if (items[item].LineItems && items[item].LineItems.length > 0) {
								items[item].sku = items[item].LineItems[0].SkuNumber;
								items[item].product_id = items[item].LineItems[0].ProductId;
								items[item].product_title =
									items[item].LineItems[0].ProductName;
							}
							items[item].volume = 0;
							for (let lineItems in items[item].LineItems) {
								items[item].volume =
									Number(items[item].volume) +
									Number(items[item].LineItems[lineItems].QuantitySold);
							}
						}
						this.setState({
							tableLoading: false,
							items: items,
							totalPage: data.pagination.TotalNumberOfPages,
							normal: false,
						});
					} else {
						this.setState({ tableLoading: false });
					}
				},
				(err) => {
					//console.log.log("err====", err);
					this.setState({ tableLoading: false });
					helper.errorMessage(err);
				},
			);
		}
	}

	toggleUpdate() {
		this.setState({
			small: !this.state.small,
		});
	}

	actionSearch() {
		if (this.state.searchText.length > 0) {
			this.getData(1, this.state.pageSize);
		}
	}
	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				//this.setState({items:[]});
			},
		);
	}

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.actionSearch();
		}
	};

	trackUpdate(OrderNumber) {
		console.log('OrderNumber', OrderNumber);
		this.trackOrder = helper.getItem(
			this.state.items,
			'OrderNumber',
			OrderNumber,
		);
		console.log('item===', this.trackOrder);
		this.setState({ small: !this.state.small });
	}

	handlecarrierChange = (carrier) => {
		this.setState({ carrier });
	};

	addReturns(observstion, id) {
		console.log('observstion,id===', observstion, id);
		this.setState({ tableLoading: true });
		let req = {
			EcomdashId: id,
			Returns: {
				Observstion: observstion,
				Date: new Date(),
			},
		};
		api.postApi('orders/addReturns', req).then(
			(shippmentInfo) => {
				helper.successMessage('Sucessfully updated');
				let items = this.state.items;
				let trackIndex = helper.getItemIndex(
					this.state.items,
					'EcomdashId',
					id,
				);
				if (items[trackIndex]) {
					items[trackIndex].Returns = req.Returns;
				}
				console.log('shippmentInfo==', shippmentInfo, items);
				this.setState({ tableLoading: false, items: [ ...items ] });
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				//helper.errorMessage(err);
			},
		);
	}

	saveTrack() {
		if (this.state.trackingNumber == '') {
			helper.successMessage('Tracking number field is required');
		} else {
			// else if (this.state.trackingUrl == '') {
			//   helper.successMessage('Tracking url field is required');
			// }
			console.log(this.state.carrier, this.trackOrder);

			this.setState({ tableLoading: true, small: false });

			api
				.getEcomDashApi('orders/' + this.trackOrder.EcomdashId + '/shipments')
				.then(
					(shippmentInfo) => {
						console.log('shippmentInfo===', shippmentInfo);
						if (shippmentInfo.status == 'Success') {
							if (shippmentInfo.data.length == 0) {
								this.createShipmentId();
							} else {
								this.createTrackingInfo(
									shippmentInfo.data[0].Id,
									shippmentInfo.data[0].Items,
								);
							}
						} else {
							this.setState({ tableLoading: false });
						}
					},
					(err) => {
						console.log('err====', err);
						this.setState({ tableLoading: false });
						helper.errorMessage(err);
					},
				);
		}
	}

	createShipmentId() {
		let lineItems = [];

		for (let data1 in this.trackOrder.LineItems) {
			let items = {
				LineItemId: this.trackOrder.LineItems[data1].OrderLineItemId,
				quantity: this.trackOrder.LineItems[data1].QuantitySold,
			};
			lineItems.push(items);
		}

		var reqData = [
			{
				OrderId: this.trackOrder.EcomdashId,
				LineItems: lineItems,
			},
		];
		console.log('reqData==', reqData);
		api.postEcomDashApi('Shipments/Create', reqData).then(
			(shippmentInfo) => {
				let shippmentId = '';
				if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].WasSuccessful
				) {
					shippmentId = shippmentInfo.data[0].Shipment.Id;
					this.createTrackingInfo(
						shippmentId,
						shippmentInfo.data[0].Shipment.Items,
					);
				} else if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].Messages &&
					0 < shippmentInfo.data[0].Messages.length
				) {
					helper.errorMessage(shippmentInfo.data[0].Messages[0]);
					console.log(
						'===Not create shippment===',
						shippmentInfo.data[0].Messages[0],
					);
					this.setState({ tableLoading: false });
				} else {
					helper.errorMessage('please try again later');
					console.log('===Not create shippment===', shippmentInfo);
					this.setState({ tableLoading: false });
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	createTrackingInfo(shipmentId, LineItems) {
		let newLineItems = [];
		for (let data1 in LineItems) {
			let lineItems1 = {
				LineItemId: Number(LineItems[data1].Id),
				TrackingNumber: this.state.trackingNumber,
				TrackingURL: this.state.trackingUrl,
			};
			if (this.state.carrier != null) {
				lineItems1.CarrierId = Number(this.state.carrier.value);
			}
			newLineItems.push(lineItems1);
		}

		let reqItems = {
			ShipmentId: Number(shipmentId),
			LineItems: newLineItems,
		};
		if (this.state.carrier != null) {
			reqItems.CarrierId = Number(this.state.carrier.value);
		}

		let newReq = [ reqItems ];

		api.postEcomDashApi('Shipments/submitTrackingInfo', newReq).then(
			(shippmentInfo) => {
				if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].WasSuccessful
				) {
					this.updateOrders();
				} else if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].Messages
				) {
					this.setState({ tableLoading: false });
					helper.errorMessage(shippmentInfo.data[0].Messages[0]);
				} else {
					this.setState({ tableLoading: false });
					helper.errorMessage('please try again later');
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	updateOrders() {
		let req = {
			orderId: this.trackOrder.EcomdashId,
		};
		this.setState({ tableLoading: true });
		api.postApi('orders/updateOrders', req).then(
			(shippmentInfo) => {
				helper.successMessage('Sucessfully updated');
				let items = this.state.items;
				let trackIndex = helper.getItemIndex(
					this.state.items,
					'OrderNumber',
					this.trackOrder.OrderNumber,
				);
				if (items[trackIndex]) {
					items[trackIndex].shipment_status = 'Shipped';
				}
				console.log('shippmentInfo==', shippmentInfo, items);
				this.setState({ tableLoading: false, items: [ ...items ] });
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				//helper.errorMessage(err);
			},
		);
	}

	render() {
		const { t } = this.props.setOptions;
		return (
			<Col className='container-div' md={12}>
				<Col md={12} className='top_products'>
					<Card>
						<CardBody>
							<h5 className='bold-text bold-textt'>ORDERS</h5>
							<div className='search' style={{ marginLeft: 15 }}>
								<Input
									type='text'
									name='search'
									placeholder='Search for sku,name,orders'
									value={this.state.searchText}
									onKeyDown={this.handleKeyDown}
									onChange={(event) => {
										this.searchKey(event.target.value);
									}}
								/>
								<Button
									onClick={() => {
										this.actionSearch();
									}}
									style={{ padding: 0 }}
								>
									Search
								</Button>
							</div>
							{this.state.Uid != undefined &&
							this.state.Uid != '' && (
								<TableList
									normal={this.state.normal}
									tableLoading={this.state.tableLoading}
									totalPage={this.state.totalPage}
									getData={this.getData}
									items={this.state.items}
									headers={this.state.headers}
									trackUpdate={this.trackUpdate}
									addReturns={this.addReturns}
									name={this.state.name}
									className='myprodList'
								/>
							)}
						</CardBody>
					</Card>
				</Col>
				<div className='alignTop' />
				<Modal
					isOpen={this.state.small}
					toggle={this.toggleUpdate}
					className={'modal-md ' + this.props.className}
					style={{ marginTop: 150 }}
				>
					<ModalHeader toggle={this.toggleUpdate} />
					<ModalBody style={{ overflow: 'inherit' }}>
						<div
							className='form__form-group-field left'
							style={{ width: '100%' }}
						>
							<span>
								<label>Tracking Number</label>
								<input
									name='name'
									type='text'
									placeholder=''
									value={this.state.trackingNumber}
									onChange={(event) => {
										this.setState({ trackingNumber: event.target.value });
									}}
								/>
							</span>
						</div>

						<div
							className='form__form-group-field right'
							style={{ width: '100%' }}
						>
							<span>
								<label>Tracking Url</label>
								<input
									name='name'
									type='text'
									placeholder=''
									value={this.state.trackingUrl}
									onChange={(event) => {
										this.setState({ trackingUrl: event.target.value });
									}}
								/>
							</span>
						</div>

						<div
							className='form__form-group-field right'
							style={{ width: '100%' }}
						>
							<span>
								<label>Carrier</label>
								<Select
									value={this.state.carrier}
									onChange={this.handlecarrierChange}
									options={this.state.carrierList}
								/>
							</span>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={() => {
								this.saveTrack();
							}}
						>
							Ok
						</Button>{' '}
						<Button color='secondary' onClick={this.toggleUpdate}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Col>
		);
	}
}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(SearchordersCard);
