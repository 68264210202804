import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProfileCard from './components/ProfileCard';


import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Profile extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {

    }
  }



  

  render() {

    return (
      <div>

      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Profile</h3>
          </Col>
        </Row>
        <Row>
            <ProfileCard/>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Profile);
