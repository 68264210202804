import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import ForgotpasswordForm from './components/ForgotpasswordForm';

const Forgotpassword = () => (
  <div className="account">
    {/* <div className="account__left">
      <img className="" src="img/bg.png" ></img>
    </div> */}
    
    <div className="account__wrapper">
      <div className="account__card">
      
      <div className="account__card_inner" style={{position:'relative'}}>
        <div className="account__head">
        <Link to="/login"><img src="../img/arrow.png" className="close_right"/></Link>
          <h3 className="account__title">
            <span className="account__logo"> <img src="../img/logo1.png"/>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Enter your email and we send you a passsword reset link.</h4>
        </div>
        <ForgotpasswordForm/>
        </div>
      </div>
    </div>
  </div>
);

export default Forgotpassword;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
