import React, { useEffect, useState } from 'react';
import TableList from "../../containers/Table";
import api from '../../utils/api';
import {
    Card,
    CardBody,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
} from 'reactstrap';

const FlaggedKeyword = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [flaggedKeywords, setFlaggedKeywords] = useState([]);
    const headers = [
        { key: "keyword", title: "name" },
        { key: "delete", title: "delete" },
    ];

    const fetchKeywords = () => {
        api.getApi('product/flaggedKeyword')
            .then((resdata) => {
                setFlaggedKeywords(resdata);
            });
    }

    const addKeyword = () => {
        api.postApi('product/flaggedKeyword', { keyword })
            .then(() => {
                fetchKeywords();
                setShowModal(false);
                setKeyword('');
            });
    }

    const deleteAction = (id) => {
        api.postApi(`product/flaggedKeyword/delete/${id}`)
            .then((response) => {
                console.log('haha', response)
                fetchKeywords();
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const update = (id) => {
        api.putApi(`product/flaggedKeyword/${id}`, { keyword })
            .then(() => {
                fetchKeywords();
            });
    }

    useEffect(() => {
        fetchKeywords();
    }, []);

    return (
        <Col id="top__merchants_table" md={12} style={{ marginTop: 30 }}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Flagged Keywords</h5>
                        <button onClick={() => setShowModal(!showModal)} className='btn addbtn'>
                            Add Flagged Keyword
                        </button>
                    </div>
                    <div>
                        <TableList
                            normal={true}
                            headers={headers}
                            showPageSizeOptions={false}
                            totalPage={1}
                            items={flaggedKeywords}
                            className='myprodList'
                            deleteAction={deleteAction}
                        />
                    </div>
                </CardBody>
            </Card>
            <Modal
                size='sm'
                isOpen={showModal}
                style={{ top: 180 }}
                toggle={() => setShowModal(!showModal)}
            >
                <ModalHeader>Add Flagged keyword</ModalHeader>
                <ModalBody>
                    <div
                        className='form__form-group-field right'
                        style={{ width: '100%' }}
                    >
                        <span>
                            <label>keyword</label>
                            <input
                                name='name'
                                type='text'
                                placeholder=''
                                value={keyword}
                                onChange={(event) => setKeyword(event.target.value)}
                            />
                        </span>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='primary'
                        onClick={() => addKeyword()}
                    >
                        Submit
                    </Button>
                    <Button onClick={() => setShowModal(!showModal)} color='danger'>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}

export default FlaggedKeyword;
