import React from 'react'
import { useLightbox } from 'simple-react-lightbox'

const ProductImage = props => {
  const { openLightbox } = useLightbox()
  return (
    <img className="product_img"  onClick={() => openLightbox(props.imageToOpen)} src={props.url}></img>
  )
}

export default ProductImage
