import React from 'react';
import { Card, CardBody } from "reactstrap";
import moment from 'moment';
import TableList from "../../Table";

const headers = [
    { key: "StorefrontOrderNumber", title: "Order Id" },
    { key: "Sku", title: "Sku" },
    { key: "StorefrontName", title: "Market Place" },
    { key: "item_total", title: "Total Items" },
    { key: "return_date", title: "Date of Return" },
    { key: "PaymentReceivedDate", title: "Payment Recieved" },
    { key: "Quanity", title: "Quanity" },
    { key: "ReturnValue", title: "Return Value" },
    { key: "Reason", title: "Reason" },
    { key: "Observstion", title: "Resolution" },
]

const OrdersTable = (props) => {
    const { returns } = props;

    const uniqueOrderLineItems = [];
    for (let orderReturn of returns) {
        const uniqueLineItemSkus = [...new Set(orderReturn.LineItems.map((lineItem) => lineItem.SkuNumber))];
        const uniqueLineItem = orderReturn.LineItems.filter((returnOrder, index) => {
            if (uniqueLineItemSkus.includes(returnOrder.SkuNumber)) {
                uniqueLineItemSkus.splice(index, 1)
                return true;
            };
        });

        for (let order of uniqueLineItem) {
            uniqueOrderLineItems.push({
                StorefrontOrderNumber: orderReturn.StorefrontOrderNumber,
                Sku: order.SkuNumber,
                StorefrontName: orderReturn.StorefrontName,
                item_total: orderReturn.LineItems.length,
                return_date: moment(orderReturn.Returns.Date).format('DD/MM/YYYY HH:MM:SS'),
                PaymentReceivedDate: moment(orderReturn.PaymentReceivedDate).format('DD/MM/YYYY HH:MM:SS'),
                Observstion: orderReturn.Returns.Observstion,
                Quanity: orderReturn.LineItems.length,
                Reason: '',
                ReturnValue: `$${orderReturn.Returns.returnValue || orderReturn.TotalAmount}`,
            });
        }
    };
    return (
        <div id="returns__overview">
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Returns Overview</h5>
                    </div>
                    <TableList
                        normal={true}
                        headers={headers}
                        showPageSizeOptions={false}
                        totalPage={1}
                        items={uniqueOrderLineItems}
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default OrdersTable;
