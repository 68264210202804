import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MarketplaceLinkManagementCard from './components/MarketplaceLinkManagementCard';


import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


const MarketPlaceLinks = (props) => {
    return (
        <div>
            <Container className="dashboard">
                <Row>
                    <Col md={12}>
                        <h3 className="page-title">Marketplace Link Management</h3>
                    </Col>
                </Row>
                <Row>
                    <MarketplaceLinkManagementCard {...props}/>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = ({ curdReducer }) => {
    return curdReducer;
};

export default connect(mapStateToProps, actions)(MarketPlaceLinks);
