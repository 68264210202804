import {
	Card,
	CardBody,
	Col,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import React, { PureComponent } from 'react';
import {
	LineChart,
	Line,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import DatePicker from 'react-date-picker';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';

const StatisticsCard = ({
	fromDate,
	toDate,
	salesData,
	changeFromDate,
	changeToDate,
	changeDate,
	ranges,
	start,
	end,
	local,
	maxDate,
	applyCallback,
}) => (
	<Col md={12} className='dashboard_middle'>
		<Card>
			<span className='chart_head' style={{ padding: 18 }}>
				TOTAL SALES
			</span>
			{/* <FormGroup className="days_selectbox1">
          <Input type="select" name="select" id="exampleSelect">
            <option>Past 30 days</option>
            <option>Past 60 days</option>
            <option>Past 90 days</option>
            <option>Past 120 days</option>
          </Input>
        </FormGroup> */}
			<FormGroup row className='from_to1'>
				<label className='select_date' style={{ paddingLeft: 20 }}>
					Select Date
				</label>
				<DateTimeRangeContainer
					ranges={ranges}
					start={start}
					end={end}
					local={local}
					maxDate={maxDate}
					applyCallback={applyCallback}
				>
					<FormControl
						id='formControlsTextB'
						type='text'
						label='Date'
						value={fromDate + ' - ' + toDate}
						placeholder='Enter Date'
					/>
				</DateTimeRangeContainer>
			</FormGroup>

			<ResponsiveContainer width='100%' height={400}>
				<LineChart
					width={1000}
					height={400}
					data={salesData}
					margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
				>
					<XAxis dataKey='days' interval={0} dy={15} fontSize='11' />
					<YAxis type='number' padding={{ top: 70 }} domain={[ 0, 'auto' ]} />
					<Tooltip />
					<CartesianGrid stroke='#eee' strokeDasharray='5 5' />
					{/* <Line type="monotone" dataKey="ebay" stroke="#404040" />
        <Line type="monotone" dataKey="Amazon" stroke="#8e919f" />
        <Line type="monotone" dataKey="Walmart" stroke="#3b455f" /> */}
					<Line type='monotone' dataKey='Sales' stroke='#000' />
				</LineChart>
			</ResponsiveContainer>
		</Card>
	</Col>
);

export default StatisticsCard;
