import {
  ROLE_LOADING,
  ROLE_ADD_RECORD,
  ROLE_LIST_RECORD,
  ROLE_DELETE_RECORD
} from '../constants/Type';

const INITIAL_STATE = {
  list_role: {},
  error: '',
  loading: false,
};

export default function(state = {}, action) {
  switch (action.type) {
      case ROLE_LOADING:
        return { ...state, loading: action.payload, error: ''};
      case ROLE_ADD_RECORD:
        return { ...state, loading: false, error: '',list_role: state.list_role.concat(action.payload)};
      case ROLE_LIST_RECORD:
          return { ...state, loading: false, error: '',list_role:action.payload};
      case ROLE_DELETE_RECORD:
        let data = state.list_role.filter(item => action.payload !== item.id)
        return { ...state, loading: false, error: '',list_role:data};
    default:
      return state;
  }
}
