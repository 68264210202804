import React from 'react';

const svgLogo = () => {
    return (
        <svg style={{ width: '25%' }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.58 139.28">
            <defs></defs>
            <title>VS-B-R</title>
            <rect fill="#fff" x="-2.35" y="-2.41" width="461" height="145.76" />
            <path fill="#3d445a" d="M93,27.49A42.34,42.34,0,0,0,58.77,45.08L58,46.17H69.51l.2-.2a33.27,33.27,0,1,1,32,55.86l-5.75,10,.75-.06A42.2,42.2,0,0,0,93,27.49Z" />
            <path fill="#3d445a" d="M59.89,66.74H30.23a4.46,4.46,0,0,0,0,8.92H60.3a32.63,32.63,0,0,1-.56-6C59.75,68.71,59.81,67.72,59.89,66.74Z" />
            <path fill="#3d445a" d="M42.12,81.26a4.46,4.46,0,0,0,0,8.92H66.94a33.33,33.33,0,0,1-5.07-8.92Z" />
            <path fill="#3d445a" d="M92,103A33,33,0,0,1,72,95.32H51.84a4.46,4.46,0,1,0,0,8.92h17a42,42,0,0,0,18.93,7.32l.45.06,5-8.63Z" />
            <path fill="#3d445a" d="M112.31,58.21a11.61,11.61,0,0,0-10.15-5.86H43.26a4.46,4.46,0,0,0,0,8.92h58.9a2.81,2.81,0,0,1,2.43,4.21l-11,19.08L82.87,66H72.57L93,101.36l.59,1,18.75-32.48A11.59,11.59,0,0,0,112.31,58.21Z" />
            <path fill="#3d445a" d="M157.32,85.43,144.37,53.86h7.43l8.61,21.38L169,53.86h7.43L163.49,85.43Z" />
            <path fill="#3d445a" d="M171.31,85.43l13-31.57h6.2l13,31.57h-7.43L194,80.13H180.85l-2.13,5.3Zm11.55-10.87h9.19L187.48,63.1Z" />
            <path fill="#3d445a" d="M207.36,85.43V53.86h7V79.09h12.32v6.34Z" />
            <path fill="#3d445a" d="M230.91,85.43V53.86h21.56V60.2H237.89v5.93h13.63v6.2H237.89v6.75h14.9v6.34H230.91Z" />
            <path fill="#3d445a" d="M264,85.43V60.2h-8.56V53.86h24V60.2H271V85.43Z" />
            <path fill="#3d445a" d="M291.32,77.46c1.59,4,4.67,6.34,8.88,6.34,2.54,0,4.35-.63,5.48-1.9a6.41,6.41,0,0,0,1.72-4.39,5.78,5.78,0,0,0-1.72-4.44,22.69,22.69,0,0,0-5.84-3.4,21.79,21.79,0,0,1-6.2-3.67,6.7,6.7,0,0,1-1.95-5.16A7,7,0,0,1,294,55.45a8.35,8.35,0,0,1,5.8-2.13,8.49,8.49,0,0,1,8.06,4.62l-2,1a6.34,6.34,0,0,0-6-3.49c-3.31,0-5.75,2.17-5.75,5.25A4.87,4.87,0,0,0,296,65a16.72,16.72,0,0,0,2.08,1.31l2.9,1.36c3.22,1.45,4.85,2.31,6.48,3.9s2.31,3.35,2.31,5.93c0,5.07-3.58,8.47-9.56,8.47a11.1,11.1,0,0,1-10.92-7.56Z" />
            <path fill="#3d445a" d="M316.36,85.43V53.86h17.21V56H318.71V67.62h14v2.22h-14V83.25h15.17v2.17H316.36Z" />
            <path fill="#3d445a" d="M341,85.43V53.86h2.35V83.25H356.9v2.17H341Z" />
            <path fill="#3d445a" d="M363.19,85.43V53.86h2.36V83.25h13.59v2.17H363.19Z" />
            <path fill="#3d445a" d="M385.43,85.43V53.86h17.21V56H387.78V67.62h14v2.22h-14V83.25H403v2.17H385.43Z" />
            <path fill="#3d445a" d="M410,85.43V53.86h10.1a9,9,0,0,1,6.57,2.63A8.7,8.7,0,0,1,429.36,63a9,9,0,0,1-6.2,8.83l7.65,13.63h-2.58L420.8,72.16a4.51,4.51,0,0,1-.95,0h-7.47V85.44H410ZM412.38,70h7.52A6.93,6.93,0,0,0,425,68,7,7,0,0,0,420,56h-7.65V70Z" />
        </svg>

    )
}

export default svgLogo;
