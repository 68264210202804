import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Table, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import SidebarRolesLink from './SidebarRolesLink';
import SidebarRoles from './SidebarRoles';
import TableList from '../../Table';

class RolestabsCard extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      isLoading:true,
      searchText:'',
      headers:[
               {key:'id',title:'ID'},
               {key:'name',title:'Name'},
               {key:'description',title:'description'}
             ],
      name:'user',
      items:[],
      tempItems:[],
      activeTab: '1',
    };
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {t} = this.props.setOptions
    return (
      <Col xs={12} md={12} lg={12} xl={12} className="rolestabs">
        <Card>
          <CardBody>
            <div className="card__title">
              <span className="card__title_top">{t('USER_ROLES')} </span>
            </div>
            <div className="tabs">
              <div className="tabs__wrap">
                <TableList items={this.props.items} headers={this.state.headers} name={this.state.name}/>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default (RolestabsCard);
