import history from '../../utils/history';
import firebase1 from '../../utils/config';
import * as firebase from 'firebase';

import {
	LOGIN_USER_SUCCESS,
	LOGIN_USER_FAIL,
	LOGIN_USER_SUCCESS_DETAILS,
	LOGOUT,
} from '../constants/Type';

export const loginUser = ({ email, password }) => {
	return (dispatch) => {
		dispatch({ type: LOGIN_USER_FAIL, payload: '' });
		firebase1.app
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((user) => loginUserSuccess(dispatch, user))
			.catch((error) => loginUserFail(dispatch, error));
	};
};

export const registerUser = (data) => {
	return (dispatch) => {
		dispatch({ type: LOGIN_USER_FAIL, payload: '' });
		firebase1.app
			.auth()
			.createUserWithEmailAndPassword(data.email, data.password)
			.then((user) => registerUserSuccess(dispatch, user, data))
			.catch((error) => loginUserFail(dispatch, error));
	};
};
export const addWalkthroughFlag = (data) => {
	return (dispatch) => {
		firebase1.app.auth().onAuthStateChanged((user) => {
			if (user != null) {
				var that = this;
				const db = firebase1.app.firestore();
				db
					.collection('user')
					.where('uid', '==', user.uid)
					.get()
					.then(async function(userSnapshot) {
						let user = {};
						let id;
						userSnapshot.forEach((docs) => {
							user = docs.data();
							if (!user.walkthroughDone) {
								user.walkthroughDone = true;
							}
							id = docs.id;
						});
						await db.collection('user').doc(id).set(user);
					});
			} else {
				console.log('error==');
			}
		});
	};
};
// mark flag to false to see walkthrough again
export const changeWalkthroughFlag = () => {
	return (dispatch) => {
		firebase1.app.auth().onAuthStateChanged((user) => {
			if (user != null) {
				var that = this;
				const db = firebase1.app.firestore();
				db
					.collection('user')
					.where('uid', '==', user.uid)
					.get()
					.then(async function(userSnapshot) {
						let user = {};
						let id;
						userSnapshot.forEach((docs) => {
							user = docs.data();
							if (user.walkthroughDone) {
								user.walkthroughDone = false;
							}
							id = docs.id;
						});
						await db.collection('user').doc(id).set(user);
					});
			} else {
				console.log('error==');
			}
		});
	};
};

export const fbRegisterUser = () => {
	return (dispatch) => {
		dispatch({ type: LOGIN_USER_FAIL, payload: '' });
		var provider = new firebase.auth.FacebookAuthProvider();
		provider.setCustomParameters({
			display: 'popup',
		});
		console.log(provider);
		// firebase1.app.auth().createUserWithEmailAndPassword(data.email, data.password)
		firebase1.app
			.auth()
			.signInWithPopup(provider)
			.then((user) => fbRegisterUserSuccess(dispatch, user))
			.catch((error) => loginUserFail(dispatch, error));
	};
};

export const logout = () => async (dispatch) => {
	await firebase1.app.auth().signOut();
	history.push('/');
	dispatch({
		type: LOGOUT,
		payload: {},
	});
};

// export const forgotUser = (email,navigation) => {
//   return (dispatch) => {
//     console.log('navigation==',email);
//
//     firebase.auth().sendPasswordResetEmail(email)
//     .then(user => {
//       console.log('user==',user);
//       helper.showMsg('A password reset email has been sent to '+email);
//       dispatch(() => navigation.navigate('SignIn'));
//     })
//     .catch((error) => {
//       dispatch({
//         type: LOGIN_USER_FAIL,
//         payload:error.message
//       });
//     })
//   }
// };
//
// export const logoutUser = () => {
//   return (dispatch) => {
//     dispatch({ type: LOGOUT });
//     firebase.auth().signOut();
//   };
// };
//

const loginUserSuccess = (dispatch, user) => {
	const currentUser = firebase1.app.auth().currentUser;
	// let data ={
	//   login_timestamp:new Date()
	// }
	// const db = firebase1.app.firestore();
	// db.collection("users").doc(currentUser.uid).update(data);
	dispatch({
		type: LOGIN_USER_SUCCESS,
		payload: user,
	});
};

const registerUserSuccess = (dispatch, user, data) => {
	console.log('user==', user);
	const db = firebase1.app.firestore();
	data.role = { label: 'client', value: 'c2i7Hj29ZKc8lasrdh8HYymyrJn1' };
	data.uid = user.uid;
	db.collection('user').doc().set(data);
	dispatch({
		type: LOGIN_USER_SUCCESS,
		payload: user,
	});
};

const fbRegisterUserSuccess = (dispatch, user) => {
	if (user != null) {
		console.log('user==', user);
		console.log('user==', user.user.uid);
		console.log('user==', user.additionalUserInfo);

		let date = new Date();
		let createdDate =
			date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
		let data = {
			firstname: user.user.displayName,
			lastname: '',
			email: user.user.email,
			role: { label: 'client', value: 'c2i7Hj29ZKc8lasrdh8HYymyrJn1' },
			company: '',
			password: '',
			uid: user.user.uid,
			login_type: 'facebook',
			created_at: createdDate,
			updated_at: createdDate,
		};
		console.log('prodile===========', data);
		const db = firebase1.app.firestore();
		db
			.collection('user')
			.where('uid', '==', user.user.uid)
			.get()
			.then(function(userSnapshot) {
				console.log('fbRegisterUserSuccess.size==', userSnapshot.size);
				if (userSnapshot.size == 0) {
					db.collection('user').doc().set(data);
				}
				dispatch({
					type: LOGIN_USER_SUCCESS,
					payload: user.user,
				});
				//
			});
	}
};

export const updateBilling = (data) => {
	return (dispatch) => {
		const currentUser = firebase1.app.auth().currentUser;
		if (currentUser) {
			var user = firebase1.app
				.firestore()
				.collection('user')
				.where('uid', '==', currentUser.uid);
			user.get().then((userSnapshot) => {
				if (userSnapshot.size == 0) {
					//this.setState({ isAuthenticating: false,isLoading:false });
					console.log('userSnapshot.size==', userSnapshot.size);
				} else {
					let key = '';
					userSnapshot.forEach(function(doc) {
						key = doc.id;
					});
					firebase1.app
						.firestore()
						.collection('user')
						.doc(key)
						.update({ billing: data });
					localStorage.setItem('isBilling', true);
					history.push('/pages/products');
				}
			});
		}
	};
};

export const getUser = () => {
	return (dispatch) => {
		const currentUser = firebase1.app.auth().currentUser;
		if (currentUser) {
			firebase1.app
				.firestore()
				.collection('user')
				.where('uid', '==', currentUser.uid)
				.onSnapshot(function(snapshot) {
					snapshot.docChanges.forEach(function(change) {
						//  console.log('change.type===',change.type);
						if (change.type === 'modified' || change.type === 'added') {
							//  console.log("Modified user: ", change.doc.data());
							let userData = {
								...change.doc.data(),
								key: change.doc.id,
							};
							dispatch({
								type: LOGIN_USER_SUCCESS_DETAILS,
								payload: userData,
							});
						}
						if (change.type === 'removed') {
							console.log('Removed city: ', change.doc.data());
						}
					});
				});
		}
	};
};

const loginUserFail = (dispatch, error) => {
	dispatch({
		type: LOGIN_USER_FAIL,
		payload: error.message,
	});
};

/*
Google
ios Client ID: 363240785520-uru4rm8es70obe1056im7q4vds9ipk7k.apps.googleusercontent.com
android Client ID: 363240785520-gbsmb4i93qmo5ictu7ste83199tkpven.apps.googleusercontent.com
*/
