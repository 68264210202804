const initialState = {
  access_token: null,
  app_id: null,
  expires_in: null,
  nonce: null,
  scope: null,
  token_type: null,
  error: false,
  loading: false,
  status: null,
  message: null,
  data: [],
  CurrentPage: null,
  TotalNumberOfPages: null,
  TotalNumberOfEntries: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_PAYPAL_TOKEN":
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case "GET_PAYPAL_TOKEN_SUCCESS":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "GET_PAYPAL_TOKEN_FAIL":
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    case "GET_TRANSACTIONS":
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case "GET_TRANSACTIONS_SUCCESS":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "GET_TRANSACTIONS_FAIL":
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    case "SAVE_PAYPAL_ORDER":
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case "SAVE_PAYPAL_ORDER_SUCCESS":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "SAVE_PAYPAL_ORDER_FAIL":
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    case "SAVE_PAYPAL_SUBSCRIPTION":
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case "SAVE_PAYPAL_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "SAVE_PAYPAL_SUBSCRIPTION_FAIL":
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
