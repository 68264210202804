import React, { useState } from 'react';
import ReactTooltip from "react-tooltip";
import { Prompt } from 'react-router';

/*
    Editatable cell UI for each bulk product field corresponding
    to a respective bulk product cell
 */
const BulkProductTableCell = (props) => {
    const { value, rowValidation, rowIndex, updateFunction, field } = props;
    const [cellFocused, setCellFocused] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const cellProps = {
        className: `row${ rowValidation.hasError && ' error-cell'} ${(!rowValidation.hasError && cellFocused) && ' valid-cell'}`,
    }
    const [cellValue, setCellValue] = useState(value);
    if (rowValidation.hasError && !cellFocused) {
        cellProps['data-tip'] = rowValidation.hasError && `Error: ${rowValidation.errorMessage}`;
    };

    const updateCellValue = (event) => {
        setCellValue(event.target.value);
        setIsDirty(true);
        updateFunction(field, event.target.value, rowIndex);
    }

    return (
        <div>
            <div {...cellProps}>
                {/* promp a user before they reload the page when they have usaved changes */}
                {/* <Prompt
                    when={isDirty}
                    message='You have unsaved changes, are you sure you want to leave?'
                /> */}
                <input
                    type="text"
                    value={cellValue}
                    onChange={updateCellValue}
                    onFocus={() => setCellFocused(true)}
                    onBlur={() => setCellFocused(false)}
                />
            </div>
            {
               cellFocused && <ReactTooltip />
            }
        </div>
    );
}

export default BulkProductTableCell;
