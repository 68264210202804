import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import helper from '../../../utils/helper';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import {
    Card,
    CardBody,
    Col,
    Button,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter, Modal
} from 'reactstrap';
import api from '../../../utils/api';

import CSVReader from 'react-csv-reader';

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header =>
        header
            .toLowerCase()
            .replace(/\W/g, '_')
}


class UPCManagementCard extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            searchText: '',
            tableLoading: true,
            totalPage: 20,
            headers: [
                {key: 'Number', title: 'Number'},
                {key: 'Sku', title: 'SKU'},
                {key: 'Company', title: 'Company'},
                {key: 'edit-upc', title: 'Edit'},
                {key: 'delete-upc', title: 'Delete'}
            ],
            name: 'upcmanagement',
            items: [],
            tempItems: [],
            confirmationModal: false,
            csvData: [],
            importing: false,
        };
        this.deleteAction = this.deleteAction.bind(this);
        this.getData = this.getData.bind(this);
        this.handleForce = this.handleForce.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.bullkUpc = this.bullkUpc.bind(this);
        this.handleDarkSideForce = this.handleDarkSideForce.bind(this);

        this.tempPage = 0
        this.stripeCustomer = [];
        this.pageArray = [];
    }

    getData(page, pageSize) {
        let apiPath = '';
        apiPath = 'upc/list';

        let items = this.state.items;
        let startAfter = '';
        if (0 < items.length) {
            if (this.tempPage < page) {
                startAfter = items[items.length - 1].firstname;

            } else if (page == 1) {
                startAfter = ''
            } else {
                if (this.pageArray[page]) {
                    startAfter = this.pageArray[page];
                }
            }
        }


        let reqData = {
            "Pagination": {
                "ResultsPerPage": pageSize,
                "PageNumber": page,
                "StartAfter": startAfter,
                "Search": this.state.searchText
            }
        }
        this.setState({tableLoading: true})
        //console.log(reqData);
        api.postApi(apiPath, reqData).then((data) => {
            if (data.status == 'Success') {
                let items = data.data;
                if (0 < items.length) {
                    this.pageArray[page + 1] = items[items.length - 1].firstname
                    if (this.tempPage < page) {
                        this.setState({totalPage: (page + 1)})
                    }
                }
                items = items.sort(this.compare);

                this.setState({isLoading: false, items: items, tempItems: items, totalPage: data.pagination.TotalNumberOfPages})
            } else if (data.messages.length > 0) {
                helper.errorMessage(data.messages[0]);
            }
            this.tempPage = page
            this.setState({isLoading: false, tableLoading: false});
        })
    }


    actionSearch() {

        if (this.state.searchText.length > 0) {
            this.pageArray = [];
            this.getData(1, 25)
        }
    }

    headerFilter(filterText, item) {

        let isFilter = false;
        for (let data in this.state.headers) {
            if (item[this.state.headers[data].key] && item[this.state.headers[data].key].label && String(item[this.state.headers[data].key].label).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
                isFilter = true;
            } else if (String(item[this.state.headers[data].key]).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
                isFilter = true;
            }
        }
        return isFilter;
    }

    searchKey(text) {
        this.setState({
            searchText: text
        }, () => {
            if (text.length == 0) {
                this.pageArray = [];
                this.getData(1, 25)
            }
        })

    }

    compare(a, b) {
        if (Number(a.date) < Number(b.date)) {
            return 1;
        }
        if (Number(a.date) > Number(b.date)) {
            return -1;
        }
        return 0;
    }

    handleForce = data => {
        const fileInput = document.getElementById('upc');
        fileInput.value = null;
        this.setState({
            csvData: data
        })
        this.toggleConfirm();
    }

    async deleteAction(id) {
        this.setState({tableLoading: true})
        try {
            const data = await api.postApi(`upc/delete/${id}`);
            if (data.status == 'Success') {
                this.getData();
                helper.successMessage('Upc Deleted');
            } else {
                helper.errorMessage(data.messages[0]);
                this.setState({tableLoading: false})

            }

        } catch (e) {
            this.setState({tableLoading: false})

        }
    }

    addSku() {

        this.companyName = []
        this.userId = [];
        let csvData = this.state.csvData;
        for (let data in csvData[0]) {
            this.userId.push(csvData[0][data]);
            this.companyName.push(data);
        }
        this.count = 0
        this.sku = [];
        for (let data in csvData) {

            if (csvData[data]['parent_188264092'] && data < 5) {
                this.sku.push(csvData[data]['parent_188264092'])
            }
        }

    }

    handleDarkSideForce = error => {
        console.log('error==', error);
    }

    toggleConfirm() {
        this.setState({confirmationModal: !this.state.confirmationModal})
    }

    bullkUpc = async () => {
        const find = this.state.csvData.find(c => !c.number);
        if(find) {
            helper.errorMessage('One of the upc numbers is empty, check the csv file');
        } else {
            try {
                this.setState({ importing: true })
                const data = await api.postApi(`upc/bulk`, { upcs: this.state.csvData});
                if (data.status == 'Success') {
                    this.getData(1, 25)
                    this.toggleConfirm();
                    helper.successMessage('Import completed');
                    this.setState({ importing: false })
                } else {
                    helper.errorMessage(data.messages[0]);
                    this.setState({ confirmationModal: false, importing: false})
                }

            } catch (e) {
                this.setState({ importing: false})

            }
        }
    }


    render() {
        return (

            <Col className="container-div" md={12}>
                {this.state.isLoading == true &&
                <div className="load" style={{backgroundColor: 'transparent', width: '85%', height: '50%'}}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                        </svg>
                    </div>
                </div>}


                <Col md={12}>
                    <div className="search" style={{marginLeft: 15}}>
                        <Input type="text" name="search" placeholder="Search for upc" value={this.state.searchText}
                               onChange={(event) => {
                                   this.searchKey(event.target.value)
                               }}/>
                        <Button onClick={() => {
                            this.actionSearch()
                        }} style={{padding: 0}}>Search</Button>
                    </div>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">UPC management
                                    <Link to="/pages/addupcmanagement" className="addbuttons btn addbtn">
                                        + ADD

                                    </Link>
                                    <Button className="btn addbtn product_orange">
                                        Import UPCs
                                        <CSVReader
                                            accept='.csv'
                                            cssClass="csv-reader-input"
                                            onFileLoaded={this.handleForce}
                                            onError={this.handleDarkSideForce}
                                            parserOptions={papaparseOptions}
                                            inputId="upc"
                                            inputStyle={{color: "red"}}
                                        />
                                    </Button>
                                </h5>

                            </div>


                            <TableList normal={false}
                                       tableLoading={this.state.tableLoading}
                                       totalPage={this.state.totalPage}
                                       getData={this.getData}
                                       items={this.state.items}
                                       deleteAction={this.deleteAction}
                                       headers={this.state.headers}
                                       name={this.state.name}
                                       filename="UPC-Management"
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Modal
                    isOpen={this.state.confirmationModal}
                    toggle={this.toggleConfirm}
                    className={'modal-sm ' + this.props.className}
                    style={{marginTop: 150}}
                >
                    <ModalHeader toggle={this.toggleConfirm}>Alert</ModalHeader>
                    <ModalBody>{this.state.importing ? 'Importing...' : 'Do you want to import?'}</ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={this.state.importing}
                            color='primary'
                            onClick={() => {
                                this.bullkUpc();
                            }}
                        >
                            Ok
                        </Button>{' '}
                        <Button color='secondary' onClick={this.toggleConfirm} disabled={this.state.importing}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Col>
        )
    }

}

const mapStateToProps = ({curdReducer, authReducer}) => {
};

export default connect(mapStateToProps, actions)(UPCManagementCard);
