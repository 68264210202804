import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Button,
	Input,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import api from '../../../utils/api';
import TableList from '../../Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import firebase1 from '../../../utils/config';
import PayPalBtn from '../../../components/PayPalBtn';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '50%',
		height: '390px',
		textAlign: 'center',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

class Morereviews extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			searchText: '',
			name: 'companies',
			items: [],
			tempItems: [],
			purchaseSku: [],
			selectDiscount: null,
			role: '',
			modalIsOpen: false,
			totalPrice: 0,
			pageSize: 25,
			searchSkuText: '',
			professionalBrand: false,
			tabIndex: 0,
			listingOptimizationPrice: 99,
			reviewPrice: 60,
			enhancedBrandPrice: 200,
			purchaseSkuHeaders: [
				{ key: 'CreatedAt', title: 'Purchased Date' },
				{ key: 'Sku', title: 'Sku' },
			],
			headers: [
				{ key: 'firstname', title: 'Name' },
				{ key: 'email', title: 'Email' },
				{ key: 'discount', title: 'Discount Level' },
			],
			skuHeaders: [
				{ key: 'Sku', title: 'Sku' },
				{ key: 'Name', title: 'Product Title' },
				{ key: 'add_cart', title: 'Action' },
			],
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.getProductData = this.getProductData.bind(this);
		this.addToCart = this.addToCart.bind(this);
		this.removeCart = this.removeCart.bind(this);
		this.getPurchaseSku = this.getPurchaseSku.bind(this);
		this.paypalOnError = this.paypalOnError.bind(this);
		this.paypalonSuccess = this.paypalonSuccess.bind(this);
		this.paypalonCreateOrder = this.paypalonCreateOrder.bind(this);
	}

	componentDidMount() {
		//  console.log('user=====',this.props.userdetails);
		const { user, userdetails } = this.state;
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
		}
		//console.log('this.props.userdetails', this.props.userdetails)
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	updateUser(user) {
		//console.log('user==2323232332323=', user);
		let stripeCustomerId = '';
		let professionalBrand = false;

		if (user.billing && user.billing.stripe_customer_id) {
			stripeCustomerId = user.billing.stripe_customer_id;
		}
		if (user.professional_brand) {
			professionalBrand = user.professional_brand.active;
		}
		this.setState(
			{
				isLoading: false,
				key: user.key,
				firstname: user.firstname,
				company: user.company,
				email: user.email,
				role: user.role.label,
				Uid: user.uid,
				stripeCustomerId: stripeCustomerId,
				professionalBrand: professionalBrand,
				selectDiscount: user.discount ? user.discount : null,
			},
			() => {
				if (user.role.label == 'admin') {
					this.getUser();
					this.getPurchaseSku(1, this.state.pageSize);
				}
			},
		);
	}

	async getUser() {
		let apiPath = '';

		apiPath = 'user/marketuserlist';
		let reqData = {
			Pagination: {
				ResultsPerPage: 25,
				PageNumber: 1,
			},
		};
		this.setState({ isLoading: true });
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				for (let item in items) {
					if (items[item].discount) {
						items[item].discount = Number(items[item].discount.value);
						if (items[item].discount == -1) {
							items[item].discount = "Don't Participate in Discounts";
						}
					} else {
						items[item].discount = 0;
					}
				}
				this.setState({ isLoading: false, items: items, tempItems: items });
			}
		});
	}

	async getPurchaseSku(page, pageSize) {
		let apiPath = '';
		apiPath = 'market/purchaseskulist';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};

		if (this.state.searchSkuText.length > 0) {
			reqData.sku = this.state.searchSkuText;
		}
		if (this.state.role != 'admin') {
			reqData.Uid = this.state.Uid;
		}

		this.setState({ tableLoading: true, pageSize: pageSize });
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				//  console.log('getPurchaseSkuitems===',items);
				this.setState({
					tableLoading: false,
					purchaseSkuitems: items,
					purchaseSkutempItems: items,
				});
			}
		});
	}

	async updateDiscount() {
		const { selectDiscount, firstname, company, email } = this.state;
		if (selectDiscount == null) {
			helper.errorMessage('please select discount');
		} else {
			this.setState({ isLoading: true });
			await this.props.updateRecord(
				'user',
				{ discount: selectDiscount },
				this.state.key,
				false,
			);
			let apiPath = '';
			apiPath = 'mail/onDiscountDetails';
			let reqData = {
				firstname: firstname,
				company: company,
				email: email,
				discount: selectDiscount.label,
			};

			api.postApi(apiPath, reqData).then((data) => {
				console.log(data);
				if (data.status == 'Success') {
				}
				this.setState({ isLoading: false });
			});
		}
	}

	handleDiscountChange = (selectDiscount) => {
		this.setState({ selectDiscount });
	};

	viewData = () => {
		window.open('http://www.valetseller.com/advertising-packages/');
	};

	actionSearch() {
		if (this.state.searchText.length > 0) {
			let filterItems = [];
			let items = this.state.tempItems;
			items.map((item, index) => {
				if (this.headerFilter(this.state.searchText, item)) {
					filterItems.push(item);
				}
			});
			this.setState({ items: filterItems });
		}
	}

	searchSkuKey(text) {
		this.setState(
			{
				searchSkuText: text,
			},
			() => {
				if (text.length == 0) {
					this.getPurchaseSku(1, this.state.pageSize);
				}
			},
		);
	}

	actionSkuSearch() {
		if (this.state.searchSkuText.length > 0) {
			this.getPurchaseSku(1, this.state.pageSize);
		}
	}

	headerFilter(filterText, item) {
		let isFilter = false;
		for (let data in this.state.headers) {
			if (
				item[this.state.headers[data].key] &&
				item[this.state.headers[data].key].label &&
				String(item[this.state.headers[data].key].label)
					.toLowerCase()
					.indexOf(filterText.toLowerCase()) !== -1
			) {
				isFilter = true;
			} else if (
				String(item[this.state.headers[data].key])
					.toLowerCase()
					.indexOf(filterText.toLowerCase()) !== -1
			) {
				isFilter = true;
			}
		}
		return isFilter;
	}

	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				if (text.length == 0) {
					this.setState({ items: this.state.tempItems });
				}
			},
		);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	getProductData(page, pageSize) {
		console.log('page===', page);
		this.setState({ tableLoading: true, pageSize: pageSize });
		this.productCount = 0;
		let apiPath = '';
		let purchaseSku = this.state.purchaseSku;
		console.log('purchaseSku===', purchaseSku);
		apiPath = 'inventory/getProducts';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};
		if (this.state.searchText.length > 0) {
			reqData.sku = this.state.searchText;
		}
		if (this.state.role != 'admin') {
			reqData.Uid = this.state.Uid;
		}
		api.postApi(apiPath, reqData).then(
			(data) => {
				if (data.status == 'Success') {
					let items = data.data;
					for (let item in items) {
						if (purchaseSku.indexOf(items[item].Sku) != -1) {
							items[item].iscart = true;
						} else {
							items[item].iscart = false;
						}
						items[item].price = 99;
					}
					this.setState({
						tableLoading: false,
						items: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					});
				} else {
					this.setState({ tableLoading: false });
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	addToCart(id) {
		let items = this.state.items;
		let index = helper.getItemIndex(this.state.items, 'Id', id);
		items[index].iscart = true;
		let purchaseSku = this.state.purchaseSku;
		purchaseSku.push(items[index].Sku);
		this.setState({ items: [ ...items ], purchaseSku: purchaseSku }, () => {
			this.calculateAllPrice();
		});
	}

	removeCart(id) {
		let items = this.state.items;
		let index = helper.getItemIndex(this.state.items, 'Id', id);
		let purchaseSku = this.state.purchaseSku;
		items[index].iscart = false;
		purchaseSku.splice(purchaseSku.indexOf(items[index].Sku), 1);
		this.setState({ items: [ ...items ], purchaseSku: purchaseSku }, () => {
			this.calculateAllPrice();
		});
	}

	calculateAllPrice() {
		let tabIndex = this.state.tabIndex;
		let price = 0;
		console.log('=tabIndex===', tabIndex);

		price = this.state.reviewPrice;

		let totalPrice = Number(this.state.purchaseSku.length) * price;
		this.setState({ totalPrice: totalPrice });
	}

	actionPurchase() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			console.log('totalPrice==', totalPrice);
			let req = {
				amount: totalPrice * 100,
				currency: 'usd',
				description: 'Valetseller SkU Purchase',
				customer: stripeCustomerId,
			};
			api.stripePost('charges', req).then((cutomerData) => {
				if (cutomerData.error) {
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					//helper.successMessage('sucessfully changed');
					this.setState({ isLoading: false });
					this.addMarketSku();
					this.openModal();
				}
			});
		}
	}

	closeMarketingModal() {
		let items = this.state.items;
		for (let data in items) {
			items[data].iscart = false;
		}

		this.setState(
			{ modalIsOpen: false, purchaseSku: [], items: [ ...items ] },
			() => {
				this.calculateAllPrice();
			},
		);
	}

	addMarketSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.listingOptimizationPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addmarketsku', reqData).then((data) => {
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	actionEnhancedBrandPurchase() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			console.log('totalPrice==', totalPrice);
			let req = {
				amount: totalPrice * 100,
				currency: 'usd',
				description: 'Enhanced Brand Content SkU Purchase',
				customer: stripeCustomerId,
			};
			api.stripePost('charges', req).then((cutomerData) => {
				if (cutomerData.error) {
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					//helper.successMessage('sucessfully changed');
					this.setState({ isLoading: false });
					this.addEnhancedBrandSku();
					this.openModal();
				}
			});
		}
	}

	addEnhancedBrandSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.enhancedBrandPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addenhancedsku', reqData).then((data) => {
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	actionReviewSku() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.addReviewSku();
		}
	}

	addReviewSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.reviewPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
			servicename: 'More Reviews',
		};
		console.log('reqData==', reqData);
		api.postApi('market/addreviewsku', reqData).then((data) => {
			this.setState({ isLoading: false });
			this.openModal();
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	async brandPurchase() {
		let stripeCustomerId = this.state.stripeCustomerId;
		this.setState({ isLoading: true });
		let req = {
			amount: 79900,
			currency: 'usd',
			description: 'Professional Storefront Design',
			customer: stripeCustomerId,
		};
		api.stripePost('charges', req).then((cutomerData) => {
			if (cutomerData.error) {
				helper.errorMessage(cutomerData.error.message);
				this.setState({ isLoading: false });
			} else {
				this.updateBrandPurchase();
			}
		});
	}

	async updateBrandPurchase() {
		const db = firebase1.app.firestore();
		await db
			.collection('user')
			.doc(this.state.key)
			.update({ professional_brand: { created_at: new Date(), active: true } });
		this.setState({ isLoading: false });
		this.openModal();
		let reqData = {
			email: this.state.email,
			firstname: this.state.firstname,
			price: 799,
			company: this.state.company,
		};
		api.postApi('mail/onProfessionalDetails', reqData).then((data) => {
			console.log(data);
		});
	}

	paypalonCreateOrder(data, actions) {
		const { totalPrice } = this.state;
		if (0 < totalPrice) {
			return actions.order.create({
				purchase_units: [
					{
						amount: {
							currency_code: 'USD',
							value: totalPrice,
						},
					},
				],
				application_context: {
					shipping_preference: 'NO_SHIPPING',
				},
			});
		} else {
			helper.errorMessage('please add one sku');
			return false;
		}
	}

	paypalonSuccess(data) {
		console.log('Payapl approved', data);
		window.gtag('event', 'get-more-reviews', {
			'type': 'paid_subscription',
			'value': this.state.totalPrice,
			'utm_source': localStorage.getItem('utm_source'),
			'utm_campaign': localStorage.getItem('utm_campaign')
		})
		this.addReviewSku();
		const Uid = this.state.Uid || (this.props.userdetails && this.props.userdetails.uid);
		this.props.savePaypalOrder({ Uid, orderID: data.orderID || data.id, paymentType: 'GET_MORE_REVIEWS', userDetails: this.props.userdetails})
	}

	paypalOnError(err) {
		console.log('Error', err);
	}

	render() {
		const { t } = this.props.setOptions;
		const {
			selectDiscount,
			isLoading,
			role,
			totalPrice,
			professionalBrand,
		} = this.state;

		if (isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<Row>
					<Col md={12}>
						<Link to='/pages/marketing'>
							<button className='back_products'>Back</button>
						</Link>
					</Col>
					<Row className='ads-market-main'>
						<Col className='ads-market-div'>
							<Col md={6}>
								<h2>Get More Reviews</h2>
								<p>
									Reviews are an essential part of increasing your product
									ranking and gaining traction on marketplaces. Amazon offers
									the Early Reviewer Program which incentivizes buyers to leave
									reviews for your products.
								</p>
								<p>
									${helper.getNumberFormat(60)}/SKU (you will only be charged if
									a review is left)
								</p>
								{/* <button onClick={() => { this.actionReviewSku() }}>Enroll</button> */}
							</Col>
							<Col md={6}>
								<img src='../img/market5.png' />
							</Col>
						</Col>
					</Row>
					<Row className='' style={{ width: '100%' }}>
						<Col md={12}>
							<div className='list_opti_table'>
								<div className='total_list'>
									<span>Total Price </span>:{' '}
									<span>${helper.getNumberFormat(totalPrice)}</span>
								</div>
								<div
									className='ads-market-div'
									style={{ margin: 0, width: '100%' }}
								>
									<div
										className='paypalbtn'
										style={{ float: 'right', margin: 0 }}
									>
										<PayPalBtn
											amount={totalPrice}
											onCreateOrder={this.paypalonCreateOrder}
											onSuccess={this.paypalonSuccess}
											catchError={this.paypalOnError}
											onError={this.paypalOnError}
											onCancel={this.paypalOnError}
										/>
										{/* <button style={{margin: 0}} onClick={() => { this.actionPurchase() }} >Purchase</button> */}
									</div>
								</div>
								<div className='purchase_total_list'>
									<TableList
										normal={false}
										tableLoading={this.state.tableLoading}
										totalPage={this.state.totalPage}
										getData={this.getProductData}
										addToCart={this.addToCart}
										removeCart={this.removeCart}
										items={this.state.items}
										headers={this.state.skuHeaders}
										className='myprodList'
										filename="More-Reviews"
									/>
								</div>
							</div>
						</Col>
					</Row>
					<Modal
						isOpen={this.state.modalIsOpen}
						onAfterOpen={this.afterOpenModal}
						onRequestClose={this.closeModal}
						style={customStyles}
						contentLabel='Example Modal'
					>
						<span className='closepurchase' onClick={this.closeModal}>
							X
						</span>
						<img
							src='../img/favlogo.png'
							style={{ width: 80, marginBottom: 20 }}
						/>
						<h2>Thank you for your purchase!</h2>
						<p className='purchase_p'>
							Thank you for your purchase! Your listing information has been
							sent to our copywriting team. Please allow 5-7 days for your
							listing optimizations to be delivered
						</p>
						<p className='purchase_p'>
							If you have any questions, please email us at
							support@valetseller.com
						</p>

						<button
							className='btn addbtn return_app'
							onClick={() => {
								this.closeMarketingModal();
							}}
						>
							Return to App
						</button>
					</Modal>
				</Row>
			);
		}
	}
}
const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(Morereviews);
