
import helper from "../../utils/helper";
import history from '../../utils/history';
import firebase1 from '../../utils/config';

import {
  ROLE_ADD_RECORD,
  ROLE_LIST_RECORD,
  ROLE_DELETE_RECORD
} from '../constants/Type';


const collection = 'role';

export const listRoleRecord = () => {
  return (dispatch) => {
    
      const db = firebase1.app.firestore();
      let listRecord =[];
      db.collection(collection).get().then(function(querySnapshot) {
          console.log("Document successfully written!");
          querySnapshot.forEach(function(doc) {
            var currentDocument=doc.data();
            currentDocument.id =doc.id;
            listRecord.push(currentDocument);
          })
          console.log('listRecord=',listRecord);
          dispatch({
            type: ROLE_LIST_RECORD,
            payload:listRecord
          });
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      })
  }
}



export const addRoleRecord =  (data) => async dispatch => {

    const db = firebase1.app.firestore();
    let date = new Date();
    let createdDate = date.getDate()+"/"+(date.getMonth()+1)+"/"+(date.getFullYear());
    data.created_at = createdDate;

    db.collection(collection).add(data)
    .then(function(docRef) {
        let list ={
          name:data.name,
          description:data.description,
          id:docRef.id
        }
        console.log('list11===',list);
        dispatch({
          type: ROLE_ADD_RECORD,
          payload:list
        });
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    })

  };

// export const updateRoleRecord = (collection,data,id) => async dispatch => {
//     console.log('data==',data);
//     const db = firebase1.app.firestore();
//     if(data.file!=''){
//       const photoURL =  await uploadImage(data.file,collection);
//       data.profile_photo = photoURL;
//     }
//     data.file ='';
//     db.collection(collection).doc(id).update(data)
//     .then(function() {
//         console.log("Document successfully written!");
//         history.push("/pages/"+collection);
//     })
//     .catch(function(error) {
//         console.error("Error writing document: ", error);
//     })
//
// };
//
//
//
export const deleteRoleRecord = (id) => {
  return (dispatch) => {
    const db = firebase1.app.firestore();
    db.collection(collection).doc(id).delete();
    dispatch({
      type: ROLE_DELETE_RECORD,
      payload:id
    });
  };
};
