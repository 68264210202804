import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Label, Input } from 'reactstrap';

class ModalCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false,
      name:'',
      description:''
    };

  }
  render() {
    const {t} = this.props.setOptions
    return (
        <Col md={12} className="newroles">
              <div className="newroles_inner">
                <h3>{t('CREATE_NEW_ROLE')} <span onClick={() => { this.props.closeRole() }}>X</span></h3>
                <Label>{t('NAME')}</Label>
                <Input placeholder="" value={this.state.name} onChange={(event) => {this.setState({ name: event.target.value })}}/>
                <p>{t('ROLE_NAME')}</p>

                <Label>{t('DESCRIPTION')}</Label>
                <Input placeholder="" value={this.state.description} onChange={(event) => {this.setState({ description: event.target.value })}}/>
                <p>{t('DESCRIPTION_NAME')}</p>

                <div className="formbuttons">
                  <button className="cancelbtn" onClick={() => { this.props.closeRole() }}>{t('CANCEL')}</button>
                  <button  className="nextbutton" onClick={() => { this.props.createRole(this.state) }}>{t('CREATE')}</button>
                </div>
              </div>
        </Col>
      )
    }
  }

export default ModalCard;
