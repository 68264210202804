import React, { Component } from 'react';
import { Collapse, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class SidebarRoles extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

  static defaultProps = {
    icon: '',
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  toggle1 = () => {
    this.setState({ collapse1: !this.state.collapse1 });
  };

  toggle2 = () => {
    this.setState({ collapse2: !this.state.collapse2 });
  };

  toggle3 = () => {
    this.setState({ collapse3: !this.state.collapse3 });
  };

  toggle4 = () => {
    this.setState({ collapse4: !this.state.collapse4 });
  };

  render() {
    const {
      title, icon, isNew, children,
    } = this.props;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': this.state.collapse,
    });

    return (
      <div className={categoryClass}>
        <div>
          <button className="sidebar__link sidebar__category" onClick={this.toggle}>
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Organisation Units
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
          <Collapse isOpen={this.state.collapse} className="sidebar__submenu-wrap">
            <ul className="sidebar__submenu">
              <div>
              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  Organisation Units
                </p>
              </li>

              </div>
            </ul>
          </Collapse>
        </div>

        <div>
          <button className="sidebar__link sidebar__category" onClick={this.toggle1}>
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Users
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
          <Collapse isOpen={this.state.collapse1} className="sidebar__submenu-wrap">
            <ul className="sidebar__submenu">
              <div>
              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  Users
                </p>
              </li>
              </div>
            </ul>
          </Collapse>
        </div>


        <div>
          <button className="sidebar__link sidebar__category" onClick={this.toggle2}>
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Security
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
          <Collapse isOpen={this.state.collapse2} className="sidebar__submenu-wrap">
            <ul className="sidebar__submenu">
              <div>
              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  User Security Management
                </p>
              </li>

              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  Security Settings
                </p>
              </li>
              </div>
            </ul>
          </Collapse>
        </div>


        <div>
          <button className="sidebar__link sidebar__category" >
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Groups
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
        </div>


        <div>
          <button className="sidebar__link sidebar__category">
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Domains
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
        </div>

        <div>
          <button className="sidebar__link sidebar__category" >
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Services
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
        </div>


        <div>
          <button className="sidebar__link sidebar__category">
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Data Management
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
        </div>


        <div>
          <button className="sidebar__link sidebar__category"onClick={this.toggle4}>
            <span className="sidebar__category-icon lnr lnr-chevron-right" />
            <Input type="checkbox"/>
            <p className="sidebar__link-title"> Mobile Device Management
              {isNew && <span className="sidebar__category-new" />}
            </p>
          </button>
          <Collapse isOpen={this.state.collapse4} className="sidebar__submenu-wrap">
            <ul className="sidebar__submenu">
              <div>
              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  User Management
                </p>
              </li>

              <li className="sidebar__link">
                <Input type="checkbox"/>
                <p className="sidebar__link-title">
                  Chatbot
                </p>
              </li>
              </div>
            </ul>
          </Collapse>
        </div>

      </div>


    );
  }
}
