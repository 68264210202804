import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import TableList from "../../Table";

import { Card, CardBody, Col } from "reactstrap";

class TopMerchantCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchText: "",
      headers: [
        { key: "Company", title: "Brand" },
        { key: "TotalAmount", title: "Total Revenue" },
      ],
      name: "Inventory",
      items: [],
      tempItems: [],
      totalPage: 0,
      tableLoading: true,
    };
  }

  render() {
    return (
      <Col id="top__merchants_table" className="inventory_status" md={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Top Merchants</h5>
            </div>
            <div className="inventory_table">
              <TableList
                normal={true}
                headers={this.state.headers}
                showPageSizeOptions={false}
                name={this.state.name}
                totalPage={1}
                items={this.props.items}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = ({ curdReducer }) => {
  const { list_companies } = curdReducer;
  return { list_companies };
};

export default connect(mapStateToProps, actions)(TopMerchantCard);
