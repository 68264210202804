import React, { PureComponent } from 'react';
import api from '../../../utils/api';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';
import Switch from 'react-switch';
import Modal from 'react-modal';

import TableList from '../../Table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
	Card,
	CardBody,
	Col,
	Badge,
	Button,
	Input,
	FormGroup,
	Label,
} from 'reactstrap';
import Select from 'react-select';
import CSVReader from 'react-csv-reader';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';

const cartList = helper.getSellList();
const shipList = helper.getShipList();
const paidList = helper.getPaidList();
const timeList = helper.getTimeList();
const priorityColorList = helper.getColorList();

const paypalPlanList = [
	{
	  id: "P-8SU46672P5752974EL6V7DYI",
	  sku: 9,
	  marketplace: 3,
	  name: "$99 / Month",
	  price: "$99.00",
	  isYear: false,
	}, //plan1 month
	{
	  id: "P-8CG47749TA642021FL6V7EGA",
	  sku: 49,
	  marketplace: 5,
	  name: "$149 / Month",
	  price: "$149.00",
	  isYear: false,
	}, //plan2 month
	{
	  id: "P-28W706339P938212YL6V7EUI",
	  sku: 99,
	  marketplace: 10,
	  name: "$199 / Month",
	  price: "$199.00",
	  isYear: false,
	},
	{
	  id: "P-8LN221246F8497041MBDU6XY",
	  sku: 9,
	  marketplace: 3,
	  name: "$85 / Month*",
	  price: "$510.00",
	  isYear: true,
	}, //plan1 half year
	{
	  id: "P-55N781364R8691314MBDU7GQ",
	  sku: 49,
	  marketplace: 5,
	  name: "$127 / Month*",
	  price: "$762.00",
	  isYear: true,
	}, //plan2 half year
	{
	  id: "P-77X47275R2592790FMBDU7UQ",
	  sku: 99,
	  marketplace: 10,
	  name: "$170 / Month*",
	  price: "$1,020.00",
	  isYear: true,
	}, //plan3 half year
	{
	  id: "P-4N066220RU154484NMEFJ4KA",
	  sku: 5,
	  marketplace: 1,
	  name: "$39.00 / Month",
	  price: "$39.00",
	  isYear: false,
	  commission: "10%",
	}, //plan1 month
	{
	  id: "P-3UD50413W8710083RMD6ZLPI",
	  sku: 10,
	  marketplace: 3,
	  name: "$99.00 / Month",
	  price: "$99.00",
	  isYear: false,
	  commission: "5%",
	}, //plan2 month
	{
	  id: "P-73V28932PS628292FMD6ZMCI",
	  sku: 100,
	  marketplace: 10,
	  name: "$199 / Month",
	  price: "$199.00",
	  isYear: false,
	  commission: "5%",
	},
	{
	  id: "P-77M806847P0378016MD6ZM2I",
	  sku: 5,
	  marketplace: 1,
	  name: "$34.00 / month",
	  price: "$290.00",
	  isYear: true,
	  commission: "10%",
	}, //plan1 half year
	{
	  id: "P-8W652289VG3957527MD6ZNOA",
	  sku: 10,
	  marketplace: 3,
	  name: "$79 / Month*",
	  price: "$990.00",
	  isYear: true,
	  commission: "5%",
	}, //plan2 half year
	{
	  id: "P-4MG839423G922882KMD6ZN7Q",
	  sku: 100,
	  marketplace: 10,
	  name: "$159 / Month*",
	  price: "$1,990.00",
	  isYear: true,
	  commission: "5%",
	}, //plan3 half year
  ];

const alertcustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: '160px',
		width: '300px',
	},
};
const deleteCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: '160px',
		width: '400px',
	},
};

const subscriptionList = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];

const feesCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: '390px',
		width: '400px',
	},
};

const feessCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: '410px',
		width: '80%',
	},
};

const papaparseOptions = {
	header: true,
	dynamicTyping: true,
	skipEmptyLines: true,
	transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
};
const validEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

class AddusermanagementCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			paypal_billing: {},
			isLoading: false,
			checked: false,
			searchText: '',
			name: 'user',
			companyLogo: '',
			file: '',
			id: '',
			firstname: '',
			lastname: '',
			age: '',
			email: '',
			tempemail: '',
			password: '',
			loginType: '',
			companyList: [],
			roleList: [],
			role: null,
			gender: null,
			company: null,
			Uid: '',
			items: [],
			tempItems: [],
			comission: 10,
			warehouseName: '',
			tempWarehouseName: '',
			selectSellType: null,
			selectShip: null,
			selectPaid: null,
			selectTime: null,
			selectSubscription: { value: 'no', label: 'No' },
			selectPrioritycolor: { value: 'blackPrioritycolor', label: 'Black' },
			isAccountDisabled: false,
			isnotificationDisabled: true,
			advertismentfeesmodalIsOpen: false,
			fulfillmentmodalIsOpen: false,
			isordernotificationDisabled: true,
			transactionItems: [],
			advertismentItems: [],
			alertmodalIsOpen: false,
			walmartmodalIsOpen: false,
			orderNotificationList: [],
			emailmodalIsOpen: false,
			editpricemodalIsOpen: false,
			paymentmodalIsOpen: false,
			storageFees: 0,
			storageSpaceUsed: 0,
			advertisingPlan: 0,
			advertismentAmount: 0,
			orderEmail: '',
			editPrice: '',
			monthYear: '',
			monthYearFullFillMent: '',
			postage: 0,
			receiving: 0,
			barcode: 0,
			kitting: 0,
			packaging: 0,
			handling: 0,
			pallet: 0,
			freight: 0,
			partner: 0,
			other: 0,
			fullFillmentItems: [],
			paymentPageItems: [],
			marketPlaceAmount: '',
			socialAmount: '',
			expenseBreakDownOther: 0,
			bankAccount: '',
			bankName: '',
			bankRouting: '',
			paypalEmail: '',
			transactionHeaders: [
				{ key: 'created', title: 'Date' },
				{ key: 'email', title: 'Email' },
				{ key: 'status', title: 'Status' },
				{ key: 'amount', title: 'Amount' },
				{ key: 'currency', title: 'Currency' },
				{ key: 'description', title: 'Description' },
				{ key: 'receipt_url', title: 'Invoice' },
			],
			headers: [
				{ key: 'count', title: 'No' },
				{ key: 'Sku', title: 'SKU' },
				{ key: 'delete', title: 'Delete' },
			],
			ordernotificationHeader: [
				{ key: 'count', title: 'No' },
				{ key: 'email', title: 'Email' },
				// { key: 'notification_status', title: 'Status' },
				{ key: 'delete', title: 'Delete' },
			],
			advertisingfeesHeader: [
				// { key: 'count', title: 'No' },
				{ key: 'month_year', title: 'Month-Year' },
				{ key: 'marketPlaceAmount', title: 'Marketplace Advertising' },
				{ key: 'socialAmount', title: 'Social Advertising' },
				{ key: 'edit_payment', title: 'Edit' },
				{ key: 'delete', title: 'Delete' },
			],
			fulfillmentfeesHeader: [
				{ key: 'postage', title: 'Postage' },
				{ key: 'receiving', title: 'Receiving' },
				{ key: 'barcode', title: 'Barcode Prep' },
				{ key: 'kitting', title: 'Bundling/Kitting' },
				{ key: 'packaging', title: 'Packaging Prep' },
				{ key: 'handling', title: 'Handling' },
				{ key: 'pallet', title: 'Pallet Prep' },
				{ key: 'freight', title: 'Freight' },
				{ key: 'partner', title: 'Partner 3PL Fulfillment Fees' },
				{ key: 'other', title: 'Other' },
				{ key: 'month_year', title: 'Month-Year' },
				{ key: 'storageFees', title: 'Storage Fees' },
				{ key: 'storageSpaceUsed', title: 'Storage Space Used' },
				{ key: 'returnPostageCost', title: 'Returned Inventory' },
				{ key: 'returnHandlingCost', title: 'Return Handling Fee'},
				{ key: 'internationalFees', title: 'International Fees'},
				{ key: 'edit_payment', title: 'Edit' },
				{ key: 'delete', title: 'Delete' },
			],
			paymentfeesHeader: [
				{ key: 'total_revenue', title: 'Total Revenue' },
				{ key: 'total_exp', title: 'Total Expenses' },
				{ key: 'returns_fee', title: 'Returns' },
				{ key: 'amazon', title: 'Amazon' },
				{ key: 'ebay', title: 'EBay' },
				{ key: 'walmart', title: 'Walmart' },
				{ key: 'other', title: 'Other' },
				{ key: 'expenseBreakDownOther', title: 'Other (Other Expense)' },
				{ key: 'promoFees', title: 'Promo Fees' },
				{ key: 'marketPlaceCommissionPercentage', title: 'Marketplace Commission Percentage' },
				{ key: 'month_year', title: 'Month-Year' },
				{ key: 'edit_payment', title: 'Edit' },
				{ key: 'delete', title: 'Delete' },
			],
			paymentHeaders: [
				{ label: 'Total Revenue', key: 'total_revenue' },
				{ label: 'Total Expenses', key: 'total_exp' },
				{ label: 'ValetSeller Commission', key: 'valetseller_commission' },
				{ label: 'Storage Commission', key: 'storage_commission' },
				{ label: 'Market Place Commission', key: 'market_place_commission' },
				{ label: 'Partner 3PL Fullfillment Fees', key: 'partner_3pl_full' },
				{ label: 'Marketplace Advertising', key: 'marketplace_advertising' },
				{ label: 'Social Advertising', key: 'social_advertising' },
				{ label: 'Promo Fees', key: 'promo_fees' },
				{ label: 'Other', key: 'other' },
				{ label: 'Other (Other Expense)', key: 'expenseBreakDownOther' },
				{ label: 'Returns', key: 'returns' },
				{ label: 'Balance', key: 'balance' },
			],
			normal: false,
			paymentCsvData: [],
			monthYearPayment: '',
			paymentPieCard: {
				labels: [
					'Valet Seller Commission',
					'Marketplace Commission',
					'Partner 3PL Fullfillment Fees',
					'Marketplace Advertising',
					'Social Advertising',
					'Promo Fees',
					'Other',
					'Returns',
					// 'TotalExp',
				],
				seriesData: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							'#ab0df6',
							'#4d8bff',
							'#ff6b77',
							'#27b7fd',
							'#c30cf4',
							'#cddc39',
							'#ffeb3b',
							'#009688',
							// '#03f490'
						],
						hoverBackgroundColor: [
							'#ab0df6',
							'#4d8bff',
							'#ff6b77',
							'#27b7fd',
							'#c30cf4',
							'#cddc39',
							'#ffeb3b',
							'#009688',
							// '#03f490'
						],
					},
				],
				showOverride: false,
			},

			accountheaders: [
				{ key: 'date', title: 'Date' },
				{ key: 'charge', title: 'Charge' },
				{ key: 'amount', title: 'Amount' },
			],
			totalRevenue: '',
			totalReturns: '',
			totalExpenses: '',
			channelFees: 0,
			couponFees: 0,
			usFBAFees: 0,
			ukFBAFees: 0,
			otherAmazonFess: 0,
			vSFixedFees: 0,
			uSAmazonAds: 0,
			uKAmazonAds: 0,
			walmartAds: 0,
			returns: 0,
			reimbursements: 0,
			fBAInbounds: 0,
			balanceDue: 0,
			totalExp: 0,
			ebayRevenue: 0,
			walmartRevenue: 0,
			amazonUKRevenue: 0,
			amazonUSRevenue: 0,
			valetsellerComissionPercentage: 0,
			storageFeesPercentage: 0,
			totalBreakDown: 0,
			totalAdditonalServices: 0,
			channelPayment: [],
			storeList: [],
			overridesHeaders:[
				{ key: 'text', accessor: 'utm_medium', title: 'UTM Medium' },
				{ key: 'text', accessor: 'utm_term', title: 'UTM Term' },
				{ key: 'text', accessor: 'utm_source', title: 'UTM Source' },
				{ key: 'text', accessor: 'utm_campaign', title: 'UTM Campaign' },
				{ key: 'text', accessor: 'utm_content', title: 'UTM Content' },
				{ key: 'text', accessor: 'number_sku', title: "Number of SKU's" },
				{ key: 'text', accessor: 'number_sku', title: "To/tal Sales" },
				{ key: 'ammount', accessor: 'totalComission', title: "Total Commission" },
				{ key: 'date', accessor: 'register_date', title: "Register Date" },
				{ key: 'date', accessor: 'subscriber_date', title: "Subscription Date" },
				{ key: 'text', accessor: 'status', title: "Status (Free/Paid)" },
				{ key: 'ammount', accessor: 'totalPaid', title: "Total Paid" },
				{ key: 'ammount', accessor: 'planValue', title: "Paid Plan Value" },
				{ key: 'text', accessor: 'marketingPlan', title: "Marketing Plan (Y/N)" },
				{ key: 'ammount', accessor: 'marketingPlanValue', title: "Marketing Plan Value" },
				{ key: 'text', accessor: 'logisticsPlan', title: "Logistics Plan (Y/N)" },
				{ key: 'ammount', accessor: 'logisticsPlanValue', title: "Logistics Plan Value" },
				{ key: 'voidEdit', title: 'Edit' },
				{ key: 'voidDelete', title: 'Delete' },
			],
			overrideForm: {},
			overrides: [],
			loadingOverride: false,
			warehouses: [],
			defaultWarehouse: null,
			savingNewDefaultWarehouse: false,
			isHybridAccount: false,
			hybridCommissions: {},
			returnPostageCost: 0,
			returnHandlingCost: 0,
			internationalFees: 0,
		};

		this.getData = this.getData.bind(this);
		this.handleBlockChange = this.handleBlockChange.bind(this);
		this.handleEmailnotificationChange = this.handleEmailnotificationChange.bind(
			this,
		);
		this.handleOrdernotificationChange = this.handleOrdernotificationChange.bind(
			this,
		);
		this.handleChangeDate = this.handleChangeDate.bind(this);

		this.closealertModal = this.closealertModal.bind(this);
		this.closewalmartModal = this.closewalmartModal.bind(this);
		this.closeadvertismentfeesModal = this.closeadvertismentfeesModal.bind(
			this,
		);
		this.closePaymentfeesModal = this.closePaymentfeesModal.bind(this);
		this.deleteAction = this.deleteAction.bind(this);
		this.deleteOrderAction = this.deleteOrderAction.bind(this);
		this.getAdvertising = this.getAdvertising.bind(this);
		this.getFullFillmentService = this.getFullFillmentService.bind(this);
		this.getPaymentPage = this.getPaymentPage.bind(this);
		this.deleteAdvertisment = this.deleteAdvertisment.bind(this);
		this.deleteFullFillment = this.deleteFullFillment.bind(this);
		this.deletePaymentPage = this.deletePaymentPage.bind(this);

		this.editPayment = this.editPayment.bind(this);
		this.editFulfillment = this.editFulfillment.bind(this);
		this.editAdvertisment = this.editAdvertisment.bind(this);
		this.getWarehouses = this.getWarehouses.bind(this);
		this.setDefaultWarehouse = this.setDefaultWarehouse.bind(this);
		this.handleMarketPlaceHybridCommisions = this.handleMarketPlaceHybridCommisions.bind(this);
		this.getPaypalTransactionHistory = this.getPaypalTransactionHistory.bind(this);
	}

	componentDidMount() {
		if (this.props.list_role == undefined) {
			this.props.listRoleRecord();
		} else {
			this.updateRole(this.props.list_role);
		}
		console.log('this.props.match.params.id===', this.props.match.params.id);
		if (this.props.match.params.id) {
			this.getUser();
		}
		this.getWarehouses();
		this.getPaypalTransactionHistory()
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.list_role != this.props.list_role) {
			this.updateRole(nextProps.list_role);
		}
	}

	closealertModal() {
		this.setState({ alertmodalIsOpen: false });
	}

	closealertModal() {
		this.setState({ alertmodalIsOpen: false });
	}

	closewalmartModal() {
		this.setState({ walmartmodalIsOpen: false });
	}
	openEmail() {
		this.setState({ emailmodalIsOpen: true });
	}
	openEditprice() {
		this.setState({ editpricemodalIsOpen: true });
	}

	openAdvertismentfees() {
		this.setState({ advertismentfeesmodalIsOpen: true });
	}

	closeadvertismentfeesModal() {
		this.setState({ advertismentfeesmodalIsOpen: false });
	}

	closePaymentfeesModal() {
		this.setState({ paymentmodalIsOpen: false });
	}

	openFulfillmentmodalfees() {
		this.setState({ fulfillmentmodalIsOpen: true });
	}

	closefulfillmentfeesModal() {
		this.setState({ fulfillmentmodalIsOpen: false });
	}

	handleChangeDate(date) {
		this.setState({ monthYear: date });
	}

	handleChangeFullFillMentDate(date) {
		this.setState({ monthYearFullFillMent: date });
	}

	handleChangePaymentDate(date) {
		this.setState({ monthYearPayment: date });
	}

	getUser(notLoading) {
		let that = this;
		const db = firebase1.app.firestore();
		if(!notLoading) {
			this.setState({ isLoading: true })
		} else {
			this.setState({ tableLoading: true }) ;
		}
		db
			.collection('user')
			.where('uid', '==', this.props.match.params.id)
			.get()
			.then(function(userSnapshot) {
				console.log('userSnapshot.size==', userSnapshot.size);
				if (userSnapshot.size > 0) {
					userSnapshot.forEach(function(doc) {
						let data = doc.data();
						data.key = doc.id;
						console.log(data);
						that.updateState(data);
						if (data.billing && data.billing.stripe_customer_id) {
							that.newTransactionItems = [];
							that.getTransactionHistory(
								data.billing.stripe_customer_id,
								data.email,
								'',
							);
						}
					});
				}
				// that.setState({isLoading:false});
			});
	}

	getPaypalTransactionHistory() {
		this.setState({
			isLoading: true,
			tableLoading: true,
		});
		api.getApi(`transactionsHistory/${this.props.match.params.id}`)
			.then((response) => {
				console.log('=====',response.data)
				this.newTransactionItems = (this.newTransactionItems || []).concat(
					(response.data || []).map((transaction) => ({
						email: transaction.Email,
						created: transaction.Time,
						status: transaction.Status,
						currency: transaction.Currency,
						amount: transaction.GrossAmount,
						description: '',
						receipt_url: transaction.id,
					})),
				);
				this.setState({
					isLoading: false,
					tableLoading: false,
					transactionItems: this.newTransactionItems,
				})
			});
	}

	getTransactionHistory(customerId, email, nextPage) {
		let url = 'charges?customer=' + customerId + '&limit=100';
		if (nextPage != '') {
			url =
				'charges?customer=' +
				customerId +
				'&limit=100&starting_after=' +
				nextPage;
		}
		api.stripeGet(url).then((cutomerData) => {
			if (cutomerData.error) {
				helper.errorMessage(cutomerData.error.message);
				this.setState({ isLoading: false, tableLoading: false,
				});
			} else {
				console.log('cutomerData===', cutomerData.data);
				let transactionItems = cutomerData.data;

				for (let data in transactionItems) {
					transactionItems[data].email = email;
					transactionItems[data].created = helper.getDateFormat(
						transactionItems[data].created * 1000,
					);
					transactionItems[data].amount = transactionItems[data].amount / 100;
					transactionItems[data].currency = transactionItems[data].currency;
					transactionItems[data].description =
						transactionItems[data].description;
				}
				this.newTransactionItems = this.newTransactionItems.concat(
					transactionItems,
				);
				if (cutomerData.has_more) {
					console.log(transactionItems[transactionItems.length - 1].id);
					this.getTransactionHistory(
						customerId,
						email,
						transactionItems[transactionItems.length - 1].id,
					);
				} else {
					this.setState({
						isLoading: false,
						tableLoading: false,
						transactionItems: this.newTransactionItems,
					});
				}
			}
		});
	}

	handleMarketPlaceHybridCommisions(e) {
		e.preventDefault();
		const { hybridCommissions } = this.state;
		const newHybridCommissions = {
			...hybridCommissions,
			[e.target.name]: parseInt(e.target.value),
		};
		this.setState({ hybridCommissions: newHybridCommissions });
	}

	getData(page, pageSize) {
		let that = this;
		this.setState({ tableLoading: true, pageSize: pageSize });
		let apiPath = '';

		apiPath = 'sku/list';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};
		if (this.state.searchText.length > 0) {
			reqData.sku = this.state.searchText;
		}
		if (this.props.match.params.id) {
			reqData.Uid = this.props.match.params.id;
		}
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				console.log('items==', String.fromCharCode('Caf�Gavotte-051497154165'));
				let count = page * pageSize - pageSize + 1;
				for (let item in items) {
					items[item].count = count;
					items[item].key = items[item]._id;
					count = count + 1;
				}
				this.setState(
					{
						tableLoading: false,
						items: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					},
					() => {
						//this.getProducts();
					},
				);
			} else if (data.messages.length > 0) {
				helper.errorMessage(data.messages[0]);
			}

			that.setState({ isLoading: false });
		});
	}

	deleteAction(id) {
		let items = this.state.items;
		let index = helper.getItemIndex(items, '_id', id);
		let sku = items[index].Sku;
		items.splice(index, 1);
		this.setState({ items: [ ...items ] });
		let apiPath = 'sku/deleteSku';
		let reqData = {
			sku: sku,
			Uid: this.state.Uid,
		};
		console.log(reqData);
		api.postApi(apiPath, reqData).then((data) => {
			console.log('sucessfullt delete==', data);
		});
	}

	actionSearch() {
		if (this.state.searchText.length > 0) {
			this.getData(1, this.state.pageSize);
		}
	}

	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				if (text.length == 0) {
					this.getData(1, this.state.pageSize);
				}
			},
		);
	}

	getCompany() {
		this.setState({ isLoading: true });
		let companyList = [];
		api.getApi('storefronts').then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				for (let item in items) {
					companyList.push({ value: items[item].Id, label: items[item].Name });
				}
			}
			this.setState({ isLoading: false, companyList: companyList });
		});
	}

	async deleteOrderAction(key) {
		console.log('key===', key);
		let orderNotificationList = this.state.orderNotificationList;
		console.log(orderNotificationList[key - 1].email, this.state.email);

		if (orderNotificationList[key - 1].email == this.state.email) {
			helper.errorMessage('this is primary email address');
		} else {
			orderNotificationList.splice(key - 1, 1);
			const db = firebase1.app.firestore();
			await db
				.collection('user')
				.doc(this.state.key)
				.update({ order_notification_list: orderNotificationList });
			this.setState({ orderNotificationList: orderNotificationList }, () => {
				this.resetOrderNotifications();
			});
		}
	}

	updateState(editData) {
		let orderNotificationList = [];
		if (editData.order_notification_list != undefined) {
			orderNotificationList = editData.order_notification_list;
		} else {
			orderNotificationList.push({ email: editData.email, status: true });
		}
		let count = 1;
		for (let item in orderNotificationList) {
			orderNotificationList[item].count = count;
			orderNotificationList[item].key = count;
			count = count + 1;
		}
		const planId = editData.paypal_billing && editData.paypal_billing.plan_id;
		const currentPlan = paypalPlanList.find((plan) => planId === plan.id);
		console.log('orderNotificationList===', editData);
		this.setState({
			defaultWarehouse: editData.defaultWarehouse && {
				label: editData.defaultWarehouse.name,
				value: editData.defaultWarehouse.id,
			},
			isLoading: false,
			tableLoading: false,
			id: editData.id,
			key: editData.key,
			loginType: editData.login_type ? editData.login_type : 'register',
			created_at: editData.created_at,
			companyLogo: editData.company_logo,
			email: editData.email,
			tempemail: editData.email,
			firstname: editData.firstname,
			role: editData.role,
			comission: editData.comission ? editData.comission : 10,
			password: editData.password,
			company: editData.company,
			storageFees: editData.storage_fees ? editData.storage_fees : 0,
			advertisingPlan: editData.advertising_amount
				? editData.advertising_amount
				: 0,
			Uid: editData.uid,
			orderNotificationList: orderNotificationList,
			isnotificationDisabled:
				editData.payment_notification !== undefined
					? editData.payment_notification
					: true,
			isordernotificationDisabled:
				editData.order_notification != undefined
					? editData.order_notification
					: true,
			isAccountDisabled:
				editData.account_disabled != undefined
					? editData.account_disabled
					: false,
			selectSellType: editData.sell_type ? editData.sell_type : null,
			selectShip: editData.ship_orders ? editData.ship_orders : null,
			selectPaid: editData.paid_type ? editData.paid_type : null,
			selectTime: editData.handle_time ? editData.handle_time : null,
			selectPrioritycolor: editData.priority
				? editData.priority
				: this.state.selectPrioritycolor,
			warehouseName: editData.warehouse_name ? editData.warehouse_name : '',
			tempWarehouseName: editData.warehouse_name ? editData.warehouse_name : '',
			selectSubscription: editData.skip_monthly_check
				? editData.skip_monthly_check
				: this.state.selectSubscription,
			overrides: editData.overrides ? [ { ...editData.overrides }] : [],
			hybridCommissions: editData.hybridCommissions || {},
			isHybridAccount: editData.isHybridAccount || false,
			paypal_billing: {
				value: planId,
				label: currentPlan && currentPlan.name,
			},
			bankAccount: editData.paymentDetails && editData.paymentDetails.bankAccount,
			bankName: editData.paymentDetails && editData.paymentDetails.bankName,
			bankRouting: editData.paymentDetails && editData.paymentDetails.bankRouting,
			paypalEmail: editData.paymentDetails && editData.paymentDetails.paypalEmail,
		});
	}

	updateRole(list) {
		let roleList = [];
		for (let data in list) {
			let item = { value: list[data].id, label: list[data].name };
			roleList.push(item);
		}
		console.log('rolelist=====', list);
		this.setState({ roleList: roleList });
	}

	handleroleChange = (role) => {
		this.setState({ role });
		console.log(`Option selected:`, role);
	};

	handlefulfillmentChange = (fulfillment) => {
		this.setState({ fulfillment });
	};

	handlecompanyChange = (company) => {
		this.setState({ company });
		console.log(`Option selected:`, company);
	};

	async addOrderEmail() {
		let email = this.state.orderEmail;
		let orderNotificationList = this.state.orderNotificationList;

		if (email == '' || email == undefined) {
			helper.errorMessage('Email field is required');
		} else if (email && !validEmail.test(email.replace(/ /g, ''))) {
			helper.errorMessage('Email invalid');
		} else {
			var emailData = this.state.orderNotificationList.find((data1) => {
				return data1.email === email;
			});
			console.log('emailData===', emailData);
			if (emailData) {
				helper.errorMessage('Already added');
			} else {
				orderNotificationList.push({ email: email, status: true });
				this.setState(
					{
						orderNotificationList: [ ...orderNotificationList ],
						emailmodalIsOpen: false,
					},
					() => {
						this.resetOrderNotifications();
					},
				);
				const db = firebase1.app.firestore();
				await db
					.collection('user')
					.doc(this.state.key)
					.update({ order_notification_list: orderNotificationList });
			}
		}
	}

	resetOrderNotifications() {
		let orderNotificationList = this.state.orderNotificationList;
		let count = 1;
		for (let data in orderNotificationList) {
			orderNotificationList[data].count = count;
			orderNotificationList[data].key = count;
			count = count + 1;
		}
		this.setState({ orderNotificationList: [ ...orderNotificationList ] });
	}

	create = () => {
		let date = new Date();
		let createdDate =
			date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
		//Must be at least six characters long

		const {
			selectSellType,
			selectShip,
			selectPaid,
			advertisingPlan,
			storageFees,
			selectTime,
			selectPrioritycolor,
			isnotificationDisabled,
			isordernotificationDisabled,
			isAccountDisabled,
			warehouseName,
			loginType,
			firstname,
			lastname,
			gender,
			age,
			profile_photo,
			file,
			role,
			company,
			password,
			email,
			comission,
			isHybridAccount,
			hybridCommissions,
			paypal_billing,
			bankAccount,
			bankName,
			bankRouting,
			paypalEmail,
		} = this.state;
		console.log(paypal_billing.value, 'knjbj')
		const plan = paypalPlanList.find((paypalPlan) => paypalPlan.id === paypal_billing.value);
		console.log('***', plan)

		if (firstname == '' || firstname == undefined) {
			helper.errorMessage('Firstname field is required');
		} else if (email == '' || email == undefined) {
			helper.errorMessage('Email field is required');
		} else if (email && !validEmail.test(email.replace(/ /g, ''))) {
			helper.errorMessage('email invalid');
		} else if (
			loginType != 'facebook' &&
			(password == '' || password == undefined)
		) {
			helper.errorMessage('Password field is required');
		} else if (loginType != 'facebook' && password.length < 6) {
			helper.errorMessage('Password must be at least six characters long');
		} else if (role == '' || role == undefined) {
			helper.errorMessage('Role field is required');
		} else if (company == '' || company == undefined) {
			helper.errorMessage('Company field is required');
		} else if (comission == '') {
			helper.errorMessage('Comission field is required');
		} else if (0 > comission) {
			helper.errorMessage('Comission fees invalid');
		} else if (storageFees === '') {
			helper.errorMessage('Storage fees field is required');
		} else if (advertisingPlan != '' && 0 > advertisingPlan) {
			helper.errorMessage('advertising invalid');
		} else {
			console.log('age====', age);
			let data = {
				firstname: firstname,
				email: email,
				role: role,
				company: company,
				password: password,
				company_logo_file: file,
				created_at: createdDate,
				updated_at: createdDate,
				comission: comission,
				// storage_fees: storageFees,
				advertising_amount: advertisingPlan,
				sell_type: selectSellType,
				ship_orders: selectShip,
				paid_type: selectPaid,
				handle_time: selectTime,
				priority: selectPrioritycolor,
				warehouse_name: '',
				account_disabled: isAccountDisabled,
				payment_notification: isnotificationDisabled,
				order_notification: isordernotificationDisabled,
				skip_monthly_check: this.state.selectSubscription,
				isHybridAccount,
				hybridCommissions,
				paymentDetails: {
					// bankAccount,
					// bankName,
					// bankRouting,
					// paypalEmail,
				}
				// paypal_billing: 
				// marketPlaceCount: plan.marketplace,
			};

			if (bankAccount) {
				data['paymentDetails']['bankAccount'] = bankAccount;
			}
			if (bankName) {
				data['paymentDetails']['bankName'] = bankName;
			}
			if (bankRouting) {
				data['paymentDetails']['bankRouting'] = bankRouting;
			}
			if (paypalEmail) {
				data['paymentDetails']['paypalEmail'] = paypalEmail;
			}

			if (plan) {
				data['paypal_billing'] = {
					plan_id: plan.id,
					sku_count: plan.sku,
				}
				data['marketPlaceCount'] = plan.marketplace;
			}

			let that = this;
			const db = firebase1.app.firestore();
			that.setState({ isLoading: true });
			console.log('data===', paypal_billing);

			db
				.collection('user')
				.where('email', '==', email)
				.get()
				.then(function(querySnapshot) {
					console.log('querySnapshot===', querySnapshot.size);
					if (querySnapshot.size == 0) {
						that.updateAction(data);
					} else {
						if (that.state.tempemail == that.state.email) {
							// that.checkWarehouse(data);
							that.updateAction(data);
						} else {
							that.setState({ isLoading: false });
							helper.errorMessage('This email already taken');
						}
					}
				});
		}
	};

	async checkWarehouse(data) {
		console.log(
			'this.state.warehouseName===',
			this.state.warehouseName,
			this.state.tempWarehouseName,
		);
		if (
			data.ship_orders &&
			data.ship_orders.value == 'myself' &&
			this.state.warehouseName != this.state.tempWarehouseName
		) {
			let reqData = {
				Name: this.state.warehouseName,
				ContactInformation: {
					FullName: this.state.firstname,
					EmailAddress: this.state.email,
				},
			};
			api.postEcomDashApi('Warehouses', reqData).then((warehouseData) => {
				console.log('warehouseData===', warehouseData);
				if (warehouseData.status == 'Success') {
					// helper.successMessage('');
					data.warehouse_id = warehouseData.data.Id;
					this.updateAction(data);
				} else {
					if (warehouseData && warehouseData.messages.length > 0) {
						helper.errorMessage(
							warehouseData.messages[warehouseData.messages.length - 1],
						);
					}
					this.setState({ isLoading: false });
				}
			});
		} else {
			this.updateAction(data);
		}
	}

	async updateAction(data) {
		const db = firebase1.app.firestore();

		if (this.state.id == '') {
			data.admin = true;
			data.register_date = new Date();

			this.setState({ isLoading: true });

			// await db.collection('user').add(data);
			await this.props.addRecord('user', data, false);
			helper.successMessage('Successfully added');
			this.setState({ isLoading: false });
			history.push('/pages/user');
			//this.props.addRecord('user', data);
		} else {
			//  data.created_at = this.state.created_at;
			this.setState({ isLoading: true });
			console.log('company_logo_file==', data);
			await this.props.updateRecord('user', data, this.state.key, false);
			helper.successMessage('Successfully updated');
			// await db.collection('user').doc(this.state.key).update(data)
			// helper.successMessage('Successfully updated')
			this.setState({ isLoading: false });
			//history.push("/pages/user");
		}
	}

	photoOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files.length > 0) {
			const file: File = e.target.files[0];
			console.log(file);
			let that = this;
			try {
				this.setState({ file: file });
				var reader = new FileReader();
				reader.onload = function(e) {
					that.setState({ companyLogo: e.target.result });
				};
				reader.readAsDataURL(file);
			} catch (err) {
				console.log(err);
			} finally {
			}
		}
	};

	// addSkuTest(){

	//   let newItem =[{sku:'TestPsb-1598358376247'},{sku:'TestPsb-1598614942415'}]
	//   let req = {
	//     Uid: this.props.match.params.id,
	//     skulist: newItem
	//   }
	//   console.log(req);
	//   api.postApi('sku/addsku', req).then((resdata) => {
	//     console.log(resdata);
	//     if (resdata.status == 'Success') {
	//       helper.successMessage('Sucessfully sku imported')
	//     } else {
	//       helper.errorMessage(resdata.messages[0])
	//     }
	//     this.setState({ isLoading: false })

	//   })
	// }

	handleForce = (data) => {
		this.setState(
			{
				csvData: data,
			},
			() => {
				if (data.length > 0) {
					console.log('data==', data);
					if (data.length == 0) {
						helper.errorMessage('No data. please check csv');
					} else if (data[0].sku == undefined) {
						helper.errorMessage('invalid format. please check csv');
					} else {
						this.setState({ isLoading: true });
						let newItem = [];
						for (let item in data) {
							if (data[item] && data[item] != '') {
								// let sku = data[item].sku.replace(/\s/g,'');
								data[item].sku = data[item].sku;
								if (data[item].sku && data[item].sku != '') {
									data[item].sku = String(data[item].sku).trim();
								}
								newItem.push(data[item]);
							}
						}
						let req = {
							Uid: this.props.match.params.id,
							skulist: newItem,
						};
						console.log(req);
						api.postApi('sku/addsku', req).then((resdata) => {
							console.log(resdata);
							if (resdata.status == 'Success') {
								helper.successMessage('Sucessfully sku imported');
							} else {
								helper.errorMessage(resdata.messages[0]);
							}
							this.setState({ isLoading: false });
						});
					}
				}
			},
		);
	};

	updateProduct() {
		console.log(this.state.items);
		let newItems = [];
		for (let data in this.state.items) {
			if (this.state.items[data].Sku != '') {
				let sku = String(this.state.items[data].Sku).trim();
				newItems.push({ sku: sku });
			}
		}
		let req = {
			Uid: this.props.match.params.id,
			skulist: newItems,
		};
		console.log(req);
		api.postApi('sku/addsku', req).then((resdata) => {
			console.log(resdata);
			if (resdata.status == 'Success') {
				helper.successMessage(
					'Updating inventory,please check after 10 minutes',
				);
			} else {
				helper.errorMessage(resdata.messages[0]);
			}
			this.setState({ isLoading: false });
		});
	}

	deleteAllSku() {
		let req = {
			Uid: this.props.match.params.id,
		};
		this.setState({ isLoading: true, alertmodalIsOpen: false });
		api.postApi('sku/deleteAllSku', req).then((resdata) => {
			console.log(resdata);
			if (resdata.status == 'Success') {
				helper.successMessage('Sucessfully removed');
			} else {
				helper.errorMessage(resdata.messages[0]);
			}
			this.setState({ isLoading: false });
		});
	}

	handleCartChange = (selectSellType) => {
		if (selectSellType.map((o) => o.value) == 'Walmart') {
			this.setState({ selectSellType, walmartmodalIsOpen: true });
		} else {
			this.setState({ selectSellType });
		}
	};

	// handleCartChange = (selectSellType) => {
	//   this.setState({ selectSellType });
	// };
	handleShipChange = (selectShip) => {
		this.setState({ selectShip });
	};
	handlePaidChange = (selectPaid) => {
		this.setState({ selectPaid });
	};
	handleTimeChange = (selectTime) => {
		this.setState({ selectTime });
	};
	handleBlockChange(isAccountDisabled) {
		this.setState({ isAccountDisabled: !isAccountDisabled });
	}
	handleEmailnotificationChange(isnotificationDisabled) {
		console.log('isnotificationDisabled===', isnotificationDisabled);
		this.setState({ isnotificationDisabled: isnotificationDisabled });
	}
	handleOrdernotificationChange(isordernotificationDisabled) {
		this.setState({ isordernotificationDisabled: isordernotificationDisabled });
	}
	handleSubscriptionChange = (selectSubscription) => {
		this.setState({ selectSubscription });
	};
	handlePrioritycolor = (selectPrioritycolor) => {
		this.setState({ selectPrioritycolor });
	};

	handlePlanSelect = (paypal_billing) => {
		this.setState({ paypal_billing });
	}

	getFullFillmentService(page, pageSize) {
		console.log('==================================');
		let that = this;
		this.setState({ tableLoading: true, pageSize: pageSize });
		let apiPath = '';

		apiPath = 'advertising/getFullFillmentService';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};

		if (this.props.match.params.id) {
			reqData.Uid = this.props.match.params.id;
		}
		console.log('reqData========', reqData);
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				let count = page * pageSize - pageSize + 1;
				for (let item in items) {
					items[item].count = count;
					items[item].key = items[item]._id;
					items[item].month_year = items[item].MonthYear;
					items[item].postage = items[item].Postage;
					items[item].receiving = items[item].Receiving;
					items[item].barcode = items[item].Barcode;
					items[item].kitting = items[item].Kitting;
					items[item].packaging = items[item].Packaging;
					items[item].handling = items[item].Handling;
					items[item].pallet = items[item].Pallet;
					items[item].freight = items[item].Freight;
					items[item].partner = items[item].Partner;
					items[item].other = items[item].Other;
					count = count + 1;
				}
				console.log('items===', items);
				this.setState(
					{
						tableLoading: false,
						fullFillmentItems: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					},
					() => {
						//this.getProducts();
					},
				);
			} else if (data.messages.length > 0) {
				helper.errorMessage(data.messages[0]);
			}

			that.setState({ isLoading: false });
		});
	}

	getAdvertising(page, pageSize) {
		console.log('==================================');
		let that = this;
		this.setState({ tableLoading: true, pageSize: pageSize });
		let apiPath = '';

		apiPath = 'advertising/getAdvertising';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};

		if (this.props.match.params.id) {
			reqData.Uid = this.props.match.params.id;
		}
		console.log('reqData========', reqData);
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				let count = page * pageSize - pageSize + 1;
				for (let item in items) {
					items[item].count = count;
					items[item].key = items[item]._id;
					items[item].month_year = items[item].MonthYear;
					items[item].amount = items[item].Amount;
					count = count + 1;
				}
				console.log('items===', items);
				this.setState(
					{
						tableLoading: false,
						advertismentItems: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					},
					() => {
						//this.getProducts();
					},
				);
			} else if (data.messages.length > 0) {
				helper.errorMessage(data.messages[0]);
			}

			that.setState({ isLoading: false });
		});
	}

	getPaymentPage(page, pageSize) {
		console.log('==================================');
		let that = this;
		this.setState({ tableLoading: true, pageSize: pageSize });
		let apiPath = '';

		apiPath = 'advertising/getPaymentPageService';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};

		if (this.props.match.params.id) {
			reqData.Uid = this.props.match.params.id;
		}
		console.log('reqData========', reqData);
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				let count = page * pageSize - pageSize + 1;
				console.log('items===', items);
				for (let item in items) {
					items[item].count = count;
					items[item].key = items[item]._id;
					items[item].month_year = items[item].MonthYear;
					items[item].total_revenue = items[item].Revenue;
					items[item].total_exp = items[item].Expenses;
					items[item].returns_fee = items[item].Returns;
					items[item].amazon = items[item].Amazon;
					items[item].ebay = items[item].EBay;
					items[item].walmart = items[item].Walmart;
					items[item].other = items[item].Other;
					items[item].promoFees = items[item].PromoFees;
					items[item].marketPlaceCommissionPercentage = items[item].marketPlaceCommissionPercentage;
					count = count + 1;
				}

				this.setState(
					{
						tableLoading: false,
						paymentPageItems: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					},
					() => {
						//this.getProducts();
					},
				);
			} else if (data.messages.length > 0) {
				helper.errorMessage(data.messages[0]);
			}

			that.setState({ isLoading: false });
		});
	}

	editAdvertisment(id) {
		let items = this.state.advertismentItems;
		let item = helper.getItem(items, '_id', id);
		console.log(item, id, items);
		let { monthYear, marketPlaceAmount, socialAmount } = this.state;

		if (item) {
			marketPlaceAmount = item.marketPlaceAmount;
			socialAmount = item.socialAmount;
			monthYear = new Date(item.Date);
		}

		this.setState({
			advertismentfeesmodalIsOpen: true,
			marketPlaceAmount: marketPlaceAmount,
			socialAmount: socialAmount,
			monthYear: monthYear,
		});
	}

	showAdvertismentModel() {
		this.setState({
			advertismentfeesmodalIsOpen: true,
			marketPlaceAmount: '',
			socialAmount: '',
			monthYear: '',
		});
	}

	addAdvertisment() {
		let advertismentAmount = this.state.advertismentAmount;
		console.log(
			'advertismentAmount==',
			advertismentAmount,
			this.state.monthYear,
		);
		if (this.state.monthYear == '') {
			helper.errorMessage('please select month and year');
		} else if (this.state.marketPlaceAmount == '') {
			helper.errorMessage('please enter marketplace advertising amount');
		} else if (this.state.socialAmount == '') {
			helper.errorMessage('please enter social advertising amount');
		} else {
			this.setState({ advertismentfeesmodalIsOpen: false, tableLoading: true });
			let monthYear = helper.getMonthFormat(this.state.monthYear);
			let req = {
				Uid: this.props.match.params.id,
				MonthYear: monthYear,
				Date: this.state.monthYear,
				marketPlaceAmount: this.state.marketPlaceAmount,
				socialAmount: this.state.socialAmount,
			};
			console.log(req);
			api.postApi('advertising/addAdvertising', req).then((resdata) => {
				console.log(resdata);
				if (resdata.status == 'Success') {
					// helper.successMessage('Updating inventory,please check after 10 minutes');
					this.getAdvertising(1, 25);
				} else {
					helper.errorMessage(resdata.messages[0]);
				}
				this.setState({ isLoading: false });
			});
		}
	}

	editPayment(id) {
		let items = this.state.paymentPageItems;
		let item = helper.getItem(items, '_id', id);
		console.log(item, id, items);
		let {
			totalRevenue,
			totalReturns,
			totalExpenses,
			monthYearPayment,
			amazon,
			ebay,
			walmart,
			other,
			promoFees,
			marketPlaceCommissionPercentage,
		} = this.state;

		if (item) {
			totalRevenue = item.Revenue;
			totalExpenses = item.Expenses;
			totalReturns = item.Returns;
			amazon = item.amazon;
			ebay = item.ebay;
			walmart = item.walmart;
			other = item.other;
			promoFees = item.promoFees;
			monthYearPayment = new Date(item.Date);
			marketPlaceCommissionPercentage = item.marketPlaceCommissionPercentage;
		}

		this.setState({
			paymentmodalIsOpen: true,
			totalRevenue: totalRevenue,
			totalExpenses: totalExpenses,
			totalReturns: totalReturns,
			monthYearPayment: monthYearPayment,
			amazon: amazon,
			ebay: ebay,
			walmart: walmart,
			promoFees: promoFees,
			marketPlaceCommissionPercentage,
			other: other,
		});
	}

	showPayment() {
		this.setState({
			totalRevenue: '',
			totalExpenses: '',
			totalReturns: '',
			monthYearPayment: '',
			amazon: '',
			ebay: '',
			walmart: '',
			other: '',
			promoFees: '',
			marketPlaceCommissionPercentage: '',
			paymentmodalIsOpen: true,
		});
	}

	addPayment() {
		let {
			totalRevenue,
			totalReturns,
			totalExpenses,
			amazon,
			ebay,
			walmart,
			other,
			monthYearPayment,
			promoFees,
			expenseBreakDownOther,
			marketPlaceCommissionPercentage,
		} = this.state;
		if (monthYearPayment === '') {
			helper.errorMessage('please select month and year');
		} else {
			this.setState({ paymentmodalIsOpen: false, tableLoading: true });
			let monthYear = helper.getMonthFormat(monthYearPayment);
			let req = {
				Uid: this.props.match.params.id,
				MonthYear: monthYear,
				Date: this.state.monthYearPayment,
				Revenue: totalRevenue,
				Returns: totalReturns,
				Expenses: totalExpenses,
				Amazon: amazon,
				EBay: ebay,
				Walmart: walmart,
				Other: other,
				PromoFees: promoFees,
				marketPlaceCommissionPercentage,
				expenseBreakDownOther,
			};
			console.log(req);
			api.postApi('advertising/addPaymentPageService', req).then((resdata) => {
				console.log(resdata);
				if (resdata.status == 'Success') {
					this.getPaymentPage(1, 25);
				} else {
					helper.errorMessage(resdata.messages[0]);
				}
				this.setState({ isLoading: false });
			});
		}
	}

	showFullFillmentModel() {
		this.setState({
			fulfillmentmodalIsOpen: true,
			postage: '',
			receiving: '',
			barcode: '',
			kitting: '',
			packaging: '',
			handling: '',
			pallet: '',
			freight: '',
			partner: '',
			other: '',
			monthYearFullFillMent: '',
			returnPostageCost: '',
			storageFees: '',
			returnHandlingCost: '',
			internationalFees: '',
		});
	}
	editFulfillment(id) {
		let items = this.state.fullFillmentItems;
		let item = helper.getItem(items, '_id', id);
		console.log(item, id, items);
		let {
			postage,
			receiving,
			barcode,
			kitting,
			packaging,
			handling,
			pallet,
			freight,
			partner,
			other,
			monthYearFullFillMent,
			returnPostageCost,
			storageFees,
			returnHandlingCost,
			internationalFees,
			storageSpaceUsed,
		} = this.state;

		if (item) {
			postage = item.postage;
			receiving = item.receiving;
			barcode = item.barcode;
			kitting = item.kitting;
			packaging = item.packaging;
			handling = item.handling;
			pallet = item.pallet;
			freight = item.freight;
			partner = item.partner;
			other = item.other;
			monthYearFullFillMent = new Date(item.Date);
			returnPostageCost = item.returnPostageCost;
			storageFees = item.storageFees || storageFees;
			returnHandlingCost = item.returnHandlingCost;
			internationalFees = item.internationalFees;
			storageSpaceUsed = item.storageSpaceUsed;
		}

		this.setState({
			fulfillmentmodalIsOpen: true,
			postage: postage,
			receiving: receiving,
			barcode: barcode,
			kitting: kitting,
			packaging: packaging,
			handling: handling,
			pallet: pallet,
			freight: freight,
			partner: partner,
			other: other,
			monthYearFullFillMent: monthYearFullFillMent,
			returnPostageCost,
			returnHandlingCost,
			internationalFees,
			storageFees,
			storageSpaceUsed,
		});
	}

	addFulfillment() {
		let {
			postage,
			receiving,
			barcode,
			kitting,
			packaging,
			handling,
			pallet,
			freight,
			partner,
			other,
			returnPostageCost,
			monthYearFullFillMent,
			storageFees,
			storageSpaceUsed,
			key,
			returnHandlingCost,
			internationalFees,
		} = this.state;
		console.log(postage, receiving);
		if (monthYearFullFillMent === '') {
			helper.errorMessage('please select month and year');
		} else if (postage === '') {
			helper.errorMessage('please enter postage');
		} else if (receiving === '') {
			helper.errorMessage('please enter receiving');
		} else if (barcode === '') {
			helper.errorMessage('please enter barcode');
		} else if (kitting === '') {
			helper.errorMessage('please enter kitting');
		} else if (packaging === '') {
			helper.errorMessage('please enter packaging');
		} else if (handling === '') {
			helper.errorMessage('please enter handling');
		} else if (pallet === '') {
			helper.errorMessage('please enter pallet');
		} else if (freight === '') {
			helper.errorMessage('please enter freight');
		} else if (partner === '') {
			helper.errorMessage('please enter partner');
		} else if (storageSpaceUsed === '') {
			helper.errorMessage('please enter storage space used');
		} else if (other === '') {
			helper.errorMessage('please enter other');
		} else if (storageFees === '') {
			helper.errorMessage('Storage fees invalid');
		} else if (returnHandlingCost === '') {
			helper.errorMessage('Return Handling Fee invalid');
		} else if (internationalFees === '') {
			helper.errorMessage('International Fees has an invalid value');
		} else {
			this.setState({ fulfillmentmodalIsOpen: false, tableLoading: true });
			let monthYear = helper.getMonthFormat(monthYearFullFillMent);
			let req = {
				Uid: this.props.match.params.id,
				MonthYear: monthYear,
				Date: this.state.monthYearFullFillMent,
				Postage: postage,
				Receiving: receiving,
				Barcode: barcode,
				Kitting: kitting,
				Packaging: packaging,
				Handling: handling,
				Pallet: pallet,
				Freight: freight,
				Partner: partner,
				Other: other,
				returnPostageCost,
				returnHandlingCost,
				internationalFees,
				storageFees,
				storageSpaceUsed,
			};
			console.log(req);
			api.postApi('advertising/addFullFillmentService', req).then((resdata) => {
				console.log(resdata);
				if (resdata.status == 'Success') {
					const db = firebase1.app.firestore();
					db.collection('user').doc(key).update({ storageFees })
						.then(() => {
							console.log("saved to firestore")
						})
						.catch((error) => console.log("not saved to firestore", error));
					this.getFullFillmentService(1, 25);
				} else {
					helper.errorMessage(resdata.messages[0]);
				}
				this.setState({ isLoading: false });
			});
		}
	}

	deleteAdvertisment(id) {
		// let items = this.state.items;
		// let index = helper.getItemIndex(items, '_id', id);
		console.log('id===', id);
		let req = {
			_id: id,
			Uid: this.props.match.params.id,
		};
		console.log(req);
		api.postApi('advertising/deleteAdvertising', req).then((resdata) => {
			console.log(resdata);
			if (resdata.status == 'Success') {
				// helper.successMessage('Updating inventory,please check after 10 minutes');
				this.getAdvertising(1, 25);
			} else {
				helper.errorMessage(resdata.messages[0]);
			}
			this.setState({ isLoading: false });
		});
	}

	deleteFullFillment(id) {
		// let items = this.state.items;
		// let index = helper.getItemIndex(items, '_id', id);
		console.log('id===', id);
		let req = {
			_id: id,
			Uid: this.props.match.params.id,
		};
		console.log(req);
		api.postApi('advertising/deleteFullFillment', req).then((resdata) => {
			console.log(resdata);
			if (resdata.status == 'Success') {
				// helper.successMessage('Updating inventory,please check after 10 minutes');
				this.getFullFillmentService(1, 25);
			} else {
				helper.errorMessage(resdata.messages[0]);
			}
			this.setState({ isLoading: false });
		});
	}

	deletePaymentPage(id) {
		// let items = this.state.items;
		// let index = helper.getItemIndex(items, '_id', id);
		console.log('id===', id);
		let req = {
			_id: id,
			Uid: this.props.match.params.id,
		};
		console.log(req);
		api.postApi('advertising/deletePaymentPage', req).then((resdata) => {
			console.log(resdata);
			if (resdata.status == 'Success') {
				// helper.successMessage('Updating inventory,please check after 10 minutes');
				this.getPaymentPage(1, 25);
			} else {
				helper.errorMessage(resdata.messages[0]);
			}
			this.setState({ isLoading: false });
		});
	}

	getOverrides = () => {
		return [this.state.overrides || {}];
	}

	deleteOverride = async () => {
		this.setState({ tableLoading: true });
		try {
			await firebase1.app.firestore()
				.collection('user')
				.doc(this.state.key)
				.update({ overrides: null });
			this.getUser(true);
			this.setState({ tableLoading: false });

		} catch (e) {
			this.setState({ tableLoading: false });

		}

	}
	editOverride = (overrideForm) => {
		this.toggleOverride()
		this.setState({ overrideForm })
	}

	toggleOverride = () => {
		const value  = !this.state.showOverride
		if(!value) {
			this.setState({ overrideForm: {} });
		}
		this.setState({ showOverride: value })
	}

	handleChangeOverride = (field, value) => {
		this.setState({
			overrideForm: {
				...this.state.overrideForm,
				[field]: value,
			}
		})
	};

	submitOverrides = async () => {
		try {
			this.setState({ loadingOverride: true });


			const data = await firebase1.app.firestore()
				.collection('user')
				.doc(this.state.key)
				.update({ overrides: {
						advertising_amount: this.state.overrideForm.advertising_amount || null,
						totalComission: this.state.overrideForm.totalComission || null,
						fullFillments: this.state.overrideForm.fullFillments || null,
						number_sku: this.state.overrideForm.number_sku || null,
						paid: this.state.overrideForm.paid || null,
						paypal_billing: this.state.overrideForm.paypal_billing || null,
						register_date: this.state.overrideForm.register_date || null,
						sales: this.state.overrideForm.sales || null,
						ship_orders: this.state.overrideForm.ship_orders || null,
						subscriber_date: this.state.overrideForm.subscriber_date || null,
						utm_campaign: this.state.overrideForm.utm_campaign || null,
						utm_content: this.state.overrideForm.utm_content || null,
						utm_medium: this.state.overrideForm.utm_medium || null,
						utm_term: this.state.overrideForm.utm_term || null,
						utm_source: this.state.overrideForm.utm_term || null,
						status: this.state.overrideForm.status && this.state.overrideForm.status.value
							?  this.state.overrideForm.status.value :  this.state.overrideForm.status || null,
						totalPaid: this.state.overrideForm.totalPaid || null,
						planValue: this.state.overrideForm.planValue || null,
						marketingPlan: this.state.overrideForm.marketingPlan && this.state.overrideForm.marketingPlan.value
							?  this.state.overrideForm.marketingPlan.value :  this.state.overrideForm.marketingPlan || null,
						marketingPlanValue: this.state.overrideForm.marketingPlanValue || null,
						logisticsPlanValue: this.state.overrideForm.logisticsPlanValue || null,
						logisticsPlan: this.state.overrideForm.logisticsPlan && this.state.overrideForm.logisticsPlan.value
							?  this.state.overrideForm.logisticsPlan.value :  this.state.overrideForm.logisticsPlan || null,
					} });

			this.getUser(true);
			this.setState({ loadingOverride: false });
		} catch (e) {
			console.log(e)
			this.setState({ loadingOverride: false });
		}

		this.toggleOverride();
	}

	// fetch all available ecomdash warehouses
	getWarehouses() {
		api.getEcomDashApi('Warehouses?resultsPerPage=1000&pageNumber=1')
			.then((response) => {
				this.setState({ warehouses: response.data })
			})
	};

	// set a default warehouse for a user account
	setDefaultWarehouse() {
		const { defaultWarehouse, key } = this.state;
		if (defaultWarehouse) {
			this.setState({ savingNewDefaultWarehouse: true });
			const db = firebase1.app.firestore();

			db.collection('user').doc(key).update({ defaultWarehouse: {
				name: defaultWarehouse.label,
				id: defaultWarehouse.value,
			}})
				.then(() => {
					helper.successMessage('Warehouse saved successfully');
					this.setState({ savingNewDefaultWarehouse: false })
				})
				.catch(() => this.setState({ savingNewDefaultWarehouse: false }));
		}
		else {
			helper.errorMessage('Something went wrong, warehouse not set');
		}
	}

	render() {
		const { t } = this.props.setOptions;
		const {
			selectSellType,
			selectShip,
			selectPaid,
			selectTime,
			selectSubscription,
			warehouseName,
			isAccountDisabled,
			isnotificationDisabled,
			isordernotificationDisabled,
			selectPrioritycolor,
			maxDate,
			paymentHeaders,
			paymentCsvData,
			paymentPieCard,
			totalRevenue,
			channelPayment,
			returnHandlingCost,
			returnPostageCost,
			savingNewDefaultWarehouse,
			warehouses,
			defaultWarehouse,
			isHybridAccount,
			hybridCommissions,
			internationalFees,
			paypal_billing,
			bankAccount,
			bankName,
			bankRouting,
			paypalEmail,
		} = this.state;

		console.log('---', selectPaid)

		return (
			<Col className='container-div' md={12}>
				{this.state.isLoading == true && (
					<div
						className='load'
						style={{
							backgroundColor: 'transparent',
							width: '85%',
							height: '50%',
						}}
					>
						<div className='load__icon-wrap'>
							<svg className='load__icon'>
								<path
									fill='#6c6f7f'
									d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
								/>
							</svg>
						</div>
					</div>
				)}

				{this.state.isLoading == false && (
					<Col id="user_managemant__table"  md={12}>
						<Card style={{ marginBottom: 15 }}>
							<CardBody>
								<Tabs>
									<TabList>
										<Tab>Add User</Tab>
										<Tab>SKU List</Tab>
										<Tab>Transaction History</Tab>
										<Tab>Order Notification</Tab>
										{/* <Tab>Advertising Fees</Tab> */}
										<Tab>Fulfillment Fees</Tab>
										<Tab>Payment</Tab>
										<Tab>Overrides</Tab>
										<Tab>Warehouse</Tab>
									</TabList>

									<TabPanel>
										<div className='card__title'>
											<h5 className='bold-text'>Add User</h5>
										</div>

										<div className='form__form-group-field left'>
											<span>
												<label>
													Name<span className='mantory'>*</span>
												</label>
												<input
													name='name'
													type='text'
													placeholder=''
													value={this.state.firstname}
													onChange={(event) => {
														this.setState({ firstname: event.target.value });
													}}
												/>
											</span>
										</div>

										<div className='form__form-group-field right'>
											<span>
												<label>
													Email<span className='mantory'>*</span>
												</label>
												<input
													name='name'
													type='email'
													placeholder=''
													value={this.state.email}
													onChange={(event) => {
														this.setState({ email: event.target.value });
													}}
												/>
											</span>
										</div>

										{this.state.loginType != 'facebook' && (
											<div className='form__form-group-field right'>
												<span>
													<label>
														Password<span className='mantory'>*</span>
													</label>
													<input
														name='name'
														type='password'
														placeholder=''
														value={this.state.password}
														onChange={(event) => {
															this.setState({ password: event.target.value });
														}}
													/>
												</span>
											</div>
										)}

										<div className='form__form-group-field right'>
											<span>
												<label>
													Role<span className='mantory'>*</span>
												</label>
												<Select
													value={this.state.role}
													onChange={this.handleroleChange}
													options={this.state.roleList}
												/>
											</span>
										</div>

										<div className='form__form-group-field right'>
											<span>
												<label>
													Company<span className='mantory'>*</span>
												</label>
												<input
													name='company'
													type='text'
													placeholder=''
													value={this.state.company}
													onChange={(event) => {
														this.setState({ company: event.target.value });
													}}
												/>
											</span>
										</div>
										{
											!isHybridAccount && (
												<div className='form__form-group-field right'>
													<span>
														<label>
															Commission (%)<span className='mantory'>*</span>
														</label>
														<input
															name='commission'
															type='number'
															placeholder=''
															value={this.state.comission}
															onChange={(event) => {
																this.setState({ comission: event.target.value });
															}}
														/>
													</span>
												</div>
											)
										}
{/* 
										<div className='form__form-group-field right'>
											<span>
												<label>
													Storage Fees<span className='mantory'>*</span>
												</label>
												<input
													name='comission'
													type='number'
													placeholder=''
													value={this.state.storageFees}
													onChange={(event) => {
														this.setState({ storageFees: event.target.value });
													}}
													min='0'
												/>
											</span>
										</div> */}

										<div className='form__form-group-field right'>
											<span>
												<label>
													Advertising Plan<span className='mantory'>*</span>
												</label>
												<input
													name='comission'
													type='number'
													placeholder=''
													value={this.state.advertisingPlan}
													onChange={(event) => {
														this.setState({
															advertisingPlan: event.target.value,
														});
													}}
													min='0'
												/>
											</span>
										</div>

										<div
											className='form__form-group-field profile_image'
											style={{ marginBottom: 10 }}
										>
											<div className='uploaddiv'>
												{this.state.companyLogo != '' && (
													<img className='' src={this.state.companyLogo} />
												)}
												<p>Company Logo</p>
												<input
													type='file'
													accept='image/*'
													onChange={(e: ChangeEvent<HTMLInputElement>) =>
														this.photoOnChange(e)}
												/>
											</div>
										</div>

										<div className='profile_select'>
											<div className='form-group col-md-12 '>
												<Label>
													Where Would You Like to Sell? (Multi Select)
												</Label>
												<Select
													value={selectSellType}
													onChange={this.handleCartChange}
													options={cartList}
													isMulti
												/>
											</div>
											<div className='form-group col-md-12'>
												<Label>How Will You Ship Orders?</Label>
												<Select
													value={selectShip}
													onChange={this.handleShipChange}
													options={shipList}
												/>
											</div>
											{/* {selectShip && selectShip.value == 'myself' && <div className="form__form-group-field warehouse_input">
                      <span>
                        <Label>Warehouse Name</Label>
                        <input name="shortdescription" type="text" placeholder="" value={warehouseName}
                          onChange={(event) => { this.setState({ warehouseName: event.target.value }) }} />
                      </span>
                    </div>} */}
											<div className='form-group col-md-12'>
												<Label>How Will You Get Paid?</Label>
												<Select
													value={selectPaid}
													onChange={this.handlePaidChange}
													options={paidList}
												/>
											</div>
											{
												selectPaid && selectPaid.value === 'paypal' && (
												<div className='form__form-group-field right'>
													<span>
														<label>
														Paypal email
														</label>
														<input
															name='name'
															type='email'
															placeholder=''
															value={paypalEmail}
															onChange={(event) => {
																this.setState({ paypalEmail: event.target.value })
															}}
														/>
													</span>
												</div>
												)
											}
											{
												selectPaid && selectPaid.value === 'ach' && (
													<div>
														<div className='form__form-group-field right'>
															<span>
																<label>
																Bank Account
																</label>
																<input
																	name='name'
																	type='email'
																	placeholder=''
																	value={bankAccount}
																	onChange={(event) => {
																		this.setState({ bankAccount: event.target.value })
																	}}
																/>
															</span>
														</div>
														<div className='form__form-group-field right'>
															<span>
																<label>
																Bank Routing
																</label>
																<input
																	name='name'
																	type='email'
																	placeholder=''
																	value={bankRouting}
																	onChange={(event) => {
																		this.setState({ bankRouting: event.target.value })
																	}}
																/>
															</span>
														</div>
														<div className='form__form-group-field right'>
															<span>
																<label>
																Bank Name
																</label>
																<input
																	name='name'
																	type='email'
																	placeholder=''
																	value={bankName}
																	onChange={(event) => {
																		this.setState({ bankName: event.target.value })
																	}}
																/>
															</span>
														</div>
													</div>
												)
											}
											<div className='form-group col-md-12'>
												<Label>Handling time</Label>
												<Select
													value={selectTime}
													onChange={this.handleTimeChange}
													options={timeList}
												/>
											</div>
											<div className='form-group col-md-12'>
												<Label>Skip Monthly Subscription Check</Label>
												<Select
													value={selectSubscription}
													onChange={this.handleSubscriptionChange}
													options={subscriptionList}
												/>
											</div>

											<div className='form-group col-md-12'>
												<Label>Priority Color</Label>
												<Select
													value={selectPrioritycolor}
													onChange={this.handlePrioritycolor}
													options={priorityColorList}
												/>
											</div>

											<div className='form-group col-md-12'>
												<Label>Subscription Plan</Label>
												<Select
													value={paypal_billing}
													onChange={this.handlePlanSelect}
													options={paypalPlanList.map((plan) => ({
														...plan,
														value: plan.id,
														label: plan.name,
													}))}
												/>
											</div>

											<div className='col-md-12 toggle_field'>
												<Label style={{ width: '100%' }}>
													Unblock / Block{' '}
												</Label>
												<Switch
													onChange={this.handleBlockChange}
													checked={!isAccountDisabled}
												/>
											</div>

											<div className='col-md-12 toggle_field'>
												<Label style={{ width: '100%' }}>
													Payment Notification{' '}
												</Label>
												<Switch
													onChange={this.handleEmailnotificationChange}
													checked={isnotificationDisabled}
												/>
											</div>

											<div className='col-md-12 toggle_field'>
												<Label style={{ width: '100%' }}>
													Order Notification{' '}
												</Label>
												<Switch
													onChange={this.handleOrdernotificationChange}
													checked={isordernotificationDisabled}
												/>
											</div>

											<div className='col-md-12 toggle_field'>
												<Label style={{ width: '100%' }}>
													Hybrid account{' '}
												</Label>
												<Switch
													onChange={() => {
														this.setState({ isHybridAccount: !isHybridAccount })
													}}
													checked={isHybridAccount}
												/>
											</div>
										</div>

										{/* <div className="form__form-group-field right">
              <span>
                <label>Company Logo</label>
                <input type="file" id="file-input" accept="image/*" name="file-input" />
              </span>
              <span className="upload_img_block">
                    <img src="" className="upload_img" alt=""  />
                    <i className="nav-icon icon-close upload_img_close"  ></i>
                </span>
          </div> */}

										<div className='form_footer'>
										{
											isHybridAccount && (
												<div style={{ display: 'flex' }}>
													{
														selectSellType.map(cart => {
															return (
																<div class="form__form-group-field">
																	<span>
																		<label>
																			{cart.label} commission (%)
																		</label>
																		<input
																			type="number"
																			name={cart.value.toLowerCase()}
																			value={hybridCommissions[cart.value.toLowerCase()]}
																			onChange={this.handleMarketPlaceHybridCommisions}
																			min="0"
																		/>
																	</span>
																</div>
															)
														})
													}
												</div>
											)
										}
											<Link to='/pages/user'>
												<button className='btn addbtn cancelbtn'>Cancel</button>
											</Link>
											{this.state.id == '' && (
												<button
													className='btn addbtn'
													onClick={() => {
														this.create();
													}}
												>
													Add
												</button>
											)}
											{this.state.id != '' && (
												<button
													className='btn addbtn'
													onClick={() => {
														this.create();
													}}
												>
													Update
												</button>
											)}
										</div>
									</TabPanel>
									<TabPanel>
										{this.props.match.params.id && (
											<div>
												<div className='search' style={{ marginLeft: 15 }}>
													<Input
														type='text'
														name='search'
														placeholder='Search for sku'
														value={this.state.searchText}
														onChange={(event) => {
															this.searchKey(event.target.value);
														}}
													/>
													<Button
														onClick={() => {
															this.actionSearch();
														}}
														style={{ padding: 0 }}
													>
														Search
													</Button>
												</div>
												<div className='card__title' style={{ marginTop: 0 }}>
													<h5 className='bold-text'>Sku List</h5>
												</div>
												<button
													className='btn addbtn'
													style={{ marginTop: 10 }}
													onClick={() => {
														this.updateProduct();
													}}
												>
													Sync Product Details
												</button>
												<button
													className='btn addbtn'
													style={{ marginTop: 10 }}
													onClick={() => {
														this.setState({ alertmodalIsOpen: true });
													}}
												>
													Delete All SKU
												</button>
												<div
													style={{ float: 'right', 'margin-top': '10px' }}
													className='step1'
												>
													<h5>
														<button className='btn addbtn'>
															Import Sku
															<CSVReader
																cssClass='csv-reader-input'
																onFileLoaded={this.handleForce}
																onError={this.handleDarkSideForce}
																parserOptions={papaparseOptions}
																inputId='ObiWan'
																inputStyle={{ color: 'red' }}
															/>
														</button>
													</h5>
												</div>
												<TableList
													normal={this.state.normal}
													pageSize={10}
													tableLoading={this.state.tableLoading}
													deleteAction={this.deleteAction}
													totalPage={this.state.totalPage}
													getData={this.getData}
													items={this.state.items}
													headers={this.state.headers}
													name={this.state.name}
													className='myprodList'
													filename="SKU-List"
												/>
											</div>
										)}
									</TabPanel>

									<TabPanel>
										{this.props.match.params.id && (
											<div>
												<div className='card__title'>
													<h5 className='bold-text'>Transaction History</h5>
												</div>
												<TableList
													normal={true}
													items={this.state.transactionItems}
													headers={this.state.transactionHeaders}
													name={this.state.name}
													className='myprodList'
													filename="Transaction-History"
												/>
											</div>
										)}
									</TabPanel>

									<TabPanel>
										<button
											className='btn addbtn'
											style={{ marginTop: 10 }}
											onClick={() => {
												this.setState({
													emailmodalIsOpen: true,
													orderEmail: '',
												});
											}}
										>
											Add
										</button>
										<TableList
											normal={true}
											items={this.state.orderNotificationList}
											deleteAction={this.deleteOrderAction}
											headers={this.state.ordernotificationHeader}
											name={this.state.name}
											className='myprodList'
											filename="Order-Notification"
										/>
									</TabPanel>

									{/* <TabPanel>
                  <div className="card__title" style={{ marginTop: 40 }}>
                    <h5 className="bold-text">Advertising Fees</h5>
                  </div>
                  <button className="btn addbtn" style={{ marginTop: 10 }} onClick={() => { this.showAdvertismentModel() }}>Add</button>
                  <TableList normal={this.state.normal} editPaymentAction={this.editAdvertisment} tableLoading={this.state.tableLoading} deleteAction={this.deleteAdvertisment} headers={this.state.advertisingfeesHeader} name={this.state.name} className="myprodList" items={this.state.advertismentItems} totalPage={this.state.totalPage} getData={this.getAdvertising} />
                </TabPanel> */}

									<TabPanel>
										<div className='card__title' style={{ marginTop: 40 }}>
											<h5 className='bold-text'>Fulfillment Fees</h5>
										</div>
										<button
											className='btn addbtn'
											style={{ marginTop: 10 }}
											onClick={() => {
												this.showFullFillmentModel();
											}}
										>
											Add
										</button>
										<TableList
											normal={this.state.normal}
											editPaymentAction={this.editFulfillment}
											tableLoading={this.state.tableLoading}
											deleteAction={this.deleteFullFillment}
											headers={this.state.fulfillmentfeesHeader}
											name={this.state.name}
											className='myprodList'
											items={this.state.fullFillmentItems}
											totalPage={this.state.totalPage}
											getData={this.getFullFillmentService}
											filename="Fulfillment-Fees"
										/>
									</TabPanel>

									<TabPanel>
										<div className='card__title' style={{ marginTop: 40 }}>
											<h5 className='bold-text'>Payment</h5>
										</div>
										<button
											className='btn addbtn'
											style={{ marginTop: 10 }}
											onClick={() => {
												this.showPayment();
											}}
										>
											Add
										</button>
										<TableList
											normal={this.state.normal}
											editPaymentAction={this.editPayment}
											tableLoading={this.state.tableLoading}
											deleteAction={this.deletePaymentPage}
											headers={this.state.paymentfeesHeader}
											name={this.state.name}
											className='myprodList'
											items={this.state.paymentPageItems}
											totalPage={this.state.totalPage}
											getData={this.getPaymentPage}
											filename="Payment"
										/>
									</TabPanel>

									<TabPanel>
										<div className='card__title' style={{ marginTop: 40 }}>
											<h5 className='bold-text'>Overrides</h5>
										</div>
										{this.state.overrides.length === 0 && <button
											className='btn addbtn'
											style={{ marginTop: 10 }}
											onClick={this.toggleOverride
											}
										>
											Add
										</button> }
										<TableList
											normal={this.state.normal}
											editVoidAction={this.editOverride}
											tableLoading={this.state.tableLoading}
											deleteVoidAction={this.deleteOverride}
											headers={this.state.overridesHeaders}
											name={this.state.name}
											items={this.state.overrides || []}
											totalPage={1}
											getData={this.getOverrides}
											filename="Overrides-User-Management"
										/>
									</TabPanel>
									<TabPanel>
										<lable>Default warehouse</lable>
										<div style={{ display: 'flex' }}>
											<div style={{ width: '50%' }}>
												<Select
													options={warehouses.map((warehouse) => ({
														label: warehouse.Name,
														value: warehouse.Id,
													}))}
													value={defaultWarehouse}
													isSearchable={true}
													isClearable={true}
													placeholder="select warehouse"
													onChange={(value) => this.setState({ defaultWarehouse: value })}
													style={{ width: '100%' }}
												/>
											</div>
											<button
												className='btn addbtn'
												onClick={this.setDefaultWarehouse}
												disabled={savingNewDefaultWarehouse}
											>
												{ savingNewDefaultWarehouse ? 'Saving...' : 'Save' }
											</button>
										</div>
									</TabPanel>
								</Tabs>
							</CardBody>
						</Card>
					</Col>
				)}

				<Modal
					isOpen={this.state.editpricemodalIsOpen}
					onRequestClose={this.closeEmailModal}
					style={deleteCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						Edit{' '}
						<a
							className='closemodal'
							onClick={() => {
								this.setState({ editpricemodalIsOpen: false });
							}}
						>
							X
						</a>
					</h2>

					<div className='agreement'>
						<input
							type='number'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.editPrice}
							onChange={(event) => {
								this.setState({ editPrice: event.target.value });
							}}
						/>
					</div>
					<button className='btn addbtn update-btn' style={{ marginBottom: 0 }}>
						Save
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.setState({ editpricemodalIsOpen: false });
						}}
					>
						Cancel
					</button>
				</Modal>

				<Modal
					isOpen={this.state.emailmodalIsOpen}
					onRequestClose={this.closeEmailModal}
					style={deleteCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						Email{' '}
						<a
							className='closemodal'
							onClick={() => {
								this.setState({ emailmodalIsOpen: false });
							}}
						>
							X
						</a>
					</h2>

					<div className='agreement'>
						<input
							type='email'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.orderEmail}
							onChange={(event) => {
								this.setState({ orderEmail: event.target.value });
							}}
						/>
					</div>
					<button
						className='btn addbtn update-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.addOrderEmail();
						}}
					>
						Save
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.setState({ emailmodalIsOpen: false });
						}}
					>
						Cancel
					</button>
				</Modal>
				<Modal
					isOpen={this.state.advertismentfeesmodalIsOpen}
					onRequestClose={this.closeadvertismentfeesModal}
					style={feesCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						{' '}
						Add Advertisment Fees<a
							className='closemodal'
							onClick={() => {
								this.setState({ advertismentfeesmodalIsOpen: false });
							}}
						>
							X
						</a>
					</h2>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label style={{ width: '100%' }}>Month/Year</label>
						<DatePicker
							style={{ width: '100%' }}
							showMonthYearPicker
							dateFormat='MM/yyyy'
							className='form-control'
							selected={this.state.monthYear}
							onChange={(date) => this.handleChangeDate(date)}
							maxDate={new Date()}
						/>
						{/* <DatePicker onChange={this.onChangeDob} value={this.state.dateBirth} maxDate={maxDate} showMonthYearPicker /> */}
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>MarketPlace Advertising</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.marketPlaceAmount}
							onChange={(event) => {
								this.setState({ marketPlaceAmount: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Social Advertising</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.socialAmount}
							onChange={(event) => {
								this.setState({ socialAmount: event.target.value });
							}}
						/>
					</div>
					<button
						className='btn addbtn update-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.addAdvertisment();
						}}
					>
						Save
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.setState({
								advertismentfeesmodalIsOpen: false,
								marketPlaceAmount: '',
								socialAmount: '',
								monthYear: '',
							});
						}}
					>
						Cancel
					</button>
				</Modal>
				<Modal
					isOpen={this.state.fulfillmentmodalIsOpen}
					onRequestClose={this.closeadvertismentfeesModal}
					style={feessCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						{' '}
						Add Fulfillment Fees<a
							className='closemodal'
							onClick={() => {
								this.setState({ fulfillmentmodalIsOpen: false });
							}}
						>
							X
						</a>
					</h2>

					<div
						className='agreement agreementss'
						style={{
							marginTop: 20,
							marginBottom: 20,
							padding: 10,
							width: '100%',
						}}
					>
						<label style={{ width: '100%' }}>Month/Year</label>
						<DatePicker
							style={{ width: 200 }}
							showMonthYearPicker
							dateFormat='MM/yyyy'
							className='form-control'
							selected={this.state.monthYearFullFillMent}
							onChange={(date) => this.handleChangeFullFillMentDate(date)}
							maxDate={new Date()}
						/>
						{/* <DatePicker onChange={this.onChangeDob} value={this.state.dateBirth} maxDate={maxDate} showMonthYearPicker /> */}
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Storage Space Used</label>
						<input
							className='form-control'
							name='comission'
							type='number'
							placeholder=''
							value={this.state.storageSpaceUsed}
							onChange={(event) => {
								this.setState({ storageSpaceUsed: event.target.value });
							}}
							min='0'
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Storage Fees</label>
						<input
							className='form-control'
							name='comission'
							type='number'
							placeholder=''
							value={this.state.storageFees}
							onChange={(event) => {
								this.setState({ storageFees: event.target.value });
							}}
							min='0'
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Postage</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.postage}
							onChange={(event) => {
								this.setState({ postage: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Receiving</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.receiving}
							onChange={(event) => {
								this.setState({ receiving: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Barcode Prep</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.barcode}
							onChange={(event) => {
								this.setState({ barcode: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Bundling/Kitting</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.kitting}
							onChange={(event) => {
								this.setState({ kitting: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Packaging Prep</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.packaging}
							onChange={(event) => {
								this.setState({ packaging: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Handling</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.handling}
							onChange={(event) => {
								this.setState({ handling: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Pallet Prep</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.pallet}
							onChange={(event) => {
								this.setState({ pallet: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Freight</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.freight}
							onChange={(event) => {
								this.setState({ freight: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Partner 3PL Fulfillment Fees</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.partner}
							onChange={(event) => {
								this.setState({ partner: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Other</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.other}
							onChange={(event) => {
								this.setState({ other: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Return Postage Cost</label>
						<input
							className='form-control'
							name='returnPostageCost'
							type='number'
							placeholder=''
							min='0'
							value={returnPostageCost}
							onChange={(event) => {
								this.setState({ returnPostageCost: event.target.value })
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Return Handling Fee</label>
						<input
							className='form-control'
							name='returnHandlingCost'
							type='number'
							placeholder=''
							min='0'
							value={returnHandlingCost}
							onChange={(event) => {
								this.setState({ returnHandlingCost: event.target.value })
							}}
						/>
					</div>
					<div
						className='agreement agreement_split'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>International Fees</label>
						<input
							className='form-control'
							name='internationalFees'
							type='number'
							placeholder=''
							min='0'
							value={internationalFees}
							onChange={(event) => {
								this.setState({ internationalFees: event.target.value })
							}}
						/>
					</div>
					<button
						className='btn addbtn update-btn'
						style={{ marginBottom: 0, marginTop: 20 }}
						onClick={() => {
							this.addFulfillment();
						}}
					>
						Save
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0, marginTop: 20 }}
						onClick={() => {
							this.setState({
								fulfillmentmodalIsOpen: false,
								advertismentAmount: 0,
								monthYear: '',
							});
						}}
					>
						Cancel
					</button>
				</Modal>

				<Modal
					isOpen={this.state.paymentmodalIsOpen}
					onRequestClose={this.closePaymentfeesModal}
					style={feesCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						{' '}
						Add Payment Fees<a
							className='closemodal'
							onClick={() => {
								this.setState({ paymentmodalIsOpen: false });
							}}
						>
							X
						</a>
					</h2>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label style={{ width: '100%' }}>Month/Year</label>
						<DatePicker
							style={{ width: 200 }}
							showMonthYearPicker
							dateFormat='MM/yyyy'
							className='form-control'
							selected={this.state.monthYearPayment}
							onChange={(date) => this.handleChangePaymentDate(date)}
							maxDate={new Date()}
						/>
						{/* <DatePicker onChange={this.onChangeDob} value={this.state.dateBirth} maxDate={maxDate} showMonthYearPicker /> */}
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Amazon</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.amazon}
							onChange={(event) => {
								this.setState({ amazon: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>ebay</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.ebay}
							onChange={(event) => {
								this.setState({ ebay: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Walmart</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.walmart}
							onChange={(event) => {
								this.setState({ walmart: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Other</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.other}
							onChange={(event) => {
								this.setState({ other: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Total Revenue</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.totalRevenue}
							onChange={(event) => {
								this.setState({ totalRevenue: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Total Expenses</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.totalExpenses}
							onChange={(event) => {
								this.setState({ totalExpenses: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Returns</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.totalReturns}
							onChange={(event) => {
								this.setState({ totalReturns: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Promo Fees</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.promoFees}
							onChange={(event) => {
								this.setState({ promoFees: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Marketplace Commission Percentage</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.marketPlaceCommissionPercentage}
							onChange={(event) => {
								this.setState({ marketPlaceCommissionPercentage: event.target.value });
							}}
						/>
					</div>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Other (Expense Breakdown)</label>
						<input
							type='number'
							min='1'
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.expenseBreakDownOther}
							onChange={(event) => {
								this.setState({ expenseBreakDownOther: event.target.value });
							}}
						/>
					</div>
					<button
						className='btn addbtn update-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.addPayment();
						}}
					>
						Save
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.setState({
								paymentmodalIsOpen: false,
								advertismentAmount: 0,
								monthYear: '',
							});
						}}
					>
						Cancel
					</button>
				</Modal>

				<Modal
					isOpen={this.state.alertmodalIsOpen}
					onRequestClose={this.closealertModal}
					style={alertcustomStyles}
					contentLabel='Delete Modal'
				>
					<h2 ref={(subtitle) => (this.subtitle = subtitle)}>
						Delete{' '}
						<a className='closemodal' onClick={this.closealertModal}>
							X
						</a>
					</h2>

					<div className='agreement'>
						<span>
							<b>Are you sure you want to delete?</b>
						</span>
					</div>
					<button
						className='btn addbtn update-btn'
						style={{ marginBottom: 0 }}
						onClick={() => {
							this.deleteAllSku();
						}}
					>
						Yes
					</button>
					<button
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={this.closealertModal}
					>
						No
					</button>
				</Modal>

				<Modal
					isOpen={this.state.walmartmodalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closewalmartModal}
					style={deleteCustomStyles}
					contentLabel='Example Modal'
				>
					<h2 ref={(subtitle) => (this.subtitle = subtitle)}>
						{' '}
						Business day<a
							className='closemodal'
							onClick={this.closewalmartModal}
						>
							X
						</a>
					</h2>

					<div className='agreement'>
						<p style={{ marginTop: 10, fontSize: 16 }}>
							Walmart's default handling time is 1 business day only
						</p>
					</div>
				</Modal>


				<Modal
					isOpen={this.state.showOverride}
					onRequestClose={this.toggleOverride}
					style={feesCustomStyles}
				>
					<h2
						ref={(subtitle) => (this.subtitle = subtitle)}
						className='emailh2'
					>
						{' '}
						Overrides fields<a
						className='closemodal'
						onClick={this.toggleOverride}
					>
						X
					</a>
					</h2>
					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>UTM Medium</label>
						<input
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.utm_medium}
							onChange={(event) => {
								this.handleChangeOverride('utm_medium', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>UTM Term</label>
						<input
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.utm_term}
							onChange={(event) => {
								this.handleChangeOverride('utm_term', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>UTM Source</label>
						<input
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.utm_source}
							onChange={(event) => {
								this.handleChangeOverride('utm_source', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>UTM Campaign</label>
						<input
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.utm_campaign}
							onChange={(event) => {
								this.handleChangeOverride('utm_campaign', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>UTM Content</label>
						<input
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.utm_content}
							onChange={(event) => {
								this.handleChangeOverride('utm_content', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Number of SKU's</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.number_sku}
							onChange={(event) => {
								this.handleChangeOverride('number_sku', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Total Sales</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.sales}
							onChange={(event) => {
								this.handleChangeOverride('sales', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Total Commission</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.totalComission}
							onChange={(event) => {
								this.handleChangeOverride('totalComission', event.target.value)
							}}
						/>

					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Register Date</label>
						<DatePicker
							style={{ width: 200 }}
							className='form-control'
							selected={this.state.overrideForm.register_date ?  moment(this.state.overrideForm.register_date, 'MM/DD/YYYY').toDate() : undefined}
							onChange={(date) =>
								this.handleChangeOverride('register_date', helper.getDateFormat(date))
							}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Subscription Date</label>
						<DatePicker
							style={{ width: 200 }}
							className='form-control'
							selected={this.state.overrideForm.subscriber_date ?  moment(this.state.overrideForm.subscriber_date, 'MM/DD/YYYY').toDate() : undefined}
							onChange={(date) => this.handleChangeOverride('subscriber_date', helper.getDateFormat(date))
							}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Status (Free/Paid)</label>
						<Select
							value={
								this.state.overrideForm.status && !this.state.overrideForm.status.value ? {
								value: this.state.overrideForm.status,
								label: this.state.overrideForm.status
							} : this.state.overrideForm.status}
							onChange={(val) => this.handleChangeOverride('status', val)}
							options={[
								{value: 'Paid', label: 'Paid '},
								{value: 'Free', label: 'Free '},
							]}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Total Paid</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.totalPaid}
							onChange={(event) => {
								this.handleChangeOverride('totalPaid', event.target.value)
							}}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Paid Plan Value</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.planValue}
							onChange={(event) => {
								this.handleChangeOverride('planValue', event.target.value)
							}}
						/>
					</div>


					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Marketing Plan (Y/N)</label>
						<Select
							value={this.state.overrideForm.marketingPlan && !this.state.overrideForm.marketingPlan.value ?  {
								value: this.state.overrideForm.marketingPlan,
								label: this.state.overrideForm.marketingPlan
							} : this.state.overrideForm.marketingPlan}
							onChange={(val) => this.handleChangeOverride('marketingPlan', val)}
							options={[
								{ value: 'Y', label: 'Y'},
								{ value: 'N', label: 'N '},
							]}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Marketing Plan Value</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.marketingPlanValue}
							onChange={(event) => {
								this.handleChangeOverride('marketingPlanValue', event.target.value)
							}}
						/>
					</div>


					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Logistics Plan (Y/N)</label>
						<Select
							value={this.state.overrideForm.logisticsPlan && !this.state.overrideForm.logisticsPlan.value ?  {
								value: this.state.overrideForm.logisticsPlan,
								label: this.state.overrideForm.logisticsPlan
							} : this.state.overrideForm.logisticsPlan}
							onChange={(val) => this.handleChangeOverride('logisticsPlan', val)}
							options={[
								{ value: 'Y', label: 'Y'},
								{ value: 'N', label: 'N '},
							]}
						/>
					</div>

					<div
						className='agreement'
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<label>Logistics Plan Value

						</label>
						<input
							type="number"
							min="0"
							className='form-control'
							style={{
								width: '100%',
								padding: 8,
								marginBottom: 10,
								marginTop: 8,
							}}
							value={this.state.overrideForm.logisticsPlanValue}
							onChange={(event) => {
								this.handleChangeOverride('logisticsPlanValue', event.target.value)
							}}
						/>
					</div>

					<button
						disabled={this.state.loadingOverride}
						className='btn addbtn update-btn'
						style={{ marginBottom: 0 }}
						onClick={this.submitOverrides}
					>
						Save
					</button>
					<button
						disabled={this.state.loadingOverride}
						className='btn addbtn disconnect-btn'
						style={{ marginBottom: 0 }}
						onClick={this.toggleOverride}
					>
						Cancel
					</button>
				</Modal>
			</Col>
		);
	}
}

const mapStateToProps = ({ curdReducer, roleReducer }) => {
	const { list_role } = roleReducer;
	return { list_role };
};

export default connect(mapStateToProps, actions)(AddusermanagementCard);
