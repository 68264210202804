import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';

class ForgotpasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      isLoading:false,
      error: '',
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  resetPassword = () => {
      const { email } = this.state;
      if(email==''){
        helper.errorMessage('Email field is required');
      }else{
          let that =this;
          this.setState({isLoading:true})
          var auth = firebase1.app.auth();
          auth.sendPasswordResetEmail(email).then(function(data) {
            // Email sent.
            helper.successMessage('Mail sent sucessfully');
            that.setState({isLoading:false,email:''})
            history.push('/login')
          }).catch(function(error) {
            console.log(error);
            helper.errorMessage(error.message);
            that.setState({isLoading:false})
            // An error happened.
          });
      }

  }

  render() {
    const { handleSubmit } = this.props;

    if(this.state.isLoading){
      return(
        <div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )
    }else{
      return (
        <form className="form" onSubmit={handleSubmit}>
  
  
          <div className="form__form-group">
            <div className="form__form-group-field" style={{width:'100%'}}>
              <Field
                name="email"
                component="input"
                type="text"
                placeholder="Email"
                value={this.state.email}  onChange={(event) => {this.setState({ email: event.target.value })}}
              />
            </div>
          </div>
          <a onClick={() => { this.resetPassword() }} className="btn btn-primary signupbtns_active account__btn--small"><span style={{color:'#fff'}}>Reset Password</span></a>
  
          {/* <span className="btn btn-primary signupbtns_active account__btn--small">Send request</span> */}
        </form>
      );
    }
   
  }
}

export default reduxForm({
  form: 'forgot_password_form',
})(ForgotpasswordForm);
