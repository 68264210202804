import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ShippingCard from './components/ShippingCard';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Shipping extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:''
    }
  }

  render() {
    const {t} = this.props.setOptions
    return (
      <div>
      <Container className="dashboard">
        <Row>
           <ShippingCard {...this.props}/>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Shipping);
