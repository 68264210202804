import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import authReducer from './authReducer';
import curdReducer from './curdReducer';
import roleReducer from './roleReducer';
import paypalReducer from "./paypalReducer";

export default combineReducers({
    form: reduxFormReducer, // mounted under "form",
    theme: themeReducer,
    authReducer:authReducer,
    curdReducer:curdReducer,
    sidebar: sidebarReducer,
    roleReducer:roleReducer,
    paypalReducer
});
