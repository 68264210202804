import { PayPalButton } from 'react-paypal-button-v2';
import React from 'react';

export function PayPalBtn(props) {
	const {
		amount,
		currency,
		onCreateOrder,
		onSuccess,
		catchError,
		onError,
		onCancel,
	} = props;
	const paypalKey = process.env.REACT_APP_PAYPAL_PAY_CLIENT;

	return (
		<PayPalButton
			amount={amount}
			shippingPreference='NO_SHIPPING'
			createOrder={(data, actions) => onCreateOrder(data, actions)}
			onSuccess={(data, details) => onSuccess(data, details)}
			onError={(err) => onError(err)}
			catchError={(err) => catchError(err)}
			onCancel={(err) => onCancel(err)}
			options={{
				clientId: paypalKey,
				vault: true,
				disableFunding: 'credit',
			}}
			style={{
				label: 'pay',
				layout: 'vertical',
			}}
		/>
	);
}

export default PayPalBtn;
