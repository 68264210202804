import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Button,
	Input,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import api from '../../../utils/api';
import TableList from '../../Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import firebase1 from '../../../utils/config';

const discountList = [
	{ value: '20', label: '20%' },
	{ value: '25', label: '25%' },
	{ value: '30', label: '30%' },
	{ value: '35', label: '35%' },
	{ value: '40', label: '40%' },
	{ value: '45', label: '45%' },
	{ value: '50', label: '50%' },
	{ value: '55', label: '55%' },
	{ value: '60', label: '60%' },
	{ value: '65', label: '65%' },
	{ value: '70', label: '70%' },
	{ value: '-1', label: "Don't Participate in Discounts" },
];

const planList = [
	{
		value: 'enterprise',
		label: 'Enterprise Marketing: $10,000/mo',
		price_id: 'price_1HTgGpDlZZqatNiCh1ZD50oS',
		product_id: 'prod_I3nsHICkfEbV0q',
		amount: 10000,
		price: '$10,000.00',
	},
	{
		value: 'premium',
		label: 'Premium Marketing: $1,000/mo',
		price_id: 'price_1HTgGLDlZZqatNiCO6kAx7pq',
		product_id: 'prod_I3nsOw5Nh5MHB0',
		amount: 1000,
		price: '$1,000.00',
	},
	{
		value: 'basic',
		label: 'Growth Marketing: $500/mo',
		price_id: 'price_1HTgFTDlZZqatNiCdSj4KWab',
		product_id: 'prod_I3nri6WFM56pZf',
		amount: 500,
		price: '$500.00',
	},
	{
		value: 'starter',
		label: 'Starter Marketing: $250/mo',
		price_id: 'price_1HTgEwDlZZqatNiCcA1ga5sn',
		product_id: 'prod_I3nqq68o4wFgDe',
		amount: 250,
		price: '$250.00',
	},
];

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '50%',
		height: '390px',
		textAlign: 'center',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

class Discount extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			selectDiscount: null,
			selectPlan: planList[0],
			adPrice: 500,
			isAdBilled: false,
		};
	}

	componentDidMount() {
		//  console.log('user=====',this.props.userdetails);
		const { user, userdetails } = this.state;
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
		}
		//console.log('this.props.userdetails', this.props.userdetails)
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	updateUser(user) {
		//console.log('user==2323232332323=', user);
		let stripeCustomerId = '';
		let professionalBrand = false;

		if (user.billing && user.billing.stripe_customer_id) {
			stripeCustomerId = user.billing.stripe_customer_id;
		}
		if (user.professional_brand) {
			professionalBrand = user.professional_brand.active;
		}
		this.setState(
			{
				isLoading: false,
				key: user.key,
				firstname: user.firstname,
				company: user.company,
				email: user.email,
				role: user.role.label,
				Uid: user.uid,
				stripeCustomerId: stripeCustomerId,
				professionalBrand: professionalBrand,
				adPrice: user.adPrice ? user.adPrice : 500,
				isAdBilled: user.isAdBilled != undefined ? user.isAdBilled : false,
				selectDiscount: user.discount ? user.discount : null,
				selectPlan: user.advertising_plan
					? user.advertising_plan
					: this.state.selectPlan,
			},
			() => {
				if (user.role.label == 'admin') {
					this.getUser();
					this.getPurchaseSku(1, this.state.pageSize);
				}
			},
		);
	}

	async getUser() {
		let apiPath = '';

		apiPath = 'user/marketuserlist';
		let reqData = {
			Pagination: {
				ResultsPerPage: 25,
				PageNumber: 1,
			},
		};
		this.setState({ isLoading: true });
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				for (let item in items) {
					if (items[item].discount) {
						items[item].discount = Number(items[item].discount.value);
						if (items[item].discount == -1) {
							items[item].discount = "Don't Participate in Discounts";
						}
					} else {
						items[item].discount = 0;
					}
				}
				this.setState({ isLoading: false, items: items, tempItems: items });
			}
		});
	}

	async advertisingBilling() {
		const { stripeCustomerId, selectPlan } = this.state;
		console.log('selectPlan===', selectPlan);
		if (selectPlan == null) {
			helper.errorMessage('please select plan');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			let amount = selectPlan.amount * 100;
			let req = {
				'items[0][price_data][currency]': 'usd',
				'items[0][price_data][product]': selectPlan.product_id,
				'items[0][price_data][unit_amount]': amount,
				'items[0][price_data][recurring][interval]': 'month',
				customer: stripeCustomerId,
			};
			console.log(req);
			api.stripePost('subscriptions', req).then((cutomerData) => {
				if (cutomerData.error) {
					console.log(cutomerData);
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					this.setState({ isLoading: false, isAdBilled: true });
					this.openModal();
					this.sendMail();
					this.props.updateRecord(
						'user',
						{ isAdBilled: true, advertising_plan: selectPlan },
						this.state.key,
						false,
					);
				}
			});
		}
		this.sendMail();
	}

	sendMail() {
		let reqData = {
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			plan: this.state.selectPlan,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addadvertising', reqData).then((data) => {});
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	async updateDiscount() {
		const { selectDiscount, firstname, company, email } = this.state;
		if (selectDiscount == null) {
			helper.errorMessage('please select discount');
		} else {
			this.setState({ isLoading: true });
			await this.props.updateRecord(
				'user',
				{ discount: selectDiscount },
				this.state.key,
				false,
			);
			let apiPath = '';
			apiPath = 'mail/onDiscountDetails';
			let reqData = {
				firstname: firstname,
				company: company,
				email: email,
				discount: selectDiscount.label,
			};

			api.postApi(apiPath, reqData).then((data) => {
				console.log(data);
				if (data.status == 'Success') {
				}
				helper.successMessage('Discount updated');
				this.setState({ isLoading: false });
			});
		}
	}

	handleDiscountChange = (selectDiscount) => {
		this.setState({ selectDiscount });
	};

	handlePlanChange = (selectPlan) => {
		this.setState({ selectPlan });
	};

	viewData = () => {
		window.open('http://www.valetseller.com/advertising-packages/');
	};

	render() {
		const { t } = this.props.setOptions;
		const {
			selectDiscount,
			selectPlan,
			isLoading,
			role,
			totalPrice,
			professionalBrand,
			isAdBilled,
		} = this.state;

		if (isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<Row>
					<Row className='ads-market-main'>
						<Col md={12}>
							<Link to='/pages/marketing'>
								<button className='back_products'>Back</button>
							</Link>
						</Col>
						<Col className='ads-market-div'>
							<Col md={6}>
								<h2>Set Maximum Discounts</h2>
								<p>
									Valet Seller will periodically run promotional events with
									Coupon Codes, Credits, and Discounts.
								</p>
								<p>
									How it works: If you set your maximum discount to 20%, your
									products will be opted-in to be discounted at a level up to
									20%. You can change your setting at any time.
								</p>
								<div className='form-group discount_level'>
									<Label>Discount Level</Label>
									<Select
										value={selectDiscount}
										onChange={this.handleDiscountChange}
										options={discountList}
									/>
								</div>
								<button
									onClick={() => {
										this.updateDiscount();
									}}
								>
									Opt-In to Discounts
								</button>
							</Col>

							<Row className='ads-market-main' style={{ display: 'none' }}>
								<Col className='ads-market-div'>
									<Col md={6}>
										<h2>Pricing</h2>
										<p>$100 per SKU</p>
									</Col>
									{/* <Col md={6}>
                                <img src="../img/market1.png" />
                              </Col> */}
								</Col>
							</Row>

							<Col md={6}>
								<img src='../img/market7.png' style={{ marginTop: 55 }} />
							</Col>
						</Col>
					</Row>

					<Modal
						isOpen={this.state.modalIsOpen}
						onAfterOpen={this.afterOpenModal}
						onRequestClose={this.closeModal}
						style={customStyles}
						contentLabel='Example Modal'
					>
						<span className='closepurchase' onClick={this.closeModal}>
							X
						</span>
						<img
							src='../img/favlogo.png'
							style={{ width: 80, marginBottom: 20 }}
						/>
						<h2>Thank you for your purchase!</h2>
						{/* <p className="purchase_p">
              Thank you for your purchase! Your listing information has been sent to our copywriting team. Please allow 5-7 days for your listing optimizations to be delivered
            </p> */}
						<p className='purchase_p'>
							If you have any questions, please email us at
							support@valetseller.com
						</p>

						<button
							className='btn addbtn return_app'
							onClick={() => {
								this.closeModal();
							}}
						>
							Return to App
						</button>
					</Modal>
				</Row>
			);
		}
	}
}
const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(Discount);
