import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import HelpRequestCard from './components/HelpRequestCard';


import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Helprequest extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchText: ''
    }
  }

  render() {
    const { t } = this.props.setOptions
    return (
      <div>
        <Container className="dashboard">
          <Row>
            <Col md={12}>

            </Col>
          </Row>
          <Row>
            <HelpRequestCard {...this.props} />

          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Helprequest);
