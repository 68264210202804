import React, { PureComponent, Fragment } from 'react';
import api from '../../../utils/api';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';
import { Card, CardBody, Col } from 'reactstrap';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Tour from "react-product-intro";
import TopbarProfile from "../../Layout/topbar/TopbarProfile";
import {
  GLOBAL_LISTING_ATTRIBUTES, HELP, WAREHOUSE, ADD_BUTTON, CANCEL_BUTTON
} from '../../Walkthrough/popupText';
import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser'
import getCroppedImg from './cropImage'
import { getRotatedImage, getImageSize } from './rotateImage'

import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



import htmlToDraft from 'html-to-draftjs';
// import {Slider} from '@material-ui/lab/Slider';
// import 
//   popupText
// from '../../Walkthrough';

const IsCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '-28%',
    bottom: '-37%',
    width: '380px',
    height: '450px',
    transform: 'translate(-50%, -50%)',
    zindex: '9999',
    overflow: 'hidden'
  }
};

const editImageStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '-28%',
    bottom: '-37%',
    width: '680px',
    height: '480px',
    transform: 'translate(-50%, -50%)',
    zindex: '9999',
    overflow: 'hidden'
  }
};

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

class AddproductsCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      searchText: '',
      isUpdate: false,
      Id: '',
      key: '',
      userCompany: '',
      CompanyId: '',
      productStatus: null,
      brandStatus: null,
      brandStatusType: [
        { value: 'your_brandname', label: 'Use Your Brand Name' },
        { value: 'our_brandname', label: 'Use Our Brand Name' }
      ],
      productType: [
        { value: 'Product', label: 'Product' },
        { value: 'Kit', label: 'Kit' },
        { value: 'Built', label: 'Built' }
      ],
      statusType: [
        { value: 'pending_reviewal', label: 'Pending Reviewal' },
        { value: 'under_review', label: 'Under Review' },
        { value: 'published', label: 'Published' }
      ],
      globalType: [
        { value: 'New', label: 'New' },
        { value: 'Used', label: 'Used' },
      ],
      mimeType: [
        { value: 'image/gif', label: 'image/gif' },
        { value: 'image/png', label: 'image/png' },
        { value: 'image/jpeg', label: 'image/jpeg' }
      ],
      Sku: '',
      Name: '',
      BrandName: '',
      Type: null,
      TypeValue: null,
      QuantityOnHand: 0,
      Identifiers: {
        UPC: '',
        EAN: '',
        ISBN: '',
        GTIN: ''
      },
      IsAutoSyncEnabled: true,
      IsDiscontinued: false,
      IsDropshipped: false,
      IsFireArm: false,
      IsVariation: false,
      IsParent: false,
      IsColor: false,
      IsSize: false,
      IsQuantity: false,
      IsCustom: false,
      sizeItems: [],
      BuyPrice: 0,
      editorState: EditorState.createEmpty(),
      GlobalListingAttributes: {
        SoldAsName: '',
        ShortDescription: '',
        LongDescription: '',
        Price: '',
        Condition: null,
        value: null,
        GlobalValue: null
      },
      Images: [],
      skuList: [],
      Warehouses: [],
      SupplierInformation: {
        SupplierId: '',
        SKU: '',
        Price: ''
      },
      tempItems: [],
      isTourActive: false,
      tourStep: 1,
      Uid: '',
      modalIsOpen: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 3 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      imageIndex: -1,
      productVariations: [],
      colorItems: [],
      displayColorPicker: false,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.editImageModal = this.editImageModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    //console.log('this.props.match.params.id==', this.props.match.params.id)


    if (this.props.userdetails) {
      this.updateUser(this.props.userdetails);
      if (!this.props.userdetails.walkthroughDone) {
        //console.log("+++++++++++++++++++++++++++++", this.props.userdetails.walkthroughDone)
        let dashboardSeen = typeof localStorage.dashboardSeen != "undefined" ? localStorage.dashboardSeen : false;
        let productSeen = typeof localStorage.productSeen != "undefined" ? localStorage.productSeen : false;
        let addProductSeen = typeof localStorage.addProductSeen != "undefined" ? localStorage.addProductSeen : false;
        let inventorySeen = typeof localStorage.inventorySeen != "undefined" ? localStorage.inventorySeen : false;
        let topRightMenuSeen = typeof localStorage.topRightMenuSeen != "undefined" ? localStorage.topRightMenuSeen : false;

        //check the currentRoute
        //currentTab	
        switch ("addProduct") {
          case "dashboard":
            if (!dashboardSeen) {
              //show walkthrough as per current page
              this.setState({
                isTourActive: true
              });
              localStorage.dashboardSeen = true;
            }

            break;
          case "product":
            if (!productSeen) {
              //show walkthrough as per current page
              this.setState({
                isTourActive: true
              });
              localStorage.productSeen = true;
            }
            break;
          case "addProduct":
            if (!addProductSeen) {
              //show walkthrough as per current page
              this.setState({
                isTourActive: true
              });
              localStorage.addProductSeen = true;
            }
            break;
          case "inventory":
            if (!inventorySeen) {
              //show walkthrough as per current page
              this.setState({
                isTourActive: true
              });
              localStorage.inventorySeen = true;
            }
            break;
          case "topRightMenu":
            if (!topRightMenuSeen) {
              //show walkthrough as per current page
              this.setState({
                isTourActive: true
              });
              localStorage.topRightMenuSeen = true;
            }
            break;

        }

      }
    }
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.userdetails != undefined && nextProps.userdetails != this.props.userdetails) {
      this.updateUser(nextProps.userdetails);
    }
  }
  updateUser(user) {

    let skuList = [];
    if (user.sku) {
      skuList = user.sku;
    }
    this.setState({
      userCompany: user.company,
      role: user.role.label,
      Uid: user.uid,
      skuList: skuList,
      key: user.key
    }, () => {
      if (this.props.match.params.id) {
        this.setState({ Id: Number(this.props.match.params.id) })
        this.getProduct();
      } else {
        this.getBrandList();
        let Images = []
        Images.push({ MIMEType: null, Url: '', DisplayOrder: '', value: '' })
        this.setState({ Images: Images });
      }
      //
    })
  }

  updateSku(newSku) {
    let skuList = this.state.skuList;
    skuList.push(newSku);
    const db = firebase1.app.firestore();
    db.collection('user').doc(this.state.key).update({ sku: skuList });
  }

  getBrandList() {
    this.setState({ isLoading: true })
    console.log(this.state.Uid)
    api.getApi('Inventory/getBrandList?Uid=' + this.state.Uid).then((resdata) => {
      let data = resdata.data;
      console.log('data===', data);
      this.setState({ isLoading: false })
      if (data) {
        let brandList = data;
        let brandStatusType = this.state.brandStatusType;
        for (let item in brandList) {
          brandStatusType.push({ label: brandList[item].BrandName, value: brandList[item].BrandName });
        }
        brandStatusType = brandStatusType.reverse();
        this.setState({ brandStatusType: [...brandStatusType] })
      }
    })
  }

  getProduct() {
    this.setState({ isLoading: true })
    api.getApi('Inventory/?id=' + this.props.match.params.id).then((resdata) => {
      let data = resdata.data;
      console.log('resdata==', resdata);
      this.setState({ isLoading: false })
      if (data) {
        let TypeValue = null;
        let GlobalValue = null;
        let Identifiers = data.Identifiers;
        let GlobalListingAttributes = data.GlobalListingAttributes;
        let SupplierInformation = data.SupplierInformation;
        let Warehouses = data.Warehouses;
        for (let item in this.state.productType) {
          if (this.state.productType[item].value == data.Type) {
            TypeValue = this.state.productType[item];
          }
        }
        for (let item in this.state.globalType) {
          if (this.state.globalType[item].value == GlobalListingAttributes.Condition) {
            GlobalValue = this.state.globalType[item];
          }
        }
        let Images = data.Images;
        for (let item1 in Images) {
          for (let item in this.state.mimeType) {
            if (this.state.mimeType[item].value == Images[item1].MIMEType) {
              Images[item1].value = this.state.mimeType[item];
            }
          }
        }
        if (Images == undefined) {
          Images = [];
        }
        if (Warehouses == undefined) {
          Warehouses = [];
        }

        let longDescription = convertFromHTML(GlobalListingAttributes.LongDescription ? GlobalListingAttributes.LongDescription : '');

        if (longDescription.contentBlocks) {
          let blockArray = ContentState.createFromBlockArray(longDescription)
          longDescription = EditorState.createWithContent(blockArray);
        }
        let productStatus = null
        let brandStatus = null;
        if (data.ProductStatus && data.ProductStatus != '') {
          productStatus = this.state.statusType.find(data1 => {
            return data1.value === data.ProductStatus;
          });
          productStatus = productStatus ? productStatus : null
        }
        let brandStatusType = this.state.brandStatusType;
        let brandList = resdata.brandList;

        for (let item in brandList) {
          brandStatusType.push({ label: brandList[item].BrandName, value: brandList[item].BrandName });
        }
        brandStatusType = brandStatusType.reverse();
        console.log(brandStatusType);
        if (data.BrandStatus && data.BrandStatus != '') {

          brandStatus = brandStatusType.find(data1 => {
            return String(data1.value).toLocaleLowerCase() === String(data.BrandName).toLocaleLowerCase() && data1.value != 'your_brandname';
          });
          console.log('brandStatus===', brandStatus);
          if (brandStatus == null) {
            brandStatus = this.state.brandStatusType.find(data1 => {
              return data1.value === data.BrandStatus;
            });
          }

        }

        Images = Images.sort((a, b) => (Number(a.DisplayOrder) > Number(b.DisplayOrder)) ? 1 : ((Number(b.DisplayOrder) > Number(a.DisplayOrder)) ? -1 : 0));

        console.log('Images===', Images);
        //console.log('data==',data);
        this.setState({
          productStatus: productStatus ? productStatus : null,
          brandStatus: brandStatus ? brandStatus : null,
          brandStatusType: [...brandStatusType],
          Sku: data.Sku,
          Name: data.Name,
          BrandName: data.BrandName,
          CompanyId: data.CompanyId,
          Type: data.Type,
          TypeValue: TypeValue,
          QuantityOnHand: data.QuantityOnHand,
          BuyPrice: data.BuyPrice ? data.BuyPrice : 0,
          Identifiers: {
            UPC: Identifiers.UPC ? Identifiers.UPC : '',
            EAN: Identifiers.EAN ? Identifiers.EAN : '',
            ISBN: Identifiers.ISBN ? Identifiers.ISBN : '',
            GTIN: Identifiers.GTIN ? Identifiers.GTIN : ''
          },
          IsAutoSyncEnabled: data.IsAutoSyncEnabled,
          IsDiscontinued: data.IsDiscontinued,
          IsDropshipped: data.IsDropshipped,
          IsFireArm: data.IsFireArm,
          IsVariation: data.IsVariation,
          IsParent: data.IsParent,
          editorState: longDescription,
          GlobalListingAttributes: {
            SoldAsName: GlobalListingAttributes.SoldAsName ? GlobalListingAttributes.SoldAsName : '',
            ShortDescription: GlobalListingAttributes.ShortDescription ? GlobalListingAttributes.ShortDescription : '',
            LongDescription: GlobalListingAttributes.LongDescription ? GlobalListingAttributes.LongDescription : '',
            Price: GlobalListingAttributes.Price ? GlobalListingAttributes.Price : '',
            Condition: GlobalListingAttributes.Condition ? GlobalListingAttributes.Condition : '',
            GlobalValue: GlobalValue
          },
          Images: Images,
          Warehouses: Warehouses,
          SupplierInformation: {
            SupplierId: SupplierInformation ? SupplierInformation.SupplierId : '',
            SKU: SupplierInformation ? SupplierInformation.SKU : '',
            Price: SupplierInformation ? SupplierInformation.Price : ''
          },
        })
      } else {
        helper.errorMessage('Invalid product id');
        this.props.history.push('/pages/products');
      }

    })
  }

  handleProductChange = (Type) => {
    this.setState({ Type: Type.value, TypeValue: Type });
  }

  handleGlobalChange = (Condition) => {
    let GlobalListingAttributes = this.state.GlobalListingAttributes;
    GlobalListingAttributes.Condition = Condition.value;
    GlobalListingAttributes.GlobalValue = Condition;
    this.setState({ GlobalListingAttributes: GlobalListingAttributes, isUpdate: !this.state.isUpdate });
  }


  updateIdentifiers(value, type) {
    let Identifiers = this.state.Identifiers;
    Identifiers[type] = value;
    this.setState({ Identifiers: Identifiers, isUpdate: !this.state.isUpdate });
  }

  updateGlobalAttributes(value, type) {
    let GlobalListingAttributes = this.state.GlobalListingAttributes;
    GlobalListingAttributes[type] = value;
    this.setState({ GlobalListingAttributes: GlobalListingAttributes, isUpdate: !this.state.isUpdate });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState: editorState,
      isUpdate: !this.state.isUpdate
    });
  };

  addImage() {
    let Images = this.state.Images;
    if (Images.length < 9) {
      if (Images.length > 0 && Images[Images.length - 1].Url == '') {
        helper.errorMessage('please upload image');
      } else {
        Images.push({ MIMEType: null, Url: '', DisplayOrder: '', value: '' })
        this.setState({ Images: Images, isUpdate: !this.state.isUpdate });
      }
    } else {
      helper.errorMessage('Minimum 9 images ');
    }
  }

  addWareHouses() {
    let Warehouses = this.state.Warehouses;
    if (Warehouses.length > 0 && Warehouses[Warehouses.length - 1].WarehouseId == '') {
      helper.errorMessage('please enter WarehouseId');
    } else {
      Warehouses.push({ WarehouseId: '', Aisle: '', Bin: '', QuantityOnHand: '', SKU: '', IsActive: true })
      this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate });
    }

  }

  handleStatusChange = (productStatus) => {
    this.setState({ productStatus: productStatus })
  }

  handleBrandStatusChange = (brandStatus) => {
    let BrandName = this.state.BrandName;
    if (brandStatus.value == 'your_brandname' || brandStatus.value == 'our_brandname') {
      BrandName = '';
    } else {
      BrandName = brandStatus.value;
    }
    this.setState({ brandStatus: brandStatus, BrandName: '' })
  }

  handleMimeChange = (mimeType, index) => {
    let Images = this.state.Images;
    Images[index].MIMEType = mimeType.value;
    Images[index].value = mimeType;
    this.setState({ Images: Images, isUpdate: !this.state.isUpdate })
  }

  updateImageState(value, field, index) {
    let Images = this.state.Images;
    Images[index][field] = value;
    this.setState({ Images: Images, isUpdate: !this.state.isUpdate })
  }

  removeImage(index) {
    let Images = this.state.Images;
    Images.splice(index, 1);
    this.setState({ Images: Images, isUpdate: !this.state.isUpdate })
  }

  updateWareHouseState(value, field, index) {
    let Warehouses = this.state.Warehouses;

    if (field == 'IsActive') {
      Warehouses[index][field] = !Warehouses[index][field];
      //console.log('value==', Warehouses[index][field]);
    } else {
      Warehouses[index][field] = value;
    }

    this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate })
  }

  removeWareHouses(index) {
    let Warehouses = this.state.Warehouses;
    Warehouses.splice(index, 1);
    this.setState({ Warehouses: Warehouses, isUpdate: !this.state.isUpdate })
  }

  updateSupplier(value, field) {
    let SupplierInformation = this.state.SupplierInformation;
    SupplierInformation[field] = value;
    this.setState({ SupplierInformation: SupplierInformation, isUpdate: !this.state.isUpdate })
  }

  updateIdentifiers(value, field) {
    let Identifiers = this.state.Identifiers;
    Identifiers[field] = value;
    this.setState({ Identifiers: Identifiers, isUpdate: !this.state.isUpdate })
  }

  removeImageFile(index) {
    let Images = this.state.Images;
    Images[index].Url = '';
    Images[index].file = '';
    this.setState({ 'Images': Images, time: new Date().getTime() });
  }

  photoOnChange = async (e, field, index) => {
    if ((e.target.files).length > 0) {
      const file: File = (e.target.files)[0];
      //console.log(file);
      let that = this;
      try {
        // let Images = that.state.Images;
        // Images[index].file = file
        console.log('file===', Number(file.size));
        if (file.size > 3000000) {
          helper.errorMessage('Please upload file less than 3MB.');
        } else {
          const orientation = await getOrientation(file)
          var reader = new FileReader();
          reader.onload = function (e) {
            // Images[index].Url = e.target.result;
            if (that.fileInput) {
              that.fileInput.value = "";
            }
            that.checkImage(e.target.result, orientation, index);
          }
          reader.readAsDataURL(file);
        }

      } catch (err) {
        //console.log(err);
      } finally {
        //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
      }
    }
  };

  async checkImage(imageDataUrl, orientation, index) {
    const imageSize = await getImageSize(
      imageDataUrl
    )
    if (imageSize && 250 <= imageSize.width && 250 <= imageSize.height) {
      this.rotatedImage(imageDataUrl, orientation, index);
    } else {
      helper.errorMessage('That image is too small. It needs to be at least 225 x 225 pixels');
    }
    console.log('imageDataUrl, orientation, index===', imageSize.width, imageSize.height);
  }


  async rotatedImage(imageDataUrl, orientation, index) {
    const rotation = ORIENTATION_TO_ANGLE[orientation]
    // if (rotation) {
    //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
    // }
    this.setState({
      modalIsOpen: true,
      imageIndex: index,
      imageSrc: imageDataUrl,
      rotation: 0,
      crop: { x: 0, y: 0 },
      zoom: 1,
    });
  }

  async croppedImage() {

    //console.log(this.state.croppedAreaPixels);
    //console.log(this.state.rotation);
    const croppedImage = await getCroppedImg(
      this.state.imageSrc,
      this.state.croppedAreaPixels,
      this.state.rotation
    )
    let Images = this.state.Images;

    Images[this.state.imageIndex].file = croppedImage.file
    Images[this.state.imageIndex].Url = croppedImage.url

    this.setState({ Images: Images, modalIsOpen: false })
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels: croppedAreaPixels })
    ////console.log(croppedArea, croppedAreaPixels)
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  onRotate = () => {

    let rotation = this.state.rotation;
    if (rotation == 0) {
      rotation = 90;
    } else if (rotation == 90) {
      rotation = 180;
    } else if (rotation == 180) {
      rotation = 270;
    } else if (rotation == 270) {
      rotation = 360;
    } else if (rotation == 360) {
      rotation = 0;
    }
    this.setState({ rotation: rotation })
  }

  addProduct = () => {

    var urlCondition = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    //console.log('authReducer===', this.state.userCompany);
    const { userCompany, Id, Sku, Name, Type, QuantityOnHand, Identifiers, IsAutoSyncEnabled, IsDiscontinued, IsDropshipped,
      IsFireArm, brandStatusType, IsVariation, IsParent, GlobalListingAttributes, Images, Warehouses, SupplierInformation, editorState, brandStatus, BrandName } = this.state;


    if (editorState) {
      let block = convertToRaw(editorState.getCurrentContent())
      GlobalListingAttributes.LongDescription = draftToHtml(block);
    }
    //console.log('GlobalListingAttributes===', GlobalListingAttributes);
    if (Sku == '') {
      helper.errorMessage('SKU field is required');
    } else if (Name == '') {
      helper.errorMessage('Product title field is required');
    } else if (GlobalListingAttributes.price == '') {
      helper.errorMessage('Price field is required');
    }else if (GlobalListingAttributes.LongDescription == '') {
      helper.errorMessage('Description field is required');
    }else if (brandStatus == null) {
      helper.errorMessage('Please select brand name');
    } else if (brandStatus.value == 'your_brandname' && BrandName == '') {
      helper.errorMessage('Brand name field is required');
    } else if (brandStatus.value == 'your_brandname' && helper.getItemLowerIndex(brandStatusType, 'value', BrandName) != 0) {
      helper.errorMessage('Brand name already exists');
    }
    else if (Images.length == 0) {
      helper.errorMessage('please upload one image');
    // } else if (Images.length > 0 && Images[Images.length - 1].Url == '') {
    //   helper.errorMessage('please upload one image');
    } else {
      this.imageCount = 0;
      this.uploadImage();
      this.setState({ isLoading: true })
    }
  }

  async uploadImage() {

    if (this.imageCount < this.state.Images.length) {

      if (this.state.Images[this.imageCount].file != '' && this.state.Images[this.imageCount].file != undefined) {
        const photoURL = await this.uploadFirebaseImage(this.state.Images[this.imageCount].file, 'products');
        console.log('photoURL====', photoURL);
        this.state.Images[this.imageCount].Url = photoURL;
        this.state.Images[this.imageCount].file = '';
      }
      this.imageCount = this.imageCount + 1;
      this.uploadImage();
    } else {
      this.updateProduct();
    }

  }

  viewData = () => {
    window.open('https://www.valetseller.com/helpcenter/editing-your-photos/');
  }

  updateProduct() {
    const { userCompany, Id, Sku, Name, Type, QuantityOnHand, Identifiers, IsAutoSyncEnabled, IsDiscontinued, IsDropshipped,
      IsFireArm, IsVariation, IsParent, BrandName, GlobalListingAttributes, Images, Warehouses, SupplierInformation, BuyPrice, CompanyId, brandStatus, productStatus } = this.state;
    let reqData = {
      Sku: Sku,
      Name: Name,
      CompanyId: CompanyId,
      Type: Type,
      QuantityOnHand: QuantityOnHand,
      BuyPrice: BuyPrice,
      Identifiers: Identifiers,
      IsAutoSyncEnabled: IsAutoSyncEnabled,
      IsDiscontinued: IsDiscontinued,
      IsDropshipped: IsDropshipped,
      IsFireArm: IsFireArm,
      IsVariation: IsVariation,
      IsParent: IsParent,
      GlobalListingAttributes: GlobalListingAttributes,
      Images: Images,
      Warehouses: Warehouses,
      SupplierInformation: SupplierInformation
    }

    if (Id == '') {

      reqData.Sku = userCompany + '-' + Sku;
      reqData.Sku = reqData.Sku.replace(/\s/g, '');
      reqData.QuantityOnHand = Number(QuantityOnHand);


      api.postEcomDashApi('Inventory', reqData).then((data) => {
        console.log(data);
        if (data.status == 'Success') {
          let product = data.data.Product;
          product.Uid = this.state.Uid;
          product.BuyPrice = BuyPrice;
          if (productStatus == null) {
            product.ProductStatus = 'pending_reviewal';
          } else {
            product.ProductStatus = productStatus ? productStatus.value : '';
          }
          product.BrandStatus = brandStatus ? brandStatus.value : '';
          product.BrandName = BrandName;

          console.log('product======', product);

          api.postApi('inventory/addProducts', product).then((data) => {
            this.setState({ isLoading: false })
            this.props.history.push('/pages/products');
            helper.successMessage('Your Product is Under Review and Will Be Live in 1-3 Business Days');
          })
          this.sendAddproductEmail(reqData.Sku)
          // this.updateSku(this.state.Sku);

        } else if (data.data && data.data.WasSuccessful == false) {
          this.setState({ isLoading: false })
          if (data.data.Messages.length > 0) {
            helper.errorMessage(data.data.Messages[0]);
          }
        } else {
          this.setState({ isLoading: false })
          if (data.messages.length > 0) {
            helper.errorMessage(data.messages[0]);
          }
        }
      }, err => {
        //console.log('err====', err);
        this.setState({ isLoading: false });
        helper.errorMessage(err);
      })
    } else {
      reqData.Id = Id;
      reqData.QuantityOnHand = Number(QuantityOnHand)
      api.putEcomDasApi('Inventory', reqData).then((data) => {
        if (data.status == 'Success') {
          let product = data.data.Product;
          product.Uid = this.state.Uid;
          product.BuyPrice = BuyPrice;
          product.ProductStatus = productStatus ? productStatus.value : '';
          product.BrandStatus = brandStatus ? brandStatus.value : '';
          product.BrandName = BrandName;
          console.log('product==', product);
          api.postApi('inventory/updateProducts', product).then((data) => {
            this.setState({ isLoading: false })
            this.props.history.push('/pages/products');
            helper.successMessage('Your product was successfully updated');
          })

        } else if (data.data && data.data.WasSuccessful == false) {
          this.setState({ isLoading: false })
          if (data.data.Messages.length > 0) {
            helper.errorMessage(data.data.Messages[0]);
          }
        }
        else {
          this.setState({ isLoading: false })
          if (data.messages.length > 0) {
            helper.errorMessage(data.messages[0]);
          }
        }
      }, err => {
        //console.log('err====', err);
        this.setState({ isLoading: false });
        helper.errorMessage(err);
      })
    }
  }

  async sendAddproductEmail(sku) {

    let apiPath = "";
    apiPath = "mail/onProductDetails";
    let reqData = {
      firstname: this.props.userdetails ? this.props.userdetails.firstname : '',
      email: this.props.userdetails ? this.props.userdetails.email : '',
      sku: sku,
      company: this.state.userCompany
    };
    console.log('reqData===', reqData);
    api.postApi(apiPath, reqData).then(
      (data) => {
        console.log(data);
        if (data.status == "Success") {

        }
        this.setState({ isLoading: false })
      })
  }



  async uploadFirebaseImage(file, collection) {
    try {
      let timeStamp = new Date().getTime();
      const spaceRef = firebase1.app.storage().ref().child(collection).child(String(timeStamp));
      return spaceRef.put(file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
        return snapshot.ref.getDownloadURL().then((downloadURL) => {
          return downloadURL;
        })

      });
    } catch (err) {
      //console.log(err);
      return null;
    }

  };

  onCancelWalkthrough = () => {
    this.setState({ isTourActive: false })
    this.props.addWalkthroughFlag()
  }
  onNext = (step) => {
    if (step == 2) {
      this.setState({ tourStep: step })
      document.getElementById('topBar__Id').classList.add("show");
    }
    else if (step == 6) {
      history.push('products', { walkthrough: true })
      document.getElementById('topBar__Id').classList.remove("show");
    } else {
      this.setState({ tourStep: step })
      document.getElementById('topBar__Id').classList.remove("show");
    }
  }


  editImageModal() {
    this.setState({ editmodalIsOpen: true });
  }

  closeImageModal() {
    this.setState({ editmodalIsOpen: false });
  }


  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    if (this.fileInput) {
      this.fileInput.value = "";
    }
    this.setState({ modalIsOpen: false });
  }

  handleClick = (index) => {
    let colorItems = this.state.colorItems;
    colorItems[index].displayColorPicker =  true
    this.setState({ colorItems:[...colorItems]})
  };

  handleClose = (index) => {
    let colorItems = this.state.colorItems;
    colorItems[index].displayColorPicker = false
    this.setState({ colorItems: [...colorItems ]})
  };

  handleChange = (color,index) => {
    let colorItems = this.state.colorItems;
    colorItems[index].color = color.rgb;
  //  colorItems[index].displayColorPicker = false;
    this.setState({ colorItems:[...colorItems]})
  };

  handleChangeColor = (IsColor) => {
    this.setState({ IsColor: !this.state.IsColor, colorItems: [{ value: '', name: '', color: '' }] })
  };

  addColor() {
    let colorItems = this.state.colorItems;
    if (colorItems.length == 0){
      colorItems.push({ value: '', name: '', color: '',displayColorPicker:false });
      this.setState({ colorItems: [...colorItems] })
    }else if (colorItems.length == 0 || colorItems[colorItems.length - 1].value == '') {
      helper.errorMessage('please select name and colors');
    } else {
      colorItems.push({ value: '', name: '', color: '',displayColorPicker:false });
      this.setState({ colorItems: [...colorItems] })
    }
  }
  removeColor(index) {
    let colorItems = this.state.colorItems;
    colorItems.splice(index, 1)
    this.setState({ colorItems: [...colorItems] });

  }
  updateColor(value, index) {
    let colorItems = this.state.colorItems;
    colorItems[index].value = value;
    this.setState({ colorItems: [...colorItems] })
  }

  removeAllColor() {
    this.setState({ colorItems: [], IsColor: false })
  }

  handleChangeSize = (IsSize) => {
    this.setState({ IsSize: !this.state.IsSize, sizeItems: [{ value: '', name: '' }] })
  };

  addSize() {
    let sizeItems = this.state.sizeItems;
    if (sizeItems.length == 0){
      sizeItems.push({ value: '', name: '' });
      this.setState({ sizeItems: [...sizeItems] })
    }else if (sizeItems[sizeItems.length - 1].value == '') {
      helper.errorMessage('please enter size');
    } else {
      sizeItems.push({ value: '', name: '' });
      this.setState({ sizeItems: [...sizeItems] })
    }
  }
  updateSize(value, index) {
    let sizeItems = this.state.sizeItems;
    sizeItems[index].value = value;
    this.setState({ sizeItems: [...sizeItems] })
  }
  removeSize(index) {
    let sizeItems = this.state.sizeItems;
    sizeItems.splice(index, 1);
    this.setState({ sizeItems: [...sizeItems] })
  }
  removeAllSize() {
    this.setState({ sizeItems: [], IsSize: false })
  }

  handleChangeCustom = (IsCustom) => {
    this.setState({ IsCustom: !this.state.IsCustom })
  };



  render() {

    const {
      brandStatusType, brandStatus, Id, role, TypeValue, mimeType,
      productType, globalType, Sku, Name, Type, QuantityOnHand, Identifiers, IsAutoSyncEnabled, IsDiscontinued, IsDropshipped,
      IsFireArm, IsVariation, IsParent, GlobalListingAttributes, Images, Warehouses,
      SupplierInformation, longDescription, editorState, BuyPrice, statusType, productStatus, BrandName, IsColor, IsSize, IsQuantity, IsCustom,
      colorItems, sizeItems
    } = this.state;

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (
      <>
        {/* <div className="overlayIntro">
          <Tour
            active={this.state.isTourActive}
            step={this.state.tourStep}
            onNext={(step) => this.onNext(step)}
            onBack={(step) => this.setState({ tourStep: step })}
            onCancel={() => this.onCancelWalkthrough()}
            hideSteps={true}
            steps={[
              {
                step: 1,
                selector: ".steps1",
                containerStyle: { marginTop: 200 },
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{GLOBAL_LISTING_ATTRIBUTES}</div>
              },
              {
                step: 2,
                selector: ".help11",
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{HELP}</div>
              },
              {
                step: 3,
                selector: ".step2",
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{WAREHOUSE}</div>
              }
              , {
                step: 4,
                selector: ".step3",
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{ADD_BUTTON}</div>
              },
              {
                step: 5,
                selector: ".cancelbtn",
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{CANCEL_BUTTON}</div>
              }, {
                step: 6,
                selector: ".cancelbtn1",
                title: <div style={{ textAlign: "center", marginTop: '10px', fontSize: "16px", color: "black" }}></div>,
                body: <div style={{ textAlign: "center", marginTop: '20px', fontSize: "16px", color: "black" }}>{CANCEL_BUTTON}</div>
              }
            ]}
          />
        </div> */}
        <Col className="container-div" md={12}>
          {this.state.isLoading == true && <div className="load" style={{ backgroundColor: 'transparent', width: '85%', height: '50%' }}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          </div>}


          {this.state.isLoading == false && <Col md={12} >
            <Card style={{ marginTop: 50 }}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Add Products</h5>
                  {/* <button onClick={this.openModal} className="btn addbtn add_products">crop</button> */}
                </div>

                <div className="identifiers">
                  <span className="form_header">Global Listing Attributes<span></span></span>
                  <div className="images_div">
                    <div className="form__form-group-field image-width">

                      <ReactTooltip />
                      <span>
                        <span ><label>SKU<span className="mantory">*</span></label> <img className="tooltips" src="../../img/info.png"
                          data-tip="Stock Keeping Unit. IsCustomizable code to distinguish products"></img></span>
                        <input name="name" type="text" placeholder="" value={Sku}
                          onChange={(event) => { this.setState({ Sku: event.target.value }) }} />
                      </span>
                    </div>
                    <div className="form__form-group-field image-width">
                      <span>
                        <label className="step2">Product Title<span className="mantory">*</span></label>
                        <input name="name" type="text" placeholder="" value={Name}
                          onChange={(event) => { this.setState({ Name: event.target.value }) }} />
                      </span>
                    </div>
                    {/* <div className="form__form-group-field image-width">
                <span>
                <label>QuantityOnHand</label>
                  <input name="number" type="text" placeholder="" value={QuantityOnHand}
                      onChange={(event) => {this.setState({ QuantityOnHand: event.target.value })}}/>
                </span>
              </div> */}
                    <div className="form__form-group-field image-width">
                      <span>
                        <label>Short Description</label>
                        <input name="shortdescription" type="text" placeholder="" value={GlobalListingAttributes.ShortDescription}
                          onChange={(event) => { this.updateGlobalAttributes(event.target.value, 'ShortDescription') }} />
                      </span>
                    </div>
                    <div className="form__form-group-field image-width">
                      <span>
                        <label>Your Cost (Optional)</label>
                        <input name="buyprice" type="number" placeholder="" value={BuyPrice}
                          onChange={(event) => { this.setState({ BuyPrice: event.target.value }) }} />
                      </span>
                    </div>

                    <div className="form__form-group-field image-width">
                      <span>
                        <label>Selling Price<span className="mantory">*</span></label>
                        <input name="shortdescription" type="number"   placeholder="" value={GlobalListingAttributes.Price}
                          onChange={(event) => { this.updateGlobalAttributes(event.target.value, 'Price') }} />
                      </span>
                    </div>
                    <div className="form__form-group-field image-width">
                      <span>
                        <label>Brand Type <span className="mantory">*</span>
                          <a href="https://www.valetseller.com/helpcenter/use-one-of-valet-sellers-registered-brands/" target="_blank">
                            <img className="tooltips" src="../../img/info.png" ></img>
                          </a></label>
                        <Select
                          value={brandStatus}
                          onChange={this.handleBrandStatusChange}
                          options={brandStatusType}
                        />
                      </span>
                    </div>
                    {brandStatus && brandStatus.value == 'your_brandname' && <div className="form__form-group-field image-width">
                      <span>
                        <label>Brand Name<span className="mantory">*</span></label>
                        <input name="shortdescription" type="text" placeholder="" value={BrandName}
                          onChange={(event) => { this.setState({ BrandName: event.target.value }) }} />
                      </span>
                    </div>}
                    {role == 'admin' && <div className="form__form-group-field image-width">
                      <span>
                        <label>Product Status</label>
                        <Select
                          value={productStatus}
                          onChange={this.handleStatusChange}
                          options={statusType}
                        />
                      </span>
                    </div>}

                    <div className="form__form-group-field image-width-1">
                      <span>
                        <label>Long Description<span className="mantory">*</span></label>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />

                        {/* <textarea name="longdescription" rows="10" type="textarea" placeholder="" value={GlobalListingAttributes.LongDescription}
                          onChange={(event) => { this.updateGlobalAttributes(event.target.value, 'LongDescription') }}
                          className="textarea"></textarea> */}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="identifiers">
                  <span className="form_header">Identifiers<span>( Optional )</span></span>
                  <div className="form__form-group-field middle-width">
                    <span>
                      <span><label>UPC</label><img className="tooltips" src="../../img/info.png"
                        data-tip="Universal Product Code"></img></span>
                      <input name="name" type="text" placeholder="" value={Identifiers.UPC}
                        onChange={(event) => { this.updateIdentifiers(event.target.value, 'UPC') }} />
                    </span>
                  </div>
                  <div className="form__form-group-field middle-width">
                    <span>
                      <span><label>EAN</label><img className="tooltips" src="../../img/info.png"
                        data-tip="International Article Number"></img></span>
                      <input name="name" type="text" placeholder="" value={Identifiers.EAN}
                        onChange={(event) => { this.updateIdentifiers(event.target.value, 'EAN') }} />
                    </span>
                  </div>
                  <div className="form__form-group-field middle-width">
                    <span>
                      <span><label>ISBN</label><img className="tooltips" src="../../img/info.png"
                        data-tip="International Standard Book Number"></img></span>
                      <input name="name" type="text" placeholder="" value={Identifiers.ISBN}
                        onChange={(event) => { this.updateIdentifiers(event.target.value, 'ISBN') }} />
                    </span>
                  </div>
                  <div className="form__form-group-field middle-width">
                    <span>
                      <span><label>GTIN</label><img className="tooltips" src="../../img/info.png"
                        data-tip="Global Trade Item Number"></img></span>
                      <input name="name" type="text" placeholder="" value={Identifiers.GTIN}
                        onChange={(event) => { this.updateIdentifiers(event.target.value, 'GTIN') }} />
                    </span>
                  </div>
                </div>




               


                <div className="identifiers">
                  <span className="form_header">Images<span className="mantory" style={{ fontSize: 20 }}>*</span></span>
                  {Images.map((item, key) => {
                    return (
                      <div className="images_div image_div_center" key={key}>
                        {/* <div className="form__form-group-field image-width1">
                      <span>
                        <label>MIME Type</label>
                        <Select
                        value={item.value}
                        onChange={(event) => { this.handleMimeChange(event,key)}}
                        options={mimeType}/>
                      </span>
                  </div> */}
                        <div className="image-width1 image-width2">
                          <div className="form__form-group-field">
                            {/* <span className="image-upload">
                        <label>Url</label>
                        <input name="url" type="text" placeholder="" value={item.Url}
                        onChange={(event) => {this.updateImageState(event.target.value,'Url',key)}}/>
                      </span> */}
                            {/* <span className="image-width_or">(or)</span> */}
                            <span className="image-upload image-upload_file">
                              <label className="image_upload_title image_uploadhead">Image Upload</label>
                              <span className="image_upload_split">

                                <input name="url" type="file" placeholder="" ref={ref => this.fileInput = ref} accept="image/*"
                                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.photoOnChange(e, 'Url', key)} />
                              </span>

                              {item.Url != '' && <span className="upload_img_block">
                                <img src={item.Url} className="upload_img" alt="" />
                                <img src="../../img/close.png" className="upload_img_close" alt="" onClick={() => { this.removeImageFile(key) }} />
                              </span>}

                            </span>
                          </div>
                        </div>
                        <div className="form__form-group-field image-width1 image-order">
                          <span>
                            <label>Display Order</label>
                            <input name="displayorder" type="text" placeholder="" value={item.DisplayOrder}
                              onChange={(event) => { this.updateImageState(event.target.value, 'DisplayOrder', key) }} />
                          </span>
                        </div>
                        <button className="image-width-delete" onClick={() => { this.removeImage(key) }}>Remove</button>
                      </div>
                    )
                  })}
                  <div className="add-more" >
                    <button className="btn addbtn" onClick={() => { this.addImage() }}>Add Image</button>

                    <button onClick={() => { this.viewData() }} className="addbtn addbtn_guide">Photo How-To Guide</button>

                  </div>

                </div>


                {/* <div className="identifiers step2">
                  <span className="form_header">Warehouse<span>(Optional)</span></span>

                  {Warehouses.map((item, key) => {
                    return (
                      <div className="images_div" key={key}>
                        <div className="form__form-group-field image-width">
                          <span>
                            <label>Warehouse Id <span className="manitory">*</span> ({item.Name})</label>
                            <input name="warehouseid" type="number" placeholder="" value={item.WarehouseId}
                              onChange={(event) => { this.updateWareHouseState(event.target.value, 'WarehouseId', key) }} />
                          </span>
                        </div>
                       
                        <div className="form__form-group-field image-width">
                          <span>
                            <label>Quantity On Hand</label>
                            <input name="quantityonhand" type="number" placeholder="" value={item.QuantityOnHand}
                              onChange={(event) => { this.updateWareHouseState(event.target.value, 'QuantityOnHand', key) }} />
                          </span>
                        </div>
                     
                        <div className="autosync">
                          <div>
                            <label>Active </label>
                            <span>
                              <input name={"warehouse" + key} type="radio" placeholder="" checked={item.IsActive} value={true}
                                onChange={(event) => { this.updateWareHouseState(true, 'IsActive', key) }} />
                              <p>True</p>
                            </span>
                            <span>
                              <input name={"warehouse" + key} type="radio" placeholder="" checked={!item.IsActive} value={false}
                                onChange={(event) => { this.updateWareHouseState(false, 'IsActive', key) }} />
                              <p>False</p>
                            </span>
                          </div>
                        </div>
                        <button onClick={() => { this.removeWareHouses(key) }} className="image-width-delete" style={{ marginTop: 0 }}>Remove</button>
                      </div>)
                  })}
                  <div className="add-more" onClick={() => { this.addWareHouses() }}>
                    <button className="btn addbtn">Add Warehouse</button>
                  </div>
                </div> */}


                {/* <div className="identifiers">
            <span className="form_header">Supplier Information</span>
            <div className="images_div">  
              <div className="form__form-group-field image-width">
                  <span>
                    <label>Supplier Id</label>
                    <input name="supplierid" type="text" placeholder="" value={SupplierInformation.SupplierId}
                  onChange={(event) => {this.updateSupplier(event.target.value,'SupplierId')}}/>
                  </span>
              </div>
              <div className="form__form-group-field image-width">
                  <span>
                    <label>SKU</label>
                    <input name="suppliersku" type="text" placeholder="" value={SupplierInformation.SKU}
                   onChange={(event) => {this.updateSupplier(event.target.value,'SKU')}}/>
                  </span>
              </div>
              <div className="form__form-group-field image-width">
                  <span>
                    <label>Price</label>
                    <input name="supplierprice" type="number" placeholder="" value={SupplierInformation.Price}
                   onChange={(event) => {this.updateSupplier(event.target.value,'Price')}}/>
                  </span>
              </div>

            </div>
          
          </div> */}


                <div className="form_footer">
                  <Link to="/pages/products"><button className="btn addbtn cancelbtn" >Cancel</button></Link>
                  {Id == '' && <button className="btn addbtn step3" onClick={() => { this.addProduct() }}>Add</button>}
                  {Id != '' && <button className="btn addbtn" onClick={() => { this.addProduct() }}>Update</button>}
                </div>

              </CardBody>
            </Card>

          </Col>}

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={IsCustomStyles}
            contentLabel="Example Modal"
          >
            <div className="editimage">
              <button className="btn addbtn optimisation cropclose" onClick={() => { this.closeModal() }}>
                Close
              </button>
              <span>Edit Image</span>
              <button className="btn addbtn optimisation cropsave" onClick={() => { this.croppedImage() }}>SAVE</button>
            </div>

            <Cropper
              image={this.state.imageSrc}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              rotation={this.state.rotation}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}

            />
            <button className="btn addbtn add_products optimisation cropbtn" onClick={() => { this.onRotate() }} >ROTATE</button>

          </Modal>

          <Modal
            isOpen={this.state.editmodalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeImageModal}
            style={editImageStyles}
            contentLabel="Example Modal"
          >
            <div className="top_model_head">
              <button className="btn addbtn optimisation cropclose closing" onClick={() => { this.closeImageModal() }}>
                X
              </button>
              <span>Select a photo for this variation</span>
              {/* <button className="btn addbtn optimisation cropsave" onClick={() => { this.croppedImage() }}>SAVE</button> */}
            </div>
            <span className="title_varients">L, green</span>
            <div className="selectImages">
              <a href="#" className="selected_image"><img src="../../img/close_btn.png"></img></a>
              <a href="#"><img src="../../img/close_btn.png"></img></a>
              <a href="#"><img src="../../img/close_btn.png"></img></a>
            </div>
            <div className="radio_variations">
              <p><input type="radio" name="variations" /> <span>Only apply this photo to the current Variation</span></p>
              <p><input type="radio" name="variations" /> <span>Apply this photo to All "L" Variations</span></p>
              <p><input type="radio" name="variations" /> <span>Apply this photo to All "green" Variations</span></p>
              <p><input type="radio" name="variations" /> <span>Apply this photo to All "IsCustom" Variations</span></p>
            </div>
            <div className="variations_save">
              <button className="btn optimisation" >SAVE</button>
            </div>
          </Modal>
        </Col>
      </>
    )


  }

}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(AddproductsCard);
