import React, { PureComponent } from 'react';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import history from '../../../utils/history';
import { addHelpQuery } from '../../../redux/actions/curdActions';
import Modal from 'react-modal';
import api from '../../../utils/api';

const customStyles = {
	content: {
		top: '55%',
		left: '50%',
		right: '0%',
		bottom: '25%',
		transform: 'translate(-50%, -50%)',
	},
};

const customtextStyles = {
	content: {
		top: '55%',
		left: '50%',
		right: '0%',
		bottom: '25%',
		width: '320px',
		transform: 'translate(-50%, -50%)',
	},
};

class HelpForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			name: '',
			email: '',
			question: '',
			modalIsOpen: false,
			textmodalIsOpen: false,
			message: '',
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.opentextModal = this.opentextModal.bind(this);
		this.closetextModal = this.closetextModal.bind(this);
	}

	componentDidMount() {
		if (
			this.props.userdetails &&
			Object.keys(this.props.userdetails).length > 0
		) {
			this.updateUser(this.props.userdetails);
		}
	}
	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}
	updateUser(user) {
		let role = '';
		if (user.role) {
			role = user.role.label;
		}
		console.log('user=====', user.key);
		this.setState({
			userCompany: user.company,
			role: role,
			key: user.key,
			Uid: user.uid,
			firstname: user.firstname,
			email: user.email,
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var validEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

		const { name, email, question } = this.state;
		console.log(this.state);
		if (name == '' || name == undefined) {
			helper.errorMessage('Name field is required');
		} else if (email == '' || email == undefined) {
			helper.errorMessage('Email field is required');
		} else if (email && !validEmail.test(email.replace(/ /g, ''))) {
			helper.errorMessage('Email invalid');
		} else if (question == '') {
			helper.errorMessage('Please fill your query');
		} else {
			// this.setState({ isLoading: true })
			console.log('+++++++', this.props);
			this.props.addHelpQuery({ email, name, question });
			this.setState({ email: '', name: '', question: '', isLoading: false });
		}
	};

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	opentextModal() {
		this.setState({ textmodalIsOpen: true });
	}

	closetextModal() {
		this.setState({ textmodalIsOpen: false });
	}

	submitPhoneNumber() {
		const { phonenumber, firstname, userCompany, email } = this.state;
		if (phonenumber == '' || phonenumber == undefined) {
			helper.errorMessage('Phone number field is required');
		} else {
			this.setState({ modalIsOpen: false, isLoading: true });
			let apiPath = '';
			apiPath = 'mail/onContactDetails';
			let reqData = {
				firstname: firstname,
				company: userCompany,
				email: email,
				phonenumber: phonenumber,
			};
			console.log(reqData);
			api.postApi(apiPath, reqData).then((data) => {
				console.log(data);
				if (data.status == 'Success') {
					helper.successMessage('Thank You! We will contact you shortly');
				}
				this.setState({ isLoading: false });
			});
		}
	}

	render() {
		// const {t} = this.props.setOptions
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const message = params.get('msg');

		if (message != null) {
			if (message.length > 0) {
				this.setState({ question: message });
			}
		}

		if (this.state.isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<div className='col-md-12'>
					<div className='card'>
						<div className='card-body'>
							<div className='card__title'>
								<h5 className='bold-text d-flex justify-content-between align-items-center'>
									<span>Help</span>
								</h5>
							</div>
							<div className='clearfix' />
							<div className='row'>
								<div className='col-md-8'>
									<div className=''>
										<div className='form__form-group-field w-100'>
											<span>
												<input
													value={this.state.name}
													placeholder='Your Name'
													onChange={(event) => {
														this.setState({ name: event.target.value });
													}}
													id='name'
													type='text'
												/>
											</span>
										</div>
										<div className='form__form-group-field w-100'>
											<span>
												<input
													value={this.state.email}
													placeholder='Email Address'
													onChange={(event) => {
														this.setState({ email: event.target.value });
													}}
													id='email'
													type='text'
												/>
											</span>
										</div>
										<div className='form__form-group-field w-100'>
											<textarea
												rows='4'
												className='form-control w-100'
												placeholder='How can we help?'
												value={this.state.question}
												onChange={(event) => {
													this.setState({ question: event.target.value });
												}}
												id='question'
											/>
										</div>
										<div className='text-left'>
											<a
												href='https://www.valetseller.com/help-center/'
												target='_blank'
												className='btn addbtn float-left text-capitalize'
											>
												Want to find an answer yourself? Visit our help center
											</a>
										</div>
										<div className='form_footer pt-0'>
											{/* <a href="/pages/user">
													<button class="btn addbtn cancelbtn">Cancel</button>
												</a> */}
											<button
												className='btn addbtn'
												onClick={(e) => {
													this.handleSubmit(e);
												}}
											>
												Submit
											</button>
										</div>
										{/* <a href="javascript:void(0)" onClick={() => { this.handleSubmit() }} className="btn btn-primary"><span>{('SUBMIT')}</span></a> */}
									</div>
								</div>
								<div className='col-md-4'>
									<div className='address py-4'>
										<h5>ADDRESS</h5>
										<p>320 N. Washington Street, Rochester, NY 14625</p>
										<a href='mailto:support@valetseller.com'>
											<strong>E:</strong> support@valetseller.com
										</a>
										<a href='tel:P:  +1 (908) 873-6370'>
											<strong>P:</strong> +1 (908) 873-6370
										</a>

										<a onClick={this.openModal} style={{ cursor: 'pointer' }}>
											Or have us text you
										</a>
										<div className='footer-social d-flex'>
											<a href='https://www.facebook.com/valetseller'>
												<i className='fab fa-facebook-f' />
											</a>
											<a href='https://www.instagram.com/valetseller'>
												<i className='fab fa-instagram' />
											</a>
											<a href='https://www.linkedin.com/company/valetseller'>
												<i className='fab fa-linkedin-in' />
											</a>
										</div>
									</div>

									<div className='shadow-sm'>
										<a
											href='https://calendly.com/kevin-dunne/demo'
											target='_blank'
										>
											<div
												className='card card-body border-top-custom p-3'
												style={{ marginBottom: 10 }}
											>
												<h5 className='card_title'>
													Training Webinars - Register Now!
												</h5>
												<p>
													Learn more about Valet Seller in a quick 15 minute
													training demo
												</p>
											</div>
										</a>

										<div
											className='card card-body border-top-custom p-3'
											onClick={this.opentextModal}
											style={{ cursor: 'pointer' }}
										>
											<h5 className='card_title'>
												Onboarding Tips - Get Help Now!
											</h5>
											<p>
												Learn more about Valet Seller through our text support
												line.
											</p>
										</div>
									</div>

									<Modal
										isOpen={this.state.textmodalIsOpen}
										onAfterOpen={this.afterOpenModal}
										onRequestClose={this.closetextModal}
										style={customtextStyles}
										contentLabel='Example Modal'
									>
										<h4 ref={(subtitle) => (this.subtitle = subtitle)}>
											TEXT to My Phone Number:{' '}
											<a className='closemodal' onClick={this.closetextModal}>
												X
											</a>
										</h4>

										<div className='agreement'>
											<div
												className='form__form-group-field w-100'
												style={{ padding: 0 }}
											>
												<span>(585) 857-2728</span>
											</div>
										</div>
									</Modal>

									<Modal
										isOpen={this.state.modalIsOpen}
										onAfterOpen={this.afterOpenModal}
										onRequestClose={this.closeModal}
										style={customStyles}
										contentLabel='Example Modal'
									>
										<h4 ref={(subtitle) => (this.subtitle = subtitle)}>
											Enter your phone number:{' '}
											<a className='closemodal' onClick={this.closeModal}>
												X
											</a>
										</h4>

										<div className='agreement'>
											<div
												className='form__form-group-field w-100'
												style={{ padding: 0 }}
											>
												<span>
													<input
														placeholder='Enter Phone Number'
														type='number'
														value={this.state.phonenumber}
														onChange={(event) => {
															this.setState({
																phonenumber: event.target.value,
															});
														}}
													/>
													<button
														className='btn addbtn disconnect-btn'
														onClick={() => {
															this.submitPhoneNumber();
														}}
													>
														Submit
													</button>
												</span>
											</div>
										</div>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = ({ authReducer }) => {
	return authReducer;
};

export default connect(mapStateToProps, actions)(HelpForm);
