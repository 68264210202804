import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import DashboardCard from './components/DashboardCard';
import StatisticsCard from './components/StatisticsCard';
import PiechartCard from './components/PiechartCard';
// import InventorystatusCard from './components/InventorystatusCard';
import SliderCard from './components/SliderCard';
import TopsellerCard from './components/TopsellerCard';
import api from '../../utils/api';
import helper from '../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import moment from 'moment';
// import UpgradePopup from '../../components/UpgradePopup';
import TopMerchantCard from './components/TopMerchantCard';

class Dashboard extends PureComponent {
	constructor(props) {
		super(props);
		let now = new Date();
		let end = moment(
			new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
		);
		let start = moment(end).subtract(32, 'days');

		this.state = {
			isLoading: true,
			start: start,
			end: end,
			fromDate: '',
			toDate: '',
			selectFromDate: '',
			selectToDate: '',
			typeChannel: 'All',
			salesData: [],
			items: [],
			totalAmount: 0,
			totalProducts: 0,
			totalOrders: 0,
			dayCount: 0,
			startDayCount: 0,
			topProducts: [],
			lowStock: [],
			topMerchants: [],
			storeList: [],
			userCompany: '',
			role: '',
			skuList: [],
			loadingText: 0,
			paypalBilling: '',
			// isUpgradePopup:false,
			admin: true,
			pieData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [
							'#8e919f',
							'#6f6f6f',
							'#3b455f',
							'#c77e7e',
							'#9caad6',
							'#61719a',
							'#755050',
							'#ad7b9d',
						],
						hoverBackgroundColor: [
							'#8e919f',
							'#6f6f6f',
							'#3b455f',
							'#c77e7e',
							'#9caad6',
							'#61719a',
							'#755050',
							'#ad7b9d',
						],
					},
				],
			},
			Uid: '',
		};
		this.resetState();
		this.changeFromDate = this.changeFromDate.bind(this);
		this.changeToDate = this.changeToDate.bind(this);
		this.changeDate = this.changeDate.bind(this);
		this.handleChannelChange = this.handleChannelChange.bind(this);
		this.applyCallback = this.applyCallback.bind(this);
	}

	resetState() {
		this.setState({
			salesData: [],
			items: [],
			totalAmount: 0,
			totalOrders: 0,
			dayCount: 0,
			startDayCount: 0,
			loadingText: 0,
			topProducts: [],
			pieData: {
				labels: [],
				datasets: [
					{
						data: [],
						label: 'Dataset 1',
						backgroundColor: [
							'#8e919f',
							'#6f6f6f',
							'#3b455f',
							'#c77e7e',
							'#9caad6',
							'#61719a',
							'#755050',
							'#ad7b9d',
						],
						hoverBackgroundColor: [
							'#8e919f',
							'#6f6f6f',
							'#3b455f',
							'#c77e7e',
							'#9caad6',
							'#61719a',
							'#755050',
							'#ad7b9d',
						],
					},
				],
			},
		});
	}

	componentDidMount() {
		if (this.props.userdetails && this.props.userdetails.role) {
			this.updateUser(this.props.userdetails);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails !== undefined &&
			nextProps.userdetails !== this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	updateUser(user) {
		if (this.state.role === '') {
			console.log('user===', user);
			let sellList = helper.getSellList();
			if (user.role.label !== 'admin') {
				sellList = user.sell_type ? user.sell_type : helper.getSellList();
			}
			let newSellList = [];
			for (let data in sellList) {
				if (
					sellList[data].label !== 'Jet' &&
					sellList[data].label !== 'Groupon'
				) {
					newSellList.push(sellList[data]);
				}
			}
			sellList = newSellList;
			// let isUpgradePopup = false;
			let paypalBilling = user.paypal_billing ? user.paypal_billing : '';
			let admin = user.admin;
			let firstSession = localStorage.getItem('firstsession');
			// if(paypalBilling === '' && !admin&&firstSession){
			// 	isUpgradePopup = true
			// }
			localStorage.removeItem('firstsession');
			this.setState(
				{
					userCompany: user.company,
					role: user.role.label,
					Uid: user.uid,
					storeList: sellList,
					admin: user.admin,
					// isUpgradePopup:isUpgradePopup,
					paypalBilling: user.paypal_billing ? user.paypal_billing : '',
				},
				() => {
					this.getTotalProducts();
					this.getTodayDate();
					this.getTopMerchants();
				},
			);
		}
	}

	getTodayDateObj(){
		let fromDate = new Date();
		let toDate = new Date();
		fromDate.setDate(fromDate.getDate() - 32);
		fromDate = helper.getDateFormat(fromDate);
    	toDate = helper.getDateFormat(toDate);
		return {
    		toDate,
			fromDate,
			selectToDate: toDate,
      		selectFromDate: fromDate
		}
	}

	getTodayDate() {
		let fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 32);
		let toDate = new Date();

		fromDate = helper.getDateFormat(fromDate);
		toDate = helper.getDateFormat(toDate);
		this.setState(
			{
				fromDate: fromDate,
				toDate: toDate,
				selectFromDate: fromDate,
				selectToDate: toDate,
			},
			() => {
				this.getDateReport();
			},
		);
	}

	changeFromDate(value) {
		this.setState(
			{
				fromDate: helper.getDateFormat(value),
				selectFromDate: helper.getDateFormat(value),
			},
			() => {
				// this.getDateReport()
				console.log(
					'changeFromDate===',
					this.state.fromDate,
					this.state.toDate,
				);
			},
		);
	}

	changeToDate(value) {
		this.setState(
			{
				toDate: helper.getDateFormat(value),
				selectToDate: helper.getDateFormat(value),
			},
			() => {
				// this.getDateReport()
				console.log('changeToDate===', this.state.fromDate, this.state.toDate);
			},
		);
	}

	changeDate() {
		this.resetState();
		this.getDateReport();
		this.getTopMerchants();
	}

	getDateReport() {
		let dayDiffernce = helper.diffDays(this.state.fromDate, this.state.toDate);
		console.log(
			'dayDiffernce===',
			this.state.fromDate,
			this.state.toDate,
			dayDiffernce,
		);

		this.setState(
			{
				dayCount: dayDiffernce,
				startDayCount: 0,
				selectFromDate: this.state.fromDate,
				selectToDate: this.state.toDate,
			},
			() => {
				this.getFiveDateReport();
			},
		);
	}

	getFiveDateReport() {
		let startDayCount = this.state.startDayCount;
		let dayCount = this.state.dayCount;

		let fromDate = moment(this.state.selectFromDate).add(
			startDayCount * 10,
			'days',
		);
		if (startDayCount > 0) {
			fromDate = moment(fromDate).add(1, 'days');
		}

		let toDate = moment(this.state.selectFromDate).add(
			10 + startDayCount * 10,
			'days',
		);
		let currentDate = moment(this.state.selectToDate);

		if (currentDate.diff(toDate, 'days') < 0) {
			toDate = currentDate;
		}

		fromDate = helper.getDateFormat(fromDate);
		toDate = helper.getDateFormat(toDate);

		this.setState(
			{
				fromDate: fromDate,
				toDate: toDate,
				time: new Date().getTime(),
			},
			() => {
				this.getData(1);
			},
		);
	}

	applyCallback(startDate, endDate) {
		this.setState(
			{
				start: startDate,
				end: endDate,
				selectFromDate: helper.getDateFormat(endDate),
				selectToDate: helper.getDateFormat(startDate),
				toDate: helper.getDateFormat(endDate),
				fromDate: helper.getDateFormat(startDate),
			},
			() => {
				this.changeDate();
			},
		);
	}

	getTotalProducts() {
		let apiPath = '';
		apiPath = 'inventory/lowStockProducts';
		let reqData = {};
		if (this.state.role !== 'admin') {
			reqData.Uid = this.state.Uid;
		}
		api.postApi(apiPath, reqData).then(
			(data) => {
				if (data.status === 'Success' && data.pagination) {
					let items = data.data;
					for (let item in items) {
						items[item].deliver_stock = items[item].QuantityOnHand;
						if (items[item].GlobalListingAttributes && items[item].GlobalListingAttributes.Price) {
							items[item].price = items[item].GlobalListingAttributes.Price;
						} else {
							items[item].price = 0;
						}
					}
					this.setState({
						totalProducts: data.pagination.TotalNumberOfEntries,
					});
					this.filterLowStock(items);
				}
			},
			(err) => {
				console.log('err====', err);
				helper.errorMessage(err);
			},
		);
	}

	filterLowStock(items) {
		let newItems = [];

		for (let data in items) {
			if (items[data].deliver_stock < 10) {
				newItems.push(items[data]);
			}
		}
		console.log('newItems==', newItems.length);
		this.productCount = 0;
		this.setState(
			{
				lowStock: newItems,
			},
			() => {},
		);
	}

	getData(page) {
		this.setState({ isLoading: true });
		let apiPath = 'orders/getOrders';
		let dayDecrease = new Date(this.state.fromDate);
		dayDecrease.setDate(dayDecrease.getDate() - 1);
		dayDecrease = helper.getDateFormat(dayDecrease);
		let reqData = {
			FromDate: this.state.fromDate + ' 00:00:00',
			ToDate: this.state.toDate + ' 23:59:00',
			DateType: 1,
			OnlyNeedsFulfillment: true,
			Pagination: {
				ResultsPerPage: 200,
				PageNumber: page,
			},
			IncludeCustomerInfo: true,
			IncludeLineItems: true,
		};
		if (this.state.role !== 'admin') {
			reqData.Uid = this.state.Uid;
		}
		api.postApi(apiPath, reqData).then(
			(data) => {
				if (data.status === 'Success') {
					let items = data.data;
					for (let item in items) {
						if (items[item].CustomerInfo) {
							items[item].customer_name = items[item].CustomerInfo.FullName;
							let city = items[item].CustomerInfo.Address.City
								? items[item].CustomerInfo.Address.City + ','
								: '';
							let country = items[item].CustomerInfo.Address.Country
								? items[item].CustomerInfo.Address.Country
								: '';
							items[item].customer_address = city + country;
							items[item].email = items[item].CustomerInfo.EmailAddress;
						}
						if (items[item].LineItems && items[item].LineItems.length > 0) {
							items[item].sku = items[item].LineItems[0].SkuNumber;
							items[item].product_id = items[item].LineItems[0].ProductId;
							items[item].product_title = items[item].LineItems[0].ProductName;
							items[item].volume = items[item].LineItems[0].QuantitySold;
						}
					}

					let startDayCount = this.state.startDayCount;
					let precentageCount = Math.round(this.state.dayCount / 11);
					let loadingPercentage = Math.round(
						100 /
							data.pagination.TotalNumberOfPages *
							data.pagination.CurrentPage /
							precentageCount,
					);

					if (precentageCount > 0) {
						precentageCount = 100 / precentageCount;
					} else {
						precentageCount = 0;
					}

					loadingPercentage = Math.round(
						precentageCount * startDayCount + loadingPercentage,
					);

					if (loadingPercentage > 100) {
						loadingPercentage = 100;
					}

					this.setState({ loadingText: loadingPercentage });

					if (
						data.pagination.CurrentPage < data.pagination.TotalNumberOfPages
					) {
						let newitems = this.state.items;
						newitems = newitems.concat(items);
						this.setState({
							items: newitems,
							totalPage: data.pagination.TotalNumberOfPages,
							totalOrders: data.pagination.TotalNumberOfEntries,
						});
						this.getData(data.pagination.CurrentPage + 1);
					} else {
						let newitems = this.state.items;
						newitems = newitems.concat(items);

						startDayCount = startDayCount + 1;
						this.setState(
							{
								startDayCount: startDayCount,
								items: newitems,
								isLoading: false,
							},
							() => {
								if (startDayCount * 10 < this.state.dayCount - 1) {
									this.getFiveDateReport();
								} else {
									this.filterProduct(newitems, data);
								}
							},
						);
					}
				} else {
					if (data.messages.length > 0) {
						helper.errorMessage(data.messages[0]);
					}
					this.setState({ isLoading: false });
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ isLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	filterProduct(items, data) {
		let storeList = this.state.storeList;
		let newitems = [];
		let totalOrders = 0;
		// console.log('items===', items);
		for (let data in items) {
			for (let data2 in storeList) {
				if (
					items[data].StorefrontType === 'EBay' &&
					storeList[data2].value === 'ebay'
				) {
					items[data].StorefrontName = storeList[data2].value;
					newitems.push(items[data]);
				} else if (
					items[data].StorefrontName === storeList[data2].value ||
					items[data].StorefrontType === storeList[data2].label
				) {
					newitems.push(items[data]);
					items[data].StorefrontName = storeList[data2].value;
				}
			}
		}
		console.log('newitems===', newitems.length);

		for (let data in newitems) {
			let totalAmount = 0;
			let volume = 0;
			newitems[data].price = 0;
			if (newitems[data].Status !== 'Cancelled') {
				for (let data1 in newitems[data].LineItems) {
					volume =
						volume + Number(newitems[data].LineItems[data1].QuantitySold);
					totalAmount =
						totalAmount +
						Number(newitems[data].LineItems[data1].UnitPrice) *
							Number(newitems[data].LineItems[data1].QuantitySold);
					newitems[data].price = Number(
						newitems[data].LineItems[data1].UnitPrice,
					);
				}
				totalOrders = Number(totalOrders) + 1;
			}
			newitems[data].TotalAmount = Number(totalAmount).toFixed(2);
			newitems[data].price = Number(newitems[data].price).toFixed(2);
			newitems[data].volume = volume;
		}

		this.resetGraph(newitems);
		this.getTopProducts(newitems);
		this.setState({
			isLoading: false,
			items: newitems,
			totalPage: data.pagination.TotalNumberOfPages,
			totalOrders: totalOrders,
		});
	}

	resetGraph(items) {
		let storeList = this.state.storeList;
		let salesData = [];
		let timeLabelList = [];
		let diffDays = helper.diffDays(
			this.state.selectFromDate,
			this.state.selectToDate,
		);

		for (let i = 0; i < diffDays + 1; i++) {
			let date = new Date(this.state.selectFromDate);
			date.setDate(date.getDate() + i);

			timeLabelList.push({
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				date: date.getDate(),
			});
			salesData.push({
				days: helper.getMonthName(date.getMonth()) + ' ' + date.getDate(),
				Sales: 0,
				...this.addStore(),
			});
		}
		timeLabelList = timeLabelList.reverse();
		salesData = salesData.reverse();

		for (let data in storeList) {
			storeList[data].totalAmount = 0;
		}

		let totalAmount = 0;
		for (let data in timeLabelList) {
			let Sales = 0;
			for (let data1 in items) {
				if (items[data1].Status !== 'Cancelled') {
					let createDate = new Date(items[data1].PaymentReceivedDate);
					//console.log('createDate===',createDate,createDate.getDate());
					if (
						timeLabelList[data].year === createDate.getFullYear() &&
						timeLabelList[data].month === createDate.getMonth() + 1 &&
						timeLabelList[data].date === createDate.getDate()
					) {
						for (let data2 in storeList) {
							if (
								items[data1].StorefrontName === storeList[data2].value &&
								salesData[data][storeList[data2].value] !== undefined
							) {
								salesData[data][storeList[data2].value] = (Number(
									salesData[data][storeList[data2].value],
								) + Number(items[data1].TotalAmount)).toFixed(2);
								storeList[data2].totalAmount = (Number(
									storeList[data2].totalAmount,
								) + Number(items[data1].TotalAmount)).toFixed(2);
							}
						}
						Sales = Number(Sales) + Number(items[data1].TotalAmount);
					}
				}
			}

			salesData[data].Sales = Sales.toFixed(2);
			totalAmount = Number(totalAmount) + Number(salesData[data].Sales);
			totalAmount = totalAmount.toFixed(2);
		}
		salesData = salesData.reverse();
		for (let item in salesData) {
			if (salesData[item].Amazon) {
				salesData[item].Amazon = Number(salesData[item].Amazon);
			}
			if (salesData[item].ebay) {
				salesData[item].ebay = Number(salesData[item].ebay);
			}
			if (salesData[item].Walmart) {
				salesData[item].Walmart = Number(salesData[item].Walmart);
			}
			if (salesData[item].Sales) {
				salesData[item].Sales = Number(salesData[item].Sales);
			}
		}
		this.setSellingChannel(storeList);
		this.setState({
			salesData: salesData,
			totalAmount: totalAmount,
			storeList: storeList,
		});
	}

	getTopProducts(items) {
		let topProducts = [];
		let arrayProducts = [];
		for (let item in items) {
			if (items[item].Status !== 'Cancelled') {
				let index = arrayProducts.indexOf(items[item].product_id);
				if (index === -1) {
					items[item].price = items[item].price;
					arrayProducts.push(items[item].product_id);
					topProducts.push(Object.assign({}, items[item]));
				} else {
					topProducts[index].volume =
						Number(topProducts[index].volume) + Number(items[item].volume);
					topProducts[index].TotalAmount =
						Number(topProducts[index].TotalAmount) +
						Number(items[item].TotalAmount);
					topProducts[index].TotalAmount = Number(
						topProducts[index].TotalAmount,
					).toFixed(2);
				}
			}
		}
		topProducts = topProducts.sort(this.compare);
		// topProducts=topProducts.slice(0,25);
		// topProducts[index].TotalAmount = topProducts[index].TotalAmount.toFixed(2);
		this.setState({
			topProducts: topProducts,
			topProductsTempItem: topProducts,
		});
		// console.log('arrayProducts==',arrayProducts.length,topProducts);
	}

	getTopMerchants(){
		if (this.state.role !== 'admin') return
		let FromDate = this.state.fromDate || this.getTodayDateObj().selectFromDate;
    	let ToDate = this.state.toDate || this.getTodayDateObj().selectToDate;
		api.getApi(`orders/topMerchants?FromDate=${FromDate}&ToDate=${ToDate}`).then(
			({ data, ...rest}) => {
				if (!data || !data.length || rest.status !== "Success") return;
					this.setState({
						topMerchants: data
					});
				},
			(err) => {
				console.log("err====", err);
				helper.errorMessage(err);
			}
    	);
	}

	handleChannelChange = (status) => {
		let items = this.state.topProductsTempItem;
		let orderItems = [];
		if (status.value === -1) {
			orderItems = items;
		} else {
			for (let data in items) {
				if (items[data].StorefrontName === status.value) {
					orderItems.push(items[data]);
				}
			}
		}

		this.setState({ channel: status, topProducts: orderItems }, () => {});
	};

	compare(a, b) {
		if (Number(a.TotalAmount) < Number(b.TotalAmount)) {
			return 1;
		}
		if (Number(a.TotalAmount) > Number(b.TotalAmount)) {
			return -1;
		}
		return 0;
	}

	setSellingChannel(storeList) {
		let pieData = this.state.pieData;
		let seriesData = [];
		for (let data in storeList) {
			if (storeList[data].value !== 'All') {
				pieData.labels.push(storeList[data].label);
				seriesData.push(Number(storeList[data].totalAmount));
				// pieData.datasets[0].data.push(Math.round(Number(storeList[data].totalAmount)));
			}
		}
		var total = seriesData.length > 0 ? seriesData.reduce((a, v) => a + v) : 0;
		console.log('total===', total);
		if (0 < total) {
			var inPercent = seriesData.map((v) => Math.ceil(v / total * 100));
			pieData.datasets[0].data = inPercent;
			pieData.seriesData = seriesData;
		}

		this.setState({ pieData: { ...pieData }, time: Math.random() });
		// console.log('pieData==',pieData,storeList);
	}

	addStore() {
		let typeChannel = this.state.typeChannel;
		let storeList = this.state.storeList;
		let obj = {};
		for (let data in storeList) {
			if (typeChannel === 'All' || storeList[data].value === typeChannel) {
				obj[storeList[data].value] = 0;
			}
		}
		return obj;
	}

	render() {
		let now = new Date();
		let start = moment(
			new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
		);
		let end = moment(start).add(1, 'days').subtract(1, 'seconds');
		let displayFromDate = helper.getDateFormat(this.state.selectFromDate);
		let displayToDate = helper.getDateFormat(this.state.selectToDate);

		let ranges = {
			Today: [ moment(start), moment(end) ],
			Yesterday: [
				moment(start).subtract(1, 'days'),
				moment(end).subtract(1, 'days'),
			],
			'Last 7 days': [ moment(start).subtract(7, 'days'), moment(end) ],
			'Last 14 days': [ moment(start).subtract(14, 'days'), moment(end) ],
			'This Month': [
				moment(start).subtract(now.getDate() - 1, 'days'),
				moment(end),
			],
			'Last 30 days': [ moment(start).subtract(30, 'days'), moment(end) ],
			'Last Month': [
				moment().subtract(1, 'months').startOf('month'),
				moment().subtract(1, 'months').endOf('month'),
			],
		};

		let local = {
			format: 'MM/DD/YYYY',
			sundayFirst: false,
		};
		let maxDate = moment(start).add(24, 'hour');

		const {
			loadingText,
			role,
			totalAmount,
			topMerchants,
			isLoading,
			topProducts,
			totalProducts,
			totalOrders,
			// isUpgradePopup,
			salesData,
			pieData,
		} = this.state;
		return (
			<Container className='dashboard'>
				{isLoading === true && (
					<div
						className='load'
						style={{
							backgroundColor: 'transparent',
							width: '85%',
							height: '50%',
						}}
					>
						<div className='load__icon-wrap'>
							<svg className='load__icon'>
								<path
									fill='#6c6f7f'
									d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
								/>
							</svg>
							{loadingText} %
						</div>
					</div>
				)}
				{isLoading === false && (
					<Row>
						<Col md={12}>
							<h3 className='page-title'>Overview</h3>
						</Col>
					</Row>
				)}
				{isLoading === false && (
					<Row>
						{/* {isUpgradePopup && <UpgradePopup />} */}
						<DashboardCard
							totalAmount={totalAmount}
							totalProducts={totalProducts}
							totalOrders={totalOrders}
						/>
						<StatisticsCard
							fromDate={displayFromDate}
							toDate={displayToDate}
							salesData={salesData}
							changeDate={this.changeDate}
							changeFromDate={this.changeFromDate}
							changeToDate={this.changeToDate}
							ranges={ranges}
							start={this.state.start}
							end={this.state.end}
							local={local}
							maxDate={maxDate}
							applyCallback={this.applyCallback}
						/>
						<PiechartCard pieData={pieData} />
						{role === 'admin' && <TopMerchantCard items={topMerchants} />}
						{/* {role === 'admin' && <InventorystatusCard items={lowStock} />} */}
						{role !== 'admin' && <SliderCard />}
						<TopsellerCard
							topProducts={topProducts}
							channel={this.state.channel}
							company={this.state.userCompany}
							filename={'topproducts'}
							handleChannelChange={this.handleChannelChange}
						/>
					</Row>
				)}
			</Container>
		);
	}
}

const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(Dashboard);
