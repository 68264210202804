import ebayLogo from '../img/ebay.svg';
import amazonLogo from '../img/Amazon.svg';
import walmartLogo from '../img/Walmart.svg';
import wishLogo from '../img/Wish.svg';
import valetShopsLogo from '../img/Valet Shop.png';
import bonanzaLogo from '../img/Bonanza.svg';
import amazonCanadaLogo from '../img/Amazon Canada.svg';
import walmartCanadaLogo from '../img/Walmart Canada.svg';
import searsLogo from '../img/Sears.svg';
import amazonUAELogo from '../img/amazon.ae.png';
import amazonSingaporeLogo from '../img/amazon.sg.png';
import krogerLogo from '../img/kroger_image.png';
import shopifyLogo from '../img/Shopify.svg';
import bedBathAndBeyond from '../img/bedBathAndBeyond.png';
import wayfairImage from '../img/waifair.png';

const imageMapper = {
    eBay: ebayLogo,
    ebay: ebayLogo,
    Ebay: ebayLogo,
    Walmart: walmartLogo,
    Wish: wishLogo,
    Bonanza: bonanzaLogo,
    "Amazon Canada": amazonCanadaLogo,
    "Walmart Canada": walmartCanadaLogo,
    Sears: searsLogo,
    "Valet Shops": valetShopsLogo,
    Amazon: amazonLogo,
    Kroger: krogerLogo,
    "amazon.ae": amazonUAELogo,
    "amazon.sg": amazonSingaporeLogo,
    "Amazon.ae": amazonUAELogo,
    "Amazon.sg": amazonSingaporeLogo,
    Shopify: shopifyLogo,
    "Valet Shop": valetShopsLogo,
    "bed bath and beyond": bedBathAndBeyond,
    "Bed Bath and Beyond": bedBathAndBeyond,
    "Bed Bath & Beyond": bedBathAndBeyond,
    Wayfair: wayfairImage,
};

const LogoImageUrls = (imageUrl) => {
    return imageMapper[imageUrl];
}

export default LogoImageUrls;
