import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import api from '../../../utils/api';
import DatePicker from 'react-date-picker';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';
import moment from 'moment';
import ImportMissingOrders from './importMissingOrders';
import { trackingModel } from '../../../utils/validationSchemas';
import OrdersTable from './returnsTable';

import {
	Card,
	CardBody,
	Col,
	Button,
	Input,
	FormGroup,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import {
	LineChart,
	Line,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import Select from 'react-select';
import SalesWalkthrough from '../../Walkthrough/component/SalesWalkthrogh';

class SalesCard extends PureComponent {
	constructor(props) {
		super(props);
		let end = moment(new Date());
		let start = moment(end).subtract(5, 'days');

		this.state = {
			isLoading: false,
			searchText: '',
			selectFromDate: '',
			selectToDate: '',
			dayCount: 5,
			startDayCount: 0,
			start: start,
			end: end,
			headers: [
				{ key: 'sku', title: 'SKU' },
				{ key: 'product_title', title: 'Product Title' },
				{ key: 'price', title: 'Price' },
				{ key: 'TotalAmount', title: 'Sales' },
				{ key: 'volume', title: 'Quantity Ordered' },
			],
			orderCsvHeaders: [
				{ key: 'order_id', title: 'Order Id' },
				{ key: 'PaymentReceivedDate', title: 'Date' },
				{ key: 'StorefrontName', title: 'Channel' },
				{ key: 'customer_name', title: 'Customer Name' },
				{ key: 'customer_address', title: 'Shipping Address' },
				{ key: 'email', title: 'Email/Phone No' },
				{ key: 'sku', title: 'SKU' },
				{ key: 'product_title', title: 'Product Title' },
				{ key: 'Status', title: 'Sale Status' },
				{ key: 'shipment_status', title: 'Shipment Status' },
				// { key: 'DeliveryMethod', title: 'Delivery Method' },
				{ key: 'TotalAmount', title: 'Price' },
				{ key: 'volume', title: 'Quantity Ordered' },
				{ key: 'total_stock', title: 'Total Stock' },
			],
			orderHeaders: [
				{ key: 'order_details', title: 'Order Details' },
				{ key: 'order_id', title: 'Order Id' },
				{ key: 'PaymentReceivedDate', title: 'Date' },
				{ key: 'StorefrontName', title: 'Channel' },
				{ key: 'customer_name', title: 'Customer Name' },
				// { key: 'customer_address', title: 'Shipping Address' },
				// { key: 'email', title: 'Email/Phone No' },
				// { key: 'sku', title: 'SKU' },
				// { key: 'product_title', title: 'Product Title' },
				{ key: 'Status', title: 'Sale Status' },
				{ key: 'shipment_status', title: 'Shipment Status' },
				{ key: 'PaidAt', title: 'Billed' },
				// { key: 'DeliveryMethod', title: 'Delivery Method' },
				{ key: 'TotalAmount', title: 'Price' },
				{ key: 'volume', title: 'Quantity Ordered' },
				{ key: 'total_stock', title: 'Total Stock' },
				// { key: 'track_update', title: 'Tracking' },
			],
			// topHeaders: [
			// 	// { key: 'order_id', title: 'Order Id' },
			// 	// { key: 'DateCreatedInEcomdash', title: 'Date' },
			// 	{ key: 'StorefrontName', title: 'Channel' },
			// 	//{ key: 'customer_name', title: 'Customer Name' },
			// 	//{ key: 'customer_address', title: 'Shipping Address' },
			// 	//{ key: 'email', title: 'Email/Phone No' },
			// 	{ key: 'sku', title: 'SKU' },
			// 	{ key: 'product_title', title: 'Product Title' },
			// 	//{ key: 'Status', title: 'Sale Status' },
			// 	//{ key: 'shipment_status', title: 'Shipment Status' },
			// 	{ key: 'TotalAmount', title: 'Total Revenue' },
			// 	{ key: 'volume', title: 'Quantity Ordered' },
			// ],
			status: { value: -1, label: 'All' },
			statusFilter: [
				{ value: -1, label: 'All' },
				{ value: 0, label: 'Cancelled' },
				{ value: 1, label: 'Complete' },
				{ value: 2, label: 'InProcess' },
				{ value: 3, label: 'New' },
			],
			channel: { value: -1, label: 'All' },
			channelFilter: [ { value: -1, label: 'All' } ],
			name: 'sales',
			loadingText: 0,
			items: [],
			orderItems: [],
			tempItems: [],
			totalPage: 0,
			tableLoading: true,
			fromDate: '',
			toDate: '',
			salesData: [],
			totalAmount: 0,
			topProducts: [],
			typeChannel: 'All',
			userCompany: '',
			skuList: [],
			pages: [],
			role: '',
			Uid: '',
			storeList: [],
			carrierList: [],
			trackingUrl: '',
			trackingNumber: '',
			importOrdersFromEcomdash: false,
			validateTracking: {},
			itemShipped: false,
			returns: [],
		};

		this.getData = this.getData.bind(this);
		this.applyCallback = this.applyCallback.bind(this);
		this.toggleUpdate = this.toggleUpdate.bind(this);
		this.trackUpdate = this.trackUpdate.bind(this);
		this.addReturns = this.addReturns.bind(this);
		this.searchOrders = this.searchOrders.bind(this);
		this.saveTrackingDetails = this.saveTrackingDetails.bind(this);
		this.alertOnFailure = this.alertOnFailure.bind(this);
		this.getReturns = this.getReturns.bind(this);
	}

	resetState() {
		this.setState({
			items: [],
			returns: [],
			loadingText: 0,
			tempItems: [],
			totalPage: 0,
			salesData: [],
			totalAmount: 0,
			topProducts: [],
		});
	}

	componentDidMount() {
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
		}
	}
	toggleUpdate() {
		this.setState({
			small: !this.state.small,
			validateTracking: {},
		});
	}

	updateTrackingForShippedItem() {
		const { trackingNumber, trackingUrl, carrier } = this.state;
		const validateTracking =  trackingModel.check({
			trackingNumber,
			trackingUrl,
			carrier: Boolean(carrier) ? carrier.label : null,
		});

		let trackingHasError = false;

		for (let validation of Object.keys(validateTracking)) {
			if (validateTracking[validation] && validateTracking[validation].hasError) {
				trackingHasError = true;
				helper.errorMessage(validateTracking[validation] && validateTracking[validation].errorMessage);
			}
		}
		if (trackingHasError) {
			this.setState({
				validateTracking,
			});
			return;
		}
		this.setState({
			validateTracking: {},
		});
		api.postApi('emailTrackingInfo', {
			trackingNumber,
			trackingUrl,
			carrier,
			ecomdashId: this.trackOrder.EcomdashId,
		})
			.then((response) => {
				this.toggleUpdate();
				this.setState({
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
				});
				helper.successMessage(response.messages);
			})
			.catch((error) => {
				this.toggleUpdate();
				this.setState({
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
				});
				helper.errorMessage('Something went wrong, try again later.')
			});
	}
	deleteModel() {
		this.toggleUpdate();
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}
	updateUser(user) {
		//console.log('user====', user);
		if (this.state.role == '') {
			let sellList = helper.getSellList();
			let channelFilter = this.state.channelFilter;
			if (user.role && user.role.label != 'admin') {
				sellList = user.sell_type ? user.sell_type : helper.getSellList();
			}
			let newSellList = [];
			for (let data in sellList) {
				if (
					sellList[data].label != 'Jet' &&
					sellList[data].label != 'Groupon'
				) {
					newSellList.push(sellList[data]);
				}
			}
			sellList = newSellList;
			channelFilter = channelFilter.concat(sellList);

			sellList.push({ label: 'Total Sales', value: 'Sales' });
			let colors = [
				'#8e919f',
				'#6f6f6f',
				'#3b455f',
				'#c77e7e',
				'#9caad6',
				'#61719a',
				'#755050',
				'#ad7b9d',
			];

			for (let data in sellList) {
				sellList[data].visible = true;
				if (sellList[data].value == 'Walmart Canada') {
					sellList[data].className = 'WalmartCanada';
				} else if (sellList[data].value == 'Valet Shop') {
					sellList[data].className = 'ValetShop';
				} else if (sellList[data].value == 'Amazon Canada') {
					sellList[data].className = 'AmazonCanada';
				} else {
					sellList[data].className = sellList[data].value;
				}
				sellList[data].colors = colors[data] ? colors[data] : '#8e919f';
			}
			sellList[sellList.length - 1].colors = '#000';

			let orderHeaders = this.state.orderHeaders;
			if (user.ship_orders && user.ship_orders.value == 'valetseller') {
				orderHeaders.push({ key: 'view', title: 'Ship Details' });
			}
			
			if (user.role && user.role.label == 'admin') {
				orderHeaders.push({ key: 'returns', title: 'Returns' });
			}

			this.setState(
				{
					userCompany: user.company,
					role: user.role.label,
					storeList: sellList,
					channelFilter: channelFilter,
					Uid: user.uid,
					orderHeaders: orderHeaders,
					email: user.email,
				},
				() => {
					this.getTodayDate();
					this.getCarriers();
				},
			);
		}
	}

	getCarriers() {
		api.getEcomDashApi('carriers?resultsPerPage=100').then((data) => {
			if (data.status == 'Success') {
				let carrierList = [];
				for (let item of data.data) {
					if (
						item.Id == 7607 ||
						item.Id == 7605 ||
						item.Id == 7606 ||
						item.Id == 24064 ||
						item.Id == 7608
					) {
						carrierList.push({ label: item.Name, value: item.Id });
					}
				}
				this.setState({ carrierList: carrierList });
			}
		});
	}

	getTodayDate() {
		let fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 5);
		let toDate = new Date();

		fromDate = helper.getDateFormat(fromDate);
		toDate = helper.getDateFormat(toDate);
		this.setState(
			{
				fromDate: fromDate,
				toDate: toDate,
				selectFromDate: fromDate,
				selectToDate: toDate,
				dayCount: 5,
			},
			() => {
				this.getData(1);
			},
		);
	}

	getReturns(reqData) {
		api.postApi('returns', reqData)
			.then((response) => {
				const newReturns = [...this.state.returns];
				this.setState({ returns: newReturns.concat(response.data).filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i) })
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getData(page, searchKeyWord = null, ToDate=null, FromDate=null) {
		if (searchKeyWord || ToDate || FromDate) {
			this.setState({
				items: [],
			})
		}
		this.setState({ tableLoading: true });
		let apiPath = 'orders/getOrders';
		const currentTime = moment(new Date());
		let reqData = {
			ToDate: (ToDate && ToDate.toISOString()) || this.state.end.toISOString(),
			FromDate: (FromDate && FromDate.toISOString()) || this.state.start.toISOString(),
			searchKeyWord, 
			Pagination: {
				ResultsPerPage: 200,
				PageNumber: page,
			},
		};
		if (this.state.role != 'admin') {
			reqData.Uid = this.state.Uid;
		}
		this.getReturns(reqData);
		//console.log('reqData==',reqData);
		api.postApi(apiPath, reqData).then(
			(data) => {
				if (data.status == 'Success') {
					let items = data.data;
					//	console.log(items);
					for (let item in items) {
						let customerAddress = '';
						if (items[item].CustomerInfo) {
							items[item].customer_name = items[item].CustomerInfo.FullName;
							items[item].email = items[item].CustomerInfo.EmailAddress;
						}
						if (items[item].ShippingInfo && items[item].ShippingInfo.Address) {
							customerAddress = items[item].ShippingInfo.Address.Street1
								? items[item].ShippingInfo.Address.Street1
								: '';
							customerAddress =
								items[item].ShippingInfo.Address.Street1 == undefined
									? ''
									: customerAddress + ', ';

							customerAddress =
								customerAddress +
								(items[item].ShippingInfo.Address.Street2
									? items[item].ShippingInfo.Address.Street2
									: '');
							customerAddress =
								items[item].ShippingInfo.Address.Street2 == undefined
									? customerAddress
									: customerAddress + ', ';

							customerAddress =
								customerAddress +
								(items[item].ShippingInfo.Address.City
									? items[item].ShippingInfo.Address.City
									: '');
							customerAddress =
								items[item].ShippingInfo.Address.City == undefined
									? customerAddress
									: customerAddress + ', ';

							customerAddress =
								customerAddress +
								(items[item].ShippingInfo.Address.State
									? items[item].ShippingInfo.Address.State
									: '');
							customerAddress =
								items[item].ShippingInfo.Address.State == undefined
									? customerAddress
									: customerAddress + ', ';

							customerAddress =
								customerAddress +
								(items[item].ShippingInfo.Address.ZipCode
									? items[item].ShippingInfo.Address.ZipCode
									: '');
							customerAddress =
								items[item].ShippingInfo.Address.ZipCode == undefined
									? customerAddress
									: customerAddress + ', ';

							customerAddress =
								customerAddress +
								(items[item].ShippingInfo.Address.Country
									? items[item].ShippingInfo.Address.Country
									: '');
						}
						if (items[item].IsFBA) {
							customerAddress = 'FBA Order';
						}
						items[item].customer_address = customerAddress;
						if (items[item].LineItems && items[item].LineItems.length > 0) {
							items[item].sku = items[item].LineItems[0].SkuNumber;
							items[item].product_id = items[item].LineItems[0].ProductId;
							items[item].product_title = items[item].LineItems[0].ProductName;
						}
						items[item].shipment_status = items[item].NeedsFulfillment
							? 'InProcess'
							: 'Shipped';
						if (items[item].shipment_status == 'Shipped') {
							if (items[item].Status == 'InProcess') {
								items[item].shipment_status = 'InProcess';
							}
						}
						if (items[item].Status == 'Cancelled') {
							items[item].shipment_status = 'Cancelled';
						}
						items[item].order_id = items[item].IsShopifyOrder
							? items[item].OrderNumber
							: items[item].StorefrontOrderNumber;
					}

					let startDayCount = this.state.startDayCount;
					let precentageCount = Math.round(this.state.dayCount / 11);
					let loadingPercentage = Math.round(
						100 /
							data.pagination.TotalNumberOfPages *
							data.pagination.CurrentPage /
							precentageCount,
					);

					if (precentageCount > 0) {
						precentageCount = 100 / precentageCount;
					} else {
						precentageCount = 0;
					}

					loadingPercentage = Math.round(
						precentageCount * startDayCount + loadingPercentage,
					);

					if (loadingPercentage > 100) {
						loadingPercentage = 100;
					}
					
					this.setState({ loadingText: loadingPercentage });
					// console.log('data.pagination===',data.pagination.CurrentPage,'TotalNumberOfPages==',data.pagination.TotalNumberOfPages);
					const pages = [...this.state.pages];
					if (
						data.pagination.CurrentPage < data.pagination.TotalNumberOfPages
					) {
						console.log('^%£$%^&*-----', items);
						let newitems = this.state.items;
						newitems = newitems.concat(items);
						this.setState({
							items: newitems,
							totalPage: 1,
							orderItems: newitems,
							tempItems: newitems,
						});
						if (!pages.includes(data.pagination.CurrentPage)) {
							this.getData(data.pagination.CurrentPage + 1, searchKeyWord, ToDate, FromDate);
						}
						pages.push(data.pagination.CurrentPage);
						this.setState({ pages: new Set(pages) })
					} else {
						let newitems = this.state.items;
						newitems = newitems.concat(items).filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i);
						console.log('^%£$%^&*', newitems);
						let startDayCount = this.state.startDayCount;
						startDayCount = startDayCount + 1;
						this.setState(
							{
								startDayCount: startDayCount,
								items: newitems,
								isLoading: false,
								pages: [],
							},
							() => {
								if (startDayCount * 10 < this.state.dayCount - 1) {
									this.getFiveDateReport();
								} else {
									newitems = newitems.sort(function(a, b) {
										if (
											a.DateCreatedInEcomdash &&
											a.DateCreatedInEcomdash != '' &&
											b.DateCreatedInEcomdash &&
											b.DateCreatedInEcomdash != ''
										) {
											return (
												new Date(b.DateCreatedInEcomdash) -
												new Date(a.DateCreatedInEcomdash)
											);
										} else {
											return -1;
										}
									});
									console.log('££££££', newitems)
									this.filterProduct(newitems, data);
								}
							},
						);
					}
				} else {
					if (data.messages.length > 0) {
						helper.errorMessage(data.messages[0]);
					}
					this.setState({ tableLoading: false });
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	filterProduct(items, data) {
		let that = this;
		let storeList = this.state.storeList;
		console.log('hahah', storeList)
		let newitems = [];
		console.log('items===', items.length);
		for (let data in items) {
			for (let data2 in storeList) {
				if (
					items[data].StorefrontType == 'EBay' &&
					storeList[data2].value == 'ebay'
				) {
					items[data].StorefrontName = storeList[data2].value;
					newitems.push(items[data]);
				} else if (
					items[data].StorefrontName == storeList[data2].value ||
					items[data].StorefrontType == storeList[data2].label
				) {
					newitems.push(items[data]);
					items[data].StorefrontName = storeList[data2].value;
				}
				else if (
					items[data].StorefrontType == 'Bed Bath & Beyond' &&
					storeList[data2].value == 'bed bath and beyond'
				) {
					items[data].StorefrontName = 'Bed Bath & Beyond';
					newitems.push(items[data]);
				}
			}
		}
		//
		for (let data in newitems) {
			let totalAmount = 0;
			let volume = 0;

			if (newitems[data].Status != 'Cancelled') {
				for (let data1 in newitems[data].LineItems) {
					volume =
						volume + Number(newitems[data].LineItems[data1].QuantitySold);
						newitems[data].price = Number(
							newitems[data].LineItems[data1].UnitPrice,
						) 
						- Number(newitems[data].DiscountAmount);
						newitems[data].LineItems[data1].UnitPrice = Number(
							newitems[data].LineItems[data1].UnitPrice,
						) 
						- Number(newitems[data].DiscountAmount);
						newitems[data].LineItems[data1].LineItemTotal = Number(
							newitems[data].LineItems[data1].LineItemTotal,
						) 
						- Number(newitems[data].DiscountAmount);
						totalAmount =
							totalAmount +
							Number(newitems[data].price) *
								Number(newitems[data].LineItems[data1].QuantitySold);
				}
				newitems[data].TotalAmount = Number(totalAmount).toFixed(2);
				newitems[data].price = Number(newitems[data].price).toFixed(2);
				newitems[data].volume = volume;
			}
		}

		//newitems = newitems.reverse();
		this.updateTotalStock(newitems, data.pagination.TotalNumberOfPages);
	}

	updateTotalStock(newitems, totalPage) {
		let sku = [];
		for (let data in newitems) {
			newitems[data].total_stock = 0;
			if (sku.indexOf(newitems[data].sku) == -1) {
				sku.push(newitems[data].sku);
			}
		}
		console.log(sku.length, newitems.length);
		let reqData = {
			skus: sku,
		};
		//  console.log(reqData);
		api.postApi('inventory/getProductBalances', reqData).then(
			(data) => {
				if (data.status == 'Success') {
					let productBalancesData = data.data;
					for (let item in newitems) {
						for (let item1 in productBalancesData) {
							if (productBalancesData[item1].Sku == newitems[item].sku) {
								newitems[item].total_stock =
									productBalancesData[item1].QuantityOnHand;
							}
						}
					}
				}

				this.resetGraph(newitems);
				this.getTopProducts(newitems);
				this.setState({
					tableLoading: false,
					totalPage: totalPage,
					items: newitems,
					orderItems: newitems,
					tempItems: newitems,
				});
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	changeType(index) {
		let storeList = this.state.storeList;
		storeList[index].visible = !storeList[index].visible;
		this.setState({ storeList: storeList }, () => {
			this.resetGraph(this.state.items);
		});
	}

	addStore() {
		let typeChannel = this.state.typeChannel;
		let storeList = this.state.storeList;
		let obj = {};
		for (let data in storeList) {
			if (storeList[data].visible) {
				obj[storeList[data].value] = 0;
			}
		}
		return obj;
	}
	resetGraph(items) {
		let storeList = this.state.storeList;
		let salesData = [];
		let timeLabelList = [];
		let diffDays = helper.diffDays(
			this.state.selectFromDate,
			this.state.selectToDate,
		);

		console.log(
			'graph===',
			this.state.selectFromDate,
			this.state.selectToDate,
			diffDays,
		);

		for (let i = 0; i < diffDays + 1; i++) {
			let date = new Date(this.state.selectFromDate);
			date.setDate(date.getDate() + i);

			timeLabelList.push({
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				date: date.getDate(),
			});
			salesData.push({
				days: helper.getMonthName(date.getMonth()) + ' ' + date.getDate(),
				Sales: 0,
				...this.addStore(),
			});
		}
		timeLabelList = timeLabelList.reverse();

		salesData = salesData.reverse();

		let totalAmount = 0;
		for (let data in timeLabelList) {
			let Sales = 0;
			for (let data1 in items) {
				if (items[data1].Status != 'Cancelled') {
					let createDate = new Date(items[data1].PaymentReceivedDate);
					if (
						timeLabelList[data].year == createDate.getFullYear() &&
						timeLabelList[data].month == createDate.getMonth() + 1 &&
						timeLabelList[data].date == createDate.getDate()
					) {
						for (let data2 in storeList) {
							// console.log(items[data1].StorefrontName,storeList[data2].value);
							if (
								items[data1].StorefrontName == storeList[data2].value &&
								salesData[data][storeList[data2].value] != undefined
							) {
								salesData[data][storeList[data2].value] = (Number(
									salesData[data][storeList[data2].value],
								) + Number(items[data1].TotalAmount)).toFixed(2);
							}
						}
						Sales = Number(Sales) + Number(items[data1].TotalAmount);
					}
				}
			}

			// console.log('Sales=====',Sales);
			if (storeList[storeList.length - 1].visible) {
				salesData[data].Sales = Number(Sales).toFixed(2);
			}
			totalAmount = Number(totalAmount) + Number(Sales);
			totalAmount = totalAmount.toFixed(2);
		}

		//  salesData = salesData.reverse();
		salesData = salesData.reverse();
		for (let item in salesData) {
			if (salesData[item].Amazon) {
				salesData[item].Amazon = Number(salesData[item].Amazon);
			}
			if (salesData[item].ebay) {
				salesData[item].ebay = Number(salesData[item].ebay);
			}
			if (salesData[item].Walmart) {
				salesData[item].Walmart = Number(salesData[item].Walmart);
			}
			if (salesData[item].Sales) {
				salesData[item].Sales = Number(salesData[item].Sales);
			}
		}
		this.setState({ salesData: salesData, totalAmount: totalAmount });
	}

	getTopProducts(items) {
		let topProducts = [];
		let arrayProducts = [];
		for (let item in items) {
			if (items[item].Status != 'Cancelled') {
				let index = arrayProducts.indexOf(items[item].product_id);
				if (index == -1) {
					arrayProducts.push(items[item].product_id);
					items[item].price = items[item].price;
					topProducts.push(Object.assign({}, items[item]));
				} else {
					topProducts[index].price = Number(items[item].price);
					topProducts[index].volume =
						Number(topProducts[index].volume) + Number(items[item].volume);
					topProducts[index].TotalAmount =
						Number(topProducts[index].TotalAmount) +
						Number(items[item].TotalAmount);
					topProducts[index].TotalAmount = Number(
						topProducts[index].TotalAmount,
					).toFixed(2);
				}
			}
		}
		topProducts = topProducts.sort(this.compare);
		// topProducts=topProducts.slice(0,30);
		this.setState({ topProducts: topProducts });
	}

	compare(a, b) {
		if (Number(a.TotalAmount) < Number(b.TotalAmount)) {
			return 1;
		}
		if (Number(a.TotalAmount) > Number(b.TotalAmount)) {
			return -1;
		}
		return 0;
	}

	changeFromDate(value) {
		this.setState(
			{
				fromDate: helper.getDateFormat(value),
				selectFromDate: helper.getDateFormat(value),
			},
			() => {
				// this.getDateReport()
			},
		);
	}

	changeToDate(value) {
		this.setState(
			{
				toDate: helper.getDateFormat(value),
				selectToDate: helper.getDateFormat(value),
			},
			() => {
				// this.getDateReport()
			},
		);
	}

	changeDate() {
		this.resetState();
		this.getDateReport();
	}

	getDateReport() {
		let dayDiffernce = helper.diffDays(this.state.fromDate, this.state.toDate);
		console.log('dayDiffernce=====', dayDiffernce);

		this.setState(
			{
				dayCount: dayDiffernce,
				startDayCount: 0,
				selectFromDate: this.state.fromDate,
				selectToDate: this.state.toDate,
			},
			() => {
				this.getFiveDateReport();
			},
		);
	}

	getFiveDateReport() {
		let startDayCount = this.state.startDayCount;
		let dayCount = this.state.dayCount;

		let fromDate = moment(this.state.selectFromDate).add(
			startDayCount * 10,
			'days',
		);
		if (startDayCount > 0) {
			fromDate = moment(fromDate).add(1, 'days');
		}

		let toDate = moment(this.state.selectFromDate).add(
			10 + startDayCount * 10,
			'days',
		);
		let currentDate = moment(this.state.selectToDate);

		if (currentDate.diff(toDate, 'days') < 0) {
			toDate = currentDate;
		}

		fromDate = helper.getDateFormat(fromDate);
		toDate = helper.getDateFormat(toDate);

		this.setState({ fromDate: fromDate, toDate: toDate }, () => {
			this.getData(1);
		});
	}

	handleSatusChange = (status) => {
		let items = this.state.tempItems;
		let orderItems = [];

		if (status.value == -1) {
			orderItems = items;
		} else {
			for (let data in items) {
				if (items[data].Status == status.label) {
					if (
						this.state.channel.value == -1 ||
						this.state.channel.value == items[data].StorefrontName
					) {
						orderItems.push(items[data]);
					}
				}
			}
		}
		console.log(orderItems.length);
		this.setState({ status, orderItems: orderItems }, () => {});
	};

	handleChannelChange = (status) => {
		let items = this.state.tempItems;
		let orderItems = [];
		if (status.value == -1) {
			orderItems = items;
		} else {
			for (let data in items) {
				if (items[data].StorefrontName == status.value) {
					orderItems.push(items[data]);
				}
			}
		}

		this.setState({ channel: status, orderItems: orderItems }, () => {});
	};

	applyCallback(startDate, endDate) {
		console.log(
			startDate,
			endDate,
			helper.getDateFormat(startDate),
			helper.getDateFormat(endDate),
		);
		this.setState(
			{
				start: startDate,
				end: endDate,
				selectFromDate: helper.getDateFormat(endDate),
				selectToDate: helper.getDateFormat(startDate),
				toDate: helper.getDateFormat(endDate),
				fromDate: helper.getDateFormat(startDate),
			},
			() => {
				this.changeDate();
				this.getData(1, null, startDate, endDate);
			},
		);
	}

	trackUpdate(OrderNumber, shipped = false) {
		console.log('OrderNumber', OrderNumber);
		this.trackOrder = helper.getItem(
			this.state.items,
			'OrderNumber',
			OrderNumber,
		);
		console.log('item===', this.trackOrder);
		this.setState({ small: !this.state.small, itemShipped: shipped });
	}

	handlecarrierChange = (carrier) => {
		this.setState({ carrier });
	};

	addReturns(observstion, id,returnDate, returnValue) {
		console.log('observstion,id===', observstion, id);
		this.setState({ tableLoading: true });
		let req = {
			EcomdashId: id,
			Returns: {
				Observstion: observstion,
				Date: new Date(returnDate),
				returnValue,
			},
		};
		//console.log('req===',req);
		api.postApi('orders/addReturns', req).then(
			(shippmentInfo) => {
				helper.successMessage('Sucessfully updated');
				let items = this.state.items;
				let trackIndex = helper.getItemIndex(
					this.state.items,
					'EcomdashId',
					id,
				);
				if (items[trackIndex]) {
					items[trackIndex].Returns = req.Returns;
				}
				this.setState({ tableLoading: false, items: [ ...items ] });
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				//helper.errorMessage(err);
			},
		);
	}

	saveTrackingDetails() {
		const { trackingNumber, trackingUrl, carrier } = this.state;
		const { EcomdashId } = this.trackOrder;
		api.postApi('orders/saveTrackingDetails', {
			trackingNumber,
			trackingUrl,
			carrier,
			EcomdashId,
		})
			.then((response) => {
				console.log('res', response);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	alertOnFailure() {
		const { trackingNumber, trackingUrl, carrier } = this.state;
		const { EcomdashId, StorefrontOrderNumber, LineItems } = this.trackOrder;
		const data = {
			trackingNumber,
			trackingUrl,
			carrier,
			EcomdashId,
			StorefrontOrderNumber,
			LineItems,
		}
		if (trackingNumber && carrier && carrier.label) {
			api.postApi('orders/alertOnFailure', data)
				.then((response) => {
					console.log('res', response);
				})
				.catch((error) => {
					console.log(error);
				});
		};
	}

	saveTrack() {
		const { trackingNumber, trackingUrl, carrier } = this.state;
		const validateTracking =  trackingModel.check({
			trackingNumber,
			trackingUrl,
			carrier: Boolean(carrier) ? carrier.label : null,
		});

		let trackingHasError = false;

		for (let validation of Object.keys(validateTracking)) {
			if (validateTracking[validation] && validateTracking[validation].hasError) {
				trackingHasError = true;
				helper.errorMessage(validateTracking[validation] && validateTracking[validation].errorMessage);
			}
		}
		if (trackingHasError) {
			this.setState({
				validateTracking,
			});
			return;
		}
		this.setState({
			validateTracking: {},
		});
		if (this.state.trackingNumber == '') {
			helper.successMessage('Tracking number field is required');
		} else {
			// else if (this.state.trackingUrl == '') {
			//   helper.successMessage('Tracking url field is required');
			// }
			console.log(this.state.carrier, this.trackOrder);

			this.setState({ tableLoading: true, small: false });

			api
				.getEcomDashApi('orders/' + this.trackOrder.EcomdashId + '/shipments')
				.then(
					(shippmentInfo) => {
						console.log('shippmentInfo===', shippmentInfo);
						if (shippmentInfo.status == 'Success') {
							this.saveTrackingDetails();
							if (shippmentInfo.data.length === 0) {
								this.createShipmentId();
							} else {
								this.createTrackingInfo(
									shippmentInfo.data[0].Id,
									shippmentInfo.data[0].Items,
								);
							}
						} else {
							this.setState({
								trackingNumber: '',
								trackingUrl: '',
								carrier: '',
								tableLoading: false,
							});
						}
					},
					(err) => {
						console.log('err====', err);
						this.alertOnFailure();
						helper.errorMessage(err);
						this.setState({
							trackingNumber: '',
							trackingUrl: '',
							carrier: '',
							tableLoading: false,
						});
					},
				);
		}
	}

	createShipmentId() {
		let lineItems = [];

		for (let data1 in this.trackOrder.LineItems) {
			let items = {
				LineItemId: this.trackOrder.LineItems[data1].OrderLineItemId,
				quantity: this.trackOrder.LineItems[data1].QuantitySold,
			};
			lineItems.push(items);
		}

		var reqData = [
			{
				OrderId: this.trackOrder.EcomdashId,
				LineItems: lineItems,
			},
		];
		console.log('reqData==', reqData);
		api.postEcomDashApi('Shipments/Create', reqData).then(
			(shippmentInfo) => {
				let shippmentId = '';
				if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].WasSuccessful
				) {
					shippmentId = shippmentInfo.data[0].Shipment.Id;
					this.createTrackingInfo(
						shippmentId,
						shippmentInfo.data[0].Shipment.Items,
					);
				} else if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].Messages &&
					0 < shippmentInfo.data[0].Messages.length
				) {
					helper.errorMessage(shippmentInfo.data[0].Messages[0]);
					this.alertOnFailure();
					console.log(
						'===Not create shippment===',
						shippmentInfo.data[0].Messages[0],
					);
					this.setState({
						tableLoading: false,
						trackingNumber: '',
						trackingUrl: '',
						carrier: '',
					});
				} else {
					helper.errorMessage('please try again later');
					this.alertOnFailure();
					console.log('===Not create shippment===', shippmentInfo);
					this.setState({
						tableLoading: false,
						trackingNumber: '',
						trackingUrl: '',
						carrier: '',
					});
				}
			},
			(err) => {
				console.log('err====', err);
				this.alertOnFailure();
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
				this.setState({
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
				});
			},
		);
	}

	createTrackingInfo(shipmentId, LineItems) {
		let newLineItems = [];
		for (let data1 in LineItems) {
			let lineItems1 = {
				LineItemId: Number(LineItems[data1].Id),
				TrackingNumber: this.state.trackingNumber,
				TrackingURL: this.state.trackingUrl,
			};
			if (this.state.carrier != null) {
				lineItems1.CarrierId = Number(this.state.carrier.value);
			}
			newLineItems.push(lineItems1);
		}

		let reqItems = {
			ShipmentId: Number(shipmentId),
			LineItems: newLineItems,
		};
		if (this.state.carrier != null) {
			reqItems.CarrierId = Number(this.state.carrier.value);
		}

		let newReq = [ reqItems ];

		api.postEcomDashApi('Shipments/submitTrackingInfo', newReq).then(
			(shippmentInfo) => {
				if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].WasSuccessful
				) {
					this.updateOrders();
				} else if (
					shippmentInfo &&
					shippmentInfo.data &&
					shippmentInfo.data[0].Messages
				) {
					this.alertOnFailure();
					helper.errorMessage(shippmentInfo.data[0].Messages[0]);
					this.setState({
						trackingNumber: '',
						trackingUrl: '',
						carrier: '',
						tableLoading: false,
					});
				} else {
					this.alertOnFailure()
					helper.errorMessage('please try again later');
					this.setState({
						trackingNumber: '',
						trackingUrl: '',
						carrier: '',
						tableLoading: false,
					});
				}
			},
			(err) => {
				console.log('err====', err);
				this.alertOnFailure();
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
				this.setState({
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
				});
			},
		);
	}

	updateOrders() {
		let req = {
			orderId: this.trackOrder.EcomdashId,
		};
		this.setState({ tableLoading: true });
		api.postApi('orders/updateOrders', req).then(
			(shippmentInfo) => {
				helper.successMessage('Sucessfully updated');
				let items = this.state.items;
				let trackIndex = helper.getItemIndex(
					this.state.items,
					'OrderNumber',
					this.trackOrder.OrderNumber,
				);
				if (items[trackIndex]) {
					items[trackIndex].shipment_status = 'Shipped';
				}
				console.log('shippmentInfo==', shippmentInfo, items);
				this.setState({
					tableLoading: false,
					items: [ ...items ],
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
				});
			},
			(err) => {
				console.log('err====', err);
				this.setState({
					trackingNumber: '',
					trackingUrl: '',
					carrier: '',
					tableLoading: false,
				});
				//helper.errorMessage(err);
			},
		);
	}

	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				this.actionSearch();
			},
		);
	}

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
		}
	};

	actionSearch() {
		let filterText = this.state.searchText;
		let items = this.state.tempItems;

		if (filterText != '') {
			let filterItems = [];
			items.map((item, index) => {
				if (this.headerFilter(filterText, item)) {
					filterItems.push(item);
				}
			});
			console.log(filterItems);
			this.setState({ orderItems: filterItems });
		} else {
			this.setState({ orderItems: items });
		}
	}

	headerFilter(filterText, item) {
		let isFilter = false;
		for (let data in this.state.orderHeaders) {
			if (
				item[this.state.orderHeaders[data].key] &&
				item[this.state.orderHeaders[data].key].label &&
				String(item[this.state.orderHeaders[data].key].label)
					.replace(/\s/g, '')
					.toLowerCase()
					.indexOf(filterText.replace(/\s/g, '').toLowerCase()) !== -1
			) {
				isFilter = true;
			} else if (
				item[this.state.orderHeaders[data].key] &&
				String(item[this.state.orderHeaders[data].key])
					.replace(/\s/g, '')
					.toLowerCase()
					.indexOf(filterText.replace(/\s/g, '').toLowerCase()) !== -1
			) {
				isFilter = true;
			}
		}
		return isFilter;
	}

	searchOrders = (e) => {
		e.preventDefault();
		const { searchText } = this.state;
		this.getData(1, searchText);
	}

	render() {
		let now = new Date();
		let start = moment(
			new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
		);
		let end = moment(start).add(1, 'days').subtract(1, 'seconds');
		let displayFromDate = helper.getDateFormat(this.state.selectFromDate);
		let displayToDate = helper.getDateFormat(this.state.selectToDate);
		let ranges = {
			Today: [ moment(start), moment(end) ],
			Yesterday: [
				moment(start).subtract(1, 'days'),
				moment(end).subtract(1, 'days'),
			],
			'Last 7 days': [ moment(start).subtract(7, 'days'), moment(end) ],
			'Last 14 days': [ moment(start).subtract(14, 'days'), moment(end) ],
			'This Month': [
				moment(start).subtract(now.getDate() - 1, 'days'),
				moment(end),
			],
			'Last 30 days': [ moment(start).subtract(30, 'days'), moment(end) ],
			'Last Month': [
				moment().subtract(1, 'months').startOf('month'),
				moment().subtract(1, 'months').endOf('month'),
			],
		};

		let local = {
			format: 'MM/DD/YYYY',
			sundayFirst: false,
		};
		let maxDate = moment(start).add(24, 'hour');
		const { t } = this.props.setOptions;

		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD"
		});
		const { validateTracking, items } = this.state;
		console.log('time tie ', items)
		return (
			<Col className='container-div' md={12}>
				{this.state.tableLoading == false && (
					<h2>
						<span className='chart_head' style={{ padding: 18 }}>
							TOTAL SALES
						</span>
					</h2>
				)}
				{this.state.tableLoading == false && (
					<Col md={12} className='date-picker'>
						<label className='select_date'>Select Date</label>
						<DateTimeRangeContainer
							ranges={ranges}
							start={this.state.start}
							end={this.state.end}
							local={local}
							maxDate={maxDate}
							applyCallback={this.applyCallback}
						>
							<FormControl
								id='formControlsTextB'
								type='text'
								label='Date'
								value={displayFromDate + ' - ' + displayToDate}
								placeholder='Enter Date'
							/>
						</DateTimeRangeContainer>
					</Col>
				)}

				{this.state.tableLoading == true && (
					<div
						className='load'
						style={{
							backgroundColor: 'transparent',
							width: '85%',
							height: '50%',
						}}
					>
						<div className='load__icon-wrap'>
							<svg className='load__icon'>
								<path
									fill='#6c6f7f'
									d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
								/>
							</svg>
							{this.state.loadingText} %
						</div>
					</div>
				)}

				{this.state.tableLoading == false && (
					<Col md={12} className='dashboard_middle salesgraphs'>
						<Card>
							<h2 className='sales_head'>
								<span className='chart_head'>SALES</span>

								{this.state.storeList.map((item, index) => (
									<Col className='search_filters search_filter_btn' key={index}>
										<button
											onClick={() => {
												this.changeType(index);
											}}
											className={
												'btn addbtn btn1 ' +
												(item.visible ? item.className : '')
											}
										>
											{item.label}
										</button>
									</Col>
								))}
							</h2>
							<div className='responsive_graph'>
								{this.state.salesData.length > 0 && (
									<ResponsiveContainer width='100%' height={400}>
										<LineChart
											width={770}
											height={400}
											data={this.state.salesData}
										>
											<XAxis dataKey='days' />
											<YAxis
												type='number'
												padding={{ top: 70 }}
												domain={[ 0, 'auto' ]}
											/>
											<Tooltip
												formatter={value => formatter.format(value)}
											/>
											<CartesianGrid stroke='#eee' strokeDasharray='5 5' />
											{this.state.storeList.map((item, index) => (
												<Line
													key={index}
													type='monotone'
													dataKey={item.value}
													stroke={item.colors}
												/>
											))}
										</LineChart>
									</ResponsiveContainer>
								)}
							</div>
							{this.state.salesData.length > 0 && (
								<div className='right_side_sales'>
									<span>
										<h3>Total Sales</h3>
										<h4>{formatter.format(this.state.totalAmount)}</h4>
									</span>
									<span>
										<h3>Daily Average</h3>
										<h4>
											{formatter.format(
												this.state.totalAmount / (this.state.dayCount + 1),
											)}
										</h4>
									</span>
								</div>
							)}
						</Card>
					</Col>
				)}

				{/* {this.state.tableLoading == false && (
					<Col md={12} className='top_products'>
						<Card>
							<CardBody>
								{this.state.tableLoading == false && (
									<div className='card__title'>
										<h5 className='bold-text'>Top Products</h5>
									</div>
								)}

								{this.state.fromDate != '' && (
									<TableList
										normal={true}
										company={this.state.userCompany}
										filename={'Top-Products'}
										tableLoading={this.state.tableLoading}
										totalPage={this.state.totalPage}
										getData={this.getData}
										items={this.state.topProducts}
										headers={this.state.topHeaders}
										name={this.state.name}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				)} */}
				{this.state.tableLoading == false && (
					<Col id="orders__table" md={12} className='top_products'>
						<Card>
							<CardBody>
								<h5 className='bold-text bold-textt'>ORDERS</h5>
								<div className='search1 search_orders1'>
									<span>
										<Label style={{ fontSize: 12 }}>Status</Label>
										<Select
											value={this.state.status}
											onChange={this.handleSatusChange}
											options={this.state.statusFilter}
										/>
									</span>
									<span>
										<Label style={{ fontSize: 12 }}>Channel</Label>
										<Select
											value={this.state.channel}
											onChange={this.handleChannelChange}
											options={this.state.channelFilter}
										/>
									</span>
									<span>
										{/* <Input
											type='text'
											name='search'
											placeholder='Search for orders'
											value={this.state.searchText}
											// onKeyDown={this.handleKeyDown}
											onChange={(event) => {
												this.searchKey(event.target.value);
											}}
										/> */}
										 <div className="search" style={{ marginLeft: 0 }}>
											<form onSubmit={this.searchOrders}>
												<Input
													type='text'
													name='search'
													placeholder='Search for orders'
													value={this.state.searchText}
													onChange={(event) => {
														if (!event.target.value) {
															this.getData(1);
														}
														this.setState({ searchText: event.target.value });
													}}
												/>
												<Button type="submit">
													Search
												</Button>
											</form>
										</div>
									</span>
									<span>
										{
											this.state.role === 'admin' && (
												<button
													className='btn addbtn'
													style={{ float: 'left' }}
													onClick={() => this.setState({ importOrdersFromEcomdash: true })}
												>
													Pull Ecomdash Orders
												</button>
											)
										}
										<ImportMissingOrders
											modalIsOpen={this.state.importOrdersFromEcomdash}
											closeModal={() => this.setState({ importOrdersFromEcomdash: false })}
										/>
									</span>
								</div>
								{/* <button className="btn" onClick={() => { this.toggleUpdate() }}>Update</button> */}
								{this.state.fromDate != '' && (
									<TableList
										normal={true}
										tableLoading={this.state.tableLoading}
										company={this.state.userCompany}
										filename={'orders'}
										trackUpdate={this.trackUpdate}
										totalPage={this.state.totalPage}
										getData={this.getData}
										items={this.state.orderItems}
										headers={this.state.orderHeaders}
										orderCsvHeaders={this.state.orderCsvHeaders}
										isOrders={true}
										Uid={this.state.Uid}
										name={this.state.name}
										addReturns={this.addReturns}
										email={this.state.email}
										role={this.state.role}
										noDataText="No Orders In Selected Date Range"
									/>
								)}
								{this.state.fromDate != '' && (
									<div>
										<br />
										<OrdersTable returns={this.state.returns} />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
				)}
				<div className='alignTop' />
				<SalesWalkthrough />

				<Modal
					isOpen={this.state.small}
					toggle={this.toggleUpdate}
					className={'modal-md ' + this.props.className}
					style={{ marginTop: 150 }}
				>
					<ModalHeader toggle={this.toggleUpdate} />
					<ModalBody style={{ overflow: 'inherit' }}>
						<div
							className='form__form-group-field left'
							style={{ width: '100%' }}
						>
							<div
								className={(validateTracking.trackingNumber && validateTracking.trackingNumber.hasError) ? "form__form-group-error-container" : ''}
								style={{ width: '100%' }}
							>
								<span>
									<label>
										Tracking Number
										<span class="mantory">*</span>
									</label>
									<input
										type='text'
										placeholder=''
										value={this.state.trackingNumber}
										className="form__product-input"
										onChange={(event) => {
											this.setState({ trackingNumber: event.target.value });
										}}
									/>
								</span>
								<p class="form__error_text">{validateTracking.trackingNumber && validateTracking.trackingNumber.errorMessage}</p>
							</div>
						</div>

						<div
							className='form__form-group-field right'
							style={{ width: '100%' }}
						>
							<div
								style={{ width: '100%' }}
								className={(validateTracking.trackingUrl && validateTracking.trackingUrl.hasError) ? "form__form-group-error-container" : ''}
							>
								<span>
									<label>
										Tracking URL
									</label>
									<input
										type='text'
										placeholder=''
										value={this.state.trackingUrl}
										className="form__product-input"
										onChange={(event) => {
											this.setState({ trackingUrl: event.target.value });
										}}
									/>
								</span>
								<p class="form__error_text">{validateTracking.trackingUrl && validateTracking.trackingUrl.errorMessage}</p>
							</div>
						</div>

						<div
							className='form__form-group-field right'
							style={{ width: '100%' }}
						>
							<div
								style={{ width: '100%' }}
								className={(validateTracking.carrier && validateTracking.carrier.hasError) ? "form__form-group-error-container" : ''}
							>
								<span>
									<label>
										Carrier
										<span class="mantory">*</span>
									</label>
									<Select
										value={this.state.carrier}
										onChange={this.handlecarrierChange}
										options={this.state.carrierList}
									/>
								</span>
								<p class="form__error_text">{validateTracking.carrier && validateTracking.carrier.errorMessage}</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={() => {
								if (this.state.itemShipped) {
									this.updateTrackingForShippedItem();
								}
								else {
									this.saveTrack();
								}
							}}
						>
							Ok
						</Button>{' '}
						<Button color='secondary' onClick={this.toggleUpdate}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Col>
		);
	}
}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(SalesCard);
