import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Badge, Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';

class ShipmentsCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:'',
      headers:[{key:'sku',title:'SKU'},
               {key:'product_title',title:'Product Title'},
               {key:'price',title:'Price'},
               {key:'sales',title:'Sales'},
               {key:'volume',title:'Volume'},
               {key:'warehouse_stock',title:'VS Warehouse Stock'},
               {key:'amazon_fba_stock',title:'Amazon FBA Stock'},
               {key:'deliver_stock',title:'Deliver Stock'}
             ],
      name:'shipments',
      items:[],
      tempItems:[]
    };
  }

  componentDidMount() {
    console.log('this.props.list_patient==',this.props.list_companies);

  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps=====',nextProps);
    this.setState({isLoading:false})
  }


  render() {
    const {t} = this.props.setOptions
    return (
      <Col className="container-div" md={12}>
      {this.state.isLoading==true&&<div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>}
      {this.state.isLoading==false&&<Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Shipments</h5>
            {/* <div className="search">
              <Input type="text" name="search" placeholder="Search"/>
              <Button>Search</Button>
            </div> */}
          </div>
          <TableList items={this.state.items} headers={this.state.headers} name={this.state.name}/>
        </CardBody>
      </Card>
      </Col>}
      </Col>
    )
  }

}

const mapStateToProps = ({ curdReducer }) => {
  const {list_companies} = curdReducer;
  return {list_companies};
};

export default connect(mapStateToProps, actions)(ShipmentsCard);
