import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { PDFExport } from '@progress/kendo-react-pdf';
import moment from 'moment';
import { connect } from 'react-redux';
import api from '../../../utils/api';
import { Button } from 'reactstrap';
import * as actions from '../../../redux/actions';
import SvgLogo from '../../../components/svgLogo';

const serviceMapper = {
    BUY_UPC: 'Purchased UPC(s)',
    CONSULTING_SESSSION: 'Consulting Session',
    PROFESSIONAL_STOREFRONT_DESIGN: 'Professional Storefront Design',
    ENHANCED_BRAND_CONTENT: 'Enhanced Brand Content',
    PHOTOGRAPHY: 'Product Photography',
    LIST_OPTIMIZATION: 'List Optimization',
    SUBSCRIPTION_UPGRADE: 'Valet Seller Subscription',
    ADVERTISING: 'Advertising Budget',
    BRAND_MONITORING: 'Brand Monitoring'
}

const Invoice = (props) => {
    const { id } = useParams();
    const [invoiceData, setInvoiceData] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const userProfile = props.userDetails || {};

    useEffect(() => {
        api.getApi(`invoice/${id}`)
            .then((response) => {
                setInvoiceData(response.data);
                api.postApi('user/list', {
                    Pagination: {
                        PageNumber: 1,
                        ResultsPerPage: 25,
                        Search: response.data.userDetails.email,
                        StartAfter: "",
                    }
                })
                    .then((response) => {
                        if (response.data[0]) {
                            setUserDetails(response.data[0]);
                        }
                    })
            });
    }, []);

    return(
        <div style={{ paddingTop: '2%' }}>
            <div style={{ margin: '0 auto', border: '1px solid #6c6f7f', padding: '1%', width: 1000 }}>
                <SvgLogo />
                <div style={{ margin: '2%' }}>
                    <h1>Invoice</h1>
                    <br />
                    <br />
                    <p>To: { userDetails.company }</p>
                    <p>Customer Name: { userProfile.firstname || userDetails.firstname } { userProfile.lastname || userDetails.lastname}</p>
                    <p>Company Name: { userProfile.company || userDetails.company }</p>
                    <br />
                    <br />
                    <p>Details: ---</p>
                    <p>Account Email: {userProfile.email || (invoiceData.userDetails && invoiceData.userDetails.email)} </p>
                    <p>Invoice #: {id}</p>
                    <div style={{ width: '50%', marginLeft: '50%' }}>
                        <p>
                            <b>Valet Seller Invoice</b>
                        </p>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Summary for Date</span>
                            <span>{moment(invoiceData.createdAt).format('MM/DD/YYYY')}</span>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Service Purchased</span>
                            <span>{serviceMapper[invoiceData.paymentType]}</span>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Ending Balance</span>
                            <span>${invoiceData.amount}</span>
                        </div>
                    </div>
                    <br />
                    <div>
                        <hr />
                        <p>
                            <b>This is not a bill.</b>
                        </p>
                        <p>This is a summary of billing activity for the time period stated above.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

class DownloadInvoice extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return(
            <div>
                <Button className="btn addbtn" onClick={() => { this.pdfExportComponent.save(); }}>Download Invoice</Button>
                <PDFExport
                    forcePageBreak=".page-break"
                    /* eslint no-return-assign: 0 */
                    ref={(component) => this.pdfExportComponent = component}
                >
                    <Invoice {...this.props} />
                </PDFExport>
            </div>
        )
    }
}

const mapStateToProps = ({ authReducer }) => {
    const { userdetails } = authReducer;
    return { userDetails: userdetails };
  };
  
export default connect(mapStateToProps, actions)(DownloadInvoice);
