import { PayPalButton } from 'react-paypal-button-v2';
import React from 'react';

export function PayPalSubscriberBtn(props) {
	const {
		amount,
		currency,
		createSubscription,
		onApprove,
		catchError,
		onError,
		onCancel,
		onButtonReadyCallBack,
		plan_id,
	} = props;

	const onButtonReadyCallBackFunction = onButtonReadyCallBack ? onButtonReadyCallBack : () => {};
	const paypalKey = process.env.REACT_APP_PAYPAL_PAY_CLIENT;
	return (
		<PayPalButton
			shippingPreference='NO_SHIPPING'
			createSubscription={(data, details) => createSubscription(data, details)}
			onApprove={(data, details) => {
				data.plan_id = plan_id;
				onApprove(data, details);
			}}
			onError={(err) => onError(err)}
			catchError={(err) => catchError(err)}
			onCancel={(err) => onCancel(err)}
			onButtonReady={onButtonReadyCallBackFunction}
			options={{
				clientId: paypalKey,
				vault: true,
				disableFunding: 'credit',
			}}
			style={{
				label: 'pay',
				layout: 'vertical',
			}}
		/>
	);
}

export default PayPalSubscriberBtn;
