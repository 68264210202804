import React, { PureComponent } from 'react';
import helper from '../../utils/helper';
import history from '../../utils/history';
import api from '../../utils/api';
import LogoImageUrls from '../../components/images';
import {
	Card,
	CardBody,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Input,
	FormGroup,
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import striptags from "striptags";
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import * as actions from '../../redux/actions';
import { connect } from 'react-redux';
import { CSVLink, CSVDownload } from 'react-csv';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import ProductImage from './ProductImage';
import Switch from 'react-switch';
import moment from 'moment';
import _ from 'lodash';
import OrderStatus from './changeShipStatus';

const callbacks = {
	onSlideChange: (slide) => console.log(slide),
	onLightboxOpened: (current) => console.log(current),
	onLightboxClosed: (current) => console.log(current),
	onCountSlides: (total) => console.log(total),
};

const IsCustomStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '380px',
		height: '450px',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};

const options = {
	buttons: {
		backgroundColor: '#1C7293',
		iconColor: '#e8eddf',
		iconPadding: '7px',
	},
	caption: {
		captionColor: '#e8eddf',
		captionFontSize: '30px',
	},
	settings: {
		overlayColor: 'rgba(6, 90, 130, 1)',
	},
	thumbnails: {
		thumbnailsAlignment: 'start',
	},
};

class TableList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			small: false,
			isLoading: false,
			pages: 0,
			loading: true,
			items: [],
			confirmModal: false,
			confirmObservationModal: false,
			orderDetails: '',
			isordernotificationDisabled: true,
			modalIsOpen: false,
			observation: '',
			returnDate: '',
			allMarked: false,
			TrackingNumber: '',
			TrackingURL: '',
			ShipmentId: '',
			dropdownOpen: false,
			exportingAll: false,
			returnValue: 0,
		};

		this.toggle = this.toggle.bind(this);
		this.reactTable = React.createRef();

		this.toggleSmall = this.toggleSmall.bind(this);
		this.deleteModel = this.deleteModel.bind(this);
		this.handleOrdernotificationChange = this.handleOrdernotificationChange.bind(
			this,
		);
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.deleteId = '';
	}
	componentDidMount() {
		//this.languageList=helper.getLanguages();
		//console.log('componentDidMount',this.props);
		//this.createColumn();
	}
	componentWillMount() {
		//console.log('componentWillMount',this.props);
	}

	exportAll(headers) {
		this.setState({
			exportingAll: true,
		});
		let exportType;
		if (this.props.isProduct) {
			exportType = 'product';
		}
		if (this.props.isOrders) {
			exportType = 'orders';
		}
		const Uid = this.props.role === 'admin' ? null : this.props.Uid;
		api.postApi('exportAll', {headers, exportType, Uid, email: this.props.email})
			.then((response) => {
				console.log('response', response)
				this.setState({
					exportingAll: false,
				})
				helper.successMessage(response.messages);
			})
			.catch(err => {
				console.log(err);
			})
	}

	viewOrderDetails(item) {
		console.log('item===', item);
		api
			.getEcomDashApi('orders/' + item.EcomdashId + '/shipments')
			.then((data) => {
				if (data.data.length != 0) {
					this.setState({
						TrackingNumber: data.data[0].Items[0].TrackingNumber,
					});
					this.setState({ TrackingURL: data.data[0].Items[0].TrackingURL });
					this.setState({ ShipmentId: data.data[0].Items[0].ShipmentId });
				} else {
					this.setState({
						TrackingNumber: '-',
					});
					this.setState({ TrackingURL: '-' });
					this.setState({ ShipmentId: '-' });
				}
			});

		this.setState({
			confirmModal: !this.state.confirmModal,
			orderDetails: item,
		});
	}

	viewObservation(item) {
		console.log('item===', item);
		this.returnItems = item;
		this.setState({
			confirmObservationModal: !this.state.confirmObservationModal,
			observation: '',
		});
	}

	addObservation() {
		let observation = this.state.observation;
		let returnDate = this.state.returnDate;
		const returnValue = this.state.returnValue;

		if (returnDate == '') {
			helper.errorMessage('ReturnDate field is required');
		} else if (observation == '') {
			helper.errorMessage('Observation field is required');
		} else {
			this.props.addReturns(
				observation,
				this.returnItems.EcomdashId,
				returnDate,
				returnValue,
			);

			this.setState({
				confirmObservationModal: false,
				returnDate: '',
				observation: '',
			});
		}
	}

	toggle() {
		this.setState({
			confirmModal: !this.state.confirmModal,
		});
	}
	handleOrdernotificationChange(isordernotificationDisabled) {
		this.setState({ isordernotificationDisabled: isordernotificationDisabled });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	renderEditAction(id) {
		return (
			<div
				onClick={() => {
					this.editAction(id);
				}}
			>
				<img className='' src='../img/edit_btn.png' className='pencil_edit' />
			</div>
		);
	}

	renderMark(id) {
		let item = helper.getItem(this.props.items, 'Id', id);
		let marked = false;
		if (item) {
			marked = item.marked;
		}
		return (
			<div>
				<Input
					type='checkbox'
					checked={marked}
					className='check_box'
					onChange={() => {
						this.props.setMark(id);
					}}
				/>
			</div>
		);
	}

	renderMarketplaces(id) {
		let items = helper.getItem(this.props.items, 'Id', id);
		if (items) {
			return (
				<div className='marketplace_img'>
					{items.SellType.map((item, index) => {
						const url = LogoImageUrls(item.label);
						if (item.link) {
							return (
								<a href={item.link} target='_blank'>
									<img className='' src={url} className='pencil_edit' key={index} />
								</a>
							);
						} else {
							return (
								<img className='' src={url} className='pencil_edit' key={index} />
							);
						}
					})}
				</div>
			);
		} else {
			return <div />;
		}
	}

	renderViewAction(id) {
		return (
			<button
				className='btn addbtn'
				onClick={() => {
					this.viewAction(id);
				}}
			>
				View
			</button>
		);
	}

	renderUserEditAction(id) {
		return (
			<div
				onClick={() => {
					this.editUserAction(id);
				}}
			>
				<img className='' src='../img/edit_btn.png' className='pencil_edit' />
			</div>
		);
	}

	renderPriorityAction(value) {
		if (value && value.value) {
			return (
				<div>
					<span className={value.value} />
				</div>
			);
		} else {
			return (
				<div>
					<span className='blackPrioritycolor' />
				</div>
			);
		}
	}

	renderNotificationstatusAction(id) {
		const { isordernotificationDisabled } = this.state;
		return (
			<div>
				{' '}
				<Switch
					onChange={this.handleOrdernotificationChange}
					checked={isordernotificationDisabled}
				/>
			</div>
		);
	}

	renderCCStatusAction(value) {
		let color;
		if (value == 'Charged') {
			color = 'green';
		} else if (value == 'Pending') {
			color = 'red';
		} else {
			color = 'black';
		}
		return <div style={{ color: color }}>{value}</div>;
	}

	// toggle() {
	//   this.setState({
	//     modal: !this.state.modal,
	//   });
	// }

	toggleSmall(id) {
		this.deleteId = id;
		this.setState({
			small: !this.state.small,
		});
	}

	renderDeleteAction(rowData) {
		return (
			<div
				onClick={() => {
					this.toggleSmall(rowData.original._id || rowData.original.key);
				}}
			>
				<img className='' src='../../img/trash.png' className='pencil_edit' />
			</div>
		);
	}

	renderPaymentEditAction(id) {
		return (
			<div
				onClick={() => {
					this.props.editPaymentAction(id);
				}}
			>
				<img
					className=''
					src='../../img/edit_btn.png'
					className='pencil_edit'
				/>
			</div>
		);
	}

	renderTrackUpdateAction(OrderNumber) {
		let items = helper.getItem(this.props.items, 'OrderNumber', OrderNumber);
		if (
			items &&
			items.shipment_status == 'InProcess' &&
			items.Status != 'Cancelled' &&
			items.IsShopifyOrder != true
		) {
			return (
				<button
					className='btn addbtn'
					style={{ float: 'none' }}
					onClick={() => {
						this.props.trackUpdate(OrderNumber);
					}}
				>
					Update Tracking
				</button>
			);
		}
		else {
			return (
				<button
					className='btn addbtn'
					style={{ float: 'none' }}
					onClick={() => {
						this.props.trackUpdate(OrderNumber, true);
					}}
				>
					Update Tracking
				</button>
			);
		}
	}

	renderOrderDetailsAction(OrderNumber) {
		let items = helper.getItem(this.props.items, 'OrderNumber', OrderNumber);
		return (
			<button
				className='btn addbtn'
				onClick={() => {
					this.viewOrderDetails(items);
				}}
			>
				Details
			</button>
		);
	}

	renderReturns(OrderNumber, price) {
		let items = helper.getItem(this.props.items, 'OrderNumber', OrderNumber);
		if (items.Returns) {
			return <button className='btn addbtn'>Returned</button>;
		} else {
			return (
				<button
					className='btn addbtn'
					onClick={() => {
						this.setState({ returnValue: Number(price) })
						this.viewObservation(items);
					}}
				>
					Return
				</button>
			);
		}
	}

	renderAddProductAction(cellData) {
		let items = helper.getItem(this.props.items, 'id', cellData.id);
		if (items && items.marked == true) {
			return (
				<button
					className='btn disconnect-btn'
					onClick={() => {
						this.props.addShopifyProduct(cellData.id);
					}}
				>
					Remove
				</button>
			);
		} else {
			return (
				<button
					className='btn update-btn'
					onClick={() => {
						const variationsWithSkus = cellData.variants.filter((variation) => Boolean(variation.sku));
						if (variationsWithSkus.length !== cellData.variants.length) {
							helper.errorMessage("Please enter a SKU in Shopify for this product or all its variations, reload this page, and try adding it again.")
						}
						else {
							this.props.addShopifyProduct(cellData.id);
						}
					}}
				>
					Add
				</button>
			);
		}
	}

	editUserAction(id) {
		let item = helper.getItem(this.props.items, 'uid', id);
		history.push('edit' + this.props.name + '/' + item.uid);
	}
	editAction(id) {
		let item = helper.getItem(this.props.items, 'Id', id.original.Id);
		if (item.Id) {
			history.push({
				pathname: `edit${this.props.name}/${encodeURIComponent(item._id)}`,
				state: {
					searchQuery: this.props.productSearchQuery,
				}
			});
		} else if (item.uid) {
			history.push({
				pathname: `edit${this.props.name}/${encodeURIComponent(item.uid)}`,
				state: {
					searchQuery: this.props.productSearchQuery,
				}
			});
		}
	}

	viewAction(id) {
		//	let item = helper.getItem(this.props.items, 'Id', id.original.OrderNumber);
		history.push('shipping/' + id.original.OrderNumber);
	}

	renderShopifyImageAction(id) {
		//   return (<img className="" src={url} onClick={() => { this.toggle() }}></img>);
		let items = helper.getItem(this.props.items, 'id', id);
		if (items && items.images) {
			let images = [];
			for (let data in items.images) {
				images.push({ src: items.images[data].src });
			}
			return (
				<div>
					<div>
						<SimpleReactLightbox>
							<ProductImage light url={items.image} />
							<SRLWrapper options={options} images={images} />
						</SimpleReactLightbox>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<p />
				</div>
			);
		}
	}

	renderImageAction(id) {
		//   return (<img className="" src={url} onClick={() => { this.toggle() }}></img>);
		let items = helper.getItem(this.props.items, 'Id', id);
		if (items && items.image) {
			let images = [];
			for (let data in items.Images) {
				images.push({ src: items.Images[data].Url });
			}
			return (
				<div>
					<div>
						<SimpleReactLightbox>
							<ProductImage light url={items.image} />
							<SRLWrapper options={options} images={images} />
						</SimpleReactLightbox>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<p />
				</div>
			);
		}
	}

	renderIconAction(name) {
		//console.log(name);
		//name = 'Amazon Canada'
		console.log('%%%', name)
		if (
			name == 'Walmart' ||
			name == 'Amazon' ||
			name == 'ebay' ||
			name == 'Sears' ||
			name == 'Bonanza' ||
			name == 'Walmart Canada' ||
			name == 'Amazon Canada' ||
			name == 'Shopify' ||
			name == 'Wish' ||
			name === 'Kroger' ||
			name === 'Valet Shops' ||
			name === 'Valet Shop'
			|| name === 'Bed Bath & Beyond'
			|| name === 'Wayfair'
		) {
			const url = LogoImageUrls(name);
			return (
				<div>
					<img className='' src={url} />
				</div>
			);
		} else {
			return <div>{name}</div>;
		}
	}

	renderTypeAction(value) {
		if (value && value.label != undefined) {
			return (
				<div>
					<p>{value.label}</p>
				</div>
			);
		} else {
			return (
				<div>
					<p>{value}</p>
				</div>
			);
		}
	}

	renderUserAction(value) {
		return (
			<div>
				<p>{value.length}</p>
			</div>
		);
	}

	renderPriceAction(value) {
		return (
			<div>
				<p>${helper.getNumberFormat(value)}</p>
			</div>
		);
	}

	renderInvoice(id) {
		let item = helper.getItem(this.props.items, 'id', id);
		return (
			<div>
				<a href={`/invoice/${item.receipt_url || id}`} target='_blank'>
					<Button className='btn update-btn'>Invoice</Button>
				</a>
			</div>
		);
	}

	renderAddCart(id) {
		let item = helper.getItem(this.props.items, 'Id', id);
		if (item && item.iscart) {
			return (
				<div>
					<Button
						onClick={() => {
							this.props.removeCart(id);
						}}
						className='btn disconnect-btn'
					>
						Remove
					</Button>
				</div>
			);
		} else {
			return (
				<div>
					<Button
						onClick={() => {
							this.props.addToCart(id);
						}}
						className='btn update-btn'
					>
						Add
					</Button>
				</div>
			);
		}
	}

	renderDate(value) {
		return (
			<div>
				<p>{helper.getDateTimeFormat(value)}</p>
			</div>
		);
	}

	renderCheckAction(id) {
		let item = helper.getItem(this.props.items, 'id', id);
		return (
			<div>
				<Input
					className='form-check-input'
					type='checkbox'
					checked={item.assign_case}
					onChange={(e) => this.props.changeCase(e.target.checked, item.key)}
				/>
			</div>
		);
	}

	renderPrice(amount) {
		return (
			<div>
				<p>{helper.priceFormat(amount)}</p>
			</div>
		);
	}

	deleteModel() {
		console.log('!@£$%^', this.deleteId)
		this.props.deleteAction(this.deleteId);
		//this.props.deleteRecord(this.props.name,this.props.items,this.deleteId);
		this.toggleSmall();
	}

	onTableViewChange = () => {
		const current = this.reactTable.current;
		if (current) {
			const page = current.state.page;
			const pgSize = current.state.pageSize;
			// const allData = current.getResolvedState().sortedData;
			const startIdx = page * pgSize;
			this.setState({ page: page, pgSize: pgSize });
			this.props.setAllMark && this.props.setAllMark(page, pgSize, true);
		}
	};

	renderLogisticsPlanValue(fullFillments) {
		if (fullFillments.length > 0) {
			return `$${helper.getNumberFormat(
				fullFillments.reduce(
					(value, item) =>
						value +
						(Number(item.Freight) +
							Number(item.Handling) +
							Number(item.Kitting) +
							Number(item.Other) +
							Number(item.Packaging) +
							Number(item.Pallet) +
							Number(item.Partner) +
							Number(item.Postage) +
							Number(item.Receiving)),
					0,
				),
			)}`;
		}
		return `$${helper.getNumberFormat(0)}`;
	}

	editMongoAction(id) {
		let item = helper.getItem(this.props.items, '_id', id);
		history.push('edit' + this.props.name + '/' + item._id);
	}

	renderEditMongoAction(id) {
		return (
			<div
				onClick={() => {
					this.editMongoAction(id);
				}}
			>
				<img className='' src='../img/edit_btn.png' className='pencil_edit' />
			</div>
		);
	}

	renderDeleteMongoAction(id) {
		return (
			<div
				onClick={() => {
					this.toggleSmall(id);
				}}
			>
				<img className='' src='../../img/trash.png' className='pencil_edit' />
			</div>
		);
	}

	renderViewUpcAction(sku) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<button
					className='btn addbtn'
					onClick={() => {
						history.push(`/pages/editproducts/${sku}`);
					}}
				>
					View
				</button>
			</div>
		);
	}

	renderVoidEditAction(row) {
		return (
			<div
				onClick={() => {
					this.props.editVoidAction(row);
				}}
			>
				<img
					className=''
					src='../../img/edit_btn.png'
					className='pencil_edit'
				/>
			</div>
		);
	}

	renderVoidDeleteAction(row) {
		return (
			<div
				onClick={() => {
					this.props.deleteVoidAction(row);
				}}
			>
				<img className='' src='../../img/trash.png' className='pencil_edit' />
			</div>
		);
	}

	renderOriginalOverride = (data, original) => {
		if (
			[ 'sales', 'totalPaid', 'paidPlanValue', 'advertising_amount' ].includes(
				data.accessor,
			)
		) {
			return this.renderPriceAction(original[data.accessor]);
		}

		if (data.accessor == 'comission') {
			return this.renderPriceAction(
				original[data.accessor] * original.sales / 100,
			);
		}

		if (data.accessor == 'fullFillments') {
			return this.renderLogisticsPlanValue(original[data.accessor]);
		}

		if (data.accessor == 'paypal_billing') {
			return original[data.accessor] ? 'Paid' : 'Free';
		}

		if (data.accessor == 'advertising_amount-plan') {
			return original['advertising_amount'] === 0 ||
			!original['advertising_amount']
				? 'N'
				: 'Y';
		}

		if (data.accessor == 'ship_orders_logic') {
			return original['ship_orders'] === 'I will ship orders myself'
				? 'N'
				: 'Y';
		}

		return original[data.accessor];
	};

	renderOrderStatus(rowData) {
		return(
			<div>
				{ this.props.role === 'admin' ? <OrderStatus rowData={rowData} /> : rowData.value }
			</div>
		);
	}

	renderSku(original,isExpanded) {
		return (
			<div>
				{original.Sku}
				{Boolean(original.ProductVariations && original.ProductVariations.length)&&<div>[{original.ProductVariations && original.ProductVariations.length} variations]</div>}
			</div>
		);
	}

	render() {
		const { page, orderDetails, allMarked } = this.state;

		let columns = [];
		let tableData = this.props.items;
		let row = {};
		let csvData = [];
		let ProductCSVData = [];
		let csvDataKey = [];
		let headers = [];
		csvDataKey[0] = [];

		let pgSize = 10;
		let pageSizeOptions = [ 5, 10, 20, 25 ];
		if (tableData) {
			//  pgSize = (tableData.length > 10) ? 20 : 10;
			if (tableData.length > 100) {
				pageSizeOptions = [ 10, 20, 25, 50, 100 ];
			} else if (tableData.length > 50) {
				pageSizeOptions = [ 10, 20, 25 ];
			} else if (tableData.length > 25) {
				pageSizeOptions = [ 10, 20 ];
			} else {
				pageSizeOptions = [ 10 ];
			}
		}

		this.props.headers.map((data) => {
			const isCommon = [ 'text', 'ammount', 'date' ].includes(data.key);
			const isOverride = [ 'override' ].includes(data.key);
			if (
				data.key != 'delete' &&
				data.key != 'edit' &&
				data.key != 'preview' &&
				data.key != 'useredit' &&
				data.key != 'voidEdit' &&
				data.key != 'voidDelete' &&
				data.key != 'edit-upc' &&
				data.key != 'delete-upc' &&
				data.key != 'view-upc'
			) {
				headers.push({
					label: data.title,
					key: isOverride
						? data.overrideField
						: isCommon ? data.accessor : data.key,
				});
			}

			csvDataKey[0].push(data);

			if (data.key == 'useredit') {
				row = {
					Header: data.title,
					accessor: 'uid', // String-based value accessors!
					Cell: ({ value }) => this.renderUserEditAction(value),
				};
			} else if (data.key == 'edit') {
				row = {
					Header: data.title,
					accessor: 'id', // String-based value accessors!
					Cell: (value) => this.renderEditAction(value),
				};
			} else if (data.key == 'edit_payment') {
				row = {
					Header: data.title,
					accessor: 'key', // String-based value accessors!
					Cell: ({ value }) => this.renderPaymentEditAction(value),
				};
			} else if (data.key == 'view') {
				row = {
					Header: data.title,
					accessor: 'id', // String-based value accessors!
					Cell: (value) => this.renderViewAction(value),
				};
			} else if (data.key == 'priority') {
				row = {
					Header: data.title,
					accessor: data.key, // String-based value accessors!
					Cell: ({ value }) => this.renderPriorityAction(value),
				};
			} else if (data.key == 'notification_status') {
				row = {
					Header: data.title,
					accessor: data.key, // String-based value accessors!
					Cell: ({ value }) => this.renderNotificationstatusAction(value),
				};
			} else if (data.key == 'delete') {
				row = {
					Header: data.title,
					accessor: 'key', // String-based value accessors!
					Cell: (rowData) => this.renderDeleteAction(rowData),
				};
			} else if (data.key == 'add_product') {
				row = {
					Header: data.title,
					accessor: 'id', // String-based value accessors!
					Cell: (cellData) => this.renderAddProductAction(cellData.original),
				};
			} else if (data.key == 'track_update') {
				row = {
					Header: data.title,
					accessor: 'OrderNumber', // String-based value accessors!
					Cell: ({ value }) => this.renderTrackUpdateAction(value),
				};
			} else if (data.key == 'order_details') {
				row = {
					Header: data.title,
					accessor: 'OrderNumber', // String-based value accessors!
					Cell: ({ value }) => this.renderOrderDetailsAction(value),
				};
			} else if (data.key == 'PaymentReceivedDate') {
				row = {
					Header: data.title,
					accessor: 'PaymentReceivedDate', // String-based value accessors!
					Cell: ({ value }) => moment(value).format('MM/DD/YYYY h:mm:ss'),
				};
			} else if (data.key == 'returns') {
				row = {
					Header: data.title,
					accessor: 'OrderNumber', // String-based value accessors!
					Cell: ({ value, original }) => this.renderReturns(value, original.price),
				};
			} else if (data.key == 'image') {
				row = {
					Header: data.title,
					accessor: 'Id', // String-based value accessors!
					Cell: ({ value }) => this.renderImageAction(value),
				};
			} else if (data.key == 'marketplaces') {
				row = {
					Header: data.title,
					accessor: 'Id', // String-based value accessors!
					Cell: ({ value }) => this.renderMarketplaces(value),
				};
			} else if (data.key == 'mark') {
				row = {
					Header: (
						<div>
							{' '}
							<Input
								checked={allMarked}
								onChange={(event) => {
									this.setState({ allMarked: !allMarked });
									this.props.setAllMark(page, pgSize, !allMarked);
								}}
								type='checkbox'
								className='check_box'
							/>{' '}
							Select All{' '}
						</div>
					),
					sortable: false,
					filterable: false,
					accessor: 'Id', // String-based value accessors!
					Cell: ({ value }) => this.renderMark(value),
				};
			} else if (data.key == 'shopify_image') {
				row = {
					Header: data.title,
					accessor: 'id', // String-based value accessors!
					Cell: ({ value }) => this.renderShopifyImageAction(value),
				};
			} else if (data.key == 'StorefrontName') {
				row = {
					Header: data.title,
					accessor: 'StorefrontName', // String-based value accessors!
					Cell: ({ value }) => this.renderIconAction(value),
				};
			} else if (data.key == 'Status') {
				row = {
					Header: data.title,
					accessor: 'Status', // String-based value accessors!
					Cell: (rowData) => this.renderOrderStatus(rowData),
				};
			} else if (data.key == 'type') {
				row = {
					Header: data.title,
					accessor: 'type', // String-based value accessors!
					Cell: ({ value }) => this.renderTypeAction(value),
				};
			} else if (data.key == 'company') {
				row = {
					Header: data.title,
					accessor: 'company', // String-based value accessors!
					Cell: ({ value }) => this.renderTypeAction(value),
				};
			} else if (data.key == 'gender') {
				row = {
					Header: data.title,
					accessor: 'gender', // String-based value accessors!
					Cell: ({ value }) => this.renderTypeAction(value),
				};
			} else if (data.key == 'user') {
				row = {
					Header: data.title,
					accessor: 'user', // String-based value accessors!
					Cell: ({ value }) => this.renderUserAction(value),
				};
			} else if (
				data.key == 'buy_price' ||
				data.key == 'price' ||
				data.key == 'sales' ||
				data.key == 'TotalAmount'
			) {
				row = {
					Header: data.title,
					accessor: data.key, // String-based value accessors!
					Cell: ({ value }) => this.renderPriceAction(value),
				};
			} else if (data.key == 'country') {
				row = {
					Header: data.title,
					accessor: 'country', // String-based value accessors!
					Cell: ({ value }) => this.renderTypeAction(value),
				};
			} else if (data.key == 'paypal_billing') {
				row = {
					Header: data.title,
					accessor: 'paypal_billing', // String-based value accessors!
					Cell: ({ value }) => (value ? 'Paid' : 'Free'),
				};
			} else if (data.key == 'advertising_amount-plan') {
				row = {
					Header: data.title,
					accessor: 'advertising_amount',
					Cell: ({ value }) => (value === 0 || !value ? 'N' : 'Y'),
				};
			} else if (data.key == 'advertising_amount') {
				row = {
					Header: data.title,
					accessor: 'advertising_amount',
					Cell: ({ value }) => `$${helper.getNumberFormat(value)}`,
				};
			} else if (data.key == 'ship_orders_logic') {
				row = {
					Header: data.title,
					accessor: 'ship_orders',
					Cell: ({ value }) =>
						value === 'I will ship orders myself' ? 'N' : 'Y',
				};
			} else if (data.key == 'receipt_url') {
				row = {
					Header: data.title,
					accessor: 'id', // String-based value accessors!
					Cell: ({ value }) => this.renderInvoice(value),
				};
			} else if (data.key == 'add_cart') {
				row = {
					Header: data.title,
					accessor: 'Id', // String-based value accessors!
					Cell: ({ value }) => this.renderAddCart(value),
				};
			} else if (data.key == 'DateCreatedInEcomdash') {
				row = {
					Header: data.title,
					accessor: 'DateCreatedInEcomdash', // String-based value accessors!
					Cell: ({ value }) => this.renderDate(value),
				};
			} else if (data.key == 'fullFillments') {
				row = {
					Header: data.title,
					accessor: 'fullFillments', // String-based value accessors!
					Cell: ({ value }) => this.renderLogisticsPlanValue(value),
				};
			} else if (data.key == 'comission') {
				row = {
					Header: data.title,
					accessor: 'comission', // String-based value accessors!
					Cell: ({ row }) =>
						this.renderPriceAction(row.comission * row.sales / 100),
				};
			} else if (data.key == 'edit_mongo') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ value }) => {
						return this.renderEditMongoAction(value);
					},
				};
			} else if (data.key == 'delete-mongo') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ value }) => this.renderDeleteMongoAction(value),
				};
			} else if (data.key == 'view-upc') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ row }) => this.renderViewUpcAction(row.sku),
				};
			} else if (data.key == 'voidEdit') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ row }) => this.renderVoidEditAction(row),
				};
			} else if (data.key == 'voidDelete') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ row }) => this.renderVoidDeleteAction(row),
				};
			} else if (data.key == 'text') {
				row = {
					Header: data.title,
					accessor: data.accessor, // String-based value accessors!
					Cell: ({ value }) => value,
				};
			} else if (data.key == 'ammount') {
				row = {
					Header: data.title,
					accessor: data.accessor, // String-based value accessors!
					Cell: ({ value }) => this.renderPriceAction(value),
				};
			} else if (data.key == 'date') {
				row = {
					Header: data.title,
					accessor: data.accessor, // String-based value accessors!
					Cell: ({ value }) =>
						value
							? moment(value, 'MM/DD/YYYY').format('MM/DD/YYYY')
							: undefined,
				};
			} else if (data.key == 'product_status') {
				row = {
					Header: data.title,
					accessor: data.accessor, // String-based value accessors!
					Cell: (row) => {
						return (row.original && row.original.ProductStatus === 'pending_reviewal' && !Boolean(row.original && row.original.MarketPlaceLinks && row.original.MarketPlaceLinks.length)) ? 'Pending Reviewal' : row.original.ProductStatus || "";
					}
				};
			} else if (data.key == 'override') {
				row = {
					Header: data.title,
					accessor: data.accessor, // String-based value accessors!
					Cell: ({ original }) => {
						if (original.overrides) {
							if (original.overrides[data.overrideField]) {
								return [
									'buy_price',
									'price',
									'sales',
									'TotalAmount',
									'comission',
									'totalPaid',
									'paidPlanValue',
									'advertising_amount',
									'fullFillments',
								].includes(data.accessor)
									? `$${helper.getNumberFormat(
											original.overrides[data.overrideField],
										)}`
									: original.overrides[data.overrideField] || '-';
							} else {
								return this.renderOriginalOverride(data, original);
							}
						}
						return this.renderOriginalOverride(data, original);
					},
				};
			} else if (data.key === 'delete-upc') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ value, original }) =>
						!original.UserId ? this.renderDeleteMongoAction(value) : null,
				};
			}
			else if (data.key === 'PaidAt') {
				row = {
					Header: data.title,
					accessor: 'PaidAt', // String-based value accessors!
					Cell: ({ value, original }) => {
						if (original.shipment_status === 'Cancelled' || original.Status === 'Cancelled') {
							return 'Cancelled'
						}
						return Boolean(value) ? "Complete": "InProcess";
					},
				};
			}
			else if (data.key === 'edit-upc') {
				row = {
					Header: data.title,
					accessor: '_id', // String-based value accessors!
					Cell: ({ value, original }) =>
						!original.UserId ? this.renderEditMongoAction(value) : null,
				};
			}
			else if (data.key === 'Sku' && this.props.isProduct) {
				row = {
					// Make an expander cell
					Header: data.title, // No header
					// width: 150,
					// id: 'expander', // It needs an ID
					// expander: true,
					accessor: data.key, // String-based value accessors!
					// Expander: ({original, isExpanded }) => this.renderSku(original,isExpanded)
					Cell: ({original, isExpanded }) => this.renderSku(original,isExpanded)
				};
			} else {
				row = {
					Header: data.title,
					accessor: data.key, // String-based value accessors!
				};
			}
			columns.push(row);
		});

		if (this.props.name == 'usermanagement') {
			headers.push({ label: 'Cancel Reason', key: 'cancel_reason' });
			csvDataKey[0].push({ key: 'cancel_reason' });
		}

		for (let data in tableData) {
			var index = Number(data) + 1;
			let dataobject = [];

			for (let col in csvDataKey[0]) {
				if (csvDataKey[0][col].key == 'priority') {
					//console.log(tableData[data][csvDataKey[0][col]]);
					dataobject = {
						...dataobject,
						[csvDataKey[0][col].key]: tableData[data][csvDataKey[0][col].key]
							? tableData[data][csvDataKey[0][col].key].label
							: 'Black',
					};
				} else if (
					csvDataKey[0][col] != 'delete' &&
					csvDataKey[0][col] != 'edit' &&
					csvDataKey[0][col] != 'useredit' &&
					csvDataKey[0][col] != 'preview' &&
					csvDataKey[0][col] != 'voidEdit' &&
					csvDataKey[0][col] != 'voidDelete' &&
					csvDataKey[0][col].key != 'delete' &&
					csvDataKey[0][col].key != 'edit' &&
					csvDataKey[0][col].key != 'useredit' &&
					csvDataKey[0][col].key != 'preview' &&
					csvDataKey[0][col].key != 'voidEdit' &&
					csvDataKey[0][col].key != 'voidDelete'
				) {
					const isOverride = csvDataKey[0][col].key === 'override';
					let overrideData;

					let commonData;

					if (isOverride) {
						if (csvDataKey[0][col].accessor === 'comission') {
							overrideData = tableData[data].overrides
								? `$${helper.getNumberFormat(
										tableData[data].overrides[csvDataKey[0][col].overrideField],
									)}` ||
									`$${helper.getNumberFormat(
										tableData[data][csvDataKey[0][col].accessor] *
											tableData[data].sales /
											100,
									)}` ||
									'$0'
								: `$${helper.getNumberFormat(
										tableData[data][csvDataKey[0][col].accessor] *
											tableData[data].sales /
											100,
									)}` || '$0';
						} else if (csvDataKey[0][col].accessor === 'paypal_billing') {
							overrideData = tableData[data].overrides
								? tableData[data].overrides[csvDataKey[0][col].overrideField] ||
									tableData[data][csvDataKey[0][col].accessor]
									? 'Paid'
									: 'Free' || '-'
								: tableData[data][csvDataKey[0][col].accessor]
									? 'Paid'
									: 'Free' || '-';
						} else if (
							csvDataKey[0][col].accessor === 'advertising_amount-plan'
						) {
							overrideData = tableData[data].overrides
								? tableData[data].overrides[csvDataKey[0][col].overrideField] ||
									tableData[data]['advertising_amount'] === 0 ||
									!tableData[data]['advertising_amount']
									? 'N'
									: 'Y'
								: tableData[data]['advertising_amount'] === 0 ||
									!tableData[data]['advertising_amount']
									? 'N'
									: 'Y';
						} else if (csvDataKey[0][col].accessor === 'ship_orders_logic') {
							overrideData = tableData[data].overrides
								? tableData[data].overrides[csvDataKey[0][col].overrideField] ||
									tableData[data]['ship_orders'] === 'I will ship orders myself'
									? 'N'
									: 'Y'
								: tableData[data]['ship_orders'] === 'I will ship orders myself'
									? 'N'
									: 'Y';
						} else if (csvDataKey[0][col].accessor === 'fullFillments') {
							overrideData = tableData[data].overrides
								? `$${helper.getNumberFormat(
										tableData[data].overrides[csvDataKey[0][col].overrideField],
									)}` ||
									this.renderLogisticsPlanValue(
										tableData[data]['fullFillments'],
									) ||
									'$0'
								: this.renderLogisticsPlanValue(
										tableData[data]['fullFillments'],
									) || '$0';
						} else {
							const prevValue = tableData[data].overrides
								? tableData[data].overrides[csvDataKey[0][col].overrideField] ||
									tableData[data][csvDataKey[0][col].accessor] ||
									''
								: tableData[data][csvDataKey[0][col].accessor] || '-';

							overrideData = [
								'buy_price',
								'price',
								'sales',
								'TotalAmount',
								'comission',
								'totalPaid',
								'paidPlanValue',
								'advertising_amount',
								'fullFillments',
							].includes(csvDataKey[0][col].accessor)
								? `$${helper.getNumberFormat(prevValue)}`
								: prevValue || '-';
						}
					} else {
						if (csvDataKey[0][col].key === 'comission') {
							commonData = `$${helper.getNumberFormat(
								tableData[data][csvDataKey[0][col].key] * tableData[data].sales,
							)}`;
						} else if (csvDataKey[0][col].key === 'paypal_billing') {
							commonData = tableData[data][csvDataKey[0][col].key]
								? 'Paid'
								: 'Free';
						} else if (csvDataKey[0][col].key === 'advertising_amount-plan') {
							commonData =
								tableData[data]['advertising_amount'] === 0 ||
								!tableData[data]['advertising_amount']
									? 'N'
									: 'Y';
						} else if (csvDataKey[0][col].key === 'ship_orders_logic') {
							commonData =
								tableData[data]['ship_orders'] === 'I will ship orders myself'
									? 'N'
									: 'Y';
						} else if (csvDataKey[0][col].key === 'fullFillments') {
							commonData = this.renderLogisticsPlanValue(
								tableData[data]['fullFillments'],
							);
						} else if (
							csvDataKey[0][col].key === 'text' ||
							csvDataKey[0][col].key === 'date'
						) {
							commonData = tableData[data][csvDataKey[0][col].accessor];
						} else if (csvDataKey[0][col].key === 'ammount') {
							commonData = `$${helper.getNumberFormat(
								tableData[data][csvDataKey[0][col].accessor],
							)}`;
						} else {
							const prevValue = tableData[data][csvDataKey[0][col].key] || '-';
							commonData = [
								'buy_price',
								'price',
								'sales',
								'TotalAmount',
								'comission',
								'totalPaid',
								'paidPlanValue',
								'advertising_amount',
								'fullFillments',
							].includes(csvDataKey[0][col].accessor)
								? `$${helper.getNumberFormat(prevValue)}`
								: prevValue || '-';
						}
					}

					let removeCommma = String(
						isOverride ? overrideData : commonData,
					).replace(',', '');

					let dataKey;
					if (isOverride) {
						if (overrideData) dataKey = 'overrideField';
						if (!overrideData) dataKey = 'accessor';
					} else if (
						[ 'date', 'text', 'ammount' ].includes(csvDataKey[0][col].key)
					) {
						dataKey = 'accessor';
					} else {
						dataKey = 'key';
					}
					dataobject = {
						...dataobject,
						[csvDataKey[0][col][dataKey]]: removeCommma,
					};
				}
			}
			csvData.push(dataobject);
		}

		let noramlpgSize = 25;
		let pageSizeTableOptions = [ 25, 50, 100, 150, 200 ];
		if (this.props.pageSize) {
			noramlpgSize = this.props.pageSize;
			pageSizeTableOptions = [ 10, 25, 50, 100, 150, 200 ];
		}

		if (this.props.isProduct) {
			if (tableData && this.props.isProduct) {
				for (let product of tableData) {
					const defaultWareHouse = product.defaultWarehouse || 2019003243;
					const warehouseArr = product.Warehouses || [];
					const warehouse = warehouseArr.filter((warehouse) => warehouse.WarehouseId === defaultWareHouse);
					let QuantityOnHand = product.QuantityOnHand || "0";
					if (Boolean(product.Warehouses && product.Warehouses.length) && Boolean(warehouse.length) && product.Type !== "Kit") {
						QuantityOnHand = Boolean(String(warehouse[0].QuantityOnHand)) ? String(warehouse[0].QuantityOnHand) : "0";
					}
					product.Image1 = product.Images && product.Images[0] && product.Images[0].Url;
					product.Image2 = product.Images && product.Images[1] && product.Images[1].Url;
					product.Image3 = product.Images && product.Images[2] && product.Images[2].Url;
					product.Image4 = product.Images && product.Images[3] && product.Images[3].Url;
					product.Image5 = product.Images && product.Images[4] && product.Images[4].Url;
					product.Image6 = product.Images && product.Images[5] && product.Images[5].Url;
					product.Image7 = product.Images && product.Images[6] && product.Images[6].Url;
					product.QuantityOnHand = QuantityOnHand;
				};
			}
	
			const csvHeaders = [
				{ label: "Parent SKU Number", key: "ParentSku" },
				{ label: "SKU Number", key: "Sku" },
				{ label: "Name", key: "Name" },
				{ label: "Sold As Name", key: "SoldAsName" },
				{ label: "Condition", key: "Condition" },
				{ label: "Description", key: "LongDescription" },
				{ label: "Price", key: "Price" },
				{ label: "Brand", key: "BrandName" },
				{ label: "Category", key: "Category" },
				{ label: "Upc", key: "UPC" },
				{ label: "EAN", key: "EAN" },
				{ label: "GTIN", key: "GTIN" },
				{ label: "ISBN", key: "ISBN" },
				{ label: "Quantity Available", key: "QuantityOnHand" },
				{ label: "Image 1", key: "Image1" },
				{ label: "Image 2", key: "Image2" },
				{ label: "Image 3", key: "Image3" },
				{ label: "Image 4", key: "Image4" },
				{ label: "Image 5", key: "Image5" },
				{ label: "Image 6", key: "Image6" },
				{ label: "Image 7", key: "Image7" },
				{ label: "Features 1", key: "Feature1" },
				{ label: "Features 2", key: "Feature2" },
				{ label: "Features 3", key: "Feature3" },
				{ label: "Features 4", key: "Feature4" },
				{ label: "Features 5", key: "Feature5" },
				// { label: "Weight", key: "Weight" },
				// { label: "Width", key: "Width" },
				// { label: "Height", key: "Height" },
				// { label: "Length", key: "Length" },
				// { label: "Retail Price", key: "BuyPrice" },
				// { label: "Inventory Quantity", key: "QuantityOnHand" },
				// { label: "Keywords", key: "Seo" },
				// { label: "ISBN", key: "Identifiers.ISBN" },
				// { label: "GTIN", key: "Identifiers.GTIN" },
				// { label: "EAN", key: "Identifiers.EAN" },
				// { label: "Product Feature", key: "Feature2" },
				// { label: "Product Feature", key: "Feature3" },
				// { label: "Product Feature", key: "Feature4" },
				// { label: "Product Feature", key: "Feature5" },
			];

			const cleanCsv = (dataCellData) => {
				if (dataCellData) {
					return String(dataCellData).replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'');
				}
				return "";
			};
	
			headers = csvHeaders;
			for (let data of tableData) {
				if (data.ProductVariations && Boolean(data.ProductVariations.length)) {
					for (let variant of data.ProductVariations) {
						ProductCSVData.push({
							ParentSku: cleanCsv(data && data.ParentSku || data && data.Sku),
							Sku: cleanCsv(variant.Sku || data.Sku),
							Name: cleanCsv(data && data.Name),
							SoldAsName: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.SoldAsName),
							LongDescription: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.LongDescription),
							Condition: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.Condition),
							Price: cleanCsv(variant.Price || data && data.GlobalListingAttributes && data.GlobalListingAttributes.Price),
							BrandName: cleanCsv(data && data.BrandName),
							Category: cleanCsv(data && data.Category),
							UPC: cleanCsv(variant.UPC || data && data.Identifiers && data.Identifiers.UPC),
							EAN: cleanCsv(variant.EAN || data && data.Identifiers && data.Identifiers.UPC),
							GTIN: cleanCsv(variant.GTIN || data && data.Identifiers && data.Identifiers.UPC),
							ISBN: cleanCsv(variant.ISBN || data && data.Identifiers && data.Identifiers.UPC),
							Image1: cleanCsv(variant.Image || data && data.Image1),
							Image2: cleanCsv(data && data.Image2),
							Image3: cleanCsv(data && data.Image3),
							Image4: cleanCsv(data && data.Image4),
							Image5: cleanCsv(data && data.Image5),
							Image6: cleanCsv(data && data.Image6),
							Image7: cleanCsv(data && data.Image7),
							QuantityOnHand: cleanCsv(variant.QuantityAvailable || data && data.QuantityOnHand),
							Feature1: cleanCsv(data && data.Feature1),
							Feature2: cleanCsv(data && data.Feature2),
							Feature3: cleanCsv(data && data.Feature3),
							Feature4: cleanCsv(data && data.Feature4),
							Feature5: cleanCsv(data && data.Feature5),
						});
					}
				}
				else {
					ProductCSVData.push({
						ParentSku: cleanCsv(data && data.ParentSku),
						Sku: cleanCsv(data && data.Sku),
						Name: cleanCsv(data && data.Name),
						SoldAsName: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.SoldAsName),
						LongDescription: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.LongDescription),
						Condition: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.Condition),
						Price: cleanCsv(data && data.GlobalListingAttributes && data.GlobalListingAttributes.Price),
						BrandName: cleanCsv(data && data.BrandName),
						Category: cleanCsv(data && data.Category),
						UPC: cleanCsv(data && data.Identifiers && data.Identifiers.UPC),
						EAN: cleanCsv(data && data.Identifiers && data.Identifiers.EAN),
						GTIN: cleanCsv(data && data.Identifiers && data.Identifiers.GTIN),
						ISBN: cleanCsv(data && data.Identifiers && data.Identifiers.ISBN),
						Image1: cleanCsv(data && data.Image1),
						Image2: cleanCsv(data && data.Image2),
						Image3: cleanCsv(data && data.Image3),
						Image4: cleanCsv(data && data.Image4),
						Image5: cleanCsv(data && data.Image5),
						Image6: cleanCsv(data && data.Image6),
						Image7: cleanCsv(data && data.Image7),
						QuantityOnHand: cleanCsv(data && data.QuantityOnHand),
						Feature1: cleanCsv(data && data.Feature1),
						Feature2: cleanCsv(data && data.Feature2),
						Feature3: cleanCsv(data && data.Feature3),
						Feature4: cleanCsv(data && data.Feature4),
						Feature5: cleanCsv(data && data.Feature5),
					});
				}
			}
		}

		if (this.props.isOrders) {
			headers = (this.props.orderCsvHeaders || []).map((orderHeader) => ({
				label: orderHeader.title,
				key: orderHeader.key
			}));
			csvData = tableData;
		}

		if (this.props.isProduct) {
			csvData = ProductCSVData;
		}
		return (
			<div>
				{this.state.isLoading == true && (
					<div
						className='load'
						style={{
							backgroundColor: 'transparent',
							width: '85%',
							height: '50%',
						}}
					>
						<div className='load__icon-wrap'>
							<svg className='load__icon'>
								<path
									fill='#6c6f7f'
									d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
								/>
							</svg>
						</div>
					</div>
				)}
				{this.state.isLoading == false && (
					<div>
						<FormGroup row className='download_buttons'>
							<Col
								sm='12'
								style={{ display: 'flex', justifyContent: 'flex-end' }}
							>
								<Col
									sm={4}
									lg={4}
									style={{ display: 'flex', justifyContent: 'flex-end' }}
								>
									{
										(!this.props.isProduct && !this.props.isOrders) && (
											<CSVLink
											headers={headers}
											data={csvData || []}
											filename={helper.getFileNameCsv(
												null,
												this.props.filename,
												new Date(),
											)}
										>
											<button className='btn addbtn'>Export CSV</button>
										</CSVLink>
										)
									}
									{
										(this.props.isProduct || this.props.isOrders) && (
											<div className="csv_export_container">
											<Dropdown
												style={{ padding: 0, marginRight: 0, marginBottom: 0 }}
												disabled={true}
												isOpen={this.state.dropdownOpen} toggle={() => {
												this.setState({
													dropdownOpen: !this.state.dropdownOpen,
												});
											}}>
												<DropdownToggle caret>
													{this.state.exportingAll ? "Exporting...": "Export CSV"}
												</DropdownToggle>
												<DropdownMenu>
												<CSVLink
													headers={headers}
													data={csvData || []}
													filename={helper.getFileNameCsv(
														null,
														this.props.filename,
														new Date(),
													)}
												>
													<DropdownItem>Export Current Rows</DropdownItem>
												</CSVLink>
													<DropdownItem onClick={() => this.exportAll(headers)}>Export All Available Rows</DropdownItem>
												</DropdownMenu>
											</Dropdown>
										</div>
										)
									}
									{/*	{ this.props.exportAll && <button className='btn addbtn' onClick={() => this.props.exportAll({ headers, csvDataKey })}>
										Export All
									</button> }*/}
								</Col>
								{this.props.name == 'shipments' && (
									<Col sm='2' className='search_filters'>
										<button className='btn addbtn'>Due</button>
									</Col>
								)}
								{this.props.name == 'shipments' && (
									<Col sm='2' className='search_filters'>
										<button className='btn addbtn'>Past</button>
									</Col>
								)}
								{this.props.name == 'shipments' && (
									<Col sm='2' className='search_filters'>
										<button className='btn addbtn'>All</button>
									</Col>
								)}
								{this.props.name == 'Inventory' && (
									<Col sm='3' className='from_date'>
										<button className='btn addbtn'>
											Create Fulfillment Order
										</button>
									</Col>
								)}
							</Col>
						</FormGroup>
						{this.props.normal != true && (
							<ReactTable
								className='-striped -highlight'
								data={tableData}
								columns={columns}
								defaultPageSize={noramlpgSize}
								pages={this.props.totalPage}
								className='-striped -highlight'
								loading={this.props.tableLoading}
								showPagination={true}
								showPaginationTop={false}
								showPaginationBottom={true}
								resizable={true}
								onPageChange={this.onTableViewChange}
								onPageSizeChange={this.onTableViewChange}
								onSortedChange={this.onTableViewChange}
								onExpandedChange={this.onTableViewChange}
								onFilteredChange={this.onTableViewChange}
								onResizedChange={this.onTableViewChange}
								pageSizeOptions={pageSizeTableOptions}
								noDataText={this.props.noDataText ? this.props.noDataText : "No rows found"}
								manual // this would indicate that server side pagination has been enabled
								getTrProps={(state, rowInfo, instance) => {
									if (rowInfo && !this.props.isUserManagement) {
										return {
											style: {
												background: ((rowInfo.original && rowInfo.original.ProductStatus === 'pending_reviewal') && !Boolean(rowInfo.original && rowInfo.original.MarketPlaceLinks && rowInfo.original.MarketPlaceLinks.length)) ? '#ffa50029' : 'transparent',
											}
										}
									}
									return {
										style: {
											background: 'transparent',
										}
									};
								}}
								onFetchData={(state, instance) => {
									if (this.props.getData) {
										console.log('fetchdata===============');
										this.setState({ allMarked: false });
										this.props.getData(state.page + 1, state.pageSize);
									}
								}}
							/>
						)}
						{this.props.normal == true && (
							<ReactTable
								className='-striped -highlight simpleListing'
								data={tableData}
								columns={columns}
								defaultPageSize={pgSize}
								resizable={true}
								showPageSizeOptions={
									this.props.showPageSizeOptions == false ? false : true
								}
								pageSizeOptions={pageSizeOptions}
								className='-striped -highlight'
								loading={this.props.tableLoading}
								onPageChange={this.onTableViewChange}
								onPageSizeChange={this.onTableViewChange}
								onSortedChange={this.onTableViewChange}
								onExpandedChange={this.onTableViewChange}
								onFilteredChange={this.onTableViewChange}
								onResizedChange={this.onTableViewChange}
								noDataText={this.props.noDataText ? this.props.noDataText : "No rows found"}
								onFetchData={(state, instance) => {
									if (this.props.getProductPageDetails) {
										this.props.getProductPageDetails(
											state.page + 1,
											state.pageSize,
										);
									}
								}}
							/>
						)}
					</div>
				)}

				<Modal
					isOpen={this.state.confirmObservationModal}
					className={'modal-sm ' + this.props.className}
					style={{ marginTop: 150 }}
				>
					<ModalHeader />
					<ModalBody style={{ overflow: 'inherit' }}>
						<div
							className='form__form-group-field left'
							style={{ width: '100%', display: 'block' }}
						>
							<span style={{ width: '100%', float: 'left' }}>
								<label>Return Date</label>
								<input
									name='date'
									type='date'
									placeholder=''
									value={this.state.returnDate}
									onChange={(event) => {
										this.setState({ returnDate: event.target.value });
									}}
								/>
							</span>

							<span style={{ width: '100%', float: 'left', marginTop: 15 }}>
								<label>Observation</label>
								<input
									name='name'
									type='text'
									placeholder='Observation'
									value={this.state.observation}
									onChange={(event) => {
										this.setState({ observation: event.target.value });
									}}
								/>
							</span>
							<span style={{ width: '100%', float: 'left', marginTop: 15 }}>
								<label>Return Value ($)</label>
								<input
									name='return_value'
									type='number'
									min="0"
									placeholder='Return value'
									value={this.state.returnValue}
									onChange={(event) => {
										this.setState({ returnValue: event.target.value });
									}}
								/>
							</span>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							onClick={() => {
								this.addObservation();
							}}
						>
							Ok
						</Button>{' '}
						<Button
							color='secondary'
							onClick={() => {
								this.setState({ confirmObservationModal: false });
							}}
						>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<div onClick={this.toggleSmall}>
					<Modal
						isOpen={this.state.small}
						toggle={this.toggleSmall}
						className={'modal-sm ' + this.props.className}
						style={{ marginTop: 150 }}
					>
						<ModalHeader toggle={this.toggleSmall}></ModalHeader>
						<ModalBody>Are you sure you want to delete this product?</ModalBody>
						<ModalFooter>
							<Button
								color='primary'
								onClick={() => {
									this.deleteModel();
								}}
							>
								Ok
							</Button>{' '}
							<Button color='secondary' onClick={this.toggleSmall}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</div>

				<Modal
					size='lg'
					isOpen={this.state.confirmModal}
					toggle={this.toggle}
					className='orders_details_div'
				>
					<ModalHeader toggle={this.toggle}>
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex' }}>
								<span>Order Details</span>
								<div>
									{
										this.renderTrackUpdateAction(orderDetails.OrderNumber)
									}
								</div>
							</div>
						</div>
					</ModalHeader>
					<ModalBody className='orders_details'>
						<Col sm='4'>
							<p className='orders_details_label'>Order Id </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{orderDetails.OrderNumber}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Date </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{helper.getDateFormat(orderDetails.paymentDate)}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Channel </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{orderDetails.StorefrontName}
							</p>
						</Col>
						<div className='fix_height'>
							<Col sm='4'>
								<p className='orders_details_label'>Customer Name </p>
								<span className='order_dots'>:</span>
								<p className='orders_details_label_next'>
									{orderDetails.customer_name}
								</p>
							</Col>
							<Col sm='4'>
								<p className='orders_details_label'>Shipping Address </p>
								<span className='order_dots'>:</span>
								<p className='orders_details_label_next'>
									{orderDetails.customer_address}
								</p>
							</Col>
							<Col sm='4'>
								<p className='orders_details_label'>Email/Phone </p>
								<span className='order_dots'>:</span>
								<p className='orders_details_label_next'>
									{orderDetails.email}
								</p>
							</Col>
						</div>
						{/* <Col sm='4'>
							<p className='orders_details_label'>SKU </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>{orderDetails.sku}</p>
						</Col> */}
						<Col sm='4'>
							<p className='orders_details_label'>Sale Status </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>{orderDetails.Status}</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Shipment Status </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{orderDetails.shipment_status}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Shipment Id </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{this.state.ShipmentId}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Tracking Number </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								{this.state.TrackingNumber}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Tracking Url </p>
							<span className='order_dots'>:</span>
							<a
								href={this.state.TrackingURL}
								target='_blank'
								className='orders_details_label_next'
							>
								{this.state.TrackingURL}
							</a>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Price </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>
								${helper.getNumberFormat(orderDetails.TotalAmount)}
							</p>
						</Col>
						<Col sm='4'>
							<p className='orders_details_label'>Quantity Ordered </p>
							<span className='order_dots'>:</span>
							<p className='orders_details_label_next'>{orderDetails.volume}</p>
						</Col>

						<Col md={12} className='order_items' style={{ paddingTop: 20 }}>
							{orderDetails != '' && (
								<Col md={6} className='order_summary'>
									<Col md={12} className='order_summary_inner'>
										<h2>Order Items</h2>
										<div className='order_header' style={{ width: '100%' }}>
											<span className='order_item'>Sku</span>
											<span className='order_unit'>Unit</span>
											<span className='order_qty'>Qty</span>
											<span className='order_total'>Total $</span>
										</div>
										{orderDetails.LineItems.map((item, index) => (
											<div
												className='order_body'
												style={{ width: '100%' }}
												key={index}
											>
												<span className='order_item'>{item.SkuNumber}</span>
												<span className='order_unit'>
													${helper.getNumberFormat(item.UnitPrice)}
												</span>
												<span className='order_qty'>{item.QuantitySold}</span>
												<span className='order_total'>
													${helper.getNumberFormat(item.LineItemTotal)}
												</span>
											</div>
										))}
									</Col>
								</Col>
							)}

							<Col md={6} className='order_summary'>
								<Col md={12} className='order_summary_inner'>
									<h2>Order Activity </h2>
									<div className='activity' style={{ width: '100%' }}>
										{orderDetails.shipDate != '' &&
										orderDetails.shipDate != null && (
											<Col md={12} className='activity_inner'>
												<Col md={4} xs={3}>
													<span className='activity_date'>
														{helper.getActivityFormat(orderDetails.shipDate)}
													</span>
													<span className='dot' style={{ marginRight: 10 }} />
													<span className='line' />
												</Col>
												<Col md={8} xs={9}>
													<span className='dot_header'>Order Shipped</span>
													{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.shipDate)}</span> */}
												</Col>
											</Col>
										)}

										{orderDetails.PurchaseDate != '' &&
										orderDetails.PurchaseDate != null && (
											<Col md={12} className='activity_inner'>
												<Col md={4} xs={3}>
													<span className='activity_date'>
														{helper.getActivityFormat(
															orderDetails.PurchaseDate,
														)}
													</span>
													<span className='dot' style={{ marginRight: 10 }} />
													<span className='line' />
												</Col>
												<Col md={8} xs={9}>
													<span className='dot_header'>Order Paid</span>
													{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.paymentDate)}</span> */}
												</Col>
											</Col>
										)}

										{orderDetails.DateCreatedInEcomdash != '' &&
										orderDetails.DateCreatedInEcomdash != null && (
											<Col md={12} className='activity_inner final'>
												<Col md={4} xs={3}>
													<span className='activity_date'>
														{helper.getActivityFormat(
															orderDetails.DateCreatedInEcomdash,
														)}
													</span>
													<span className='dot' style={{ marginRight: 10 }} />
													<span className='line' />
												</Col>
												<Col md={8} xs={9}>
													<span className='dot_header'>Order Created</span>
													{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.orderDate)}</span> */}
												</Col>
											</Col>
										)}
									</div>
								</Col>
							</Col>
						</Col>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default TableList;
