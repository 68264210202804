import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import ResetpasswordForm from './components/ResetpasswordForm';

const Resetpassword = () => (
  <div className="account">
    {/* <div className="account__left">
      <img className="" src="img/bg.png" ></img>
    </div> */}
    <div className="account__wrapper">
      <div className="account__card">
      <div className="account__card_inner">
        <div className="account__head">
          <h3 className="account__title">
            <span className="account__logo"> <img src="../img/logo.png"/>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Enter your email and we send you a passsword reset link.</h4>
        </div>
        <ResetpasswordForm/>
        </div>
      </div>
    </div>
  </div>
);

export default Resetpassword;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
