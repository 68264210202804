import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import DatePicker from 'react-date-picker';

import {
	Card,
	CardBody,
	Col,
	Button,
	Input,
	FormGroup,
	Label,
} from 'reactstrap';
import api from '../../../utils/api';
import Select from 'react-select';

class OrdersCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			searchText: '',
			headers: [
				{ key: 'OrderNumber', title: 'Order No' },
				{ key: 'DateCreatedInEcomdash', title: 'Date' },
				{ key: 'StorefrontName', title: 'Channel' },
				{ key: 'customer_name', title: 'Customer Name' },
				{ key: 'customer_address', title: 'Customer Address' },
				{ key: 'email', title: 'Email/Phone No' },
				{ key: 'sku', title: 'SKU' },
				{ key: 'product_title', title: 'Product Title' },
				{ key: 'Status', title: 'Sale Status' },
				{ key: 'DeliveryMethod', title: 'Shipment Status' },
				{ key: 'TotalAmount', title: 'Price' },
				{ key: 'volume', title: 'Units Sold' },
				{ key: 'total_stock', title: 'Total Stock' },
			],
			name: 'Orders',
			items: [],
			status: { value: -1, label: 'All' },
			statusFilter: [
				{ value: -1, label: 'All' },
				{ value: 0, label: 'Cancelled' },
				{ value: 1, label: 'Complete' },
				{ value: 2, label: 'InProcess' },
				{ value: 3, label: 'New' },
			],
			tempItems: [],
			totalPage: 0,
			tableLoading: true,
			fromDate: '',
			toDate: '',
		};

		this.getData = this.getData.bind(this);
	}

	componentDidMount() {
		console.log('this.props.list_patient==', this.props.list_companies);
		this.getTodayDate();
	}

	getTodayDate() {
		let fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 5);
		let toDate = new Date();

		fromDate = helper.getDateFormat(fromDate);
		toDate = helper.getDateFormat(toDate);

		console.log('today==', fromDate, toDate);
		this.setState({ fromDate: fromDate, toDate: toDate });
	}

	getData(page) {
		this.setState({ tableLoading: true });
		let apiPath = 'orders/getOrders';
		let reqData = {
			FromDate: this.state.fromDate + ' 00:00:00',
			ToDate: this.state.toDate + ' 23:59:00',
			DateType: 0,
			OnlyNeedsFulfillment: true,
			Pagination: {
				ResultsPerPage: 25,
				PageNumber: page,
			},
			IncludeCustomerInfo: true,
			IncludeLineItems: true,
		};
		if (this.state.status.value > -1) {
			reqData.status = this.state.status.value;
		}
		console.log('reqData==', reqData);
		api.postApi(apiPath, reqData).then((data) => {
			console.log('data====', data);
			if (data.status == 'Success') {
				let items = data.data;
				for (let item in items) {
					if (items[item].CustomerInfo) {
						items[item].customer_name = items[item].CustomerInfo.FullName;
						let street = items[item].CustomerInfo.Address.Street1
							? items[item].CustomerInfo.Address.Street1 + ','
							: '';
						let city = items[item].CustomerInfo.Address.City
							? items[item].CustomerInfo.Address.City + ','
							: '';
						let country = items[item].CustomerInfo.Address.Country
							? items[item].CustomerInfo.Address.Country + ','
							: '';
						let zipcode = items[item].CustomerInfo.Address.ZipCode
							? items[item].CustomerInfo.Address.ZipCode
							: '';
						var fulladdress = street + city + country + zipcode;
						var trim = fulladdress.replace(/(^,)|(,$)/g, '');
						items[item].customer_address = trim;
						items[item].email = items[item].CustomerInfo.EmailAddress;
					}
					if (items[item].LineItems && items[item].LineItems.length > 0) {
						items[item].sku = items[item].LineItems[0].SkuNumber;
						items[item].product_title = items[item].LineItems[0].ProductName;
						items[item].volume = items[item].LineItems[0].QuantitySold;
					}
				}
				this.setState({
					items: items,
					totalPage: data.pagination.TotalNumberOfPages,
				});
			} else {
				if (data.messages.length > 0) {
					helper.errorMessage(data.messages[0]);
				}
			}
			this.setState({ tableLoading: false });
		});
	}

	changeFromDate(value) {
		this.setState(
			{
				fromDate: helper.getDateFormat(value),
			},
			() => {
				this.getData(1);
			},
		);
	}

	changeToDate(value) {
		this.setState(
			{
				toDate: helper.getDateFormat(value),
			},
			() => {
				this.getData(1);
			},
		);
	}

	actionSearch() {
		if (this.state.searchText.length > 0) {
			this.getData(1);
		}
	}

	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				if (text.length == 0) {
					this.getData(1);
				}
			},
		);
	}

	handleSatusChange = (status) => {
		this.setState({ status }, () => {
			this.getData(1);
		});
	};

	render() {
		const { t } = this.props.setOptions;
		return (
			<Col className='container-div' md={12}>
				{this.state.isLoading == true && (
					<div
						className='load'
						style={{
							backgroundColor: 'transparent',
							width: '85%',
							height: '50%',
						}}
					>
						<div className='load__icon-wrap'>
							<svg className='load__icon'>
								<path
									fill='#6c6f7f'
									d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
								/>
							</svg>
						</div>
					</div>
				)}
				{this.state.isLoading == false && (
					<Col md={12}>
						<Card>
							<CardBody>
								<div className='card__title'>
									<h5 className='bold-text'>Orders</h5>
									<FormGroup row className='download_buttons'>
										{this.state.fromDate != '' && (
											<FormGroup
												row
												className='from_to'
												style={{ width: '75%', margin: 0 }}
											>
												<Col
													sm='3'
													className='from_date'
													style={{ marginRight: 15 }}
												>
													<Label style={{ fontSize: 12 }}>From</Label>
													<DatePicker
														value={new Date(this.state.fromDate)}
														onChange={(event) => this.changeFromDate(event)}
													/>

													{/* <Input type="date" id="date-input" name="date-input" placeholder="date"  value={this.state.fromDate}   onChange={(event) => this.changeFromDate(event.target.value)}/> */}
												</Col>
												<Col sm='3' className='from_date'>
													<Label style={{ fontSize: 12 }}>To</Label>
													<DatePicker
														value={new Date(this.state.toDate)}
														onChange={(event) => this.changeToDate(event)}
													/>

													{/* <Input type="date" id="date-input" name="date-input" placeholder="date" value={this.state.toDate} onChange={(event) => this.changeToDate(event.target.value)}/> */}
												</Col>
											</FormGroup>
										)}

										<div className='search1'>
											<span>
												<Label style={{ fontSize: 12 }}>Status</Label>
												<Select
													value={this.state.status}
													onChange={this.handleSatusChange}
													options={this.state.statusFilter}
												/>
											</span>
										</div>
									</FormGroup>
								</div>

								{this.state.fromDate != '' && (
									<TableList
										tableLoading={this.state.tableLoading}
										totalPage={this.state.totalPage}
										getData={this.getData}
										items={this.state.items}
										headers={this.state.headers}
										name={this.state.name}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				)}
			</Col>
		);
	}
}

const mapStateToProps = ({ curdReducer }) => {
	const { list_companies } = curdReducer;
	return { list_companies };
};

export default connect(mapStateToProps, actions)(OrdersCard);
