import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import firebase1 from '../../../utils/config';
import { Card, CardBody, Col, Badge, Button, Input, FormGroup, Label } from 'reactstrap';
import api from '../../../utils/api';

import CSVReader from 'react-csv-reader';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

class UsermanagementCard extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      searchText: '',
      tableLoading: true,
      totalPage: 20,
      headers: [{ key: 'priority', title: 'Priority Color' },
      { key: 'firstname', title: 'Name' },
      { key: 'email', title: 'Email' },
      { key: 'role', title: 'Role' },
      { key: 'company', title: 'Company Name' },
      { key: 'override', accessor: 'utm_medium', overrideField: 'utm_medium', title: 'UTM Medium' },
      { key: 'override', accessor: 'utm_term', overrideField: 'utm_term', title: 'UTM Term' },
      { key: 'override', accessor: 'utm_source', overrideField: 'utm_source', title: 'UTM Source' },
      { key: 'override', accessor: 'utm_campaign', overrideField: 'utm_campaign', title: 'UTM Campaign' },
      { key: 'override', accessor: 'utm_content', overrideField: 'utm_content', title: 'UTM Content' },
      { key: 'override', accessor: 'number_sku', overrideField: 'number_sku', title: "Number of SKU's" },
      { key: 'override', accessor: 'sales', overrideField: 'sales', title: "Total Sales" },
      { key: 'override', accessor: 'comission', overrideField: 'totalComission', title: "Total Commission" },
      { key: 'override', accessor: 'register_date', overrideField: 'register_date', title: "Register Date" },
      { key: 'override', accessor: 'subscriber_date', overrideField: 'subscriber_date', title: "Subscription Date" },
      { key: 'cancel_date', title: "Cancellation Date" },
      { key: 'override', accessor: 'paypal_billing', overrideField: 'status', title: "Status (Free/Paid)" },
      { key: 'override', accessor: 'totalPaid', overrideField: 'totalPaid', title: "Total Paid" },
      { key: 'override', accessor: 'paidPlanValue', overrideField: 'planValue', title: "Paid Plan Value" },
      { key: 'override', accessor: 'advertising_amount-plan', overrideField: 'marketingPlan', title: "Marketing Plan (Y/N)" }, // if 0 is N
      { key: 'override', accessor: 'advertising_amount', overrideField: 'marketingPlanValue', title: "Marketing Plan Value" },
      { key: 'override', accessor: 'ship_orders_logic', overrideField: 'logisticsPlan', title: "Logistics Plan (Y/N)" },
      { key: 'override', accessor: 'fullFillments', overrideField: 'logisticsPlanValue', title: "Logistics Plan Value" },
      { key: 'password', title: "Password" },
      { key: 'sell_type', title: "Marketplaces" },
      { key: 'ship_orders', title: "Fulfillment Method" },
      { key: 'paid_type', title: "Payment Method" },
      { key: 'handle_time', title: "Handle Time" },
      { key: 'customer_feedback', title: "FeedBack" },
      { key: 'useredit', title: 'Edit' },
      { key: 'delete', title: 'Delete' }
      ],
      name: 'usermanagement',
      items: [],
      tempItems: [],
    };
    this.deleteAction = this.deleteAction.bind(this);
    this.getData = this.getData.bind(this);
    this.exportUsersToCsv = this.exportUsersToCsv.bind(this);
    this.tempPage = 0
    this.stripeCustomer = [];
    this.pageArray = [];
  }

  componentDidMount() {
    //this.setState({ isLoading: true });
    // this.getUser();
  }


  getData(page, pageSize) {
    let apiPath = '';
    apiPath = 'user/list';

    let items = this.state.items;
    let startAfter = '';
    if (0 < items.length) {
      if (this.tempPage < page) {
        startAfter = items[items.length - 1].firstname;

      } else if (page == 1) {
        startAfter = ''
      } else {
        console.log('pageArray===', this.pageArray[page])
        if (this.pageArray[page]) {
          startAfter = this.pageArray[page];
        }
      }
    }

   
    let reqData = {
      "Pagination": {
        "ResultsPerPage": pageSize,
        "PageNumber": page,
        "StartAfter": startAfter,
        "Search": this.state.searchText
      }
    }
    this.setState({ tableLoading: true })
    //console.log(reqData);
    api.postApi(apiPath, reqData).then((data) => {
      if (data.status == 'Success') {
        let items = data.data;
        //let count =((page*pageSize)-pageSize)+1;
        for (let item in items) {

          if (items[item].role && items[item].role.label) {
            items[item].role = items[item].role.label;
          }

          if (items[item].customer_feedback == undefined){
            items[item].customer_feedback = ''
          }

          if (items[item].cancel_reason == undefined){
            items[item].cancel_reason = ''
          }

          if (items[item].sell_type && items[item].sell_type.length > 0) {
            let sellList = '';
            for (let item1 in items[item].sell_type) {
              if (item1 != (items[item].sell_type.length - 1)) {
                sellList = items[item].sell_type[item1].label + ',' + sellList
              } else {
                sellList = sellList + items[item].sell_type[item1].label
              }

            }

            items[item].sell_type = sellList;
          } else {
            items[item].sell_type = ''
          }

          if (items[item].ship_orders) {
            items[item].ship_orders = items[item].ship_orders.label;
          } else {
            items[item].ship_orders = ''
          }

          if (items[item].paid_type) {
            items[item].paid_type = items[item].paid_type.label;
          } else {
            items[item].paid_type = ''
          }

          if (items[item].handle_time) {
            items[item].handle_time = items[item].handle_time.label;
          } else {
            items[item].handle_time = ''
          }

          if (items[item].admin == false) {

            if (items[item].billing && items[item].billing.stripe_customer_id) {

              items[item].cc_status = items[item].billing.cc_status;
              items[item].subscriber_date = items[item].billing.subscriber_date;
            } else {
              items[item].cc_status = 'Pending';
              items[item].subscriber_date = '-';
            }
          }
          else {
            items[item].subscriber_date = '-'
            items[item].cc_status = 'Admin User';
          }
          if (items[item].register_date != '' && items[item].register_date) {
            items[item].register_date = helper.getDateFormat(items[item].register_date);
          }
        }
        if (0 < items.length) {
          this.pageArray[page + 1] = items[items.length - 1].firstname
          if (this.tempPage < page) {
            this.setState({ totalPage: (page + 1) })
          }
        }
        items = items.sort(this.compare);

        this.setState({ isLoading: false, items: items, tempItems: items, totalPage: data.pagination.TotalNumberOfPages })
      } else if (data.messages.length > 0) {
        helper.errorMessage(data.messages[0]);
      }
      this.tempPage = page
      this.setState({ isLoading: false, tableLoading: false });
    })
  }



  actionSearch() {

    if (this.state.searchText.length > 0) {
      this.pageArray = [];
      this.getData(1, 25)
    }
  }

  headerFilter(filterText, item) {

    let isFilter = false;
    for (let data in this.state.headers) {
      if (item[this.state.headers[data].key] && item[this.state.headers[data].key].label && String(item[this.state.headers[data].key].label).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
        isFilter = true;
      }
      else if (String(item[this.state.headers[data].key]).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) {
        isFilter = true;
      }
    }
    return isFilter;
  }

  searchKey(text) {
    this.setState({
      searchText: text
    }, () => {
      if (text.length == 0) {
        this.pageArray = [];
        this.getData(1, 25)
      }
    })

  }

  compare(a, b) {
    if (Number(a.date) < Number(b.date)) {
      return 1;
    }
    if (Number(a.date) > Number(b.date)) {
      return -1;
    }
    return 0;
  }

  handleForce = data => {
    this.setState({
      csvData: data
    }, () => {
      if (data.length > 0) {
        console.log('data==', data);
      }
    })
  }

  async deleteAction(id) {
    console.log('id===', id);
    let items = this.state.items;
    let tempItems = this.state.tempItems;
    this.setState({tableLoading:true})
    const db = firebase1.app.firestore();
    await db.collection('user').doc(id).delete();
    this.getData(this.tempPage, 25)
    // let index = helper.getItemIndex(items, 'key', id);
    // items.splice(index, 1);
    // index = helper.getItemIndex(tempItems, 'key', id);
    // tempItems.splice(index, 1);
    // this.setState({ items: [...items], tempItems: [...tempItems] });

  }

  addSku() {


    this.companyName = []
    this.userId = [];
    let csvData = this.state.csvData;
    for (let data in csvData[0]) {
      this.userId.push(csvData[0][data]);
      this.companyName.push(data);
    }
    this.count = 0
    this.sku = [];
    for (let data in csvData) {

      if (csvData[data]['parent_188264092'] && data < 5) {
        this.sku.push(csvData[data]['parent_188264092'])
      }
    }

  }



  handleDarkSideForce = error => {
    console.log('error==', error);
  }

  exportUsersToCsv() {
    let apiPath = '';
    apiPath = 'user/exportCsv';

    api.postApi(apiPath, {}).then(
        (data) => {
          if (data.status == 'Success') {
            helper.successMessage('Successfully processed export request. Email should arrive soon.');
          } else {
            helper.errorMessage('Something went wrong...')
          }
          this.setState({ isLoading: false });
        }
    )
  }


  render() {
    const { userdetails } = this.props;

    return (

      <Col className="container-div" md={12}>
        {this.state.isLoading == true && <div className="load" style={{ backgroundColor: 'transparent', width: '85%', height: '50%' }}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>}


        <Col md={12} >
          <div className="search" style={{ marginLeft: 15 }}>
            <form onSubmit={(e) => { 
                e.preventDefault();
                this.actionSearch();
             }}>
              <Input type="text" name="search" placeholder="Search for user" value={this.state.searchText} onChange={(event) => { this.searchKey(event.target.value) }} />
              <Button type="submit" style={{ padding: 0 }}>Search</Button>
            </form>
          </div>
          <div className="export-to-csv">
            <Button onClick={() => { this.exportUsersToCsv() }} style={{ padding: 0 }}>Export User List</Button>
          </div>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">User Management
            <Link to="/pages/adduser" className="addbuttons btn addbtn">
              {/* <button className="" >  */}
              + ADD
              {/* </button> */}
              </Link>
                  {/* <button className="btn addbtn">Import Sku
              <CSVReader
                cssClass="csv-reader-input"
                onFileLoaded={this.handleForce}
                onError={this.handleDarkSideForce}
                parserOptions={papaparseOptions}
                inputId="ObiWan"
                inputStyle={{color: 'red'}}/>
            </button> */}
                </h5>
              </div>

              <TableList normal={false}
                tableLoading={this.state.tableLoading}
                totalPage={this.state.totalPage}
                getData={this.getData}
                items={this.state.items}
                deleteAction={this.deleteAction}
                headers={this.state.headers}
                name={this.state.name}
                filename="User-Management"
                isUserManagement={true}
                company={userdetails && userdetails.company}
              />
            </CardBody>
          </Card>
        </Col>
      </Col>
    )
  }

}

const mapStateToProps = ({ curdReducer, authReducer }) => {
  const { list_companies } = curdReducer;
  const { userdetails } = authReducer;
  return { list_companies, userdetails };
};

export default connect(mapStateToProps, actions)(UsermanagementCard);
