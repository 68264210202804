import React, { Component } from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import Modal from 'react-modal';
import { Creatable } from 'react-select';
import api from '../../../utils/api';
import helper from '../../../utils/helper';

const uploadVideoStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '-28%',
        bottom: '-37%',
        width: '80%',
        height: '200px',
        transform: 'translate(-50%, -50%)',
        zindex: '9999',
        overflow: 'hidden',
    },
};

const components = {
    DropdownIndicator: null,
};

const createOption = (label) => ({
    label,
    value: label,
});


class ImportMissingOrders extends Component {
    state = {
        inputValue: '',
        value: [],
        loading: false,
    };
    handleChange = (value, actionMeta) => {
        console.groupEnd();
        this.setState({ value });
    };
    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (event) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                console.group('Value Added');
                console.log(value);
                console.groupEnd();
                this.setState({
                    inputValue: '',
                    value: [...value, createOption(inputValue)],
                });
                event.preventDefault();
        }
    };

    pullOrders = () => {
        const { value } = this.state;
        this.setState({ loading: true });
        api.postApi('ImportOrdersByEcomdashId', { ecomdashIds: value.map((ecomdashId) => ecomdashId.value) })
            .then((response) => {
                this.setState({ loading: false });
                if (response.status === 'Success') {
                    helper.successMessage(response.message);
                }
                else {
                    helper.successMessage(response.message);
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                helper.successMessage("Something wen't wrong, please try again later.");
            });
    }

    render() {
        const { modalIsOpen, closeModal } = this.props;
        const { inputValue, value } = this.state;
        return (
            <div>
                <Modal
                    size='sm'
                    isOpen={modalIsOpen}
                    style={{ top: 180 }}
                    style={uploadVideoStyles}
                    toggle={closeModal}
                >
                    <ModalHeader>Enter Ecomdash IDs</ModalHeader>
                    <ModalBody>
                        <Creatable
                            components={components}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={this.handleChange}
                            onInputChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder="Enter Ecomdash Order Id (press enter on every entry)"
                            value={value}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='primary'
                            onClick={this.pullOrders}
                            disabled={Boolean(value.length === 0) || this.state.loading}
                        >
                            {
                                this.state.loading ? 'Please wait...' : 'Pull Orders'
                            }
                        </Button>
                        <Button color='danger' onClick={closeModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
};

export default ImportMissingOrders;
