import React, { PureComponent } from 'react';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';

class LogInForm extends PureComponent {


  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      isLoading:false,
      error: '',
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };


  componentWillReceiveProps(nextProps) {
    if(nextProps.user!=this.props.user){
      console.log('logined');
      this.setState({isLoading:false})
       // history.push("/pages/dashboard");
    }
    if (nextProps.error !== this.props.error&&nextProps.error!='') {
        this.setState({isLoading:false})
         helper.errorMessage(nextProps.error);
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  };

  handleLogin = () => {
      const { email, password } = this.state;
      //history.push('/sign_up');
      console.log();
      if(email==''){
        helper.errorMessage('Email field is required');
      }else if(password==''){
        helper.errorMessage('Password field is required');
      }else{
        localStorage.setItem('firstsession',true);
        this.setState({isLoading:true})
        this.props.loginUser({ email, password });
      }
  }

  render() {
    const {t} = this.props.setOptions
    if(this.state.isLoading){
      return(
        <div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )
    }else{
      return (
        <div className="form">
          <div className="form__form-group">
            <div className="form__form-group-field" style={{width:'100%'}}>
              <input value={this.state.email}  onChange={(event) => {this.setState({ email: event.target.value })}}
                    id="email"
                    type="text"
                     placeholder="Username/Email"
                  />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field" style={{width:'100%'}}>
              <input
                  value={this.state.password}  onChange={(event) => {this.setState({ password: event.target.value })}}
                    id="password"
                    type="password"
                    onKeyDown={this.handleKeyDown}
                  placeholder="Password"
                />
            </div>
  
          </div>
          <div className="form__form-group firstlastname">
            <div className="form__form-group-field left">
  
            </div>
            <div className="account__forgot-password right">
              {/* <a className="signup_link" href="/signup">Sign up</a> */}
              <Link to="/forgotpassword">Forgot Password?</Link>
              
            </div>
          </div>
          <a onClick={() => { this.handleLogin() }} className="btn btn-primary loginbtns_active account__btn--small"><span style={{color:'#fff'}}>Login</span></a>
        
        </div>
      );
    }
    }
    
}

const mapStateToProps = ({ authReducer }) => {
  return authReducer;
};

export default connect(mapStateToProps, actions)(LogInForm);
