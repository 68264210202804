
import helper from "../../utils/helper";
import history from '../../utils/history';
import firebase1 from '../../utils/config';

import {
  ADD_RECORD,
  EDIT_RECORD,
  DELETE_RECORD,
  LIST_RECORD,
  UPDATE_RECORD,
  UPDATE_EXAM_MODAL,
  EDIT_MEDICAL_RECORD,
  UPDATE_MEDICAL_RECORD,
  TABLE_LOADING,
  LOGIN_USER_SUCCESS_DETAILS
} from '../constants/Type';



export const listRecord = (collection) => {
  return (dispatch) => {
      const db = firebase1.app.firestore();
      let listRecord =[];
      db.collection(collection).get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var currentDocument=doc.data();
            currentDocument.key=doc.id;
            listRecord.push(currentDocument);
          })
          listRender(collection,listRecord,dispatch);
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      })
  }
}

export const editAction = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_RECORD,
      payload:data
    });

  };
};



export const editRecord = (collection,id) => {
  return (dispatch) => {
    
    const db = firebase1.app.firestore();
    db.collection(collection).doc(id).get()
    .then(function(querySnapshot) {
        let data = querySnapshot.data()
        data.key = querySnapshot.id;
        dispatch({
          type: EDIT_RECORD,
          payload:data
        });
        console.log('edit====',data);
        //window.location.href="/pages/"+collection;
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    })

  };
};



export const addRecord =  (collection,data,redirect) => async dispatch => {

    
  const db = firebase1.app.firestore();

    if(data.file!=undefined){
      if(data.file!=''){
        const photoURL =  await uploadImage(data.file,collection);
        data.profile_photo = photoURL;
      }
      data.file ='';
    }
    if(data.company_logo_file!=undefined){
      if(data.company_logo_file!=''){
        const photoURL =  await uploadImage(data.company_logo_file,collection);
        data.company_logo = photoURL;
      }
      data.company_logo_file ='';
    }
    
    //const counterRef = db.collection(collection)
    db.collection(collection).add(data).then(function(querySnapshot) {
      console.log('curd');
     
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    })

    

};




export const updateRecord = (collection,data,key,redirect) => async dispatch => {
   

    const db = firebase1.app.firestore();
    if(data.file!=undefined){
      if(data.file!=''){
        const photoURL =  await uploadImage(data.file,collection);
        data.profile_photo = photoURL;
      }
      data.file ='';
    }
    if(data.company_logo_file!=undefined){
      if(data.company_logo_file!=''){
        const photoURL =  await uploadImage(data.company_logo_file,collection);
        data.company_logo = photoURL;
      }
      data.company_logo_file ='';
    }
    let updatelist ={
      ...data,
      key:key
    }

   // console.log('updatelist==',updatelist);
    
    db.collection(collection).doc(key).update(data)
    .then(function(data1) {
       // console.log("Document successfully written!");
        
        if(!data.editModal){
          if(redirect!=false){
            history.push("/pages/"+collection);
          }else{

          }
          
        }
        
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    })
    console.log('updateRecord======',data);
};

export const updateExamModal = (action) => async dispatch => {

  dispatch({
    type: UPDATE_EXAM_MODAL,
    payload:action
  });

}

export const uploadImage = async (file,collection): Promise<string | null> => {
  try {

    let timeStamp = new Date().getTime();
    const spaceRef = firebase1.app.storage().ref().child(collection).child(String(timeStamp));
    return spaceRef.put(file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
      return snapshot.ref.getDownloadURL().then((downloadURL) => {
          return downloadURL;
      })

    });
  } catch (err) {
    console.log(err);
    return null;
  }

};

export const deleteRecord = (collection,items,id) => {
  return (dispatch) => {
     const db = firebase1.app.firestore();
     db.collection(collection).doc(id).delete();
    deleteRender(collection,id,dispatch);
  };
};

export const deleteDependency =  (collection,id) => async dispatch => {
  
  const db = firebase1.app.firestore();
  
 
  let query=''
  if(collection=='patients'){
    query=db.collection('medical').where("generalInformation.key", "==", id)
  }else if(collection=='diagnosis'){
    query=db.collection('medical').where("generalInformation.key", "==", id)
  }
  if(query!=''){
    dispatch({
      type: TABLE_LOADING,
      payload:true
    });
    query.get().then(function(querySnapshot) {
      console.log(querySnapshot.size);
      if(querySnapshot.size>0){
        helper.errorMessage('we cannot delete because there are medical case associated with the '+collection);
      }else{
        db.collection(collection).doc(id).delete();
        deleteRender(collection,id,dispatch);
      }
      dispatch({
        type: TABLE_LOADING,
        payload:false
      });
      //console.log('collection===',id);
    })
  }else{
    db.collection(collection).doc(id).delete();
    deleteRender(collection,id,dispatch);
  }
 
  //
}

const updateRender = async (collection,item,dispatch) => {

  let name ='list_'+collection;
  dispatch({
    type: UPDATE_RECORD,
    name:name,
    payload:item
  });

}

const listRender = async (collection,item,dispatch) => {

  let name ='list_'+collection;
  dispatch({
    type: LIST_RECORD,
    name:name,
    payload:item
  });

}





const addRender = async (collection,item,dispatch) => {

  let name ='list_'+collection;

  console.log(name,item);
  dispatch({
    type: ADD_RECORD,
    name:name,
    payload:item
  });

}

const deleteRender = async (collection,id,dispatch) => {

  let name ='list_'+collection;
  dispatch({
    type: DELETE_RECORD,
    name:name,
    payload:id
  });
}

export const addHelpQuery = (data)=>{
  return (dispatch)=>{
  console.log("data+++++++++++",data)
  const db = firebase1.app.firestore();
  return db.collection('help').add(data).then(res=>{
    if(res){
      helper.successMessage('Thanks for submitting this case. A support associate will contact you as soon as possible');
    }
  }).catch(err=>{  helper.errorMessage('Something went wrong');})
  }

}
