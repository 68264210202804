import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HelpForm from './components/HelpForm';



const Help = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Help</h3>
      </Col>
    </Row>
    <Row>
      <HelpForm />
    </Row>
  </Container>
);

export default Help;
