const ecomDashBaseUrl = process.env.REACT_APP_ECOMDASH_BASE_URL;
const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.valetseller.com/' : process.env.REACT_APP_BASE_URL;

const paypalBaseUrl = process.env.REACT_APP_PAYPAL_BASE_URL;



const stripBaseUrl = process.env.REACT_APP_STRIPE_BASE_URL;
const stripeKey =  process.env.REACT_APP_STRIPE_KEY;
const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;


const stripeHeaders = {
  'Authorization': 'Bearer '+stripeSecretKey,
  'Content-Type':'application/x-www-form-urlencoded'
}

const headers = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OPC_KEY,
  'ecd-subscription-key': process.env.REACT_APP_OPC_SUBSCRPTION_KEY,
}

const paypalHeadersToken = {
  'Authorization': `Basic ${process.env.REACT_APP_PAYPAL_TOKEN_KEY}`
}



let api = {
  checkShopify(data){
    let url = process.env.REACT_APP_PAYPAL_SHOPIFY_URL;
    let options={
      method: "POST",
      body: JSON.stringify(data)
    }
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );
  },
  paypalTokenPost(path,data){

    var urlencoded = new URLSearchParams();
    for(let item in data){
      urlencoded.append(item, data[item]);
    }
    let url = paypalBaseUrl+path;
    let options={
      method: "POST",
      headers:paypalHeadersToken,
      body: urlencoded
    }
    console.log('options===',options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
  paypalGet(path,token){

    let url = paypalBaseUrl+path;
    let paypalAuthorizationToken = {
      'Authorization':"Bearer "+token
    }
    let options={
      method: "GET",
      headers:paypalAuthorizationToken
    }
    console.log(url,options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
  paypalPost(path,data,token){

    var urlencoded = new URLSearchParams();
    for(let item in data){
      urlencoded.append(item, data[item]);
    }
    let paypalAuthorizationToken = {
      'Authorization':"Bearer "+token,
      'Content-Type':'application/json'
    }
    let url = paypalBaseUrl+path;
    let options={
      method: "POST",
      headers:paypalAuthorizationToken,
      body: JSON.stringify(data)
    }
    console.log('options===',options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(result))
        .catch(error => console.log('error', error));

      }
    );

  },
  stripeGet(path){

    let url = stripBaseUrl+path;
    let options={
      method: "GET",
      headers:{'Authorization': 'Bearer '+stripeSecretKey}
    }
    //console.log(url,options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
  stripeDelete(path){

    let url = stripBaseUrl+path;
    let options={
      method: "DELETE",
      headers:{'Authorization': 'Bearer '+stripeSecretKey}
    }
   // console.log(url,options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
  stripeUpdate(path,data){
    var urlencoded = new URLSearchParams();
    for(let item in data){
      urlencoded.append(item, data[item]);
    }
    let url = stripBaseUrl+path;
    let options={
      method: "POST",
      headers:stripeHeaders,
      body: urlencoded
    }
    //console.log(url,options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
 stripePost(path,data){

    var urlencoded = new URLSearchParams();
    for(let item in data){
      urlencoded.append(item, data[item]);
    }
    let url = stripBaseUrl+path;
    let options={
      method: "POST",
      headers:stripeHeaders,
      body: urlencoded
    }
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(response => response.text())
        .then(result => resolve(JSON.parse(result)))
        .catch(error => console.log('error', error));

      }
    );

  },
  postApi(path,data) {

    let url = baseUrl+path;

    let options={
        method: "POST",
        headers,
        body: JSON.stringify(data)
    }
    
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
          }
        );
      }
    );

  },
  postEcomDashApi(path,data) {

    let url = ecomDashBaseUrl+path;

    let options={
        method: "POST",
        headers,
        body: JSON.stringify(data)
    }
    console.log(options);
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
          }
        );
      }
    );

  },
  putApi(path,data) {

    let url = baseUrl+path;

    let options={
        method: "PUT",
        headers,
        body: JSON.stringify(data)
    }
    
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
          }
        );
      }
    );

  },
  putEcomDasApi(path,data) {

    let url = ecomDashBaseUrl+path;

    let options={
        method: "PUT",
        headers,
        body: JSON.stringify(data)
    }
    
    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
          }
        );
      }
    );

  },
  deleteApi(path) {

    let url = baseUrl+path;
    var formappend =  new FormData();
    

    let options={
        method: "DELETE",
        headers,
        body: formappend
    }

    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {


            if (response.ok) {

              //alert(JSON.stringify(response));
              let responseText = response.json();

              resolve(responseText);
            }
            else {
              //alert(JSON.stringify(response));
              console.log('error1',response);
              reject(response.statusText);  
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
          }
        );
      }
    );


    //return fetch(url,options).then((res) => res.json())
  },
  getEcomDashApi(path) {
    let url = ecomDashBaseUrl+path;
    

    let options={
        method: "GET",
        headers
    }


    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          }
        );
      }
    );
  },
  getApi(path) {
    let url = baseUrl+path;
    

    let options={
        method: "GET",
        headers
    }


    return new Promise(
      function (resolve, reject) {
        fetch(url,options)
        .then(
          function(response) {
            if (response.ok) {
              let responseText = response.json();
              resolve(responseText);
            }
            else {
              console.log('error1',response,response.status);
              reject(response.statusText);  
              //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
            }
          }
        )
        .catch(
          function(error) {
             console.log('error2',error);
          }
        );
      }
    );
  }
}

module.exports = api
