import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  Badge,
  Button,
  Input,
  FormGroup,
  Label,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import helper from "../../../utils/helper";
import { connect } from "react-redux";
import ReactTable from "react-table";
import * as actions from "../../../redux/actions";
import TableList from "../../Table";
import api from "../../../utils/api";
import CSVReader from "../../../utils/csvReader";

// import CSVReader from "react-csv-reader";
import Tour from "react-product-intro";
import Select from "react-select";
import {
  INTRO,
  ADD_PRODUCT_BUTTON,
  PRODUCT_LISTING,
} from "../../Walkthrough/popupText";
import firebase1 from "../../../utils/config";
import { bulkProductModel } from '../../../utils/validationSchemas';
import { bulkProductTableColumns } from '../../../utils/helper';

const msgStyles = {
  background: "skyblue",
  color: "white"
};

// const NoOptionsMessage = () => {
//   return (
//     <Link to="/pages/marketplaces"><center>Upgrade Here for More Storefronts</center></Link> 
//   );
// };

const cartList = helper.getSellList();
const shipList = helper.getShipList();
const paidList = helper.getPaidList();
const timeList = helper.getTimeList();

const customStyles = {
  content: {
    top: "56%",
    left: "50%",
    right: "-28%",
    bottom: "auto",
    width: "450px",
    transform: "translate(-50%, -50%)",
    zIndex: "9999",
    overflow: "hidden",
  },
};
const deleteCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '160px',
    width: '400px'
  }
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class ProductsCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchText: "",
      statusType: [
        { value: 'draft', label: 'Draft' },
        { value: 'pending_reviewal', label: 'Pending Reviewal' },
        { value: 'under_review', label: 'Under Review' },
        { value: 'published', label: 'Published' }
      ],
      selectedProducts: [],
      headers: [
        { key: "Sku", title: "SKU" },
        { key: "Name", title: "Product Title" },
        { key: "image", title: "Image" },
        { key: "buy_price", title: "MSRP" },
        { key: "price", title: "Selling Price" },
        { key: 'warehouse_stock', title: 'Valet Seller Warehouse Stock' },
        { key: 'amazon_fba_stock', title: 'Amazon FBA Stock' },
        { key: "product_status", title: "Product Status" },
        { key: "returnedStock", title: "Returned Inventory" },
        { key: "marketplaces", title: "Marketplaces" },
        // { key: 'deliver_stock', title: 'Deliverr Stock' },
        { key: "edit", title: "Edit" },
        { key: "delete", title: "Delete" },
      ],
      name: "products",
      items: [],
      tempItems: [],
      skuList: [],
      userCompany: "",
      pageSize: 25,
      role: "",
      normal: false,
      planName: '',
      isTourActive: false,
      isWalkthroughActive: false,
      tourStep: 1,
      stepTour: 1,
      key: "",
      Uid: "",
      modalIsOpen: false,
      walmartmodalIsOpen: false,
      selectSellType: null,
      selectShip: null,
      deletingBulkProducts: false,
      selectPaid: null,
      selectTime: null,
      warehouseName: '',
      billing: '',
      websiteLink: '',
      csvValidation: [],
      productList: [],
      savingBulkProducts: false,
      paypalEmail: null,
      bankAccount: null,
      bankRouting: null,
      bankName: null,
      deleteMultiPromp: false,
      bulkPublishLoading: false,
    };
    this.getData = this.getData.bind(this);
    this.productCount = 0;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleForce = this.handleForce.bind(this);
    this.bulkPublish = this.bulkPublish.bind(this);
    this.closewalmartModal = this.closewalmartModal.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.setMark = this.setMark.bind(this);
    this.setAllMark = this.setAllMark.bind(this);
    this.updateBulkProductAndValidate = this.updateBulkProductAndValidate.bind(this);
    this.zipData = this.zipData.bind(this);
    this.updateProductCsv = this.updateProductCsv.bind(this);
    this.deleteMuti = this.deleteMuti.bind(this);
    this.toggleMultiDelete = this.toggleMultiDelete.bind(this);
  }

  componentDidMount() {
    if (this.props.userdetails && Object.keys(this.props.userdetails).length > 0) {
      this.updateUser(this.props.userdetails);
      if (!this.props.userdetails.walkthroughDone) {
        let productSeen =
          typeof localStorage.productSeen != "undefined"
            ? localStorage.productSeen
            : false;
        //check the currentRoute
        //currentTab
        switch ("product") {
          case "product":
            if (!productSeen && !this.props.location.state) {
              //show walkthrough as per current page

              if (this.props.userdetails.ship_orders == undefined) {
                this.setState({ modalIsOpen: true });
              } else {
                this.setState({ isTourActive: true });
              }

              localStorage.productSeen = true;
            }
            break;
          default:
            break;
        }
      }
    }
    if (this.props.location.state && this.props.location.state.walkthrough) {
      this.setState({ isWalkthroughActive: true });
    }
    const currentUrl = new URL(window.location.href);
    if (Boolean(currentUrl.searchParams.get('q'))) {
      this.setState({ searchText: currentUrl.searchParams.get('q') })
    }
  }

  toggleMultiDelete() {
    const { deleteMultiPromp } = this.state;
    this.setState({ deleteMultiPromp: !deleteMultiPromp });
  }

  deleteMuti() {
    const { selectedProducts } = this.state;
    this.setState({
      deletingBulkProducts: true,
    });
    Promise.all(
      selectedProducts.map((product) => (
        api.postApi("sku/deleteSku", {
          sku: product._id,
          Uid: this.state.Uid,
        })
      )
    ))
    .then((response) => {
      // stop loading state and show toast on success
      toast.success(`Successfully deleted ${selectedProducts.length} product(s).`);
      this.setState({
        deletingBulkProducts: false,
        selectedProducts: []
      });
      this.getData();
      this.toggleMultiDelete();
    }).catch((err) => {
        toast.error("Something wen't wrong");
        this.setState({
          deletingBulkProducts: false,
        });
        this.toggleMultiDelete();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userdetails &&
      (nextProps.userdetails.walkthroughDone == undefined ||
        nextProps.userdetails.walkthroughDone == false)
    ) {
      console.log('touractive===', this.state.isWalkthroughActive);
      if (!this.state.isWalkthroughActive) {
        if (nextProps.userdetails.ship_orders == undefined) {
          this.setState({ modalIsOpen: true });
        } else {
          this.setState({ isTourActive: true });
        }

      } else {
        this.setState({ isTourActive: false });
      }
    } else {
      this.setState({ isTourActive: false });
    }

    if (
      nextProps.userdetails != undefined &&
      nextProps.userdetails != this.props.userdetails
    ) {
      this.updateUser(nextProps.userdetails);
    }
    this.setState({ isLoading: false });
  }

  updateUser(user) {
    let skuList = [];
    if (user.sku) {
      skuList = user.sku;
    }
    let role = "";
    if (user.role) {
      role = user.role.label;
    }
    console.log('user=====', user);
    let headers = this.state.headers;
    if (user.ship_orders && user.ship_orders.value === 'myself') {
      const index = headers.findIndex((header) => header.key ==="warehouse_stock");
      headers[index].title = 'Warehouse Stock';
    }
    let newHeaders = []
    newHeaders.push({ key: 'mark', title: "Select" });
    newHeaders = newHeaders.concat(headers);

    this.setState({
      userCompany: user.company,
      role: role,
      skuList: skuList,
      planName: user.plan_name ? user.plan_name : 'plan_99_m',
      billing: user.billing,
      key: user.key,
      Uid: user.uid,
      headers:[...newHeaders],
      firstname: user.firstname,
      email: user.email
    });
  }

  handleForce = (data, file) => {
    const csvValidation = [];
    for (let i = 0; i < data.length; i++) {
      csvValidation.push(
        this.validateCsv(data[i]),
      );
    }
    this.setState({
      csvValidation,
      csvData: data,
    });
  };

  // validate bulk products
  validateCsv(csvData) {
      const check = bulkProductModel.check({
        Sku: csvData.sku_number__required_,
        Name: csvData.name__required_,
        Weight: csvData.weight__required_,
        Upc: csvData.upc__required_,
        Brand: csvData.brand__required_,
        Price: csvData.price__required_,
        Description: csvData.description__required_,
        Image1: csvData.image_1__required_,
        Image2: csvData.image_2__optional_,
        Image3: csvData.image_3__optional_,
        Image4: csvData.image_4__optional_,
        Image5: csvData.image_5__optional_,
        Image6: csvData.image_6__optional_,
        Image7: csvData.image_7__optional_,
        Category: csvData.category__required_,
        QuantityOnHand: csvData.inventory_quantity__required_,
      })

      // check if all fields in a row are valid
      const isValid = !Object.keys(check).map((key) => check[key].hasError).includes(true);
      return {
        ...check,
        isValid,
      }
  }

  updateProductCsv() {
    let validatedProductData = this.zipData().flatMap((item) => {
      if (item.validation.isValid){
        const csvData = item.row;
        return {
          ParentSku: csvData.parent_sku_number__optional_,
          Sku: csvData.sku_number__required_,
          Name: csvData.name__required_,
          Type: "Product",
          TypeValue: null,
          QuantityOnHand: csvData.inventory_quantity__required_,
          Identifiers: {
            UPC: csvData.upc__required_,
            EAN: "",
            ISBN: "",
            GTIN: "",
          },
          IsAutoSyncEnabled: true,
          IsDiscontinued: false,
          IsDropshipped: false,
          IsFireArm: false,
          IsVariation: false,
          IsParent: false,
          ProductStatus: 'pending_reviewal',
          GlobalListingAttributes: {
            SoldAsName: csvData.name__required_,
            ShortDescription: '',
            LongDescription: csvData.description__required_,
            Price: csvData.price__required_,
            Condition: null,
            value: null,
          },
          // get all validated product images in a CSV row
          Images: ['image_1__required_', ...Array(6).keys()].flatMap((i) => {
            if (i !== 'image_1__required_') {
              return {
                Url: csvData[`image_${i + 2}__optional_`],
                id: i + 2,
              } || [];
            }
            return {
              Url: csvData['image_1__required_'],
              id: 1,
            };
          }),
          Warehouses: [],
          SupplierInformation: {
            SupplierId: "",
            SKU: "",
            Price: "",
          },
          BrandName: csvData.brand__required_,
          Category: csvData.category__required_,
          BuyPrice: csvData.retail_price__optional_,
          Weight: csvData.weight__required_,
          WeightUnit: csvData.weight_unit__required_,
          Uid: this.state.Uid,
          ProductStatus: 'pending_reviewal',
          SellType: [{"value":"Valet Shop","label":"Valet Shops"}],
        };
      }
      return [];
    })

    this.setState({
      savingBulkProducts: true,
    });

    const variationGroups = {}
    const productArr = [];

    for (let i = 0; i < validatedProductData.length; i++) {
      if (Boolean(validatedProductData[i].ParentSku)) {

        if (variationGroups[validatedProductData[i].ParentSku]) {
          variationGroups[validatedProductData[i].ParentSku].push(validatedProductData[i]);
        }
        else {
          variationGroups[validatedProductData[i].ParentSku] = [validatedProductData[i]]
        }
        validatedProductData[i].skip = true;
      }
      else {
        productArr.push(validatedProductData[i]);
      }
    };
    const variations = Object.keys(variationGroups).map((key) => {
      let images = [];
      const addedImages = {};

      for (let i = 0; i < variationGroups[key].length; i++) {
        const variationImages = variationGroups[key][i].Images.filter((image) => {
          if (image.Url && !addedImages[image.Url]) {
            addedImages[image.Url] = null;
            return true;
          }
        });
        console.log('variationImages', variationImages)
        images = images.concat(variationImages);
      }
      return {
        Id: "",
        Sku: key,
        SellType: [{"value":"Valet Shop","label":"Valet Shops"}],
        ProductStatus: 'pending_reviewal',
        Uid: this.state.Uid,
        IsAutoSyncEnabled: true,
        IsDiscontinued: false,
        IsDropshipped: false,
        IsFireArm: false,
        IsParent: false,
        IsVariation: true,
        Category: variationGroups[key][0].Category,
        ProductStatus: 'pending_reviewal',
        BrandName: variationGroups[key][0].BrandName,
        Seo: "",
        Name: variationGroups[key][0].Name,
        Weight: variationGroups[key][0].Weight,
        Feature1: "",
        Feature2: "",
        Feature3: "",
        Feature4: "",
        Feature5: "",
        Warehouses: [],
        IsVariation: true,
        Images: images,
        GlobalListingAttributes: {
          Condition: null,
          value: null,
          GlobalValue: null,
          LongDescription: variationGroups[key][0].LongDescription,
          ShortDescription: '',
          Price: 0,
        },
        ProductVariations: variationGroups[key].map((variation) => ({
          Active: true,
          BuyPrice: variationGroups[key][0].BuyPrice,
          EAN: "",
          GTIN: "",
          ISBN: "",
          Image: variationGroups[key][0].Images[0] && variation.Images[0].Url,
          Price: variationGroups[key][0].GlobalListingAttributes.Price,
          Sku: variationGroups[key][0].Sku,
          UPC: variationGroups[key][0].Identifiers.UPC,
          Variation: variationGroups[key][0].Name,
        }))
      };
    });

    const allProducts = productArr.concat(variations);

    // check if a product is has variations and group by variation

    // create all draft products after CSV data has been validated
    // wait till all the bulk product promises been submited before resolving all promises
    Promise.all(
      allProducts.map((product) => {
        if (!product.ProductVariations) {
          return api.postApi("inventory/addProducts", product)
        }
        else {
          return api.postApi("inventory/sendCsvProducts", product)
        }
      }
    ))
    .then((response) => {
      // stop loading state and show toast on success
      toast.success(`Successfully saved ${response.length} product(s).`);
      this.setState({
        savingBulkProducts: false,
      });
      this.getData();
    }).catch((err) => {
        toast.error("Something wen't wrong");
        this.setState({
          savingBulkProducts: false,
        });
    });
  
      // TODO: Ecomdash from csv
      // api.postEcomDashApi("Inventory", this.productList[this.productCount]).then(
      //   (data) => {
      //     console.log("Inventory====", data);
      //
      //     if (data.status == "Success") {
      //       let product = data.data.Product;
      //       product.Uid = this.state.Uid;
      //       product.ProductStatus = 'pending_reviewal';
      //       api.postApi("inventory/addProducts", product).then((data) => {
      //         this.productCount = this.productCount + 1;
      //         console.log("Inventory1====", data);
      //         this.updateProductCsv();
      //       });
      //     } else {
      //       if (data.data && data.data.Messages.length > 0) {
      //         helper.errorMessage((this.productCount + 2) + ' row : ' + data.data.Messages[0]);
      //       }
      //       this.setState({ isLoading: false });
      //     }
      //   },
      //   (err) => {
      //     console.log("err====", err);
      //     this.setState({ isLoading: false });
      //     helper.errorMessage(err);
      //   }
      // );

  }

  handleDarkSideForce = (error) => {
    console.log("error==", error);
  };

  getData(page, pageSize) {
    this.setState({ tableLoading: true, pageSize: pageSize, items: [] });
    this.productCount = 0;
    let apiPath = "";

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.append('q', this.state.searchText);
    console.log('---', currentUrl.search);
    // this.props.history.push(currentUrl.search);
    // console.log(' ---- |||', currentUrl.search)

    apiPath = "inventory/getProducts";
    let reqData = {
      Pagination: {
        ResultsPerPage: pageSize,
        PageNumber: page,
      },
    };
    if (this.state.searchText.length > 0) {
      reqData.sku = this.state.searchText;
    }
    if (this.state.role != "admin") {
      reqData.Uid = this.state.Uid;
    }
    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == "Success") {
          let items = data.data;
          console.log('items===', items);
          for (let item in items) {
            items[item].warehouse_stock = 0;
            items[item].amazon_fba_stock = 0;
            items[item].brand_warehouse_stock = 0;
            items[item].deliver_stock = 0;
            items[item].Id = items[item]._id;
            items[item].key = items[item].Sku;

            items[item].product_status = ''
            if (items[item].ProductStatus && items[item].ProductStatus != '') {
              let productStatus = this.state.statusType.find(data1 => {
                return data1.value === items[item].ProductStatus;
              });
              items[item].product_status = productStatus ? productStatus.label : ''
              if (productStatus && productStatus.value == 'pending_reviewal') {
                items[item].product_status = 'Published';
              }
            } else {
              items[item].product_status = 'Published';
            }

            items[item].buy_price = 0;
            if (items[item].BuyPrice) {
              items[item].buy_price = items[item].BuyPrice;
            }
            if (items[item].Warehouses && items[item].Warehouses.length === 0) {
              items[item].warehouse_stock = items[item].QuantityOnHand;
            }
            for (let item1 in items[item].Warehouses) {
              if (items[item].Warehouses[item1].WarehouseId === items[item].defaultWarehouse) {
                items[item].warehouse_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
              }
              else if (items[item].Warehouses[item1].WarehouseId == '2019003243') {// warehouse stock
                items[item].warehouse_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
              } else if (items[item].Warehouses[item1].WarehouseId == '2019017136') {//Amazon - FBA
                items[item].amazon_fba_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
              } else if (items[item].Warehouses[item1].WarehouseId == '2019008149') {//Deliverr
                items[item].deliver_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
              }
              // else if (user.ship_orders && user.ship_orders.value == 'myself'&&items[item].Warehouses[item1].Name == user.warehouse_name) { // own warehouses
              //   ////console.log('user.warehouse_id====',user.warehouse_name,items[item].Warehouses[item1].QuantityOnHand);
              //   isMyWarehouses = true
              //   items[item].brand_warehouse_stock = Number(items[item].Warehouses[item1].QuantityOnHand);
              // }
            }

            if (items[item] && items[item].Type === "Kit") {
              items[item].warehouse_stock = items[item].QuantityOnHand;
            }

            if (items[item].product_status == 'Draft') {
              items[item].warehouse_stock = Number(items[item].QuantityOnHand);
            }
            if (items[item].Images && items[item].Images.length > 0) {
              let Images = items[item].Images;
              Images = Images.sort((a, b) => (Number(a.DisplayOrder) > Number(b.DisplayOrder)) ? 1 : ((Number(b.DisplayOrder) > Number(a.DisplayOrder)) ? -1 : 0));
              items[item].image = Images[0].Url;
            } else {
              items[item].image = "";
            }
            if (items[item].GlobalListingAttributes && items[item].GlobalListingAttributes.Price) {
              items[item].price = items[item].GlobalListingAttributes.Price;
            } else {
              items[item].price = 0;
            }
            items[item].marked = false;
          }
          this.setState({
            tableLoading: false,
            items: items,
            totalPage: data.pagination.TotalNumberOfPages,
            normal: false,
          });
        } else {
          this.setState({ tableLoading: false });
        }
      },
      (err) => {
        console.log("err====", err);
        this.setState({ tableLoading: false });
        helper.errorMessage(err);
      }
    );
  }

  setMark(id) {
    let index = helper.getItemIndex(this.state.items, 'Id', id);
    if (index !== -1) {
      let items = this.state.items;
      items[index].marked = !items[index].marked
      this.setState({
        items: [...items],
        selectedProducts: items.filter((item)=> item.marked),
      });
    }
  }

  setAllMark(page, pgSize, ismark) {

		let items = this.state.items;
		for (let data in items) {
      items[data].marked = ismark
    }
		this.setState({
      items: [...items],
      selectedProducts: items.filter((item)=> item.marked),
    });
	}

  async updateSellOrder() {
    const {
      selectSellType,
      selectShip,
      selectPaid,
      selectTime,
      firstname,
      userCompany,
      email,
      warehouseName,
      planName,
      websiteLink,
      paypalEmail,
      bankAccount,
      bankRouting,
      bankName,
    } = this.state;
    let selectPlan = helper.getPlanName(planName);
    let paymentDetails;
    if (selectPaid && selectPaid.value === 'ach') {
      paymentDetails = {
        bankAccount,
        bankRouting,
        bankName,
      }
    }
    if (selectPaid && selectPaid.value === 'paypal') {
      paymentDetails = { paypalEmail };
    }

    if (selectShip == null) {
      helper.errorMessage('please select ship orders');
    } else if (selectPaid == null) {
      helper.errorMessage('please select paid type');
    } else if ((selectShip && selectShip.value !== 'valetseller') && !Boolean(selectTime && selectTime.value)) {
      helper.errorMessage('please select Handle time');
    } else if ((selectPaid && selectPaid.value === 'ach') && !bankAccount) {
      helper.errorMessage('please enter a bank account');
    } else if ((selectPaid && selectPaid.value === 'ach') && !bankRouting) {
      helper.errorMessage('please enter a bank routing');
    } else if ((selectPaid && selectPaid.value === 'ach') && !bankName) {
      helper.errorMessage('please enter a bank name');
    } else if ((selectPaid && selectPaid.value === 'paypal') && !paypalEmail) {
      helper.errorMessage('please enter a paypal email');
    } else {

      let data = {
        ship_orders: selectShip,
        paid_type: selectPaid,
        handle_time: selectTime,
        website_link: websiteLink,
        warehouse_name: '',
        paymentDetails,
      }
      if ((selectPaid && selectPaid.value === 'ach')) {
        data.bankAccount = bankAccount;
        data.bankRouting = bankRouting;
        data.bankName = bankName;
      }
      this.updateBoardAction(data);
    }
  }

  bulkPublish() {
    this.setState({ bulkPublishLoading: true });
    api.postApi('bulkPublish', {
      productIds: [...this.state.selectedProducts].map((product) => product._id),
    })
      .then((response) => {
        this.setState({ bulkPublishLoading: false, selectedProducts: [] });
        this.getData();
        helper.successMessage('Products published Successfully');
      });
  }

  deleteAction(sku) {
    console.log('id===', sku);
    let apiPath = "";
    apiPath = "sku/deleteSku";
    let reqData = {
      sku: sku,
      Uid: this.state.Uid,
    };
    console.log('reqData===', reqData);
    this.setState({ isLoading: true })
    api.postApi(apiPath, reqData).then(
      (data) => {
        console.log(data);
        if (data.status == "Success") {

        }
        helper.successMessage('Product deleted successfully');
        this.setState({ isLoading: false })
      })


  }

  async updateBoardAction(data) {

    await this.props.updateRecord('user', data, this.state.key, false)
    let apiPath = "";
    apiPath = "mail/onBoardDetails";
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.userCompany,
      email: this.state.email,
      ...data
    };
    console.log('reqData===', reqData);
    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == "Success") {

        }
        this.setState({ isLoading: false })
      })
    this.setState({ isTourActive: true, modalIsOpen: false });
  }

  createSubscriber(data) {
    let apiPath = "";
    apiPath = "payment/createsubscriber";
    let reqData = {
      firstname: this.state.firstname,
      email: this.state.email,
      plan_id: data.plan_id
    };
    console.log('reqData===', reqData);
    api.postApi(apiPath, reqData).then(
      (data) => {
        console.log(data);
        if (data.status == "Success") {

        }
      })
  }

  actionSearch() {
    if (this.state.searchText.length > 0) {
      this.getData(1, this.state.pageSize);
    }
  }

  searchKey(text) {
    this.setState(
      {
        searchText: text,
      },
      () => {
        if (text.length == 0) {
          this.getData(1, this.state.pageSize);
        }
      }
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onNextWalkthrough = (step) => {
    if (step == 2) {
      this.setState({ isTourActive: false });
      this.props.history.push("newproducts", { walkthrough: true });
    } else {
      this.setState({ isTourActive: false });
    }
  };
  onwalkthroughNext = (step) => {
    if (step == 2) {
      this.setState({ isTourActive: false });
      this.props.history.push("dashboard", { walkthrough: true });
    } else {
      this.setState({ isTourActive: false });
    }
  };

  onCancelWalkthrough = async () => {
    console.log("cancel walkthrough");
    await this.props.addWalkthroughFlag();
    this.setState({ isTourActive: false, isWalkthroughActive: false });
    localStorage.removeItem("addProductSeen");
    localStorage.removeItem("productSeen");
    localStorage.removeItem("inventorySeen");
    localStorage.removeItem("salesSeen");
    localStorage.removeItem("dashboardSeen");
    localStorage.removeItem("topRightMenuSeen");
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  // handleCartChange = (selectSellType) => {
  //   this.setState({ selectSellType });
  // };
  handleCartChange = (selectSellType) => {
    if (selectSellType[selectSellType.length - 1] && selectSellType[selectSellType.length - 1].value == 'Walmart') {
      helper.warningMessage("Walmart's default handling time is 1 business day only");
    }
    this.setState({ selectSellType });
  };
  handleShipChange = (selectShip) => {
    this.setState({ selectShip });
  };
  handlePaidChange = (selectPaid) => {
    this.setState({ selectPaid });
  };
  handleTimeChange = (selectTime) => {
    this.setState({ selectTime });
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.actionSearch();
    }
  }
  closewalmartModal() {
    this.setState({ walmartmodalIsOpen: false });
  }

  updateValetShops() {
    let items = this.state.items;
    let syncId = [];
    for (let item in items) {
      if (items[item].marked) {
        syncId.push(items[item].Sku);
      }
    }
    if (syncId.length == 0) {
      helper.errorMessage('please select one product');
    } else {
      let apiPath = "";
      apiPath = "inventory/syncValetShops";
      let reqData = {
        productsId: syncId,
      };
      console.log('reqData===', reqData);
      this.setState({ isLoading: true })
      api.postApi(apiPath, reqData).then(
        (data) => {
          console.log(data);
          if (data.status == "Success") {

          }
          helper.successMessage('Products updated');
          this.setState({ isLoading: false })
        })
    }
  }

  // run validations each time changes occur in the editatable react table cells
  updateBulkProductAndValidate(field, value, index) {
    const csvDataCopy = [...this.state.csvData];
    const csvValidationCopy = [...this.state.csvValidation];
    csvDataCopy[index][field] = value;
    // validate single row to avoid perfomance issues that could arise
    const validate = this.validateCsv(csvDataCopy[index]);

    // set the state of the validations to update the error states of various cells
    // an updated copy of the previos validation is set
    csvValidationCopy[index] = validate;
    this.setState({
      csvValidation: csvValidationCopy,
    })
  }

  // zip rows toghther with theircorrecponding validation states
  zipData() {
    return this.state.csvValidation.map((validation, index) => ({
      validation,
      row: this.state.csvData[index],
      updateFunction: this.updateBulkProductAndValidate,
    }));
  }

  render() {
    const { t } = this.props.setOptions;
    const { selectSellType, selectShip, selectPaid, selectTime, warehouseName, websiteLink,role, deletingBulkProducts, selectedProducts } = this.state;
    const zip = this.zipData();
  
    const newCartList = cartList.flatMap((cart) => {
      if (((selectSellType && selectSellType.length) || 0) < (this.props.userdetails && this.props.userdetails.marketPlaceCount)) {
        return {
          ...cart,
        }
      }
      return [];
    })

    return (
      <>
        <Modal
          isOpen={zip.length > 0}
          className="modal-xl bulk-add-products"
        >
          <ModalHeader>
              Products
          </ModalHeader>
          <ModalBody>
          <ReactTable
            className='-striped -highlight'
            data={zip}
            columns={bulkProductTableColumns}
            className='-striped -highlight'
            resizable={true}
            minRows = {0}
					/>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.updateProductCsv}
              disabled={this.state.savingBulkProducts}
            >
              {
                this.state.savingBulkProducts ? "Saving..": "Save"
              }
            </Button>
            {' '}
            <Button
              color="secondary"
              onClick={() => {
                this.setState({
                  csvValidation: [],
                  csvData: [],
                });
              }}
            >
              cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="overlayIntro resizePop productpop">
          <Tour
            active={this.state.isTourActive}
            step={this.state.tourStep}
            onNext={(step) => this.onNextWalkthrough(step)}
            onBack={(step) => this.setState({ tourStep: step })}
            onCancel={() => this.onCancelWalkthrough()}
            hideSteps={true}
            steps={[
              {
                step: 1,
                selector: ".step1product",
                title: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "white",
                    }}
                  ></div>
                ),
                body: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    {ADD_PRODUCT_BUTTON}
                  </div>
                ),
              },
              {
                step: 2,
                selector: ".simpleListing",
                title: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "16px",
                      color: "white",
                    }}
                  ></div>
                ),
                body: (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "16px",
                      color: "black",
                    }}
                  >
                    Here is where you can find your product details or edit the
                    listing
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="alignCenter"></div>
        <Col className="container-div" md={12}>
          {this.state.isLoading == true && (
            <div
              className="load"
              style={{
                backgroundColor: "transparent",
                width: "85%",
                height: "50%",
              }}
            >
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path
                    fill="#6c6f7f"
                    d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          )}
          {this.state.isLoading == false && (
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">
                      Products
                      {role=='admin'&&<a className="add-product-csv product_bold-text btn addbtn add_products optimisation"
                        onClick={() => { this.updateValetShops(); }}
                        href="javscript:void(0)"
                      >
                        Sync to Valet Shops
                      </a>}

                      <a className="add-product-csv product_bold-text btn addbtn add_products optimisation"
                        download
                        href="https://firebasestorage.googleapis.com/v0/b/lustrous-oasis-258814.appspot.com/o/template%2FValet_Seller_Bulk_Upload_Templatev2_.csv?alt=media"
                      >
                        {/* <button className=""> */}
                          Download CSV Template
                        {/* </button> */}
                      </a>
                      <a className="product_bold-text btn addbtn add_products optimisation"
                        target="_blank"
                        href="https://www.valetseller.com/wp-content/uploads/2020/06/OptimizationsTemplate.pdf"
                      >
                        {/* <button className=""> */}
                          Optimization Guide
                        {/* </button> */}
                      </a>
                      <div className="add_width" style={{ float: "right" }}>
                      {
                          Boolean(selectedProducts.length) && (
                            <Link
                              to='#'
                              disabled={this.state.bulkPublishLoading}
                              onClick={() => this.bulkPublish()}
                              className="btn addbtn product_orange add_products step1product add-button"
                            >
                              {this.state.bulkPublishLoading? "publishing": "Bulk Publish"} ({selectedProducts.length})
                            </Link>
                          )
                        }
                        {
                          Boolean(selectedProducts.length) && (
                            <Link
                              to={`/pages/bulkEdit/${selectedProducts.map((product) => product._id).join()}/`}
                              className="btn addbtn product_orange add_products step1product add-button"
                            >
                              Bulk Edit ({selectedProducts.length})
                            </Link>
                          )
                        }
                        {
                          Boolean(selectedProducts.length) && (
                            <button
                              className="btn deleteBtn add_products step1product add-button"
                              onClick={this.toggleMultiDelete}
                            >
                              Delete ({selectedProducts.length})
                            </button>
                          )
                        }
                        <Link to="/pages/newproducts" className="btn addbtn product_orange add_products step1product add-button">
                            Add Products
                        </Link>
                        <button className="btn addbtn product_orange">
                          Import Products
                          <CSVReader
                            accept='.csv'
                            ref={ref => this.fileInput = ref}
                            cssClass="csv-reader-input"
                            onFileLoaded={this.handleForce}
                            onError={this.handleDarkSideForce}
                            parserOptions={papaparseOptions}
                            inputId="ObiWan"
                            inputStyle={{ color: "red" }}
                          />
                        </button>
                        {/* <button onClick={this.openModal} className="btn addbtn add_products">crop</button> */}
                      </div>
                    </h5>

                    <div className="search" style={{ marginLeft: 15 }}>
                      <Input
                        type="text"
                        name="search"
                        placeholder="Search by sku or product title"
                        value={this.state.searchText}
                        onKeyDown={this.handleKeyDown}
                        onChange={(event) => {
                          this.searchKey(event.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          this.actionSearch();
                        }}
                        style={{ padding: 0 }}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                  <div className="overlayIntro">
                    <Tour
                      active={this.state.isWalkthroughActive}
                      step={this.state.stepTour}
                      onNext={(step) => this.onwalkthroughNext(step)}
                      onBack={(step) => this.setState({ stepTour: step })}
                      onCancel={() => this.onCancelWalkthrough()}
                      hideSteps={true}
                      steps={[
                        {
                          step: 1,
                          selector: ".simplesel",
                          title: (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                                fontSize: "16px",
                                color: "white",
                              }}
                            ></div>
                          ),
                          body: (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "20px",
                                fontSize: "16px",
                                color: "black",
                              }}
                            >
                              {PRODUCT_LISTING}
                            </div>
                          ),
                        },
                        {
                          step: 2,
                          selector: ".rt-table",
                          title: (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "10px",
                                fontSize: "16px",
                                color: "white",
                              }}
                            ></div>
                          ),
                          body: (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "20px",
                                fontSize: "16px",
                                color: "black",
                              }}
                            >
                              Here is where you can find your product details or
                              edit the listing
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                  {this.state.Uid !== undefined && this.state.Uid !== "" && (
                    <TableList
                      productSearchQuery={this.state.searchText}
                      normal={this.state.normal}
                      tableLoading={this.state.tableLoading}
                      company={this.state.userCompany}
                      filename={'products'}
                      totalPage={this.state.totalPage}
                      deleteAction={this.deleteAction}
                      setMark={this.setMark}
                      setAllMark={this.setAllMark}
                      getData={this.getData}
                      items={this.state.items}
                      headers={this.state.headers}
                      name={this.state.name}
                      className="myprodList"
                      isProduct={true}
                      Uid={this.state.Uid}
                      email={this.state.email}
                      role={this.state.role}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          )}

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={console.log('close')}
            style={customStyles}
            contentLabel="Example Modal"
            className="get_started_modal"
          >
            <h2
              style={{ textAlign: "center", fontSize: 22, fontWeight: "bold" }}
            >
              Getting Started
            </h2>

            <div className="agreement onboard onboardlightbox">
              <div className="form-group col-md-12">
                <Label>Where Would You Like to Sell? (Multi Select)</Label>
                <Select
                  // components={{ NoOptionsMessage }}
                  // styles={{ noOptionsMessage: base => ({ ...base, ...msgStyles }) }}
                  value={selectSellType}
                  onChange={this.handleCartChange}
                  options={newCartList}
                  isMulti
                />
              </div>
              <div className="form-group col-md-12">
                <Label>How Will You Ship Orders?</Label>
                <Select
                  value={selectShip}
                  onChange={this.handleShipChange}
                  options={shipList}
                />
              </div>
              {/* {selectShip && selectShip.value == 'myself' && <div className="form-group col-md-12 warehousename">
                <Label>Warehouse Name</Label>
                <input name="shortdescription" type="text" placeholder="" value={warehouseName}
                  onChange={(event) => { this.setState({ warehouseName: event.target.value }) }} />
              </div>} */}
              <div className="form-group col-md-12">
                <Label>How Will You Get Paid?</Label>
                <Select
                  value={selectPaid}
                  onChange={this.handlePaidChange}
                  options={paidList}
                />
              </div>
              {
                (selectPaid && selectPaid.value === 'paypal') && (
                  <div className="form-group col-md-12 warehousename">
                    <Label>Paypal Email</Label>
                    <input name="paypalEmail" type="text" onChange={this.handleChange} placeholder="Paypal Email" />
                  </div>
                )
              }
              {
                (selectPaid && selectPaid.value === 'ach') && (
                  <div>
                    <div className="form-group col-md-12 warehousename">
                      <Label>Bank Account</Label>
                      <input name="bankAccount" type="text" onChange={this.handleChange} placeholder="Bank Account" />
                    </div>
                    <div className="form-group col-md-12 warehousename">
                      <Label>Bank Routing</Label>
                      <input name="bankRouting" type="text" onChange={this.handleChange} placeholder="Bank Routing" />
                    </div>
                    <div className="form-group col-md-12 warehousename">
                      <Label>Bank Name</Label>
                      <input name="bankName" type="text" onChange={this.handleChange} placeholder="Bank Name" />
                    </div>
                  </div>
                )
              }
              {
                (!selectShip || (selectShip && selectShip.value !== 'valetseller')) && (
                  <div className="form-group col-md-12 handletime">
                    <Label>Handling Time</Label>
                    <Select
                      value={selectTime}
                      onChange={this.handleTimeChange}
                      options={timeList}
                    />
                  </div>
                )
              }
              <div className="form-group col-md-12 warehousename">
                <Label>Company Website Link</Label>
                <input name="shortdescription" type="text" placeholder="Company Website Link" value={websiteLink}
                  onChange={(event) => { this.setState({ websiteLink: event.target.value }) }} />
              </div>
              <div
                className="form-group col-md-12" >
                <a className="btn addbtn add_products next" onClick={() => { this.updateSellOrder(); }}>Next</a>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={this.state.walmartmodalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closewalmartModal}
            style={deleteCustomStyles}
            contentLabel="Example Modal"
          >

            <h2 ref={subtitle => this.subtitle = subtitle}> Business day<a className="closemodal" onClick={this.closewalmartModal}>X</a></h2>

            <div className="agreement">
              <p style={{ marginTop: 10, fontSize: 16 }}>Walmart's default handling time is 1 business day only</p>
            </div>
          </Modal>
        </Col>
					<Modal
						isOpen={this.state.deleteMultiPromp}
						toggle={this.toggleMultiDelete}
						style={{ marginTop: 150 }}
					>
						<ModalHeader toggle={this.toggleSmall}></ModalHeader>
						<ModalBody>Are you sure you want to delete {this.state.selectedProducts.length} product(s)?</ModalBody>
						<ModalFooter>
							<Button
								color='primary'
								onClick={() => {
									this.deleteMuti();
								}}
                disabled={deletingBulkProducts}
							>
                { deletingBulkProducts ? "Deleting..." : "Delete"}
							</Button>{' '}
							<Button color='secondary' onClick={this.toggleMultiDelete}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
      </>
    );
  }
}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(ProductsCard);
