import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';

import { Card, CardBody, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import api from '../../../utils/api';
import { CSVLink, CSVDownload } from "react-csv";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';
import DatePicker from "react-date-picker";
import moment from "moment";

class ReportsCard extends PureComponent {

  constructor(props) {
    super(props)
    let now = new Date();
    let end = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let start = moment(end).subtract(30, "days");

    this.state = {
      isLoading: false,
      searchText: '',
      fromDate: '',
      toDate: '',
      selectFromDate: '',
      selectToDate: '',
      name: 'reports',
      items: [],
      loadingText: 0,
      tempItems: [],
      csvData: [],
      csvHeaders: [],
      skuList: [],
      userCompany: '',
      role: '',
      dayCount: 5,
      startDayCount: 0,
      start: start,
      end: end,
      salesHeader: [
        { key: 'OrderNumber', title: 'Order No' },
        { key: 'product_id', title: 'Product Id' },
        { key: 'product_title', title: 'Product Title' },
        { key: 'PaymentReceivedDate', title: 'PaymentReceivedDate' },
        { key: 'TotalAmount', title: 'Sales' },
        { key: 'Status', title: 'Status' },
        { key: 'StorefrontType', title: 'StorefrontType' },
        { key: 'DeliveryMethod', title: 'DeliveryMethod' }
      ],
      salesSkuHeader: [
        { key: 'OrderNumber', title: 'Order No' },
        { key: 'product_id', title: 'Product Id' },
        { key: 'product_title', title: 'Product Title' },
        { key: 'PurchaseDate', title: 'PurchaseDate' },
        { key: 'sku', title: 'SKU' },
        { key: 'TotalAmount', title: 'Sales' },
        { key: 'Status', title: 'Status' },
        { key: 'StorefrontType', title: 'StorefrontType' },
        { key: 'DeliveryMethod', title: 'DeliveryMethod' }
      ],
      customerHeader: [
        { key: 'OrderNumber', title: 'Order No' },
        { key: 'product_id', title: 'Product Id' },
        { key: 'product_title', title: 'Product Title' },
        { key: 'customer_name', title: 'Customer Name' },
        { key: 'customer_address', title: 'Customer Address' },
        { key: 'email', title: 'Email' },
        { key: 'PurchaseDate', title: 'PurchaseDate' },
        { key: 'sku', title: 'SKU' },
        { key: 'TotalAmount', title: 'Sales' },
        { key: 'Status', title: 'Status' }
      ],
      lowStackHeader: [
        { key: 'Sku', title: 'SKU' },
        { key: 'Id', title: 'ProductId' },
        { key: 'QuantityOnHand', title: 'Deliver Stock' }
      ],
      productHeader: [
        { key: 'sku', title: 'SKU' },
        { key: 'product_title', title: 'Product Title' },
        { key: 'sku', title: 'SKU' },
        { key: 'price', title: 'Price' },
        { key: 'TotalAmount', title: 'Sales' },
        { key: 'volume', title: 'Quantity Ordered' }
      ],
      csvFileName: '',
      startDayCount: 0,
      dayCount: 30,
      Uid: '',
    };
    this.exportBtn = React.createRef();
    this.applyCallback = this.applyCallback.bind(this);
  }



  componentDidMount() {

    if (this.props.userdetails) {
      this.updateUser(this.props.userdetails);
    }
    this.getTodayDate();
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.userdetails != undefined && nextProps.userdetails != this.props.userdetails) {
      this.updateUser(nextProps.userdetails);
    }
    this.setState({ isLoading: false })
  }
  updateUser(user) {

    let skuList = [];
    if (user.sku) {
      skuList = user.sku;
    }
    this.setState({
      userCompany: user.company,
      role: user.role.label,
      skuList: skuList,
      Uid: user.uid
    }, () => {
      
    })
  }
  getTodayDate() {

    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 32);
    let toDate = new Date();

    fromDate = helper.getDateFormat(fromDate);
    toDate = helper.getDateFormat(toDate);
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      selectFromDate: fromDate,
      selectToDate: toDate,
    }, () => {

    })

  }

  getFiveDateReport() {

    let startDayCount = this.state.startDayCount;
    let dayCount = this.state.dayCount;

    let fromDate = moment(this.state.selectFromDate).add((startDayCount * 10), "days")
    if (startDayCount > 0) {
      fromDate = moment(fromDate).add(1, 'days')
    }

    let toDate = moment(this.state.selectFromDate).add(((10) + (startDayCount * 10)), "days")
    let currentDate = moment(this.state.selectToDate);

    if (currentDate.diff(toDate, 'days') < 0) {
      toDate = currentDate;
    }

    fromDate = helper.getDateFormat(fromDate);
    toDate = helper.getDateFormat(toDate);

    console.log('fromDate===', fromDate, 'toDate', toDate);
    this.setState({ fromDate: fromDate, toDate: toDate }, () => {
      let name = this.state.csvFileName;
      if (name == 'sales_by_time_period') {
        this.salesTimeReport(1)
      } else if (name == 'sales_by_sku') {
        this.salesTimeReport(1)
      } else if (name == 'customer_order_report') {
        this.salesTimeReport(1)
      } else if (name == 'low_stock_report') {
        this.getProducts(1)
      } else if (name == 'product_report') {
        this.salesTimeReport(1)
      }
    })

  }


  selectReport(name) {

    let dayDiffernce = helper.diffDays(this.state.fromDate, this.state.toDate);
    this.setState({ csvFileName: name, items: [], loadingText: 0, dayCount: (dayDiffernce), startDayCount: 0, selectFromDate: this.state.fromDate, selectToDate: this.state.toDate }, () => {
      console.log('name===', name);
      this.getFiveDateReport()

    })
  }

  salesTimeReport(page) {
    this.setState({ isLoading: true })
    let apiPath = 'orders/getOrders'
    let reqData = {
      "FromDate": this.state.fromDate + " 00:00:00",
      "ToDate": this.state.toDate + " 23:59:00",
      "DateType": 1,
      "OnlyNeedsFulfillment": true,
      "Pagination": {
        "ResultsPerPage": 200,
        "PageNumber": page
      },
      "IncludeCustomerInfo": true,
      "IncludeLineItems": true,
    }
    if (this.state.role != 'admin') {
      reqData.Uid = this.state.Uid;
    }
    if (this.state.csvFileName != 'customer_order_report') {
      reqData.status = 1; //complete order
    }
    //console.log(reqData);
    api.postApi(apiPath, reqData).then((data) => {
      //  console.log('data====',data);
      if (data.status == 'Success') {
        let items = data.data;
        for (let item in items) {
          if (items[item].CustomerInfo) {
            items[item].customer_name = items[item].CustomerInfo.FullName;
            items[item].customer_address = items[item].CustomerInfo.Address.City + ',' + items[item].CustomerInfo.Address.Country;
            items[item].email = items[item].CustomerInfo.EmailAddress
          }
          if (items[item].LineItems && items[item].LineItems.length > 0) {
            items[item].sku = items[item].LineItems[0].SkuNumber;
            items[item].Sku = items[item].LineItems[0].SkuNumber;
            items[item].product_id = items[item].LineItems[0].ProductId;
            items[item].product_title = items[item].LineItems[0].ProductName;
            items[item].volume = items[item].LineItems[0].QuantitySold;

          }
          // items[item].TotalAmount =  items[item].TotalAmount.toFixed(2);
        }
        // console.log(data.pagination.CurrentPage,data.pagination.TotalNumberOfPages);

        let startDayCount = this.state.startDayCount
        let precentageCount = Math.round(this.state.dayCount / 11);
        let loadingPercentage = Math.round((((100 / data.pagination.TotalNumberOfPages) * data.pagination.CurrentPage)) / (precentageCount));

        if (precentageCount > 0) {
          precentageCount = 100 / precentageCount;

        } else {
          precentageCount = 0;
        }

        loadingPercentage = Math.round((precentageCount * startDayCount) + loadingPercentage);

        if (loadingPercentage > 100) {
          loadingPercentage = 100;
        }

        this.setState({ loadingText: loadingPercentage })


        if (data.pagination.CurrentPage < data.pagination.TotalNumberOfPages) {
          let newitems = this.state.items;
          newitems = newitems.concat(items);
          this.setState({ items: newitems, totalPage: 1 });
          this.salesTimeReport((data.pagination.CurrentPage + 1));
        } else {
          let newitems = this.state.items;
          newitems = newitems.concat(items);
          let startDayCount = this.state.startDayCount
          startDayCount = startDayCount + 1;
          this.setState({ startDayCount: startDayCount, items: newitems, isLoading: false }, () => {
            // console.log('dayCount===',startDayCount);
            if ((startDayCount * 10) < (this.state.dayCount - 1)) {
              this.getFiveDateReport();
            } else {
              this.generateCsv();
            }

          })
        }
      } else {
        this.setState({ isLoading: false })
      }
    }, err => {
      console.log('err====', err);
      this.setState({ isLoading: false });
      helper.errorMessage(err);
    })
  }

  getProducts(page) {
    this.setState({ isLoading: true, loadingText: 50 })
    let apiPath = 'inventory/lowStockProducts';
    let reqData = {
      "Pagination": {
        "ResultsPerPage": 200,
        "PageNumber": 1
      }
    }

    if (this.state.role != 'admin') {
      reqData.Uid = this.state.Uid;
    }
    console.log('reqData==', reqData);
    api.postApi(apiPath, reqData).then((data) => {
      console.log('data===', data);
      if (data.status == 'Success') {
        let items = data.data;
        for (let item in items) {
          items[item].warehouse_stock = 0;
          items[item].amazon_fba_stock = 0;
          items[item].brand_warehouse_stock = 0;
          items[item].deliver_stock = 0;
          for (let item1 in items[item].Warehouses) {
            items[item].warehouse_stock = Number(items[item].warehouse_stock) + Number(items[item].Warehouses[item1].QuantityOnHand);
          }
          for (let item1 in items[item].Storefronts) {
            items[item].deliver_stock = Number(items[item].deliver_stock) + Number(items[item].Storefronts[item1].AvailableQuantity);
          }
          if (items[item].Identifiers) {
            items[item].Id = items[item].Identifiers.ProductId
          }
          items[item].sku = items[item].Sku;
        }

        let loadingPercentage = 100;

        let newitems = this.state.items;
        newitems = newitems.concat(items);

        this.setState({ items: newitems, isLoading: false, loadingText: loadingPercentage }, () => {
          this.generateCsv();
        })
      } else {
        this.setState({ isLoading: false })
      }

    }, err => {
      console.log('err====', err);
      this.setState({ isLoading: false });
      helper.errorMessage(err);
    })

  }

  generateCsv() {
    let { items, csvFileName } = this.state;
    let header = [];
    let csvDataKey = [];
    let csvData = [];
    let csvHeaders = [];
    csvDataKey[0] = [];

    if (csvFileName == 'sales_by_time_period') {
      header = this.state.salesHeader
    } else if (csvFileName == 'sales_by_sku') {
      header = this.state.salesSkuHeader
    } else if (csvFileName == 'customer_order_report') {
      header = this.state.customerHeader;
    } else if (csvFileName == 'low_stock_report') {
      header = this.state.lowStackHeader;
    } else if (csvFileName == 'product_report') {
      header = this.state.productHeader;
    }

    header.map((data) => {
      if (data.key != 'delete' && data.key != 'edit' && data.key != 'preview') {
        csvHeaders.push({ label: data.title, key: data.key });
      }
      csvDataKey[0].push(data.key);
    })

    // if( this.state.role!='admin'){
    //   let userCompany = this.state.userCompany;
    //   let filterSku =[];
    //   let that =this;
    //   for(let data in items){
    //     let index = that.state.skuList.indexOf(items[data].sku);
    //     if(index!=-1){
    //       filterSku.push(items[data]);
    //     }
    //   }
    //   items = filterSku;
    // }

    if (csvFileName == 'product_report') {
      items = this.getTopProducts(items);
    }
    if (csvFileName == 'low_stock_report') {
      // items = this.filterLowStock(items);
      console.log('items=low stock==', items.length);
    }

    for (let data in items) {
      var index = Number(data) + 1;
      let dataobject = []
      for (let col in csvDataKey[0]) {
        if (csvDataKey[0][col] != 'delete' && csvDataKey[0][col] != 'edit' && csvDataKey[0][col] != 'preview') {
          dataobject = {
            ...dataobject,
            [csvDataKey[0][col]]: items[data][csvDataKey[0][col]]
          }
        }

      }
      csvData.push(dataobject)
    }
    console.log('csvdata==', csvData, csvHeaders);
    this.setState({ csvData: csvData, csvHeaders: csvHeaders }, () => {
      this.downloadCsv();
    })

  }

  filterLowStock(items) {
    let newItems = [];
    for (let data in items) {
      if (items[data].deliver_stock < 10) {
        newItems.push(items[data]);
      }
    }
    return newItems;
  }
  getTopProducts(items) {

    let topProducts = [];
    let arrayProducts = [];
    for (let item in items) {
      let index = arrayProducts.indexOf(items[item].product_id);
      if (index == -1) {
        arrayProducts.push(items[item].product_id);
        items[item].price = items[item].TotalAmount;
        topProducts.push(Object.assign({}, items[item]));
      } else {
        topProducts[index].volume = Number(topProducts[index].volume) + Number(items[item].volume);
        topProducts[index].TotalAmount = Number(topProducts[index].TotalAmount) + Number(items[item].TotalAmount);
      }
    }
    topProducts = topProducts.sort(this.compare);
    return topProducts;
  }

  compare(a, b) {
    if (Number(a.TotalAmount) < Number(b.TotalAmount)) {
      return 1;
    }
    if (Number(a.TotalAmount) > Number(b.TotalAmount)) {
      return -1;
    }
    return 0;
  }



  async downloadCsv() {
    console.log('downloadLink==');

    let downloadLink = this.exportBtn;
    console.log('downloadLink==', downloadLink);
    if (
      downloadLink
      && downloadLink.current
      && downloadLink.current.link
      && downloadLink.current.link.click
      && typeof downloadLink.current.link.click == 'function'
    ) {
      downloadLink.current.link.click();
    }
  }

  applyCallback(startDate, endDate) {



    let dayDiffernce = helper.diffDays(startDate, endDate);
    this.setState({
      start: startDate,
      end: endDate,
      selectFromDate: helper.getDateFormat(startDate),
      selectToDate: helper.getDateFormat(endDate),
      toDate: helper.getDateFormat(endDate),
      fromDate: helper.getDateFormat(startDate)
    }, () => {

    })

  }


  render() {
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let displayFromDate = helper.getDateFormat(this.state.selectFromDate)
    let displayToDate = helper.getDateFormat(this.state.selectToDate);

    let ranges = {
      "Today": [moment(start), moment(end)],
      "Yesterday": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
      "Last 7 days": [moment(start).subtract(7, "days"), moment(end)],
      "Last 14 days": [moment(start).subtract(14, "days"), moment(end)],
      "This Month": [moment(start).subtract((now.getDate() - 1), "days"), moment(end)],
      "Last 30 days": [moment(start).subtract(30, "days"), moment(end)],
      "Last Month": [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
    }

    let local = {
      "format": "MM/DD/YYYY",
      "sundayFirst": false
    }
    let maxDate = moment(start).add(24, "hour")
    const { csvData, csvHeaders, csvFileName } = this.state;
    let fileName = csvFileName + '.csv';
    return (
      <Col className="container-div" md={12}>
        {this.state.isLoading == true && <div className="load" style={{ backgroundColor: 'transparent', width: '85%', height: '50%' }}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
            {this.state.loadingText} %
        </div>
        </div>}

        {this.state.isLoading == false && <Col md={12}>
          <Card>
            <CardBody>

              <div className="card__title">
                <Col md={12} className="date-picker">
                  <label className="select_date">Select Date</label>
                  <DateTimeRangeContainer
                    ranges={ranges}
                    start={this.state.start}
                    end={this.state.end}
                    local={local}
                    maxDate={maxDate}
                    applyCallback={this.applyCallback}>

                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Date"
                      value={displayFromDate + ' - ' + displayToDate}
                      placeholder="Enter Date"
                    />
                  </DateTimeRangeContainer>
                </Col>
                <h5 className="reports_head">Sales Reports</h5>


                <div className="reports_left">
                  <div className="reports_left_inner">
                    <img className="" src="../img/graph.png" ></img>
                  </div>
                  <div className="reports_right_inner" onClick={() => { this.selectReport('sales_by_time_period') }}>
                    <span>Sales by Time Period</span>
                    <p>View your sales for each channel by time period.</p>
                  </div>
                </div>



                <div className="reports_right">
                  <div className="reports_left_inner">
                    <img className="" src="../img/graph.png" ></img>
                  </div>
                  <div className="reports_right_inner" onClick={() => { this.selectReport('sales_by_sku') }}>
                    <span>Sales by SKU</span>
                    <p>View your sales by SKU on each channel during a specified time period.</p>
                  </div>
                </div>

              </div>


              <div className="card__title">
                <h5 className="reports_head">Inventory Reports</h5>

                <div className="reports_left">
                  <div className="reports_left_inner">
                    <img className="" src="../img/graph.png" ></img>
                  </div>
                  <div className="reports_right_inner" onClick={() => { this.selectReport('low_stock_report') }}>
                    <span>Low Stock Report</span>
                    <p>View a list of inventory SKUs that are low in stock for reordering purposes.</p>
                  </div>
                </div>

              </div>

              <div className="card__title">
                <h5 className="reports_head">Customer Reports</h5>

                <div className="reports_left">
                  <div className="reports_left_inner">
                    <img className="" src="../img/graph.png" ></img>
                  </div>
                  <div className="reports_right_inner" onClick={() => { this.selectReport('customer_order_report') }}>
                    <span>Customer Orders Report</span>
                    <p>View a list of Orders by Customer.</p>
                  </div>
                </div>

              </div>

              <div className="card__title">
                <h5 className="reports_head">Product Reports</h5>

                <div className="reports_left">
                  <div className="reports_left_inner">
                    <img className="" src="../img/graph.png" ></img>
                  </div>
                  <div className="reports_right_inner" onClick={() => { this.selectReport('product_report') }}>
                    <span>Product Specification Report</span>
                    <p>View a list of Specification of the Products.</p>
                  </div>
                </div>
              </div>
            </CardBody>
            <CSVLink ref={this.exportBtn} filename={fileName} headers={csvHeaders} data={csvData}></CSVLink>;
      </Card>
        </Col>}
      </Col>
    )
  }

}

const mapStateToProps = ({ authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(ReportsCard);



