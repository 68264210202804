import React, { PureComponent,Component } from 'react';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import history from '../../../utils/history';
import Select from 'react-select';
import Country from '../../../utils/country.json';
import firebase1 from '../../../utils/config';
import Modal from 'react-modal';
import api from '../../../utils/api';
import NumberFormat from 'react-number-format';

import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : '-28%',
    bottom                : '-37%',
    transform             : 'translate(-50%, -50%)'
  }
};


class BillingForm extends PureComponent {
  

  constructor(props) {
    super(props)
    let countryList =[];
    let countries = Country.countries;
    for(let data in countries){
      countryList.push({label:countries[data].name,value:countries[data].name})
    }
    this.state = {
      showPassword: false,
      firstname:'',
      lastname:'',
      cardnumber:'',
      expiremonth:'',
      expireyear:'',
      ccv:'',
      zip:'',
      created_at:'',
      role:'client',
      company:'',
      email: '',
      password: '',
      isLoading:false,
      countryList:countryList,
      error: '',
      selectCountry:null,
      modalIsOpen: false,
      isAgree:false

    }

    this.handleToken = this.handleToken.bind(this);
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };


  componentDidMount(){
    const currentUser = firebase1.app.auth().currentUser;

    if(localStorage.getItem('registerdata')){
      this.signUpData = JSON.parse(localStorage.getItem('registerdata'));
    }else if(currentUser==null||currentUser==undefined){
      history.push('/login')
    }

    
  }

  componentWillReceiveProps(nextProps) {
  
    if(nextProps.user!=this.props.user){
      localStorage.removeItem('registerdata')
      history.push("/pages/products");
      this.setState({isLoading:false})
    }
    if (nextProps.error !== this.props.error&&nextProps.error!='') {
         helper.errorMessage(nextProps.error);
         this.setState({isLoading:false})
         history.goBack();
    }
    
  }

 

  handleToken(data,type){
    console.log('token==',type);
    if(type=='update'){
      this.props.updateBilling(data);
    }else{
      this.signUpData.billing = data;
      console.log('this.signUpData===',this.signUpData);
      this.props.registerUser(this.signUpData);
    }

  }


  fbLogin(){
    this.setState({isLoading:true})
    this.props.fbRegisterUser();
  }

  render() {
   
    const { handleSubmit } = this.props;
   

    if(this.state.isLoading){
      return(
        <div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )
    }else{
      return (
        <div>
          <Elements>
            <CardForm handleResult={this.handleToken} />
          </Elements>
        </div>
      );
    }
    
  }
}


const mapStateToProps = ({ authReducer }) => {
  return authReducer;
};

export default connect(mapStateToProps, actions)(BillingForm);


const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class _CardForm extends Component {
 

  constructor(props) {
    super(props)
    let countryList =[];
    let countries = Country.countries;
    for(let data in countries){
      countryList.push({label:countries[data].name,value:countries[data].name})
    }
    this.state = {
      showPassword: false,
      errorMessage:'',
      firstname:'',
      lastname:'',
      cardnumber:'',
      expiremonth:'',
      expireyear:'',
      ccv:'',
      zip:'',
      created_at:'',
      role:'client',
      company:'',
      email: '',
      password: '',
      isLoading:false,
      countryList:countryList,
      error: '',
      selectCountry:null,
      modalIsOpen: false,
      isAgree:false

    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  handleValueChange = (selectCountry) => {
    this.setState({selectCountry})
  }


  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  
  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {

      const {isAgree,firstname,selectCountry,lastname,cardnumber,expiremonth,expireyear,ccv,zip } = this.state;

      this.props.stripe.createToken().then((tokenData) => {
        console.log('tokenData==',tokenData);
        if(tokenData.error){
          helper.errorMessage(tokenData.error.message);
          this.setState({isLoading:false});
        }else if(firstname==''){
          helper.errorMessage('First name field is required');
        }else if(zip==''){
          helper.errorMessage('Postal Code is required');
        }else if(selectCountry==null){
          helper.errorMessage('please select country');
        }else if(isAgree==false){
          helper.errorMessage('please enable terms and condition');
        }else{
          this.setState({isLoading:true});
         const currentUser = firebase1.app.auth().currentUser;
          let req ={}
          if(currentUser){
            req ={
              email:currentUser.email,
              source:tokenData.token.id
            }
          }else{
            let signUpDataEmail =''
            if(localStorage.getItem('registerdata')){
              signUpDataEmail = JSON.parse(localStorage.getItem('registerdata'));
              signUpDataEmail = signUpDataEmail.email;
            }
            req ={
              email:signUpDataEmail,
              source:tokenData.token.id
            }
          }
          console.log(req);
          api.stripePost('customers',req).then((cutomerData) => {
            console.log('cutomerData===',cutomerData);
            if(cutomerData.error){
              helper.errorMessage(cutomerData.error.message);
              this.setState({isLoading:false});
            }else{
              let date = new Date();
              let createdDate = date.getDate()+"/"+(date.getMonth()+1)+"/"+(date.getFullYear());
              let data ={
                firstname:firstname,
                lastname:lastname,
                token:tokenData.token.id,
                created_at:createdDate,
                country:selectCountry,
                zip:zip,
                stripe_customer_id:cutomerData.id
              }
              if(currentUser){
                this.props.handleResult(data,'update')
              }else{
                this.props.handleResult(data,'insert')
              }
            }
           
          })
        }

      })
     
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const {isLoading,countryList,firstname,lastname,company, 
      role,email, password,cardnumber,expiremonth,expireyear,ccv,zip } = this.state;
      if(this.state.isLoading){
        return(
          <div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          </div>
        )
      }else{
        return (
          <div className="CardDemo">
            <form onSubmit={this.handleSubmit.bind(this)}>
    
            <div className="account__wrapper">
            <div className="account__card_billing account__card signup___card" style={{position: 'absolute', left:0, right:0}}>
              <div className="left_billing">
                <h2>Enter Your Billing Info</h2>
                <h3>Why collect this information now?</h3>
                <p>We ask for your credit card to allow you to continue your
                service after your free trial expires. This also helps us reduce
                fraud.</p>
    
                <h3>Will my credit card be charged right now?</h3>
                <p>No. Your credit card will not be charged at any point during
                your 14-day free trial (unless you make a purchase within
                the app). After your free trial, your subscription will automat
                ically start on our lowsest plan until you decide to upgrade,
                downgrade, or cancel. Plans are automatically upgrade when
                maximum SKUs are reached.</p>
    
                <h3>Can I cancel anytime?</h3>
                <p>Yes If you decide that Valet Seller is not the right solution
                for you, you can cancel whenever you want to both during
                and after your trial.</p>
              </div>
              <div className="account__head account_head_billing">
                <h3 className="account__subhead subhead forms_billing" style={{marginBottom:20}}></h3>
                <div className="form signupform billing_form">
                  
                  <div className="form__form-group billing_form_half" >
                    <label>First Name</label>
                    <div className="form__form-group-field left">
                      <input
                        name="firstname"
                        id="firstname"
                        type="text"
                        value={firstname}  onChange={(event) => {this.setState({ firstname: event.target.value })}}
                      />
                    </div>
                  </div>
                  <div className="form__form-group billing_form_half">  
                  <label>Last Name</label>
                    <div className="form__form-group-field right">
                      <input
                        name="lastname"
                        component="input"
                        type="text"
                        value={lastname}  onChange={(event) => {this.setState({ lastname: event.target.value })}}
                      />
                    </div>
                  </div>
                  <label className="card_details">Credit Card Number
                    <CardElement
                      onChange={this.handleChange}
                      hidePostalCode={true}
                      {...createOptions()}
                    />
                  </label>
              {/* <div className="error" role="alert">
                {this.state.errorMessage}
              </div> */}
                
    
                  <label className="expiration_date" style={{width:'100%', paddingLeft:10, paddingRight:10}}>Country</label>
                  <div className="form__form-group" style={{width:'100%', paddingLeft:10, paddingRight:10}}>
                
                    {countryList.length>0&&<Select
                            value={this.state.selectCountry}
                            onChange={this.handleValueChange}
                            options={countryList}
                    />}
                  </div>
    
                  <div className="form__form-group billing_form_div" >  
                  <label>Zip/Postal</label>
                    <div className="form__form-group-field right">
                      <input
                        name="lastname"
                        component="input"
                        id="postal-code-element"
                        type="text"
                        value={zip}  onChange={(event) => {this.setState({ zip: event.target.value })}}
                      />
                    </div>
                  </div>
    
                  <div className="form__form-group billing_form_div" >  
                    <div className="right">
                      <input type="checkbox" className="check_box" checked={this.state.isAgree}  onChange={(event) => {this.setState({ isAgree: event.target.checked })}}/>
                      <span className="check_box_span">I agree with <a onClick={this.openModal}>terms and conditions</a></span>
                    </div>
                  </div>
    
                    <button className="btn signupbtns_active account__btn--small buttons_pay" >Start my free trial</button>
                    <span className="signup_option_div">
                      <span className="signup_option_hr"></span>
                    </span>
                  </div>
                </div>      
              </div>
            </div>
             
            </form>
            <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            >
    
            <h2 ref={subtitle => this.subtitle = subtitle}>DISTRIBUTION AGREEMENT <a className="closemodal" onClick={this.closeModal}>X</a></h2>
            
            <div className="agreement">
              <span><b>Valet Seller</b></span>
              This Distribution Agreement (“Agreement”) is made and effective between Valet Seller (“Distributor”) 
              and Brand Owner (“Client”), the seller or owner of the below mentioned product(s) (“Product”). 
              
                <span>1.<b>Purpose.</b>  Distributor desires to secure from Client, and Client desires to provide to Distributor, 
                  a non-exclusive right (“Right”) to sell and distribute the Product outlined in Section 2 of this Agreement.
                 The Right grants Distributor the ability to sell or distribute the Products by any lawful means, including
                  but not limited to their sale through e-commerce marketplaces.</span>
                <span>2.<b>Description of the Product.</b> General Merchandise (“Product”). Client warrants that they have all 
                  necessary rightsto assign the Right and make this Agreement with Distributor. </span>
                <span>3.<b>Term.</b>  This Agreement shall be effective for six months (“Term”). Upon completion of the Term, 
                  this Agreement shall automatically renew for subsequent one year terms, unless terminated by actions 
                  pursuant Section 10 of this Agreement.</span>
                <span>4.<b>Payment.</b>  Client agrees to pay to Distributor 10 percent of all Product sale or distribution 
                  revenues generated by Distributor. Client also agrees to pay the selling fees assessed by the Distributors 
                  selling channels. Distributor will promptly deposit client’s funds on the 1st of every month via bank 
                  transfer failing which the Client has the right toterminate this Agreement immediately. The Distributor 
                  shall still be liable for the funds owed to the Client. Client will pay Distributor a listing fee each 
                  month.</span>
                <span>5.<b>Fulfillment.</b> Client agrees to ship the orders within 48 business hours of receiving order 
                  notifications.</span>
                <span>6.<b>Marketing Material.</b>  Client will supply all images, descriptions and marketing material. 
                  Distributor may produceand use additional materials as needed, provided Client provides written 
                  consent. Distributor agrees not to make any claims or warranties regarding the Product that are 
                  not in keeping with the claims and warranties that are represented by Client on Client’s 
                  webpage.</span>
                <span>7.<b>Best Efforts.</b>  Both parties agree to use all reasonable efforts to facilitate a growing 
                  and robust business relationship pursuant this Agreement. Distributor agrees to use their best 
                  efforts to increase sales volume, while Client agrees to use all reasonable efforts to provide 
                  adequate Product to fulfill any Distributor facilitated sale or distribution orders. </span>
                <span>8.<b>Most Favored Nation.</b>  Client agrees to offer to Distributor the best terms it makes 
                  available to any other seller (in the event they be more favorable than those currently offered 
                  to Distributor), distributor or other similar party, and to promptly notify Distributor of any 
                  changes pursuant to this provision. Client shall facilitate the availability or delivery of 
                  Product in furtherance of Distributor’s Right in at least as favorable terms as it treats any 
                  other selleror distributor, or sale, distribution or other similar agreement, and shall not 
                  unduly delay any communication or shipment. Client further agrees not to compete directly with 
                  Distributor, to include creating any alternative listing of the Product – whether alone or 
                  packaged with other products, on any e-commerce outlet already in use by Distributor, without 
                  the express consent of Distributor in writing. Distributor will not advertise or sell the Product 
                  at any price other than those provided in writing by Client.</span>
                <span>9.<b>Indemnity.</b>  Client maintains and assumes full responsibility for any liability arising from Distributor’s sale of the Product. 
                  Client indemnifies, defends and holds harmless Distributor and its members, directors, officers, 
                  employees and agents from any and all claims, demands, actions, suits and other losses resulting 
                  from Distributor’s sale or distribution of the Product. Client warrants it has all regulatory and 
                  legal authority to produce,sell and distribute the Product.</span>
                <span>10.<b>Termination.</b>  After the initial term, this Agreement may be terminated by either party upon 
                  thirty days' notice in writing. Writing includes communication by electronic mail, so long as such 
                  terminating party makes all reasonable efforts to ensure the other party has received such 
                  notification.</span>
                <span>11.<b>Severability.</b>  If any term of this Agreement is held by an appropriate court to be 
                  invalid or unenforceable, then this Agreement, including the remaining terms shall remain in 
                  effect.</span> 
                <span>12.<b>Governing Law.</b>  This Agreement shall be governed in accordance with the laws of the United 
                  States and the State of New York, where Distributor is incorporated. Both parties consent to the 
                  exclusive jurisdiction of the Stateof New York and United States Federal courts.</span>
                <span>13.<b>Entire Agreement.</b> This Agreement constitutes the entire agreement, and any modification must 
                  be in writing andsigned by both parties.</span>
            </div>
            </Modal>
          </div>
        );
      }
    
  }
}

const CardForm = injectStripe(_CardForm);