import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
//import SignUp from '../SignUp/index';
import Billing from '../Billing/index';

import Forgotpassword from '../Forgotpassword/index';
import Resetpassword from '../Resetpassword/index';

// import User from '../User/index';
import Products from '../Products/index';
import Sales from '../Sales/index';
import Marketing from '../Marketing/index';
import Advertising from '../Marketing/components/Advertising';
import Discount from '../Marketing/components/Discount';
import Listingoptimization from '../Marketing/components/Listingoptimization';
import Productphotography from '../Marketing/components/Productphotography';
import Enhancedbrand from '../Marketing/components/Enhancedbrand';
import Morereviews from '../Marketing/components/Morereviews';
import Professionalstorefront from '../Marketing/components/Professionalstorefront';

import Orders from '../Orders/index';
import Roles from '../Roles/index';
import Usermanagement from '../Usermanagement/index';
import Addusermanagement from '../Addusermanagement/index';
import HelpRequestCard from '../Helprequest/index';

import Addproducts from '../Addproducts/index';
import ProductVariation from '../Addproducts/components/Productvariation';

import Inventory from '../Inventory/index';

import Shipments from '../Shipments/index';
import Shipping from '../Shipping/index';
import Reports from '../Reports/index';
import Profile from '../Profile/index';
import Help from '../Help/index';
import Searchorders from '../Searchorders/index';

import Dashboard from '../Dashboard/index';
import Payment from '../Payment/index';
import ConsultingSession from '../Marketing/components/ConsultingSession';
import BrandMonitoring from '../Marketing/components/BrandMonitoring';
import BuyUpcs from '../Marketing/components/buyUpcs';
import UpcManagement from '../UpcManagement';
import AddUpcManagement from '../AddUpcManagement';
import MarketplaceLinkManagement from '../MarketplaceLinkManagement';
import DownloadInvoice from '../Marketing/components/invoice';
import BulkEdit from '../../containers/Products/bulkEdit/bulkEdit';
import FlaggedKeyword from '../flaggedKeywords/index';


// import Adduser from '../Adduser/index';

class Router extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const setOptions = {
			t: this.props.t,
		};

		return (
			<MainWrapper>
				<main>
					<Switch>
						<Route exact path='/pages/billing'>
							<Redirect to='/pages/marketplaces' />
						</Route>
						<Route
							exact
							path='/'
							render={(props) => {
								if (this.props.isAuthenticating) {
									if (this.props.walkthroughDone) {
										return <Redirect to={{ pathname: '/pages/dashboard' }} />;
									} else {
										return <Redirect to={{ pathname: '/pages/products' }} />;
									}
								}
								return <LogIn {...props} setOptions={setOptions} />;
							}}
						/>
						<Route
							exact
							path='/login'
							render={(props) => {
								if (this.props.isAuthenticating) {
									return <Redirect to={{ pathname: '/pages/dashboard' }} />;
								}
								return <LogIn {...props} setOptions={setOptions} />;
							}}
						/>
						<Route
							exact
							path='/forgotpassword'
							render={(props) => (
								<Forgotpassword {...props} setOptions={setOptions} />
							)}
						/>{' '}
						<Route
							exact
							path='/resetpassword'
							render={(props) => (
								<Resetpassword {...props} setOptions={setOptions} />
							)}
						/>
						<>
							<Layout setOptions={setOptions} />
							<div className='container__wrap'>
								<Switch>
									<Route
										exact
										path='/pages/products'
										render={(props) => (
											<Products {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/searchorders'
										render={(props) => (
											<Searchorders {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/bulkEdit/:products/'
										render={(props) => (
											<BulkEdit {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/searchorders/:text'
										render={(props) => (
											<Searchorders {...props} setOptions={setOptions} />
										)}
									/>

									<Route
										exact
										path='/pages/sales'
										render={(props) => (
											<Sales {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/marketing'
										render={(props) => (
											<Marketing {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/orders'
										render={(props) => (
											<Orders {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/roles'
										render={(props) => (
											<Roles {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/editusermanagement/:id'
										render={(props) => (
											<Addusermanagement {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/invoice/:id'
										render={(props) => (
											<DownloadInvoice {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/addproducts'
										render={(props) => (
											<Addproducts {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/newproducts'
										render={(props) => (
											<ProductVariation {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/editnewproducts/:id'
										render={(props) => (
											<ProductVariation {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/editproducts/:id'
										render={(props) => (
											<ProductVariation {...props} setOptions={setOptions} />
										)}
									/>

									{/* <Route exact path="/pages/editproducts/:id" render={(props) => <Addproducts {...props} setOptions={setOptions} />} /> */}
									{/* <Route
										exact
										path='/pages/inventory'
										render={(props) => (
											<Inventory {...props} setOptions={setOptions} />
										)}
									/> */}

									{this.props.role == 'admin' && (
										<Route
											exact
											path='/pages/flaggedKeywords'
											render={(props) => (
												<FlaggedKeyword {...props} setOptions={setOptions} />
											)}
										/>
									)}

									{this.props.role == 'admin' && (
										<Route
											exact
											path='/pages/helprequest'
											render={(props) => (
												<HelpRequestCard {...props} setOptions={setOptions} />
											)}
										/>
									)}
									{this.props.role == 'admin' && (
										<Route
											exact
											path='/pages/user'
											render={(props) => (
												<Usermanagement {...props} setOptions={setOptions} />
											)}
										/>
									)}
									{this.props.role == 'admin' && (
										<Route
											exact
											path='/pages/adduser'
											render={(props) => (
												<Addusermanagement {...props} setOptions={setOptions} />
											)}
										/>
									)}
									<Route
										exact
										path='/pages/reports'
										render={(props) => (
											<Reports {...props} setOptions={setOptions} />
										)}
									/>

									<Route
										exact
										path='/pages/profile'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/integrations'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/marketplaces'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/transaction'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/branding'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/subscription'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/notification'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/tax'
										render={(props) => (
											<Profile {...props} setOptions={setOptions} />
										)}
									/>

									<Route
										exact
										path='/pages/help'
										render={(props) => (
											<Help {...props} setOptions={setOptions} />
										)}
									/>
									<Route path='/pages/dashboard' component={Dashboard} />
									<Route path='/pages/payments' component={Payment} />
									<Route
										path='/pages/payment/:month/:year'
										component={Payment}
									/>
									<Route
										exact
										path='/pages/advertising'
										render={(props) => (
											<Advertising {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/discount'
										render={(props) => (
											<Discount {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/listingoptimization'
										render={(props) => (
											<Listingoptimization {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/productphotography'
										render={(props) => (
											<Productphotography {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/enhancedbrand'
										render={(props) => (
											<Enhancedbrand {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/morereviews'
										render={(props) => (
											<Morereviews {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/professionalstorefront'
										render={(props) => (
											<Professionalstorefront
												{...props}
												setOptions={setOptions}
											/>
										)}
									/>
									<Route
										exact
										path='/pages/shipping/:id'
										render={(props) => (
											<Shipping {...props} setOptions={setOptions} />
										)}
									/>
									<Route
										exact
										path='/pages/consultingsession'
										render={(props) => (
											<ConsultingSession
												{...props}
												setOptions={setOptions}
											/>
										)}
									/>
									<Route
										exact
										path='/pages/buyUpcs'
										render={(props) => (
											<BuyUpcs
												{...props}
												setOptions={setOptions}
											/>
										)}
									/>
									<Route
										exact
										path='/pages/brandMonitoring'
										render={(props) => (
											<BrandMonitoring
												{...props}
												setOptions={setOptions}
											/>
										)}
									/>
									{this.props.role == 'admin' && (<>
									<Route
										exact
										path='/pages/upcmanagement'
										render={(props) => (
											<UpcManagement
												{...props}
												setOptions={setOptions}
											/>
										)}
									/>
										<Route
											exact
											path='/pages/addupcmanagement'
											render={(props) => (
												<AddUpcManagement {...props} setOptions={setOptions} />
											)}
										/>
										<Route
											exact
											path='/pages/editupcmanagement/:id'
											render={(props) => (
												<AddUpcManagement {...props} setOptions={setOptions} />
											)}
										/>
										<Route
											exact
											path='/pages/marketplacelinkmanagement'
											render={(props) => (
												<MarketplaceLinkManagement
													{...props}
													setOptions={setOptions}
												/>
											)}
										/>
									</>)}
								</Switch>
							</div>
						</>
					</Switch>
				</main>
			</MainWrapper>
		);
	}
}
export default Router;
