import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import TableList from '../../Table';
import firebase1 from '../../../utils/config';
import {
  Card, CardBody, Col, Badge, Button, Input, FormGroup, Label, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import api from '../../../utils/api';
import { FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';

import CSVReader from 'react-csv-reader';

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header =>
    header
      .toLowerCase()
      .replace(/\W/g, '_')
}

class PaymentCard extends PureComponent {
  asyncOrderCsvLink = React.createRef();
  asyncReturnCsvLink = React.createRef();

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      name: 'user',
      ordersHeaders: [
        { label: 'Storefront Name', key: 'Storefront Name' },
        { label: 'Order Number', key: 'Order Number' },
        { label: 'Ecomdash ID', key: 'Ecomdash ID' },
        { label: 'Status', key: 'Status' },
        { label: 'Line Item Total', key: 'Line Item Total' },
        { label: 'Product Name', key: 'Product Name' },
        { label: 'Sku', key: 'Sku' },
        { label: 'Warehouses', key: 'Warehouses' },
        { label: 'Payment Received Date', key: 'Payment Received Date' },
        { label: 'Last Modified Date', key: 'Last Modified Date' },
        { label: 'Purchase Date', key: 'Purchase Date' },
        { label: 'Billed Date', key: 'Billed Date' },
        { label: 'Customer Name', key: 'Customer Name' },
      ],
      returnHeaders: [
        { label: 'Marketplace', key: 'Marketplace' },
        { label: 'SKU', key: 'SKU' },
        { label: 'Item Total', key: 'Item Total' },
        { label: 'Date Of Return', key: 'Date Of Return' },
        { label: 'Payment Received Date', key: 'Payment Received Date' },
        { label: 'Return Bill Date', key: 'Return Bill Date' },
        { label: 'Item Name', key: 'Item Name' },
        { label: 'Quantity', key: 'Quantity' },
        { label: 'Observation', key: 'Observation' },
      ],
      csvData: [],
      orders: [],
      returnOrders: [],
      totalRevenue: 0,
      channelFees: 0,
      postage: 0,
      couponFees: 0,
      returns: 0,
      balanceDue: 0,
      totalExp: 0,
      totalAdditonalServices: 0,
      channelPayment: [],
      notificationModel: false,
      paymentHeaders: [
        { label: 'Total Revenue', key: 'Total Revenue' },
        { label: 'Total Expense', key: 'Total Expense' },
        { label: 'Balance', key: 'Balance' },
        { label: 'Valetseller Commission', key: 'Valetseller Commission' },
        { label: 'Marketplace Commission', key: 'Marketplace Commission' },
        { label: 'Returns', key: 'Returns' },
        { label: 'Promo Fees', key: 'Promo Fees' },
        { label: 'Other Expense', key: 'Other Expense' },
        { label: 'Postage', key: 'Postage' },
        { label: 'Receiving', key: 'Receiving' },
        { label: 'Barcode Prep', key: 'Barcode Prep' },
        { label: 'Bundling/Kitting', key: 'Bundling/Kitting' },
        { label: 'Packaging Prep', key: 'Packaging Prep' },
        { label: 'Handling', key: 'Handling' },
        { label: 'Pallet Prep', key: 'Pallet Prep' },
        { label: 'Freight', key: 'Freight' },
        { label: 'Partner 3PL Fulfillment Fees', key: 'Partner 3PL Fulfillment Fees' },
        { label: 'Storage', key: 'Storage' },
        { label: 'Other Fulfillment', key: 'Other Fulfillment' },
        { label: 'Total Fulfillment', key: 'Total Fulfillment' },
        { label: 'Marketplace Advertising', key: 'Marketplace Advertising' },
        { label: 'Additional Services Total', key: 'Additional Services Total' }
      ],
      paymentCsvData: [],
      ordersData: [],
      returnHandlingCost: 0,
      returnPostageCost: 0,
      storageFees: 0,
      selectedDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0))
    };

    this.exportBtn = React.createRef();
    this.showModal = this.showModal.bind(this);

  }

  componentDidMount() {
    const { user, userdetails } = this.state;
    if (this.props.userdetails) {
      this.updateUser(this.props.userdetails);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userdetails != undefined && nextProps.userdetails != this.props.userdetails) {
      this.updateUser(nextProps.userdetails);
    }
  }

  updateUser(user) {
    let selectedDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0));
    this.setState({
      key: user.key,
      firstname: user.firstname,
      company: user.company,
      email: user.email,
      role: user.role ? user.role.label : '',
      Uid: user.uid,
      selectedDate: selectedDate,
    }, () => {
      if (this.props.match.params.month && this.props.match.params.year) {
        // months are zero based, so need to subtract one because incoming isn't zero based
        let selectedDate = new Date(Date.UTC(this.props.match.params.year, this.props.match.params.month - 1, 1, 0, 0, 0, 0));
        this.setState({
          selectedDate: selectedDate,
          isLoading: true
        })
        this.getPaymentDetails()

      } else {
        let selectedDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0));
        this.setState({
          selectedDate: selectedDate,
          isLoading: true
        });
        this.getPaymentDetails()
      }
    })
  }

  handleChangeMonth = date => {
    let selectedDate = moment(`${date.getFullYear()}-${date.getMonth() + 1}-01T00:00:00.000Z`, "YYYY-MM-DDTHH:mm:ssZ").toDate()
    let toDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 999)).toISOString();
    this.setState({
      selectedDate: selectedDate,
      orders: [],
      isLoading: true
    }, () => {
      this.getPaymentDetails()
    })
  };

  getReturns() {

    let apiPath = 'payment/getPaymentReturnOrders';

    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 999)).toISOString();
    let reqData = {
      FromDate: fromDate,
      ToDate: toDate,
      Uid: this.state.Uid
    }
    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          let returnOrders = [];
          let orders = data.data;
          for (let order of orders) {
            for (let line of order.LineItems) {
              returnOrders.push({
                'Marketplace': order.StorefrontName,
                'SKU': line.SkuNumber,
                'Item Total': line.LineItemTotal,
                'Date Of Return': order.Returns.Date,
                'Payment Received Date': order.PaymentReceivedDate,
                'Return Bill Date': order.Returns.billedAt ? moment(new Date(order.Returns.billedAt)).utc().format('MM-YYYY') : '',
                'Item Name': line.ProductName,
                'Quantity': line.QuantitySold,
                'Observation': order.Returns.Observstion //misspelled in db
              })
            }

            if (order.DiscountAmount) {
              returnOrders.push({
                'Marketplace': order.StorefrontName,
                'SKU': 'PROMOTIONS',
                'Item Total': order.DiscountAmount * -1,
                'Date Of Return': order.Returns.Date,
                'Payment Received Date': order.PaymentReceivedDate,
                'Return Bill Date': order.Returns.billedAt ? moment(new Date(order.Returns.billedAt)).utc().format('MM-YYYY') : '',
                'Item Name': 'PROMOTIONS',
                'Quantity': 'PROMOTIONS',
                'Observation': order.Returns.Observstion //misspelled in db
              });
            }
          }
          this.setState({ isLoading: false, returnOrders: returnOrders });
          // this will trigger the download
          this.asyncReturnCsvLink.current.link.click();
        } else {
          helper.errorMessage('Something went wrong...');
          this.setState({ isLoading: false });
        }
      }
    )
  }

  getOrders(page) {
    this.setState({ isLoading: true });
    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 999)).toISOString();
    let apiPath = 'payment/getPaymentOrders';
    let reqData = {
      FromDate: fromDate,
      ToDate: toDate,
      Uid: this.state.Uid,
      Pagination: {
        ResultsPerPage: 200,
        PageNumber: page,
      }
    };

    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          let newOrders = data.data;
          if (data.pagination.CurrentPage < data.pagination.TotalNumberOfPages) {
            this.setState({ orders: this.state.orders.concat(newOrders) });
            this.getOrders(data.pagination.CurrentPage + 1);
          } else {
            this.setState({ orders: this.state.orders.concat(newOrders) });
            let orders = this.state.orders;
            orders = [...new Map(orders.map(item => [item['_id'], item])).values()];
            let ordersData = [];
            for (let order of orders) {
              for (let line of order.LineItems) {
                ordersData.push({
                  'Storefront Name': order.StorefrontName,
                  'Order Number': order.OrderNumber,
                  'Ecomdash ID': order.EcomdashId,
                  'Status': order.Status,
                  'Line Item Total': line.LineItemTotal,
                  'Quantity': line.QuantitySold,
                  'Unit Price': line.UnitPrice,
                  'Product Name': line.ProductName,
                  'Sku': line.SkuNumber,
                  'Warehouses': line.WarehouseName,
                  'Payment Received Date': order.PaymentReceivedDate,
                  'Last Modified Date': order.LastModifiedDate,
                  'Purchase Date': order.PurchaseDate,
                  'Billed Date': order.PaidAt ? moment(new Date(order.PaidAt)).utc().format('MM-YYYY') : '',
                  'Customer Name': order.CustomerInfo.FullName
                });
              }

              if (order.DiscountAmount) {
                ordersData.push({
                  'Storefront Name': order.StorefrontName,
                  'Order Number': order.OrderNumber,
                  'Ecomdash ID': order.EcomdashId,
                  'Status': order.Status,
                  'Line Item Total': order.DiscountAmount * -1,
                  'Quantity': 'PROMOTIONS',
                  'Unit Price': 'PROMOTIONS',
                  'Product Name': 'PROMOTIONS',
                  'Sku': 'PROMOTIONS',
                  'Warehouses': 'PROMOTIONS',
                  'Payment Received Date': order.PaymentReceivedDate,
                  'Last Modified Date': order.LastModifiedDate,
                  'Purchase Date': order.PurchaseDate,
                  'Billed Date': order.PaidAt ? moment(new Date(order.PaidAt)).utc().format('MM-YYYY') : '',
                  'Customer Name': order.CustomerInfo.FullName
                });
              }
            }
            this.setState({ isLoading: false, ordersData: ordersData });
            // this will trigger the download
            this.asyncOrderCsvLink.current.link.click();
          }
        } else {
          if (data.messages.length > 0) {
            helper.errorMessage(data.messages[0]);
          }
          this.setState({ isLoading: false });
        }
      },
      (err) => {
        helper.errorMessage(err);
        this.setState({ isLoading: false });
      }
    )
  }

  getPaymentDetails() {
    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 999)).toISOString();

    let apiPath = 'payment/getPagePaymentDetails';
    let reqData = {
      FromDate: fromDate,
      ToDate: toDate
    }
    if (this.state.role != 'admin') {
      reqData.Uid = this.state.Uid;
    }
    api.postApi(apiPath, reqData).then((data) => {
      if (data.status == 'Success') {
        let pageData = data.data;

        let channelPayment = [];
        channelPayment.push({ '_id': 'Amazon', 'sales': pageData['Revenue']['Channel Summary']['Amazon'] });
        channelPayment.push({ '_id': 'eBay', 'sales': pageData['Revenue']['Channel Summary']['EBay'] });
        channelPayment.push({ '_id': 'Walmart', 'sales': pageData['Revenue']['Channel Summary']['Walmart'] });
        channelPayment.push({ '_id': 'Other', 'sales': pageData['Revenue']['Channel Summary']['Other'] });

        let paymentCsvData = [];
        paymentCsvData.push({
          'Total Revenue': pageData['Revenue']['Total'],
          'Total Expense': pageData['Expense']['Total'],
          'Balance': pageData['Balance'],
          'Valetseller Commission': pageData['Expense']['Valetseller Commission'],
          'Marketplace Commission': pageData['Expense']['Marketplace Commission'],
          'Returns': pageData['Expense']['Returns'],
          'Promo Fees': pageData['Expense']['Promo Fees'],
          'Other Expense': pageData['Expense']['Other'],
          'Postage': pageData['Fulfillment']['Postage'],
          'Receiving': pageData['Fulfillment']['Receiving'],
          'Barcode Prep': pageData['Fulfillment']['Barcode Prep'],
          'Bundling/Kitting': pageData['Fulfillment']['Bundling/Kitting'],
          'Packaging Prep': pageData['Fulfillment']['Packaging Prep'],
          'Handling': pageData['Fulfillment']['Handling'],
          'Pallet Prep': pageData['Fulfillment']['Pallet Prep'],
          'Freight': pageData['Fulfillment']['Freight'],
          'Partner 3PL Fulfillment Fees': pageData['Fulfillment']['Partner 3PL Fulfillment Fees'],
          'Storage': pageData['Fulfillment']['Storage'],
          'Other Fulfillment': pageData['Fulfillment']['Other Fulfillment Fees'],
          'Total Fulfillment': pageData['Fulfillment']['Total Fulfillment Fees'],
          'Marketplace Advertising': pageData['Marketplace Advertising'],
          'Additional Services Total': pageData['Additional Services Total'],
        })

        this.setState({
          totalExpense: pageData['Expense']['Total'],
          totalAdditonalServices: pageData['Additional Services Total'],
          channelPayment: channelPayment,
          paymentCsvData: paymentCsvData,
          totalRevenue: pageData['Revenue']['Total'],
          valetSellerCommission: pageData['Expense']['Valetseller Commission'],
          marketPlaceCommission: pageData['Expense']['Marketplace Commission'],
          storageCommission: pageData['Fulfillment']['Storage'],
          partner3PLFullfillmentFees: pageData['Fulfillment']['Partner 3PL Fulfillment Fees'],
          marketplaceAdvertising: pageData['Marketplace Advertising'],
          promoFees: pageData['Expense']['Promo Fees'],
          other: pageData['Expense']['Other'],
          balanceDue: pageData['Balance'],
          returns: pageData['Expense']['Returns'],
          postage: pageData['Fulfillment']['Postage'],
          receiving: pageData['Fulfillment']['Receiving'],
          barcode: pageData['Fulfillment']['Barcode Prep'],
          kitting: pageData['Fulfillment']['Bundling/Kitting'],
          packaging: pageData['Fulfillment']['Packaging Prep'],
          handling: pageData['Fulfillment']['Handling'],
          pallet: pageData['Fulfillment']['Pallet Prep'],
          freight: pageData['Fulfillment']['Freight'],
          partner: pageData['Fulfillment']['Partner 3PL Fulfillment Fees'],
          otherFullFillMentAmount: pageData['Fulfillment']['Other Fulfillment Fees'],
          returnHandlingCost: pageData['Fulfillment']['Return Handling Cost'],
          returnPostageCost: pageData['Fulfillment']['Return Postage Cost'],
          internationalFees: pageData['Fulfillment']['International Fees'],
          storageFees: pageData['Fulfillment']["Storage Fees"],
          totalFullFillMent: pageData['Fulfillment']['Total Fulfillment Fees'],
          isLoading: false
        })
      } else {
        helper.errorMessage('Something went wrong...');
        this.setState({ isLoading: false });
      }
    })

  }

  showModal() {
    this.setState({
      notificationModel: !this.state.notificationModel,
    });
  }

  sendMailNotification() {
    this.setState({ isLoading: true, notificationModel: false });
    let apiPath = 'payment/sendPaymentNotification';

    // these are strings for the email template and not zero based for month
    let reqData = {
      month: moment.utc(this.state.selectedDate).format('MM'),
      year: moment.utc(this.state.selectedDate).format('YYYY')
    }
    api.postApi(apiPath, reqData).then((data) => {
      if (data.status == 'Success') {
        helper.successMessage('Email successfully sent.')
      }
      this.setState({ isLoading: false });
    })
  }

  async getClientPaymentSummaryReport() {
    let apiPath = 'payment/getClientPaymentSummaryReport';

    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 999)).toISOString();

    let reqData = {
      FromDate: fromDate,
      ToDate: toDate
    };

    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          helper.successMessage('Report generating, check your email soon.')
        }
      })
  }

  async getAdminReturnDetailReport() {
    let apiPath = 'payment/returnsDetailReport';

    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 59)).toISOString();

    let reqData = {
      FromDate: fromDate,
      ToDate: toDate
    };

    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          helper.successMessage('Report generating, check your email soon.')
        }
      })
  }

  async getAdminOrderDetailReport() {
    let apiPath = 'payment/sendOrderDetailReport';

    let fromDate = this.state.selectedDate.toISOString();
    let toDate = new Date(Date.UTC(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth() + 1, 0, 23, 59, 59, 59)).toISOString();

    let reqData = {
      FromDate: fromDate,
      ToDate: toDate
    };
    this.setState({ isLoading: true });
    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          helper.successMessage('Report generating, check your email soon.');
        }
        this.setState({ isLoading: false });
      })
  }

  async downloadUserOrdersCsv() {
    this.setState({ isLoading: true });
    this.getOrders(1);
  }

  async downloadUserReturnsCsv() {
    this.setState({ isLoading: true });
    this.getReturns();
  }

  billOrders() {
    api.getApi("orders/billOrders").then(
      (data) => {
        helper.errorMessage('Billing orders')
      })
  }

  requestPayment() {
    let apiPath = 'mail/onRequestPayment';
    let reqData = {
      firstname: this.state.firstname,
      company: this.state.company,
      email: this.state.email,
    };
    this.setState({ isLoading: true })
    api.postApi(apiPath, reqData).then(
      (data) => {
        if (data.status == 'Success') {
          helper.successMessage('sucessfully mail sent')
        } else {
          helper.errorMessage('Something went wrong...');
        }
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { totalRevenue, ordersData, ordersHeaders, role, paymentHeaders, paymentCsvData, channelPayment, csvData, csvHeaders, returnHeaders, returnOrders } = this.state;
    const hideMonth = (
      moment(this.state.selectedDate).format('MM-YYYY') === moment().format('MM-YYYY')
      || moment(this.state.selectedDate).isSameOrAfter(moment())
    ) && this.state.role != 'admin';
    return (

      <Col className="container-div" md={12}>
        {this.state.isLoading == true && (
          <div
            className='load'
            style={{
              backgroundColor: 'transparent',
              width: '85%',
              height: '50%',
            }}
          >
            <div className='load__icon-wrap'>
              <svg className='load__icon'>
                <path
                  fill='#6c6f7f'
                  d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
                />
              </svg>
            </div>
          </div>
        )}


        {this.state.isLoading == false && <Col md={12} >
          <Card>
            <CardBody className="payment_datepicker">
              {role != 'admin' && <div className="card__title">
                <h5 className="bold-text pr-0">Payment</h5>
              </div>}
              <Button className="btn addbtn" onClick={() => { this.pdfExportComponent.save(); }} disabled={hideMonth}>Download Report</Button>
              {role == 'admin' && <button className="btn addbtn" onClick={() => {
                this.showModal();
              }}>Send Email Notifications</button>}
              {role == 'admin' && <button className="btn addbtn" onClick={() => { this.getAdminReturnDetailReport() }} disabled={hideMonth}>Download Returns</button>}
              {role != 'admin' && <div>
                <button onClick={() => { this.downloadUserReturnsCsv() }} className="btn addbtn" disabled={hideMonth}>Download Returns</button>
                <CSVLink filename='returns.csv' headers={returnHeaders} className="hidden" data={returnOrders} target="_blank" ref={this.asyncReturnCsvLink}></CSVLink>
              </div>}
              {role == 'admin' && <button className="btn addbtn" onClick={() => { this.getAdminOrderDetailReport() }} disabled={hideMonth}>Download Orders</button>}
              {role != 'admin' && <div>
                <button onClick={() => { this.downloadUserOrdersCsv() }} className="btn addbtn" disabled={hideMonth}>Download Orders</button>
                <CSVLink filename='orders.csv' headers={ordersHeaders} className="hidden" data={ordersData} target="_blank" ref={this.asyncOrderCsvLink}></CSVLink>
              </div>}
              {role == 'admin' && <button className="btn addbtn" onClick={() => { this.getClientPaymentSummaryReport() }} disabled={hideMonth}>Download Summary Report</button>}
              {role == 'admin' && <button className="btn addbtn" onClick={() => { this.billOrders() }} disabled={hideMonth}>Bill Order</button>}
              {role == 'admin' && <CSVLink ref={this.exportBtn} filename='payment.csv' headers={csvHeaders} data={csvData}></CSVLink>}
              <div className="datepicker_payment">
                <div className="datepicker_payment_inner">
                  <Label>Select Month/Year</Label>
                  <DatePicker
                    showMonthYearPicker
                    dateFormat='MM/yyyy'
                    selected={new Date(this.state.selectedDate.getUTCFullYear(), this.state.selectedDate.getUTCMonth(), 1, 0, 0, 0, 0)}
                    onChange={this.handleChangeMonth}
                  />
                </div>
              </div>
              {
                hideMonth ? (
                  <div>
                    <div style={{ marginTop: '13%', paddingTop: '5%', paddingBottom: '10%', marginBottom: '5%', border: '1px solid #888' }}>
                      <center>
                        <h4>Month Pending</h4>
                        <br />
                        <p>Payment details will be available when disbursements are processed for this month.</p>
                      </center>
                    </div>
                  </div>
                ) : (
                  <PDFExport
                    forcePageBreak=".page-break"
                    /* eslint no-return-assign: 0 */
                    ref={(component) => this.pdfExportComponent = component}
                  >
                    <div style={{ padding: '5%' }}>
                      <div className="Break_down">

                        <div className="summary">
                          <h3 style={{ fontSize: 20, textAlign: 'left' }}>Summary<CSVLink filename='payment.csv' headers={paymentHeaders} data={paymentCsvData}><button className="btn addbtn">Download</button></CSVLink></h3>
                          <span className="summary_div">
                            <span className="left_summary">Total Revenue</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.totalRevenue)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Total Expenses</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.totalExpense)}</span>
                          </span>
                          <span className="summary_div" style={{ background: '#3d445a', color: '#fff' }}>
                            <span className="left_summary">Balance</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.balanceDue)}</span>
                          </span>
                        </div>
                        <div className="summary">
                          <h3 style={{ fontSize: 20, textAlign: 'left' }}>Expense Breakdown</h3>
                          <span className="summary_div">
                            <span className="left_summary">Valet Seller Commission</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.valetSellerCommission)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Marketplace Commission</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.marketPlaceCommission)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Returns</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.returns)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Promo Fees</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.promoFees)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Other</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.otherExpenses)}</span>
                          </span>
                          <span className="summary_div" style={{ background: '#3d445a', color: '#fff' }}>
                            <span className="left_summary">Total</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.totalExpense)}</span>
                          </span>
                        </div>
                        <div className="summary">
                          <h3 style={{ fontSize: 20, textAlign: 'left' }}>Valet Seller Fulfillment  </h3>
                          <span className="summary_div">
                            <span className="left_summary">Postage</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.postage)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Receiving</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.receiving)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Barcode Prep</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.barcode)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Bundling/Kitting</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.kitting)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Packaging Prep</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.packaging)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Handling</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.handling)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Pallet Prep</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.pallet)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Freight</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.freight)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Partner 3PL Fulfillment Fees</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.partner)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Storage</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.storageFees || 0)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Return Handling Cost</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.returnHandlingCost)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Return Postage Cost</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.returnPostageCost)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">International Fees</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.internationalFees || 0)}</span>
                          </span>
                          <span className="summary_div">
                            <span className="left_summary">Other</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.otherFullFillMentAmount)}</span>
                          </span>
                          <span className="summary_div" style={{ background: '#3d445a', color: '#fff' }}>
                            <span className="left_summary">Total</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.totalFullFillMent)}</span>
                          </span>
                        </div>
                        <div className="summary">
                          <h3 style={{ fontSize: 20, textAlign: 'left' }}>Additional Services  </h3>
                          <span className="summary_div">
                            <span className="left_summary">Marketplace Advertising Plan</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.marketplaceAdvertising)}</span>
                          </span>
                          <span className="summary_div" style={{ background: '#3d445a', color: '#fff' }}>
                            <span className="left_summary">Total</span>
                            <span className="right_summary">${helper.getNumberFormat(this.state.totalAdditonalServices)}</span>
                          </span>
                        </div>
                      </div>
                      <div className="Break_downs" style={{ marginBottom: 30, position: 'relative' }}>
                        <h4>Channel Summary</h4>
                        {channelPayment.map((item, i) => {
                          if (item._id == 'EBay' || item._id == 'eBay' || item._id == 'Walmart' || item._id == 'Amazon' || item._id == 'Other') {
                            return (
                              <span className="summary_div" key={i}>
                                <span className="left_summary">{item._id}</span>
                                <span className="right_summary">${helper.getNumberFormat(item.sales)}</span>
                              </span>
                            )
                          }
                        })}
                        <span className="summary_div" style={{ background: '#3d445a', color: '#fff' }}>
                          <span className="left_summary">Total Revenue</span>
                          <span className="right_summary">${helper.getNumberFormat(totalRevenue)}</span>
                        </span>
                      </div>
                    </div>
                  </PDFExport>
                )
              }
            </CardBody>
          </Card>
        </Col>}

        <div onClick={this.showModal}>
          <Modal
            isOpen={this.state.notificationModel}
            toggle={this.showModal}
            className={'modal-sm '}
            style={{ marginTop: 150 }}
          >
            <ModalHeader toggle={this.showModal}>Alert</ModalHeader>
            <ModalBody>Are you sure you want to trigger email notifications for the month of {moment.utc(this.state.selectedDate).format('MMMM YYYY')}?</ModalBody>
            <ModalFooter>
              <Button
                color='primary'
                onClick={() => {
                  this.sendMailNotification();
                }}
              >
                Yes
              </Button>{' '}
              <Button color='secondary' onClick={this.showModal}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Col>

    )
  }

}
const mapStateToProps = ({ authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(PaymentCard);
