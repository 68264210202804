import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddproductsCard from './components/AddproductsCard';


import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Addproducts extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:''
    }
  }
  
  render() {
    const {t} = this.props.setOptions
    return (
      <div>
      <Container className="dashboard">
        <Row>
          <Col md={6}>
            <h3 className="page-title">Add Products</h3>
          </Col>
          <Col md={6}>
          <a  className="btn addbtn add_products optimisation" target="_blank" style={{"margin": "10px"}}
           href="https://docs.google.com/document/d/1XtCyIpryol6zAj8MT6vIfNzlvP5tJGTnmBG0yd8dHl8">
                {/* <button > */}
                  Optimization Guide
                  {/* </button> */}
              </a>
          </Col>
        </Row>
        <Row>
            <AddproductsCard {...this.props}/>
           
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Addproducts);
