//auth
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAIL = "login_user_fail";
export const LOGIN_USER = "login_user";
export const LOGOUT = "logout";
export const LOGIN_USER_SUCCESS_DETAILS = "login_user_sucess_details"; 

//datatable

export const ADD_RECORD = "add_record";
export const EDIT_RECORD = "edit_record";
export const DELETE_RECORD = "delete_record";
export const LIST_RECORD = "list_record";
export const UPDATE_MEDICAL_RECORD = "update_medical_record"; 
export const UPDATE_RECORD = "update_record";
export const UPDATE_EXAM_MODAL = "update_exam_modal";
export const EDIT_MEDICAL_RECORD = "edit_medical_record"; 
export const TABLE_LOADING = "table_loading"; 

export const LIST_PATIENT_RECORD = "list_patient_record";
export const LIST_MEDICALCASES_RECORD = "list_medicalcases_record";
export const LIST_DIAGNOSIS_RECORD = "list_diagnosis_record";
export const LIST_HYPOTHESIS_RECORD = "list_hypothesis_record";
export const LIST_MEDICALEXAMS_RECORD = "list_medicalexams_record";
export const LIST_TREATMENT_RECORD = "list_treatment_record";
export const LIST_DERIVATIONS_RECORD = "list_derivations_record";
export const LIST_COMPANY_RECORD = "list_company_record";
export const LIST_USER_RECORD = "list_user_record";
export const UPDATE_TOGGLE = "Update_Toggle";


//role
export const ROLE_ADD_RECORD = "role_add_record";
export const ROLE_LIST_RECORD = "role_list_record";
export const ROLE_LOADING = "role_loading";
export const ROLE_DELETE_RECORD = "role_delete_record";



