import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Button,
	Input,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import api from '../../../utils/api';
import TableList from '../../Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	PDFDownloadLink,
} from '@react-pdf/renderer';

const shippingList = [
	{ value: '1975631', label: 'Ana Alfaro' },
	{ value: '2096432', label: 'Andrew Michaud' },
	{ value: '2148663', label: 'Andrew Timm' },
	{ value: '2142765', label: 'Ashley Piedra ' },
	{ value: '2143783', label: 'Ashley piedra' },
	{ value: '2161257', label: 'Ashley piedra' },
	{ value: '1965658', label: 'Bridget Riley' },
	{ value: '2179081', label: 'David DeDionisio' },
	{ value: '1906672', label: 'Dr. Ahmed El-Deeb' },
	{ value: '1959281', label: 'Dulce Huerta' },
	{ value: '2842906', label: 'GHD Return' },
	{ value: '2014159', label: 'Ian Ballard' },
	{ value: '2105468', label: 'Jacob Kearns' },
	{ value: '2161239', label: 'James Mc Gullam' },
	{ value: '2167230', label: 'Janice Cantrell' },
	{ value: '2096386', label: 'Jennifer Collins' },
	{ value: '1993769', label: 'Jennifer Jennings' },
	{ value: '2192263', label: 'joann clayborne' },
	{ value: '275119', label: 'Kaya Kopi' },
	{ value: '2161272', label: 'Kenny Miller' },
	{ value: '585755', label: 'Kevin Dunne' },
	{ value: '2648540', label: 'Kevin Dunne' },
	{ value: '2820392', label: 'Kevin Vegas House' },
	{ value: '2026900', label: 'Martha Rose' },
	{ value: '2061497', label: 'Maysam Ardehali' },
	{ value: '2225808', label: 'Michael Lanno' },
	{ value: '2148618', label: 'Nancy Stobaugh' },
	{ value: '2014118', label: 'Neena Husid' },
	{ value: '2853130', label: 'PHUC THINH PRODUCTION' },
	{ value: '2410087', label: 'PR Location' },
	{ value: '2215249', label: 'Quang Luu' },
	{ value: '2234890', label: 'Rebecca Willis' },
	{ value: '2842829', label: 'Return Label GHB' },
	{ value: '2130404', label: 'Rex Verley' },
	{ value: '2061471', label: 'Robert Lattimore' },
	{ value: '1938535', label: 'Ruchir Agrawal' },
	{ value: '1942663', label: 'Sandra Palacio' },
	{ value: '1978884', label: 'Sarah Leachman' },
	{ value: '2186803', label: 'Shawn Lin' },
	{ value: '2096412', label: 'Sheryl DiBella ' },
	{ value: '2061519', label: 'SHERYL GREEN' },
	{ value: '2163325', label: 'Shkeera Long' },
	{ value: '361629', label: 'Shredderall' },
	{ value: '1927282', label: 'Small Town Cultures' },
	{ value: '2148639', label: 'Spencer Gray' },
	{ value: '1993786', label: 'Tammy Pendergast' },
	{ value: '2179053', label: 'TARA CROOKS' },
	{ value: '2875406', label: 'Thomas Dunne' },
	{ value: '275118', label: 'Valet Seller' },
	{ value: '2378014', label: 'Victoria Van Vliet' },
	{ value: '2026876', label: 'Virginia Arena' },
	{ value: '2100977', label: 'wesam yaakoub' },
	{ value: '2163296', label: 'Willard Hammon' },
	{ value: '2319105', label: 'Ying Cao' },
	{ value: '2817626', label: 'Zachary and Madison Nass' },
	{ value: '2817970', label: 'Zachary and Madison Nass' },
	{ value: '2061397', label: 'Zandra Washington' },
	{ value: '1903045', label: 'Ziad' },
];

const confirmList = [
	{ label: 'No Confirmation', value: 'none' },
	{ label: 'Delivery', value: 'delivery' },
	{ label: 'Signature', value: 'signature' },
	{ label: 'Adult Signature', value: 'adult_signature' },
];

const insuranceList = [
	{ label: 'None', value: 0 },
	{ label: 'Shipsurance Discount Insurance', value: 1 },
	{ label: 'Carrier Insurance', value: 2 },
	{ label: 'Other (external)', value: 3 },
];

const carrierList = [
	{ value: 'stamps_com', label: 'Stamps.com' },
	{ value: 'ups', label: 'UPS' },
	{ value: 'fedex', label: 'FedEx' },
	{ value: 'dhl_express', label: 'DHL Express' },
	{ value: 'amazon_shipping', label: 'Amazon Buy Shipping' },
	{ value: 'ups_walleted', label: 'UPS from ShipStation' },
];

class MarketingCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			print: null,
			batch: null,
			selectConfirm: { label: 'No Confirmation', value: 'none' },
			selectInsurance: { label: 'None', value: 0 },
			shippingFrom: { value: '275118', label: 'Valet Seller' },
			selectService: null,
			selectCarrier: null,
			selectPackage: null,

			weight: {
				value: 0,
				units: 'ounces',
				WeightUnits: 0,
			},
			printType: [ { value: 'print', label: 'Print' } ],
			batchType: [ { value: 'batch', label: 'Add a batch' } ],
			shippedOrders: '',
		};
	}

	componentDidMount() {
		console.log('shippingid=====', this.props.match.params.id);
		if (this.props.match.params.id) {
			this.getShippedOrder(this.props.match.params.id);
		}
	}

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.getShippedOrder(this.state.searchOrders);
		}
	};

	seacrhProduct() {
		this.getShippedOrder(this.state.searchOrders);
	}

	getShippedOrder(searchOrders) {
		this.setState({ isLoading: true });
		api
			.getApi('shipstation/getOrders?orderNumber=' + searchOrders)
			.then((resdata) => {
				let shippedOrders = '';

				if (resdata.data && resdata.data != null) {
					shippedOrders = resdata.data;
					if (shippedOrders.orderStatus == 'awaiting_shipment') {
						shippedOrders.orderStatus = 'Awaiting Shipment';
					} else if (shippedOrders.orderStatus == 'shipped') {
						shippedOrders.orderStatus = 'Shipped';
					} else if (shippedOrders.orderStatus == 'awaiting_payment') {
						shippedOrders.orderStatus = 'Awaiting Payment';
					} else if (shippedOrders.orderStatus == 'pending_fulfillment') {
						shippedOrders.orderStatus = 'Pending Fulfillment';
					} else if (shippedOrders.orderStatus == 'on_hold') {
						shippedOrders.orderStatus = 'On Hold';
					} else if (shippedOrders.orderStatus == 'cancelled') {
						shippedOrders.orderStatus = 'Cancelled';
					}

					if (shippedOrders.shipTo) {
						shippedOrders.shipTo.street1 = shippedOrders.shipTo.street1;
						if (
							shippedOrders.shipTo.street2 != null &&
							shippedOrders.shipTo.street2 != ''
						) {
							shippedOrders.shipTo.street1 =
								shippedOrders.shipTo.street1 +
								' ' +
								shippedOrders.shipTo.street2;
						}
						if (
							shippedOrders.shipTo.street3 != null &&
							shippedOrders.shipTo.street3 != ''
						) {
							shippedOrders.shipTo.street1 =
								shippedOrders.shipTo.street1 +
								' ' +
								shippedOrders.shipTo.street3;
						}
						if (
							shippedOrders.shipTo.state != null &&
							shippedOrders.shipTo.state != ''
						) {
							shippedOrders.shipTo.city =
								shippedOrders.shipTo.city + ' ' + shippedOrders.shipTo.state;
						}
						if (
							shippedOrders.shipTo.postalCode != null &&
							shippedOrders.shipTo.postalCode != ''
						) {
							shippedOrders.shipTo.city =
								shippedOrders.shipTo.city +
								' ' +
								shippedOrders.shipTo.postalCode;
						}
						if (
							shippedOrders.shipTo.country != null &&
							shippedOrders.shipTo.country != ''
						) {
							shippedOrders.shipTo.city =
								shippedOrders.shipTo.city + ' ' + shippedOrders.shipTo.country;
						}
					}
				}

				console.log('shippedOrders===', resdata, shippedOrders);
				this.setState({ isLoading: false, shippedOrders: shippedOrders });
			});
	}

	handleprintChange = (print) => {
		//this.setState({ print: print })
		this.openPdf();
	};

	handlebatchChange = (batch) => {
		this.setState({ batch: batch });
	};

	handleShippingChange = (shippingFrom) => {
		this.setState({ shippingFrom: shippingFrom });
	};

	handleCarriersChange = (selectCarrier) => {
		this.setState({ selectCarrier: selectCarrier });
	};

	handlePackageChange = (selectPackage) => {
		this.setState({ selectPackage: selectPackage });
	};

	handleServiceChange = (selectService) => {
		this.setState({ selectService: selectService });
	};

	handleConfirmChange = (selectConfirm) => {
		this.setState({ selectConfirm: selectConfirm });
	};

	handleInsuranceChange = (selectInsurance) => {
		this.setState({ selectInsurance: selectInsurance });
	};
	async openPdf() {
		const { shippedOrders } = this.state;
		const blob = await pdf(
			<Document onLoadSuccess={this.onDocumentLoad}>
				<Page style={styles.body}>
					<Text style={styles.header_top}>Packing Slip</Text>
					<View style={styles.header}>
						<View style={styles.headersection}>
							<View style={styles.leftheadersection}>
								<View style={styles.rightinnerheadersection}>
									<Text style={styles.heading_head}>Valet Seller</Text>
									<Text style={styles.heading}>320 N Washington St</Text>
									<Text style={styles.heading}>Suite 100</Text>
									<Text style={styles.heading}>Rochester, NY 14625</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.headersection}>
						<View style={styles.leftheadersectionbox}>
							<View style={styles.border_cover}>
								<View style={styles.border_div}>
									<Text style={styles.border_left}>Ship To:</Text>
									<Text style={styles.border_right}>
										{shippedOrders.shipTo.name}
									</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left} />
									<Text style={styles.border_right}>YOUNG KING MAFIA</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left} />
									<Text style={styles.border_right}>PRODUCTIONS</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left} />
									<Text style={styles.border_right}>
										{shippedOrders.shipTo.street1}
									</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left} />
									<Text style={styles.border_right}>
										{shippedOrders.shipTo.city}
									</Text>
								</View>
							</View>
						</View>

						<View style={styles.rightheadersectionbox}>
							<View style={styles.border_cover}>
								<View style={styles.border_div}>
									<Text style={styles.border_left}>Order #</Text>
									<Text style={styles.border_right1}>
										: {shippedOrders.orderId}
									</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left}>Date</Text>
									<Text style={styles.border_right1}>
										: {shippedOrders.paymentDate}
									</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left}>User</Text>
									<Text style={styles.border_right1}>
										: {shippedOrders.customerEmail}
									</Text>
								</View>
								<View style={styles.border_div}>
									<Text style={styles.border_left}>Ship Date</Text>
									<Text style={styles.border_right1}>
										: {shippedOrders.shipDate}
									</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={styles.headerTable}>
						<View style={styles.headerTableTd}>
							<Text style={styles.headerTableTdtext}>Item</Text>
						</View>
						<View style={styles.headerTableTd1}>
							<Text style={styles.headerTableTdtext}>Description</Text>
						</View>
						<View style={styles.headerTableTd}>
							<Text style={styles.headerTableTdtext}>Price</Text>
						</View>
						<View style={styles.headerTableTd}>
							<Text style={styles.headerTableTdtext}>Qty</Text>
						</View>
					</View>

					{shippedOrders != '' &&
						shippedOrders.items.map((item, index) => (
							<View style={styles.bodyTable} key={index}>
								<View style={styles.bodyTableTd}>
									<Text style={styles.headerTableTdtext}>{index + 1}</Text>
								</View>
								<View style={styles.bodyTableTd1}>
									<Text style={styles.headerTableTdtext}>{item.name}</Text>
								</View>
								<View style={styles.bodyTableTd}>
									<Text style={styles.headerTableTdtext}>
										${item.unitPrice}
									</Text>
								</View>
								<View style={styles.bodyTableTd}>
									<Text style={styles.headerTableTdtext}>{item.quantity}</Text>
								</View>
							</View>
						))}

					<View style={styles.headersection}>
						<View style={styles.rightheadersection}>
							<View style={styles.subtotal_div}>
								<Text style={styles.sub_total}>Subtotal:</Text>
								<Text style={styles.sub_total}>
									${shippedOrders.orderTotal}
								</Text>
							</View>
							<View style={styles.subtotal_div}>
								<Text style={styles.sub_total}>Shipping:</Text>
								<Text style={styles.sub_total}>
									${shippedOrders.shippingAmount}
								</Text>
							</View>
							<View style={styles.subtotal_div}>
								<Text style={styles.sub_total}>TOTAL</Text>
								<Text style={styles.sub_total}>
									${Number(shippedOrders.orderTotal) +
										Number(shippedOrders.shippingAmount) +
										Number(shippedOrders.taxAmount)}
								</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>,
		).toBlob();
		saveAs(blob, 'shipping.pdf');
	}

	render() {
		const { t } = this.props.setOptions;
		const {
			print,
			searchOrders,
			isLoading,
			batch,
			batchType,
			printType,
			selectService,
			selectPackage,
			shippingFrom,
			selectInsurance,
			shippingType,
			shippedOrders,
			selectConfirm,
			selectCarrier,
			serviceList,
			packageList,
		} = this.state;

		if (isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<Row className='tabs_market_div shipping_head'>
					<Col md={12}>
						<Link to='/pages/sales'>
							<button className='back_products'>Back</button>
						</Link>
						<Col md={4} className='search_shipping'>
							{/* <label style={{width:'100%'}}>Search</label> */}
							<Input
								name='name'
								type='text'
								placeholder=''
								value={searchOrders}
								onChange={(event) => {
									this.setState({ searchOrders: event.target.value });
								}}
								onKeyDown={this.handleKeyDown}
							/>
							<Button
								className='search_button'
								onClick={() => {
									this.seacrhProduct();
								}}
							>
								Search
							</Button>
						</Col>
					</Col>
					<Col className='shipping_head_inner'>
						<Col md={2} className='shipping_head_status'>
							<img src='../../img/logo.svg' />
						</Col>
						<Col md={6} className='shipping_head_status'>
							<span className='shipping_head_order'>
								<span>Order:</span>
								{shippedOrders.orderNumber}
							</span>
							<span className='shipping_head_order'>
								<span>Status:</span>
								{shippedOrders.orderStatus}
							</span>
						</Col>
					</Col>

					<Col md={12} className='shipping_head_second'>
						{shippedOrders == '' && (
							<p className='nodatafound'>No Order Found</p>
						)}
						{shippedOrders != '' && (
							<Col md={12}>
								<Col md={2} className='shipping_head_select'>
									<Select
										placeholder='Print'
										value={print}
										onChange={this.handleprintChange}
										options={printType}
									/>
								</Col>

								{/* <Col md={2} className="shipping_head_select">
                <Select placeholder="Add to batch" value={batch} onChange={this.handlebatchChange} options={batchType} />
              </Col>
              <Col md={2} className="shipping_head_select">
                <button>Create Return</button>
              </Col> */}
							</Col>
						)}

						{/* <Col md={12}>
              <Col md={12} className="search_shipping">
                <Input type="text"/>
              </Col>
            </Col> */}

						{shippedOrders != '' && (
							<Col md={12} style={{ float: 'left' }}>
								<Col md={6} style={{ float: 'left' }}>
									<Col md={12} className='order_summary'>
										<Col md={12} className='order_summary_inner'>
											<h2>Order Summary </h2>
											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/date.png' />
												</span>
												<span className='id'>Order ID:</span>
												<span>{shippedOrders.orderId}</span>
											</Col>

											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/total.png' />
												</span>
												<span className='id'>Product Total:</span>
												<span>${shippedOrders.orderTotal}</span>
											</Col>

											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/id.png' />
												</span>
												<span className='id'>Order Date:</span>
												<span>
													{helper.getDateFormat(shippedOrders.orderDate)}
												</span>
											</Col>

											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/total.png' />
												</span>
												<span className='id'>Shipping Paid:</span>
												<span>${shippedOrders.shippingAmount}</span>
											</Col>

											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/date.png' />
												</span>
												<span className='id'>Paid Date:</span>
												<span>
													{helper.getDateFormat(shippedOrders.paymentDate)}
												</span>
											</Col>

											<Col md={6} className='order_summary_inner_div'>
												<span>
													<img src='../../img/total.png' />
												</span>
												<span className='id'>Tax Paid:</span>
												<span>${shippedOrders.taxAmount}</span>
											</Col>

											<Col
												md={12}
												className='order_summary_inner_div bottom_summary'
											>
												<span>
													<img src='../../img/email.png' />
												</span>
												<span className='id'>Total Paid:</span>
												<span>
													${(Number(shippedOrders.orderTotal) +
														Number(shippedOrders.shippingAmount) +
														Number(shippedOrders.taxAmount)).toFixed(2)}
												</span>
											</Col>
											{/* <Select placeholder="Add Tags..." value={batch} onChange={this.handlebatchChange} options={batchType} /> */}
										</Col>
									</Col>
								</Col>

								{shippedOrders != '' && (
									<Col md={6} style={{ float: 'left' }}>
										<Col md={12} className='order_summary'>
											<Col md={12} className='order_summary_inner Buyer'>
												<h2>Buyer / Receipent Info </h2>
												<Col md={12} className='order_summary_inner_div'>
													<span className='buyer_img'>
														<img src='../../img/date.png' />
													</span>
													<span className='buyer_label'>Sold To:</span>
													<span className='buyer_input'>
														{shippedOrders.billTo.name}
													</span>
													{/* <span className="buyer_input">{shippedOrders.customerEmail}</span>
                      <span className="buyer_input">{shippedOrders.customerUsername}</span> */}
												</Col>

												<Col md={12} className='order_summary_inner_div'>
													<span className='buyer_img'>
														<img src='../../img/total.png' />
													</span>
													<span className='buyer_label'>Ship To:</span>
													<span className='buyer_input'>
														{shippedOrders.shipTo.name}
													</span>
													{shippedOrders.shipTo.street1 != '' && (
														<span className='buyer_input'>
															{shippedOrders.shipTo.street1}
														</span>
													)}
													{/* {shippedOrders.shipTo.city != '' && <span className="buyer_input">{shippedOrders.shipTo.city}</span>} */}
												</Col>

												<Col md={12} className='order_summary_inner_div'>
													<span className='buyer_img'>
														<img src='../../img/email.png' />
													</span>
													<span className='buyer_label'>Email / Phone:</span>
													<span className='buyer_input'>
														{shippedOrders.customerEmail}
													</span>
												</Col>
											</Col>
										</Col>
									</Col>
								)}

								{/*}	<Col md={4} style={{ float: 'left' }}>
									<Col md={12} className='order_summary'>
										<Col md={12} className='order_summary_inner'>
											<h2>
												Shipments
												{/* <button>Edit</button> 
												<button className='ship_btn'>Labels</button>
												<button className='ship_btn'>Docs</button>
												<button className='ship_btn'>Void</button>
												<button className='ship_btn'>Return</button>
												<button className='ship_btn'>Resend</button>
											</h2>
											<Col md={12}>
												<Col md={1}>
													<Input type='checkbox' />
												</Col>
												<Col md={11} className='tablefull'>
													<div className='tablehead'>
														<Col md={4}>Shipping</Col>
														<Col md={4}>Tracking</Col>
														<Col md={4}>Status</Col>
													</div>
													{shippedOrders.orderStatus == 'Shipped' && (
														<div className='tablebody'>
															<Col md={4}>
																{shippedOrders.shipDate} Valet Seller
															</Col>
															<Col md={4}>
																{helper.getFirstUpperCase(
																	shippedOrders.serviceCode,
																)}
															</Col>
															<Col md={4} />
														</div>
													)}
												</Col>
											</Col>
											{/* <Select placeholder="Add Tags..." value={batch} onChange={this.handlebatchChange} options={batchType} /> 
										</Col>
									</Col>
                                </Col> */}
							</Col>
						)}

						{shippedOrders != '' && (
							<Col md={12} className='order_items'>
								<Col md={12} className='order_summary'>
									<Col md={12} className='order_summary_inner'>
										<h2>Order Items</h2>
										<div className='order_header'>
											<span className='order_item'>Item</span>
											<span className='order_unit'>Unit</span>
											<span className='order_qty'>Qty</span>
											<span className='order_total'>Total $</span>
										</div>
										{shippedOrders != '' &&
											shippedOrders.items.map((item, index) => (
												<div className='order_body' key={index}>
													<span className='order_item'>{item.name}</span>
													<span className='order_unit'>${item.unitPrice}</span>
													<span className='order_qty'>{item.quantity}</span>
													<span className='order_total'>
														${item.unitPrice * item.quantity}
													</span>
												</div>
											))}
									</Col>
								</Col>

								<Col md={12} className='order_summary'>
									<Col md={12} className='order_summary_inner'>
										<h2>Order Activity</h2>
										<div className='activity'>
											{shippedOrders.shipDate != '' &&
											shippedOrders.shipDate != null && (
												<Col md={12} className='activity_inner'>
													<Col md={2} xs={3}>
														<span className='activity_date'>
															{helper.getActivityFormat(shippedOrders.shipDate)}
														</span>
														<span className='dot' />
														<span className='line' />
													</Col>
													<Col md={10} xs={9}>
														<span className='dot_header'>Order Shipped</span>
														{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.shipDate)}</span> */}
													</Col>
												</Col>
											)}

											{shippedOrders.paymentDate != '' &&
											shippedOrders.paymentDate != null && (
												<Col md={12} className='activity_inner'>
													<Col md={2} xs={3}>
														<span className='activity_date'>
															{helper.getActivityFormat(
																shippedOrders.paymentDate,
															)}
														</span>
														<span className='dot' />
														<span className='line' />
													</Col>
													<Col md={10} xs={9}>
														<span className='dot_header'>Order Paid</span>
														{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.paymentDate)}</span> */}
													</Col>
												</Col>
											)}

											{shippedOrders.orderDate != '' &&
											shippedOrders.orderDate != null && (
												<Col md={12} className='activity_inner final'>
													<Col md={2} xs={3}>
														<span className='activity_date'>
															{helper.getActivityFormat(
																shippedOrders.orderDate,
															)}
														</span>
														<span className='dot' />
														<span className='line' />
													</Col>
													<Col md={10} xs={9}>
														<span className='dot_header'>Order Created</span>
														{/* <span className="dot_data">{helper.getDateTimeFormat(shippedOrders.orderDate)}</span> */}
													</Col>
												</Col>
											)}
										</div>
									</Col>
								</Col>

								<Col md={12} className='order_summary'>
									<Col md={12} className='order_summary_inner'>
										<h2>Order Notes</h2>
										<div className=''>
											<Col md={12} className='activity_inner'>
												<span className='ordernotes'>
													<span className='activity_date'>Customer:</span>{' '}
													{shippedOrders.customerNotes != '' &&
													shippedOrders.customerNotes != null ? (
														shippedOrders.customerNotes
													) : (
														'None'
													)}
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>To Buyer:</span> None
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>Internel:</span>{' '}
													{shippedOrders.internalNotes != '' &&
													shippedOrders.internalNotes != null ? (
														shippedOrders.internalNotes
													) : (
														'None'
													)}{' '}
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>
														This is a gift:
													</span>{' '}
													{shippedOrders.giftMessage != '' &&
													shippedOrders.giftMessage != null ? (
														shippedOrders.giftMessage
													) : (
														'None'
													)}{' '}
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>
														Custom field 1:
													</span>{' '}
													None
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>
														Custom field 2:
													</span>{' '}
													None
												</span>
												<span className='ordernotes'>
													<span className='activity_date'>
														Custom field 3:
													</span>{' '}
													None
												</span>
											</Col>
										</div>
									</Col>
								</Col>
							</Col>
						)}

						{shippedOrders == 'dummy' && (
							<Col md={4} className='shipping_info'>
								<Col md={12} className='order_summary'>
									<Col md={12} className='order_summary_inner'>
										<h2>Shipping Info</h2>
										<div className='activity'>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Requested</span>
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Shipping From</span>
												</Col>
												<Col md={8}>
													<Select
														value={shippingFrom}
														onChange={this.handleShippingChange}
														options={shippingList}
													/>
												</Col>
											</Col>
											<Col md={12} className='activity_inner weight'>
												<Col md={4}>
													<span className='activity_date'>Weight</span>
												</Col>
												<Col md={8}>
													<Input type='number' className='weightinput' />
													<span>LB</span>
													<Input type='number' className='weightinput' />
													<span>OZ</span>
													{/* <Select value={shippingFrom} onChange={this.handleShippingChange} options={shippingType} style={{width:'26%'}}/>  */}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Carriers</span>
												</Col>
												<Col md={8}>
													<Select
														value={selectCarrier}
														onChange={this.handleCarriersChange}
														options={carrierList}
													/>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Service</span>
												</Col>
												<Col md={8}>
													<Select
														value={selectService}
														onChange={this.handleServiceChange}
														options={serviceList}
													/>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Package</span>
												</Col>
												<Col md={8}>
													<Select
														value={selectPackage}
														onChange={this.handlePackageChange}
														options={packageList}
													/>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Size</span>
												</Col>
												<Col md={8} className='shippingsize'>
													<Input
														type='number'
														placeholder='L'
														style={{ width: 'calc(53% - 60px)' }}
													/>
													<span
														style={{
															float: 'left',
															fontSize: 15,
															marginTop: 6,
														}}
													>
														*
													</span>
													<Input
														type='number'
														placeholder='W'
														style={{ width: 'calc(53% - 60px)' }}
													/>
													<span
														style={{
															float: 'left',
															fontSize: 15,
															marginTop: 6,
														}}
													>
														*
													</span>
													<Input
														type='number'
														placeholder='H'
														style={{ width: 'calc(53% - 60px)' }}
													/>
													<span
														style={{
															float: 'left',
															fontSize: 15,
															marginTop: 6,
														}}
													>
														(in)
													</span>
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Confirm</span>
												</Col>
												<Col md={8}>
													<Select
														value={selectConfirm}
														onChange={this.handleConfirmChange}
														options={confirmList}
													/>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Insurance</span>
												</Col>
												<Col md={8}>
													<Select
														value={selectInsurance}
														onChange={this.handleInsuranceChange}
														options={insuranceList}
													/>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Rate</span>
												</Col>
												<Col md={8}>
													<span className='activity_date'>$2.78</span>{' '}
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={4}>
													<span className='activity_date'>Delivery Time</span>
												</Col>
												<Col md={8}>
													<span className='activity_date'>3 days</span>
												</Col>
											</Col>
											<Col md={12} className='activity_inner'>
												<Col md={12}>
													<button>Create Label</button>
												</Col>
											</Col>
										</div>
									</Col>
								</Col>

								{/* <Col md={12} className="order_summary">
              <Col md={12} className="order_summary_inner">
                <h2>Other Shipping</h2>
                <div className="activity othershipping">
                  <Col md={12} className="activity_inner">
                    <Col md={4}><span className="activity_date">Ship From</span></Col>
                    <Col md={8}><Select value={shippingFrom} onChange={this.handleShippingChange} options={shippingType} /> </Col>
                  </Col>
                  <Col md={12} className="activity_inner1">
                    <Input type="checkbox"/><span>Print postgate on the shipping label for this order</span>
                  </Col>
                  <Col md={12} className="activity_inner1">
                    <Input type="checkbox"/><span>This order is non-machinable</span>
                  </Col>
                  <Col md={12} className="activity_inner1">
                    <Input type="checkbox"/><span>Do not notify marketplace when shipped</span>
                  </Col>
                  <Col md={12} className="activity_inner1">
                    <Input type="checkbox"/><span>Include a return label with the outgoing shipping label</span>
                  </Col>
                
                </div>
              </Col> 
            </Col>  */}
							</Col>
						)}
					</Col>

					{/* {shippedOrders==''&&<span className="comming_soon">No Data</span>} */}
				</Row>
			);
		}
	}
}
const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(MarketingCard);

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	header_top: {
		fontSize: 14,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
		marginTop: 20,
	},
	watermark: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	watermarkpending: {
		color: '#000',
		fontSize: 80,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		transform: 'rotate(-45deg)',
		userSelect: 'none',
		opacity: 0.2,
	},
	watermarkrejected: {
		color: '#000',
		fontSize: 80,
		fontWeight: 'bold',
		textTransform: 'uppercase',
		transform: 'rotate(-45deg)',
		userSelect: 'none',
		opacity: 0.2,
	},

	page: {
		flexDirection: 'row',
		backgroundColor: '#fff',
		padding: 20,
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	headersection: {
		flexDirection: 'row',
		width: '100%',
	},
	leftheadersection: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	image: {
		objectFit: 'cover',
	},
	leftinnerheadersection: {
		width: 80,
		height: 40,
		backgroundColor: '#ccc',
	},
	heading: {
		fontSize: 14,
		fontWeight: 'bold',
		paddingTop: 10,
		textAlign: 'center',
	},
	heading_head: {
		fontSize: 16,
		fontWeight: 'bold',
	},
	sub_heading: {
		fontSize: 10,
		paddingTop: 10,
		fontWeight: 200,
	},
	sub_sub_heading: {
		fontSize: 10,
		paddingTop: 10,
		fontWeight: 200,
		marginBottom: 10,
	},
	bars: {
		width: '100%',
		height: 2,
		backgroundColor: '#000',
		marginTop: 1,
	},
	rightheadersection: {
		width: '50%',
	},
	rightheadersectionbox: {
		width: '50%',
		marginTop: 5,
	},
	header: {},
	leftheadersection: {
		width: '50%',
	},
	leftheadersectionbox: {
		width: '50%',
		marginTop: 5,
	},
	bars_div: {
		marginTop: 10,
	},
	right_text: {
		fontSize: 18,
		paddingLeft: 10,
		fontWeight: 'bold',
	},
	right_web: {
		paddingLeft: 35,
		fontSize: 12,
		paddingTop: 20,
	},
	border_cover: {
		padding: 15,
		height: 170,
	},
	border_div: {
		flexDirection: 'row',
		marginTop: 2,
		marginBottom: 2,
	},
	border_left: {
		width: 70,
		fontSize: 12,
		textAlign: 'right',
	},
	border_left1: {
		width: 120,
		fontSize: 12,
	},
	border_right: {
		fontSize: 10,
		marginRight: 50,
	},

	border_right1: {
		fontSize: 10,
		marginRight: 50,
		marginTop: 1.5,
	},
	bottomBox: {
		flexDirection: 'row',
	},
	bottomBoxText: {
		fontSize: 12,
		padding: 5,
	},
	headerTable: {
		width: '100%',
		flexDirection: 'row',
		backgroundColor: '#ccc',
		marginTop: 10,
	},
	bodyTable: {
		width: '100%',
		flexDirection: 'row',
	},
	headerTableTd: {
		width: '10%',
		fontSize: 12,
		elevation: 5,
		textAlign: 'center',
		justifyContent: 'center',
		paddingTop: 3,
		paddingBottom: 3,
	},
	bodyTableTd: {
		width: '10%',
		fontSize: 10,
		textAlign: 'center',
		justifyContent: 'center',
		paddingTop: 3,
		paddingBottom: 3,
	},

	headerDescriptionTd: {
		width: '25%',
		fontSize: 12,
		margin: 2,
	},
	headerCostcenterTd: {
		width: '15%',
		fontSize: 12,
		margin: 2,
	},
	headerTableTdtext: {
		textAlign: 'center',
	},
	bodyDescriptionTd: {
		width: '25%',
		fontSize: 10,
		margin: 2,
	},
	bodyCostcenterTd: {
		width: '15%',
		fontSize: 10,
		margin: 2,
	},
	subtotal_div: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	sub_total: {
		fontSize: 14,
		marginTop: 10,
		paddingLeft: 15,
		paddingRight: 15,
	},
	bottom: {
		width: '100%',
		marginTop: 10,
		marginBottom: 20,
	},
	footer: {
		position: 'absolute',
		bottom: 5,
		left: 35,
		width: '100%',
	},
	footer_blacktext: {
		fontSize: 10,
		width: '100%',
	},
	footer_redtext: {
		fontSize: 10,
		color: 'red',
		width: '100%',
	},

	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	header: {
		fontSize: 12,
		marginBottom: 20,
		textAlign: 'center',
		color: 'grey',
	},

	page: {
		flexDirection: 'row',
		backgroundColor: '#fff',
		padding: 20,
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	headersection: {
		flexDirection: 'row',
		width: '100%',
	},
	headersections: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
	},
	leftheadersection: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	image: {
		objectFit: 'cover',
	},
	leftinnerheadersection: {
		width: 80,
		height: 40,
		backgroundColor: '#ccc',
	},
	heading: {
		fontSize: 12,
		fontWeight: 'bold',
	},
	sub_heading: {
		fontSize: 10,
		paddingTop: 10,
		fontWeight: 200,
	},
	sub_sub_heading: {
		fontSize: 10,
		paddingTop: 10,
		fontWeight: 200,
		marginBottom: 10,
	},
	bars: {
		width: '100%',
		height: 2,
		backgroundColor: '#000',
		marginTop: 1,
	},
	rightheadersection: {
		width: '50%',
	},
	rightheadersectionbox: {
		width: '49%',
		marginLeft: '1%',
		marginTop: 5,
	},
	header: {
		marginTop: 20,
		marginBottom: 20,
	},
	leftheadersection_div: {
		flexDirection: 'row',
	},
	leftheadersection: {
		width: '50%',
	},
	leftheadersectionbox: {
		width: '49%',
		marginRight: '1%',
		marginTop: 5,
	},
	bars_div: {
		marginTop: 10,
	},
	right_text: {
		fontSize: 18,
		paddingLeft: 10,
		fontWeight: 'bold',
	},
	right_web: {
		paddingLeft: 35,
		fontSize: 12,
		paddingTop: 20,
	},
	border_cover: {
		padding: 15,
		height: 170,
	},
	border_cover1: {
		padding: 15,
	},
	border_div: {
		flexDirection: 'row',
		marginTop: 2,
		marginBottom: 2,
	},
	border_divs: {
		flexDirection: 'column',
		marginTop: 2,
		marginBottom: 2,
		width: '44%',
		marginLeft: '3%',
		marginRight: '3%',
	},
	border_left: {
		width: 70,
		fontSize: 12,
	},
	border_left1: {
		width: 120,
		fontSize: 12,
	},
	border_left11: {
		width: '100%',
		fontSize: 12,
		textAlign: 'center',
	},
	border_left2: {
		width: 120,
		fontSize: 12,
	},
	border_left_caps: {
		textTransform: 'uppercase',
		fontSize: 12,
		width: 120,
	},
	border_right: {
		fontSize: 10,
		marginRight: 50,
	},

	border_right1: {
		fontSize: 10,
		marginRight: 50,
		marginTop: 1.5,
		marginRight: 20,
		width: 150,
	},
	headerTableTd1: {
		width: '70%',
		fontSize: 10,
		elevation: 5,
		textAlign: 'center',
		justifyContent: 'center',
		paddingTop: 3,
		paddingBottom: 3,
	},
	bodyTableTd1: {
		width: '70%',
		fontSize: 10,
		textAlign: 'center',
		justifyContent: 'center',
		paddingTop: 3,
		paddingBottom: 3,
	},
	headerTableTdtext: {
		textAlign: 'center',
		fontSize: 10,
	},
	bottom1: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 50,
	},
	bottom2: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 50,
	},
});
