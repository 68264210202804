import React, { useState, useEffect } from 'react'
import { Grid, Input, Select } from 'react-spreadsheet-grid';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Tooltip from "react-simple-tooltip"
import { marketPlaceModal } from '../../../utils/validationSchemas';

const MarketplaceList = [
    { id: 1, name: 'eBay' },
    { id: 2, name: 'ebay' },
    { id: 3, name: 'Ebay' },
    { id: 4, name: 'Walmart' },
    { id: 5, name: 'Wish' },
    { id: 6, name: 'Bonanza' },
    { id: 7, name: 'Amazon Canada' },
    { id: 8, name: 'Walmart Canada' },
    { id: 9, name: 'Sears' },
    { id: 10, name: 'Valet Shops' },
    { id: 11, name: 'Amazon' },
    { id: 12, name: 'Kroger' },
    { id: 13, name: 'amazon.ae' },
    { id: 14, name: 'amazon.sg' },
    { id: 15, name: 'Shopify' },
    { id: 16, name: 'kroger' },
    { id: 17, name: 'sears' },
    { id: 18, name: 'walmart canada' },
    { id: 19, name: 'Walmart canada' },
    { id: 20, name: 'Amazon canada' },
    { id: 21, name: 'amazon canada' },
    { id: 22, name: 'Valet shops' },
    { id: 23, name: 'valet shops' },
    { id: 24, name: 'Bed Bath and Beyond' },
    { id: 25, name: 'Wayfair' },
];
const MarketPlaceValidator = (props) => {
    const [rows, setRows] = useState(props.marketplacedata || []);
    const { setCSVData } = props;
    const onFieldChange = (rowId, field) => (value) => {
        let index;
        const rowsCopy = [...rows];
        const row = rowsCopy.find(({ _id }, i) => {
            if (_id === rowId) index = i;
            return _id === rowId;
        });
        if (field === 'marketplace') value = MarketplaceList.find(({ id }) => id === value).name;
        _.set(row, field, value);
        rowsCopy[index] = row;
        setRows(rowsCopy);
        setCSVData(rowsCopy);
    };

    const validatedMarketplaces = rows.map((marketPlaces) => {
        let validation = {};
        validation = marketPlaceModal.check({
            ...marketPlaces,
            sku: String(marketPlaces),
        });

        const isValid = !Object.keys(validation).map((key) => {
            return validation[key].hasError;
        }).includes(true);

        return {
            ...marketPlaces,
            validation,
            isValid,
        }
    });

    useEffect(() => {
        setRows((props.marketplacedata || []).map((marketplace, index) => ({
            ...marketplace,
            _id: index,
        })));
    }, [props.marketplacedata]);

    const RenderCustomCell = ({ hasError, children, errorMessage }) => (
        hasError ? (
            <Tooltip
                placement="bottom"
                content={errorMessage}
            >
                {children}
            </Tooltip>
        ) : children
    );

    return (
        <div>
            <Link to='/pages/products'>
                <button className='back_products'>Back</button>
            </Link>
            <Grid
                columns={[
                    {
                        title: () => 'SKU',
                        getCellClassName: (row) => {
                            if (row.validation && row.validation.sku && row.validation.sku.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.validation && row.validation.sku && row.validation.sku.hasError}
                                    errorMessage={row.validation && row.validation.sku && row.validation.sku.errorMessage}
                                >
                                    <Input
                                        value={row.sku}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'sku')}
                                        placeholder="SKU"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Marketplace',
                        getCellClassName: (row) => {
                            if (row.validation && row.validation.marketplace && row.validation.marketplace.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            const marketplace = MarketplaceList.find(({ name }) => name === row.marketplace);
                            return (
                                <RenderCustomCell
                                    hasError={row.validation && row.validation.marketplace && row.validation.marketplace.hasError}
                                    errorMessage={row.validation && row.validation.marketplace && row.validation.marketplace.errorMessage}
                                >
                                    <Select
                                        selectedId={marketplace && marketplace.id}
                                        isOpen={focus}
                                        items={MarketplaceList}
                                        placeholder="Marketplace"
                                        onChange={onFieldChange(row._id, 'marketplace')}
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Link',
                        getCellClassName: (row) => {
                            if (row.validation && row.validation.link && row.validation.link.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.validation && row.validation.link && row.validation.link.hasError}
                                    errorMessage={row.validation && row.validation.link && row.validation.link.errorMessage}
                                >
                                    <Input
                                        value={row.link}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'link')}
                                        placeholder="Link"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                ]}
                rows={validatedMarketplaces}
                getRowKey={row => row._id}
            />
        </div>
    )
}

export default MarketPlaceValidator;
