import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SystemRolesCard from './components/SystemRolesCard';
import RolestabsCard from './components/RolestabsCard';
import ModalCard from './components/ModalCard';
import helper from '../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

class RolesPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false,
      isLoading:true,
      roleList:[],
      selectRoleId:'',
      items:[],
      tempItems:[],
    };
    
  }

  componentDidMount() {

    if(this.props.list_role==undefined){
      this.setState({isLoading:true})
    }else{
      this.setState({isLoading:false,roleList:this.props.list_role})
    }
    
    this.props.listRoleRecord();
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps=====',nextProps);
    if(nextProps.list_role){
      this.setState({isLoading:false,roleList:nextProps.list_role})
    }
  }

  render() {
    console.log('selectRoleId',this.state.roleList);
    const {t} = this.props.setOptions
    return (
      <div className="container-div">
        {this.state.isLoading==true&&<div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>}
        {this.state.isLoading==false&&<Container className="roles">
          <Row>
            <Col md={12}>
              <h3 className="page-title">{t('ROLES')}</h3>
            </Col>
          </Row>
          <Row>
            {/* <SystemRolesCard {...this.props} openRole={this.openRole}  deleteRole={this.deleteRole} selectRole={this.selectRole}  roleList={this.state.roleList} selectRoleId={this.state.selectRoleId}/> */}
            <RolestabsCard {...this.props} items={this.state.roleList}/>
            {this.state.isModal&&<ModalCard {...this.props} closeRole={this.closeRole} createRole={this.createRole}/>}
          </Row>
        </Container>}
    </div>
    )
  }
}

const mapStateToProps = ({ curdReducer,roleReducer }) => {
  const {list_role} = roleReducer;
  const {list_user} = curdReducer;
  return {list_role,list_user};
};

export default connect(mapStateToProps, actions)(RolesPage);
