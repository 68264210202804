import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import {
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	Button,
	Input,
	FormGroup,
	Label,
	Row,
} from 'reactstrap';
import AdvertisingBudget from './advertisingBugdet';
import PurchasedUpcs from './purchasedUpcs';
import ListOptimization from './listOptimization';
import Photography from './photography';
import EnhancedBrandContent from './enhancedBrandContent';
import ProfessionalStorefronDesign from './professionalStorefronDesign';
import ConsultingSessionHistory from './consultingSessionHistory';
import BrandMonitoringHistory from './BrandMonitoringHistory';
import api from '../../../utils/api';
import TableList from '../../Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';
import firebase1 from '../../../utils/config';

const discountList = [
	{ value: '20', label: '20%' },
	{ value: '25', label: '25%' },
	{ value: '30', label: '30%' },
	{ value: '35', label: '35%' },
	{ value: '40', label: '40%' },
	{ value: '45', label: '45%' },
	{ value: '50', label: '50%' },
	{ value: '55', label: '55%' },
	{ value: '60', label: '60%' },
	{ value: '65', label: '65%' },
	{ value: '70', label: '70%' },
	{ value: '-1', label: "Don't Participate in Discounts" },
];

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: '-28%',
		bottom: '-37%',
		width: '50%',
		height: '390px',
		textAlign: 'center',
		transform: 'translate(-50%, -50%)',
		zindex: '9999',
		overflow: 'hidden',
	},
};
class NewMarketingCard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			searchText: '',
			name: 'companies',
			items: [],
			tempItems: [],
			purchaseSku: [],
			selectDiscount: null,
			role: '',
			modalIsOpen: false,
			totalPrice: 0,
			pageSize: 25,
			searchSkuText: '',
			professionalBrand: false,
			tabIndex: 0,
			listingOptimizationPrice: 99,
			reviewPrice: 60,
			enhancedBrandPrice: 200,
			purchaseSkuHeaders: [
				{ key: 'CreatedAt', title: 'Purchased Date' },
				{ key: 'Sku', title: 'Sku' },
			],
			headers: [
				{ key: 'firstname', title: 'Name' },
				{ key: 'email', title: 'Email' },
				{ key: 'discount', title: 'Discount Level' },
			],
			skuHeaders: [
				{ key: 'Sku', title: 'Sku' },
				{ key: 'Name', title: 'Product Title' },
				{ key: 'price', title: 'Price' },
				{ key: 'add_cart', title: 'Action' },
			],
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.getProductData = this.getProductData.bind(this);
		this.addToCart = this.addToCart.bind(this);
		this.removeCart = this.removeCart.bind(this);
		this.getPurchaseSku = this.getPurchaseSku.bind(this);
	}

	componentDidMount() {
		//  console.log('user=====',this.props.userdetails);
		const { user, userdetails } = this.state;
		if (this.props.userdetails) {
			this.updateUser(this.props.userdetails);
		}
		//console.log('this.props.userdetails', this.props.userdetails)
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.userdetails != undefined &&
			nextProps.userdetails != this.props.userdetails
		) {
			this.updateUser(nextProps.userdetails);
		}
	}

	updateUser(user) {
		//console.log('user==2323232332323=', user);
		let subscription_id = '';
		let professionalBrand = false;

		if (user.paypal_billing && user.paypal_billing.subscription_id) {
			subscription_id = user.paypal_billing.subscription_id;
		} else {
			subscription_id = 'none';
		}
		if (user.professional_brand) {
			professionalBrand = user.professional_brand.active;
		}
		this.setState(
			{
				isLoading: false,
				key: user.key,
				firstname: user.firstname,
				company: user.company,
				email: user.email,
				role: user.role.label,
				Uid: user.uid,
				subscription_id: subscription_id,
				professionalBrand: professionalBrand,
				selectDiscount: user.discount ? user.discount : null,
			},
			() => {
				if (user.role.label == 'admin') {
					this.getUser();
					this.getPurchaseSku(1, this.state.pageSize);
				}
			},
		);
	}

	async getUser() {
		let apiPath = '';

		apiPath = 'user/marketuserlist';
		let reqData = {
			Pagination: {
				ResultsPerPage: 25,
				PageNumber: 1,
			},
		};
		this.setState({ isLoading: true });
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				for (let item in items) {
					if (items[item].discount) {
						items[item].discount = Number(items[item].discount.value);
						if (items[item].discount == -1) {
							items[item].discount = "Don't Participate in Discounts";
						}
					} else {
						items[item].discount = 0;
					}
				}
				this.setState({ isLoading: false, items: items, tempItems: items });
			}
		});
	}

	async getPurchaseSku(page, pageSize) {
		let apiPath = '';
		apiPath = 'market/purchaseskulist';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};

		if (this.state.searchSkuText.length > 0) {
			reqData.sku = this.state.searchSkuText;
		}
		if (this.state.role != 'admin') {
			reqData.Uid = this.state.Uid;
		}

		this.setState({ tableLoading: true, pageSize: pageSize });
		api.postApi(apiPath, reqData).then((data) => {
			if (data.status == 'Success') {
				let items = data.data;
				//  console.log('getPurchaseSkuitems===',items);
				this.setState({
					tableLoading: false,
					purchaseSkuitems: items,
					purchaseSkutempItems: items,
				});
			}
		});
	}

	async updateDiscount() {
		const { selectDiscount, firstname, company, email } = this.state;
		if (selectDiscount == null) {
			helper.errorMessage('please select discount');
		} else {
			this.setState({ isLoading: true });
			await this.props.updateRecord(
				'user',
				{ discount: selectDiscount },
				this.state.key,
				false,
			);
			let apiPath = '';
			apiPath = 'mail/onDiscountDetails';
			let reqData = {
				firstname: firstname,
				company: company,
				email: email,
				discount: selectDiscount.label,
			};

			api.postApi(apiPath, reqData).then((data) => {
				console.log(data);
				if (data.status == 'Success') {
				}
				this.setState({ isLoading: false });
			});
		}
	}

	handleDiscountChange = (selectDiscount) => {
		this.setState({ selectDiscount });
	};

	viewData = () => {
		window.open('http://www.valetseller.com/advertising-packages/');
	};

	actionSearch() {
		if (this.state.searchText.length > 0) {
			let filterItems = [];
			let items = this.state.tempItems;
			items.map((item, index) => {
				if (this.headerFilter(this.state.searchText, item)) {
					filterItems.push(item);
				}
			});
			this.setState({ items: filterItems });
		}
	}

	searchSkuKey(text) {
		this.setState(
			{
				searchSkuText: text,
			},
			() => {
				if (text.length == 0) {
					this.getPurchaseSku(1, this.state.pageSize);
				}
			},
		);
	}

	actionSkuSearch() {
		if (this.state.searchSkuText.length > 0) {
			this.getPurchaseSku(1, this.state.pageSize);
		}
	}

	headerFilter(filterText, item) {
		let isFilter = false;
		for (let data in this.state.headers) {
			if (
				item[this.state.headers[data].key] &&
				item[this.state.headers[data].key].label &&
				String(item[this.state.headers[data].key].label)
					.toLowerCase()
					.indexOf(filterText.toLowerCase()) !== -1
			) {
				isFilter = true;
			} else if (
				String(item[this.state.headers[data].key])
					.toLowerCase()
					.indexOf(filterText.toLowerCase()) !== -1
			) {
				isFilter = true;
			}
		}
		return isFilter;
	}

	searchKey(text) {
		this.setState(
			{
				searchText: text,
			},
			() => {
				if (text.length == 0) {
					this.setState({ items: this.state.tempItems });
				}
			},
		);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	getProductData(page, pageSize) {
		console.log('page===', page);
		this.setState({ tableLoading: true, pageSize: pageSize });
		this.productCount = 0;
		let apiPath = '';
		let purchaseSku = this.state.purchaseSku;
		console.log('purchaseSku===', purchaseSku);
		apiPath = 'inventory/getProducts';
		let reqData = {
			Pagination: {
				ResultsPerPage: pageSize,
				PageNumber: page,
			},
		};
		if (this.state.searchText.length > 0) {
			reqData.sku = this.state.searchText;
		}
		if (this.state.role != 'admin') {
			reqData.Uid = this.state.Uid;
		}
		api.postApi(apiPath, reqData).then(
			(data) => {
				if (data.status == 'Success') {
					let items = data.data;
					for (let item in items) {
						if (purchaseSku.indexOf(items[item].Sku) != -1) {
							items[item].iscart = true;
						} else {
							items[item].iscart = false;
						}
						items[item].price = 99;
					}
					this.setState({
						tableLoading: false,
						items: items,
						totalPage: data.pagination.TotalNumberOfPages,
						normal: false,
					});
				} else {
					this.setState({ tableLoading: false });
				}
			},
			(err) => {
				console.log('err====', err);
				this.setState({ tableLoading: false });
				helper.errorMessage(err);
			},
		);
	}

	addToCart(id) {
		let items = this.state.items;
		let index = helper.getItemIndex(this.state.items, 'Id', id);
		items[index].iscart = true;
		let purchaseSku = this.state.purchaseSku;
		purchaseSku.push(items[index].Sku);
		this.setState({ items: [ ...items ], purchaseSku: purchaseSku }, () => {
			this.calculateAllPrice();
		});
	}

	removeCart(id) {
		let items = this.state.items;
		let index = helper.getItemIndex(this.state.items, 'Id', id);
		let purchaseSku = this.state.purchaseSku;
		items[index].iscart = false;
		purchaseSku.splice(purchaseSku.indexOf(items[index].Sku), 1);
		this.setState({ items: [ ...items ], purchaseSku: purchaseSku }, () => {
			this.calculateAllPrice();
		});
	}

	calculateAllPrice() {
		let tabIndex = this.state.tabIndex;
		let price = 0;
		console.log('=tabIndex===', tabIndex);

		if (tabIndex == 1) {
			price = this.state.listingOptimizationPrice;
		} else if (tabIndex == 4) {
			price = this.state.reviewPrice;
		} else if (tabIndex == 5) {
			price = this.state.enhancedBrandPrice;
		}
		let totalPrice = Number(this.state.purchaseSku.length) * price;
		this.setState({ totalPrice: totalPrice });
	}

	actionPurchase() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			console.log('totalPrice==', totalPrice);
			let req = {
				amount: totalPrice * 100,
				currency: 'usd',
				description: 'Valetseller SkU Purchase',
				customer: stripeCustomerId,
			};
			api.stripePost('charges', req).then((cutomerData) => {
				if (cutomerData.error) {
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					//helper.successMessage('sucessfully changed');
					this.setState({ isLoading: false });
					this.addMarketSku();
					this.openModal();
				}
			});
		}
	}

	closeMarketingModal() {
		let items = this.state.items;
		for (let data in items) {
			items[data].iscart = false;
		}

		this.setState(
			{ modalIsOpen: false, purchaseSku: [], items: [ ...items ] },
			() => {
				this.calculateAllPrice();
			},
		);
	}

	addMarketSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.listingOptimizationPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addmarketsku', reqData).then((data) => {
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	actionEnhancedBrandPurchase() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.setState({ isLoading: true });
			console.log('totalPrice==', totalPrice);
			let req = {
				amount: totalPrice * 100,
				currency: 'usd',
				description: 'Enhanced Brand Content SkU Purchase',
				customer: stripeCustomerId,
			};
			api.stripePost('charges', req).then((cutomerData) => {
				if (cutomerData.error) {
					helper.errorMessage(cutomerData.error.message);
					this.setState({ isLoading: false });
				} else {
					//helper.successMessage('sucessfully changed');
					this.setState({ isLoading: false });
					this.addEnhancedBrandSku();
					this.openModal();
				}
			});
		}
	}

	addEnhancedBrandSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.enhancedBrandPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addenhancedsku', reqData).then((data) => {
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	actionReviewSku() {
		let totalPrice = this.state.totalPrice;
		let stripeCustomerId = this.state.stripeCustomerId;

		if (totalPrice == 0) {
			helper.errorMessage('please add one sku');
		} else if (stripeCustomerId == '') {
			helper.errorMessage('please go to profile and add credit card.');
		} else {
			this.addReviewSku();
		}
	}

	addReviewSku() {
		let reqData = {
			Sku: this.state.purchaseSku,
			Uid: this.state.Uid,
			email: this.state.email,
			firstname: this.state.firstname,
			qty: this.state.purchaseSku.length,
			price: this.state.enhancedBrandPrice,
			subtotal: this.state.totalPrice,
			taxes: 0,
			total: this.state.totalPrice,
		};
		console.log('reqData==', reqData);
		api.postApi('market/addreviewsku', reqData).then((data) => {
			this.setState({ isLoading: false });
			this.openModal();
			console.log(data);
			if (data.status == 'Success') {
				this.setState({ purchaseSku: [] });
			}
		});
	}

	async brandPurchase() {
		let stripeCustomerId = this.state.stripeCustomerId;
		this.setState({ isLoading: true });
		let req = {
			amount: 79900,
			currency: 'usd',
			description: 'Professional Storefront Design',
			customer: stripeCustomerId,
		};
		api.stripePost('charges', req).then((cutomerData) => {
			if (cutomerData.error) {
				helper.errorMessage(cutomerData.error.message);
				this.setState({ isLoading: false });
			} else {
				this.updateBrandPurchase();
			}
		});
	}

	async updateBrandPurchase() {
		const db = firebase1.app.firestore();
		await db
			.collection('user')
			.doc(this.state.key)
			.update({ professional_brand: { created_at: new Date(), active: true } });
		this.setState({ isLoading: false });
		this.openModal();
		let reqData = {
			email: this.state.email,
			firstname: this.state.firstname,
			price: 799,
			company: this.state.company,
		};
		api.postApi('mail/onProfessionalDetails', reqData).then((data) => {
			console.log(data);
		});
	}

	render() {
		const { t } = this.props.setOptions;
		const {
			selectDiscount,
			isLoading,
			role,
			subscription_id,
			totalPrice,
			professionalBrand,
		} = this.state;
		//console.log(subscription_id);
		if (isLoading) {
			return (
				<div
					className='load'
					style={{
						backgroundColor: 'transparent',
						width: '85%',
						height: '50%',
					}}
				>
					<div className='load__icon-wrap'>
						<svg className='load__icon'>
							<path
								fill='#6c6f7f'
								d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
							/>
						</svg>
					</div>
				</div>
			);
		} else {
			return (
				<Row className='market_user_div'>
					{role == 'admin' && (
						<Tabs className='market_user'>
							<TabList>
								<Tab>Set Discounts</Tab>
								<Tab>Purchased SKU</Tab>
								<Tab>Advertising Budget</Tab>
								<Tab>List Optimisation</Tab>
								<Tab>Photography</Tab>
								<Tab>Enhaced Brand Content</Tab>
								<Tab>Professional Storefront Design</Tab>
								<Tab>Consulting Session</Tab>
								<Tab>Purchase UPCs</Tab>
								<Tab>Brand Monitoring</Tab>
							</TabList>
							<TabPanel>
								<Col md={12} style={{ marginTop: 30 }}>
									<div className='search' style={{ marginLeft: 15 }}>
										<Input
											type='text'
											name='search'
											placeholder='Search for user'
											value={this.state.searchText}
											onChange={(event) => {
												this.searchKey(event.target.value);
											}}
										/>
										<Button
											onClick={() => {
												this.actionSearch();
											}}
											style={{ padding: 0 }}
										>
											Search
										</Button>
									</div>
									<TableList
										normal={true}
										items={this.state.items}
										headers={this.state.headers}
										name={this.state.name}
									/>
								</Col>
							</TabPanel>
							<TabPanel>
								<Col md={12} style={{ marginTop: 30 }}>
									<div className='search' style={{ marginLeft: 15 }}>
										<Input
											type='text'
											name='search'
											placeholder='Search for sku'
											value={this.state.searchSkuText}
											onChange={(event) => {
												this.searchSkuKey(event.target.value);
											}}
										/>
										<Button
											onClick={() => {
												this.actionSkuSearch();
											}}
											style={{ padding: 0 }}
										>
											Search
										</Button>
									</div>
									<TableList
										normal={false}
										tableLoading={this.state.tableLoading}
										totalPage={this.state.totalPurchaseSkuPage}
										getData={this.getPurchaseSku}
										items={this.state.purchaseSkuitems}
										headers={this.state.purchaseSkuHeaders}
										name={this.state.name}
									/>
								</Col>
							</TabPanel>
							<TabPanel>
								<AdvertisingBudget />
							</TabPanel>
							<TabPanel>
								<ListOptimization />
							</TabPanel>
							<TabPanel>
								<Photography />
							</TabPanel>
							<TabPanel>
								<EnhancedBrandContent />
							</TabPanel>
							<TabPanel>
								<ProfessionalStorefronDesign />
							</TabPanel>
							<TabPanel>
								<ConsultingSessionHistory />
							</TabPanel>
							<TabPanel>
								<PurchasedUpcs />
							</TabPanel>
							<TabPanel>
								<BrandMonitoringHistory />
							</TabPanel>
						</Tabs>
					)}

					{role != 'admin' && (
						<div className='tab_market_table tab_market_table1'>
							<div className='list_opti' style={{ borderWidth: 0 }}>
								<div className='list_opti_div list_opti_div_head'>
									<h3>Let's Skyrocket Your Sales!</h3>
									<h4>
										Valet Seller provides multiple ways for you to boost your
										product sales. Here you can advertise your products,
										optimize your product pages, and much more!
									</h4>
								</div>
								<div className='sell_more'>
									<h3>Sell More</h3>
									<p>
										Explore one of Valet Seller's many ways to increase your
										sales and improve your e-commerce experience
									</p>
								</div>

								<div className='sell_more_div'>
									{/* {subscription_id == 'none' && (
										<Link to='/pages/marketplaces'>
											<Col md={4}>
												<img src='../img/subscription.png' />
												<h4>Upgrade Your Subscription</h4>
												<p>
													Reach marketplaces such as Amazon, Walmart, Wish, and
													eBay by upgrading your plan!
												</p>
											</Col>
										</Link>
									)} */}
									<Link to='/pages/advertising'>
										<Col md={4}>
											<img src='../img/market1.png' />
											<h4>Set Advertising Budget</h4>
											<p>
												Setting a marketing budget increases your product
												visibility and competitiveness on Valet Seller's third
												party marketplace channels.
											</p>
										</Col>
									</Link>
									<Link to='/pages/discount'>
										<Col md={4}>
											<img src='../img/market7.png' />
											<h4>Set Discounts</h4>
											<p>
												Merchandise your products by setting a maximum discount
												level. This can be used as coupon codes, credits, and
												discounts.
											</p>
										</Col>
									</Link>
									<Link to='/pages/listingoptimization'>
										<Col md={4}>
											<img src='../img/market2.png' />
											<h4>Listing Optimization</h4>
											<p>
												Valet Seller's team of expert copywriters will optimize
												your product listing to stand out among competitors and
												rank higher in marketplace search engines.
											</p>
										</Col>
									</Link>
									<Link to='/pages/productphotography'>
										<Col md={4}>
											<img src='../img/market3.png' />
											<h4>Product Photography</h4>
											<p>
												Get high quality product or model images that sell
												better - quickly and affordably.
											</p>
										</Col>
									</Link>
									<Link to='/pages/enhancedbrand'>
										<Col md={4}>
											<img src='../img/market4.png' />
											<h4>Enhanced Brand Content</h4>
											<p>
												With Enhanced Brand Content, We are able to add enhanced
												images and text that will enhance your brand's story.
											</p>
										</Col>
									</Link>
									{/* <Link to='/pages/morereviews'>
										<Col md={4}>
											<img src='../img/market5.png' />
											<h4>Get More Reviews</h4>
											<p>
												Reviews are an essential part of increasing your product
												ranking and gaining traction. Incentivize buyers to
												leave reviews for your products.
											</p>
										</Col>
									</Link> */}
									<Link to='/pages/professionalstorefront'>
										<Col md={4}>
											<img src='../img/market6.png' />
											<h4>Professional Storefront Design</h4>
											<p>
												Highlight your catalog, collection, and brand with Valet
												Seller's Storefront Design Service
											</p>
										</Col>
									</Link>
									<Link to='/pages/consultingsession'>
										<Col md={4}>
											<img src='../img/consulting-session.png' />
											<h4>Consulting Session (1-Hour) </h4>
											<p>
												Work on your product listings and increase sales with a 1-on-1 coaching session with a verified Valet Seller Consultant.
											</p>
										</Col>
									</Link>
									<Link to='/pages/buyUpcs'>
										<Col md={4}>
											<img src='../img/upcMarketing.jpeg' />
											<h4>Purchase UPCs</h4>
											<p>
												Get official UPC codes for your brand to be compliant with marketplace policy.
											</p>
										</Col>
									</Link>
									<Link to='/pages/brandMonitoring'>
										<Col md={4}>
											<img src='../img/BrandProtection.png' />
											<h4>Brand Monitoring</h4>
											<p>
											Monitor and report unauthorized resellers for your brand on Amazon, eBay, and Walmart.
											</p>
										</Col>
									</Link>
								</div>
							</div>
						</div>
					)}
					{/* <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <span className="closepurchase" onClick={this.closeModal}>X</span>
            <img src="../img/favlogo.png" style={{ width: 80, marginBottom: 20 }} />
            <h2>Thank you for your purchase!</h2>
            <p className="purchase_p">
              Thank you for your purchase! Your listing information has been sent to our copywriting team. Please allow 5-7 days for your listing optimizations to be delivered
            </p>
            <p className="purchase_p">
              If you have any questions, please email us at support@valetseller.com
            </p>

            <button className="btn addbtn return_app" onClick={() => { this.closeMarketingModal() }}>Return to App</button>

          </Modal> */}
				</Row>
			);
		}
	}
}
const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps, actions)(NewMarketingCard);
