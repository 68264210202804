import api from "../../utils/api";

export const getPaypalToken = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_PAYPAL_TOKEN", payload: "" });
    try {
      const tokenData = await api.paypalTokenPost("oauth2/token", {
        grant_type: "client_credentials",
      });
      if (tokenData && tokenData.debug_id) {
        return getPaypalTokenFail(dispatch, tokenData);
      }
      return getPaypalTokenSuccess(dispatch, tokenData);
    } catch (error) {
      getPaypalTokenFail(dispatch, error);
    }
  };
};

const getPaypalTokenSuccess = (dispatch, payload) => {
  return dispatch({ type: "GET_PAYPAL_TOKEN_SUCCESS", payload });
};

const getPaypalTokenFail = (dispatch, payload) => {
  return dispatch({ type: "GET_PAYPAL_TOKEN_FAIL", payload });
};

export const savePaypalOrder = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "SAVE_PAYPAL_ORDER", payload });
    try {
      const result = await api.postApi("orders/savePaymentDetails", payload);
      return savePaypalOrderSuccess(dispatch, result);
    } catch (error) {
      savePaypalOrderFail(dispatch, error);
    }
  };
};

const savePaypalOrderSuccess = (dispatch, payload) => {
  return dispatch({ type: "SAVE_PAYPAL_ORDER_SUCCESS", payload });
};

const savePaypalOrderFail = (dispatch, payload) => {
  return dispatch({ type: "SAVE_PAYPAL_ORDER_FAIL", payload });
};

export const getTransactionHistory = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "GET_TRANSACTIONS", payload });
    try {
      const transactionData = await api.getApi(`transactionsHistory/${payload.Uid}`);
      return getTransactionHistorySuccess(dispatch, transactionData);
    } catch (error) {
      getTransactionHistoryFail(dispatch, error);
    }
  };
};

const getTransactionHistorySuccess = (dispatch, payload) => {
  return dispatch({ type: "GET_TRANSACTIONS_SUCCESS", payload });
};

const getTransactionHistoryFail = (dispatch, payload) => {
  return dispatch({ type: "GET_TRANSACTIONS_FAIL", payload });
};

export const savePaypalSubscription = (payload) => {
  return async (dispatch) => {
    dispatch({ type: "SAVE_PAYPAL_SUBSCRIPTION", payload });
    try {
      const result = await api.postApi("subscriptions/savePaymentDetails", payload);
      return savePaypalSubscriptionSuccess(dispatch, result);
    } catch (error) {
      savePaypalSubscriptionFail(dispatch, error);
    }
  };
};

const savePaypalSubscriptionSuccess = (dispatch, payload) => {
  return dispatch({ type: "SAVE_PAYPAL_SUBSCRIPTION_SUCCESS", payload });
};

const savePaypalSubscriptionFail = (dispatch, payload) => {
  return dispatch({ type: "SAVE_PAYPAL_SUBSCRIPTION_FAIL", payload });
};
