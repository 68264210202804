import { createStore, applyMiddleware, compose } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import reducers from '../../redux/reducers/index';


// const reducer = combineReducers({
//   form: reduxFormReducer, // mounted under "form",
//   theme: themeReducer,
//   authReducer:authReducer,
//   sidebar: sidebarReducer,
// });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    /* preloadedState, */ 
    composeEnhancers(applyMiddleware(thunk))
);

export default store;
