import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
// import MarketingCard from './components/MarketingCard';
import NewMarketingCard from './components/NewMarketingCard';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Marketing extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:false,
      searchText:''
    }
  }

  render() {
    const {t} = this.props.setOptions
    return (
      <div>
      <Container className="dashboard">
        {/* <Row>
          <Col md={12}>
            <h3 className="page-title">Advertisements & Marketing</h3>
          </Col>
        </Row> */}
        <Row>
            {/* <MarketingCard {...this.props}/> */}
           <NewMarketingCard {...this.props}/>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Marketing);
