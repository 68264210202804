import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Grid, Input, Select } from 'react-spreadsheet-grid';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Tooltip from "react-simple-tooltip"
import {
    Button,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal,
  } from "reactstrap";
import { productModel } from '../../../utils/validationSchemas';
import api from "../../../utils/api";

const categoryList = [
    { id: 1, name: 'Accessories' },
    { id: 2, name: 'Apparel' },
    { id: 3, name: 'Baby' },
    { id: 4, name: 'Beauty' },
    { id: 5, name: 'Electronics' },
    { id: 6, name: 'Entertainment' },
    { id: 7, name: 'Grocery' },
    { id: 8, name: 'Hobby' },
    { id: 9, name: 'Home' },
    { id: 10, name: 'Jewelry' },
    { id: 11, name: 'Kitchen' },
    { id: 12, name: 'Casual' },
    { id: 13, name: 'Shoes' },
    { id: 14, name: 'Pets' },
    { id: 15, name: 'Sporting Goods' },
    { id: 16, name: 'Toys' },
    { id: 17, name: 'Wellness' },
];
const BulkEdit = (props) => {
    const { userdetails } = props;
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const onFieldChange = (rowId, field) => (value) => {
        let index;
        const rowsCopy = [...rows];
        const row = rowsCopy.find(({ _id }, i) => {
            if (_id === rowId) index = i;
            return _id === rowId;
        });
        if (field === 'Category') value = categoryList.find(({ id }) => id === value).name;
        _.set(row, field, value);
        rowsCopy[index] = row;
        setRows(rowsCopy);
    };

    const { products } = useParams()
    const [openWarehouseModal, setOpenWarehouseModal] = useState(false);
    const [openIndex, setOpenIndex] = useState();
    const [warehouseData, setWarehouseData] = useState();
    useEffect(() => {
        setLoading(true);
        api.postApi('fetchProducts', {
            productIds: products.split(','),
        })
            .then((response) => {
                setRows(response.products);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    const validatedProducts = rows.map((product) => {
        let validation = {};
        validation = productModel.check({
            ...product,
            Price: product.GlobalListingAttributes && product.GlobalListingAttributes.Price,
            Brand: product.BrandName,
            selectedCategory: product.Category,
        });

        const isValid = !Object.keys(validation).map((key) => {
            let cellIsInvalid = false;
            const validationCriteria = [
                'Sku', 'Brand', 'QuantityOnHand', 'Weight', 'Length', 'Height', 'Width',
                'Price', 'Name', 'selectedCategory',
            ]
            if (validationCriteria.includes(key)) {
                if (product.ProductStatus !== "draft") {
                    cellIsInvalid = validation[key].hasError
                }
                if (key === 'Sku' && product.ProductStatus === "draft" && validation[key].hasError) {
                    cellIsInvalid = validation[key].hasError
                }
            }
            return cellIsInvalid;
        }).includes(true);

        return {
            ...product,
            validation,
            isValid,
        }
    });

    const bulkSave = () => {
        setLoading(true);
        Promise.all(
            [...validatedProducts.filter((product) => product.isValid)].map((product) => {
                const productData = { ...product };
                // delete productData["_id"];
                delete productData["__v"];
                return api.putApi(`product/${productData._id}`, productData);
                // if (product.ProductStatus === "draft") {
                //     return api.postApi("inventory/draftProducts", productData);
                // }
                // else {
                //     return api.postApi("inventory/updateProducts", productData);
                // }
            }))
            .then((response) => {
                toast.success(`Successfully saved ${response.length} product(s).`);
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                toast.error("Something wen't wrong");
                setLoading(false);
            });
    }

    const RenderCustomCell = ({ hasError, children, errorMessage }) => (
        hasError ? (
            <Tooltip
                placement="bottom"
                content={errorMessage}
            >
                {children}
            </Tooltip>
        ) : children
    );

    if (loading) {
        return (
            <div className="load__icon-wrap">
                <svg className="load__icon">
                    <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
            </div>
        )
    }

    return (
        <div>
            <Link to='/pages/products'>
                <button className='back_products'>Back</button>
            </Link>
            <Grid
                columns={[
                    {
                        title: () => 'Parent SKU',
                        isColumnsResizable: true,
                        value: (row, { focus }) => {
                            return (
                                <div>
                                    <RenderCustomCell
                                        hasError={row.ProductStatus === "draft" && row.validation && row.validation.ParentSku && row.validation.ParentSku.hasError}
                                        errorMessage={row.ProductStatus === "draft" && row.validation && row.validation.ParentSku && row.validation.ParentSku.errorMessage}
                                    >
                                        <Input
                                            value={row.ParentSku}
                                            focus={focus}
                                            onChange={onFieldChange(row._id, 'ParentSku')}
                                            placeholder="Parent SKU "
                                        />
                                    </RenderCustomCell>
                                </div>
                            );
                        }
                    },
                    {
                        title: () => 'Sku',
                        getCellClassName: (row) => {
                            if (row.ProductStatus === "draft" && row.validation && row.validation.Sku && row.validation.Sku.hasError) {
                                return 'error_cell';
                            }
                            else {
                                return 'cell_edit_disallowed';
                            };
                        },
                        value: (row, { focus }) => {
                            if (row.ProductStatus === "draft") {
                                return (
                                    <div>
                                        <RenderCustomCell
                                            hasError={row.ProductStatus === "draft" && row.validation && row.validation.Sku && row.validation.Sku.hasError}
                                            errorMessage={row.ProductStatus === "draft" && row.validation && row.validation.Sku && row.validation.Sku.errorMessage}
                                        >
                                            <Input
                                                value={row.Sku}
                                                focus={focus}
                                                onChange={onFieldChange(row._id, 'Sku')}
                                                placeholder="Sku"
                                            />
                                        </RenderCustomCell>
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div className="truncate">
                                        {row.Sku}
                                    </div>
                                );
                            }
                        }
                    },
                    {
                        title: () => 'Name',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Name && row.validation.Name.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Name && row.validation.Name.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Name && row.validation.Name.errorMessage}
                                >
                                    <Input
                                        value={row.Name}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Name')}
                                        placeholder="Name"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Price',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Price && row.validation.Price.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Price && row.validation.Price.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Price && row.validation.Price.errorMessage}
                                >
                                    <Input
                                        value={row.GlobalListingAttributes.Price}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'GlobalListingAttributes.Price')}
                                        placeholder="Price"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Retail Price',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.BuyPrice && row.validation.BuyPrice.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.BuyPrice && row.validation.BuyPrice.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.BuyPrice && row.validation.BuyPrice.errorMessage}
                                >
                                    <Input
                                        value={row.BuyPrice}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'BuyPrice')}
                                        placeholder="Retail Price"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'UPC',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.UPC && row.validation.UPC.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.UPC && row.validation.UPC.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.UPC && row.validation.UPC.errorMessage}
                                >
                                    <Input
                                        value={row.Identifiers.UPC}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Identifiers.UPC')}
                                        placeholder="UPC"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Keywords',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Seo && row.validation.Seo.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Seo && row.validation.Seo.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Seo && row.validation.Seo.errorMessage}
                                >
                                    <Input
                                        value={row.Seo}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Seo')}
                                        placeholder="Keywords"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Quantity',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.QuantityOnHand && row.validation.QuantityOnHand.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.QuantityOnHand && row.validation.QuantityOnHand.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.QuantityOnHand && row.validation.QuantityOnHand.errorMessage}
                                >
                                    {
                                        userdetails && !userdetails.admin ? (
                                            <Input
                                                value={row.QuantityOnHand}
                                                focus={focus}
                                                onChange={onFieldChange(row._id, 'QuantityOnHand')}
                                                placeholder="Quantity"
                                            />
                                        ) : (
                                            <button onClick={() => {
                                                let index = -1;
                                                const rowObj = rows.find(({ _id }, i) => {
                                                    if (_id === row._id) index = i;
                                                    return _id === row._id;
                                                });
                                                setOpenIndex(index);
                                                setWarehouseData(rowObj.Warehouses);
                                                setOpenWarehouseModal(true);
                                            }}>
                                                Edit
                                            </button>
                                        )
                                    }
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Width',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Width && row.validation.Width.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Width && row.validation.Width.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Width && row.validation.Width.errorMessage}
                                >
                                    <Input
                                        value={row.Width}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Width')}
                                        placeholder="Width"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Weight',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Weight && row.validation.Weight.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Weight && row.validation.Weight.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Weight && row.validation.Weight.errorMessage}
                                >
                                    <Input
                                        value={row.Weight}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Weight')}
                                        placeholder="Weight"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Length',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Length && row.validation.Length.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Length && row.validation.Length.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Length && row.validation.Length.errorMessage}
                                >
                                    <Input
                                        value={row.Length}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Length')}
                                        placeholder="Length"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Height',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Height && row.validation.Height.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Height && row.validation.Height.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Height && row.validation.Height.errorMessage}
                                >
                                    <Input
                                        value={row.Height}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Height')}
                                        placeholder="Height"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Brand',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Brand && row.validation.Brand.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Brand && row.validation.Brand.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Brand && row.validation.Brand.errorMessage}
                                >
                                    <Input
                                        value={row.BrandName}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'BrandName')}
                                        placeholder="Brand"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Category',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.selectedCategory && row.validation.selectedCategory.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            const category = categoryList.find(({ name }) => name === row.Category);
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.selectedCategory && row.validation.selectedCategory.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.selectedCategory && row.validation.selectedCategory.errorMessage}
                                >
                                    <Select
                                        selectedId={category && category.id}
                                        isOpen={focus}
                                        items={categoryList}
                                        placeholder="Category"
                                        onChange={onFieldChange(row._id, 'Category')}
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Feature 1',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature1 && row.validation.Feature1.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature1 && row.validation.Feature1.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature1 && row.validation.Feature1.errorMessage}
                                >
                                    <Input
                                        value={row.Feature1}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Feature1')}
                                        placeholder="Product Feature 1"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Feature 2',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature2 && row.validation.Feature2.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature2 && row.validation.Feature2.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature2 && row.validation.Feature2.errorMessage}
                                >
                                    <Input
                                        value={row.Feature2}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Feature2')}
                                        placeholder="Product Feature 2"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Feature 3',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature3 && row.validation.Feature3.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature3 && row.validation.Feature3.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature3 && row.validation.Feature3.errorMessage}
                                >
                                    <Input
                                        value={row.Feature3}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Feature3')}
                                        placeholder="Product Feature 3"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Feature 4',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature4 && row.validation.Feature4.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature4 && row.validation.Feature4.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature4 && row.validation.Feature4.errorMessage}
                                >
                                    <Input
                                        value={row.Feature4}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Feature4')}
                                        placeholder="Product Feature 4"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Feature 5',
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.errorMessage}
                                >
                                    <Input
                                        value={row.Feature5}
                                        focus={focus}
                                        onChange={onFieldChange(row._id, 'Feature5')}
                                        placeholder="Product Feature 5"
                                    />
                                </RenderCustomCell>
                            );
                        }
                    },
                    {
                        title: () => 'Marketplaces',
                        width: 1000,
                        getCellClassName: (row) => {
                            if (row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.hasError) {
                                return 'error_cell';
                            };
                        },
                        value: (row, { focus }) => {
                            return (
                                <RenderCustomCell
                                    hasError={row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.hasError}
                                    errorMessage={row.ProductStatus !== "draft" && row.validation && row.validation.Feature5 && row.validation.Feature5.errorMessage}
                                >
                                    <select
                                        value={row.SellType.map((marketplace) => marketplace.value)}
                                        onChange={(e) => {
                                            const value = Array.from(e.target.selectedOptions, option => ({
                                                label: option.value,
                                                value: option.value,
                                            }));
                                            onFieldChange(row._id, 'SellType')(value);
                                        }}
                                        className="marketplace-cell"
                                        autoFocus={focus}
                                        style={{ width: 63, height: 40, border: 'none' }}
                                        multiple
                                    >
                                        <option value="amazon">Amazon</option>
                                        <option value="ebay">Ebay</option>
                                        <option value="walmart">Walmart</option>
                                        <option value="valet shops">Valet Shops</option>
                                    </select>
                                </RenderCustomCell>
                            );
                        }
                    },
                ]}
                rows={validatedProducts}
                getRowKey={row => row._id}
            />
            <div>
                <button
                    onClick={() => bulkSave()}
                    style={{ margin: '1%', padding: '0.7%' }}
                    className='addbtn'
                    disabled={loading}
                >
                    {loading ? "Updating..." : "Update"}
                </button>
            </div>
            <Modal
                isOpen={openWarehouseModal}
                className="modal-xl bulk-add-products"
            >
                <ModalHeader>
                    Warehouses
                </ModalHeader>
                <ModalBody>
                    {
                        rows[openIndex] && rows[openIndex].Warehouses && rows[openIndex].Warehouses.map((warehouse, i) => {
                            return(
                                <div className="form__form-group-field" style={{ width: '40%' }}>
                                    <span>{warehouse.Name}</span>
                                    <input
                                        style={{ border: '1px solid #ccc', borderRadius: 5 }}
                                        type="number"
                                        placeholder="quantity"
                                        min="0"
                                        class="form__product-input"
                                        value={warehouse.QuantityOnHand}
                                        onChange={(e) => {
                                            warehouseData[i].QuantityOnHand = e.target.value;
                                            setWarehouseData(warehouseData);
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        console.log("rows[openIndex]._id", rows[openIndex]._id, warehouseData);
                        onFieldChange(rows[openIndex]._id, 'Warehouses')(warehouseData);
                        setOpenWarehouseModal(false);
                     }} color="primary">
                        Save
                    </Button>
                    {' '}
                    <Button color="secondary" onClick={() => setOpenWarehouseModal(false)}>
                        cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ authReducer }) => {
	const { user, userdetails } = authReducer;
	return { user, userdetails };
};

export default connect(mapStateToProps)(BulkEdit);
