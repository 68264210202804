import React, { PureComponent } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import PayPalBtn from "../../../components/PayPalBtn";
import api from "../../../utils/api";
import helper from "../../../utils/helper";
import {connect} from "react-redux";
import * as actions from "../../../redux/actions";

class BuyUpcs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 50,
      numberOfUpcs: 1,
      Uid: null,
      Email: null,
      Name: null,
      Company: null,
    };
    this.paypalonCreateOrder = this.paypalonCreateOrder.bind(this);
    this.onApprove = this.onApprove.bind(this);
  }

  componentDidMount() {
    if (this.props.userdetails && this.props.userdetails.uid) {
      this.setState({
        Uid: this.props.userdetails.uid,
        Email: this.props.userdetails.email,
        Name: this.props.userdetails.firstname,
        Company: this.props.userdetails.company,
      });
    }
  }

  componentWillReceiveProps(nextProps) {

    if ((nextProps.userdetails != undefined)
        && (nextProps.userdetails != this.props.userdetails)
        && nextProps.userdetails.uid
    ) {
      this.setState({
        Uid: nextProps.userdetails.uid,
        Email: nextProps.userdetails.email,
        Name: nextProps.userdetails.firstname,
        Company: nextProps.userdetails.company,
      });
    }
  }

  paypalonCreateOrder = (data, actions) => {
    const { value, numberOfUpcs } = this.state;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: value * numberOfUpcs,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
      },
    });
  };

  onApprove = (details, data) => {
    const { Uid, Email, Name, Company } = this.state;
    const reqData = { paymentDetails: details, Uid, Email, Name, Company };
    api.postApi("market/buyUpcs", reqData).then((response) => {
      if (response.status === "Success") {
        helper.successMessage(response.data);
        this.props.savePaypalOrder({ Uid, orderID: data.orderID, paymentType: 'BUY_UPC', userDetails: this.props.userdetails });
      } else {
        helper.errorMessage(response.data);
      }
    });
  };

  render() {
    const { value, numberOfUpcs } = this.state;
    return (
      <div>
        <Row>
          <Col md={12}>
            <Link to="/pages/marketing">
              <button className="back_products"> Back </button>{" "}
            </Link>{" "}
          </Col>{" "}
          <Row className="ads-market-main">
            <Col className="ads-market-div">
              <Col md={6}>
                <h2> Purchase UPCs </h2>{" "}
                <p>
                  Get official UPC codes for your brand to be compliant with marketplace policy.
                </p>{" "}
                <div className="form__form-group-field">
                  <label> Select number of UPCs </label>{" "}
                  <input
                    name="name"
                    type="number"
                    min="1"
                    className="form__product-input"
                    placeholder="Select number of UPCs"
                    value={numberOfUpcs}
                    onChange={(e) =>
                      this.setState({
                        numberOfUpcs: e.target.value,
                      })
                    }
                  />{" "}
                </div>{" "}
                {/*<input className="form__product-input" type="number" value={numberOfUpcs} onChange={(e) => setNumberOfUpcs(e.target.value)} />*/}{" "}
              </Col>{" "}
              <Col md={6}>
                <img src="../img/upcMarketing.jpeg" />
              </Col>{" "}
            </Col>{" "}
          </Row>{" "}
          <Row
            className=""
            style={{
              width: "100%",
            }}
          >
            <div
              className="purchase_div"
              style={{
                marginBottom: 300,
              }}
            >
              <h1>
                {" "}
                PRICE: ${value * numberOfUpcs}{" "}
                TOTAL{" "}
              </h1>{" "}
              <PayPalBtn
                onCreateOrder={this.paypalonCreateOrder}
                onSuccess={(details, data) => this.onApprove(details, data)}
                catchError={(error) => console.log(error)}
                onError={(error) => console.log(error)}
                onCancel={(cancel) => console.log(cancel)}
              />
            </div>
          </Row>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(BuyUpcs);
