import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ShipmentsCard from './components/ShipmentsCard';

import { connect } from 'react-redux';
import * as actions from '../../redux/actions';


class Shipments extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isLoading:true,
      searchText:''
    }
    this.searchKey = this.searchKey.bind(this);
  }

  componentDidMount() {

    if(this.props.list_companies==undefined){
      this.setState({isLoading:true})
    }else{
      this.setState({isLoading:false,items:this.props.list_companies,tempItems:this.props.list_companies})
    }
    this.props.listRecord('companies');
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps=====',nextProps);
    this.setState({isLoading:false,items:nextProps.list_companies,tempItems:nextProps.list_companies})
  }

  searchKey(text){

    if(text.length==0){
      this.setState({items:this.state.tempItems})
    }

    this.setState({searchText:text}, () => {
      if(this.state.searchText.length>0){
        let items =[];
        let data = this.state.tempItems;
        // this.setState({items:[]})
        data.map((item, index) => {
          if(item.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1){
            items.push(item);
          }
        })
        this.setState({items:items})
      }
    });
  }

  render() {
    const {t} = this.props.setOptions
    return (
      <div>
      {this.state.isLoading==true&&<div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>}
      {this.state.isLoading==false&&<Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Shipments</h3>
          </Col>
        </Row>
        <Row>
            <ShipmentsCard {...this.props}/>
            {/* <LocationMapCard {...this.props} items={this.state.items} /> */}
            {/* <CompanylistCard {...this.props} items={this.state.items} searchKey={this.searchKey} searchText={this.state.searchText}/>
            <QuickDetailsCard /> */}
        </Row>
      </Container>}
      </div>
    )
  }
}

const mapStateToProps = ({ curdReducer }) => {
  return curdReducer;
};

export default connect(mapStateToProps, actions)(Shipments);
