import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router1 from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as FirebaseSDK from 'firebase';
import history from '../../utils/history';
import helper from '../../utils/helper';

// import { withTranslation } from 'react-i18next';
import {
	CardElement,
	injectStripe,
	StripeProvider,
	Elements,
} from 'react-stripe-elements';

import firebase1 from '../../utils/config';
require('firebase/firestore');

class App extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			loaded: false,
			role: '',
			isAuthenticating: false,
			walkthroughDone: false,
			isAccountDisabled: false,
			isFirebase: false,
		};
	}

	componentDidMount() {
		window.addEventListener('load', () => {
			this.setState({ loading: false });
			setTimeout(() => this.setState({ loaded: true }), 500);
		});
		const settings = { timestampsInSnapshots: true };
		var config = {
			apiKey: 'AIzaSyCYDVyRIYt2I_gKMSPYUvoV3--ZBBTdv-c',
			authDomain: 'lustrous-oasis-258814.firebaseapp.com',
			databaseURL: 'https://lustrous-oasis-258814.firebaseio.com',
			projectId: 'lustrous-oasis-258814',
			storageBucket: 'lustrous-oasis-258814.appspot.com',
			messagingSenderId: '80087202152',
			appId: '1:80087202152:web:c1142e535f207b42d4bedd',
			measurementId: 'G-QGBS794DG1',
		};
		firebase1.app = FirebaseSDK.initializeApp(config, 'default');
		const db = firebase1.app.firestore();
		db.settings(settings);

		firebase1.app.auth().onAuthStateChanged((user) => {
			if (user != null) {
				var that = this;
				let lastSignTime = (user.metadata&&user.metadata.lastSignInTime)?new Date(user.metadata.lastSignInTime):new Date();
				let diffHours = helper.diffHours(lastSignTime,new Date());
				console.log('diffHours===',diffHours,'lastSignTime==',lastSignTime)
				if(diffHours<48){
					db
					.collection('user')
					.where('uid', '==', user.uid)
					.get()
					.then(function(userSnapshot) {
						let role = '';
						let isAccountDisabled = false;
						let walkthroughDone = false;
						if (userSnapshot.size > 0) {
							userSnapshot.forEach(function(doc) {
								let data = doc.data();
								data.key = doc.id;
								console.log('data==' + doc.id);
								role = data.role.label;
								walkthroughDone = data.walkthroughDone
									? data.walkthroughDone
									: false;
								if (data.account_disabled == true) {
									isAccountDisabled = true;
								}
							});
						}
						console.log('isAccountDisabled===', isAccountDisabled);
						//isAccountDisabled = false;
						if (isAccountDisabled) {
							that.checkAccount();
							helper.errorMessage(
								'your account has been disabled please contact to support@valetseller.com',
							);
						} else {
							that.setState({
								isAuthenticating: true,
								role: role,
								isFirebase: true,
								isAccountDisabled: isAccountDisabled,
								walkthroughDone: walkthroughDone,
							});
						}
						localStorage.setItem('role', role);
						localStorage.setItem('isAccountDisabled', isAccountDisabled);
					});
				}else{
					that.checkAccount();
				}
				
			} else {
				this.setState({ isAuthenticating: false, isFirebase: true });
			}
		});
	}

	async checkAccount() {
		await firebase1.app.auth().signOut();
		localStorage.removeItem('role');
		localStorage.removeItem('addProductSeen');
		localStorage.removeItem('productSeen');
		localStorage.removeItem('inventorySeen');
		localStorage.removeItem('salesSeen');
		localStorage.removeItem('dashboardSeen');
		localStorage.removeItem('topRightMenuSeen');
		history.push('/');
		// that.setState({isAuthenticating:false}):
	}

	render() {
		const { loaded, loading, isFirebase } = this.state;
		return (
			<Provider store={store}>
				{/* <StripeProvider apiKey={'pk_live_j4y7d0zFVikVXLlg0ihoH42W00MhDf2ntg'}> */}
					<Router history={history}>
						<ScrollToTop>
							{!isFirebase && (
								<div className={`load${loading ? '' : ' loaded'}`}>
									<div className='load__icon-wrap'>
										<svg className='load__icon'>
											<path
												fill='#6c6f7f'
												d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'
											/>
										</svg>
									</div>
								</div>
							)}
							{isFirebase && (
								<div>
									<Router1
										{...this.props}
										isAccountDisabled={this.state.isAccountDisabled}
										isAuthenticating={this.state.isAuthenticating}
										role={this.state.role}
										walkthroughDone={this.state.walkthroughDone}
									/>
								</div>
							)}
							<ToastContainer />
						</ScrollToTop>
					</Router>
				{/* </StripeProvider> */}
			</Provider>
		);
	}
}

// export default hot(module)(App);
export default App;
