
// Walkthrough for the dashboard
import React, { Component } from 'react';
import Tour from "react-product-intro";
import history from '../../../utils/history';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import {
  INVENTORY
} from '../../Walkthrough/popupText';
class InventoryWalkthrough extends Component {
  constructor() {
    super();
    this.state = {

      isTourActive: false,
      tourStep: 1
    };
  }

  componentDidMount() {
    if (this.props.userdetails && !this.props.userdetails.walkthroughDone) {
      let dashboardSeen = typeof localStorage.dashboardSeen != "undefined" ? localStorage.dashboardSeen : false;
      let productSeen = typeof localStorage.productSeen != "undefined" ? localStorage.productSeen : false;
      let addProductSeen = typeof localStorage.addProductSeen != "undefined" ? localStorage.addProductSeen : false;
      let inventorySeen = typeof localStorage.inventorySeen != "undefined" ? localStorage.inventorySeen : false;
      let topRightMenuSeen = typeof localStorage.topRightMenuSeen != "undefined" ? localStorage.topRightMenuSeen : false;

      //check the currentRoute
      //currentTab	
      switch ("inventory") {
        case "dashboard":
          if (!dashboardSeen) {
            //show walkthrough as per current page
            this.setState({
              isTourActive: true
            });
            localStorage.dashboardSeen = true;
          }
          break;
        case "product":
          if (!productSeen) {
            //show walkthrough as per current page
            this.setState({
              isTourActive: true
            });
            localStorage.productSeen = true;
          }
          break;
        case "addProduct":
          if (!addProductSeen) {
            //show walkthrough as per current page
            this.setState({
              isTourActive: true
            });
            localStorage.addProductSeen = true;
          }
          break;
        case "inventory":
          if (!inventorySeen) {
            //show walkthrough as per current page
            this.setState({
              isTourActive: true
            });
            localStorage.inventorySeen = true;
          }
          break;
        case "topRightMenu":
          if (!topRightMenuSeen) {
            //show walkthrough as per current page
            this.setState({
              isTourActive: true
            });
            localStorage.topRightMenuSeen = true;
          }
          break;

      }
    }
  }

  onNextWalkthrough = (step) => {
    if (step == 2) {
      this.setState({
        isTourActive: false
      });
      history.push('branding')
    } else {
      this.setState({
        isTourActive: false
      });
    }
  }
  onCancelWalkthrough=()=>{
    this.setState({ isTourActive: false})
    this.props.addWalkthroughFlag()
  }

  render() {
    return (
      <div className="inventory_page_walkthrough">
        <div className="overlayIntro resizeInventoryPop">
          <Tour

            active={this.state.isTourActive}
            step={this.state.tourStep}
            onNext={(step) => this.onNextWalkthrough(step)}
            onBack={(step) => this.setState({ tourStep: step })}
            onCancel={() => this.onCancelWalkthrough()}
            hideSteps={true}
            steps={[
              {
                step: 1,
                // selector: ".step1",
                
                title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>{INVENTORY}</div>
              },
              {
                step: 2,
                selector: ".step2",
                title: <div style={{ textAlign:"center", marginTop:'10px', fontSize:"16px", color: "white" }}></div>,
                body: <div style={{ textAlign:"center", marginTop:'20px', fontSize:"16px", color: "black" }}>On the Inventory page you can check inventory levels in all warehouses and create an inbound shipment to restock your products.</div>
              },

            ]}
          />
        </div>
      </div>
    )
  }


}

const mapStateToProps = ({ curdReducer, roleReducer, authReducer }) => {
  const { user, userdetails } = authReducer;
  return { user, userdetails };
};

export default connect(mapStateToProps, actions)(InventoryWalkthrough);

