import React, { useState } from 'react';
import api from '../../utils/api';
import helper from '../../utils/helper';

const OrderStatus = (props) => {
    const { rowData } = props;
    const [value, setValue] = useState(rowData.value);
    const setNewStatus = (e) => {
        setValue(e.target.value);
        api.postApi('order/management', {
            _id: rowData.original._id,
            Status: e.target.value,
        })
            .then((response) => helper.successMessage(response.data))
            .catch(() => helper.errorMessage('Something when wrong, Status not updated'));
    }
    return (
        <div>
            <select
                style={{ border: 'unset' }}
                value={value}
                onChange={setNewStatus}
            >
                <option>Complete</option>
                <option>InProcess</option>
                <option>Cancelled</option>
            </select>
        </div>
    );
}

export default OrderStatus;
