import React, { useState, useEffect } from 'react';
import {
	Col,
	Button,
} from 'reactstrap';
import AsyncSelect from "react-select/lib/Async";
import TableList from '../../Table';
import api from '../../../utils/api';

const headers = [
    { key: 'amount', title: 'Amount' },
    { key: 'paypal_transaction_id', title: 'Paypal Transaction ID' },
    { key: 'userDetails.email', title: 'user' },
    { key: 'createdAt', title: 'Date' },
]

const photography = () => {
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [uid, setUid] = useState();

    useEffect(() => {
        api.getApi(`paymentDetails/ENHANCED_BRAND_CONTENT?uid=${uid}`)
            .then((response) => setTransactionDetails(response.data))
    }, [uid]);

    const loadOptions = (inputValue, callback) => {
        api.postApi('user/list', {
            Pagination: {
                PageNumber: 1,
                ResultsPerPage: 25,
                Search: inputValue,
                StartAfter: "",
            }
        })
            .then((response) => {
                const results = response.data.map((user) => ({
                    value: user.uid,
                    label: user.email
                }));
                callback(results);
            })
    };

    return(
    <div>
        <Col md={12} style={{ marginTop: 30 }}>
            <div className='search' style={{ marginLeft: 15 }}>
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    onChange={(value) => setUid(value.value)}
                />
            </div>
            <TableList
                normal={false}
                tableLoading={false}
                totalPage={1}
                getData={() => []}
                items={transactionDetails}
                headers={headers}
            />
        </Col>
    </div>
    )
}

export default photography;
