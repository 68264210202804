import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import helper from '../../../utils/helper';
import DashboardWalkthrough from "../../Walkthrough/component/DashboardWalkthrough" ;

const DashboardCard = ({totalAmount,totalProducts,totalOrders}) => (


  <Col md={12} className="dashboard_top">
    <Card>
    <Link to="/pages/products">
      <CardBody>
      
        <div className="card__title">
          <span className="card__title_top">Total Products</span>
        </div>
        <div className="card_body">
          <div className="card_body_left">
          <span className="card_body_left_top">{totalProducts}</span>
            {/* <span>13.8%</span> */}
          </div>
        </div>
        
      </CardBody>
      </Link>
    </Card>

    <Card>
    <Link to="/pages/sales">
      <CardBody>
        <div className="card__title">
          <span className="card__title_top">Total Orders</span>
        </div>
        <div className="card_body">
          <div className="card_body_left">
            <span className="card_body_left_top">{totalOrders}</span>
            {/* <span>13.8%</span> */}
          </div>
        </div>
      </CardBody>
      </Link>
    </Card>

    <Card>
    <Link to="/pages/sales">
      <CardBody>
        <div className="card__title">
          <span className="card__title_top">Total Sales</span>
        </div>
        <div className="card_body">
          <div className="card_body_left">
            <span className="card_body_left_top">{helper.priceFormat(totalAmount)}</span>
            {/* <span>13.8%</span> */}
          </div>
        </div>
      </CardBody>
      </Link>
    </Card>
    <DashboardWalkthrough/>

  </Col>
);

export default DashboardCard;
