import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_SUCCESS_DETAILS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGOUT
} from '../constants/Type';

const INITIAL_STATE = {

  user: {},
  userdetails:{},
  error: '',
  loading: false,
};

export default function(state = {}, action) {
  switch (action.type) {
      case LOGIN_USER:
        return { ...state, loading: true, error: '' };
      case LOGIN_USER_SUCCESS:
        return { ...state, ...INITIAL_STATE, user: action.payload };
      case LOGIN_USER_SUCCESS_DETAILS:
        return { ...state, userdetails: action.payload };
      case LOGIN_USER_FAIL:
        return { ...state, error: action.payload, password: '', loading: false };
      case LOGOUT:
        return { INITIAL_STATE };
    default:
      return state;
  }
}
