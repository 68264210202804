export const INTRO =
	"Welcome to Valet Seller. Let's get you set up on your account.";
export const ADD_PRODUCT_BUTTON =
	'Let’s get started by learning how to add a product to your catalog. You can add a product through a file upload, or one by one. In this tour, we will learn how to add a product one by one. To do this, you will click “Add Products”.';
export const PRODUCT_LISTING =
	'In addition to listing new products, this page is where you will find your active product details and be able to edit your listings.';

export const GLOBAL_LISTING_ATTRIBUTES =
	'Here is where you will enter your product information.';
export const HELP = 'If you need help, contact us here.';
export const WAREHOUSE = 'Fill out your product details here.';
export const ADD_BUTTON =
	'Select “Publish” when your listing is ready to publish.';
export const CANCEL_BUTTON = 'Let’s explore more Valet Seller features now.';
export const MARKETING_PLACE = 'Select where you want to sell each product.';
export const BRANDING =
	'If you choose to brand your own products, please fill out the branding tab of your profile as soon as possible, otherwise, there may be a delay in your listings.';
export const MARKETING_PLACE_PLAN =
	'Remember that at any point you can upgrade your subscription! To sell on more marketplaces or list more SKUs, upgrade here.';

export const DASHBOARD =
	'On your dashboard, you will find summarized information on how your products are performing.';
export const INVENTORY =
	'On the products page you will have a live-time view of inventory levels in all warehouses and be able to create an inbound shipment to restock your products.';
export const SALES =
	'On the orders page you will have a live time view at sales across each channel and each product. You will also see incoming orders with customer information. If you use Shopify, connect your account in your profile settings to automatically sync all orders.';
export const FINISHED =
	"Great job! You've completed the basic tour. For more help, chat us anytime or open a case on your help page. Let's start selling!";
