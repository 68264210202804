import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import helper from '../../../utils/helper';
import { connect } from 'react-redux';
import * as actions from '../../../redux/actions';
import firebase1 from '../../../utils/config';
import history from '../../../utils/history';
import qs from "query-string";
import api from '../../../utils/api';

class ResetpasswordForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      isLoading:false,
      error: '',
      actionCode:''
    };
  }

  componentDidMount(){
    console.log('this.props.location.search===',history.location.search);
    let query = qs.parse(history.location.search);
    if(query&&query.mode=='resetPassword'&&query.oobCode){
      console.log('query====',query);
      this.setState({actionCode:query.oobCode})
    }else{
      history.push('/login')
    }

  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  resetPassword = () => {
      const { password,actionCode } = this.state;
      if(password==''){
        helper.errorMessage('password field is required');
      }else{
          let that =this;
          this.setState({isLoading:true})
          var auth = firebase1.app.auth();

          auth.verifyPasswordResetCode(actionCode)
          .then(function(email) {
            // Display a "new password" form with the user's email address
            that.confirmPasswordReset(actionCode,password,email,auth,that);

          })
          .catch(function() {
            // Invalid code
            helper.errorMessage('Invalid Code');
          })
         
      }

  }

  confirmPasswordReset(actionCode,password,email,auth,that){
     auth.confirmPasswordReset(actionCode,password).then(function(data) {
            // Email sent.
            helper.successMessage('Password changed sucessfully');
            that.setState({isLoading:false,password:''})
            that.updateResetPassword(email,password);
            history.push('/login')
          }).catch(function(error) {
            console.log(error);
            helper.errorMessage(error.message);
            that.setState({isLoading:false})
            // An error happened.
          });
  }

  updateResetPassword(email,password){

    api.postApi('user/updateResetPassword', {email:email,password:password}).then(
      (data) => {
        console.log(data);
      })

  }


  render() {
    const { handleSubmit } = this.props;

    if(this.state.isLoading){
      return(
        <div className="load" style={{backgroundColor: 'transparent', width: '85%', height:'50%'}}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#6c6f7f" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      )
    }else{
      return (
        <form className="form" onSubmit={handleSubmit}>
  
          
  
          <div className="form__form-group">
            <div className="form__form-group-field" style={{width:'100%'}}>
              <Field
                name="password"
                component="input"
                type="password"
                placeholder="New Password"
                value={this.state.password}  onChange={(event) => {this.setState({ password: event.target.value })}}
              />
            </div>
          </div>
          <a onClick={() => { this.resetPassword() }} className="btn btn-primary signupbtns_active account__btn--small"><span style={{color:'#fff'}}>Submit</span></a>
  
          {/* <span className="btn btn-primary signupbtns_active account__btn--small">Send request</span> */}
        </form>
      );
    }
   
  }
}

export default reduxForm({
  form: 'forgot_password_form',
})(ResetpasswordForm);
