import React, { PureComponent } from 'react';
import { Button, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import DownIcon from 'mdi-react/ChevronDownIcon';
import i18n from 'i18next';



class Topbar extends PureComponent {

  constructor() {
    super();
    this.state = {
      collapse: false,
      language:''
    };
  }

  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  changeLanguage(value){
    console.log('type===',value);
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    window.location.reload()
  //   this.setState({'language': value}, async () => {
  //     console.log('select done');
  //     window.location.reload()
  //  });

  }

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility} = this.props;
    const {t} = this.props.setOptions

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {/* <form>

              <input placeholder={t('SEARCH_FOR')} style={{padding:10, borderWidth:0, margin: 14, width: 250}}/>
            </form> */}
          </div>

          <div className="topbar__right">

            <div className="topbar__profile">
              {/* <button className="topbar__avatar" onClick={this.toggle}>
                <p className="topbar__avatar-name">{t('LANGUAGE')}</p>
                <DownIcon className="topbar__icon" />
              </button> */}
              {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
              <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
                {/* <div className="topbar__menu">
                <a href="javascript:void(0)" onClick={() => { this.changeLanguage('en') }}><span>{t('ENGLISH')}</span></a>
                </div>
                <div className="topbar__menu">
                <a href="javascript:void(0)" onClick={() => { this.changeLanguage('es') }}><span>{t('SPANISH')}</span></a>
                </div> */}
              </Collapse>
            </div>
            <TopbarProfile {...this.props}/>
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
